var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { ToastService } from 'services/ToastService';
import { FavoriteService } from 'services/FavoriteService';
import { CalculationService } from 'services/CalculationService';
class SelectLine {
    obj;
    selected;
    constructor(obj, selected) {
        this.obj = obj;
        this.selected = selected;
    }
}
let CalculationModalFavoriteProducts = class CalculationModalFavoriteProducts extends Vue {
    loading;
    goBack;
    setLoading;
    result;
    isAllSelected = false;
    lines = [];
    selectedCount = 0;
    possibleSelectCount = 0;
    list;
    mounted() {
        this.setLoading(true);
        FavoriteService.getAllLists().then(data => {
            this.result = data.sort((a, b) => {
                let aHasProducts = a.count !== 0;
                let bHasProducts = b.count !== 0;
                if (aHasProducts < bHasProducts)
                    return 1;
                if (aHasProducts > bHasProducts)
                    return -1;
                if (a.listName < b.listName) {
                    return -1;
                }
                else if (a.listName > b.listName) {
                    return 1;
                }
                return 0;
            });
            this.possibleSelectCount = this.result.length;
            this.lines = this.result.map(x => {
                if (x.count === 0)
                    this.possibleSelectCount--;
                return new SelectLine(x, false);
            });
            this.setLoading(false);
        })
            .catch(err => {
            this.setLoading(false);
            ToastService.danger(Vue.filter('translate')('webshop_defaults_toast_errorText'));
        });
    }
    toggleSelection(line) {
        if (line.obj.count === 0) {
            ToastService.danger(Vue.filter('translate')(''));
            return;
        }
        line.selected = !line.selected;
        if (!line.selected) {
            this.isAllSelected = false;
            this.selectedCount--;
        }
        else {
            this.selectedCount++;
            this.isAllSelected = this.selectedCount === this.possibleSelectCount;
        }
        // this.emitSelections();
    }
    toggleAllLines() {
        this.isAllSelected = !this.isAllSelected;
        if (this.isAllSelected) {
            this.selectedCount = this.possibleSelectCount;
        }
        else {
            this.selectedCount = 0;
        }
        this.lines.forEach(line => {
            if (line.obj.count !== 0)
                line.selected = this.isAllSelected;
        });
        // this.emitSelections();
    }
    addFavoriteLists() {
        let selectedLines = this.lines.filter(x => x.selected).map(x => x.obj.id);
        this.setLoading(true);
        CalculationService.addFromFavoriteLists(this.list.calculationId, selectedLines).then((calculation) => {
            var newLines = calculation.calculationLines.length - this.list.calculationLines.length;
            this.$store.dispatch('Calculations/createCalculationLines', calculation);
            if (newLines == 1) {
                ToastService.success(Vue.filter('translate')('webshop_calculations_modals_addProducts_toasts_addedOne'));
            }
            else if (newLines > 1) {
                ToastService.success(Vue.filter('translate')('webshop_calculations_modals_addProducts_toasts_addedMore', newLines));
            }
            else {
                ToastService.danger(Vue.filter('translate')('webshop_calculations_modals_addProducts_toasts_noNewItems'));
            }
        }).catch().finally(() => {
            this.setLoading(false);
            this.$emit("close");
        });
    }
};
CalculationModalFavoriteProducts = __decorate([
    Component({
        props: {
            goBack: Function,
            setLoading: Function,
            loading: Boolean,
            list: Object
        }
    })
], CalculationModalFavoriteProducts);
export default CalculationModalFavoriteProducts;
