var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.products && _vm.products.length > 0,
          expression: "products && products.length > 0",
        },
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: { exclude: [], handler: "close" },
          expression: "{ exclude: [], handler: 'close' }",
        },
      ],
      staticClass: "depict-after-basket-modal",
      attrs: { id: "depict-after-basket-modal" },
      on: { focus: _vm.focus, mouseenter: _vm.focus },
    },
    [
      _c(
        "a",
        {
          staticClass: "modal-close",
          on: {
            click: function ($event) {
              return _vm.close()
            },
          },
        },
        [_c("i", { staticClass: "mdi mdi-close-circle-outline" })]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "depict-card depict-card-blue" }, [
        _c("div", { staticClass: "depict-card-title" }, [
          _vm._v(
            _vm._s(
              _vm._f("translate")("webshop_layout_depict_addedProductsTitle")
            )
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "depict-card-products" },
          _vm._l(_vm.products, function (product) {
            return _c(
              "div",
              {
                key: product.productId,
                staticClass: "depict-card-product",
                attrs: { grid: "wrap" },
              },
              [
                _c(
                  "span",
                  { staticClass: "depict-column", attrs: { column: "xl-3" } },
                  [_c("img", { attrs: { src: product.image } })]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "depict-column", attrs: { column: "xl-5" } },
                  [_c("strong", [_vm._v(_vm._s(product.description))])]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "depict-column depict-column-brand",
                    attrs: { column: "xl-4" },
                  },
                  [_vm._v(_vm._s(product.brand))]
                ),
              ]
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "depict-after-basket-modal-alternatives" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }