var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import FavoriteCreateModal from "components/favorite/FavoriteCreateModal.vue";
import { ModalService } from "modules/basic-core/basic-modal/src";
import { EventTypes } from "models/EventTypes";
import { SearchInput } from "models/search/SearchInput";
import { SearchSortBy } from "models/search/SearchSortBy";
import { Settings } from "config/Settings";
let ShopFilterFavoriteLists = class ShopFilterFavoriteLists extends Vue {
    loading;
    lists;
    sharedLists;
    input;
    eventType;
    renderCounter = 0;
    settings = Settings;
    mounted() {
    }
    beforeDestroy() {
        this.$root.$off(this.eventType);
        this.$root.$off(EventTypes.FavoriteCreatedList, this.createdNewList);
        this.$root.$off(EventTypes.FavoriteListUpdate, this.favoriteListeUpdate);
        this.$root.$off(EventTypes.FavoriteListInsert, this.favoriteListInsert);
        this.$root.$off(EventTypes.FavoriteCreatedList, this.favoriteListeUpdate);
    }
    created() {
        this.$root.$emit(this.eventType);
        this.$root.$on(EventTypes.FavoriteCreatedList, this.createdNewList);
        this.$root.$on(EventTypes.FavoriteListUpdate, this.favoriteListeUpdate);
        this.$root.$on(EventTypes.FavoriteListInsert, this.favoriteListInsert);
        this.$root.$on(EventTypes.FavoriteCreatedList, this.favoriteListeUpdate);
    }
    get selectedList() {
        if (this.input.favoriteListId && this.input.department) {
            let selList = this.lists.find(x => x.id == this.input.favoriteListId && x.department == this.input.department);
            selList = selList ? selList : this.sharedLists.find(x => x.id == this.input.favoriteListId);
            return selList;
        }
        return null;
    }
    favoriteListeUpdate() {
        this.renderCounter++;
    }
    favoriteListInsert(list) {
        this.createdNewList(list);
        this.select(list);
        this.renderCounter++;
    }
    select(list) {
        let input = Object.assign(new SearchInput(), this.input);
        input.favoriteListId = list ? list.id : null;
        input.department = list ? list.department : null;
        input.searchQuery = "";
        input.sortBy = SearchSortBy.Description;
        this.$root.$emit(EventTypes.ShopSearchStart, input);
    }
    openCreateNew() {
        ModalService.create({
            cxt: this,
            component: FavoriteCreateModal,
            maxWidth: "450px"
        });
    }
    createdNewList(list) {
        if (list) {
            // this.lists.push(list);
            let indx = this.lists.findIndex(x => list.listName.toLowerCase() <= x.listName.toLowerCase());
            if (indx >= 0) {
                this.lists.splice(indx, 0, list);
            }
            else {
                this.lists.push(list);
            }
        }
    }
};
ShopFilterFavoriteLists = __decorate([
    Component({
        props: {
            input: Object,
            lists: Array,
            sharedLists: Array,
            loading: Boolean,
            eventType: String,
        }
    })
], ShopFilterFavoriteLists);
export default ShopFilterFavoriteLists;
