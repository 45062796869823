var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table-outer-row shop-product-list-item" }, [
    _c(
      "div",
      {
        staticClass: "table-row wrap",
        class: {
          "has-alternative-product": _vm.product.AltProduct,
          "alternative-product": _vm.isAltProduct,
          "shop-product-inBasket": _vm.product.inBasket,
          "shop-product-loading": _vm.productLoading,
          "shop-product-loading-success": _vm.showLoadingSuccess,
          "shop-product-delete-success": _vm.showDeleteLoading,
          "shop-product-status-deleted":
            _vm.product.status === _vm.itemStatus.Deleted,
        },
      },
      [
        _c("div", { staticClass: "table-column product-id-column" }, [
          _vm.product.countryCode
            ? _c("div", { staticClass: "origin-country" }, [
                _c("div", [
                  _c("img", {
                    attrs: {
                      src: _vm.flagsBaseLink + _vm.product.countryCode + ".svg",
                      loading: "lazy",
                    },
                    on: {
                      error: function ($event) {
                        $event.target.src = _vm.flagsBaseLink + "no-flag.svg"
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.product.countryCode))]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.product.productId))]),
          _vm._v(" "),
          _vm.product.supplierProductId
            ? _c("span", { staticClass: "small-caption supplier-product-id" }, [
                _vm._v(
                  "\r\n                (" +
                    _vm._s(_vm.product.supplierProductId) +
                    ")\r\n            "
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table-column align-left description-column" },
          [
            _c("span", { staticClass: "small-caption" }, [
              _vm._v(_vm._s(_vm.product.postingGroupName)),
            ]),
            _vm._v(" "),
            _c("span", [
              _c("b", [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.openFoodInfo()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.product.description))]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.product.countryCode == null && _vm.product.country == null
              ? _c("span", { staticClass: "small-caption" }, [
                  _vm._v(
                    _vm._s(_vm.product.countryCode) +
                      " " +
                      _vm._s(_vm.product.country)
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("shop-item-icons", {
              attrs: {
                product: _vm.product,
                priceTypeCampaign: _vm.priceTypeCampaign,
              },
            }),
            _vm._v(" "),
            _vm.product.markings
              ? _c("highlight-markings", {
                  attrs: { itemMarkings: _vm.product.markings },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _vm.isOpenShop ? _c("div", { staticClass: "break-small" }) : _vm._e(),
        _vm._v(" "),
        !_vm.isOpenShop
          ? _c(
              "div",
              { staticClass: "table-column stock-status-column" },
              [
                _vm.showAvailableQuantity
                  ? _c(
                      "shop-item-stock-status",
                      { attrs: { product: _vm.product } },
                      [
                        _c(
                          "shop-stock-warning",
                          _vm._b(
                            {},
                            "shop-stock-warning",
                            {
                              amount: _vm.amount,
                              selectedUnit: _vm.selectedUnit,
                              product: _vm.product,
                              autoDisplayMessage: true,
                            },
                            false
                          )
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "table-column brand-column" }, [
          _c("span", [_vm._v(_vm._s(_vm.product.brand))]),
        ]),
        _vm._v(" "),
        !_vm.isOpenShop ? _c("div", { staticClass: "break-medium" }) : _vm._e(),
        _vm._v(" "),
        _vm.isOpenShop
          ? _c(
              "div",
              {
                staticClass: "table-column unit unit-column",
                class: { "open-shop": _vm.isOpenShop },
              },
              [
                !_vm.product.blocked &&
                !_vm.product.saleBlocked &&
                !_vm.product.obsoleteProduct &&
                _vm.product.status !== _vm.itemStatus.Deleted
                  ? [
                      _vm.product.units.length > 1
                        ? _c("div", { staticClass: "input-wrapper" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedUnit,
                                    expression: "selectedUnit",
                                  },
                                ],
                                staticClass: "unit calc",
                                attrs: {
                                  required: "",
                                  disabled: _vm.loading || _vm.productLoading,
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.selectedUnit = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                    _vm.changeUnit,
                                  ],
                                },
                              },
                              _vm._l(_vm.product.units, function (unit) {
                                return _c(
                                  "option",
                                  {
                                    key: unit.name,
                                    domProps: { value: unit.name },
                                  },
                                  [
                                    _vm._v(
                                      "\r\n                            " +
                                        _vm._s(
                                          _vm._f("unitDescription")(unit)
                                        ) +
                                        "\r\n                        "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ])
                        : _c("span", { staticClass: "single-unit" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("unitDescription")(_vm.product.units[0])
                              )
                            ),
                          ]),
                    ]
                  : _vm._e(),
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "break-small" }),
        _vm._v(" "),
        !_vm.isOpenShop
          ? _c(
              "div",
              { staticClass: "table-column column-group cart-column-group" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "table-column item-price price-column align-right",
                  },
                  [
                    _vm.product.blocked ||
                    _vm.product.saleBlocked ||
                    _vm.product.obsoleteProduct ||
                    _vm.product.status === _vm.itemStatus.Deleted ||
                    _vm.product.status === _vm.itemStatus.Outgoing
                      ? [
                          _vm.product.blocked ||
                          (_vm.product.saleBlocked &&
                            _vm.product.saleBlockedDate === null)
                            ? _c("p", { staticClass: "bold" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("translate")(
                                      "webshop_search_search_item_blocked_no_end_date"
                                    )
                                  )
                                ),
                              ])
                            : _vm.product.saleBlocked &&
                              _vm.product.saleBlockedDate !== null
                            ? _c("p", { staticClass: "bold" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("translate")(
                                      "webshop_search_search_item_blocked_with_end_date",
                                      _vm.$options.filters.date(
                                        _vm.product.saleBlockedDate,
                                        "L"
                                      )
                                    )
                                  )
                                ),
                              ])
                            : _vm.product.obsoleteProduct
                            ? _c("p", { staticClass: "bold" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("translate")(
                                      "webshop_search_search_item_obsolete_text_withAltProduct"
                                    )
                                  )
                                ),
                              ])
                            : _vm.product.status === _vm.itemStatus.Deleted
                            ? _c("p", { staticClass: "bold" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("translate")(
                                      "webshop_search_search_item_deleted"
                                    )
                                  )
                                ),
                              ])
                            : _vm.product.status === _vm.itemStatus.Outgoing
                            ? _c("p", { staticClass: "outgoing-text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("translate")(
                                      "webshop_search_search_item_outgoing"
                                    )
                                  )
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.product.AltProduct
                            ? _c("p", { staticClass: "bold" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("translate")(
                                      "webshop_search_search_item_alternative_item_beneath"
                                    )
                                  )
                                ),
                              ])
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.product.blocked &&
                    !_vm.product.saleBlocked &&
                    !_vm.product.obsoleteProduct &&
                    _vm.product.status !== _vm.itemStatus.Deleted &&
                    _vm.showPrice
                      ? [
                          _vm.product.comparisonPrice && _vm.showPrice
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "small-caption price-weight-notice",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("translate")(
                                        "webshop_search_search_item_comparisonPrice"
                                      )
                                    ) +
                                      ": " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.product.comparisonPrice
                                        )
                                      ) +
                                      " / " +
                                      _vm._s(_vm.product.comparisonUnit)
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "item-price",
                              class: {
                                "item-price-campaign":
                                  _vm.priceBeforeCampaignOrExpiration > 0 ||
                                  (_vm.product.markings.hasCampaign &&
                                    _vm.priceTypeCampaign),
                              },
                            },
                            [
                              _c("item-surcharge-warning", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isPriceWithSurcharge,
                                    expression: "isPriceWithSurcharge",
                                  },
                                ],
                                attrs: {
                                  "suggested-amount":
                                    _vm.leftAmountWithSurcharge,
                                },
                              }),
                              _vm._v(
                                "\r\n                        " +
                                  _vm._s(_vm._f("currency")(_vm.price))
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.priceBeforeCampaignOrExpiration > 0
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "item-price price-before-campaign",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.priceBeforeCampaignOrExpiration
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "label-wrapper" }, [
                            _vm.product.onSale
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "product-discount ui-label ui-label-small",
                                    attrs: {
                                      title: _vm.$options.filters.translate(
                                        "webshop_icons_discount_title"
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("translate")(
                                          "webshop_icons_discount"
                                        )
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "table-column favorites-column" },
                  [
                    (!_vm.isAltProduct ||
                      (_vm.isAltProduct && _vm.getListId === null)) &&
                    !_vm.punchOut
                      ? _c("shop-item-favorite-menu", {
                          attrs: {
                            product: _vm.product,
                            selectedUnit: _vm.selectedUnit,
                            loading: _vm.loading,
                            "product-loading": _vm.productLoading,
                          },
                          on: {
                            loading: _vm.setProductLoading,
                            remove: _vm.removeProduct,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                !_vm.isOpenShop
                  ? _c("div", { staticClass: "break-medium" })
                  : _vm._e(),
                _vm._v(" "),
                _vm.enableOrder
                  ? _c(
                      "div",
                      { staticClass: "table-column cart-column" },
                      [
                        !_vm.product.blocked &&
                        !_vm.product.saleBlocked &&
                        !_vm.product.obsoleteProduct &&
                        (!_vm.isAltProduct ||
                          (_vm.isAltProduct && _vm.getListId === null)) &&
                        _vm.product.status !== _vm.itemStatus.Deleted
                          ? [
                              _c("input-number", {
                                ref: "inputAmountParent",
                                staticClass: "input-wrapper quantity-wrapper",
                                attrs: {
                                  inputUnit: _vm.selectedUnit,
                                  inputDisabled:
                                    _vm.loading ||
                                    _vm.productLoading ||
                                    _vm.product.status ===
                                      _vm.itemStatus.Deleted,
                                  inputClass: "quantity-input",
                                  autocomplete: "off",
                                  inputTabindex: "3",
                                  inputStep:
                                    _vm.product.markings.weight &&
                                    _vm.selectedUnit === "KG"
                                      ? 0.01
                                      : 1,
                                  selectOnFocus: true,
                                  inputRef: "inputAmount",
                                  inputMin: -0.001,
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.addItemAmount()
                                  },
                                  keyupenter: function ($event) {
                                    return _vm.addProduct(true)
                                  },
                                },
                                model: {
                                  value: _vm.amount,
                                  callback: function ($$v) {
                                    _vm.amount = $$v
                                  },
                                  expression: "amount",
                                },
                              }),
                              _vm._v(" "),
                              _c("suggestion-tooltip", {
                                attrs: {
                                  product: _vm.product,
                                  "selected-unit": _vm.selectedUnit,
                                  amount: _vm.amount,
                                  disabled: _vm.isExpiryProduct,
                                },
                              }),
                              _vm._v(" "),
                              !_vm.product.blocked &&
                              !_vm.product.saleBlocked &&
                              !_vm.product.obsoleteProduct &&
                              _vm.product.status !== _vm.itemStatus.Deleted
                                ? [
                                    _vm.product.units.length > 1
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "input-wrapper input-wrapper-unit",
                                          },
                                          [
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.selectedUnit,
                                                    expression: "selectedUnit",
                                                  },
                                                ],
                                                staticClass: "unit calc",
                                                attrs: {
                                                  required: "",
                                                  disabled:
                                                    _vm.loading ||
                                                    _vm.productLoading ||
                                                    _vm.isExpiryProduct,
                                                },
                                                on: {
                                                  change: [
                                                    function ($event) {
                                                      var $$selectedVal =
                                                        Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function (o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function (o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                      _vm.selectedUnit = $event
                                                        .target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    },
                                                    _vm.changeUnit,
                                                  ],
                                                },
                                              },
                                              _vm._l(
                                                _vm.product.units,
                                                function (unit) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      key: unit.name,
                                                      domProps: {
                                                        value: unit.name,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\r\n                                    " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "unitDescription"
                                                            )(unit)
                                                          ) +
                                                          "\r\n                                "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          { staticClass: "single-unit" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("unitDescription")(
                                                  _vm.product.units[0]
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.product.blocked &&
                              !_vm.product.saleBlocked &&
                              !_vm.product.obsoleteProduct &&
                              (!_vm.isAltProduct ||
                                (_vm.isAltProduct && _vm.getListId === null))
                                ? _c("shop-item-buy-button", {
                                    ref: "buyButton",
                                    attrs: {
                                      product: _vm.product,
                                      loading: _vm.loading,
                                      "product-loading": _vm.productLoading,
                                      amount: _vm.amount,
                                      selectedUnit: _vm.selectedUnit,
                                      inBasket: _vm.product.inBasket,
                                      view: "list",
                                      uiSource: _vm.uiSource,
                                      position: _vm.position,
                                    },
                                    on: {
                                      loading: _vm.setProductLoading,
                                      added: _vm.addedProduct,
                                      amount: _vm.setAmount,
                                    },
                                  })
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        ((_vm.product.blocked || _vm.product.saleBlocked) &&
                          _vm.product.AltProduct === null) ||
                        _vm.product.status === _vm.itemStatus.Deleted
                          ? _c("span", {
                              staticClass: "icon-report-problem",
                              attrs: {
                                title:
                                  (_vm.product.blocked ||
                                    _vm.product.saleBlocked) &&
                                  (!_vm.product.stopTime ||
                                    _vm.product.stopTime != "00:00:00")
                                    ? _vm.$options.filters.translate(
                                        "webshop_search_search_item_errormessage_deadlineBlocked"
                                      )
                                    : "",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.isAltProduct &&
                        _vm.getListId !== null &&
                        (_vm.product.blocked ||
                          _vm.product.saleBlocked ||
                          _vm.product.obsoleteProduct) &&
                        _vm.product.AltProduct !== null
                          ? _c("shop-replace-alt-prod-button", {
                              attrs: {
                                product: _vm.product,
                                loading: _vm.loading,
                                "product-loading": _vm.productLoading,
                                listId: _vm.getListId,
                              },
                              on: {
                                loading: _vm.setProductLoading,
                                replaced: _vm.replaceProduct,
                              },
                            })
                          : _vm._e(),
                      ],
                      2
                    )
                  : _c("div", { staticClass: "table-column cart-column" }, [
                      _c("div", {
                        staticClass: "input-wrapper quantity-wrapper",
                      }),
                    ]),
              ]
            )
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "clear" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }