var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import LoadingButton from '../../shared/LoadingButton.vue';
import Loader from 'components/utility/Loader.vue';
import { CalculationService } from 'services/CalculationService';
import { CalculationTreeVM } from 'models/calculation/CalculationTreeVM';
import { CopyDetails } from 'models/calculation/CopyDetails';
let CopyCalculationModal = class CopyCalculationModal extends Vue {
    data;
    calculationTree = new CalculationTreeVM();
    calculationTreeArray = [];
    isSelectedSubCalculations = false;
    mounted() {
        this.loadCalculationTree();
    }
    get loading() {
        return this.$store.state.Calculations.loading;
    }
    closeModal() {
        this.$emit('close-modal');
    }
    getCalculationTreeArray(calculationTree, newArray) {
        let lastIndex = newArray.length - 1;
        if (lastIndex < 0) {
            newArray[0] = [];
            newArray[0].push(CalculationTreeVM.toCalculationVM(calculationTree));
            lastIndex = newArray.length - 1;
        }
        calculationTree.calculationTreeVMs.forEach(x => {
            let newRow = [];
            if (lastIndex >= 0) {
                newRow = [...newArray[lastIndex]];
            }
            newRow.push(CalculationTreeVM.toCalculationVM(x));
            newArray.push(newRow);
            this.getCalculationTreeArray(x, newArray);
        });
    }
    loadCalculationTree() {
        CalculationService.getCalculationTreeStructure(this.data.calculation.calculationId).then((res) => {
            this.calculationTree = res;
            this.calculationTreeArray = [];
            this.getCalculationTreeArray(res, this.calculationTreeArray);
        });
    }
    copyCalculation() {
        let copyDetails = new CopyDetails(this.data.calculation.calculationId, this.isSelectedSubCalculations);
        this.$store.dispatch('Calculations/copyCalculation', copyDetails).then((res) => {
            this.data.confirmFunction(res.calculationId.toString());
        }).finally(() => {
            this.$emit('close-modal');
        });
    }
};
CopyCalculationModal = __decorate([
    Component({
        components: {
            LoadingButton,
            Loader,
        },
        props: {
            data: Object
        }
    })
], CopyCalculationModal);
export default CopyCalculationModal;
