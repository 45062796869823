import { render, staticRenderFns } from "./ShopFilters.vue?vue&type=template&id=27bc5768&scoped=true&"
import script from "./ShopFilters.vue?vue&type=script&lang=ts&"
export * from "./ShopFilters.vue?vue&type=script&lang=ts&"
import style0 from "./ShopFilters.vue?vue&type=style&index=0&id=27bc5768&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27bc5768",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Projekt\\webbshop\\SvenskCater\\SvenskCater\\app\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('27bc5768')) {
      api.createRecord('27bc5768', component.options)
    } else {
      api.reload('27bc5768', component.options)
    }
    module.hot.accept("./ShopFilters.vue?vue&type=template&id=27bc5768&scoped=true&", function () {
      api.rerender('27bc5768', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/shop/filters/ShopFilters.vue"
export default component.exports