var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { ModalVM } from "../models/ModalVM";
import { ModalService } from "../service/ModalService";
import Modal from "./Modal.vue";
let ModalList = class ModalList extends Vue {
    showModal = false;
    nextId = 0;
    modals = ModalService.modals;
    modal = null;
    mounted() {
        this.$root.$on("onShowModal", this.create);
    }
    beforeDestroy() {
        this.$root.$off("onShowModal", this.create);
    }
    create(args) {
        let settings = {
            cxt: args.cxt,
            content: args.content || null,
            component: args.component || null,
            maxWidth: args.maxWidth || "100vw",
            height: args.height || null,
            duration: args.duration || null,
            active: args.active || true,
            allowDuplicates: args.allowDuplicates || false,
            data: args.data || null,
        };
        // Avoid spawning the same modal, unless duplicates are allowed
        let allowModalPush = settings.allowDuplicates;
        if (!settings.allowDuplicates) {
            allowModalPush = ModalService.modals.every((modal) => {
                if (!!args.content)
                    return args.content !== modal.content;
                else
                    return args.component.name !== modal.component.name;
            });
        }
        if (allowModalPush) {
            this.modal = new ModalVM(this.nextId++, settings.content, settings.component, settings.maxWidth, settings.height, settings.duration, settings.active, settings.allowDuplicates, settings.data);
            ModalService.modals.push(this.modal);
            if (settings.duration != null) {
                setTimeout(() => {
                    ModalService.spliceModals(this.modal);
                }, this.modal.duration);
            }
            this.showModal = args.active;
            this.modal = null;
        }
    }
    show() {
        if (ModalService.modals.length > 0)
            this.showModal = true;
    }
    close(modal = null) {
        if (modal !== null && ModalService.modals.length > 0)
            ModalService.spliceModals(modal);
        if (ModalService.modals.length === 0)
            this.showModal = false;
    }
    closeOverlay() {
        if (ModalService.modals.length === 1) {
            ModalService.modals.length--;
            ModalService.modals.length === 0;
            this.showModal = false;
        }
    }
};
ModalList = __decorate([
    Component({
        components: {
            Modal,
        },
    })
], ModalList);
export default ModalList;
