import { DeliveryDates } from 'models/shared/DeliveryDates';
import { Service } from './Service';
import { AuthApi } from 'api/AuthApi';
export class AuthService extends Service {
    static login(username, password, rememberMe) {
        return new Promise((resolve, reject) => {
            AuthApi.login(username, password, rememberMe).then((res) => {
                resolve();
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static getDeliveryDates() {
        return new Promise((resolve, reject) => {
            AuthApi.getDeliveryDates().then((res) => {
                resolve(DeliveryDates.fromApi(res.data));
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static setDeliveryDate(date, pickup) {
        return new Promise((resolve, reject) => {
            AuthApi.setDeliveryDate(date, pickup).then((res) => {
                resolve();
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static requestUsername(email) {
        return new Promise((resolve, reject) => {
            AuthApi.requestUsername(email).then((res) => {
                resolve();
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static requestPassword(username) {
        return new Promise((resolve, reject) => {
            AuthApi.requestPassword(username).then((res) => {
                resolve();
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static resetPassword(guid, token, newPassword) {
        return new Promise((resolve, reject) => {
            AuthApi.resetPassword(guid, token, newPassword).then((res) => {
                resolve();
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static changePassword(oldPassword, newPassword) {
        return new Promise((resolve, reject) => {
            AuthApi.changePassword(oldPassword, newPassword).then((res) => {
                resolve();
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
}
