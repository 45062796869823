var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ui-card" },
    [
      _c("div", { staticClass: "ui-card-header ui-card-header-inverted" }, [
        _c("span", [
          _vm._v(
            _vm._s(
              _vm._f("translate")("webshop_inventoryArchive_result_cardTitle")
            )
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("inventory-archive-list", {
        attrs: { loading: _vm.loading, "archive-list": _vm.archiveList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }