var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import InputNumber from 'components/shared/InputNumber.vue';
import { PermissionService } from 'services/PermissionService';
import { PermissionLevel } from 'models/user/PermissionLevel';
let ConvertedOrderline = class ConvertedOrderline extends Vue {
    orderline;
    showPrice = PermissionService.hasPermission(PermissionLevel.ShowPrice);
    selectedUnit = null;
    mounted() {
        this.selectedUnit = this.orderline.units.find(x => x.name == this.orderline.unit);
    }
};
ConvertedOrderline = __decorate([
    Component({
        components: {
            InputNumber
        },
        props: {
            orderline: Object,
        }
    })
], ConvertedOrderline);
export default ConvertedOrderline;
