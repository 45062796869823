export class InventoryList {
    id;
    name;
    created;
    updated;
    groups;
    linesNotInGroup;
    autoList;
    totalValue;
    totalProducts;
    totalPriceLinesNotInGroup;
    _showOverlay = false;
    /* constructor(){
        this.id = null;
        this.name = null;
        this.created = null;
        this.updated = null;
        this.groups = [];
        this.linesNotInGroup = [];
        this.autoList = false;
        this.totalValue = null;
        this.totalProducts = null;
    } */
    constructor(id = null, groups = [], linesNotInGroup = []) {
        this.id = id;
        this.name = null;
        this.created = null;
        this.updated = null;
        this.groups = groups;
        this.linesNotInGroup = linesNotInGroup;
        this.autoList = false;
        this.totalValue = null;
        this.totalProducts = null;
        this.totalPriceLinesNotInGroup = null;
    }
    static fromApi(list) {
        return Object.assign(new InventoryList(), list);
    }
}
