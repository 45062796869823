var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
export var ImagePickerErrorType;
(function (ImagePickerErrorType) {
    ImagePickerErrorType[ImagePickerErrorType["UnknownError"] = 0] = "UnknownError";
    ImagePickerErrorType[ImagePickerErrorType["SizeExceed"] = 1] = "SizeExceed";
    ImagePickerErrorType[ImagePickerErrorType["WrongFileFormat"] = 2] = "WrongFileFormat";
    ImagePickerErrorType[ImagePickerErrorType["InvalidImage"] = 3] = "InvalidImage";
})(ImagePickerErrorType || (ImagePickerErrorType = {}));
export class ImagePickerError {
    type;
    constructor(type) {
        this.type = type;
    }
}
let ImagePicker = class ImagePicker extends Vue {
    customClass;
    buttonText;
    deleteButtonText;
    dragDropText;
    imageUrl;
    compressThreshold;
    maxSize;
    maxSizeWidthOrHeight;
    disabled;
    isSelected = false;
    showDeleteButton = false;
    regex = new RegExp('image/(jpe?g|png|gif|bmp)');
    mounted() {
        if (this.imageUrl !== '') {
            this.showDeleteButton = true;
        }
    }
    selectImage(evt) {
        let inputEl = this.$refs.ipInput;
        evt.preventDefault();
        inputEl.click();
    }
    dropImage(evt) {
        evt.preventDefault();
        if (evt.dataTransfer.files && evt.dataTransfer.files.length && !this.disabled) {
            let file = evt.dataTransfer.files[0];
            this.processImage(file, true);
        }
    }
    deleteImage(evt) {
        evt.preventDefault();
        let inputEl = this.$refs.ipInput;
        let imgEl = this.$refs.ipImg;
        imgEl.src = "";
        inputEl.value = "";
        this.showDeleteButton = false;
        this.$emit("onDeleteImage", evt);
    }
    processImage(input, droppedImage = false) {
        this.isSelected = false;
        if (droppedImage || input.target.files && input.target.files[0]) {
            let inputFile = droppedImage ? input : input.target.files[0];
            if (!this.regex.test(inputFile.type)) {
                this.$emit("onError", new ImagePickerError(ImagePickerErrorType.WrongFileFormat));
                return;
            }
            let reader = new FileReader();
            reader.onload = (e) => {
                let imgEl = this.$refs.ipImg;
                let inputSize = inputFile.size / 1024;
                if (inputSize > this.maxSize) {
                    imgEl.src = this.imageUrl;
                    if (!droppedImage) {
                        input.target.value = "";
                    }
                    this.$emit("onError", new ImagePickerError(ImagePickerErrorType.SizeExceed));
                    return;
                }
                // resize
                if (inputSize > this.compressThreshold) {
                    let img = new Image();
                    img.src = URL.createObjectURL(inputFile);
                    img.onload = () => {
                        let sizeRation = 1;
                        if (img.height > this.maxSizeWidthOrHeight || img.width > this.maxSizeWidthOrHeight) {
                            sizeRation = img.height - img.width < 0 ?
                                this.maxSizeWidthOrHeight / img.height : this.maxSizeWidthOrHeight / img.width;
                        }
                        this.resizeImage(img, sizeRation);
                    };
                }
                else {
                    this.selectedImage(inputFile);
                }
                this.isSelected = true;
                this.showDeleteButton = true;
                imgEl.setAttribute("src", e.target.result.toString());
            };
            reader.readAsDataURL(inputFile);
        }
    }
    resizeImage(img, sizeRation) {
        let canvas = document.createElement("canvas");
        let height = img.height;
        let width = img.width;
        if (sizeRation < 1) {
            height = Math.round(img.height * sizeRation);
            width = Math.round(img.width * sizeRation);
        }
        canvas.height = height;
        canvas.width = width;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        // canvas.toBlob isn't supported on all browser
        this.toBlob(canvas, (blob) => {
            this.selectedImage(blob);
        }, "image/jpeg", 0.8);
        return canvas;
    }
    selectedImage(input) {
        let formData = new FormData();
        formData.append("image", input);
        this.$emit("onSelectedImage", formData);
    }
    toBlob(canvas, callback, type, quality) {
        setTimeout(function () {
            var binStr = atob(canvas.toDataURL(type, quality).split(',')[1]), len = binStr.length, arr = new Uint8Array(len);
            for (var i = 0; i < len; i++) {
                arr[i] = binStr.charCodeAt(i);
            }
            callback(new Blob([arr], { type: type || 'image/png' }));
        });
    }
    onImageError(evt) {
        let imgEl = this.$refs.ipImg;
        if (imgEl.src.startsWith('data:image')) {
            this.$emit("onError", new ImagePickerError(ImagePickerErrorType.InvalidImage));
            this.isSelected = false;
            if (this.imageUrl == '') {
                this.showDeleteButton = false;
            }
            else {
                imgEl.src = this.imageUrl;
            }
        }
    }
};
ImagePicker = __decorate([
    Component({
        props: {
            customClass: String,
            buttonText: String,
            deleteButtonText: String,
            dragDropText: String,
            imageUrl: { type: String, default: "" },
            compressThreshold: { type: Number, default: 128 },
            maxSize: { type: Number, default: 1024 },
            maxSizeWidthOrHeight: { type: Number, default: 480 },
            disabled: Boolean
        }
    })
], ImagePicker);
export default ImagePicker;
