var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.displayContent
    ? _c(
        "div",
        {
          staticClass: "shop-stock-message",
          on: { mouseleave: _vm.closeMessage },
        },
        [
          _c("span", {
            staticClass: "mdi mdi-alert",
            on: { click: _vm.toggleMessage },
          }),
          _vm._v(" "),
          _vm.active
            ? _c("div", { staticClass: "shop-stock-message-content" }, [
                _c(
                  "div",
                  [
                    this.product.stock < 0 || this.product.markings.procure
                      ? [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_search_search_item_stockStatus_stockMessage_stockUnknown"
                                )
                              ) +
                              "\n      "
                          ),
                        ]
                      : [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_search_search_item_stockStatus_stockMessage_stockExceeds"
                                )
                              ) +
                              "\n      "
                          ),
                        ],
                  ],
                  2
                ),
              ])
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }