var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ui-modal-content" }, [
      _c("h3", { staticClass: "ui-modal-header header-medium" }, [
        _vm._v(
          _vm._s(
            _vm._f("translate")("webshop_calculations_modals_addProducts_title")
          )
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "slides" },
        [
          _vm.showNavigation
            ? _c("div", { staticClass: "modal-navigation search" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "ui-button ui-button-flat ui-button-small ui-button-transparent",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.goBack()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "mdi mdi-arrow-left" }),
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("translate")("webshop_general_back")) +
                        "\r\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.searchQuery
                  ? _c("span", { staticClass: "navigation-head" }, [
                      _c("i", { staticClass: "mdi mdi-magnify" }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("translate")(
                              "webshop_calculations_modals_addProducts_nav_search"
                            )
                          ) +
                          "\r\n                    "
                      ),
                      _c("span", { staticClass: "search-query-container" }, [
                        _vm._v("- "),
                        _c("span", { staticClass: "search-query" }, [
                          _vm._v('"' + _vm._s(_vm.searchQuery) + '"'),
                        ]),
                      ]),
                    ])
                  : _vm.prefixedPeriod
                  ? _c("span", { staticClass: "navigation-head" }, [
                      _c("i", { staticClass: "mdi mdi-numeric-6-circle" }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("translate")(
                              "webshop_calculations_modals_addProducts_nav_orderFromPeriod",
                              _vm.prefixedPeriod
                            )
                          ) +
                          "\r\n                "
                      ),
                    ])
                  : _vm.showCustomerItem
                  ? _c("span", { staticClass: "navigation-head" }, [
                      _c("i", { staticClass: "mdi mdi mdi-pencil" }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("translate")(
                              "webshop_calculations_modals_addProducts_nav_customerItem"
                            )
                          ) +
                          "\r\n                "
                      ),
                    ])
                  : _c("span", { staticClass: "navigation-head" }, [
                      _c("i", { staticClass: "mdi mdi-calendar" }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("translate")(
                              "webshop_calculations_modals_addProducts_nav_orderFromDate"
                            )
                          ) +
                          "\r\n                    "
                      ),
                      _vm.selectedDate
                        ? _c(
                            "span",
                            { staticClass: "search-query-container" },
                            [
                              _vm._v("- "),
                              _c("span", { staticClass: "search-query" }, [
                                _vm._v(
                                  '"' +
                                    _vm._s(
                                      _vm._f("date")(_vm.selectedDate, "L")
                                    ) +
                                    '"'
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.showNavigation && !_vm.showFavoriteLists && !_vm.showCustomerItem
            ? _c("calculation-modal-overview", {
                attrs: {
                  loading: _vm.loading,
                  setLoading: _vm.setLoading,
                  list: _vm.data,
                  initSearchQuery: _vm.searchQuery,
                },
                on: {
                  onResult: _vm.onResult,
                  onOrderSearch: _vm.onOrderSearch,
                },
              })
            : _vm.showOrderDatepicker && !_vm.selectedDate
            ? _c("calculation-modal-datepicker", {
                on: { selected: _vm.getResultByOrders },
              })
            : _vm.showFavoriteLists
            ? _c("calculation-modal-favorite-products", {
                attrs: {
                  loading: _vm.loading,
                  setLoading: _vm.setLoading,
                  list: _vm.data,
                  goBack: _vm.goBack,
                },
                on: { close: _vm.close },
              })
            : _vm.showCustomerItem
            ? _c("calculation-modal-customer-item-form", {
                attrs: {
                  loading: _vm.loading,
                  setLoading: _vm.setLoading,
                  list: _vm.data,
                },
                on: { save: _vm.saveCustomerItem, close: _vm.close },
              })
            : _c("calculation-modal-product-result", {
                attrs: {
                  loading: _vm.loading,
                  setLoading: _vm.setLoading,
                  list: _vm.data,
                  result: _vm.result,
                  "show-search-filter": !_vm.searchQuery,
                },
                on: { close: _vm.close },
              }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "inventory-loader" }, [_vm._m(0)])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "simple-loader-ui" }, [
      _c("div", { staticClass: "simple-loader dark" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }