var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import LoadingButton from '../../shared/LoadingButton.vue';
import Loader from 'components/utility/Loader.vue';
let DeleteCalculationModal = class DeleteCalculationModal extends Vue {
    data;
    beforeDestroy() {
    }
    closeModal() {
        this.$emit('close-modal');
    }
    submit() {
        var selectedCalculation = this.$store.state.Calculations.selectedCalculation;
        this.$store.dispatch('Calculations/deleteCalculation', this.data.calculation).then(res => {
            if (selectedCalculation && selectedCalculation.calculationId) {
                if (selectedCalculation.calculationId == this.data.calculation.calculationId) {
                    this.data.replaceRoute();
                }
                if (selectedCalculation.calculationLines.find(x => x.id == this.data.calculation.calculationId && !x.isItem)) {
                    this.$store.dispatch('Calculations/selectCalculation', selectedCalculation.calculationId);
                }
            }
            this.$emit('close-modal');
        });
    }
    get loading() {
        return this.$store.state.Calculations.loading;
    }
};
DeleteCalculationModal = __decorate([
    Component({
        components: {
            LoadingButton,
            Loader
        },
        props: {
            data: Object
        }
    })
], DeleteCalculationModal);
export default DeleteCalculationModal;
