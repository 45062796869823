var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import Loader from 'components/utility/Loader.vue';
import LoadingButton from 'components/shared/LoadingButton.vue';
import { CalculationVM } from "models/calculation/CalculationVM";
import { CalculationType } from "models/calculation/CalculationType";
let CalculationModal = class CalculationModal extends Vue {
    data;
    name = '';
    CalculationType = CalculationType;
    selectedType = CalculationType.Delkalkyl;
    close() {
        this.$emit('close-modal');
    }
    create() {
        let newCalculation = new CalculationVM();
        newCalculation.name = this.name;
        newCalculation.calculationType = this.selectedType;
        this.$store.dispatch('Calculations/createCalculation', newCalculation).then((res) => {
            this.data(res.calculationId);
        }).finally(() => {
            this.$emit('close-modal');
        });
    }
    get loading() {
        return this.$store.state.Calculations.loading;
    }
    get calculationTypes() {
        let calculationTypesKeys = [];
        for (let item in CalculationType) {
            if (isNaN(Number(item))) {
                calculationTypesKeys.push(item);
            }
        }
        return calculationTypesKeys;
    }
};
CalculationModal = __decorate([
    Component({
        components: {
            Loader,
            LoadingButton
        },
        props: {
            data: Function
        }
    })
], CalculationModal);
export default CalculationModal;
