var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import VueRouter from "vue-router";
import { SearchService } from "services/SearchService";
import ShopReplaceAltProdButton from "./ShopReplaceAltProdButton.vue";
import ShopItemIcons from "./ShopItemIcons.vue";
import { EventTypes } from "models/EventTypes";
import ShopItemBuyButton from "./ShopItemBuyButton.vue";
import { PriceType } from "models/product/PriceType";
import { PermissionService } from "services/PermissionService";
import { PermissionLevel } from "models/user/PermissionLevel";
const router = new VueRouter({
    mode: "history"
});
let ShopItemReplaceModal = class ShopItemReplaceModal extends Vue {
    loadedTimeout = false;
    loading = false;
    data;
    amount = null;
    selectedUnit = null;
    priceType = PriceType;
    showPrice = PermissionService.hasPermission(PermissionLevel.ShowPrice);
    close() {
        this.$emit("close-modal");
    }
    mounted() {
        this.selectedUnit = this.data.AltProduct.units.find(x => x.stdUnit).name;
    }
    get getListId() {
        return SearchService.currentListId(this.$route);
    }
    get stdUnitName() {
        return this.selectedUnit;
    }
    get stdUnitDescription() {
        return this.data.AltProduct.units.find(x => x.name == this.selectedUnit)
            .description;
    }
    get price() {
        if (!this.selectedUnit)
            return null;
        let unit = this.data.AltProduct.units.find(x => x.name == this.selectedUnit);
        return unit.price;
    }
    replaceProduct() {
        setTimeout(() => {
            this.$root.$emit(EventTypes.ShopReplaceProductOnFavoriteList, this.data);
            this.close();
        }, 400);
    }
    addedProduct() {
        this.amount = null;
        this.close();
    }
    addProduct() {
        this.$refs.buyButton.addToBasket();
    }
    setProductLoading(loading) {
        this.$emit("loading", loading);
    }
    setAmount(event) {
        this.amount = event;
    }
    get priceTypeCampaign() {
        return this.data.AltProduct.units != null && this.data.AltProduct.units.find(x => x.priceType == this.priceType.CampaignPrice) != undefined;
    }
};
ShopItemReplaceModal = __decorate([
    Component({
        components: {
            ShopReplaceAltProdButton,
            ShopItemIcons,
            ShopItemBuyButton
        },
        props: {
            data: Object
        },
        router: router
    })
], ShopItemReplaceModal);
export default ShopItemReplaceModal;
