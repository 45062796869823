import { Service } from './Service';
import { ExpiryApi } from 'api/ExpiryApi';
import { ProductVM } from '../models/product/ProductVM';
export class ExpiryService extends Service {
    /*
        LIST
    */
    static getExpiryProducts() {
        return new Promise((resolve, reject) => {
            ExpiryApi.getExpiryProducts().then((inventoryRes) => {
                var result = inventoryRes.data
                    .map(x => ProductVM.fromApi(x));
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
}
