var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("p", { staticClass: "warning", class: _vm.warning.type }, [
    _c("i", { staticClass: "icon-report-problem" }),
    _c("span", [_vm._v(_vm._s(_vm.errorMessage))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }