var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm.isValidDate
      ? _c(
          "a",
          {
            staticClass: "shop-header-link shop-changeDeliveryDate",
            on: {
              click: function ($event) {
                return _vm.changeDeliveryDate()
              },
            },
          },
          [
            _c("i", { staticClass: "mdi mdi-calendar" }),
            _vm._v(
              "\n        " +
                _vm._s(_vm._f("date")(_vm.deliveryDate, "L")) +
                "\n    "
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "a",
      {
        ref: "shop-logout",
        staticClass: "shop-header-link shop-logout",
        on: {
          click: function ($event) {
            return _vm.logout($event)
          },
        },
      },
      [
        _vm._v(
          "\n        " +
            _vm._s(_vm._f("translate")("webshop_layout_header_logout")) +
            "\n    "
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }