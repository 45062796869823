var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.product && _vm.product.stock != null
    ? _c(
        "span",
        { staticClass: "shop-item-stock-status" },
        [
          _c(
            "span",
            {
              staticClass: "shop-item-stock-status-wrapper",
              on: {
                click: function ($event) {
                  _vm.titleActive = true
                },
                mouseleave: function ($event) {
                  _vm.titleActive = false
                },
              },
            },
            [
              _c(
                "svg",
                {
                  staticClass: "shop-item-stock-status-svg",
                  attrs: { viewBox: "0 0 64 64" },
                },
                [
                  _c("circle", {
                    staticClass: "circle-base",
                    attrs: { r: "25%", cx: "50%", cy: "50%" },
                  }),
                  _vm._v(" "),
                  _c("circle", {
                    staticClass: "circle-base product-stock-status-available",
                    class: {
                      "product-stock-status-unknown":
                        _vm.product.stock == -1 || _vm.product.markings.procure,
                    },
                    style: { "stroke-dasharray": _vm.getCircleRadius + " 101" },
                    attrs: { r: "25%", cx: "50%", cy: "50%" },
                  }),
                  _vm._v(" "),
                  _c("circle", {
                    attrs: { r: "43%", cx: "50%", cy: "50%", fill: "#fff" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "shop-item-stock-status-text" },
                [
                  _vm.product.stock >= 0 && !_vm.product.markings.procure
                    ? [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm.product.stock > _vm.maxValue
                                ? _vm.maxValue + "+"
                                : _vm.product.stock.toLocaleString()
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(_vm.product.basicUnit))]),
                      ]
                    : [
                        _c("span", {
                          staticClass: "mdi mdi-account",
                          attrs: {
                            title: _vm.$options.filters.translate(
                              "webshop_search_search_item_stockStatus_unknown"
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _vm.titleActive
                          ? _c("span", { staticClass: "stock-status-title" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$options.filters.translate(
                                    "webshop_search_search_item_stockStatus_unknown"
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                      ],
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _vm._t("default"),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }