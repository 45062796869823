var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "shop-productlist-thumbs shop-products shop-search-result-gallery",
    },
    [
      _vm.loading
        ? _c("loader", { attrs: { size: "large" } })
        : _c(
            "div",
            [
              _c(
                "transition",
                {
                  attrs: {
                    appear: "",
                    name: "transition-products",
                    tag: "div",
                  },
                },
                [
                  _vm.products.length > 0
                    ? [
                        _c(
                          "div",
                          { attrs: { grid: "wrap" } },
                          [
                            _vm._l(_vm.products, function (product, index) {
                              return [
                                _c(
                                  "div",
                                  {
                                    key: product.productId,
                                    staticClass: "shop-gallery-item-wrapper",
                                    attrs: { column: "xs-12 m-6 l-6 xl-4" },
                                  },
                                  [
                                    _c("shop-gallery-item", {
                                      attrs: {
                                        loading: _vm.loading,
                                        product: product,
                                        uiSource:
                                          _vm.OrderlineUISourceTypes
                                            .ShopGallery,
                                        position: index + 1,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            }),
                          ],
                          2
                        ),
                      ]
                    : _c(
                        "div",
                        [
                          _vm.searching
                            ? [
                                _vm.currentListId == null
                                  ? _c("p", { staticClass: "no-result-text" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("translate")(
                                            "webshop_search_search_result_empty_state_search"
                                          )
                                        )
                                      ),
                                    ])
                                  : _c("p", { staticClass: "no-result-text" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("translate")(
                                            "webshop_search_search_result_empty_state_favorite_list"
                                          )
                                        )
                                      ),
                                    ]),
                              ]
                            : [
                                _vm.currentListId == null
                                  ? _c("p", { staticClass: "no-result-text" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("translate")(
                                            "webshop_search_search_result_empty_state_no_products_search"
                                          )
                                        )
                                      ),
                                    ])
                                  : _c("p", { staticClass: "no-result-text" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("translate")(
                                            "webshop_search_search_result_empty_state_no_products_favorite_list"
                                          )
                                        )
                                      ),
                                    ]),
                              ],
                        ],
                        2
                      ),
                ],
                2
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }