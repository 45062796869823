var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ui-modal-content" }, [
      _c("h3", { staticClass: "ui-modal-header" }, [
        _vm._v(
          _vm._s(_vm._f("translate")("webshop_login_changePassword_header"))
        ),
      ]),
      _vm._v(" "),
      _c("div", [
        _c(
          "form",
          {
            staticClass: "ui-form",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submit.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "ui-form-fieldwrapper" }, [
              _c("label", [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_login_changePassword_label_oldPass"
                      )
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.tempForm.oldPassword,
                      expression: "tempForm.oldPassword",
                    },
                    { name: "autofocus", rawName: "v-autofocus" },
                  ],
                  attrs: {
                    type: "password",
                    required: "",
                    autocomplete: "off",
                  },
                  domProps: { value: _vm.tempForm.oldPassword },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.tempForm, "oldPassword", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "ui-form-fieldwrapper" }, [
              _c("label", [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_login_changePassword_label_newPass"
                      )
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.tempForm.newPassword,
                      expression: "tempForm.newPassword",
                    },
                  ],
                  ref: "newPass",
                  attrs: {
                    type: "password",
                    required: "",
                    minlength: "6",
                    autocomplete: "off",
                  },
                  domProps: { value: _vm.tempForm.newPassword },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.tempForm, "newPassword", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "ui-form-fieldwrapper" }, [
              _c("label", [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_login_changePassword_label_newPassRepeat"
                      )
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.tempForm.repeatNewPassword,
                      expression: "tempForm.repeatNewPassword",
                    },
                  ],
                  ref: "newPassRep",
                  attrs: {
                    type: "password",
                    required: "",
                    minlength: "6",
                    autocomplete: "off",
                  },
                  domProps: { value: _vm.tempForm.repeatNewPassword },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.tempForm,
                        "repeatNewPassword",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ui-form-submitwrapper" },
              [
                _c(
                  "a",
                  {
                    staticClass: "ui-button",
                    on: {
                      click: function ($event) {
                        return _vm.close()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm._f("translate")(
                          "webshop_login_changePassword_cancelBtn"
                        )
                      )
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("loading-button", {
                  attrs: {
                    "icon-class": "mdi-key-variant",
                    text: _vm.$options.filters.translate(
                      "webshop_login_changePassword_submitBtn"
                    ),
                    "loading-text": _vm.$options.filters.translate(
                      "webshop_login_changePassword_submitBtn_loading"
                    ),
                    loading: _vm.loading,
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }