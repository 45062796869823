var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table-outer-row" }, [
    _c(
      "div",
      {
        staticClass: "table-row calculation-line-row",
        class: {
          "shop-product-status-deleted":
            _vm.calculationLine.isItem &&
            !_vm.calculationLine.isCustomerItem &&
            (_vm.calculationLine.itemStatus == _vm.ItemStatus.Deleted ||
              _vm.calculationLine.itemStatus == _vm.ItemStatus.Inactive),
        },
      },
      [
        _c("div", { staticClass: "table-column hide-small" }, [
          _vm._v(
            _vm._s(
              _vm.calculationLine.isItem
                ? _vm.calculationLine.id
                : _vm.CalculationType[_vm.calculationLine.calculationType]
            )
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "table-column calculation-column-name item-description",
          },
          [
            _c("span", { staticClass: "item-small-productid no-fill" }, [
              _vm._v(
                _vm._s(
                  _vm.calculationLine.isItem
                    ? _vm.calculationLine.itemBrand
                    : ""
                )
              ),
            ]),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "item-small-productid no-fill" },
              [
                _vm.calculationLine.supplierProductId
                  ? [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm._f("translate")(
                              "webshop_calculations_calculationLineList_supplierNumberText"
                            )
                          ) +
                          "\n                " +
                          _vm._s(_vm.calculationLine.supplierProductId)
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            !_vm.calculationLine.isItem &&
            _vm.calculationLine.isCalculationAccessible
              ? _c(
                  "router-link",
                  {
                    staticClass: "calculation-list-link",
                    attrs: {
                      to: {
                        name: "calculationDetails",
                        params: { calculationId: _vm.calculationLine.id },
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "no-fill" }, [
                      _vm._v(_vm._s(_vm.calculationLine.name)),
                    ]),
                  ]
                )
              : _vm.calculationLine.isCustomerItem && !_vm.disableCalculation
              ? _c(
                  "a",
                  {
                    staticClass: "no-fill",
                    on: { click: _vm.editCustomerItem },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.calculationLine.name) + "\n                "
                    ),
                    _c("i", { staticClass: "mdi mdi-pencil" }),
                  ]
                )
              : _c("span", { staticClass: "no-fill" }, [
                  _vm._v(_vm._s(_vm.calculationLine.name)),
                ]),
            _vm._v(" "),
            _vm.calculationLine.isItem
              ? _c("span", { staticClass: "item-small-productid no-fill" }, [
                  _vm._v(
                    _vm._s(_vm._f("translate")("webshop_general_netWeight")) +
                      ": " +
                      _vm._s(_vm.nettoWeight) +
                      " kg"
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.calculationLine.markings
              ? [
                  _c(
                    "div",
                    [
                      _c("shop-item-icons", {
                        attrs: {
                          product: _vm.calculationLine,
                          priceTypeCampaign: _vm.priceTypeCampaign,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("highlight-markings", {
                    attrs: { itemMarkings: _vm.calculationLine.markings },
                  }),
                ]
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "table-column" }, [
          _vm.calculationLine.isItem && _vm.calculationLine.salesUnit
            ? _c("span", { staticClass: "no-fill" }, [
                _vm._v(
                  _vm._s(_vm.calculationLine.salesUnit.name) +
                    "(" +
                    _vm._s(_vm.calculationLine.salesUnit.description) +
                    ")"
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table-column column-justify-end small-third" },
          [
            _vm.calculationLine.itemStatus == _vm.ItemStatus.Outgoing
              ? _c("span", { staticClass: "inventory-item-outgoing-text" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_calculations_calculationLineList_lineStatus_outgoing"
                      )
                    )
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.calculationLine.itemStatus != _vm.ItemStatus.Deleted &&
            _vm.calculationLine.itemStatus != _vm.ItemStatus.Inactive &&
            _vm.calculationLine.isItem &&
            !_vm.calculationLine.isCustomerItem &&
            _vm.calculationLine.itemNetWeight == 0
              ? _c("span", { staticClass: "inventory-item-deleted-text" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_calculations_calculationLineList_lineStatus_noNetoweight"
                      )
                    )
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.calculationLine.isItem &&
            !_vm.calculationLine.isCustomerItem &&
            _vm.calculationLine.itemStatus == _vm.ItemStatus.Inactive &&
            !_vm.calculationLine.itemDeleted
              ? _c("span", { staticClass: "inventory-item-deleted-text" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_calculations_calculationLineList_lineStatus_inactive"
                      )
                    )
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.calculationLine.itemStatus == _vm.ItemStatus.Deleted
              ? _c("span", { staticClass: "inventory-item-deleted-text" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_calculations_calculationLineList_lineStatus_deleted"
                      )
                    )
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.calculationLine.itemDeleted
              ? _c("span", { staticClass: "inventory-item-deleted-text" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_calculations_calculationLineList_lineStatus_unknown"
                      )
                    )
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.showPrice
              ? [_vm._v(_vm._s(_vm._f("currency")(_vm.calculationLine.price)))]
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table-column item-amount-col column-justify-end" },
          [
            _c(
              "div",
              { staticClass: "input-wrapper" },
              [
                _c("i", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.invalidQuantity,
                      expression: "invalidQuantity",
                    },
                  ],
                  staticClass: "input-icon mdi mdi-alert",
                  attrs: {
                    title: _vm.$options.filters.translate(
                      "webshop_inventory_table_product_amountWarning"
                    ),
                  },
                }),
                _vm._v(" "),
                _c("i", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.quantityUpdated,
                      expression: "quantityUpdated",
                    },
                  ],
                  staticClass: "input-icon-updated mdi mdi-check",
                }),
                _vm._v(" "),
                _c("input-number", {
                  ref: "inputQuantityParent",
                  attrs: {
                    inputClass: "item-amount-input",
                    inputTabindex: "1",
                    autocomplete: "off",
                    inputDisabled: _vm.lineDisabled || _vm.disableQuantity,
                    inputStep: 0.01,
                    inputRef: "inputQuantity",
                    "select-onfocus": "true",
                  },
                  on: {
                    keyupenter: function ($event) {
                      return _vm.updateQuantity(true)
                    },
                    blur: function ($event) {
                      return _vm.updateQuantity()
                    },
                  },
                  model: {
                    value: _vm.calcLine.quantity,
                    callback: function ($$v) {
                      _vm.$set(_vm.calcLine, "quantity", $$v)
                    },
                    expression: "calcLine.quantity",
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "input-unit" }, [
                  _vm._v(
                    _vm._s(
                      _vm.calculationLine.isItem
                        ? _vm.CalculationLineUnits.Gram
                        : _vm.CalculationLineUnits.Piece
                    )
                  ),
                ]),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table-column item-amount-col column-justify-end" },
          [
            _vm.calculationLine.isItem
              ? _c(
                  "div",
                  { staticClass: "input-wrapper" },
                  [
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.invalidPercentageWasted,
                          expression: "invalidPercentageWasted",
                        },
                      ],
                      staticClass: "input-icon mdi mdi-alert",
                      attrs: {
                        title: _vm.$options.filters.translate(
                          "webshop_inventory_table_product_amountWarning"
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.percentageWastedUpdated,
                          expression: "percentageWastedUpdated",
                        },
                      ],
                      staticClass: "input-icon-updated mdi mdi-check",
                    }),
                    _vm._v(" "),
                    _c("input-number", {
                      ref: "inputPercentageWastedParent",
                      attrs: {
                        inputClass: "item-amount-input",
                        inputTabindex: "1",
                        autocomplete: "off",
                        inputDisabled:
                          _vm.lineDisabled || _vm.disablePercentageWasted,
                        "input-max": 99.999,
                        inputStep: 0.01,
                        inputRef: "inputQuantity",
                        "select-onfocus": "true",
                      },
                      on: {
                        keyupenter: function ($event) {
                          return _vm.updatePercentageWasted(true)
                        },
                        blur: function ($event) {
                          return _vm.updatePercentageWasted()
                        },
                      },
                      model: {
                        value: _vm.calcLine.percentageWasted,
                        callback: function ($$v) {
                          _vm.$set(_vm.calcLine, "percentageWasted", $$v)
                        },
                        expression: "calcLine.percentageWasted",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "input-unit" }, [_vm._v("%")]),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "table-column column-justify-end column-button-delete",
          },
          [
            !_vm.disableCalculation
              ? _c("inline-delete-button", {
                  attrs: {
                    "long-message": _vm.$options.filters.translate(
                      "webshop_calculations_calculationLineList_deleteLongText",
                      _vm.calculationLine.name
                    ),
                    "short-message": _vm.$options.filters.translate(
                      "webshop_calculations_calculationLineList_deleteShortText",
                      _vm.calculationLine.name
                    ),
                  },
                  on: { delete: _vm.deleteCalculationLine },
                })
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }