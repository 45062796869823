var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import * as _ from "lodash";
import { CalculationVM } from 'models/calculation/CalculationVM';
import { PermissionService } from 'services/PermissionService';
import { PermissionLevel } from 'models/user/PermissionLevel';
import { CalculationService } from '../../../services/CalculationService';
import InputNumber from 'components/shared/InputNumber.vue';
import { ModalService } from "modules/basic-core/basic-modal/src";
import CalculationModalAddProducts from "../modals/CalculationModalAddProducts.vue";
import CalculationModalAddCalculations from "../modals/CalculationModalAddCalculations.vue";
import CreateOrderFromCalculationModal from '../modals/CreateOrderFromCalculationModal.vue';
import ChangeCalculationName from "./ChangeCalculationName.vue";
import { ToastService } from 'services/ToastService';
import { Watch } from 'vue-property-decorator';
import { CalculationType } from 'models/calculation/CalculationType';
import { WindowService } from 'services/WindowService';
import { PdfReportService } from 'services/PdfReportService';
import { Settings } from 'config/Settings';
import ShareCalculationModal from '../modals/ShareCalculationModal.vue';
import CopyCalculationModal from '../modals/CopyCalculationModal.vue';
import CalculationRecipeModal from '../modals/CalculationRecipeModal.vue';
import PrintRecipeModal from '../modals/PrintRecipeModal.vue';
import { CalculationSearchSortBy } from 'models/search/SearchSortBy';
import { KeyValue } from 'models/utility/KeyValue';
let CalculationHeader = class CalculationHeader extends Vue {
    calculation;
    showPrice = PermissionService.hasPermission(PermissionLevel.ShowPrice);
    disableInputs = false;
    numberOfPortions = 0;
    priceToCustomer = 0;
    coverage = 0;
    grossProfit = 0;
    isShared = false;
    invalidCoverage = false;
    coverageUpdated = false;
    invalidGrossProfit = false;
    grossProfitUpdated = false;
    invalidNumberOfPortion = false;
    numberOfPortionUpdated = false;
    invalidSalesPrice = false;
    salesPriceUpdated = false;
    showChangeNameForm = false;
    CalculationType = CalculationType;
    calculationObject = new CalculationVM();
    showShareButton = PermissionService.hasPermission(PermissionLevel.EnableSharePortionPlanner);
    showSettings = false;
    selectedSortBy = CalculationSearchSortBy.Date;
    mounted() {
        this.calculationObject = _.clone(this.calculation);
        this.selectedSortBy = this.$store.state.Calculations.sortBy;
        this.updateValues();
    }
    get disableCalculation() {
        return this.calculation.customerNo != Settings.profileInfo.customerNo;
    }
    get sortByFilters() {
        return Object.keys(CalculationSearchSortBy)
            .reduce((newArray, key) => {
            if (isNaN(Number(key))) {
                let value = CalculationSearchSortBy[key];
                let label = Vue.filter('translate')('webshop_search_search_sortBy_' + key);
                newArray.push(KeyValue.create(label, value));
            }
            return newArray;
        }, []);
    }
    get averagePricePerMeal() {
        if (this.calculation.calculationLines && this.calculation.calculationLines.length > 0) {
            const countMeals = this.calculation.calculationLines
                .reduce(((acc, currentValue) => !currentValue.isItem && currentValue.calculationType == CalculationType.Maträtt ? acc + 1 : acc), 0);
            return this.calculation.priceToCustomer / (countMeals > 1 ? countMeals : 1);
        }
        return 0;
    }
    onCoverageChange(newVal, oldVal) {
        this.coverage = this.calculationObject.coverage = this.calculation.coverage;
    }
    onGrossProfitChange(newVal, oldVal) {
        this.grossProfit = this.calculationObject.grossProfit = this.calculation.grossProfit;
    }
    updateValues() {
        setTimeout(() => {
            this.priceToCustomer = this.calculationObject.priceToCustomer = this.calculation.priceToCustomer;
            this.numberOfPortions = this.calculationObject.numberOfPortions;
            this.coverage = this.calculationObject.coverage = this.calculation.coverage;
            this.grossProfit = this.calculationObject.grossProfit = this.calculation.grossProfit;
            this.isShared = this.calculationObject.isShared;
        }, 0);
    }
    updateCalculation() {
        if (this.disableInputs) {
            return;
        }
        this.disableInputs = true;
        CalculationService.updateCalculation(this.calculationObject).then(res => {
            this.$store.dispatch('Calculations/updateSelectedCalculation', res);
            this.updateValues();
        }).catch(err => {
            ToastService.danger(Vue.filter("translate")("webshop_calculations_calculationList_toast_errorOnUpdate"));
        }).finally(() => {
            this.disableInputs = false;
        });
    }
    updateCoverage() {
        if (this.disableInputs || this.coverage === this.calculationObject.coverage) {
            return;
        }
        this.disableInputs = true;
        CalculationService.updateCalculationCoverage(this.calculationObject).then(res => {
            this.$store.dispatch('Calculations/updateSelectedCalculation', res);
            this.updateValues();
            this.coverageUpdated = true;
            setTimeout(() => { this.coverageUpdated = false; }, 2000);
        }).catch(err => {
            this.calculationObject.coverage = this.coverage;
            this.invalidCoverage = true;
            setTimeout(() => { this.invalidCoverage = false; }, 2000);
        }).finally(() => {
            this.disableInputs = false;
        });
    }
    updateGrossProfit() {
        if (this.disableInputs || this.grossProfit === this.calculationObject.grossProfit) {
            return;
        }
        this.disableInputs = true;
        CalculationService.updateCalculationCoverage(this.calculationObject, true).then(res => {
            this.$store.dispatch('Calculations/updateSelectedCalculation', res);
            this.updateValues();
            this.grossProfitUpdated = true;
            setTimeout(() => { this.grossProfitUpdated = false; }, 2000);
        }).catch(err => {
            this.calculationObject.grossProfit = this.grossProfit;
            this.invalidGrossProfit = true;
            setTimeout(() => { this.invalidGrossProfit = false; }, 2000);
        }).finally(() => {
            this.disableInputs = false;
        });
    }
    updateSalesPrice() {
        if (this.disableInputs || this.priceToCustomer === this.calculationObject.priceToCustomer) {
            return;
        }
        this.disableInputs = true;
        CalculationService.updateCalculation(this.calculationObject).then(res => {
            this.$store.dispatch('Calculations/updateSelectedCalculation', res);
            this.updateValues();
            this.salesPriceUpdated = true;
            setTimeout(() => { this.salesPriceUpdated = false; }, 2000);
        }).catch(err => {
            this.calculationObject.priceToCustomer = this.priceToCustomer;
            this.invalidSalesPrice = true;
            setTimeout(() => { this.invalidSalesPrice = false; }, 2000);
        }).finally(() => {
            this.disableInputs = false;
        });
    }
    updateNumberOfPortion() {
        if (this.disableInputs || this.numberOfPortions === this.calculationObject.numberOfPortions) {
            return;
        }
        this.disableInputs = true;
        CalculationService.updateCalculation(this.calculationObject).then(res => {
            this.$store.dispatch('Calculations/updateSelectedCalculation', res);
            this.updateValues();
            this.numberOfPortionUpdated = true;
            setTimeout(() => { this.numberOfPortionUpdated = false; }, 2000);
        }).catch(err => {
            this.calculationObject.numberOfPortions = this.numberOfPortions;
            this.invalidNumberOfPortion = true;
            setTimeout(() => { this.invalidNumberOfPortion = false; }, 2000);
        }).finally(() => {
            this.disableInputs = false;
        });
    }
    changeCalculationName() {
        if (!this.disableInputs)
            this.showChangeNameForm = true;
    }
    addProductLines() {
        ModalService.create({
            cxt: this,
            component: CalculationModalAddProducts,
            maxWidth: "720px",
            data: this.$store.state.Calculations.selectedCalculation
        });
    }
    addCalculationLines() {
        ModalService.create({
            cxt: this,
            component: CalculationModalAddCalculations,
            maxWidth: "720px",
            data: this.$store.state.Calculations.selectedCalculation
        });
    }
    createOrderFromCalculation() {
        ModalService.create({
            cxt: this,
            component: CreateOrderFromCalculationModal,
            maxWidth: "900px",
            data: this.$store.state.Calculations.selectedCalculation
        });
        this.toggleSettings();
    }
    printCalculation() {
        if (this.disableInputs)
            return;
        this.disableInputs = true;
        const newWindow = WindowService.initializeNewWindow();
        PdfReportService.printCalculations([this.calculation.calculationId]).then(res => {
            const file = new Blob([res], { type: "application/pdf" });
            const nav = window.navigator;
            if (window.navigator && nav.msSaveOrOpenBlob) {
                nav.msSaveOrOpenBlob(file, 'Kalkyler.pdf');
                setTimeout(() => {
                    newWindow.close();
                }, 100);
            }
            else {
                const reportLink = window.URL.createObjectURL(file);
                newWindow.location = reportLink;
            }
            this.disableInputs = false;
            //this.loading = false;
        }, err => {
            setTimeout(() => {
                newWindow.close();
                if (err === 416) {
                    ToastService.danger(Vue.filter("translate")("webshop_inventory_no_products"));
                }
                else {
                    ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
                }
            }, 500);
            this.disableInputs = false;
            //this.loading = false;
            return;
        });
        this.toggleSettings();
    }
    shareCalculation() {
        ModalService.create({
            cxt: this,
            component: ShareCalculationModal,
            maxWidth: "720px",
            data: this.$store.state.Calculations.selectedCalculation
        });
        this.toggleSettings();
    }
    copyCalculation() {
        let thisRef = this;
        ModalService.create({
            cxt: this,
            component: CopyCalculationModal,
            maxWidth: "720px",
            data: {
                calculation: this.$store.state.Calculations.selectedCalculation,
                confirmFunction: (id) => {
                    thisRef.$router.push({ name: 'calculationDetails', params: { calculationId: id } });
                }
            }
        });
        this.toggleSettings();
    }
    openRecipeModal() {
        let data = this.$store.state.Calculations.selectedCalculation;
        ModalService.create({
            cxt: this,
            component: CalculationRecipeModal,
            maxWidth: "720px",
            data: data
        });
        this.toggleSettings();
    }
    toggleSettings() {
        this.showSettings = !this.showSettings;
        if (this.showSettings) {
            document.body.addEventListener("click", this.clickOutsideSettings);
        }
        else {
            document.body.removeEventListener("click", this.clickOutsideSettings);
        }
    }
    clickOutsideSettings(el) {
        const wrapper = this.$refs["inventory-settings-container"];
        if (!wrapper || wrapper.contains(el.target))
            return;
        this.toggleSettings();
    }
    printRecipe() {
        let data = this.$store.state.Calculations.selectedCalculation;
        ModalService.create({
            cxt: this,
            component: PrintRecipeModal,
            maxWidth: "450px",
            data: data
        });
        this.toggleSettings();
    }
    changeSortBy() {
        this.$store.dispatch("Calculations/changeSortBy", this.selectedSortBy);
    }
};
__decorate([
    Watch('calculation.coverage')
], CalculationHeader.prototype, "onCoverageChange", null);
__decorate([
    Watch('calculation.grossProfit')
], CalculationHeader.prototype, "onGrossProfitChange", null);
CalculationHeader = __decorate([
    Component({
        props: {
            calculation: Object,
            setLoader: Function
        },
        components: {
            ChangeCalculationName,
            InputNumber
        }
    })
], CalculationHeader);
export default CalculationHeader;
