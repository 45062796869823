var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ui-modal-content alco-modal" }, [
      _c("h3", { staticClass: "ui-modal-header" }, [
        _vm._v(_vm._s(_vm._f("translate")("webshop_myPage_alcohol_report"))),
      ]),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "ui-form",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.generateReport()
            },
          },
        },
        [
          _c("div", { staticClass: "ui-form-datepickerwrapper" }, [
            _c(
              "div",
              {
                staticClass: "date-choice-wrapper",
                attrs: { grid: "justify-between" },
              },
              [
                _c(
                  "div",
                  { attrs: { column: "" } },
                  [
                    _c("label", [
                      _vm._v(
                        _vm._s(
                          _vm._f("translate")(
                            "webshop_favorite_createList_byDate_fromDate"
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("flat-pickr", {
                      attrs: {
                        disabled: _vm.loading,
                        required: "true",
                        config: _vm.dpSettings,
                      },
                      model: {
                        value: _vm.fromDate,
                        callback: function ($$v) {
                          _vm.fromDate = $$v
                        },
                        expression: "fromDate",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { attrs: { column: "" } },
                  [
                    _c("label", [
                      _vm._v(
                        _vm._s(
                          _vm._f("translate")(
                            "webshop_favorite_createList_byDate_toDate"
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("flat-pickr", {
                      attrs: {
                        disabled: _vm.loading,
                        config: _vm.dpSettings,
                        required: "true",
                      },
                      model: {
                        value: _vm.toDate,
                        callback: function ($$v) {
                          _vm.toDate = $$v
                        },
                        expression: "toDate",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("loading-button", {
            staticClass: "loading-button",
            attrs: {
              "icon-class": "mdi-download",
              text: _vm.$options.filters.translate(
                "webshop_myPage_alcohol_generate_report"
              ),
              "loading-text": _vm.$options.filters.translate(
                "webshop_myPage_alcohol_generate_report_loading"
              ),
              loading: _vm.loading,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }