var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { SettingService } from 'services/SettingService';
import { AuthService } from 'services/AuthService';
import { CookieService } from 'services/CookieService';
import { ToastService } from 'services/ToastService';
import LoadingButton from '../../shared/LoadingButton.vue';
import { getParameterByName } from "helpers/QueryParameter";
let LoginFormPartial = class LoginFormPartial extends Vue {
    loading = false;
    newCustomerLink;
    rememberMe = false;
    showError = false;
    username = null;
    password = null;
    mounted() {
        const settings = SettingService.getAll();
        if (settings && typeof (settings.webshopCookie) != 'undefined') {
            const cookie = CookieService.get(settings.webshopCookie);
            if (cookie) {
                const userIndex = cookie.search('username=');
                const passIndex = cookie.search('password=');
                const username = userIndex > -1 ? cookie.substring(userIndex + 9, passIndex - 1) : null;
                const password = passIndex > -1 ? cookie.substring(passIndex + 9, cookie.length) : null;
                if (username && password) {
                    this.username = decodeURIComponent(username);
                    if (password.length >= 1)
                        this.password = '*******';
                    this.rememberMe = true;
                }
                ;
            }
            ;
        }
    }
    login() {
        if (!this.username || !this.password) {
            ToastService.danger(Vue.filter('translate')('webshop_login_submitToast_emptyFields'));
            return;
        }
        this.loading = true;
        this.showError = false;
        AuthService.login(this.username, this.password, this.rememberMe)
            .then(() => {
            //this.loading = false;
            let usernameParts = this.username.split("@");
            if (usernameParts.length == 1 && usernameParts[0].toLowerCase().startsWith('l')
                || usernameParts.length == 2 && usernameParts[1].toLowerCase().startsWith('l')) {
                let returnUrl = getParameterByName('ReturnUrl');
                if (!returnUrl || !returnUrl.startsWith('/leverantoer'))
                    returnUrl = '/leverantoer';
                window.location.href = returnUrl;
            }
            else {
                this.$emit('onComplete');
            }
        })
            .catch(err => {
            if (err === 401) {
                this.showError = true;
                this.password = "";
            }
            else {
                ToastService.danger(Vue.filter('translate')('webshop_defaults_toast_errorText'));
            }
            this.loading = false;
        });
    }
};
LoginFormPartial = __decorate([
    Component({
        components: {
            LoadingButton
        },
        props: {
            newCustomerLink: String
        }
    })
], LoginFormPartial);
export default LoginFormPartial;
