var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ItemSurchargeWarning_1;
import { StorageService } from 'modules/basic-core/basic-storage/src/storage/StorageService';
import { SearchViewTypes } from 'models/search/SearchViewTypes';
import Vue from "vue";
import Component from "vue-class-component";
let ItemSurchargeWarning = ItemSurchargeWarning_1 = class ItemSurchargeWarning extends Vue {
    static viewStorageKey = 'searchResultType';
    showWarningMsg = false;
    suggestedAmount;
    viewTypes = SearchViewTypes;
    viewType = SearchViewTypes.gallery;
    mounted() {
        StorageService.getItem(ItemSurchargeWarning_1.viewStorageKey)
            .then(x => {
            this.viewType = x;
        }).catch(err => { });
    }
};
ItemSurchargeWarning = ItemSurchargeWarning_1 = __decorate([
    Component({
        components: {},
        props: {
            suggestedAmount: Number
        }
    })
], ItemSurchargeWarning);
export default ItemSurchargeWarning;
