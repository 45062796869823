var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ui-modal-content" }, [
      _vm.data.isNew
        ? _c("h3", { staticClass: "ui-modal-header" }, [
            _vm._v(
              _vm._s(
                _vm._f("translate")(
                  "webshop_vendor_campaignPlanning_campaignLines_modalCampaignLine_title"
                )
              )
            ),
          ])
        : _c("h3", { staticClass: "ui-modal-header" }, [
            _vm._v(
              _vm._s(
                _vm._f("translate")(
                  "webshop_vendor_campaignPlanning_campaignLines_modalCampaignLine_titleEdit"
                )
              )
            ),
          ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.loading
            ? [_c("loader", { attrs: { size: "large" } })]
            : [
                _c("div", { staticClass: "ui-form", attrs: { grid: "wrap" } }, [
                  _c("div", { attrs: { column: "xs-12" } }, [
                    _c("div", { attrs: { grid: "wrap" } }, [
                      _c("div", { attrs: { column: "xs-6" } }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("translate")(
                              "webshop_vendor_campaignPlanning_campaignLines_modalCampaignLine_campaignCodeLabel"
                            )
                          ) + "\n                                "
                        ),
                        _c("b", [
                          _vm._v(_vm._s(_vm.data.campaignLine.campaignCode)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "_xs-text-right",
                          attrs: { column: "xs-6" },
                        },
                        [
                          _c("a", {
                            staticClass: "icon-info show-foodinfo tooltip",
                            attrs: {
                              title: _vm.$options.filters.translate(
                                "webshop_vendor_campaignPlanning_campaignLines_modalCampaignLine_info"
                              ),
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { attrs: { column: "xs-12" } }, [
                    _c("div", { attrs: { grid: "wrap" } }, [
                      _c(
                        "div",
                        {
                          staticClass: "ui-form-fieldwrapper",
                          attrs: { column: "xs-6" },
                        },
                        [
                          _c("label", { attrs: { for: "salesPeriod" } }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("translate")(
                                    "webshop_vendor_campaignPlanning_salesPeriod"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.salesWeek,
                                  expression: "salesWeek",
                                },
                              ],
                              attrs: {
                                type: "text",
                                autocomplete: "off",
                                name: "salesPeriod",
                                disabled: true,
                              },
                              domProps: { value: _vm.salesWeek },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.salesWeek = $event.target.value
                                },
                              },
                            }),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "ui-form-fieldwrapper",
                          attrs: { column: "xs-6" },
                        },
                        [
                          _c("label", { attrs: { for: "purchasePeriod" } }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("translate")(
                                    "webshop_vendor_campaignPlanning_purchasePeriod"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.purchaseWeek,
                                  expression: "purchaseWeek",
                                },
                              ],
                              attrs: {
                                type: "text",
                                autocomplete: "off",
                                name: "purchasePeriod",
                                disabled: true,
                              },
                              domProps: { value: _vm.purchaseWeek },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.purchaseWeek = $event.target.value
                                },
                              },
                            }),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    staticClass: "ui-form",
                    attrs: { name: "campaignPlanningLineModal" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submitForm.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { attrs: { grid: "wrap" } }, [
                      _c("div", { attrs: { column: "xs-12 s-6" } }, [
                        _c("div", { staticClass: "ui-form-fieldwrapper" }, [
                          _c("div", { attrs: { grid: "wrap" } }, [
                            _c("div", { attrs: { column: "xs-6" } }, [
                              _c(
                                "label",
                                { attrs: { for: "product" } },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("translate")(
                                          "webshop_vendor_campaignPlanning_campaignLines_modalCampaignLine_columnItem"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("input-select", {
                                    attrs: {
                                      list: _vm.vendorProducts,
                                      placeholder:
                                        _vm.$options.filters.translate(
                                          "webshop_vendor_campaignPlanning_campaignLines_modalCampaignLine_columnItem"
                                        ),
                                      searchBy: [
                                        "supplierProductId",
                                        "description",
                                      ],
                                      displayProperties: [
                                        "supplierProductId",
                                        "description",
                                      ],
                                      listKeys: [
                                        "productId",
                                        "supplierProductId",
                                      ],
                                      disabled: !_vm.data.isNew,
                                    },
                                    on: {
                                      select: function ($event) {
                                        return _vm.selectProduct()
                                      },
                                    },
                                    model: {
                                      value: _vm.selectedProduct,
                                      callback: function ($$v) {
                                        _vm.selectedProduct = $$v
                                      },
                                      expression: "selectedProduct",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { attrs: { column: "xs-6" } }, [
                              _c("label", { attrs: { for: "itemNo" } }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("translate")(
                                        "webshop_vendor_campaignPlanning_campaignLines_columnItemNo"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.data.campaignLine.itemNo,
                                      expression: "data.campaignLine.itemNo",
                                    },
                                  ],
                                  attrs: {
                                    type: "text",
                                    autocomplete: "off",
                                    name: "itemNo",
                                    disabled: true,
                                    tabindex: "2",
                                  },
                                  domProps: {
                                    value: _vm.data.campaignLine.itemNo,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.data.campaignLine,
                                        "itemNo",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "ui-form-fieldwrapper" }, [
                          _c(
                            "label",
                            {
                              class: {
                                "input-required-field":
                                  _vm.errorVendorProductId,
                              },
                              attrs: { for: "vendorItemNo" },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("translate")(
                                      "webshop_vendor_campaignPlanning_campaignLines_columnVendorItemNo"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.data.campaignLine.vendorItemNo,
                                    expression:
                                      "data.campaignLine.vendorItemNo",
                                  },
                                ],
                                attrs: {
                                  type: "text",
                                  maxlength: "20",
                                  autocomplete: "off",
                                  name: "vendorItemNo",
                                  disabled:
                                    (_vm.selectedProduct &&
                                      _vm.selectedProduct.productId) ||
                                    !_vm.data.isNew,
                                  tabindex: "3",
                                },
                                domProps: {
                                  value: _vm.data.campaignLine.vendorItemNo,
                                },
                                on: {
                                  change: _vm.changeVendorProductId,
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.data.campaignLine,
                                      "vendorItemNo",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "ui-form-fieldwrapper" }, [
                          _c("label", { attrs: { for: "description" } }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("translate")(
                                    "webshop_vendor_campaignPlanning_campaignLines_columnName"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.data.campaignLine.description,
                                  expression: "data.campaignLine.description",
                                },
                              ],
                              attrs: {
                                type: "text",
                                maxlength: "30",
                                autocomplete: "off",
                                name: "description",
                                disabled:
                                  (_vm.selectedProduct &&
                                    _vm.selectedProduct.productId) ||
                                  _vm.disabledByLineStatus,
                                tabindex: "4",
                              },
                              domProps: {
                                value: _vm.data.campaignLine.description,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.data.campaignLine,
                                    "description",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "ui-form-fieldwrapper" }, [
                          _c(
                            "label",
                            {
                              class: { "input-required-field": _vm.errorUnit },
                              attrs: { for: "unit" },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("translate")(
                                      "webshop_vendor_campaignPlanning_campaignLines_columnUnit"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedUnit,
                                      expression: "selectedUnit",
                                    },
                                  ],
                                  attrs: {
                                    name: "unit",
                                    disabled:
                                      _vm.units == undefined ||
                                      _vm.units.length == 0 ||
                                      _vm.disabledByLineStatus ||
                                      _vm.enablePriceChange ||
                                      _vm.data.campaignLine.itemNo != "",
                                    tabindex: "5",
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.selectedUnit = $event.target
                                          .multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      function ($event) {
                                        return _vm.selectUnit()
                                      },
                                    ],
                                  },
                                },
                                _vm._l(_vm.units, function (unit) {
                                  return _c(
                                    "option",
                                    {
                                      key: "productUnit" + unit.name,
                                      domProps: { value: unit },
                                    },
                                    [_vm._v(_vm._s(unit.name))]
                                  )
                                }),
                                0
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { attrs: { column: "xs-12 s-6" } }, [
                        _c("div", { staticClass: "ui-form-fieldwrapper" }, [
                          _c(
                            "label",
                            { attrs: { for: "campaignUnitCost" } },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("translate")(
                                      "webshop_vendor_campaignPlanning_campaignLines_columnOrdinaryPrice"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.data.campaignLine.itemNo != ""
                                ? _c("input-number", {
                                    attrs: {
                                      value: _vm.data.campaignLine.unitCost,
                                      name: "campaignUnitCost",
                                      autocomplete: "off",
                                      decimalFormat: 0.2,
                                      inputDisabled: true,
                                    },
                                  })
                                : _c("input", {
                                    attrs: {
                                      type: "text",
                                      autocomplete: "off",
                                      name: "campaignUnitCost",
                                      disabled: true,
                                    },
                                    domProps: {
                                      value: _vm.$options.filters.translate(
                                        "webshop_vendor_campaignPlanning_campaignLines_modalCampaignLine_waitingRegistrationText"
                                      ),
                                    },
                                  }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "ui-form-fieldwrapper" }, [
                          _c("label", { attrs: { for: "campaignDiscount" } }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("translate")(
                                    "webshop_vendor_campaignPlanning_campaignLines_columnDiscount"
                                  )
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { attrs: { grid: "wrap" } }, [
                              _c("div", { attrs: { column: "xs-6" } }, [
                                _c(
                                  "div",
                                  { staticClass: "discount-wrapper" },
                                  [
                                    _c("input-number", {
                                      attrs: {
                                        name: "campaignDiscount",
                                        inputTabindex: "6",
                                        autocomplete: "off",
                                        decimalFormat: 0.2,
                                        inputStep: 0.01,
                                        "select-onfocus": "true",
                                        inputDisabled: _vm.disabledByLineStatus,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.updateDiscountLcy()
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.data.campaignLine
                                            .campaignDiscountPct,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.data.campaignLine,
                                            "campaignDiscountPct",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "data.campaignLine.campaignDiscountPct",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("%")]),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { attrs: { column: "xs-6" } }, [
                                _c(
                                  "div",
                                  { staticClass: "discount-wrapper" },
                                  [
                                    _c("input-number", {
                                      attrs: {
                                        name: "campaignDiscount",
                                        inputTabindex: "7",
                                        autocomplete: "off",
                                        decimalFormat: 0.2,
                                        inputStep: 0.01,
                                        "select-onfocus": "true",
                                        inputDisabled: _vm.disabledByLineStatus,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.updateDiscountPct()
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.data.campaignLine
                                            .campaignDiscountLcy,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.data.campaignLine,
                                            "campaignDiscountLcy",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "data.campaignLine.campaignDiscountLcy",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Kr")]),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "ui-form-fieldwrapper" }, [
                          _c(
                            "label",
                            { attrs: { for: "priceWithDiscount" } },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("translate")(
                                      "webshop_vendor_campaignPlanning_campaignLines_columnCampaignPrice"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.data.campaignLine.itemNo != ""
                                ? _c("input-number", {
                                    attrs: {
                                      value:
                                        _vm.data.campaignLine.campaignUnitCost,
                                      name: "priceWithDiscount",
                                      autocomplete: "off",
                                      decimalFormat: 0.2,
                                      inputDisabled: true,
                                    },
                                  })
                                : _c("input", {
                                    attrs: {
                                      type: "text",
                                      autocomplete: "off",
                                      name: "priceWithDiscount",
                                      disabled: true,
                                    },
                                    domProps: {
                                      value: _vm.$options.filters.translate(
                                        "webshop_vendor_campaignPlanning_campaignLines_modalCampaignLine_waitingRegistrationText"
                                      ),
                                    },
                                  }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        !_vm.data.isNew
                          ? _c("div", { staticClass: "ui-form-fieldwrapper" }, [
                              _c(
                                "label",
                                { attrs: { for: "campaignUnitPrice" } },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("translate")(
                                          "webshop_vendor_campaignPlanning_campaignLines_columnOutPrice"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.data.campaignLine.comparisonPrice
                                        )
                                      ) +
                                        " / " +
                                        _vm._s(
                                          _vm.data.campaignLine.comparisonUnit
                                        )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.data.campaignLine
                                            .campaignUnitPrice
                                        )
                                      ) +
                                        " / " +
                                        _vm._s(_vm.selectedUnit.name)
                                    ),
                                  ]),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { attrs: { column: "xs-12" } }, [
                        _c("label", { attrs: { for: "comment" } }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_vendor_campaignPlanning_campaignLines_modalCampaignLine_commentLabel"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.comment,
                                expression: "comment",
                              },
                            ],
                            staticClass: "comment-text",
                            attrs: {
                              name: "comment",
                              rows: "2",
                              maxlength: "200",
                              placeholder: _vm.$options.filters.translate(
                                "webshop_vendor_campaignPlanning_campaignLines_modalCampaignLine_commentPlaceholder"
                              ),
                            },
                            domProps: { value: _vm.comment },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.comment = $event.target.value
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "modal-buttons-wrapper" },
                      [
                        !_vm.disabledByLineStatus || _vm.enablePriceChange
                          ? _c("loading-button", {
                              attrs: {
                                "icon-class": "mdi-content-save",
                                text: _vm.$options.filters.translate(
                                  "webshop_vendor_campaignPlanning_campaignLines_modalCampaignLine_buttonSave"
                                ),
                                "loading-text": _vm.$options.filters.translate(
                                  "webshop_vendor_campaignPlanning_campaignLines_modalCampaignLine_buttonSave"
                                ),
                                loading: _vm.loading,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ],
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }