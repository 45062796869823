var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import 'filters/DateFilter';
import BasketOrderline from 'components/basket/BasketOrderline.vue';
import { EventTypes } from 'models/EventTypes';
import { ModalService } from "modules/basic-core/basic-modal/src";
import BasketConfirmDeleteModal from 'components/basket/BasketconfirmDeleteModal.vue';
import CheckOutBasketRender from 'components/check-out/CheckOutBasketRender.vue';
import IconTrash from '../shared/IconTrash.vue';
import { PermissionService } from 'services/PermissionService';
import { PermissionLevel } from 'models/user/PermissionLevel';
import { GAService } from 'services/GAService';
import { UpdateOrderLineVM } from 'models/order/UpdateOrderLineVM';
let BasketOrderPart = class BasketOrderPart extends Vue {
    order;
    loading = false;
    showPrice = PermissionService.hasPermission(PermissionLevel.ShowPrice);
    emptyBasket() {
        this.loading = true;
        let products = this.order.orderlines.filter((x) => x.lineprice >= 0).map(x => {
            let orderline = new UpdateOrderLineVM(x.lineNo, x.productId, x.amount, x.unit, '', x.description);
            return orderline;
        });
        GAService.removeProductFromBasket(products);
        this.$root.$emit(EventTypes.BasketUpdate);
    }
    onDestroy() {
        this.$root.$off(EventTypes.BasketModalDeleteConfirm, this.emptyBasket);
    }
    handleEmptyBasket() {
        ModalService.create({
            'cxt': this,
            'component': BasketConfirmDeleteModal,
            'maxWidth': '450px',
            'data': this.order.deliveryDate
        });
        this.$root.$once(EventTypes.BasketModalDeleteConfirm, this.emptyBasket);
    }
};
BasketOrderPart = __decorate([
    Component({
        components: {
            BasketOrderline,
            CheckOutBasketRender,
            IconTrash
        },
        props: {
            order: Object,
        }
    })
], BasketOrderPart);
export default BasketOrderPart;
