export var PermissionLevel;
(function (PermissionLevel) {
    PermissionLevel[PermissionLevel["None"] = 0] = "None";
    PermissionLevel[PermissionLevel["EnableOrder"] = 1] = "EnableOrder";
    PermissionLevel[PermissionLevel["ShowPrice"] = 2] = "ShowPrice";
    PermissionLevel[PermissionLevel["ShowAvailableQuantity"] = 4] = "ShowAvailableQuantity";
    PermissionLevel[PermissionLevel["EnableInvoiceCopies"] = 8] = "EnableInvoiceCopies";
    PermissionLevel[PermissionLevel["EnableInventory"] = 16] = "EnableInventory";
    PermissionLevel[PermissionLevel["EnablePortionPlanner"] = 32] = "EnablePortionPlanner";
    PermissionLevel[PermissionLevel["EnableStatistics"] = 64] = "EnableStatistics";
    //TODO New permissions needed creation in DB
    PermissionLevel[PermissionLevel["EnableBasket"] = 128] = "EnableBasket";
    PermissionLevel[PermissionLevel["EnableFavoriteList"] = 256] = "EnableFavoriteList";
    PermissionLevel[PermissionLevel["EnableShareFavoriteList"] = 512] = "EnableShareFavoriteList";
    PermissionLevel[PermissionLevel["EnableSharePortionPlanner"] = 1024] = "EnableSharePortionPlanner";
})(PermissionLevel || (PermissionLevel = {}));
