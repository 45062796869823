var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ui-modal-content" }, [
      _c("h3", { staticClass: "ui-modal-header" }, [
        _vm._v(_vm._s(_vm._f("translate")("webshop_deposit_modal_header"))),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n            " +
            _vm._s(_vm._f("translate")("webshop_deposit_modal_description")) +
            "\n        "
        ),
      ]),
      _vm._v(" "),
      _c("div", [
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.addProducts()
              },
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "div-table orderline-table deposit-product-table",
              },
              [
                _c(
                  "div",
                  { staticClass: "table-body" },
                  _vm._l(_vm.products, function (item, $index) {
                    return _c("deposit-product-line", {
                      key: $index,
                      attrs: { "deposit-product": item },
                      on: { "update-quantity": _vm.onUpdateQuantity },
                    })
                  }),
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ui-form-submitwrapper" }, [
              _c(
                "button",
                { staticClass: "ui-button", attrs: { type: "submit" } },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm._f("translate")("webshop_deposit_modal_submit")
                      ) +
                      "\n                    "
                  ),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }