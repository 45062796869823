var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import ShopGalleryItem from "components/shop/partials/ShopGalleryItem.vue";
import Spinner from "components/shared/Spinner.vue";
import { SearchService } from "services/SearchService";
import { OrderlineUISourceTypes } from 'models/order/OrderlineUISourceTypes';
let ShopResultGalleryPopularProducts = class ShopResultGalleryPopularProducts extends Vue {
    loading;
    popularProducts;
    //showPopularProducts: Boolean = true;
    showAllPopularProducts = false;
    OrderlineUISourceTypes = OrderlineUISourceTypes;
    get hasPopularProducts() {
        return this.popularProducts.length > 0;
    }
    //togglePopularProducts() {
    //    this.showPopularProducts = !this.showPopularProducts;
    //}
    get currentListId() {
        return SearchService.currentListId(this.$route);
    }
    get searching() {
        return SearchService.isSearching(this.$route);
    }
    get displayedProducts() {
        if (!this.showAllPopularProducts && this.popularProducts.length > 3)
            return this.popularProducts.slice(0, 3);
        return this.popularProducts;
    }
    showMorePopularProducts() {
        this.showAllPopularProducts = !this.showAllPopularProducts;
    }
};
ShopResultGalleryPopularProducts = __decorate([
    Component({
        components: {
            ShopGalleryItem,
            Spinner
        },
        props: {
            loading: Boolean,
            popularProducts: Array
        }
    })
], ShopResultGalleryPopularProducts);
export default ShopResultGalleryPopularProducts;
