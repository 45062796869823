var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-outer-row", class: { active: _vm.order.active } },
    [
      _c("div", { staticClass: "table-row" }, [
        _c("div", { staticClass: "table-column" }, [
          _c("span", [
            _vm._v(
              _vm._s(
                _vm._f("translate")("webshop_orders_result_columnNames_name")
              ) + ":"
            ),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.order.name))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "table-column column-order-active" }, [
          _c("span", [
            _vm._v(
              _vm._s(
                _vm._f("translate")("webshop_orders_result_columnNames_active")
              ) + ":"
            ),
          ]),
          _vm._v(" "),
          _c(
            "span",
            [
              !_vm.order.active
                ? _c(
                    "button",
                    {
                      staticClass: "ui-button",
                      on: {
                        click: function ($event) {
                          return _vm.setActiveOrder()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm._f("translate")(
                            "webshop_orders_buttons_setActive"
                          )
                        )
                      ),
                    ]
                  )
                : [
                    _vm._v(
                      _vm._s(
                        _vm._f("translate")(
                          "webshop_orders_result_columnNames_active"
                        )
                      )
                    ),
                  ],
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table-column column-order-actions" },
          [
            _c("i", {
              staticClass: "mdi mdi-pencil",
              on: {
                click: function ($event) {
                  return _vm.editOrder()
                },
              },
            }),
            _vm._v(" "),
            !_vm.order.active
              ? _c("icon-trash", {
                  class: "svg-trash-fixed-with",
                  on: {
                    click: function ($event) {
                      return _vm.deleteOrder()
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }