var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import ShopMultipleProductsFooter from "./ShopMultipleProductsFooter.vue";
import { EventTypes } from "models/EventTypes";
import { SearchService } from "services/SearchService";
let DepictMultipleProductsFooter = class DepictMultipleProductsFooter extends Vue {
    itemsWithAmount = [];
    created() {
        this.$root.$on(EventTypes.ShopAddedProductsToBasket, this.emptyArrayWithAmountItems);
        this.$root.$on(EventTypes.ShopMultipleItemsItemAdded, this.addItemAmount);
        this.$root.$on(EventTypes.ShopMultipleItemsItemRemoved, this.removeItemAmount);
    }
    beforeDestroy() {
        this.$root.$off(EventTypes.ShopAddedProductsToBasket);
        this.$root.$off(EventTypes.ShopMultipleItemsItemAdded);
        this.$root.$off(EventTypes.ShopMultipleItemsItemRemoved);
    }
    emptyArrayWithAmountItems() {
        this.itemsWithAmount.forEach(x => {
            x.product.inBasket = true;
            x.product.clearAmount = true;
            x.product.addedAmount = null;
        });
        if (window.depict && typeof window.depict.reloadAddedProductsToBasket == 'function')
            window.depict.reloadAddedProductsToBasket(this.itemsWithAmount);
        this.itemsWithAmount = [];
        SearchService.itemsWithAmount = this.itemsWithAmount;
    }
    addItemAmount(event) {
        if (!event.amount || event.amount <= 0) {
            this.removeItemAmount(event.product.productId);
        }
        else {
            const index = this.itemsWithAmount.findIndex(x => x.product.productId == event.product.productId);
            if (index > -1) {
                this.itemsWithAmount[index].amount = event.amount;
            }
            else {
                this.itemsWithAmount.push(event);
            }
        }
        SearchService.itemsWithAmount = this.itemsWithAmount;
    }
    removeItemAmount(event) {
        const index = this.itemsWithAmount.findIndex(x => x.product.productId == event);
        if (index > -1) {
            this.itemsWithAmount.splice(index, 1);
        }
    }
};
DepictMultipleProductsFooter = __decorate([
    Component({
        components: {
            ShopMultipleProductsFooter
        }
    })
], DepictMultipleProductsFooter);
export default DepictMultipleProductsFooter;
