var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "order-select" }, [
    _c("i", {
      staticClass: "mdi mdi-chevron-down",
      on: {
        click: function ($event) {
          _vm.dropdownActive = !_vm.dropdownActive
          $event.preventDefault()
        },
      },
    }),
    _vm._v(" "),
    _vm.dropdownActive && !_vm.loading
      ? _c(
          "ul",
          {
            staticClass: "order-select-list",
            on: {
              click: function ($event) {
                return $event.preventDefault()
              },
              mouseleave: function ($event) {
                _vm.dropdownActive = false
              },
            },
          },
          _vm._l(_vm.orders, function (order) {
            return _c(
              "li",
              {
                key: order.orderNo,
                class: { "shop-minicart-active-order": order.active },
                on: {
                  click: function ($event) {
                    return _vm.setActiveOrder(order)
                  },
                },
              },
              [_c("span", [_vm._v(_vm._s(order.name ? order.name : ""))])]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }