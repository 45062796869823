var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.pagination.length > 1
    ? _c("div", { staticClass: "shop-pagination" }, [
        _c(
          "ul",
          [
            _c("li", { class: { disabled: _vm.input.page <= 1 } }, [
              _c(
                "a",
                {
                  attrs: {
                    title: _vm.$options.filters.translate(
                      "webshop_search_search_result_pagination_previous"
                    ),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.prev()
                    },
                  },
                },
                [_c("i", { staticClass: "mdi mdi-chevron-left" })]
              ),
            ]),
            _vm._v(" "),
            _vm._l(_vm.pagination, function (pgn, index) {
              return _c("li", { key: index, class: { active: pgn.isActive } }, [
                !pgn.isActive && pgn.val != "..." && !pgn.icon
                  ? _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.goToPage(pgn.val)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\r\n               " +
                            _vm._s(pgn.name ? pgn.name : pgn.val) +
                            "               \r\n            "
                        ),
                      ]
                    )
                  : !pgn.isActive && pgn.icon
                  ? _c(
                      "a",
                      {
                        attrs: { title: pgn.name },
                        on: {
                          click: function ($event) {
                            return _vm.goToPage(pgn.val)
                          },
                        },
                      },
                      [_c("i", { staticClass: "mdi", class: pgn.icon })]
                    )
                  : _c("span", [_vm._v(_vm._s(pgn.val))]),
              ])
            }),
            _vm._v(" "),
            _c(
              "li",
              { class: { disabled: _vm.input.page >= _vm.totalPages } },
              [
                _c(
                  "a",
                  {
                    attrs: {
                      title: _vm.$options.filters.translate(
                        "webshop_search_search_result_pagination_next"
                      ),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.next()
                      },
                    },
                  },
                  [_c("i", { staticClass: "mdi mdi-chevron-right" })]
                ),
              ]
            ),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }