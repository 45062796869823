var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import FavoriteCreateModal from 'components/favorite/FavoriteCreateModal.vue';
import { ModalService } from "modules/basic-core/basic-modal/src";
import { LoaderService } from 'services/LoaderService';
import { EventTypes } from 'models/EventTypes';
let FavoriteCreateListButton = class FavoriteCreateListButton extends Vue {
    listUrl;
    beforeDestroy() {
        this.$root.$off(EventTypes.FavoriteCreatedList, this.createdNewList);
    }
    openCreateNew() {
        ModalService.create({
            'cxt': this,
            'component': FavoriteCreateModal,
            'maxWidth': '450px'
        });
        this.$root.$once(EventTypes.FavoriteCreatedList, this.createdNewList);
    }
    createdNewList(list) {
        if (list) {
            LoaderService.createLoader(null);
            let redirectUrl;
            let listUrl = this.listUrl;
            setTimeout(() => {
                if (listUrl) {
                    redirectUrl = listUrl.replace("-1", list.id.toString());
                    redirectUrl = redirectUrl.replace("-2", list.department);
                    window.location.href = redirectUrl;
                }
                else {
                    window.location.reload();
                }
            }, 500);
        }
    }
};
FavoriteCreateListButton = __decorate([
    Component({
        props: {
            listUrl: String
        }
    })
], FavoriteCreateListButton);
export default FavoriteCreateListButton;
