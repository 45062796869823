var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.edit
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.valueLocal,
              expression: "valueLocal",
            },
            { name: "autofocus", rawName: "v-autofocus" },
            { name: "select-onfocus", rawName: "v-select-onfocus" },
          ],
          attrs: { type: "text" },
          domProps: { value: _vm.valueLocal },
          on: {
            blur: _vm.blur,
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.blur.apply(null, arguments)
            },
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.valueLocal = $event.target.value
            },
          },
        })
      : _c(
          "span",
          {
            staticClass: "item-price-small no-fill",
            on: {
              click: function ($event) {
                _vm.edit = true
              },
            },
          },
          [
            _vm._v(
              "\n    " +
                _vm._s(_vm._f("currency")(_vm.line.pricePrUnit)) +
                "\n    "
            ),
            _c("i", { staticClass: "mdi mdi-pencil" }),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }