var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "wrapper",
      class: { active: _vm.isActive, loading: _vm.loading },
    },
    [
      _c("div", { staticClass: "header" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isCustomList,
                expression: "!isCustomList",
              },
            ],
            staticClass: "name",
            on: {
              click: function ($event) {
                return _vm.toggleFilter()
              },
            },
          },
          [
            _vm._v("\r\n            " + _vm._s(_vm.name) + "\r\n            "),
            _c("spinner", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { color: "#fff" },
            }),
            _vm._v(" "),
            _vm._m(0),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "content-wrapper",
            class: { active: _vm.isActive, "custom-list": _vm.isCustomList },
          },
          [_vm._t("default")],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "arrow" }, [
      _c("i", { staticClass: "mdi mdi-chevron-right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }