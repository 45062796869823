export class ProductUnitType {
    name;
    description;
    weighed;
    constructor() {
        this.name = null;
        this.description = null;
        this.weighed = null;
    }
    static fromApi(unit) {
        return Object.assign(new ProductUnitType(), unit);
    }
}
