export class DepictCustomFilter {
    filterId;
    icon;
    displayText;
    printerFunction;
    constructor(filterId, icon, displayText, printFunction) {
        this.filterId = filterId;
        this.icon = icon;
        this.displayText = displayText;
        this.printerFunction = printFunction;
    }
}
