export class ProductVM {
    AltProduct;
    campaignLinetext;
    campaignTypeName;
    basicUnit;
    productId;
    description;
    longDescription;
    brand;
    salesUnit;
    postingGroup;
    postingGroupName;
    units;
    supplierCode;
    supplierName;
    supplierProductId;
    keywords;
    foodInfo;
    foodInfoRequired;
    productSheet;
    image;
    discontinued;
    deadlineMessage;
    orderable;
    onSale;
    onSaleUntil;
    isOnFavoriteList;
    inBasket;
    discontinuedText;
    altProductId;
    comment;
    supplierApproved;
    blocked;
    blockedByProductInfo;
    countryCode;
    country;
    favoriteSort;
    favoriteUnit;
    deposit;
    comparisonPrice;
    comparisonUnit;
    expiry;
    stopTime;
    canBeDeliveredBy;
    saleBlocked;
    saleBlockedDate;
    obsoleteProduct;
    replacementLogId;
    leadTime;
    depositProduct;
    blockedByDeadline;
    localCreateDateTime;
    eligibleOrderDate;
    isPostingGroup;
    status;
    stock;
    clearAmount;
    markings;
    addedAmount;
    surchargePct;
    leftAmountWithSurcharge;
    negotiatedCost;
    factorPriceKgPiece;
    centralPurchasePrice;
    static fromApi(item) {
        return Object.assign(new ProductVM(), item);
    }
}
