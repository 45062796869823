var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "own-product-form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit()
        },
      },
    },
    [
      _c("fieldset", [
        _c("div", { staticClass: "field" }, [
          _c("label", [
            _vm._v(_vm._s(_vm._f("translate")("webshop_general_name"))),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model.name,
                expression: "model.name",
              },
            ],
            staticClass: "slide-input counter",
            attrs: {
              type: "text",
              placeholder: "",
              required: "",
              maxlength: "50",
              disabled: _vm.disabled,
            },
            domProps: { value: _vm.model.name },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.model, "name", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "slide-input-counter" }, [
            _vm._v(_vm._s(_vm.model.name ? 50 - _vm.model.name.length : "50")),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c("label", [
            _vm._v(_vm._s(_vm._f("translate")("webshop_general_brand"))),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model.itemBrand,
                expression: "model.itemBrand",
              },
            ],
            staticClass: "slide-input counter",
            attrs: {
              type: "text",
              placeholder: "",
              maxlength: "30",
              disabled: _vm.disabled,
            },
            domProps: { value: _vm.model.itemBrand },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.model, "itemBrand", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "slide-input-counter" }, [
            _vm._v(
              _vm._s(
                _vm.model.itemBrand ? 30 - _vm.model.itemBrand.length : "30"
              )
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c("label", [
            _vm._v(_vm._s(_vm._f("translate")("webshop_general_unit"))),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.model.salesUnitName,
                  expression: "model.salesUnitName",
                },
              ],
              staticClass: "slide-input",
              attrs: { required: "" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.model,
                      "salesUnitName",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                  _vm.onSelectUnit,
                ],
              },
            },
            [
              _c("option", { domProps: { value: null } }),
              _vm._v(" "),
              _vm._l(_vm.units, function (unit) {
                return _c(
                  "option",
                  { key: unit.name, domProps: { value: unit.name } },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm._f("unitDescription")(unit)) +
                        "\n                "
                    ),
                  ]
                )
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field field-group" }, [
          _c(
            "div",
            { staticClass: "field field-group amount-field" },
            [
              _c("label", [
                _vm._v(
                  _vm._s(_vm._f("translate")("webshop_general_unitPrice")) +
                    " [kr]"
                ),
              ]),
              _vm._v(" "),
              _vm.showPrice
                ? _c("input-number", {
                    attrs: {
                      inputClass: "slide-input",
                      autocomplete: "off",
                      inputStep: 0.01,
                      "select-onfocus": "true",
                      required: "",
                    },
                    model: {
                      value: _vm.model.price,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "price", $$v)
                      },
                      expression: "model.price",
                    },
                  })
                : _c("input", {
                    staticClass: "slide-input",
                    attrs: {
                      type: "text",
                      placeholder: "",
                      required: "",
                      disabled: _vm.disabled,
                    },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field field-group amount-field" },
            [
              _c("label", [
                _vm._v(
                  _vm._s(_vm._f("translate")("webshop_general_netWeight")) +
                    " [kg]"
                ),
              ]),
              _vm._v(" "),
              _c("input-number", {
                attrs: {
                  inputClass: "slide-input",
                  autocomplete: "off",
                  inputStep: 0.001,
                  "select-onfocus": "true",
                  required: "",
                },
                model: {
                  value: _vm.model.itemNetWeight,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "itemNetWeight", $$v)
                  },
                  expression: "model.itemNetWeight",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ui-form-submitwrapper ui-form-submitwrapper-full" },
        [
          _c(
            "button",
            {
              staticClass: "ui-button ui-button-secondary ui-button-left",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.closeModal()
                },
              },
            },
            [
              _vm._v(
                _vm._s(
                  _vm._f("translate")(
                    "webshop_calculations_calculationHeader_buttons_cancel"
                  )
                )
              ),
            ]
          ),
          _vm._v(" "),
          _c("loading-button", {
            attrs: {
              "icon-class": "mdi-content-save",
              cancel: false,
              text: _vm.$options.filters.translate("webshop_general_save"),
              "loading-text": _vm.$options.filters.translate(
                "webshop_general_save"
              ),
              loading: _vm.loading,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }