var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import IconTrash from 'components/shared/IconTrash.vue';
import { Settings } from 'config/Settings';
let CampaignPlanningList = class CampaignPlanningList extends Vue {
    campaigns;
    loading;
    Settings = Settings;
    isValidPurchaseDeadline(campaign) {
        let currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 3);
        return new Date(campaign.purchaseStartingDate) > currentDate;
    }
    activeCampaign(campaignCode) {
        const selectedCampaign = this.$store.state.VendorCampaignPlanning.selectedCampaign;
        return selectedCampaign && selectedCampaign.code == campaignCode;
    }
};
CampaignPlanningList = __decorate([
    Component({
        components: {
            IconTrash
        },
        props: {
            campaigns: Array,
            loading: Boolean
        }
    })
], CampaignPlanningList);
export default CampaignPlanningList;
