import { Service } from './Service';
import { SearchApi } from 'api/SearchApi';
import { SearchOutput } from 'models/search/SearchOutput';
import { SearchSuggestion } from 'models/search/SearchSuggestion';
import { QueryParameters } from 'models/search/QueryParameters';
import { ModalService } from "modules/basic-core/basic-modal/src";
import ShopFoodInfoModal from 'components/shop/partials/ShopFoodInfoModal.vue';
import { SearchSortBy } from "models/search/SearchSortBy";
import { GAService } from "./GAService";
export class SearchService extends Service {
    static topp200 = "topp:200";
    static _cachedSortByKey = 'input_sortBy';
    static isOpenShop = false;
    static itemsWithAmount = [];
    static search(input) {
        return new Promise((resolve, reject) => {
            SearchApi.search(input).then((res) => {
                GAService.productImpressions([...res.data.popularProducts, ...res.data.products]);
                var result = res.data;
                //    .map(x => ProductCategory.fromApi(x));
                resolve(SearchOutput.fromApi(result));
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static getSearchSuggestions(input) {
        return new Promise((resolve, reject) => {
            SearchApi.getSuggestions(input).then((res) => {
                var result = res.data
                    .map(x => SearchSuggestion.fromApi(x));
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static openFoodInfo(vue, productId) {
        ModalService.create({
            'cxt': vue,
            'component': ShopFoodInfoModal,
            'maxWidth': '980px',
            'height': '80%',
            'data': productId
        });
    }
    static currentListId(route) {
        let queryKey = QueryParameters.favoriteListId;
        if (route && route.query) {
            let queryValue = route.query[queryKey];
            if (!isNaN(queryValue)) {
                var val = parseInt(queryValue);
                return val;
            }
        }
        return null;
    }
    static focusNextAmount(vue, usedInput) {
        vue.$nextTick(() => {
            var productLines = vue.$refs["productLine"];
            var setFocus = false;
            for (let i = 0; i < productLines.length; i++) {
                const productLine = productLines[i];
                const input = productLine.$refs["inputAmount"];
                if (setFocus && input) {
                    input.focus();
                    break;
                }
                if (input === usedInput) {
                    setFocus = true;
                }
            }
        });
    }
    //#region Cached SortBy methods
    static setCachedSortBy(value) {
        return new Promise((resolve, reject) => {
            this.removeCachedSortBy();
            this.cache.add(this._cachedSortByKey, {}, value);
            resolve();
        });
    }
    static getCachedSortBy(input) {
        let tempSortBy = input.sortBy;
        let hasValue = false;
        let cacheItem = this.cache.get(this._cachedSortByKey, {});
        if (cacheItem) {
            tempSortBy = cacheItem.promise;
            hasValue = true;
        }
        if (!input.favoriteListId && !input.department && tempSortBy == SearchSortBy.Favorite) {
            tempSortBy = input.searchQuery ? SearchSortBy.Relevance : SearchSortBy.Description;
        }
        else if (!hasValue) {
            tempSortBy = input.sortBy;
        }
        return tempSortBy;
    }
    static removeCachedSortBy() {
        this.cache.remove(this._cachedSortByKey, {});
    }
    //#endregion
    static isTopp200(input) {
        return input && input.searchQuery && input.searchQuery.includes(SearchService.topp200);
    }
    static isSearching(route) {
        if (!route.query || !(route.query[QueryParameters.searchQuery] || route.query[QueryParameters.sortBy])) {
            return false;
        }
        else {
            return true;
        }
    }
    static checkIfAnyFilters(query) {
        let hasFilters = false;
        Object.keys(QueryParameters).forEach(key => {
            if (QueryParameters[key] === QueryParameters.favoriteListId || QueryParameters[key] === QueryParameters.department)
                return;
            if (query[QueryParameters[key]] && query[QueryParameters[key]].length > 0) {
                hasFilters = true;
            }
        });
        return hasFilters;
    }
}
