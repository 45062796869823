var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-overlay active" }, [
    _c("div", { staticClass: "modal grey" }, [
      _c("div", { staticClass: "modal-header" }, [
        _c("h1", { staticClass: "header-medium" }, [
          _vm._v(
            _vm._s(
              _vm._f("translate")(
                "webshop_inventory_deleteProductModal_deleteProduct"
              )
            )
          ),
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "modal-close",
            on: {
              click: function ($event) {
                return _vm.close()
              },
            },
          },
          [_c("i", { staticClass: "mdi mdi-close-circle-outline" })]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-content" },
        [
          _c("inventory-product-own-form-partial", {
            attrs: {
              loading: _vm.loading,
              setLoading: _vm.setLoading,
              list: _vm.list,
              line: _vm.line,
              disabled: true,
              saveBtnText: _vm.deleteText,
              saveBtnClass: "ui-button-cancel",
            },
            on: { save: _vm.deleteProduct, cancel: _vm.close },
          }),
          _vm._v(" "),
          _vm.loading
            ? _c("div", { staticClass: "inventory-loader" }, [_vm._m(0)])
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "simple-loader-ui" }, [
      _c("div", { staticClass: "simple-loader dark" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }