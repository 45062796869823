var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "highlight-markings" }, [
    _c(
      "div",
      { staticClass: "highlight-markings-wrapper" },
      _vm._l(_vm.highlightedMarkings, function (tagKey) {
        return _c(
          "span",
          { key: tagKey, staticClass: "highlight-marking", class: tagKey },
          [
            _vm._v(
              _vm._s(
                _vm._f("translate")(
                  "webshop_general_markings_highlight_" + tagKey
                )
              )
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }