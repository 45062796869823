var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import Loader from 'components/utility/Loader.vue';
import LoadingButton from 'components/shared/LoadingButton.vue';
import ImagePicker from 'components/shared/ImagePicker.vue';
import { CalculationVM } from "models/calculation/CalculationVM";
import { CalculationService } from "services/CalculationService";
import { ToastService } from "services/ToastService";
import { ImagePickerErrorType } from 'components/shared/ImagePicker.vue';
import { Settings } from "config/Settings";
let CalculationRecipeModal = class CalculationRecipeModal extends Vue {
    data;
    loading = false;
    recipeDescription = "";
    inputForm = null;
    imageMaxSize = 20480;
    imageUrl;
    disabledCalculation = false;
    mounted() {
        this.recipeDescription = this.data.recipeDescription;
        this.imageUrl = this.data.imageUrl;
        this.disabledCalculation = this.data.customerNo != Settings.profileInfo.customerNo;
    }
    close() {
        this.$emit('close-modal');
    }
    deleteImage() {
        this.imageUrl = "";
        this.inputForm = null;
    }
    saveContent() {
        let msgTimeout = 0;
        if (this.loading)
            return;
        this.loading = true;
        new Promise((resolve, reject) => {
            let imageUrl = this.inputForm == null && this.imageUrl == "" && this.data.imageUrl != "" ? "" : this.data.imageUrl;
            let shouldDeleteImage = this.inputForm == null && imageUrl == "" && this.data.imageUrl != "";
            if (this.data.recipeDescription != this.recipeDescription || shouldDeleteImage) {
                let calculaion = Object.assign(new CalculationVM(), this.data);
                calculaion.recipeDescription = this.recipeDescription;
                calculaion.imageUrl = imageUrl;
                CalculationService.updateCalculation(calculaion).then(res => {
                    if (this.data.recipeDescription != this.recipeDescription) {
                        msgTimeout = 2000;
                        ToastService.success(Vue.filter('translate')('webshop_calculations_modals_recipe_toasts_descriptionUpdated'));
                    }
                    if (shouldDeleteImage) {
                        setTimeout(() => {
                            ToastService.success(Vue.filter('translate')('webshop_calculations_modals_recipe_toasts_imageDeleted'));
                        }, msgTimeout);
                    }
                    resolve(res);
                }).catch(err => {
                    msgTimeout = 2000;
                    ToastService.danger(Vue.filter('translate')('webshop_defaults_toast_errorText'));
                    reject(err);
                });
            }
            else {
                resolve(null);
            }
        }).finally(() => {
            if (this.inputForm) {
                CalculationService.uploadImage(this.data.calculationId, this.inputForm).then(res => {
                    setTimeout(() => {
                        ToastService.success(Vue.filter('translate')('webshop_calculations_modals_recipe_toasts_imageUploaded'));
                    }, msgTimeout);
                }).catch(err => {
                    setTimeout(() => {
                        ToastService.danger(Vue.filter('translate')('webshop_calculations_modals_recipe_toasts_uploadError'));
                    }, msgTimeout);
                }).finally(() => {
                    this.$store.dispatch('Calculations/selectCalculation', this.data.calculationId);
                    this.$emit('close-modal');
                    this.loading = false;
                });
            }
            else {
                this.$store.dispatch('Calculations/selectCalculation', this.data.calculationId);
                this.$emit('close-modal');
                this.loading = false;
            }
        });
    }
    selectedImage(inputForm) {
        this.inputForm = inputForm;
    }
    imageError(evt) {
        if (evt.type === ImagePickerErrorType.SizeExceed) {
            ToastService.danger(Vue.filter('translate')('webshop_calculations_modals_recipe_toasts_imageSizeExceed'));
            this.inputForm = null;
        }
        if (evt.type === ImagePickerErrorType.WrongFileFormat) {
            ToastService.danger(Vue.filter('translate')('webshop_calculations_modals_recipe_toasts_wrongImageFormat'));
        }
        if (evt.type === ImagePickerErrorType.InvalidImage) {
            ToastService.danger(Vue.filter('translate')('webshop_calculations_modals_recipe_toasts_invalidImage'));
        }
    }
};
CalculationRecipeModal = __decorate([
    Component({
        components: {
            Loader,
            LoadingButton,
            ImagePicker
        },
        props: {
            data: Object
        }
    })
], CalculationRecipeModal);
export default CalculationRecipeModal;
