var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import VueRouter from "vue-router";
import InventoryPicker from "./InventoryPicker.vue";
import InventoryDetail from "./InventoryDetail.vue";
import InventoryModals from "./InventoryModals.vue";
import { LoaderService } from "services/LoaderService";
import { EventTypes } from 'models/EventTypes';
import InventoryPrintSelect from "./InventoryPrintSelect.vue";
import InventoryArchiveModal from "./modals/InventoryArchiveModal.vue";
import { ModalService } from "modules/basic-core/basic-modal/src";
const routes = [{
        name: "inventoryOverview",
        path: "/webbshop/inventering"
    },
    {
        name: "inventoryDetail",
        path: "/webbshop/inventering/:id",
        component: InventoryDetail,
        props: true
    }
];
const router = new VueRouter({
    mode: "history",
    routes: routes
});
let Inventory = class Inventory extends Vue {
    descriptionText;
    descriptionTitle;
    showSettings = false;
    loading = false;
    created() {
        this.$root.$on(EventTypes.InventoryScrollToList, this.scrollToList);
    }
    beforeDestroy() {
        this.$root.$off(EventTypes.InventoryScrollToList, this.scrollToList);
    }
    setLoading(value) {
        if (!value || this.loading) {
            LoaderService.stop();
        }
        if (value) {
            LoaderService.createLoader(null);
        }
        this.loading = value;
    }
    print(countingsheet) {
        ModalService.create({
            'cxt': this,
            'component': InventoryPrintSelect,
            'maxWidth': '450px',
            'data': countingsheet
        });
    }
    openAutolagerModal() {
        let listId = parseInt(this.$route.params.id);
        if (isNaN(listId))
            listId = null;
        this.$root.$emit(EventTypes.InventoryModalAutolagerOpen, listId);
    }
    toggleSettings() {
        this.showSettings = !this.showSettings;
        if (this.showSettings) {
            document.body.addEventListener("click", this.clickOutsideSettings);
        }
        else {
            document.body.removeEventListener("click", this.clickOutsideSettings);
        }
    }
    clickOutsideSettings(el) {
        const wrapper = this.$refs["inventory-settings-container"];
        if (!wrapper || wrapper.contains(el.target))
            return;
        this.toggleSettings();
    }
    scrollToList() {
        this.$nextTick(() => {
            try {
                var isSafari = /constructor/i.test(window["HTMLElement"]) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof window['safari'] !== 'undefined' && window['safari'].pushNotification));
                var isIE = /*@cc_on!@*/ false || !!document["documentMode"];
                var isEdge = !isIE && !!window["StyleMedia"];
                var scrollPoint = document.getElementsByClassName("inventory-scroll-point")[0];
                var navHeight = document.getElementById("shop-floatingHeader").offsetHeight;
                var scrollYPos = scrollPoint.offsetTop - navHeight; //window.innerWidth < 667 ? 185 : window.innerWidth < 1100 ? 200 : 345;                       
                if (!isSafari && !isEdge && !isIE) {
                    window.scrollTo({ top: scrollYPos, behavior: 'smooth' });
                }
                else {
                    window.scroll(0, scrollYPos);
                }
            }
            catch (e) {
                console.log("e", e);
            }
        });
    }
    createArchive() {
        ModalService.create({
            'cxt': this,
            'component': InventoryArchiveModal,
            'maxWidth': '450px'
        });
    }
};
Inventory = __decorate([
    Component({
        components: {
            InventoryPicker,
            InventoryModals
        },
        props: {
            descriptionText: String,
            descriptionTitle: String
        },
        router: router
    })
], Inventory);
export default Inventory;
