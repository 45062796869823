var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table-outer-row" }, [
    _c("div", { staticClass: "table-row calculation-line-row" }, [
      _c("div", { staticClass: "table-column hide-small" }, [
        _vm._v(_vm._s(_vm.orderline.productId)),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "table-column calculation-column-name item-description",
        },
        [
          _c("span", { staticClass: "item-small-productid no-fill" }, [
            _vm._v(_vm._s(_vm.orderline.brand)),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "no-fill" }, [
            _vm._v(_vm._s(_vm.orderline.description)),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "table-column column-justify-end small-third column-price",
        },
        [
          _vm.showPrice
            ? [
                _c(
                  "span",
                  {
                    class: {
                      "item-price-campaign":
                        _vm.selectedUnit &&
                        _vm.selectedUnit.priceBeforeCampaign > 0,
                    },
                  },
                  [_vm._v(_vm._s(_vm._f("currency")(_vm.orderline.price)))]
                ),
                _vm._v(" "),
                _vm.selectedUnit && _vm.selectedUnit.priceBeforeCampaign
                  ? _c(
                      "span",
                      {
                        class: {
                          "price-before-campaign":
                            _vm.selectedUnit &&
                            _vm.selectedUnit.priceBeforeCampaign > 0,
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(
                              _vm.selectedUnit.priceBeforeCampaign
                            )
                          )
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-column item-amount-col column-justify-end" },
        [
          _c(
            "div",
            { staticClass: "input-wrapper" },
            [
              _vm.selectedUnit && _vm.selectedUnit.name
                ? _c("input-number", {
                    attrs: {
                      inputUnit:
                        _vm.selectedUnit && _vm.selectedUnit.name
                          ? _vm.selectedUnit.name
                          : "",
                      inputClass: "item-amount-input",
                      inputTabindex: "1",
                      autocomplete: "off",
                      inputStep:
                        _vm.orderline.weightItem &&
                        _vm.selectedUnit.name === "KG"
                          ? 0.01
                          : 1,
                      onlyInteger: !(
                        _vm.orderline.weightItem &&
                        _vm.selectedUnit.name === "KG"
                      ),
                      "select-onfocus": "true",
                    },
                    model: {
                      value: _vm.orderline.amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.orderline, "amount", $$v)
                      },
                      expression: "orderline.amount",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-column small-third colum-unit" },
        [
          _vm.selectedUnit && _vm.selectedUnit.name
            ? [
                _vm._v(
                  _vm._s(_vm.selectedUnit.name) +
                    "(" +
                    _vm._s(_vm.selectedUnit.description) +
                    ")"
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }