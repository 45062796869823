var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import InventoryProductSearchPartial from './partials/InventoryProductSearchPartial.vue';
import InventoryProductOrderPartial from './partials/InventoryProductOrderPartial.vue';
import InventoryProductFavoritePartial from './partials/InventoryProductFavoritePartial.vue';
import InventoryProductOwnPartial from './partials/InventoryProductOwnPartial.vue';
import { ToastService } from 'services/ToastService';
import { InventoryService } from 'services/InventoryService';
import { EventTypes } from 'models/EventTypes';
import { InventoryModalSettings } from 'models/inventory/InventoryModalSettings';
import { InventorySlideTypes } from 'models/inventory/InventorySlideTypes';
let InventoryProductModal = class InventoryProductModal extends Vue {
    loading = false;
    list;
    settings = new InventoryModalSettings();
    InventorySlideTypes = InventorySlideTypes;
    selectedProducts = [];
    selectedFavoriteLists = null;
    groupId = null;
    created() {
        this.$root.$on(EventTypes.InventoryModalProductSelected, this.onProductSelected);
        this.$root.$on(EventTypes.InventoryModalFavoriteSelected, this.onFavoriteSelected);
    }
    beforeDestroy() {
        this.$root.$off(EventTypes.InventoryModalProductSelected, this.onProductSelected);
        this.$root.$off(EventTypes.InventoryModalFavoriteSelected, this.onFavoriteSelected);
    }
    getSliceClass(slideNo) {
        if (this.settings.activeSlide === slideNo) {
            return 'active';
        }
        else {
            return 'inactive';
        }
    }
    openSlide(slide) {
        this.settings.activeSlide = 2;
        this.settings.slide = slide;
    }
    openLastMonths(months) {
        this.settings.prefixedPeriod = months;
        this.openSlide(InventorySlideTypes.order);
    }
    close() {
        this.$root.$emit(EventTypes.InventoryModalProductClose);
    }
    resetSettings(keepSearchQuery = false) {
        let searchQuery = this.settings.searchQuery;
        this.settings = new InventoryModalSettings();
        if (keepSearchQuery)
            this.settings.searchQuery = searchQuery;
        /*this.settings.activeSlide = 1;
        this.settings.slide = InventorySlideTypes.dashboard;
        this.settings.prefixedPeriod = null;
        this.settings.searchQuery = '';
        this.settings.hasSearchResult = false;*/
        this.$root.$emit(EventTypes.InventoryModalProductSelected, null);
    }
    setLoading(value) {
        this.loading = value;
    }
    addProducts() {
        this.setLoading(true);
        this.selectedProducts.forEach(x => x.groupId = this.groupId);
        InventoryService.createLines(this.list.id, this.selectedProducts).then(res => {
            var inserted = res.insertedLineIds.length;
            var ignored = res.ignoredLineIds.length;
            var updated = res.updatedLineIds.length;
            ToastService.success(Vue.filter('translate')('webshop_inventory_addmodals_toast_addedProducts', inserted, ignored, updated));
            // ToastService.success('Der er tilføjet ' + addedCount + ' produkte(r) til listen');
            this.$root.$emit(EventTypes.InventoryReloadList, this.list.id);
            this.resetSettings();
            this.setLoading(false);
        })
            .catch(err => {
            this.setLoading(false);
            ToastService.danger(Vue.filter('translate')('webshop_defaults_toast_errorText'));
        });
    }
    addFavoritListProducts() {
        this.setLoading(true);
        let favoriteLists = this.selectedFavoriteLists.map(x => x.id);
        InventoryService.addProductsFromFavoritelist(this.list.id, favoriteLists, this.groupId).then(res => {
            var inserted = res.insertedLineIds.length;
            var ignored = res.ignoredLineIds.length;
            //var updated = res.updatedLineIds.length;    
            ToastService.success(Vue.filter('translate')('webshop_inventory_addmodals_toast_addedProductsFromFavorite', inserted, ignored));
            this.$root.$emit(EventTypes.InventoryReloadList, this.list.id);
            this.resetSettings();
            this.setLoading(false);
        })
            .catch(err => {
            this.setLoading(false);
            ToastService.danger(Vue.filter('translate')('webshop_defaults_toast_errorText'));
        });
    }
    onProductSelected(products) {
        this.selectedProducts = products;
        this.selectedFavoriteLists = null;
    }
    onFavoriteSelected(favoriteLists) {
        this.selectedProducts = null;
        this.selectedFavoriteLists = favoriteLists;
    }
};
InventoryProductModal = __decorate([
    Component({
        components: {
            InventoryProductSearchPartial,
            InventoryProductOrderPartial,
            InventoryProductFavoritePartial,
            InventoryProductOwnPartial
        },
        props: {
            list: Object
        }
    })
], InventoryProductModal);
export default InventoryProductModal;
