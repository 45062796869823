var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import CalculationList from './result/CalculationList.vue';
import CalculationDetails from './CalculationDetails.vue';
import VueRouter from 'vue-router';
const routes = [{
        name: "calculations",
        path: "/webbshop/kalkyler"
    },
    {
        name: "calculationDetails",
        path: "/webbshop/kalkyler/:calculationId",
        component: CalculationDetails,
        props: true
    }
];
const router = new VueRouter({
    mode: "history",
    routes: routes
});
let Calculations = class Calculations extends Vue {
    created() {
        this.$store.dispatch('Calculations/getAll');
    }
    get calculations() {
        return this.$store.state.Calculations.list;
    }
    get loading() {
        return this.$store.state.Calculations.loading;
    }
    scrollToList() {
        this.$nextTick(() => {
            try {
                var isSafari = /constructor/i.test(window["HTMLElement"]) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof window['safari'] !== 'undefined' && window['safari'].pushNotification));
                var isIE = /*@cc_on!@*/ false || !!document["documentMode"];
                var isEdge = !isIE && !!window["StyleMedia"];
                var scrollPoint = document.getElementsByClassName("inventory-scroll-point")[0];
                var navHeight = document.getElementById("shop-floatingHeader").offsetHeight;
                var scrollYPos = scrollPoint.offsetTop - navHeight; //window.innerWidth < 667 ? 185 : window.innerWidth < 1100 ? 200 : 345;                       
                if (!isSafari && !isEdge && !isIE) {
                    window.scrollTo({ top: scrollYPos, behavior: 'smooth' });
                }
                else {
                    window.scroll(0, scrollYPos);
                }
            }
            catch (e) {
                console.log("e", e);
            }
        });
    }
};
Calculations = __decorate([
    Component({
        components: {
            CalculationList
        },
        router: router,
        props: {
            descriptionText: String,
            descriptionTitle: String
        }
    })
], Calculations);
export default Calculations;
