var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("ul", { key: "renderCounter" + _vm.renderCounter }, [
      _c("li", [
        _c(
          "a",
          {
            class: { active: _vm.isTopp200 },
            on: {
              click: function ($event) {
                return _vm.select(null, true)
              },
            },
          },
          [
            _c("i", {
              staticClass: "mdi",
              class: {
                "mdi-checkbox-marked-outline": _vm.isTopp200,
                "mdi-checkbox-blank-outline": !_vm.isTopp200,
              },
            }),
            _vm._v(
              _vm._s(
                _vm._f("translate")("webshop_search_search_filters_top_200")
              )
            ),
          ]
        ),
        _vm._v(" "),
        _c("i", {
          staticClass: "selector-icon mdi mdi-star",
          attrs: {
            title: _vm.$options.filters.translate(
              "webshop_search_search_filters_top_200"
            ),
          },
        }),
        _vm._v(" "),
        _c("i", {
          staticClass: "selector-icon mdi mdi-printer ui-button-right",
          attrs: {
            title: _vm.$options.filters.translate("webshop_favorite_print"),
          },
          on: { click: _vm.printTopN },
        }),
      ]),
      _vm._v(" "),
      _vm.hasAgreement
        ? _c("li", [
            _c(
              "a",
              {
                class: { active: _vm.agreementActive },
                on: {
                  click: function ($event) {
                    return _vm.agreementProducts()
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "mdi",
                  class: {
                    "mdi-checkbox-marked-outline": _vm.agreementActive,
                    "mdi-checkbox-blank-outline": !_vm.agreementActive,
                  },
                }),
                _vm._v(_vm._s(_vm.agreement.label) + " "),
                _c("i", {
                  staticClass: "selector-icon",
                  class: _vm.agreement.icon,
                }),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.settings.profileInfo.customerClass == null ||
      _vm.settings.profileInfo.customerClass.toUpperCase() !=
        _vm.customerClass.Ö
        ? _c("li", [
            _c(
              "a",
              {
                class: { active: _vm.campaignActive },
                on: {
                  click: function ($event) {
                    return _vm.campaignProducts()
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "mdi",
                  class: {
                    "mdi-checkbox-marked-outline": _vm.campaignActive,
                    "mdi-checkbox-blank-outline": !_vm.campaignActive,
                  },
                }),
                _vm._v(_vm._s(_vm.campaign.label) + " "),
                _c("i", {
                  staticClass: "selector-icon",
                  class: _vm.campaign.icon,
                }),
              ]
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }