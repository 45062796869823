var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ui-modal-content" }, [
      _c("h3", { staticClass: "ui-modal-header" }, [
        _vm._v(_vm._s(_vm._f("translate")("webshop_cart_empty_modalHeader"))),
      ]),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "ui-form",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c("div", [
            !_vm.isDeposit
              ? _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_cart_empty_modalText",
                        _vm.$options.filters.date(_vm.data)
                      )
                    )
                  ),
                ])
              : _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_cart_empty_modalText_deposit",
                        _vm.$options.filters.date(_vm.data)
                      )
                    )
                  ),
                ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ui-modal-footer" }, [
            _c("div", { staticClass: "ui-modal-confirm-wrapper" }, [
              _c(
                "button",
                {
                  staticClass: "ui-button ui-modal-confirm-accept",
                  attrs: { type: "submit" },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_cart_empty_modalButtonAccept"
                      )
                    )
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }