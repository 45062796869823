var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ui-modal-content" }, [
      _c("h3", { staticClass: "ui-modal-header" }, [
        _vm._v(
          _vm._s(
            _vm._f("translate")(
              "webshop_calculations_modals_deleteCalculation_title"
            )
          )
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.loading
            ? [_c("loader", { attrs: { size: "large" } })]
            : [
                _c(
                  "form",
                  {
                    staticClass: "ui-form",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submit()
                      },
                    },
                  },
                  [
                    _c("div", {
                      staticClass: "content",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$options.filters.translate(
                            "webshop_calculations_modals_deleteCalculation_text",
                            _vm.data.calculation.name
                          )
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "ui-form-submitwrapper ui-form-submitwrapper-full",
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "ui-button ui-button-secondary ui-button-left",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.closeModal()
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm._f("translate")("webshop_general_no"))
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("loading-button", {
                          attrs: {
                            "icon-class": "mdi-delete",
                            cancel: true,
                            text: _vm.$options.filters.translate(
                              "webshop_general_yes"
                            ),
                            "loading-text": _vm.$options.filters.translate(
                              "webshop_general_yes"
                            ),
                            loading: _vm.loading,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }