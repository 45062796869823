import { Service } from '../../../basic-service/src';
export class SessionStorageService extends Service {
    static getItem(key) {
        return JSON.parse(sessionStorage.getItem(key));
    }
    static setItem(key, item) {
        sessionStorage.setItem(key, JSON.stringify(item));
    }
    static removeItem(key) {
        sessionStorage.removeItem(key);
    }
}
