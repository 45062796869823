var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ui-modal-content" }, [
      _c("h3", { staticClass: "ui-modal-header" }, [
        _vm._v(
          _vm._s(
            _vm._f("translate")("webshop_calculations_modals_recipe_title")
          )
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.loading
            ? [_c("loader", { attrs: { size: "large" } })]
            : [
                _c(
                  "form",
                  {
                    staticClass: "ui-form",
                    attrs: { name: "calculationModal" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.saveContent.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "ui-form-fieldwrapper" }, [
                      _c(
                        "label",
                        { attrs: { for: "image" } },
                        [
                          _c("image-picker", {
                            attrs: {
                              imageUrl: _vm.data.imageUrl,
                              customClass: "image-picker",
                              maxSize: _vm.imageMaxSize,
                              dragDropText: _vm.$options.filters.translate(
                                "webshop_calculations_modals_recipe_imageText",
                                _vm.imageMaxSize / 1024
                              ),
                              buttonText: _vm.$options.filters.translate(
                                "webshop_calculations_modals_recipe_imageBtnSelect"
                              ),
                              deleteButtonText: _vm.$options.filters.translate(
                                "webshop_calculations_modals_recipe_imageBtnDelete"
                              ),
                              disabled: _vm.disabledCalculation,
                            },
                            on: {
                              onSelectedImage: _vm.selectedImage,
                              onDeleteImage: _vm.deleteImage,
                              onError: _vm.imageError,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "ui-form-fieldwrapper" }, [
                      _c("label", { attrs: { for: "recipeDescription" } }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("translate")(
                                "webshop_calculations_modals_recipe_labelRecipeDescription"
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.recipeDescription,
                              expression: "recipeDescription",
                            },
                          ],
                          attrs: {
                            name: "recipeDescription",
                            disabled: _vm.disabledCalculation,
                          },
                          domProps: { value: _vm.recipeDescription },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.recipeDescription = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    !_vm.disabledCalculation
                      ? _c(
                          "div",
                          { staticClass: "modal-buttons-wrapper" },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "ui-button ui-button-secondary ui-button-left",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.close()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("translate")(
                                      "webshop_calculations_calculationHeader_buttons_cancel"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("loading-button", {
                              attrs: {
                                "icon-class": "mdi-content-save",
                                text: _vm.$options.filters.translate(
                                  "webshop_calculations_modals_recipe_btnSave"
                                ),
                                "loading-text": _vm.$options.filters.translate(
                                  "webshop_calculations_modals_recipe_btnSave"
                                ),
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ],
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }