var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { VendorService } from 'services/VendorService';
import { CampaignPlanningCommentVM } from 'models/campaignPlanning/CampaignPlanningCommentVM';
import { EventTypes } from 'models/EventTypes';
import Loader from 'components/utility/Loader.vue';
import { CampaignPlanningLineWaitingForStatus } from 'models/campaignPlanning/CampaignPlanningLineStatus';
import { CampaignPlanningVM } from 'models/campaignPlanning/CampaignPlanningVM';
let CampaignPlanningComments = class CampaignPlanningComments extends Vue {
    campaignLine = null;
    loading = false;
    comments = [];
    newComment = '';
    assetsBaseLink = "/app/dist2/assets/";
    mounted() {
        document.addEventListener('mouseup', this.clickOutside);
        this.$root.$on(EventTypes.ShowComment, this.loadComments);
    }
    beforeDestroy() {
        document.removeEventListener('mouseup', this.clickOutside);
    }
    clickOutside(evt) {
        if (this.campaignLine != null) {
            let el = this.$refs.commentsFrame;
            if (!(el && el != undefined && el.contains(evt.target))) {
                this.closeFrame();
            }
        }
    }
    loadComments(campaignLine) {
        if (campaignLine == null)
            this.campaignLine = null;
        else {
            this.campaignLine = campaignLine;
            this.loading = true;
            VendorService.getCampaignPlanningComments(this.campaignLine.campaignCode, this.campaignLine.vendorNo, this.campaignLine.vendorItemNo)
                .then((res) => {
                this.comments = res;
                setTimeout(() => {
                    let el = this.$refs.commentsContainer;
                    if (el)
                        el.scrollTo({ top: el.scrollHeight, behavior: 'smooth' });
                    let textInput = this.$refs.textInput;
                    textInput.focus();
                }, 200);
                if (this.campaignLine.waitingFor == CampaignPlanningLineWaitingForStatus.WaitingForVendor)
                    this.updateWaitingFor(CampaignPlanningLineWaitingForStatus.WaitingForNoOne);
            }).finally(() => this.loading = false);
        }
    }
    updateWaitingFor(status) {
        const campaignLine = Object.assign(new CampaignPlanningVM(), this.campaignLine);
        campaignLine.waitingFor = status;
        this.$store.dispatch('VendorCampaignPlanning/changeCampaignPlanningLineWaitingFor', [campaignLine]);
    }
    sendMessage() {
        if (this.loading || this.newComment == null || this.newComment.trim() == '')
            return;
        let newComment = new CampaignPlanningCommentVM();
        newComment.campaignCode = this.campaignLine.campaignCode;
        newComment.vendorNo = this.campaignLine.vendorNo;
        newComment.vendorItemNo = this.campaignLine.vendorItemNo;
        newComment.comment = this.newComment;
        this.loading = true;
        VendorService.writeComment(newComment)
            .then((res) => {
            this.loading = false;
            if (this.campaignLine.waitingFor != CampaignPlanningLineWaitingForStatus.WaitingForSvenskCater)
                this.updateWaitingFor(CampaignPlanningLineWaitingForStatus.WaitingForSvenskCater);
            this.loadComments(this.campaignLine);
        }).catch((err) => this.loading = false)
            .finally(() => {
            let el = this.$refs.textInput;
            el.textContent = '';
            this.newComment = '';
        });
    }
    closeFrame() {
        this.campaignLine = null;
        this.newComment = '';
    }
    onCommentInputChange(evt) {
        if ((this.newComment != '' && this.newComment != null) && evt.currentTarget.innerText.length > 200) {
            evt.currentTarget.innerText = this.newComment;
        }
        if (evt.currentTarget.innerText && evt.currentTarget.innerText.length > 200) {
            evt.currentTarget.innerText = evt.currentTarget.innerText.slice(0, 200);
        }
        this.newComment = evt.currentTarget.innerText;
    }
    get isValidPurchaseDeadline() {
        let currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 3);
        return new Date(this.campaignLine.purchaseStartingDate) > currentDate;
    }
};
CampaignPlanningComments = __decorate([
    Component({
        components: {
            Loader
        },
        props: {}
    })
], CampaignPlanningComments);
export default CampaignPlanningComments;
