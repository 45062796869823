var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import ChangePasswordModal from './ChangePasswordModal.vue';
import { ModalService } from "modules/basic-core/basic-modal/src";
let ChangePassword = class ChangePassword extends Vue {
    open() {
        ModalService.create({
            'cxt': this,
            'component': ChangePasswordModal,
            'maxWidth': '450px'
        });
    }
    close() {
        //this.showModal = false;
    }
};
ChangePassword = __decorate([
    Component({
    //components: {
    //    ChangePasswordModal
    //}
    })
], ChangePassword);
export default ChangePassword;
