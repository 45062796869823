import Vue from 'vue';
import Vuex from 'vuex';
import Orders from './modules/Orders';
import Calculations from './modules/Calculations';
import VendorCampaignPlanning from './modules/VendorCampaignPlanning';
Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';
export default new Vuex.Store({
    modules: {
        Orders,
        Calculations,
        VendorCampaignPlanning
    },
    strict: debug
});
