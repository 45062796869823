var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import Loader from 'components/utility/Loader.vue';
import InputNumber from 'components/shared/InputNumber.vue';
import LoadingButton from 'components/shared/LoadingButton.vue';
import { WindowService } from 'services/WindowService';
import { PdfReportService } from 'services/PdfReportService';
import { ToastService } from 'services/ToastService';
let PrintRecipeModal = class PrintRecipeModal extends Vue {
    data;
    numberOfPortion = 1;
    loading = false;
    showSubDescription = true;
    mounted() {
        this.numberOfPortion = this.data.numberOfPortions;
    }
    closeModal() {
        this.$emit('close-modal');
    }
    printRecipe(evt) {
        evt.preventDefault();
        if (this.numberOfPortion <= 0 || this.loading)
            return;
        this.loading = true;
        const newWindow = WindowService.initializeNewWindow();
        PdfReportService.printCalculationRecipes([this.data.calculationId], this.numberOfPortion, this.showSubDescription).then(res => {
            const file = new Blob([res], { type: "application/pdf" });
            const nav = window.navigator;
            if (window.navigator && nav.msSaveOrOpenBlob) {
                nav.msSaveOrOpenBlob(file, 'KalkylRecept.pdf');
                setTimeout(() => {
                    newWindow.close();
                }, 100);
            }
            else {
                const reportLink = window.URL.createObjectURL(file);
                newWindow.location = reportLink;
            }
        }, err => {
            setTimeout(() => {
                newWindow.close();
                if (err === 416) {
                    ToastService.danger(Vue.filter("translate")("webshop_calculations_modals_recipe_toasts_noProducts"));
                }
                else {
                    ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
                }
            }, 500);
            return;
        }).finally(() => this.loading = false);
    }
};
PrintRecipeModal = __decorate([
    Component({
        components: {
            Loader,
            InputNumber,
            LoadingButton
        },
        props: {
            data: Object
        }
    })
], PrintRecipeModal);
export default PrintRecipeModal;
