var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
let ScrollToTopButton = class ScrollToTopButton extends Vue {
    showButton = false;
    mounted() {
        if (window.scrollY > 768)
            this.showButton = true;
        window.onscroll = (evt) => {
            if (window.scrollY > 768)
                this.showButton = true;
            else
                this.showButton = false;
        };
    }
    scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
};
ScrollToTopButton = __decorate([
    Component({})
], ScrollToTopButton);
export default ScrollToTopButton;
