var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table-outer-row" }, [
    _c(
      "div",
      {
        staticClass: "table-row",
        class: {
          "shop-product-status-deleted":
            _vm.line.productStatus === _vm.itemStatus.Deleted,
        },
      },
      [
        _vm.line.editedPrice || _vm.line.editedUnit
          ? _c("div", { staticClass: "ribbon" }, [
              _c(
                "span",
                {
                  attrs: {
                    title: _vm.$options.filters.translate(
                      "webshop_inventory_table_product_ribbon_editedTitle"
                    ),
                  },
                },
                [
                  _vm._v(
                    "\r\n                " +
                      _vm._s(
                        _vm._f("translate")(
                          "webshop_inventory_table_product_ribbon_edited"
                        )
                      ) +
                      "\r\n            "
                  ),
                ]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "table-column align-left hide-small",
            staticStyle: { "max-width": "60px" },
          },
          [_c("span", [_vm._v(_vm._s(_vm.line.productId))])]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table-column align-left item-description" },
          [
            _c(
              "span",
              { staticClass: "no-fill", staticStyle: { display: "none" } },
              [
                _vm._v(
                  _vm._s(
                    _vm.line.productId
                      ? _vm.line.productId
                      : _vm.$options.filters.translate(
                          "webshop_inventory_table_product_ownProduct"
                        )
                  )
                ),
              ]
            ),
            _vm._v(" "),
            _vm.line.productId
              ? _c("span", { staticClass: "item-small-productid no-fill" }, [
                  _vm._v(_vm._s(_vm.line.brand)),
                ])
              : _c("span", { staticClass: "item-small-productid no-fill" }, [
                  _c(
                    "span",
                    { staticClass: "item-small-productid" },
                    [
                      _vm._v(
                        "\r\n                    " +
                          _vm._s(
                            _vm._f("translate")(
                              "webshop_inventory_table_product_ownProduct"
                            )
                          ) +
                          "\r\n                    "
                      ),
                      _vm.line.brand ? [_vm._v(" - ")] : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(
                    "\r\n                " +
                      _vm._s(_vm.line.brand) +
                      "\r\n            "
                  ),
                ]),
            _vm._v(" "),
            _vm.line.productId
              ? _c("span", { staticClass: "item-small-productid no-fill" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_search_search_view_gallery_supplier_product_id_short"
                      )
                    ) +
                      ": " +
                      _vm._s(_vm.line.supplierProductId)
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.line.productId
              ? _c("span", [
                  _c("span", { staticClass: "inventory-product-description" }, [
                    _vm._v(_vm._s(_vm.line.description)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "break-small" }),
                ])
              : _c(
                  "a",
                  {
                    staticClass: "no-fill",
                    on: {
                      click: function ($event) {
                        return _vm.openEditProduct(_vm.list, _vm.line)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\r\n                " +
                        _vm._s(_vm.line.description) +
                        " \r\n                "
                    ),
                    _c("i", { staticClass: "mdi mdi-pencil" }),
                  ]
                ),
            _vm._v(" "),
            _vm.line.markings
              ? [
                  _c("shop-item-icons", {
                    attrs: {
                      product: _vm.line,
                      priceTypeCampaign: _vm.priceTypeCampaign,
                    },
                  }),
                  _vm._v(" "),
                  _c("highlight-markings", {
                    attrs: { itemMarkings: _vm.line.markings },
                  }),
                ]
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "break-small" }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "table-column item-amount-col align-right align-left-small small-third",
          },
          [
            _c("span", { staticClass: "hide-medium-up item-small-label" }, [
              _vm._v(
                _vm._s(
                  _vm._f("translate")(
                    "webshop_inventory_table_headers_quantity"
                  )
                )
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "input-wrapper" },
              [
                _vm.invalidAmount
                  ? _c("i", {
                      staticClass: "input-icon mdi mdi-alert",
                      attrs: {
                        title: _vm.$options.filters.translate(
                          "webshop_inventory_table_product_amountWarning"
                        ),
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.successfullyUpdated
                  ? _c("i", { staticClass: "input-icon-updated mdi mdi-check" })
                  : _vm._e(),
                _vm._v(" "),
                _c("input-number", {
                  ref: "inputAmountParent",
                  attrs: {
                    inputUnit: _vm.selectedUnit,
                    inputClass: "item-amount-input",
                    inputTabindex: "0",
                    autocomplete: "off",
                    inputDisabled: _vm.disableAmount,
                    inputStep: _vm.line.unit === "KG" ? 0.001 : 1,
                    inputRef: "inputAmount",
                    "select-onfocus": "true",
                  },
                  on: {
                    keyupenter: function ($event) {
                      return _vm.updateAmount($event, true)
                    },
                    blur: _vm.updateAmount,
                    focus: _vm.onFocusAmount,
                    input: _vm.resetInvalidAmount,
                  },
                  model: {
                    value: _vm.amount,
                    callback: function ($$v) {
                      _vm.amount = $$v
                    },
                    expression: "amount",
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "break-small" }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "table-column align-right align-left-small item-unit small-third",
          },
          [
            _c("span", { staticClass: "hide-medium-up item-small-label" }, [
              _vm._v(
                _vm._s(
                  _vm._f("translate")("webshop_inventory_table_headers_unit")
                )
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm.line.units && _vm.line.units.length > 1
                ? _c("div", { staticClass: "unit input-wrapper" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedUnit,
                            expression: "selectedUnit",
                          },
                        ],
                        staticClass: "unit calc",
                        attrs: { tabindex: "-1" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.selectedUnit = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            function ($event) {
                              return _vm.changeUnit()
                            },
                          ],
                        },
                      },
                      _vm._l(_vm.line.units, function (unit) {
                        return _c(
                          "option",
                          { key: unit.name, domProps: { value: unit.name } },
                          [_vm._v(_vm._s(_vm._f("unitDescription")(unit)))]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "unit only-print" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("unitDescription")(
                            _vm.selectedUnitObj
                              ? _vm.selectedUnitObj
                              : _vm.line.unit
                          )
                        )
                      ),
                    ]),
                  ])
                : _c("span", { staticClass: "unit" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("unitDescription")(
                          _vm.selectedUnitObj
                            ? _vm.selectedUnitObj
                            : _vm.line.unit
                        )
                      )
                    ),
                  ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "break-small" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table-column align-right small-third item-price" },
          [
            _vm.line.productStatus === _vm.itemStatus.Outgoing
              ? _c("span", { staticClass: "inventory-item-outgoing-text" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_inventory_table_product_outgoing_text"
                      )
                    )
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.line.productStatus === _vm.itemStatus.Deleted
              ? _c("span", { staticClass: "inventory-item-deleted-text" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_inventory_table_product_deleted_text"
                      )
                    )
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("span", { staticClass: "hide-medium-up item-small-label" }, [
              _vm._v(
                _vm._s(
                  _vm._f("translate")("webshop_inventory_table_headers_price")
                )
              ),
            ]),
            _vm._v(" "),
            _vm.showPrice
              ? [
                  _vm.line.productId
                    ? _c("inventory-edit-price", {
                        staticClass: "item-price input-wrapper",
                        attrs: { line: _vm.line },
                        on: {
                          updatedPrice: _vm.updateLineShowed,
                          setLoading: _vm.setLoading,
                        },
                      })
                    : _c("span", { staticClass: "item-price-small no-fill" }, [
                        _vm._v(
                          _vm._s(_vm._f("currency")(_vm.line.pricePrUnit))
                        ),
                      ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "item-price no-fill" }, [
                    _vm._v(_vm._s(_vm._f("currency")(_vm.totalPrice))),
                  ]),
                ]
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "table-column i-line-sorting" }, [
          _c(
            "div",
            {
              staticClass: "input-wrapper inventory-sort-order-wrapper",
              staticStyle: { "margin-left": "1rem" },
            },
            [
              _c("span", { staticClass: "hide-medium-up item-small-label" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("translate")(
                      "webshop_inventory_table_headers_sorting"
                    )
                  )
                ),
              ]),
              _vm._v(" "),
              _c("input-number", {
                ref: "inputSortParent",
                attrs: {
                  inputTabindex: "1",
                  inputClass: "item-amount-input inventory-sort-order",
                  onlyInteger: true,
                  "select-onfocus": "true",
                  inputMin: 0,
                  inputRef: "inputSort",
                },
                on: {
                  keyupenter: function ($event) {
                    return _vm.updateSorting($event, true)
                  },
                  blur: _vm.updateSorting,
                },
                model: {
                  value: _vm.sort,
                  callback: function ($$v) {
                    _vm.sort = $$v
                  },
                  expression: "sort",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.list.groups && _vm.list.groups.length > 0,
                  expression: "list.groups && list.groups.length > 0",
                },
              ],
              ref: "group-select-wrapper",
              staticClass: "inventory-sort-order-group-list",
            },
            [
              _c(
                "a",
                {
                  staticClass: "table-column-action",
                  attrs: {
                    title: _vm.$options.filters.translate(
                      "webshop_inventory_table_product_moveGroup"
                    ),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.toggleGroupList()
                    },
                  },
                },
                [_c("i", { staticClass: "mdi mdi-redo" })]
              ),
              _vm._v(" "),
              _vm.showGroupList
                ? _c(
                    "ul",
                    { staticClass: "move-group-list" },
                    [
                      _vm._l(_vm.list.groups, function (group) {
                        return [
                          group.id !== _vm.line.groupId
                            ? _c(
                                "li",
                                {
                                  key: group.id,
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectGroup(group.id)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(group.name))]
                              )
                            : _vm._e(),
                        ]
                      }),
                      _vm._v(" "),
                      _vm.line.groupId !== null
                        ? _c(
                            "li",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.selectGroup(null)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("translate")(
                                    "webshop_general_groupOutside"
                                  )
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _vm.line.editedUnit || _vm.line.editedPrice
            ? _c(
                "a",
                {
                  staticClass: "table-column-action",
                  attrs: {
                    title: _vm.$options.filters.translate(
                      "webshop_inventory_table_product_resetLine"
                    ),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.resetLine()
                    },
                  },
                },
                [_c("i", { staticClass: "mdi mdi-refresh" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass:
                "table-column-action inventory-action-trash hide-medium-up",
              on: {
                click: function ($event) {
                  return _vm.openDeleteComfirm()
                },
              },
            },
            [
              _c(
                "icon-trash",
                {
                  attrs: {
                    focusable: "false",
                    "class-name":
                      _vm.line.productStatus === _vm.itemStatus.Deleted
                        ? "polygon-grey"
                        : "",
                  },
                },
                [_vm._v(">")]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table-column align-right i-line-settings" },
          [
            _c("icon-trash", {
              attrs: {
                focusable: "false",
                "class-name":
                  _vm.line.productStatus === _vm.itemStatus.Deleted
                    ? "polygon-grey"
                    : "",
              },
              on: {
                click: function ($event) {
                  return _vm.openDeleteComfirm()
                },
              },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }