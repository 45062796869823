var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "div-table calculation-list" }, [
    !_vm.isSharedListEmpty
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "columns large-12 tab-nav" }, [
            _c(
              "a",
              {
                staticClass: "tab-nav-link",
                class: { "tab-nav-link-active": !_vm.isSharedList },
                on: {
                  click: function ($event) {
                    _vm.isSharedList = false
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_calculations_calculationList_tab_myCalculations"
                      )
                    ) +
                    "\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "tab-nav-link",
                class: { "tab-nav-link-active": _vm.isSharedList },
                on: {
                  click: function ($event) {
                    _vm.isSharedList = true
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_calculations_calculationList_tab_sharedCalculations"
                      )
                    ) +
                    "\n            "
                ),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "table-header" }, [
      _c("div", { staticClass: "table-row" }, [
        _c("div", { staticClass: "table-column calculation-column-name" }, [
          _vm._v(
            _vm._s(
              _vm._f("translate")(
                "webshop_calculations_calculationList_columnName"
              )
            )
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "table-column" }, [
          _vm._v(
            _vm._s(
              _vm._f("translate")(
                "webshop_calculations_calculationList_columnType"
              )
            )
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "table-column column-justify-end",
            attrs: {
              title: _vm.$options.filters.translate(
                "webshop_calculations_calculationHeader_label_grossProfit"
              ),
            },
          },
          [
            _vm._v(
              _vm._s(
                _vm._f("translate")(
                  "webshop_calculations_calculationList_columnGrossProfit"
                )
              )
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "table-column column-justify-end",
            attrs: {
              title: _vm.$options.filters.translate(
                "webshop_calculations_calculationHeader_label_salesPrice"
              ),
            },
          },
          [
            _vm._v(
              _vm._s(
                _vm._f("translate")(
                  "webshop_calculations_calculationList_columnPriceToCustomer"
                )
              )
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table-column calculation-list-button-create" },
          [
            !_vm.isSharedList
              ? _c(
                  "button",
                  {
                    staticClass:
                      "ui-button ui-button-small button-new-calculation",
                    on: {
                      click: function ($event) {
                        return _vm.createCalculation()
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "mdi mdi-plus" }),
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("translate")(
                            "webshop_calculations_calculationList_buttonCreate"
                          )
                        )
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "table-body calculation-list-table-body" },
      [
        _vm._l(_vm.filteredCalculations, function (calculation) {
          return _c(
            "div",
            {
              key: calculation.calculationId,
              staticClass: "table-outer-row",
              class: {
                active:
                  calculation.calculationId ==
                  _vm.selectedCalculation.calculationId,
              },
            },
            [
              _c("div", { staticClass: "table-row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "table-column calculation-column-name small-third",
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "calculation-list-link",
                        attrs: {
                          to: {
                            name: "calculationDetails",
                            params: {
                              calculationId: calculation.calculationId,
                            },
                          },
                        },
                      },
                      [_vm._v(_vm._s(calculation.name))]
                    ),
                    _vm._v(" "),
                    !_vm.isSharedList && calculation.isShared
                      ? _c("i", {
                          staticClass: "mdi mdi-share-variant",
                          attrs: {
                            title: _vm.$options.filters.translate(
                              "webshop_search_search_filters_iconShareTitle"
                            ),
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "table-column" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.CalculationType[calculation.calculationType]) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "table-column column-justify-end" },
                  [
                    _vm.showPrice
                      ? [
                          _vm._v(
                            _vm._s(_vm._f("currency")(calculation.grossProfit))
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "table-column column-justify-end" },
                  [
                    _vm.showPrice
                      ? [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(calculation.priceToCustomer)
                            )
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "table-column column-justify-end" },
                  [
                    !_vm.isSharedList
                      ? _c("icon-trash", {
                          attrs: { "class-name": "svg-trash-fixed-with" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteCalculation(calculation)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]
          )
        }),
        _vm._v(" "),
        !_vm.loading &&
        (!_vm.filteredCalculations || _vm.filteredCalculations.length == 0)
          ? _c("i", [
              _vm._v(
                _vm._s(
                  _vm._f("translate")(
                    "webshop_calculations_calculationList_emptyListText"
                  )
                )
              ),
            ])
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }