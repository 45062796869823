export class CampaignPlanningCommentVM {
    campaignCode;
    vendorNo;
    vendorItemNo;
    comment;
    fromVendor;
    dateCreated;
    dateCreatedLocal;
    constructor() {
        this.campaignCode = '';
        this.vendorNo = '';
        this.vendorItemNo = '';
        this.comment = '';
        this.fromVendor = false;
    }
    static fromApi(comment) {
        return Object.assign(new CampaignPlanningCommentVM(), comment);
    }
}
