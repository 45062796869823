var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import InventoryProductResultPartial from './InventoryProductResultPartial.vue';
import { ToastService } from 'services/ToastService';
import { InventoryService } from 'services/InventoryService';
import { EventTypes } from 'models/EventTypes';
let InventoryProductOrderPartial = class InventoryProductOrderPartial extends Vue {
    loading;
    list;
    goBack;
    setLoading;
    prefixedPeriod;
    selectedDate = null;
    result = [];
    mounted() {
        if (this.prefixedPeriod) {
            let date = moment().subtract(this.prefixedPeriod, 'months').toDate();
            this.search([date]);
        }
    }
    dpSettings = {
        inline: true,
        enable: [{ from: new Date(1957, 0, 1), to: new Date() }]
    };
    search(date) {
        this.setLoading(true);
        InventoryService.getSaleStatisticsLinesByDate(date[0], this.list.id).then(data => {
            this.result = data;
            if (this.result.length === 0 && !this.prefixedPeriod) {
                ToastService.danger(Vue.filter('translate')('webshop_inventory_addmodals_order_toastNoOrdersFound', Vue.filter('date')(date[0], 'L')));
            }
            this.setLoading(false);
        })
            .catch(err => {
            this.setLoading(false);
            ToastService.danger(Vue.filter('translate')('webshop_defaults_toast_errorText'));
        });
    }
    resetResult() {
        this.result = [];
        this.$root.$emit(EventTypes.InventoryModalProductSelected, null);
    }
};
InventoryProductOrderPartial = __decorate([
    Component({
        components: {
            InventoryProductResultPartial
        },
        props: {
            goBack: Function,
            list: Object,
            setLoading: Function,
            loading: Boolean,
            prefixedPeriod: Number,
        }
    })
], InventoryProductOrderPartial);
export default InventoryProductOrderPartial;
