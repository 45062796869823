var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isModal
    ? _c(
        "a",
        {
          staticClass: "replace",
          attrs: {
            href: "#",
            title: _vm.$options.filters.translate(
              "webshop_search_search_item_obsolete_button_replace_title"
            ),
          },
          on: {
            click: function ($event) {
              return _vm.open()
            },
          },
        },
        [
          _c("span", {
            staticClass: "mdi mdi-cached",
            staticStyle: { "font-size": "25px" },
          }),
        ]
      )
    : _c("div", { staticStyle: { "text-align": "center" } }, [
        _c(
          "a",
          {
            staticClass: "ui-button pull-right",
            on: {
              click: function ($event) {
                return _vm.open()
              },
            },
          },
          [
            _c("span", { staticClass: "mdi mdi-cached right-15" }),
            _vm._v(
              "\r\n        " +
                _vm._s(
                  _vm._f("translate")("webshop_search_search_item_replace")
                ) +
                "\r\n    "
            ),
          ]
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }