import "core-js/stable";
import "regenerator-runtime/runtime";
import "utilities/detect-ie";
import "appstyle.scss";
import "configs/icons.font.config";
/*
import '../js/app.utils.js';
import '../js/app.errorlog.js';
import '../js/dcui.js';
import '../js/app.js';
import '../js/app.service.js';
import '../js/app.favoritelists.js';
import '../js/app.search.js';
//import '../js/app.shop.js';
import '../js/app.layout.js';
//import '../js/app.ipaper.js';
import '../js/app.products.js';
import '../js/app.admin.js';
import '../js/app.themes.js';
import '../js/app.events.js';
*/
import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store";
import ScApp from "components/App.vue";
import VueFlatPickr from "vue-flatpickr-component";
import flatpickr from "flatpickr";
import { Swedish } from "flatpickr/dist/l10n/sv";
import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue);
import "./filters/TranslateFilter";
import "./filters/DateFilter";
import "./filters/CurrencyFilter";
import "./filters/UnitDescriptionFilter";
flatpickr.setDefaults({
    allowInput: true,
    altFormat: "Y-m-d",
    altInput: true,
    dateFormat: "Z",
    locale: Swedish,
    weekNumbers: true,
});
Vue.use(VueFlatPickr);
Vue.use(VueRouter);
new Vue({
    el: "#sc-app",
    store,
    components: { ScApp }, //render: h => h (ScApp)
});
