var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { ToastService } from 'services/ToastService';
import { ApprovalService } from 'services/ApprovalService';
import LoadingButton from '../../shared/LoadingButton.vue';
let LoginAcceptancePartial = class LoginAcceptancePartial extends Vue {
    loading = false;
    rememberMe = false;
    showError = false;
    approvals;
    selectedApprovalIds = [];
    toggle(acceptance) {
        let idx = this.selectedApprovalIds.indexOf(acceptance.id);
        if (idx !== -1) {
            this.selectedApprovalIds.splice(idx, 1);
        }
        else {
            this.selectedApprovalIds.push(acceptance.id);
        }
    }
    accept() {
        if (this.selectedApprovalIds.length !== this.approvals.length) {
            ToastService.danger(Vue.filter('translate')('webshop_login_accept_toastMissingAccept'));
            return;
        }
        this.loading = true;
        ApprovalService.acceptApprovals(this.selectedApprovalIds)
            .then(() => {
            ToastService.success(Vue.filter('translate')('webshop_login_accept_toastSuccess'));
            this.$emit("onComplete");
        })
            .catch(err => {
            ToastService.danger(Vue.filter('translate')('webshop_defaults_toast_errorText'));
            this.loading = false;
            this.selectedApprovalIds = [];
        });
    }
};
LoginAcceptancePartial = __decorate([
    Component({
        components: {
            LoadingButton
        },
        props: {
            approvals: Array
        }
    })
], LoginAcceptancePartial);
export default LoginAcceptancePartial;
