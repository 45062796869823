import Vue from "vue";
Vue.filter("unitDescription", (unit) => {
    if (typeof unit === 'string') {
        return unit;
    }
    else {
        var str = unit.name;
        if (unit.description)
            str += " (" + unit.description + ")";
        return str;
    }
});
