export class Order {
    orderNo;
    created;
    ended;
    active;
    notes;
    deliveryDate;
    procureOrder;
    orderlines;
    orderType;
    delayedDelivery;
    totalPrice;
    ecoPct;
    agreementPct;
    totalDeposit;
    name;
    totalDiscount;
}
export class CompleteOrderVM {
    comments;
    externalDocumentNo;
}
