var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ui-modal-content" }, [
      _c("h3", { staticClass: "ui-modal-header" }, [
        _vm._v(_vm._s(_vm._f("translate")("webshop_favorite_alt_product"))),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "ui-card shop-table-product shop-product-thumb shop-search-product",
          class: {
            "shop-product-inBasket": _vm.data.AltProduct.inBasket,
            "shop-product-expiry": _vm.data.AltProduct.expiry,
          },
        },
        [
          _c("div", { staticClass: "shop-product-imageload" }),
          _vm._v(" "),
          _c("div", { staticClass: "ui-card-image-full" }, [
            _c("div", { staticClass: "productId" }, [
              _vm._v(_vm._s(_vm.data.AltProduct.productId)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "shop-product-thumb-imageWrapper" }, [
              _c("img", {
                staticClass: "product-image",
                attrs: {
                  src: _vm.data.AltProduct.image
                    ? _vm.data.AltProduct.image
                    : "/app/images/no_product_image.jpg",
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ui-card-content" }, [
            _c("div", { staticClass: "content shop-product-thumb-row" }, [
              _c("strong", [
                _c("span", [_vm._v(_vm._s(_vm.data.AltProduct.description))]),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "brand" }, [
                _vm._v(_vm._s(_vm.data.AltProduct.brand)),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "brand" }, [
                _vm._v(
                  _vm._s(_vm._f("translate")("webshop_general_productGroup")) +
                    ": " +
                    _vm._s(_vm.data.AltProduct.postingGroupName)
                ),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "brand shop-product-country" }, [
                _vm._v(_vm._s(_vm.data.AltProduct.country)),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "shop-product-table-icons shop-product-thumb-row",
              },
              [
                _c("shop-item-icons", {
                  attrs: {
                    product: _vm.data,
                    priceTypeCampaign: _vm.priceTypeCampaign,
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "shop-product-loggedin-content" }, [
            _c("div", { staticClass: "ui-card-devider" }),
            _vm._v(" "),
            _vm.data.AltProduct.campaignLinetext
              ? _c("div", { staticClass: "shop-product-campaign-text" }, [
                  _c("div", { staticClass: "ui-card-content" }, [
                    _vm._v(_vm._s(_vm.data.AltProduct.campaignLinetext)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "ui-card-devider" }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.data.AltProduct.expiry
              ? _c("p", { staticClass: "expiry-info" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm._f("translate")("webshop_search_expiry_best_before")
                      ) +
                      ":\n          "
                  ),
                  _c("span", { staticClass: "expiry-date" }, [
                    _vm._v(_vm._s(_vm.data.AltProduct.expiry.date)),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "ui-card-content" }, [
              _vm.data.AltProduct.expiry
                ? _c(
                    "div",
                    { staticClass: "shop-product-thumb-row expiry-wrapper" },
                    [
                      _c("div", { staticClass: "shop-product-thumb-stock" }, [
                        _c("span", { staticClass: "label" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("translate")(
                                "webshop_search_search_view_list_expiryProduct_stockLabel"
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "expiry-stock" }, [
                          _vm._v(_vm._s(_vm.data.AltProduct.expiry.stock)),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "shop-product-thumb-row" }, [
                _c("table", { staticClass: "shop-item-replace-modal-table" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("td", [
                        _c(
                          "span",
                          { staticClass: "notice price-weight-notice" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$options.filters.translate(
                                  "webshop_general_unit"
                                )
                              )
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { align: "right" } }, [
                        _vm.data.AltProduct.weight
                          ? _c(
                              "span",
                              { staticClass: "notice price-weight-notice" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("translate")(
                                      "webshop_general_kiloPrice"
                                    )
                                  )
                                ),
                              ]
                            )
                          : _c(
                              "span",
                              { staticClass: "notice price-weight-notice" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$options.filters.translate(
                                      "webshop_general_price"
                                    )
                                  )
                                ),
                              ]
                            ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [
                        _vm.data.AltProduct.units.length > 1
                          ? _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedUnit,
                                    expression: "selectedUnit",
                                  },
                                ],
                                staticClass: "unit calc",
                                staticStyle: { width: "60%" },
                                attrs: { required: "", disabled: _vm.loading },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.selectedUnit = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                },
                              },
                              _vm._l(
                                _vm.data.AltProduct.units,
                                function (unit) {
                                  return _c(
                                    "option",
                                    {
                                      key: unit.name,
                                      domProps: { value: unit.name },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm._f("unitDescription")(unit))
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _c("span", { staticClass: "single-unit" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("stdUnitDescription")(_vm.stdUnitName)
                                )
                              ),
                            ]),
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { align: "right" } }, [
                        _vm.showPrice
                          ? _c(
                              "span",
                              {
                                staticClass: "price-container",
                                staticStyle: { "font-weight": "700" },
                              },
                              [_vm._v(_vm._s(_vm._f("currency")(_vm.price)))]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "ui-card-devider" }),
            _vm._v(" "),
            _c("div", { staticClass: "ui-card-content" }, [
              _c(
                "div",
                {
                  staticClass:
                    "shop-product-thumb-row shop-product-thumb-footerrow favorites",
                },
                [
                  _c(
                    "div",
                    { staticClass: "shop-product-thumb-quantity-wrapper" },
                    [
                      _vm.getListId
                        ? _c("shop-replace-alt-prod-button", {
                            attrs: {
                              product: _vm.data,
                              loading: _vm.loading,
                              listId: _vm.getListId,
                              isModal: true,
                            },
                            on: { replaced: _vm.replaceProduct },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.getListId
                        ? [
                            _c("div", { staticClass: "buy-wrapper" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.amount,
                                    expression: "amount",
                                  },
                                ],
                                staticClass: "quantity",
                                attrs: {
                                  disabled: _vm.loading,
                                  placeholder: _vm.$options.filters.translate(
                                    "webshop_search_search_view_gallery_amountPlaceholder"
                                  ),
                                  autocomplete: "off",
                                  type: "number",
                                  tabindex: "3",
                                },
                                domProps: { value: _vm.amount },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.addProduct()
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.amount = $event.target.value
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "buy-button" },
                                [
                                  _c("shop-item-buy-button", {
                                    ref: "buyButton",
                                    attrs: {
                                      product: _vm.data.AltProduct,
                                      loading: _vm.loading,
                                      amount: _vm.amount,
                                      selectedUnit: _vm.selectedUnit,
                                      inBasket: _vm.data.AltProduct.inBasket,
                                      view: "gallery",
                                    },
                                    on: {
                                      added: _vm.addedProduct,
                                      amount: _vm.setAmount,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "clear" }),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }