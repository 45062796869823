import { Service } from './Service';
import { StatisticsApi } from '../api/StatisticsApi';
import { ProductSalesStatisticsVM } from 'models/statistics/ProductSalesStatisticsVM';
export class StatisticsService extends Service {
    static getArticlesByDates(productStatisticsFilter) {
        return new Promise((resolve, reject) => {
            StatisticsApi.getByDates(productStatisticsFilter).then((res) => {
                var result = res.data
                    .map(x => ProductSalesStatisticsVM.fromApi(x));
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static getArticlesByDatesExcel(productStatisticsFilter) {
        return StatisticsApi.getByDatesExcel(productStatisticsFilter);
    }
}
