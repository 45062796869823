var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import LoadingButton from '../shared/LoadingButton.vue';
import { validateComparePasswords } from 'helpers/Validaters';
import { AuthService } from 'services/AuthService';
import { ToastService } from 'services/ToastService';
let ChangePasswordModal = class ChangePasswordModal extends Vue {
    loadedTimeout = false;
    loading = false;
    tempForm = {
        oldPassword: '',
        newPassword: '',
        repeatNewPassword: ''
    };
    close() {
        this.$emit('close-modal');
    }
    setupValidate() {
        let newPassDom = this.$refs.newPass;
        let newPassRepDom = this.$refs.newPassRep;
        let errorMessage = Vue.filter('translate')('webshop_login_resetPassword_toasts_notMatching');
        validateComparePasswords(newPassDom, newPassRepDom, errorMessage);
    }
    submit() {
        this.loading = true;
        if (this.tempForm.newPassword == '' || this.tempForm.newPassword != this.tempForm.repeatNewPassword) {
            ToastService.danger(Vue.filter('translate')('webshop_login_resetPassword_toasts_notMatching'));
        }
        else {
            AuthService.changePassword(this.tempForm.oldPassword, this.tempForm.newPassword).then((res) => {
                ToastService.success(Vue.filter('translate')('webshop_login_changePassword_toastSuccess'));
                this.close();
            }).catch((err) => {
                this.loading = false;
                ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
            });
        }
    }
};
ChangePasswordModal = __decorate([
    Component({
        components: {
            LoadingButton
        }
    })
], ChangePasswordModal);
export default ChangePasswordModal;
