var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "slide" }, [
    _c(
      "div",
      { staticClass: "result-list-container", attrs: { container: "" } },
      [
        _c("h2", { staticClass: "header-small header" }, [
          _vm._v(
            _vm._s(
              _vm._f("translate")(
                "webshop_calculations_modals_addProducts_search_result_header"
              )
            )
          ),
        ]),
        _vm._v(" "),
        _vm.showSearchFilter
          ? _c("div", { attrs: { grid: "wrap" } }, [
              _c("div", { attrs: { column: "xs-6 m-4" } }, [
                _c("input", {
                  staticClass: "small-search-input",
                  attrs: {
                    type: "text",
                    placeholder: _vm.$options.filters.translate(
                      "webshop_general_search"
                    ),
                  },
                  on: { input: _vm.searchFilter },
                }),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "result-list" },
          [
            _c("li", { staticClass: "result-line" }, [
              _c("div", { attrs: { grid: "justify-start" } }, [
                _c("div", { attrs: { column: "xs-5 s-6 m-6 l-6" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "clickable",
                      attrs: { grid: "m-no-gutters" },
                      on: {
                        click: function ($event) {
                          return _vm.toggleAllLines()
                        },
                      },
                    },
                    [
                      _c("div", { attrs: { column: "top xs-2 s-1 m-2" } }, [
                        _c("i", {
                          staticClass: "checkbox mdi",
                          class: {
                            "mdi-checkbox-marked": _vm.isAllSelected,
                            "mdi-checkbox-blank-outline": !_vm.isAllSelected,
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "select-all-text",
                          attrs: { column: "top xs-10" },
                        },
                        [
                          _vm._v(
                            "\r\n                                " +
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_calculations_modals_addProducts_search_result_selectAll"
                                )
                              ) +
                              "\r\n                            "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm._l(_vm.filteredLines, function (line) {
              return _c(
                "li",
                { key: line.product.productId, staticClass: "result-line" },
                [
                  _c("div", { attrs: { grid: "wrap" } }, [
                    _c("div", { attrs: { column: "xs-5 s-6 m-6 l-6" } }, [
                      _c(
                        "div",
                        {
                          attrs: { grid: "m-no-gutters clickable" },
                          on: {
                            click: function ($event) {
                              return _vm.toggleSelection(line)
                            },
                          },
                        },
                        [
                          _c("div", { attrs: { column: "xs-2 s-1 m-2" } }, [
                            _c("i", {
                              staticClass: "checkbox mdi",
                              class: [
                                line.selected
                                  ? "mdi-checkbox-marked"
                                  : "mdi-checkbox-blank-outline",
                              ],
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "text-col",
                              attrs: { column: "xs-10" },
                            },
                            [
                              _c("div", { staticClass: "text-wrapper" }, [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v(
                                    _vm._s(line.product.productId) +
                                      " - " +
                                      _vm._s(line.product.brand)
                                  ),
                                ]),
                                _vm._v(
                                  "\r\n                                    " +
                                    _vm._s(line.product.description) +
                                    "\r\n                                    "
                                ),
                                line.product.markings
                                  ? _c(
                                      "div",
                                      { on: { click: _vm.clickItemIcons } },
                                      [
                                        _c("shop-item-icons", {
                                          attrs: {
                                            product: line.product,
                                            priceTypeCampaign:
                                              _vm.priceTypeCampaign(
                                                line.product
                                              ),
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("highlight-markings", {
                                          attrs: {
                                            itemMarkings: line.product.markings,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { attrs: { column: "xs-5 s-6 m-6 l-6" } }, [
                      _c("div", { attrs: { grid: "wrap" } }, [
                        _c("div", { attrs: { column: "xs-8" } }, [
                          _c("span", { staticClass: "label" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")("webshop_general_unit")
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(line.standardUnit.name) +
                                "(" +
                                _vm._s(line.standardUnit.description) +
                                ")"
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "text-right",
                            attrs: { column: "xs-4" },
                          },
                          [
                            _c("span", { staticClass: "label" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("translate")("webshop_general_price")
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.showPrice
                              ? [
                                  _c(
                                    "span",
                                    {
                                      class: {
                                        "item-price-campaign":
                                          line.standardUnit
                                            .priceBeforeCampaign > 0,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("currency")(
                                            line.standardUnit.price
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  line.standardUnit.priceBeforeCampaign
                                    ? _c(
                                        "span",
                                        {
                                          class: {
                                            "price-before-campaign":
                                              line.standardUnit
                                                .priceBeforeCampaign > 0,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("currency")(
                                                line.standardUnit
                                                  .priceBeforeCampaign
                                              )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                    ]),
                  ]),
                ]
              )
            }),
          ],
          2
        ),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "modal-footer-buttons" }, [
      _vm.selectedCount
        ? _c(
            "form",
            {
              staticClass: "add-button-container",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.addProducts()
                },
              },
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("translate")(
                      _vm.selectedCount > 1
                        ? "webshop_calculations_modals_addProducts_search_result_addBtn_preTextMultiProducts"
                        : "webshop_calculations_modals_addProducts_search_result_addBtn_preTextSingleProduct",
                      _vm.selectedCount
                    )
                  ) + " "
                ),
                _c("b", [_vm._v(_vm._s(_vm.list.name))]),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "ui-button ui-button-small ui-button-accept",
                  attrs: { type: "submit" },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_calculations_modals_addProducts_search_result_addBtn_btnText"
                      )
                    )
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }