var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { ToastService } from "services/ToastService";
import { FavoriteService } from "services/FavoriteService";
let FavoriteAddProductOverview = class FavoriteAddProductOverview extends Vue {
    list;
    loading;
    setLoading;
    initSearchQuery;
    searchQuery = '';
    mounted() {
        this.searchQuery = this.initSearchQuery;
    }
    search() {
        if (this.searchQuery && this.searchQuery !== '' && this.searchQuery.trim().length >= 2) {
            this.setLoading(true);
            FavoriteService.searchNewProductsByQuery(this.searchQuery, this.list.id).then(result => {
                if (result && result.length == 0) {
                    ToastService.danger(Vue.filter('translate')('webshop_favorite_addProduct_search_toasts_noResultText'), Vue.filter('translate')('webshop_favorite_addProduct_search_toasts_noResultTitle'));
                    this.setLoading(false);
                }
                else {
                    this.$emit("onResult", result, this.searchQuery);
                }
            })
                .catch(err => {
                this.setLoading(false);
                ToastService.danger(Vue.filter('translate')('webshop_defaults_toast_errorText'));
            });
        }
        else {
            ToastService.danger(Vue.filter('translate')("webshop_favorite_addProduct_search_toasts_emptyInput"));
        }
    }
    openOrderSearch(months) {
        this.$emit("onOrderSearch", months);
    }
};
FavoriteAddProductOverview = __decorate([
    Component({
        props: {
            list: Object,
            loading: Boolean,
            setLoading: Function,
            initSearchQuery: String
        }
    })
], FavoriteAddProductOverview);
export default FavoriteAddProductOverview;
