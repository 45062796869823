var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.campaignLine != null
    ? _c("div", { ref: "commentsFrame", staticClass: "comments-frame" }, [
        _c("div", { staticClass: "ui-card" }, [
          _c("div", { staticClass: "ui-card-header ui-card-header-inverted" }, [
            _vm._v(_vm._s(_vm.campaignLine.description) + "\n            "),
            _c("i", {
              staticClass: "mdi mdi-close",
              on: { click: _vm.closeFrame },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ui-card-content" }, [
            _c(
              "div",
              { ref: "commentsContainer", staticClass: "comments-container" },
              [
                !_vm.loading &&
                (_vm.comments == null || _vm.comments.length == 0)
                  ? _c("div", { staticClass: "comments-empty-list" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("translate")(
                            "webshop_vendor_campaignPlanning_comments_emptyList"
                          )
                        )
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.loading
                  ? [_c("loader", { attrs: { size: "large" } })]
                  : [
                      _vm._l(_vm.comments, function (comment) {
                        return [
                          _c(
                            "div",
                            {
                              key:
                                comment.vendorNo +
                                comment.vendorItemNo +
                                comment.dateCreatedLocal,
                              staticClass: "comment-container",
                              class: { "vendor-comment": comment.fromVendor },
                            },
                            [
                              _c("div", { staticClass: "user-icon" }, [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !comment.fromVendor,
                                        expression: "!comment.fromVendor",
                                      },
                                    ],
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          _vm.assetsBaseLink +
                                          "icons/logo-color.png",
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "comment-text-wrapper" },
                                [
                                  _c("div", { staticClass: "comment-text" }, [
                                    _vm._v(_vm._s(comment.comment)),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "comment-date" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("date")(
                                          comment.dateCreatedLocal,
                                          "YYYY-MM-DD HH:mm:ss"
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      }),
                    ],
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isValidPurchaseDeadline,
                    expression: "isValidPurchaseDeadline",
                  },
                ],
                staticClass: "comment-form",
              },
              [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.newComment,
                        expression: "!newComment",
                      },
                    ],
                    staticClass: "comment-form-label",
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm._f("translate")(
                          "webshop_vendor_campaignPlanning_comments_textInputLabel"
                        )
                      )
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("span", {
                  ref: "textInput",
                  staticClass: "text-input",
                  attrs: { contenteditable: "" },
                  on: {
                    input: function ($event) {
                      return _vm.onCommentInputChange($event)
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "ui-button ui-button-accept",
                    on: { click: _vm.sendMessage },
                  },
                  [_c("i", { staticClass: "mdi mdi-send" })]
                ),
              ]
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }