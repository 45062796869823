var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "review-grade-wrapper text-center" }, [
    _c("p", [
      _vm._v(
        _vm._s(_vm._f("translate")("webshop_campaign_review_gradeHeader"))
      ),
    ]),
    _vm._v(" "),
    _c("div", [
      _c(
        "a",
        {
          staticClass: "review-grade",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              return _vm.rateProduct(1)
            },
          },
        },
        [_c("span", { staticClass: "icon-star" }), _vm._v("1\n    ")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "review-grade",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              return _vm.rateProduct(2)
            },
          },
        },
        [_c("span", { staticClass: "icon-star" }), _vm._v("2\n    ")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "review-grade",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              return _vm.rateProduct(3)
            },
          },
        },
        [_c("span", { staticClass: "icon-star" }), _vm._v("3\n    ")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "review-grade",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              return _vm.rateProduct(4)
            },
          },
        },
        [_c("span", { staticClass: "icon-star" }), _vm._v("4\n    ")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "review-grade",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              return _vm.rateProduct(5)
            },
          },
        },
        [_c("span", { staticClass: "icon-star" }), _vm._v("5\n    ")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "review-grade",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              return _vm.rateProduct(6)
            },
          },
        },
        [_c("span", { staticClass: "icon-star" }), _vm._v("6\n    ")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "review-grade",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              return _vm.rateProduct(7)
            },
          },
        },
        [_c("span", { staticClass: "icon-star" }), _vm._v("7\n    ")]
      ),
    ]),
    _vm._v(" "),
    _c("small", [
      _vm._v(
        _vm._s(_vm._f("translate")("webshop_campaign_review_gradeHelpText"))
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }