var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import Cookies from "js-cookie"; // Import Cookies object
import AgeVerification from "./AgeVerification.vue"; // Import AgeVerification component
import { EventTypes } from "./EventTypes"; // Import EventTypes object
import { ModalService, } from "../../modules/basic-core/basic-modal/src";
import LoginModal from "./LoginModal.vue"; // Import LoginModal component
import DeliveryDateModal from "./DeliveryDateModal.vue"; // Import DeliveryDateModal component
let Login = class Login extends Vue {
    showLogin = false;
    showDeliveryDate = false;
    created() {
        this.$root.$on(EventTypes.LoginModalOpen, this.open);
        this.$root.$on(EventTypes.DeliveryDateModalOpen, this.openDeliveryDate);
    }
    beforeDestroy() {
        this.$root.$off(EventTypes.LoginModalOpen, this.open);
        this.$root.$off(EventTypes.DeliveryDateModalOpen, this.openDeliveryDate);
    }
    open() {
        if (Cookies.get("ageVerified") === "true") {
            ModalService.create({
                cxt: this,
                component: LoginModal,
                maxWidth: "450px",
            });
        }
        else {
            ModalService.create({
                cxt: this,
                component: AgeVerification,
                maxWidth: "450px",
            });
        }
    }
    openDeliveryDate() {
        ModalService.create({
            cxt: this,
            component: DeliveryDateModal,
            maxWidth: "450px",
        });
    }
};
Login = __decorate([
    Component({
        props: {},
    })
], Login);
export default Login;
