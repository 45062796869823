export class InventoryGroup {
    id;
    listId;
    name;
    sorting;
    created;
    updated;
    totalPrice;
    lines;
    constructor() {
        this.id = null;
        this.listId = null;
        this.name = null;
        this.sorting = null;
        this.created = null;
        this.updated = null;
        this.totalPrice = null;
        this.lines = [];
    }
    static fromApi(group) {
        return Object.assign(new InventoryGroup(), group);
    }
}
