import { GAImpression } from 'models/googleAnalytics/GAImpression';
import { GAProduct } from 'models/googleAnalytics/GAProduct';
import { Service } from './Service';
export class GAService extends Service {
    static productImpressions(products) {
        if (window && window["dataLayer"]) {
            const productsObject = GAImpression.ConvertFromProducts(products, window.location.pathname);
            window["dataLayer"].push({ ecommerce: null });
            window["dataLayer"].push({
                ecommerce: {
                    currencyCode: 'SEK',
                    impressions: productsObject
                }
            });
        }
    }
    static productClick(products) {
        if (window && window["dataLayer"]) {
            const productsObject = GAProduct.ConvertFromProducts(products);
            window["dataLayer"].push({ ecommerce: null });
            window["dataLayer"].push({
                event: 'productClick',
                ecommerce: {
                    click: {
                        actionField: { list: window.location.pathname },
                        products: productsObject
                    }
                }
            });
        }
    }
    static productDetail(products) {
        if (window && window["dataLayer"]) {
            const productsObject = GAProduct.ConvertFromProducts(products);
            window["dataLayer"].push({ ecommerce: null });
            window["dataLayer"].push({
                ecommerce: {
                    detail: {
                        actionField: { list: window.location.pathname },
                        products: productsObject
                    }
                }
            });
        }
    }
    static addProductToBasket(products) {
        if (window && window["dataLayer"] && products && products.length) {
            const productsObject = GAProduct.ConvertFromBasketProducts(products);
            window["dataLayer"].push({ ecommerce: null });
            window["dataLayer"].push({
                event: 'addToCart',
                ecommerce: {
                    currencyCode: 'SEK',
                    add: {
                        products: productsObject
                    }
                }
            });
        }
    }
    static removeProductFromBasket(products) {
        if (window && window["dataLayer"] && products && products.length) {
            const productsObject = GAProduct.ConvertFromBasketUpdateOrderLines(products);
            window["dataLayer"].push({ ecommerce: null });
            window["dataLayer"].push({
                event: 'removeFromCart',
                ecommerce: {
                    remove: {
                        products: productsObject
                    }
                }
            });
        }
    }
    static checkoutAndRefundOrder(order) {
        if (window && window["dataLayer"] && order && order.splitOrders && order.splitOrders.length) {
            let checkoutProducts = order.splitOrders.reduce((prevOrderlines, currentOrder) => {
                let orderlines = GAProduct.ConvertFromOrderlines(currentOrder.orderlines);
                return [...prevOrderlines, ...orderlines];
            }, []);
            this.checkout(checkoutProducts, order.discountCodes);
            if (order.depositOrder && order.depositOrder.orderlines) {
                let orderlines = GAProduct.ConvertFromOrderlines(order.depositOrder.orderlines);
                this.refundProduct(order.orderId, orderlines);
            }
        }
    }
    static checkout(products, discountCodes) {
        let discountCodesString = '';
        if (discountCodes.length) {
            discountCodesString = discountCodes.join(',');
        }
        window["dataLayer"].push({ ecommerce: null });
        window["dataLayer"].push({
            event: 'checkout',
            ecommerce: {
                checkout: {
                    actionField: { step: 1, coupon: discountCodesString },
                    products: products
                }
            }
        });
        console.log(window["dataLayer"]);
    }
    static refundProduct(orderId, productsObject) {
        window["dataLayer"].push({ ecommerce: null });
        window["dataLayer"].push({
            ecommerce: {
                refund: {
                    actionField: { id: orderId },
                    products: productsObject
                }
            }
        });
        console.log(window["dataLayer"]);
    }
}
