var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loaded && !!_vm.currentOrder
    ? _c("div", { staticClass: "shop-order" }, [
        _c("div", { staticClass: "section section-medium" }, [
          _c("div", { attrs: { container: "" } }, [
            _c("div", { attrs: { grid: "wrap" } }, [
              _c("div", { attrs: { column: "s-12 m-12" } }, [
                _c("div", { staticClass: "ui-card" }, [
                  _c(
                    "div",
                    { staticClass: "ui-card-header ui-card-header-inverted" },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm._f("translate")("webshop_order_delivery")
                          ) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "ui-card-content" }, [
                    _c(
                      "div",
                      { staticClass: "ui-form" },
                      [
                        _c("div", { staticClass: "ui-form-fieldwrapper" }, [
                          _c("div", { attrs: { grid: "wrap" } }, [
                            _c("div", { attrs: { column: "s-12 l-4" } }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(
                                    _vm._f("translate")(
                                      "webshop_order_deliveryDate"
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { attrs: { column: "s-12 l-8" } },
                              [
                                _c("basket-delivery-date-change", {
                                  attrs: {
                                    "delivery-date":
                                      _vm.currentOrder.selectedDeliveryDay,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "ui-form-fieldwrapper" }, [
                          _c("div", { attrs: { grid: "wrap" } }, [
                            _c("div", { attrs: { column: "s-12 l-4" } }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(
                                    _vm._f("translate")(
                                      "webshop_order_deliveryForm"
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { attrs: { column: "s-12 l-5 xl-4" } },
                              [
                                _c("basket-change-delivery-form", {
                                  attrs: { "current-order": _vm.currentOrder },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "ui-form-fieldwrapper" }, [
                          _c("div", { attrs: { grid: "wrap" } }, [
                            _c("div", { attrs: { column: "s-12 l-4" } }, [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(
                                    _vm._f("translate")(
                                      "webshop_order_yourReference"
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { attrs: { column: "s-12 l-8" } }, [
                              _c("div", { attrs: { grid: "wrap" } }, [
                                _c("div", { attrs: { column: "s-12 l-8" } }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.currentOrder.yourReference)
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        !_vm.punchOut
                          ? [
                              _c(
                                "div",
                                { staticClass: "ui-form-fieldwrapper" },
                                [
                                  _c("div", { attrs: { grid: "wrap" } }, [
                                    _c(
                                      "div",
                                      { attrs: { column: "s-12 l-4" } },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm._f("translate")(
                                                "webshop_order_deliveryMessage"
                                              )
                                            ) +
                                            "\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { attrs: { column: "s-12 l-8" } },
                                      [
                                        _c("textarea", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.order.comments,
                                              expression: "order.comments",
                                            },
                                          ],
                                          staticClass: "comments",
                                          attrs: {
                                            rows: "2",
                                            cols: "20",
                                            autocomplete: "off",
                                            name: "comments",
                                            maxlength: "150",
                                            form: "basketForm",
                                            placeholder:
                                              _vm.$options.filters.translate(
                                                "webshop_order_deliveryMessagePlaceholder"
                                              ),
                                          },
                                          domProps: {
                                            value: _vm.order.comments,
                                          },
                                          on: {
                                            keyup: function ($event) {
                                              return _vm.setOrderComments()
                                            },
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.order,
                                                "comments",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "ui-form-fieldwrapper" },
                                [
                                  _c("div", { attrs: { grid: "wrap" } }, [
                                    _c(
                                      "div",
                                      { attrs: { column: "s-12 l-4" } },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm._f("translate")(
                                                "webshop_order_externalDocumentNo"
                                              )
                                            ) +
                                            "\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { attrs: { column: "s-12 l-8" } },
                                      [
                                        _c("div", { attrs: { grid: "wrap" } }, [
                                          _c(
                                            "div",
                                            { attrs: { column: "s-12 l-8" } },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.order
                                                        .externalDocumentNo,
                                                    expression:
                                                      "order.externalDocumentNo",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "text",
                                                  name: "externalDocumentNo",
                                                  maxlength: "35",
                                                  placeholder:
                                                    _vm.$options.filters.translate(
                                                      "webshop_order_externalDocumentNo_placeholder",
                                                      _vm.currentOrder.orderId
                                                    ),
                                                  disabled: _vm.loading,
                                                  autocomplete: "off",
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.order
                                                      .externalDocumentNo,
                                                },
                                                on: {
                                                  keyup:
                                                    _vm.setExternalDocumentNo,
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.order,
                                                      "externalDocumentNo",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "ui-form-fieldwrapper" },
                                [
                                  _c("div", { attrs: { grid: "wrap" } }, [
                                    _c(
                                      "div",
                                      { attrs: { column: "s-12 l-4" } },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm._f("translate")(
                                                "webshop_order_addDiscountCode"
                                              )
                                            ) +
                                            "\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { attrs: { column: "s-12 l-8" } },
                                      [
                                        _c("div", { attrs: { grid: "wrap" } }, [
                                          _c(
                                            "div",
                                            { attrs: { column: "s-12 l-4" } },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.discountCode,
                                                    expression: "discountCode",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "text",
                                                  name: "discountCode",
                                                  placeholder:
                                                    _vm.$options.filters.translate(
                                                      "webshop_order_discountCodePlaceholder"
                                                    ),
                                                  maxlength: "20",
                                                  disabled:
                                                    _vm.loading ||
                                                    (_vm.activeDiscountCodes &&
                                                      _vm.activeDiscountCodes
                                                        .length > 4),
                                                  autocomplete: "off",
                                                },
                                                domProps: {
                                                  value: _vm.discountCode,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.discountCode =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { attrs: { column: "s-12 l-8" } },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "ui-button ui-button-small save-discount-code",
                                                  attrs: {
                                                    disabled:
                                                      _vm.loading ||
                                                      (_vm.activeDiscountCodes &&
                                                        _vm.activeDiscountCodes
                                                          .length > 4),
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addDiscountCode()
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                              " +
                                                      _vm._s(
                                                        _vm._f("translate")(
                                                          "webshop_order_buttonAddDiscountCode"
                                                        )
                                                      ) +
                                                      "\n                            "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.activeDiscountCodes &&
                              _vm.activeDiscountCodes.length > 0
                                ? _c(
                                    "div",
                                    { staticClass: "ui-form-fieldwrapper" },
                                    [
                                      _c("div", { attrs: { grid: "wrap" } }, [
                                        _c(
                                          "div",
                                          { attrs: { column: "s-12 l-4" } },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm._f("translate")(
                                                    "webshop_order_activeDiscountCodes"
                                                  )
                                                ) +
                                                "\n                      "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { attrs: { column: "s-12 l-8" } },
                                          [
                                            _c(
                                              "div",
                                              { attrs: { grid: "wrap" } },
                                              [
                                                _c(
                                                  "div",
                                                  { attrs: { column: "s-12" } },
                                                  _vm._l(
                                                    _vm.activeDiscountCodes,
                                                    function (code) {
                                                      return _c(
                                                        "button",
                                                        {
                                                          key:
                                                            "discount-code-" +
                                                            code,
                                                          staticClass:
                                                            "ui-button ui-button-small ui-button-secondary active-discount-code",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.removeDiscountCode(
                                                                code
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                              " +
                                                              _vm._s(code)
                                                          ),
                                                          _c("i", {
                                                            staticClass:
                                                              "mdi mdi-close",
                                                          }),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "section section-medium" }, [
          _c("div", { attrs: { container: "" } }, [
            _c("div", { attrs: { grid: "wrap" } }, [
              _c(
                "div",
                { attrs: { column: "" } },
                [
                  !_vm.punchOut
                    ? _c("div", [
                        _c(
                          "div",
                          { staticStyle: { "margin-bottom": "30px" } },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "ui-button ui-button-small",
                                on: { click: _vm.openDepositModal },
                              },
                              [
                                _c("i", { staticClass: "mdi mdi-plus" }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm._f("translate")(
                                        "webshop_deposit_modal_toggle"
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.currentOrder.splitOrders, function (order, index) {
                    return _c("basket-order-part", {
                      key: index,
                      attrs: { order: order },
                    })
                  }),
                  _vm._v(" "),
                  _vm.hasDepositProducts
                    ? _c("basket-deposit-part", {
                        attrs: { order: _vm.currentOrder.depositOrder },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.checkoutCampaigns
                    ? _c(
                        "div",
                        { staticClass: "checkout-offers" },
                        _vm._l(_vm.checkoutCampaigns, function (campaign) {
                          return _c("check-out-basket-render", {
                            key: campaign.id,
                            attrs: {
                              checkoutvm: campaign,
                              imageurl: campaign.imageUrl,
                            },
                            on: {
                              reload: function ($event) {
                                return _vm.onReload()
                              },
                            },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c("div", { attrs: { grid: "" } }, [
              _c("div", { attrs: { column: "" } }, [
                _c("div", { staticClass: "shop-accept-order-footer" }, [
                  _vm.currentOrder.expiryProducts
                    ? _c("div", { staticClass: "expiry-products-disclaimer" }, [
                        _c(
                          "div",
                          { staticClass: "expiry-products-container" },
                          [
                            _c("p", [
                              _c("small", { staticClass: "small" }, [
                                _c("i", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("translate")(
                                        "webshop_order_expiryProducts_disclaimer"
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { attrs: { grid: "wrap" } }, [
                    _c("div", { attrs: { column: "s-12 l-8" } }, [
                      _c("p", { staticStyle: { "margin-bottom": "10px" } }, [
                        _c("small", { staticClass: "small" }, [
                          _c("i", [
                            _vm._v(
                              "\n                      " +
                                _vm._s(
                                  _vm._f("translate")(
                                    "webshop_order_reservationMessage"
                                  )
                                ) +
                                "\n                      "
                            ),
                            _c("a", { attrs: { href: _vm.conditionsUrl } }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("translate")(
                                    "webshop_order_reservationMessageConditions"
                                  )
                                )
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticStyle: { "margin-bottom": "10px" } }, [
                        _c("small", { staticClass: "small" }, [
                          _c("i", [
                            _vm._v(
                              "\n                      *\n                      " +
                                _vm._s(
                                  _vm._f("translate")(
                                    "webshop_order_warnings_temporaryPrice"
                                  )
                                )
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { attrs: { column: "s-12 l-4" } }, [
                      _c(
                        "form",
                        {
                          attrs: { name: "basketForm" },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.submitOrder()
                            },
                          },
                        },
                        [
                          _vm.searchUrl
                            ? _c(
                                "a",
                                {
                                  staticClass: "ui-button ui-button-secondary",
                                  attrs: { href: _vm.searchUrl },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("translate")(
                                        "webshop_order_ContinueShoppingButton"
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.punchOut
                            ? _c("loading-button", {
                                class: {
                                  "ui-button-disabled": !_vm.enableSubmitOrder,
                                },
                                attrs: {
                                  disabled: !_vm.enableSubmitOrder,
                                  "icon-class": "mdi-cart",
                                  text: _vm.$options.filters.translate(
                                    "webshop_order_complete_acceptOrder"
                                  ),
                                  "loading-text":
                                    _vm.$options.filters.translate(
                                      "webshop_order_complete_submitting_order"
                                    ),
                                  loading: _vm.loading,
                                  title: !_vm.enableSubmitOrder
                                    ? _vm.$options.filters.translate(
                                        "webshop_general_noAccess"
                                      )
                                    : "",
                                },
                              })
                            : [
                                _c("loading-button", {
                                  attrs: {
                                    "icon-class": "mdi-cart",
                                    text: _vm.$options.filters.translate(
                                      "webshop_order_complete_punchoutOrder"
                                    ),
                                    "loading-text":
                                      _vm.$options.filters.translate(
                                        "webshop_order_complete_submitting_punchoutOrder"
                                      ),
                                    loading: _vm.loading,
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: false,
                                      expression: "false",
                                    },
                                  ],
                                  key: new Date().getMilliseconds(),
                                  ref: "punchOutForm",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.currentOrder.punchOutForm
                                    ),
                                  },
                                }),
                              ],
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }