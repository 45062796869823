import { Service } from './Service';
import { ToastType } from '../models/utility/ToastType';
//import * as shortid from 'shortid';
export class ToastService extends Service {
    //static toasts: Array<Toast> = new Array<Toast>();
    static create(content, type, title = null, duration = null) {
        return new Promise((resolve, reject) => {
            // let toast = new Toast(shortid.generate(), content, type, duration);
            // ToastService.toasts.push(toast);
            // setTimeout(() => {
            // 	let idx = ToastService.toasts.findIndex((idxToast: Toast) => {
            // 		return idxToast.id == toast.id;
            // 	});
            // 	ToastService.toasts.splice(idx, 1);
            // 	resolve();
            // }, toast.duration);
            let settings = duration ? { duration: duration } : undefined;
            $.dcui.toast(content, type, title, settings);
            resolve();
        });
    }
    static success(content, title = null, duration = null) {
        return ToastService.create(content, ToastType.Success, title, duration);
    }
    static danger(content, title = null, duration = null) {
        return ToastService.create(content, ToastType.Danger, title, duration);
    }
}
