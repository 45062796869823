var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import LoadingButton from '../shared/LoadingButton.vue';
import { AuthService } from 'services/AuthService';
import { ToastService } from 'services/ToastService';
import { validateComparePasswords } from 'helpers/Validaters';
import { getParameterByName } from 'helpers/QueryParameter';
let ResetPassword = class ResetPassword extends Vue {
    loading = false;
    submitted = false;
    tempForm = {
        newPassword: '',
        repeatNewPassword: ''
    };
    tokenParm;
    guidParam;
    activation = false;
    mounted() {
        this.tokenParm = getParameterByName('token');
        this.guidParam = getParameterByName('guid');
        this.activation = getParameterByName('a') === "true"; //activation
        this.setupValidate();
    }
    submit() {
        this.loading = true;
        if (this.tempForm.newPassword == '' || this.tempForm.newPassword != this.tempForm.repeatNewPassword) {
            ToastService.danger(Vue.filter('translate')('webshop_login_resetPassword_toasts_notMatching'));
        }
        else {
            this.submitted = true;
            AuthService.resetPassword(this.guidParam, this.tokenParm, this.tempForm.newPassword).then((res) => {
                if (this.activation) {
                    ToastService.success(Vue.filter('translate')('webshop_login_resetPassword_toasts_success_activated'));
                }
                else {
                    ToastService.success(Vue.filter('translate')('webshop_login_resetPassword_toasts_success'));
                }
                setTimeout(function () {
                    window.location.href = "/?forceLogin=true";
                }, 1000);
            }).catch((err) => {
                this.submitted = false;
                this.loading = false;
                if (err === 409 || err === 400) {
                    ToastService.danger(Vue.filter('translate')('webshop_login_resetPassword_toasts_expired'));
                }
                else {
                    ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
                }
            });
        }
    }
    setupValidate() {
        let newPassDom = this.$refs.newPass;
        let newPassRepDom = this.$refs.newPassRep;
        let errorMessage = Vue.filter('translate')('webshop_login_resetPassword_toasts_notMatching');
        validateComparePasswords(newPassDom, newPassRepDom, errorMessage);
    }
};
ResetPassword = __decorate([
    Component({
        components: {
            LoadingButton
        }
    })
], ResetPassword);
export default ResetPassword;
