var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { WindowService } from 'services/WindowService';
import { PdfReportService } from 'services/PdfReportService';
import { ToastService } from "services/ToastService";
import { InventoryService } from 'services/InventoryService';
let InventoryPrintSelect = class InventoryPrintSelect extends Vue {
    data;
    lists = [];
    loading = false;
    selectedLists = [];
    print() {
        if (!(this.selectedLists.length > 0)) {
            ToastService.danger(Vue.filter("translate")("webshop_inventory_print_modal_non_selected"));
            return;
        }
        //Needed to trick the popup blocker to not block the window
        const newWindow = WindowService.initializeNewWindow();
        PdfReportService.printAllLists(this.data, this.selectedLists.map(x => x.id)).then(res => {
            const file = new Blob([res], { type: "application/pdf" });
            const nav = window.navigator;
            if (window.navigator && nav.msSaveOrOpenBlob) {
                nav.msSaveOrOpenBlob(file, 'inventeringslistor.pdf');
                setTimeout(() => {
                    newWindow.close();
                }, 100);
            }
            else {
                const reportLink = window.URL.createObjectURL(file);
                newWindow.location = reportLink;
            }
            this.loading = false;
        }, err => {
            setTimeout(() => {
                newWindow.close();
                if (err === 416) {
                    ToastService.danger(Vue.filter("translate")("webshop_inventory_no_products"));
                }
                else {
                    ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
                }
            }, 500);
            return;
        });
    }
    mounted() {
        this.loadAllLists();
    }
    loadAllLists() {
        this.loading = true;
        return InventoryService.getAllLists()
            .then(data => {
            this.lists = data;
            this.loading = false;
        })
            .catch(err => {
            ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
            this.loading = false;
        });
    }
    toggleListSelect(list) {
        const index = this.selectedLists.findIndex(x => x.id == list.id);
        if (index == -1) {
            this.selectedLists.push(list);
        }
        else {
            this.selectedLists.splice(index, 1);
        }
    }
    listSelected(list) {
        return this.selectedLists.findIndex(x => x.id == list.id) != -1;
    }
};
InventoryPrintSelect = __decorate([
    Component({
        components: {},
        props: {
            data: Boolean
        }
    })
], InventoryPrintSelect);
export default InventoryPrintSelect;
