var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { ToastService } from 'services/ToastService';
import { InventoryService } from 'services/InventoryService';
import { EventTypes } from 'models/EventTypes';
let InventoryAutolagerModal = class InventoryAutolagerModal extends Vue {
    listId;
    loading = false;
    selectedDate = null;
    submitted = false;
    result = [];
    dpSettings = {
        inline: true,
        enable: [{ from: new Date(1957, 0, 1), to: new Date() }]
    };
    reset() {
        this.submitted = false;
        this.result = [];
    }
    submit() {
        let selectedDate = new Date(this.selectedDate);
        if (!selectedDate) {
            ToastService.danger(Vue.filter('translate')('webshop_inventory_autolager_toasts_noSelectedDate'));
            return;
        }
        if (selectedDate > new Date()) {
            ToastService.danger(Vue.filter('translate')('webshop_inventory_autolager_toasts_unvalid_date'));
            return;
        }
        // Check to make sure its compatible with sql datetime
        if (selectedDate < new Date(1957, 0, 1)) {
            ToastService.danger(Vue.filter('translate')('webshop_inventory_autolager_toasts_unvalid_date'));
            return;
        }
        this.loading = true;
        InventoryService.autolager(selectedDate).then((result) => {
            ToastService.success(Vue.filter('translate')('webshop_inventory_autolager_toasts_submitted'));
            this.result = result;
            this.submitted = true;
            this.loading = false;
            this.$root.$emit(EventTypes.InventoryReloadAllLists, this.listId);
        }).catch(err => {
            this.loading = false;
            ToastService.danger(Vue.filter('translate')('webshop_defaults_toast_errorText'));
        });
    }
    close() {
        this.$root.$emit(EventTypes.InventoryModalAutolagerClose);
    }
};
InventoryAutolagerModal = __decorate([
    Component({
        components: {},
        props: {
            listId: Number
        }
    })
], InventoryAutolagerModal);
export default InventoryAutolagerModal;
