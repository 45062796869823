export function getWeekNumber(date) {
    const dayInMilliseconds = 1000 * 60 * 60 * 24;
    const dateFormatted = new Date(date);
    const currentYear = dateFormatted.getFullYear();
    const firstDayOfYear = new Date(dateFormatted.getFullYear(), 0, 1);
    const dateWithoutTime = new Date(dateFormatted.getFullYear(), dateFormatted.getMonth(), dateFormatted.getDate());
    const timeBetween = dateWithoutTime.getTime() - firstDayOfYear.getTime();
    const dayOfYear = timeBetween / dayInMilliseconds + 1;
    let dayOfWeek = dateFormatted.getDay();
    dayOfWeek = dayOfWeek === 0 ? 7 : dayOfWeek;
    let weekNumber = Math.floor((10 + dayOfYear - dayOfWeek) / 7);
    if (weekNumber < 1)
        weekNumber = weeksPerYear(currentYear - 1);
    else if (weekNumber > weeksPerYear(currentYear))
        weekNumber = 1;
    return weekNumber;
}
function weeksPerYear(year) {
    const isLeapYear = weekDayForLastDayOfYear(year);
    const isLastYearLeap = weekDayForLastDayOfYear(year - 1);
    return 52 + (isLeapYear === 4 || isLastYearLeap === 3 ? 1 : 0);
}
function weekDayForLastDayOfYear(year) {
    // let day = new Date(year, 11, 31).getDay();
    // return day === 0 ? 7: day;
    return (year + Math.ceil(year / 4) - Math.ceil(year / 100) + Math.ceil(year / 400)) % 7;
}
