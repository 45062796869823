var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import ShopGalleryItem from "components/shop/partials/ShopGalleryItem.vue";
import Loader from 'components/utility/Loader.vue';
import { ProductService } from 'services/ProductService';
import { Carousel, Slide } from 'vue-carousel';
import { OrderlineUISourceTypes } from 'models/order/OrderlineUISourceTypes';
let BasketAlsoBoughtProducts = class BasketAlsoBoughtProducts extends Vue {
    productId;
    loading = false;
    products = new Array();
    perPageCustom = [[0, 1], [640, 2], [968, 3], [1280, 4]];
    OrderlineUISourceTypes = OrderlineUISourceTypes;
    mounted() {
        this.showAlsoBoughtProducts();
        //this.$root.$on(EventTypes.BasketUpdate, this.showAlsoBoughtProducts);
    }
    beforeDestroy() {
        //this.$root.$off(EventTypes.BasketUpdate);
    }
    showAlsoBoughtProducts() {
        if (this.loading)
            return;
        this.loading = true;
        ProductService.getAlsoBoughtItems(this.productId).then((res) => {
            if (res == undefined || res && res.length == 0) {
                this.$emit('onEmptyList', this.productId);
            }
            else {
                this.products = res;
            }
        }).catch(err => {
            this.$emit('onEmptyList', this.productId);
        }).finally(() => {
            this.loading = false;
        });
    }
};
BasketAlsoBoughtProducts = __decorate([
    Component({
        components: {
            ShopGalleryItem,
            Loader,
            Carousel,
            Slide
        },
        props: {
            productId: String
        }
    })
], BasketAlsoBoughtProducts);
export default BasketAlsoBoughtProducts;
