var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
Component.registerHooks([
    'beforeRouteUpdate'
]);
import InventoryHeader from './InventoryHeader.vue';
import InventoryTable from './InventoryTable.vue';
import { ToastService } from 'services/ToastService';
import { InventoryService } from 'services/InventoryService';
import { EventTypes } from 'models/EventTypes';
let InventoryDetail = class InventoryDetail extends Vue {
    id = null;
    list = null;
    loading;
    setLoading;
    loaded = false;
    created() {
        this.$root.$on(EventTypes.InventoryLineChangedGroup, this.lineChangedGroup);
        this.$root.$on(EventTypes.InventoryLineUpdated, this.updatedLine);
        this.$root.$on(EventTypes.InventoryLineAdded, this.addedLine);
        this.$root.$on(EventTypes.InventoryLineDeleted, this.deletedLine);
        this.$root.$on(EventTypes.InventoryReloadList, this.loadList);
        this.$root.$on(EventTypes.InventoryGroupUpdated, this.groupUpdated);
    }
    beforeDestroy() {
        this.$root.$off(EventTypes.InventoryLineChangedGroup, this.lineChangedGroup);
        this.$root.$off(EventTypes.InventoryLineUpdated, this.updatedLine);
        this.$root.$off(EventTypes.InventoryLineAdded, this.addedLine);
        this.$root.$off(EventTypes.InventoryLineDeleted, this.deletedLine);
        this.$root.$off(EventTypes.InventoryReloadList, this.loadList);
        this.$root.$off(EventTypes.InventoryGroupUpdated, this.groupUpdated);
    }
    mounted() {
        this.loadList(this.$route.params.id, true);
    }
    beforeRouteUpdate(to, from, next) {
        this.loadList(to.params.id);
        this.$root.$emit(EventTypes.InventoryRouteChanged);
        next();
    }
    loadList(id, scroll = false) {
        this.setLoading(true);
        this.id = parseInt(id);
        if (Number.isInteger(this.id)) {
            this.loaded = false;
            InventoryService.getListById(this.id).then(data => {
                this.list = data;
                this.setLoading(false);
                this.loaded = true;
                this.$root.$emit(EventTypes.InventoryListUpdated, this.list);
                if (scroll) {
                    this.scrollToList();
                }
            })
                .catch(err => {
                if (err === 404) {
                    this.handleNotFound();
                }
                else {
                    this.setLoading(false);
                    ToastService.danger(Vue.filter('translate')('webshop_defaults_toast_errorText'));
                }
            });
        }
        else {
            this.handleNotFound();
        }
    }
    scrollToList() {
        this.$root.$emit(EventTypes.InventoryScrollToList);
    }
    handleNotFound() {
        ToastService.danger(Vue.filter('translate')('webshop_inventory_detail_toastListNotFound'));
        this.$router.replace({ name: 'inventoryOverview' });
    }
    lineChangedGroup(newLine, oldGroupId, newGroupId) {
        let groupLess = this.list.linesNotInGroup;
        let groups = this.list.groups;
        let oldLines = this.getLineArray(oldGroupId);
        ;
        let index = oldLines.findIndex(line => line.id == newLine.id);
        let oldUnits = oldLines[index].units;
        newLine.units = oldUnits;
        this.$delete(oldLines, index);
        if (newGroupId === null) {
            groupLess.push(newLine);
        }
        else {
            let newGroup = groups.find(group => group.id === newGroupId);
            newGroup.lines.push(newLine);
        }
        this.$root.$emit(EventTypes.InventoryListUpdated, this.list);
        this.setLoading(false);
    }
    updatedLine(updatedLine) {
        let lineArray = this.getLineArray(updatedLine.groupId);
        let index = lineArray.findIndex(line => line.id === updatedLine.id);
        let oldLine = lineArray[index];
        this.$set(lineArray, index, updatedLine);
        /* if (oldLine.sorting !== updatedLine.sorting) { */
        const oldSorting = oldLine.sorting;
        const newSorting = updatedLine.sorting;
        const element = lineArray[newSorting];
        // This is used for old sorting. Save for later.    
        // element.sorting+= oldSorting > newSorting ? 1 : -1; //UP : Down;
        lineArray.sort((a, b) => {
            let trueA = a.sorting || 0;
            if (trueA == 0)
                trueA = Number.MAX_SAFE_INTEGER;
            let trueB = b.sorting || 0;
            if (trueB == 0)
                trueB = Number.MAX_SAFE_INTEGER;
            if (trueA > trueB)
                return 1;
            if (trueA < trueB)
                return -1;
            if (trueA == trueB) {
                return a.description.localeCompare(b.description);
            }
            return 0;
        });
        /* } */
        this.$root.$emit(EventTypes.InventoryListUpdated, this.list);
    }
    addedLine(addedLine) {
        let lineArray = this.getLineArray(addedLine.groupId);
        lineArray.push(addedLine);
        this.$root.$emit(EventTypes.InventoryListUpdated, this.list);
    }
    deletedLine(deletedLine) {
        let lineArray = this.getLineArray(deletedLine.groupId);
        let index = lineArray.findIndex(line => line.id === deletedLine.id);
        this.$delete(lineArray, index);
        for (let sortIndex = index; sortIndex < lineArray.length; sortIndex++) {
            const element = lineArray[sortIndex];
            element.sorting = sortIndex;
        }
        this.$root.$emit(EventTypes.InventoryListUpdated, this.list);
    }
    groupUpdated(updatedGroup) {
        let groupArray = this.list.groups;
        let index = groupArray.findIndex(x => x.id == updatedGroup.id);
        let oldGroup = groupArray[index];
        this.$set(groupArray, index, updatedGroup);
        if (oldGroup.sorting !== updatedGroup.sorting) {
            const oldSorting = oldGroup.sorting;
            const newSorting = updatedGroup.sorting;
            const element = groupArray[newSorting];
            element.sorting += oldSorting > newSorting ? 1 : -1; //UP : Down;
            groupArray.sort((a, b) => {
                if (a.sorting < b.sorting)
                    return -1;
                if (a.sorting > b.sorting)
                    return 1;
                return 0;
            });
        }
        this.$root.$emit(EventTypes.InventoryListUpdated, this.list);
    }
    getLineArray(groupId) {
        let lineArray;
        if (groupId === null) {
            lineArray = this.list.linesNotInGroup;
        }
        else {
            lineArray = this.list.groups
                .find(group => group.id === groupId)
                .lines;
        }
        return lineArray;
    }
};
InventoryDetail = __decorate([
    Component({
        components: {
            InventoryHeader,
            InventoryTable
        },
        props: {
            loading: Boolean,
            setLoading: Function
        }
    })
], InventoryDetail);
export default InventoryDetail;
