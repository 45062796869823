var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import LoadingButton from '../shared/LoadingButton.vue';
import { ToastService } from 'services/ToastService';
import { FavoriteService } from 'services/FavoriteService';
import { EventTypes } from 'models/EventTypes';
import { SearchInput } from 'models/search/SearchInput';
let FavoriteDeleteConfirmModal = class FavoriteDeleteConfirmModal extends Vue {
    data;
    loading = false;
    beforeDestroy() {
        this.$root.$off(EventTypes.ShopSearchDone, this.closeModal);
    }
    closeModal() {
        this.$emit('close-modal');
    }
    submit() {
        this.loading = true;
        FavoriteService.deleteList(this.data.list.id).then(() => {
            ToastService.success(Vue.filter('translate')('webshop_favorite_deleteList_toast_success'));
            let index = this.data.lists.findIndex(x => x.id === this.data.list.id);
            this.data.lists.splice(index, 1);
            this.$root.$emit(EventTypes.ShopSearchStart, new SearchInput());
            this.$root.$once(EventTypes.ShopSearchDone, this.closeModal);
        }).catch(err => {
            this.loading = false;
            ToastService.danger(Vue.filter("translate")('webshop_favorite_deleteList_toast_fail'));
        });
    }
};
FavoriteDeleteConfirmModal = __decorate([
    Component({
        components: {
            LoadingButton
        },
        props: {
            data: Object
        }
    })
], FavoriteDeleteConfirmModal);
export default FavoriteDeleteConfirmModal;
