var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "shop-product-loading": this.loading,
        "shop-orderpart-product-broached": _vm.orderline.broached,
        "has-warnings":
          _vm.orderline.warnings && _vm.orderline.warnings.length > 0,
        "obsolete-product": _vm.orderline.unrecognized,
        "has-alternative": _vm.orderline.altOrderline,
        "alternate-product": _vm.orderline.isAlternative,
        "table-outer-row": !_vm.isInline,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "table-row wrap",
          class: {
            "original-product":
              _vm.orderline.altOrderline && !_vm.orderline.isAlternative,
          },
        },
        [
          _c("div", { staticClass: "table-column product-id-column" }, [
            !(
              _vm.orderline.unrecognized ||
              _vm.orderline.blocked ||
              _vm.orderline.saleBlocked
            ) &&
            !_vm.orderline.altOrderline &&
            _vm.orderline.lineprice > -1
              ? _c("i", {
                  staticClass: "mdi also-bought-products-icon",
                  class: {
                    "mdi-minus": _vm.showAlsoBoughtProducts,
                    "mdi-plus": !_vm.showAlsoBoughtProducts,
                  },
                  attrs: {
                    title: _vm._f("translate")(
                      "webshop_order_tooltipAlsoBoughtProducts"
                    ),
                  },
                  on: {
                    click: function ($event) {
                      _vm.showAlsoBoughtProducts = !_vm.showAlsoBoughtProducts
                    },
                  },
                })
              : _vm._e(),
            _vm._v(
              "\r\n            " +
                _vm._s(_vm.orderline.productId) +
                "\r\n            "
            ),
            _c("i", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.orderline.altOrderline,
                  expression: "orderline.altOrderline",
                },
              ],
              staticClass: "mdi mdi-file-replace-outline replacement-indicator",
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "table-column description-column" }, [
            _c(
              "span",
              { staticClass: "productTitle" },
              [
                _c("b", [_vm._v(_vm._s(_vm.orderline.description))]),
                _vm._v(" "),
                _c("shop-item-icons", {
                  attrs: {
                    product: _vm.orderline,
                    priceTypeCampaign: _vm.priceTypeCampaign,
                  },
                }),
                _vm._v(" "),
                _vm.orderline.markings
                  ? _c("highlight-markings", {
                      attrs: { itemMarkings: _vm.orderline.markings },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _vm.orderline.depositProduct
              ? _c("span", { staticClass: "small-caption item-small-label" }, [
                  _vm._v(
                    _vm._s(_vm._f("translate")("webshop_deposit_product_label"))
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.orderline.warnings
              ? _c(
                  "div",
                  { staticClass: "warnings" },
                  _vm._l(_vm.orderline.warnings, function (warning, index) {
                    return _c("basket-orderline-warnings", {
                      key: warning.typeName + index,
                      attrs: { warning: warning },
                    })
                  }),
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isExpiryProduct
              ? _c(
                  "span",
                  { staticClass: "expiryComment" },
                  [
                    _vm._v(
                      _vm._s(
                        _vm._f("translate")("webshop_cart_line_expiryComment")
                      ) + "\r\n                "
                    ),
                    _vm.orderline.expiry != null &&
                    _vm.orderline.expiry.reasonForSellOut
                      ? [
                          _vm._v(
                            "(" +
                              _vm._s(_vm.orderline.expiry.reasonForSellOut) +
                              ")"
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "table-column stock-column" },
            [
              _vm.showAvailableQuantity && _vm.orderline.lineprice >= 0
                ? _c(
                    "shop-item-stock-status",
                    { attrs: { product: _vm.orderline } },
                    [
                      _vm.selectedUnit
                        ? _c(
                            "shop-stock-warning",
                            _vm._b(
                              {},
                              "shop-stock-warning",
                              {
                                amount: _vm.ol.amount,
                                selectedUnit: _vm.selectedUnit.name,
                                product: _vm.orderline,
                                autoDisplayMessage: false,
                              },
                              false
                            )
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "table-column actions-column" }, [
            (_vm.orderline.unrecognized ||
              _vm.orderline.blocked ||
              _vm.orderline.saleBlocked) &&
            _vm.orderline.altOrderline
              ? _c("a", {
                  staticClass: "replace-obsolete-product-cart mdi mdi-cached",
                  attrs: {
                    title: _vm._f("translate")(
                      "webshop_search_search_item_obsolete_button_replace_title"
                    ),
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.replaceProduct()
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.orderline.isAlternative
              ? _c(
                  "a",
                  {
                    staticClass: "orderpart-product-delete",
                    on: {
                      click: function ($event) {
                        return _vm.removeOrderline()
                      },
                    },
                  },
                  [_c("icon-trash")],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "break-medium" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "table-column column-group price-column-group" },
            [
              _vm.orderline.unrecognized ||
              _vm.orderline.blocked ||
              _vm.orderline.saleBlocked ||
              _vm.outOfRangeDeliveryDate ||
              _vm.orderline.itemStatus == _vm.ItemStatus.Deleted ||
              _vm.orderline.itemStatus == _vm.ItemStatus.Inactive ||
              _vm.orderline.units == null
                ? _c(
                    "div",
                    { staticClass: "table-column obsolete-message-column" },
                    [
                      _c("basket-orderline-obsolete-message", {
                        attrs: { orderline: _vm.orderline },
                      }),
                    ],
                    1
                  )
                : [
                    _c(
                      "div",
                      { staticClass: "table-column quantity-column" },
                      [
                        _vm.isCheckoutOffer
                          ? [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: _vm.ol.amount,
                                    expression: "ol.amount",
                                    modifiers: { number: true },
                                  },
                                ],
                                staticClass: "shop-input",
                                attrs: {
                                  type: "number",
                                  name: "quantity",
                                  autocomplete: "off",
                                  disabled: "disabled",
                                },
                                domProps: { value: _vm.ol.amount },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.ol,
                                      "amount",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ]
                          : [
                              _c("input-number", {
                                ref: "inputAmountParent",
                                attrs: {
                                  inputUnit:
                                    _vm.selectedUnit && _vm.selectedUnit.name
                                      ? _vm.selectedUnit.name
                                      : "",
                                  inputRef: "inputAmount",
                                  name: "quantity",
                                  autocomplete: "off",
                                  inputClass: "shop-input shop-basket-input",
                                  inputStep:
                                    _vm.ol.weightItem &&
                                    _vm.selectedUnit !== null &&
                                    _vm.selectedUnit.name !== null &&
                                    _vm.selectedUnit.name === "KG"
                                      ? 0.001
                                      : 1,
                                  inputDisabled:
                                    _vm.ol.isAlternative || _vm.loading,
                                  selectOnFocus: true,
                                  inputTabindex: "0",
                                },
                                on: {
                                  blur: _vm.updateQuantity,
                                  keyupenter: _vm.focusNextOnEnter,
                                },
                                model: {
                                  value: _vm.ol.amount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ol, "amount", $$v)
                                  },
                                  expression: "ol.amount",
                                },
                              }),
                              _vm._v(" "),
                              _vm.orderline.discountAmountExceeded > 0
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "discount-amount-exceeded",
                                      on: {
                                        click: function ($event) {
                                          _vm.showDiscountExceededText = true
                                        },
                                        mouseleave: function ($event) {
                                          _vm.showDiscountExceededText = false
                                        },
                                      },
                                    },
                                    [
                                      _c("span", [_vm._v("*")]),
                                      _vm._v(" "),
                                      _vm.showDiscountExceededText
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "discount-amount-exceeded-txt",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$options.filters.translate(
                                                    "webshop_order_discountExceededText",
                                                    _vm.orderline
                                                      .discountAmountExceeded
                                                  )
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("suggestion-tooltip", {
                                attrs: {
                                  product: _vm.ol,
                                  "selected-unit": _vm.ol.unit,
                                  amount: _vm.ol.amount,
                                  disabled: _vm.isExpiryProduct,
                                },
                              }),
                            ],
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "table-column unit-column" },
                      [
                        _vm.orderline.units != null &&
                        _vm.orderline.units.length > 1
                          ? [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedUnit,
                                      expression: "selectedUnit",
                                    },
                                  ],
                                  staticClass: "shop-input",
                                  attrs: {
                                    name: "unit",
                                    disabled:
                                      _vm.loading ||
                                      _vm.ol.isAlternative ||
                                      _vm.isExpiryProduct,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.selectedUnit = $event.target
                                          .multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      _vm.updateUnit,
                                    ],
                                  },
                                },
                                _vm._l(_vm.orderline.units, function (unit) {
                                  return _c(
                                    "option",
                                    {
                                      key: unit.name,
                                      domProps: { value: unit },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(unit.name) +
                                          " (" +
                                          _vm._s(unit.description) +
                                          ")"
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          : _vm.orderline.units != null &&
                            _vm.orderline.units.length == 1
                          ? [
                              _vm._v(
                                _vm._s(_vm.orderline.units[0].name) +
                                  " (" +
                                  _vm._s(_vm.orderline.units[0].description) +
                                  ")"
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "table-column price-column" },
                      [
                        _vm.isCheckoutOffer
                          ? [
                              _c("i", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("translate")(
                                      "webshop_order_checkOutOffer_freeSample"
                                    )
                                  )
                                ),
                              ]),
                            ]
                          : [
                              _vm.showPrice
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "small-caption item-small-label",
                                    },
                                    [
                                      _vm._v(
                                        "\r\n                            " +
                                          _vm._s(
                                            _vm._f("translate")(
                                              "webshop_general_unitPrice"
                                            )
                                          ) +
                                          ":\r\n                            "
                                      ),
                                      _c(
                                        "span",
                                        {
                                          class: {
                                            "regular-price":
                                              _vm.orderline.discountUnitPrice,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.orderline.unitPrice
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.orderline.discountUnitPrice > 0
                                        ? _c(
                                            "span",
                                            {
                                              class: {
                                                "discount-price":
                                                  _vm.orderline
                                                    .discountUnitPrice,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    _vm.orderline
                                                      .discountUnitPrice
                                                  )
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.orderline.inclDeposit
                                ? [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "notice price-weight-notice",
                                      },
                                      [
                                        _c("small", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("translate")(
                                                "webshop_general_inclPackaging"
                                              )
                                            ) + " "
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.orderline.isAlternative && _vm.showPrice
                                ? _c(
                                    "span",
                                    { staticClass: "item-pricel no-fill" },
                                    [
                                      _c("item-surcharge-warning", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.orderline
                                                .leftAmountWithSurcharge > 0,
                                            expression:
                                              "orderline.leftAmountWithSurcharge > 0",
                                          },
                                        ],
                                        attrs: {
                                          "suggested-amount":
                                            _vm.suggestedAmount,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          class: {
                                            "regular-price":
                                              _vm.orderline.discountPrice,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.orderline.lineprice
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.orderline.discountPrice > 0
                                        ? _c(
                                            "span",
                                            {
                                              class: {
                                                "discount-price":
                                                  _vm.orderline.discountPrice,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    _vm.orderline.discountPrice
                                                  )
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.orderline.weightItem &&
                                      _vm.selectedUnit &&
                                      _vm.selectedUnit.name
                                        .trim()
                                        .toUpperCase() != "KG"
                                        ? _c("span", [_vm._v("*")])
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                      ],
                      2
                    ),
                  ],
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _vm.showAlsoBoughtProducts
        ? _c("basket-also-bought-products", {
            attrs: { productId: _vm.orderline.productId },
            on: {
              onEmptyList: function ($event) {
                _vm.showAlsoBoughtProducts = false
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.orderline.altOrderline
        ? _c("basket-orderline", {
            key: _vm.orderline.lineNo,
            attrs: {
              orderline: _vm.orderline.altOrderline,
              "delivery-date": _vm.deliveryDate,
              "is-inline": true,
            },
            on: { reload: _vm.reload },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }