import { Service } from './Service';
import { OrderApi } from 'api/OrderApi';
import { GAService } from './GAService';
import Vue from "vue";
import { ToastService } from './ToastService';
class ReplaceOrderLineVM {
    product;
    replacement;
    constructor(product, replacement) {
        this.product = product;
        this.replacement = replacement;
    }
}
export class OrderService extends Service {
    static addProductsToBasket(products) {
        return new Promise((resolve, reject) => {
            OrderApi.addProductToBasket(products).then((res) => {
                let addedProducts = products.filter(x => !x.depositLine);
                GAService.addProductToBasket(addedProducts);
                var result = res.data;
                resolve(result);
            }).catch((err) => {
                if (err.response.status == 416) {
                    this.ShowExpiryError(err.response);
                    reject(err.response);
                }
                else
                    Service.customErrorHandling({ ...err }, reject);
            });
        });
    }
    static addSingleProductToBasket(product) {
        return new Promise((resolve, reject) => {
            OrderApi.addProductToBasket([product]).then((res) => {
                GAService.addProductToBasket([product]);
                var result = res.data;
                resolve(result);
            }).catch((err) => {
                if (err.response.status == 416) {
                    this.ShowExpiryError(err.response);
                    reject(err.response);
                }
                else
                    Service.customErrorHandling({ ...err }, reject);
            });
        });
    }
    static updateOrderLine(orderline) {
        return new Promise((resolve, reject) => {
            OrderApi.updateOrderLine(orderline).then((res) => {
                if (!orderline.depositLine && orderline.quantity == 0)
                    GAService.removeProductFromBasket([orderline]);
                var result = res.data;
                resolve(result);
            }).catch((err) => {
                if (err.response.status == 416) {
                    this.ShowExpiryError(err.response);
                    reject(err.response);
                }
                else
                    Service.customErrorHandling({ ...err }, reject);
            });
        });
    }
    static replaceProduct(orderline, replacement) {
        let request = new ReplaceOrderLineVM(orderline, replacement);
        return new Promise((resolve, reject) => {
            OrderApi.replaceProduct(request).then((res) => {
                var result = res.data;
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static reviewOrder() {
        return new Promise((resolve, reject) => {
            OrderApi.reviewOrder().then((res) => {
                var result = res.data.reviewOrder;
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static emptyBasket(date) {
        return new Promise((resolve, reject) => {
            OrderApi.emptyBasket(date).then((res) => {
                var result = res.data;
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static completeOrder(data) {
        return new Promise((resolve, reject) => {
            OrderApi.completeOrder(data).then((res) => {
                var result = res.data;
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static getOrders() {
        return new Promise((resolve, reject) => {
            OrderApi.getOrders().then((res) => {
                var result = res.data;
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static setActiveOrder(orderNo) {
        return new Promise((resolve, reject) => {
            OrderApi.setActive(orderNo).then((res) => {
                var result = res.data;
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static createOrder(order) {
        return new Promise((resolve, reject) => {
            OrderApi.createOrder(order).then((res) => {
                var result = res.data;
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static updateOrder(orderNo, order) {
        return new Promise((resolve, reject) => {
            OrderApi.updateOrder(orderNo, order).then((res) => {
                var result = res.data;
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static deleteOrder(order) {
        return new Promise((resolve, reject) => {
            OrderApi.deleteOrder(order).then((res) => {
                var result = res.data;
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static addDiscountCode(orderNo, discountCode) {
        return new Promise((resolve, reject) => {
            OrderApi.addDiscountCode(orderNo, discountCode).then((res) => {
                var result = res.data;
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static removeDiscountCode(orderNo, discountCode) {
        return new Promise((resolve, reject) => {
            OrderApi.removeDiscountCode(orderNo, discountCode).then((res) => {
                var result = res.data;
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static ShowExpiryError(err) {
        let errorMessage = 'webshop_defaults_toast_errorText';
        var stockWarning = JSON.parse(err.data.error);
        // var expiryDate = new Date(stockWarning.expiryDate);
        // var deliveryDate = new Date(stockWarning.deliveryDate);
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        let defaultDate = new Date(1753, 0, 1);
        const expiryDate = new Date(stockWarning.expiryDate);
        if (stockWarning.stock === 0) {
            errorMessage = Vue.filter("translate")("webshop_cart_add_toast_expiry_stockZero", stockWarning.productId);
        }
        else {
            if (stockWarning.inBasketAmount == 0) {
                errorMessage = Vue.filter("translate")("webshop_cart_add_toast_expiry_amountLimitReached", stockWarning.productId, stockWarning.stock);
            }
            if (stockWarning.inBasketAmount > 0) {
                errorMessage = Vue.filter("translate")("webshop_cart_add_toast_expiry_amountLimitReached_inBasket", stockWarning.productId, stockWarning.stock - stockWarning.inBasketAmount, stockWarning.inBasketAmount);
            }
            if (stockWarning.unit != undefined && stockWarning.sellOutLineUnit != undefined && stockWarning.unit != stockWarning.sellOutLineUnit) {
                errorMessage = Vue.filter("translate")("webshop_order_warnings_unitMisMatch", stockWarning.productId);
            }
        }
        if (expiryDate != defaultDate && expiryDate < today) {
            errorMessage = Vue.filter("translate")("webshop_order_warnings_expired");
        }
        ToastService.danger(errorMessage, '', 6000);
    }
}
