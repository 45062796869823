var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { SearchService } from "services/SearchService";
import { Settings } from "config/Settings";
import { GAService } from "services/GAService";
let ShopItemIcons = class ShopItemIcons extends Vue {
    product;
    priceTypeCampaign;
    Settings = Settings;
    get checkIfDeadlineProduct() {
        return this.product.markings.stopTime != "00:00:00";
    }
    open() {
        const productId = this.product.productId != undefined ? this.product.productId : this.product.id;
        GAService.productClick([this.product]);
        GAService.productDetail([this.product]);
        SearchService.openFoodInfo(this, productId);
    }
    get isExpiryProduct() {
        return this.product.expiry && this.product.expiry.price > 0;
    }
    get hasBestBefore() {
        const expiryDate = new Date(this.product.expiry?.date);
        const expiryDateWithoutTime = new Date(expiryDate.getFullYear(), expiryDate.getMonth(), expiryDate.getDate());
        const defaultDate = new Date(1753, 0, 1);
        return expiryDateWithoutTime.getTime() > defaultDate.getTime();
    }
    daysBetweenDates(firstDate, secondDate) {
        const firstDateMoment = moment(firstDate);
        const secondDateMoment = moment(secondDate);
        const daysBetween = firstDateMoment.diff(secondDateMoment, 'days');
        return daysBetween;
    }
};
ShopItemIcons = __decorate([
    Component({
        props: {
            product: Object,
            priceTypeCampaign: Boolean
        }
    })
], ShopItemIcons);
export default ShopItemIcons;
