var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "result-list-container", attrs: { container: "" } },
    [
      _c("h2", { staticClass: "header-small header" }, [
        _vm._v(
          _vm._s(
            _vm._f("translate")(
              "webshop_inventory_addmodals_productResult_title"
            )
          )
        ),
      ]),
      _vm._v(" "),
      _vm.showSearchFilter
        ? _c("div", { attrs: { grid: "wrap" } }, [
            _c("div", { attrs: { column: "xs-6 m-4" } }, [
              _c("input", {
                staticClass: "small-search-input",
                attrs: {
                  type: "text",
                  placeholder: _vm.$options.filters.translate(
                    "webshop_general_search"
                  ),
                },
                on: { input: _vm.searchFilter },
              }),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "result-list" },
        [
          _c("li", { staticClass: "result-line" }, [
            _c("div", { attrs: { grid: "justify-start" } }, [
              _c("div", { attrs: { column: "xs-6 m-5 l-5" } }, [
                _c(
                  "div",
                  {
                    staticClass: "clickable",
                    attrs: { grid: "m-no-gutters" },
                    on: {
                      click: function ($event) {
                        return _vm.toggleAllLines()
                      },
                    },
                  },
                  [
                    _c("div", { attrs: { column: "top xs-2 s-1 m-2" } }, [
                      _c("i", {
                        staticClass: "checkbox mdi",
                        class: {
                          "mdi-checkbox-marked": _vm.isAllSelected,
                          "mdi-checkbox-blank-outline": !_vm.isAllSelected,
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "select-all-text",
                        attrs: { column: "top xs-10" },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm._f("translate")(
                                "webshop_inventory_addmodals_productResult_selectAll"
                              )
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm._l(_vm.filteredLines, function (line) {
            return _c(
              "li",
              {
                key: line.productId,
                staticClass: "result-line",
                attrs: { set: (_vm.itemUnit = _vm.getSelectedUnit(line)) },
              },
              [
                _c("div", { attrs: { grid: "wrap" } }, [
                  _c("div", { attrs: { column: "xs-6 s-5 m-5 l-5" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "clickable",
                        attrs: { grid: "m-no-gutters" },
                        on: {
                          click: function ($event) {
                            return _vm.toggleSelection(line)
                          },
                        },
                      },
                      [
                        _c("div", { attrs: { column: "xs-2 s-1 m-2" } }, [
                          _c("i", {
                            staticClass: "checkbox mdi",
                            class: {
                              "mdi-checkbox-marked": line._selected,
                              "mdi-checkbox-blank-outline": !line._selected,
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "text-col",
                            attrs: { column: "xs-10" },
                          },
                          [
                            _c("div", { staticClass: "text-wrapper" }, [
                              _c("span", { staticClass: "label" }, [
                                _vm._v(
                                  _vm._s(line.productId) +
                                    " - " +
                                    _vm._s(line.brand)
                                ),
                              ]),
                              _vm._v(
                                "\n                                " +
                                  _vm._s(line.description) +
                                  "\n                                "
                              ),
                              line.markings
                                ? _c(
                                    "div",
                                    { on: { click: _vm.clickItemIcons } },
                                    [
                                      _c("shop-item-icons", {
                                        attrs: {
                                          product: line,
                                          priceTypeCampaign:
                                            _vm.priceTypeCampaign(line),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("highlight-markings", {
                                        attrs: { itemMarkings: line.markings },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            line.id
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "on-list-wrapper",
                                    attrs: {
                                      title: _vm.$options.filters.translate(
                                        line.editedPrice || line.editedUnit
                                          ? "webshop_inventory_addmodals_productResult_lineIgnored"
                                          : "webshop_inventory_addmodals_productResult_lineUpdates"
                                      ),
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "mdi",
                                      class: [
                                        line.editedPrice || line.editedUnit
                                          ? "mdi-playlist-remove ignored"
                                          : "mdi-playlist-edit updates",
                                      ],
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { attrs: { column: "xs-6 s-7 m-7 l-7" } }, [
                    _c("div", { attrs: { grid: "wrap" } }, [
                      _c(
                        "div",
                        { attrs: { column: "xs-6 s-3 m-3 l-3" } },
                        [
                          _c("span", { staticClass: "label" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")("webshop_general_amount")
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("input-number", {
                            attrs: {
                              form: "addForm",
                              inputClass: "amount-input",
                              required: line._selected,
                              autocomplete: "off",
                              inputStep: line.unit === "KG" ? 0.001 : 1,
                              "select-onfocus": "true",
                            },
                            model: {
                              value: line.amount,
                              callback: function ($$v) {
                                _vm.$set(line, "amount", $$v)
                              },
                              expression: "line.amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { attrs: { column: "xs-12 s-5 m-5 l-5" } }, [
                        _c("span", { staticClass: "label" }, [
                          _vm._v(
                            _vm._s(_vm._f("translate")("webshop_general_unit"))
                          ),
                        ]),
                        _vm._v(" "),
                        line.units && line.units.length > 1
                          ? _c("span", { staticClass: "unit" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: line.unit,
                                      expression: "line.unit",
                                    },
                                  ],
                                  staticClass: "unit-input calc",
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          line,
                                          "unit",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      function ($event) {
                                        return _vm.changeUnit(line)
                                      },
                                    ],
                                  },
                                },
                                _vm._l(line.units, function (unit) {
                                  return _c(
                                    "option",
                                    {
                                      key: unit.name,
                                      domProps: { value: unit.name },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(
                                            _vm._f("unitDescription")(unit)
                                          ) +
                                          "\n                                    "
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ])
                          : _c("span", { staticClass: "unit" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm._f("unitDescription")(_vm.itemUnit)
                                  ) +
                                  "\n                            "
                              ),
                            ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "text-right",
                          attrs: { column: "xs-6 s-4 m-4 l-4" },
                        },
                        [
                          _c("span", { staticClass: "label" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")("webshop_general_price")
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.showPrice
                            ? [
                                _c(
                                  "span",
                                  {
                                    class: {
                                      "item-price-campaign":
                                        _vm.itemUnit.priceBeforeCampaign > 0,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("currency")(_vm.itemUnit.price)
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.itemUnit.priceBeforeCampaign
                                  ? _c(
                                      "span",
                                      {
                                        class: {
                                          "price-before-campaign":
                                            _vm.itemUnit.priceBeforeCampaign >
                                            0,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.itemUnit.priceBeforeCampaign
                                            )
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]),
              ]
            )
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }