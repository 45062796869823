var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    [
      _c("a", { class: _vm.className, attrs: { href: _vm.url } }, [
        _vm._v("\n        " + _vm._s(_vm.label) + "\n    "),
      ]),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }