export var EventTypes;
(function (EventTypes) {
    EventTypes["InventoryListUpdated"] = "inventory-list-updated";
    EventTypes["InventoryLineChangedGroup"] = "inventory-line-changed-group";
    EventTypes["InventoryListDeleted"] = "inventory-list-deleted";
    EventTypes["InventoryLineUpdated"] = "inventory-line-updated";
    EventTypes["InventoryLineAdded"] = "inventory-line-added";
    EventTypes["InventoryLineDeleted"] = "inventory-line-deleted";
    EventTypes["InventorySearchChanged"] = "inventory-search-changed";
    EventTypes["InventoryRouteChanged"] = "inventory-route-changed";
    EventTypes["InventoryReloadList"] = "inventory-reload-list";
    EventTypes["InventoryGroupUpdated"] = "inventory-group-updated";
    EventTypes["InventoryReloadAllLists"] = "inventory-reload-all-lists";
    EventTypes["InventoryScrollToList"] = "inventory-scroll-to-list";
    //Modals
    EventTypes["InventoryModalProductOpen"] = "inventory-modal-product-open";
    EventTypes["InventoryModalProductClose"] = "inventory-modal-product-close";
    EventTypes["InventoryModalProductSelected"] = "inventory-modal-product-selected";
    EventTypes["InventoryModalFavoriteSelected"] = "inventory-modal-favorite-selected";
    EventTypes["InventoryModalOwnProductEditOpen"] = "inventory-modal-own-product-edit-open";
    EventTypes["InventoryModalOwnProductEditClose"] = "inventory-modal-own-product-edit-close";
    EventTypes["InventoryModalProductDeleteOpen"] = "inventory-modal-product-delete-open";
    EventTypes["InventoryModalProductDeleteClose"] = "inventory-modal-product-delete-close";
    EventTypes["InventoryModalEmailOpen"] = "inventory-modal-email-open";
    EventTypes["InventoryModalEmailClose"] = "inventory-modal-email-close";
    EventTypes["InventoryModalConfirmOpen"] = "inventory-modal-confirm-open";
    EventTypes["InventoryModalConfirmClose"] = "inventory-modal-confirm-close";
    EventTypes["InventoryModalAutolagerOpen"] = "inventory-modal-autolager-open";
    EventTypes["InventoryModalAutolagerClose"] = "inventory-modal-autolager-close";
    EventTypes["InventoryModalProductSortOpen"] = "inventory-modal-product-sort-open";
    EventTypes["InventoryModalProductSortClose"] = "inventory-modal-product-sort-close";
    EventTypes["InventoryArchiveDeleteModalClose"] = "inventory-archive-delete-modal-close";
    //Login
    EventTypes["LoginModalOpen"] = "login-modal-open";
    //DeliveryDate
    EventTypes["DeliveryDateModalOpen"] = "delivery-date-modal-open";
    //Shop search
    EventTypes["ShopSearchStart"] = "shop-search-start";
    EventTypes["ShopSearchDone"] = "shop-search-done";
    EventTypes["ShopRemoveProductFromFavoriteList"] = "shop-remove-product-from-favorite-list";
    EventTypes["ShopSetProductOnFavoriteList"] = "shop-set-product-on-favorite-list";
    EventTypes["ShopCategoriesLoad"] = "shop-categories-load";
    EventTypes["ShopStorageLoad"] = "shop-storage-load";
    EventTypes["ShopMarkingsLoad"] = "shop-marks-load";
    EventTypes["ShopBrandsLoad"] = "shop-brands-load";
    EventTypes["ShopVendorsLoad"] = "shop-vendors-load";
    EventTypes["ShopFavoriteListsLoad"] = "shop-favorite-lists-load";
    EventTypes["ShopCustomListsLoad"] = "shop-custom-lists-load";
    EventTypes["ShopAddedProductToBasket"] = "shop-added-product-to-basket";
    EventTypes["ShopAddedProductsToBasket"] = "shop-added-products-to-basket";
    EventTypes["ShopReplaceProductOnFavoriteList"] = "shop-replace-product-on-favoritelist";
    EventTypes["ShopSearchRefresh"] = "shop-search-refresh";
    //Favorite 
    EventTypes["FavoriteCreatedList"] = "favorite-created-list";
    EventTypes["FavoriteReplaceModalSuccess"] = "favorite-replace-modal-success";
    EventTypes["FavoriteProductLoading"] = "favorite-product-loading";
    EventTypes["FavoriteReplaceModalError"] = "favorite-replace-modal-error";
    EventTypes["FavoriteListUpdate"] = "favorite-list-update";
    EventTypes["FavoriteListInsert"] = "favorite-list-insert";
    // Header
    EventTypes["MiniCartUpdate"] = "mini-cart-update";
    //Basket
    EventTypes["BasketHasExpiryProduct"] = "basket-has-expiry-product";
    EventTypes["BasketModalDeleteConfirm"] = "basket-modal-delete-confirm";
    EventTypes["BasketUpdate"] = "basket-update";
    EventTypes["BasketOpenDepositsModal"] = "basket-open-deposit-products-modal";
    EventTypes["BasketCloseDepositsModal"] = "basket-close-deposit-products-modal";
    EventTypes["BasketDepositProductAdded"] = "basket-deposit-product-added";
    //Statistics
    EventTypes["ProductStatisticsLoad"] = "product-statistics-load";
    // Multiple items
    EventTypes["ShopMultipleItemsItemAdded"] = "shop-multiple-items-item-added";
    EventTypes["ShopMultipleItemsItemRemoved"] = "shop-multiple-items-item-removed";
    //Orders
    EventTypes["OrdersOrderModal"] = "orders-order-modal";
    EventTypes["OrdersOrderModalClose"] = "orders-order-modal-close";
    //Campaign planning comments
    EventTypes["ShowComment"] = "show-comment";
})(EventTypes || (EventTypes = {}));
