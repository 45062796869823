export class FavoriteListLineVM {
    listNo;
    productId;
    stdUnit;
    sorting;
    description;
    brand;
    id;
    constructor() {
        this.listNo = null;
        this.productId = null;
        this.stdUnit = null;
        this.sorting = null;
        this.description = null;
        this.brand = null;
        this.id = null;
    }
    static fromApi(product) {
        return Object.assign(new FavoriteListLineVM(), product);
    }
}
