var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import Loader from 'components/utility/Loader.vue';
import { Order } from "models/order/Order";
let OrderModal = class OrderModal extends Vue {
    data;
    name = '';
    mounted() {
        if (this.data.order && this.data.order.name) {
            this.name = this.data.order.name;
        }
    }
    close() {
        this.$emit('close-modal');
    }
    update() {
        let editOrder = Object.assign(new Order(), this.data.order);
        editOrder.name = this.name;
        this.$store.dispatch('Orders/updateOrder', editOrder).finally(() => {
            this.$emit('close-modal');
        });
    }
    remove() {
        this.$store.dispatch('Orders/deleteOrder', this.data.order).finally(() => {
            this.$emit('close-modal');
        });
    }
    create() {
        let newOrder = new Order();
        newOrder.name = this.name;
        this.$store.dispatch('Orders/createOrder', newOrder).then(order => {
            this.$store.dispatch('Orders/setActiveOrder', order.orderNo).then(res => {
                this.$emit('close-modal');
            });
        }).catch(() => {
            this.$emit('close-modal');
        });
    }
    get loading() {
        return this.$store.state.Orders.loading;
    }
};
OrderModal = __decorate([
    Component({
        components: { Loader },
        props: {
            data: Object
        }
    })
], OrderModal);
export default OrderModal;
