var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import FavoriteCreateModal from 'components/favorite/FavoriteCreateModal.vue';
import { ModalService } from "modules/basic-core/basic-modal/src";
import { ItemStatus } from 'models/product/ItemStatus';
import { FavoriteService } from 'services/FavoriteService';
import { ToastService } from 'services/ToastService';
import { EventTypes } from 'models/EventTypes';
import { SearchService } from 'services/SearchService';
let ShopItemFavoriteMenu = class ShopItemFavoriteMenu extends Vue {
    loading;
    product;
    selectedUnit;
    showMenu = false;
    itemStatus = ItemStatus;
    lists = [];
    beforeDestroy() {
        this.$root.$off(EventTypes.FavoriteCreatedList, this.createdNewList);
    }
    mounted() { }
    get currentListId() {
        return SearchService.currentListId(this.$route);
    }
    setProductLoading(loading) {
        this.$emit('loading', loading);
    }
    removeFromCurrentList() {
        this.$emit('remove', this.product);
    }
    setProductOnFavoriteList(isOnFavoriteList) {
        //Setting value both here and with emit - shop need it for handling popular products, but component maybe used outside shop
        this.product.isOnFavoriteList = isOnFavoriteList;
        this.$root.$emit(EventTypes.ShopSetProductOnFavoriteList, this.product, isOnFavoriteList);
    }
    toggle() {
        this.setProductLoading(true);
        this.showMenu = !this.showMenu;
        if (this.showMenu) {
            FavoriteService.getAllLists(this.product.productId).then(data => {
                this.lists = data;
                this.setProductLoading(false);
            })
                .catch(err => {
                this.setProductLoading(false);
                ToastService.danger(Vue.filter('translate')('webshop_defaults_toast_errorText'));
            });
        }
    }
    close() {
        this.showMenu = false;
    }
    add(list) {
        this.setProductLoading(true);
        FavoriteService.addProduct(list.id, this.product.productId, this.selectedUnit).then(() => {
            ToastService.success(Vue.filter('translate')('webshop_search_favorite_toggleFavorite_addProduct_toast_added', this.product.description));
            this.setProductOnFavoriteList(true);
            list.productExists = true;
            this.setProductLoading(false);
        }).catch(err => {
            var errorMessage = Vue.filter('translate')('webshop_search_favorite_toggleFavorite_addProduct_toast_error');
            if (err === 406) {
                errorMessage = Vue.filter('translate')('webshop_search_favorite_toggleFavorite_addProduct_toast_listNotFound');
            }
            else if (err === 404) {
                errorMessage = Vue.filter('translate')('webshop_search_favorite_toggleFavorite_addProduct_toast_productNotexists');
            }
            else if (err === 409) {
                errorMessage = Vue.filter('translate')('webshop_search_favorite_toggleFavorite_addProduct_toast_exists');
            }
            this.setProductLoading(false);
            ToastService.danger(errorMessage);
        });
    }
    remove(list, removeFromResult = false) {
        this.setProductLoading(true);
        FavoriteService.deleteProductFromList(list.id, this.product.productId).then(() => {
            ToastService.success(Vue.filter('translate')('webshop_search_favorite_toggleFavorite_remove_toast_removed'));
            if (removeFromResult || this.currentListId === list.id) {
                this.removeFromCurrentList();
            }
            else {
                list.productExists = false;
                this.setProductOnFavoriteList(this.lists.findIndex(x => x.productExists) !== -1);
                this.setProductLoading(false);
            }
        }).catch((err) => {
            var errorMessage = Vue.filter('translate')('webshop_search_favorite_toggleFavorite_remove_toast_error');
            if (err === 406) {
                errorMessage = Vue.filter('translate')('webshop_search_favorite_toggleFavorite_remove_toast_listNotFound');
            }
            else if (err == 404) {
                errorMessage = Vue.filter('translate')('webshop_search_favorite_toggleFavorite_remove_toast_productNotexists');
            }
            this.setProductLoading(false);
            ToastService.danger(errorMessage);
        });
    }
    createNewList() {
        ModalService.create({
            'cxt': this,
            'component': FavoriteCreateModal,
            'maxWidth': '450px'
        });
        this.$root.$once(EventTypes.FavoriteCreatedList, this.createdNewList);
    }
    createdNewList(list) {
        if (list) {
            this.lists.push(list);
            this.add(list);
            this.$nextTick().then(() => {
                let elem = this.$refs.listWrapper;
                elem.scrollTop = elem.scrollHeight;
            });
        }
    }
    removeFromActive() {
        let list = this.lists.find(x => x.id == this.currentListId);
        this.remove(list, true);
    }
};
ShopItemFavoriteMenu = __decorate([
    Component({
        props: {
            loading: Boolean,
            product: Object,
            selectedUnit: {
                type: String,
                default: null
            }
        }
    })
], ShopItemFavoriteMenu);
export default ShopItemFavoriteMenu;
