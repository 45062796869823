var SearchSortBy;
(function (SearchSortBy) {
    SearchSortBy[SearchSortBy["Relevance"] = 0] = "Relevance";
    SearchSortBy[SearchSortBy["Description"] = 1] = "Description";
    SearchSortBy[SearchSortBy["DescriptionDesc"] = 2] = "DescriptionDesc";
    SearchSortBy[SearchSortBy["ProductId"] = 3] = "ProductId";
    SearchSortBy[SearchSortBy["ProductIdDesc"] = 4] = "ProductIdDesc";
    SearchSortBy[SearchSortBy["Favorite"] = 5] = "Favorite";
    SearchSortBy[SearchSortBy["ComparisonPrice"] = 6] = "ComparisonPrice";
    SearchSortBy[SearchSortBy["ComparisonPriceDesc"] = 7] = "ComparisonPriceDesc";
    SearchSortBy[SearchSortBy["ProductGroup"] = 8] = "ProductGroup";
    SearchSortBy[SearchSortBy["ProductGroupDesc"] = 9] = "ProductGroupDesc";
})(SearchSortBy || (SearchSortBy = {}));
var CalculationSearchSortBy;
(function (CalculationSearchSortBy) {
    CalculationSearchSortBy[CalculationSearchSortBy["Description"] = 0] = "Description";
    CalculationSearchSortBy[CalculationSearchSortBy["DescriptionDesc"] = 1] = "DescriptionDesc";
    CalculationSearchSortBy[CalculationSearchSortBy["ProductId"] = 2] = "ProductId";
    CalculationSearchSortBy[CalculationSearchSortBy["ProductIdDesc"] = 3] = "ProductIdDesc";
    CalculationSearchSortBy[CalculationSearchSortBy["Date"] = 4] = "Date";
    CalculationSearchSortBy[CalculationSearchSortBy["DateDesc"] = 5] = "DateDesc";
})(CalculationSearchSortBy || (CalculationSearchSortBy = {}));
export { SearchSortBy, CalculationSearchSortBy };
