import Vue from 'vue';
let handleOutsideClick;
Vue.directive('clickOutside', {
    bind(el, binding, vnode) {
        handleOutsideClick = (e) => {
            e.stopPropagation();
            // console.group();
            // console.log('el', el);
            // console.log('binding', binding);
            // console.log('vnode', vnode);
            // console.groupEnd();
            const { handler, exclude } = binding.value;
            let clickedOnExcludedEl = false;
            for (let refName of exclude) {
                if (!clickedOnExcludedEl) {
                    const excludedEl = vnode.context.$refs[refName];
                    clickedOnExcludedEl = excludedEl.contains(e.target);
                }
            }
            if (!el.contains(e.target) && !clickedOnExcludedEl) {
                vnode.context[handler]();
            }
        };
        document.addEventListener('click', handleOutsideClick);
        document.addEventListener('touchstart', handleOutsideClick);
    },
    unbind() {
        document.removeEventListener('click', handleOutsideClick);
        document.removeEventListener('touchstart', handleOutsideClick);
    }
});
