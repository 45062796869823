var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "statisticspage-container",
      attrs: { name: "statistics", container: "" },
    },
    [
      _c("div", { attrs: { grid: "wrap" } }, [
        _c(
          "div",
          { attrs: { column: "xs-12 xl-12", "sticky-container": "" } },
          [_c("statistics-filter", { attrs: { loading: _vm.loading } })],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { attrs: { column: "xs-12 xl-12", "sticky-container": "" } },
          [
            _c("statistics-result", {
              attrs: {
                loading: _vm.loading,
                products: _vm.products,
                productStatisticsFilter: _vm.productStatisticsFilter,
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }