var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import "filters/DateFilter";
import { Settings } from "config/Settings";
import { ReviewOrder } from "models/order/ReviewOrder";
import { EventTypes } from "models/EventTypes";
import { CompleteOrderVM } from "models/order/Order";
import { ToastService } from "../../services/ToastService";
import { ModalService } from "modules/basic-core/basic-modal/src";
import { OrderService } from "services/OrderService";
import { CheckOutService } from "services/CheckOutService";
import CheckOutBasketRender from "components/check-out/CheckOutBasketRender.vue";
import { ProductService } from "services/ProductService";
import BasketOrderPart from "components/basket/BasketOrderPart.vue";
import BasketDepositPart from "components/basket/BasketDepositPart.vue";
import BasketOrderErrorModal from "components/basket/BasketOrderErrorModal.vue";
import BasketChangeDeliveryForm from "components/basket/BasketChangeDeliveryForm.vue";
import BasketDeliveryDateChange from "components/basket/BasketDeliveryDateChange.vue";
import BasketAlsoBoughtProducts from "components/basket/BasketAlsoBoughtProducts.vue";
import OrganicPercentageModal from "components/shared/OrganicPercentageModal.vue";
import DepositProductsModal from "components/deposit-products/DepositProductsModal.vue";
import BasketWarningModal from "./BasketWarningModal.vue";
import { SessionStorageService } from "modules/basic-core/basic-storage/src/storage/SessionStorageService";
import LoadingButton from "../shared/LoadingButton.vue";
import { PermissionService } from "services/PermissionService";
import { PermissionLevel } from "models/user/PermissionLevel";
import { GAService } from "services/GAService";
let Basket = class Basket extends Vue {
    loading = false;
    loaded = false;
    currentOrder = new ReviewOrder();
    order = new CompleteOrderVM();
    checkoutCampaigns = [];
    showDepositModal = true;
    depositProducts = [];
    hasDepositProducts = false;
    commentsKey = "order_comments";
    externalDocumentNoKey = "order_externalDocumentNo";
    enableSubmitOrder = PermissionService.hasPermission(PermissionLevel.EnableOrder);
    discountCode = "";
    punchOut = Settings.profileInfo && Settings.profileInfo.punchOut;
    get searchUrl() {
        return Settings ? Settings.paths["search"] : "";
    }
    get conditionsUrl() {
        return Settings ? Settings.paths["conditions"] : "";
    }
    get ecoPctBasedOnWeight() {
        return Settings.profileInfo.ecoPctBasedOnWeight;
    }
    get ecoPercentage() {
        return Math.round(this.currentOrder.totalEcoPct);
    }
    get hasOrderLines() {
        let hasOrderLines = false;
        hasOrderLines =
            !!this.currentOrder.depositOrder &&
                !!this.currentOrder.depositOrder.orderlines &&
                this.currentOrder.depositOrder.orderlines.length > 0;
        if (hasOrderLines)
            return hasOrderLines;
        if (!!this.currentOrder && !!this.currentOrder.splitOrders) {
            this.currentOrder.splitOrders.forEach((order) => {
                if (order.orderlines.length > 0) {
                    hasOrderLines = true;
                    return;
                }
            });
        }
        return hasOrderLines;
    }
    get activeDiscountCodes() {
        return this.currentOrder.discountCodes;
    }
    mounted() {
        this.loaded = true;
        this.loadOrder();
        this.getCampaigns();
        this.$store.subscribe((mutation) => {
            if (mutation.type === "Orders/setActiveOrder") {
                this.loaded = true;
                this.loadOrder();
            }
        });
        this.$root.$on(EventTypes.BasketUpdate, (hardReload) => {
            if (hardReload)
                this.loaded = true;
            this.loadOrder();
        });
        this.$root.$on(EventTypes.BasketOpenDepositsModal, this.openDepositModal);
        setTimeout(() => {
            const selectedDeliveryDay = new Date(this.currentOrder.selectedDeliveryDay);
            const date = new Date();
            date.setDate(date.getDate() + 14);
            if (selectedDeliveryDay > date) {
                this.openBasketWarningModal();
            }
        }, 1000);
        // Load deposit products
        ProductService.getDepositProducts().then((res) => {
            this.depositProducts = res;
            if (this.depositProducts.length > 0) {
                this.hasDepositProducts = true;
            }
        }, (err) => {
            // How to handle error?
            ToastService.danger("An error occurred");
        });
    }
    onDestroy() {
        this.$root.$off(EventTypes.BasketUpdate);
        this.$root.$off(EventTypes.BasketOpenDepositsModal);
    }
    onReload() {
        this.loadOrder();
    }
    getCampaigns() {
        if (Settings.profileInfo && Settings.profileInfo.customerGroup) {
            CheckOutService.getCheckoutCampaigns(Settings.profileInfo.customerGroup).then((res) => {
                this.checkoutCampaigns = res;
            }, (err) => {
                // Don't show campaigns in case of error
            });
        }
    }
    submitOrder() {
        let vm = this;
        if (this.punchOut) {
            this.submitPunchOutOrder();
            return;
        }
        if (!this.enableSubmitOrder)
            return;
        /* Block order when blocked or obsolete products exist on the order */
        if (this.currentOrder.blockedProducts) {
            ToastService.danger(Vue.filter("translate")("webshop_order_complete_acceptOrder_toast_errorText_blocked_item_in_cart_error"));
            return;
        }
        else if (this.currentOrder.unitRemovedProducts) {
            ToastService.danger(Vue.filter("translate")("webshop_order_complete_acceptOrder_toast_errorText_unitRemoved_item_in_cart_error"));
            return;
        }
        else if (this.currentOrder.obsoleteProducts) {
            ToastService.danger(Vue.filter("translate")("webshop_order_complete_acceptOrder_toast_errorText_obsolete_item_in_cart_error"));
            return;
        }
        this.loading = true;
        OrderService.completeOrder(this.order).then((res) => {
            this.order.comments = "";
            this.order.externalDocumentNo = "";
            this.setOrderComments();
            this.setExternalDocumentNo();
            setTimeout(function () {
                /* Perhaps create a reference to this from "Settings.Paths?" */
                window.location.href =
                    "/webbshop/visa-eller-avsluta-order/tack-foer-din-bestaellning";
                SessionStorageService.removeItem(this.commentsKey);
                this.loading = false;
            }, 500);
            GAService.checkoutAndRefundOrder(this.currentOrder);
        }, (err) => {
            this.loading = false;
            let errorText = Vue.filter("translate")("webshop_order_complete_acceptOrder_toast_errorText");
            if (err === 409) {
                errorText = Vue.filter("translate")("webshop_order_complete_acceptOrder_toast_commentTooLong");
            }
            else if (err === 413) {
                errorText = Vue.filter("translate")("webshop_order_complete_acceptOrder_toast_externalDocumentNoTooLong");
            }
            else if (err === 416) {
                errorText = Vue.filter("translate")("webshop_order_complete_acceptOrder_toast_errorText_expiryError");
                this.loadOrder();
            }
            ModalService.create({
                cxt: this,
                component: BasketOrderErrorModal,
                maxWidth: "450px",
                data: errorText,
            });
        });
    }
    submitPunchOutOrder() {
        this.loading = true;
        let punchOutFormWrapper = this.$refs.punchOutForm;
        if (punchOutFormWrapper) {
            let punchOutForm = punchOutFormWrapper.children[0];
            GAService.checkoutAndRefundOrder(this.currentOrder);
            this.currentOrder.splitOrders.forEach((x) => {
                OrderService.emptyBasket(Vue.filter("date")(x.deliveryDate));
            });
            punchOutForm.submit();
        }
    }
    openOrganicPercentageModal() {
        ModalService.create({
            cxt: this,
            component: OrganicPercentageModal,
            maxWidth: "700px",
        });
    }
    openBasketWarningModal() {
        ModalService.create({
            cxt: this,
            component: BasketWarningModal,
            maxWidth: "700px",
        });
    }
    loadOrder() {
        OrderService.reviewOrder()
            .then((data) => {
            Object.assign(this.currentOrder, data);
            this.order.comments =
                SessionStorageService.getItem(this.commentsKey) || "";
            this.order.externalDocumentNo =
                SessionStorageService.getItem(this.externalDocumentNoKey) || "";
            this.loading = false;
            this.loaded = false;
        })
            .catch((err) => {
            this.loading = false;
            ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
        });
    }
    setOrderComments() {
        SessionStorageService.setItem(this.commentsKey, this.order.comments);
    }
    setExternalDocumentNo() {
        SessionStorageService.setItem(this.externalDocumentNoKey, this.order.externalDocumentNo);
    }
    openDepositModal() {
        ModalService.create({
            cxt: this,
            component: DepositProductsModal,
            maxWidth: "700px",
            data: this.depositProducts,
        });
    }
    addDiscountCode() {
        if (this.discountCode &&
            this.currentOrder.discountCodes.find((x) => x.toUpperCase() == this.discountCode.toUpperCase()) == undefined) {
            this.loading = true;
            OrderService.addDiscountCode(this.currentOrder.orderId, this.discountCode.toUpperCase())
                .then((res) => {
                this.loaded = true;
                this.discountCode = "";
                this.loadOrder();
                this.$root.$emit(EventTypes.MiniCartUpdate, res);
            })
                .catch((err) => {
                if (err == 409) {
                    if (this.currentOrder.discountCodes.length < 5) {
                        ToastService.danger(Vue.filter("translate")("webshop_order_invalid_discountCodesSizeExceeded"));
                    }
                    else {
                        ToastService.danger(Vue.filter("translate")("webshop_order_invalid_discountCodeAlreadyExist"));
                    }
                }
                else {
                    ToastService.danger(Vue.filter("translate")("webshop_order_invalid_discountCode"));
                }
            })
                .finally(() => {
                this.loading = false;
            });
        }
    }
    removeDiscountCode(code) {
        this.loading = true;
        OrderService.removeDiscountCode(this.currentOrder.orderId, code)
            .then((res) => {
            this.loaded = true;
            this.loadOrder();
            this.$root.$emit(EventTypes.MiniCartUpdate, res);
        })
            .catch((err) => {
            ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
        })
            .finally(() => {
            this.loading = false;
        });
    }
};
Basket = __decorate([
    Component({
        components: {
            BasketDeliveryDateChange,
            CheckOutBasketRender,
            BasketOrderPart,
            BasketDepositPart,
            BasketChangeDeliveryForm,
            LoadingButton,
            BasketAlsoBoughtProducts,
        },
    })
], Basket);
export default Basket;
