var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import 'filters/DateFilter';
import { Settings } from 'config/Settings';
import BasketOrderline from 'components/basket/BasketOrderline.vue';
import { EventTypes } from 'models/EventTypes';
import { ModalService } from "modules/basic-core/basic-modal/src";
import BasketConfirmDeleteModal from 'components/basket/BasketconfirmDeleteModal.vue';
import CheckOutBasketRender from 'components/check-out/CheckOutBasketRender.vue';
import IconTrash from '../shared/IconTrash.vue';
import { PermissionService } from 'services/PermissionService';
import { PermissionLevel } from 'models/user/PermissionLevel';
let BasketDepositPart = class BasketDepositPart extends Vue {
    order;
    loading = false;
    showPrice = PermissionService.hasPermission(PermissionLevel.ShowPrice);
    punchOut = Settings.profileInfo && Settings.profileInfo.punchOut;
    openBasketDepositProductsModal() {
        this.$root.$emit(EventTypes.BasketOpenDepositsModal);
    }
    emptyBasket() {
        this.loading = true;
        this.$root.$emit(EventTypes.BasketUpdate);
    }
    onDestroy() {
        this.$root.$off(EventTypes.BasketModalDeleteConfirm, this.emptyBasket);
    }
    handleEmptyBasket() {
        let date = new Date(98, 0);
        date.setFullYear(1);
        ModalService.create({
            'cxt': this,
            'component': BasketConfirmDeleteModal,
            'maxWidth': '450px',
            'data': date.toISOString()
        });
        this.$root.$once(EventTypes.BasketModalDeleteConfirm, this.emptyBasket);
    }
};
BasketDepositPart = __decorate([
    Component({
        components: {
            BasketOrderline,
            CheckOutBasketRender,
            IconTrash
        },
        props: {
            order: Object
        }
    })
], BasketDepositPart);
export default BasketDepositPart;
