var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ui-modal-content" }, [
      _c("h3", { staticClass: "ui-modal-header" }, [
        _vm._v(
          _vm._s(_vm._f("translate")("webshop_favorite_createList_header"))
        ),
      ]),
      _vm._v(" "),
      _c("div", [
        _c(
          "form",
          {
            staticClass: "ui-form",
            attrs: { name: "tempForm", novalidate: "" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submit.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "ui-form-fieldwrapper" }, [
              _c("label", { attrs: { for: "favorite-lists-form-name" } }),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("translate")("webshop_favorite_createList_nameLabel")
                  )
                ),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.tempForm.listName,
                    expression: "tempForm.listName",
                  },
                  { name: "autofocus", rawName: "v-autofocus" },
                ],
                attrs: {
                  type: "text",
                  placeholder: _vm.$options.filters.translate(
                    "webshop_favorite_createList_namePlaceholder"
                  ),
                  required: "",
                  autocomplete: "off",
                  name: "favorite-lists-form-name",
                  maxlength: "30",
                },
                domProps: { value: _vm.tempForm.listName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.tempForm, "listName", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { class: { hidden: !_vm.showByDate } }, [
              _c("p", { staticClass: "small" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("translate")(
                      "webshop_favorite_createList_byDate_text"
                    )
                  )
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "ui-form-datepickerwrapper" }, [
                _c(
                  "div",
                  {
                    staticClass: "date-choice-wrapper",
                    attrs: { grid: "justify-between" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "date-choice date-from",
                        attrs: { column: "top" },
                      },
                      [
                        _c("label", [
                          _vm._v(
                            _vm._s(
                              _vm._f("translate")(
                                "webshop_favorite_createList_byDate_fromDate"
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("flat-pickr", {
                          attrs: { config: _vm.dpSettings, required: "true" },
                          model: {
                            value: _vm.tempForm.from,
                            callback: function ($$v) {
                              _vm.$set(_vm.tempForm, "from", $$v)
                            },
                            expression: "tempForm.from",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "date-choice date-from",
                        attrs: { column: "bottom" },
                      },
                      [
                        _c("label", [
                          _vm._v(
                            _vm._s(
                              _vm._f("translate")(
                                "webshop_favorite_createList_byDate_toDate"
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("flat-pickr", {
                          attrs: { config: _vm.dpSettings, required: "true" },
                          model: {
                            value: _vm.tempForm.to,
                            callback: function ($$v) {
                              _vm.$set(_vm.tempForm, "to", $$v)
                            },
                            expression: "tempForm.to",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "ui-form-submitwrapper ui-form-submitwrapper-full",
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "ui-button ui-button-secondary ui-button-left",
                    on: {
                      click: function ($event) {
                        return _vm.toogleByDate()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm._f("translate")(
                          _vm.showByDate
                            ? "webshop_general_back"
                            : "webshop_favorite_createList_byDate_button"
                        )
                      )
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("loading-button", {
                  attrs: {
                    "icon-class": "mdi-playlist-plus",
                    text: _vm.$options.filters.translate(
                      "webshop_favorite_createList_button_create"
                    ),
                    "loading-text": _vm.$options.filters.translate(
                      "webshop_favorite_createList_button_create_loader"
                    ),
                    loading: _vm.loading,
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }