var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { InventoryArchiveVM } from "models/inventory/InventoryArchiveVM";
import { WindowService } from 'services/WindowService';
import { PdfReportService } from 'services/PdfReportService';
import { ToastService } from "services/ToastService";
import IconTrash from "../../shared/IconTrash.vue";
import { ModalService } from "modules/basic-core/basic-modal/src/service/ModalService";
import InventoryArchiveDeleteModal from "../modals/InventoryArchiveDeleteModal.vue";
import { PermissionService } from "services/PermissionService";
import { PermissionLevel } from "models/user/PermissionLevel";
let InventoryArchiveItem = class InventoryArchiveItem extends Vue {
    archiveItem;
    loading = false;
    showPrice = PermissionService.hasPermission(PermissionLevel.ShowPrice);
    showReport() {
        const newWindow = WindowService.initializeNewWindow();
        this.loading = true;
        PdfReportService.printInventoryArchive(this.archiveItem.id).then(res => {
            const file = new Blob([res], { type: "application/pdf" });
            const nav = window.navigator;
            if (nav && nav.msSaveOrOpenBlob) {
                nav.msSaveOrOpenBlob(file, Vue.filter("translate")("webshop_inventoryArchive_result_pdfName") + '_'
                    + this.archiveItem.name + '.pdf');
                setTimeout(() => {
                    newWindow.close();
                }, 100);
            }
            else {
                const reportLink = window.URL.createObjectURL(file);
                newWindow.location = reportLink;
                newWindow.name = Vue.filter("translate")("webshop_inventoryArchive_result_pdfName") + '_'
                    + this.archiveItem.name + '.pdf';
            }
            this.loading = false;
        }, err => {
            setTimeout(() => {
                newWindow.close();
                if (err === 416) {
                    ToastService.danger(Vue.filter("translate")("webshop_inventory_no_products"));
                }
                else {
                    ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
                }
            }, 500);
            this.loading = false;
            return;
        });
    }
    deleteArchive() {
        if (this.loading) {
            return;
        }
        ModalService.create({
            'cxt': this,
            'component': InventoryArchiveDeleteModal,
            'maxWidth': '450px',
            data: this.archiveItem
        });
    }
};
InventoryArchiveItem = __decorate([
    Component({
        components: {
            IconTrash
        },
        props: {
            archiveItem: InventoryArchiveVM
        }
    })
], InventoryArchiveItem);
export default InventoryArchiveItem;
