import { ItemMarkings } from 'models/product/ItemMarkings';
import { Service } from './Service';
export class SettingService extends Service {
    static getAll() {
        return window.app.settings;
    }
    static getHighlightedMarkings(itemMarkings) {
        let markings = new ItemMarkings();
        markings.agreement = true;
        return Object.keys(markings)
            .filter(x => markings[x] && itemMarkings[x]);
    }
}
