var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "modal-navigation" }, [
      _c(
        "button",
        {
          staticClass:
            "ui-button ui-button-flat ui-button-small ui-button-transparent",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.goBack()
            },
          },
        },
        [
          _c("i", { staticClass: "mdi mdi-arrow-left" }),
          _vm._v(
            " " +
              _vm._s(_vm._f("translate")("webshop_general_back")) +
              "\n        "
          ),
        ]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "navigation-head" }, [
        _c("i", { staticClass: "mdi mdi-format-list-bulleted" }),
        _vm._v(
          " " +
            _vm._s(
              _vm._f("translate")(
                "webshop_calculations_modals_addProducts_fromFavorite_favoritlist"
              )
            )
        ),
      ]),
    ]),
    _vm._v(" "),
    !_vm.lines || _vm.lines.length === 0
      ? _c("div", { staticClass: "slide-prefixed-period" }, [
          !_vm.loading
            ? _c("div", [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_inventory_addmodals_favoritLists_noList"
                      )
                    ) +
                    "\n        "
                ),
              ])
            : _vm._e(),
        ])
      : _c("div", { staticClass: "slide slide-favorite" }, [
          _c(
            "div",
            { staticClass: "result-list-container", attrs: { container: "" } },
            [
              _c("h2", { staticClass: "header-small header" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("translate")(
                      "webshop_calculations_modals_addProducts_fromFavorite_favoritlists"
                    )
                  )
                ),
              ]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "result-list" },
                [
                  _c("li", { staticClass: "result-line" }, [
                    _c("div", { attrs: { grid: "justify-start" } }, [
                      _c("div", { attrs: { column: "xs-8 m-9 l-9" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "clickable",
                            attrs: { grid: "m-no-gutters" },
                            on: {
                              click: function ($event) {
                                return _vm.toggleAllLines()
                              },
                            },
                          },
                          [
                            _c("div", { attrs: { column: "xs-2 s-1 m-1" } }, [
                              _c("i", {
                                staticClass: "checkbox mdi",
                                class: [
                                  _vm.isAllSelected
                                    ? "mdi-checkbox-marked"
                                    : "mdi-checkbox-blank-outline",
                                ],
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "select-all-text",
                                attrs: { column: "xs-10 s-2 m-2" },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm._f("translate")(
                                        "webshop_calculations_modals_addProducts_search_result_selectAll"
                                      )
                                    ) +
                                    "\n                                "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.lines, function (line) {
                    return _c(
                      "li",
                      { key: line.obj.id, staticClass: "result-line" },
                      [
                        _c("div", { attrs: { grid: "wrap" } }, [
                          _c(
                            "div",
                            {
                              class: [
                                line.obj.count !== 0 ? "clickable" : "on-list",
                              ],
                              attrs: { column: "xs-8 m-9 l-9" },
                              on: {
                                click: function ($event) {
                                  line.obj.count !== 0
                                    ? _vm.toggleSelection(line)
                                    : null
                                },
                              },
                            },
                            [
                              _c("div", { attrs: { grid: "m-no-gutters" } }, [
                                _c(
                                  "div",
                                  { attrs: { column: "xs-2 s-1 m-1" } },
                                  [
                                    line.obj.count !== 0
                                      ? _c("i", {
                                          staticClass: "checkbox mdi",
                                          class: [
                                            line.selected
                                              ? "mdi-checkbox-marked"
                                              : "mdi-checkbox-blank-outline",
                                          ],
                                        })
                                      : _c("i", {
                                          staticClass:
                                            "checkbox mdi mdi-playlist-remove",
                                          attrs: {
                                            title:
                                              _vm.$options.filters.translate(
                                                "webshop_inventory_addmodals_favoritLists_noProductsMessage"
                                              ),
                                          },
                                        }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "fav-name",
                                    attrs: { column: "xs-10 s-11 m-11" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(line.obj.listName) +
                                        " "
                                    ),
                                    line.obj.isShared
                                      ? _c("i", {
                                          staticClass: "mdi mdi-share-variant",
                                          attrs: {
                                            title:
                                              _vm.$options.filters.translate(
                                                "webshop_search_search_filters_iconShareTitle"
                                              ),
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "text-right",
                              attrs: { column: "xs-4 m-3 l-3" },
                            },
                            [
                              _c("span", { staticClass: "label" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("translate")(
                                      "webshop_generel_productQuantity"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(line.obj.count) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer-buttons" }, [
            _vm.selectedCount
              ? _c(
                  "form",
                  {
                    staticClass: "add-button-container",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.addFavoriteLists()
                      },
                    },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("translate")(
                            _vm.selectedCount > 1
                              ? "webshop_calculations_modals_addProducts_fromFavorite_buttons_add_preTextMultiProducts"
                              : "webshop_calculations_modals_addProducts_fromFavorite_buttons_add_preTextSingleProduct",
                            _vm.selectedCount
                          )
                        ) + " "
                      ),
                      _c("b", [_vm._v(_vm._s(_vm.list.name))]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "ui-button ui-button-small ui-button-accept",
                        attrs: { type: "submit" },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm._f("translate")(
                                "webshop_calculations_modals_addProducts_search_result_addBtn_btnText"
                              )
                            ) +
                            "\n                "
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }