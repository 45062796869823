export class ApprovalItem {
    id;
    name;
    description;
    version;
    versionDate;
    typeId;
    //type
    active;
    passiveApproval;
    //helpers
    expanded = false;
    constructor() {
        this.id = null;
        this.name = null;
        this.description = null;
        this.version = null;
        this.versionDate = null;
        this.typeId = null;
        //this.type
        this.active = null;
        this.passiveApproval = null;
    }
    static fromApi(approvalItem) {
        return Object.assign(new ApprovalItem(), approvalItem);
    }
}
