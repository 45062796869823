var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ui-modal-content" }, [
      _c("h3", { staticClass: "ui-modal-header" }, [
        _vm._v(
          _vm._s(
            _vm._f("translate")(
              "webshop_calculations_calculationList_confirmModal_titleCopy"
            )
          )
        ),
      ]),
      _vm._v(" "),
      _c("div", [
        _c(
          "form",
          {
            staticClass: "ui-form",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.copyCalculation()
              },
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                $event.preventDefault()
                if ($event.target !== $event.currentTarget) {
                  return null
                }
              },
            },
          },
          [
            _c("div", [
              _c("div", { staticClass: "button-include-children" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "ui-button ui-button-small button-new-calculation",
                    class: {
                      "ui-button-disabled": !(
                        _vm.calculationTree &&
                        _vm.calculationTree.calculationTreeVMs &&
                        _vm.calculationTree.calculationTreeVMs.length > 0
                      ),
                    },
                    attrs: {
                      type: "button",
                      disabled: !(
                        _vm.calculationTree &&
                        _vm.calculationTree.calculationTreeVMs &&
                        _vm.calculationTree.calculationTreeVMs.length > 0
                      ),
                    },
                    on: {
                      click: function ($event) {
                        _vm.isSelectedSubCalculations =
                          !_vm.isSelectedSubCalculations
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "mdi",
                      class: {
                        "mdi-checkbox-marked-outline":
                          _vm.isSelectedSubCalculations,
                        "mdi-checkbox-blank-outline":
                          !_vm.isSelectedSubCalculations,
                      },
                    }),
                    _vm._v(
                      "\n                            " +
                        _vm._s(
                          _vm._f("translate")(
                            "webshop_calculations_calculationHeader_buttons_selectSubCalculations"
                          )
                        ) +
                        "\n                        "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "share-text" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("translate")(
                      "webshop_calculations_calculationList_confirmModal_messageCopy"
                    )
                  )
                ),
              ]),
              _vm._v(" "),
              !_vm.calculationTree ||
              !_vm.calculationTree.calculationTreeVMs ||
              _vm.calculationTree.calculationTreeVMs.length == 0 ||
              !_vm.isSelectedSubCalculations
                ? _c(
                    "div",
                    {
                      staticClass: "calculation-tree",
                      attrs: { column: "s-12" },
                    },
                    [
                      _c("p", [
                        _c("span", [
                          _vm._v(_vm._s("- " + _vm.data.calculation.name)),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.calculationTreeArray &&
              _vm.calculationTreeArray.length > 0 &&
              _vm.isSelectedSubCalculations
                ? _c(
                    "div",
                    {
                      staticClass: "calculation-tree",
                      attrs: { column: "s-12" },
                    },
                    _vm._l(_vm.calculationTreeArray, function (line, index) {
                      return _c(
                        "p",
                        { key: index },
                        _vm._l(line, function (calculation, calculatioIndex) {
                          return _c(
                            "span",
                            { key: calculation.calculationId },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    line.length - 1 == calculatioIndex
                                      ? "- " + calculation.name
                                      : "    "
                                  ) +
                                  "\n                            "
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "ui-form-submitwrapper ui-form-submitwrapper-full",
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "ui-button ui-button-secondary ui-button-left",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.closeModal()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm._f("translate")(
                          "webshop_calculations_calculationHeader_buttons_cancel"
                        )
                      )
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("loading-button", {
                  attrs: {
                    "icon-class": "mdi-content-save",
                    cancel: false,
                    text: _vm.$options.filters.translate(
                      "webshop_calculations_calculationHeader_buttons_copyCalculation"
                    ),
                    "loading-text": _vm.$options.filters.translate(
                      "webshop_calculations_calculationHeader_buttons_copyCalculation"
                    ),
                    loading: _vm.loading,
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }