var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "review-comments-wrapper" }, [
    _c("div", { staticClass: "text-center" }, [
      _c("p", [
        _c("strong", [
          _vm._v(
            _vm._s(_vm._f("translate")("webshop_campaign_review_thanksText"))
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-wrap" }, [
      _vm._v(
        "\n    " +
          _vm._s(_vm._f("translate")("webshop_campaign_review_commentLabel")) +
          "\n    "
      ),
      _c("br"),
      _vm._v(" "),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.comment,
            expression: "comment",
          },
        ],
        attrs: {
          name: "comments",
          placeholder: _vm.$options.filters.translate(
            "webshop_campaign_review_commentPlaceholder"
          ),
        },
        domProps: { value: _vm.comment },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.comment = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "submit-wrapper" }, [
        _c(
          "button",
          {
            staticClass: "ui-button ui-button-secondary",
            on: {
              click: function ($event) {
                return _vm.ratingComment()
              },
            },
          },
          [
            _vm._v(
              _vm._s(
                _vm._f("translate")(
                  "webshop_campaign_review_comment_buttonSubmit"
                )
              )
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }