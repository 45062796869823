export class FavoriteListAddProductVM {
    listId;
    productId;
    stdUnit;
    checkoutId;
    constructor() {
        this.listId = null;
        this.productId = null;
        this.stdUnit = null;
        this.checkoutId = null;
    }
    static fromApi(product) {
        return Object.assign(new FavoriteListAddProductVM(), product);
    }
}
