var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import InlineDeleteButton from 'components/shared/InlineDeleteButton.vue';
import { LoaderService } from 'services/LoaderService';
import CampaignPlanningLineList from './result/CampaignPlanningLineList.vue';
import CampaignPlanningHeader from './result/CampaignPlanningHeader.vue';
Component.registerHooks([
    'beforeRouteUpdate'
]);
let CampaignPlanningDetails = class CampaignPlanningDetails extends Vue {
    mounted() {
        let campaignCode = this.$route.params.campaignCode;
        if (campaignCode) {
            this.selectCampaign(campaignCode);
        }
    }
    get selectedCampaign() {
        return this.$store.state.VendorCampaignPlanning.selectedCampaign;
    }
    beforeRouteUpdate(to, from, next) {
        var campaignCode = to.params.campaignCode;
        if (campaignCode) {
            this.selectCampaign(campaignCode);
        }
        next();
    }
    selectCampaign(campaignCode) {
        LoaderService.createLoader(null);
        this.$store.dispatch('VendorCampaignPlanning/selectCampaign', campaignCode).catch(err => {
            this.$router.replace({ name: 'vendorCampaigns' });
        }).finally(() => {
            LoaderService.stop();
        });
    }
    get loading() {
        return this.$store.state.VendorCampaignPlanning.loading;
    }
};
CampaignPlanningDetails = __decorate([
    Component({
        components: {
            InlineDeleteButton,
            CampaignPlanningLineList,
            CampaignPlanningHeader
        },
        props: {}
    })
], CampaignPlanningDetails);
export default CampaignPlanningDetails;
