import { VendorService } from 'services/VendorService';
import { VendorCampaignPlanningState } from 'models/campaignPlanning/VendorCampaignPlanningState';
import { ProductService } from 'services/ProductService';
import { CampaignPlanningLineStatus, CampaignPlanningLineWaitingForStatus } from 'models/campaignPlanning/CampaignPlanningLineStatus';
const state = new VendorCampaignPlanningState();
const getters = {};
const actions = {
    getAll({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            VendorService.getCampaignPlanningList().then(campaigns => {
                commit('setAll', campaigns);
                commit('setLoading', false);
                resolve(campaigns);
            }).catch(err => {
                commit('setLoading', false);
                reject();
            });
        });
    },
    selectCampaign({ commit, dispatch }, campaignCode) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            commit('selectCampaign', campaignCode);
            dispatch('getVendorProducts', state.selectedCampaign.purchaseStartingDate);
            commit('setLoading', false);
            resolve(state.selectedCampaign);
        });
    },
    getVendorProducts({ commit, dispatch }, purchaseDate) {
        return new Promise((resolve, reject) => {
            ProductService.getVendorProducts(purchaseDate).then(products => {
                commit('setVendorProducts', products);
                resolve(products);
            }).catch(err => {
                reject();
            });
        });
    },
    getUnits({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            ProductService.getAllUnits().then(units => {
                commit('setUnits', units);
                resolve(units);
            }).catch(err => {
                reject();
            });
        });
    },
    createCampaignPlanningLine({ commit, dispatch }, campaignLine) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            VendorService.createCampaignPlanningLine(campaignLine).then(res => {
                commit('createCampaignPlanningLine', res);
                commit('setLoading', false);
                resolve(campaignLine);
            }).catch(err => {
                commit('setLoading', false);
                reject();
            });
        });
    },
    updateCampaignPlanningLine({ commit, dispatch }, campaignLine) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            VendorService.updateCampaignPlanningLine(campaignLine).then(res => {
                commit('updateCampaignPlanningLine', res);
                commit('setLoading', false);
                resolve(campaignLine);
            }).catch(err => {
                commit('setLoading', false);
                reject();
            });
        });
    },
    deleteCampaignPlanningLine({ commit, dispatch }, campaignLine) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            VendorService.deleteCampaignPlanningLine(campaignLine).then(res => {
                commit('deleteCampaignPlanningLine', campaignLine);
                commit('setLoading', false);
                resolve(campaignLine);
            }).finally(() => {
                commit('setLoading', false);
                reject();
            });
        });
    },
    changeCampaignPlanningLineWaitingFor({ commit, dispatch }, campaignLines) {
        return new Promise((resolve, reject) => {
            VendorService.changeCampaignPlanningLineWaitingFor(campaignLines).then(res => {
                res.forEach(x => {
                    commit('updateCampaignPlanningLine', x);
                    commit('updateCampaignWaitingForVendor', x);
                });
                resolve(res);
            }).catch(err => {
                reject();
            });
        });
    },
};
const mutations = {
    setAll(state, list) {
        state.list = list;
    },
    selectCampaign(state, campaignCode) {
        var oldSelected = state.selectedCampaign;
        state.selectedCampaign = state.list.find((x) => x.code == campaignCode);
        if (!oldSelected || oldSelected.code != campaignCode) {
            this._vm.$nextTick(() => {
                try {
                    var isSafari = /constructor/i.test(window["HTMLElement"]) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof window['safari'] !== 'undefined' && window['safari'].pushNotification));
                    var isIE = /*@cc_on!@*/ false || !!document["documentMode"];
                    var isEdge = !isIE && !!window["StyleMedia"];
                    var scrollPoint = document.getElementsByClassName("campaign-scroll-point")[0];
                    var floatingHeader = document.getElementById("shop-floatingHeader");
                    var scrollYPos = scrollPoint.offsetTop - (floatingHeader.offsetHeight + floatingHeader.offsetTop);
                    if (!isSafari && !isEdge && !isIE) {
                        window.scrollTo({ top: scrollYPos, behavior: 'smooth' });
                    }
                    else {
                        window.scroll(0, scrollYPos);
                    }
                }
                catch (e) {
                    console.log("e", e);
                }
            });
        }
    },
    updateCampaignWaitingForVendor(state, campaignLine) {
        const campaign = state.list.find(c => c.code == campaignLine.campaignCode);
        if (campaign && campaign.waitingForVendor > 0) {
            campaign.waitingForVendor = campaign.campaignPlaningLines
                .filter(x => x.waitingFor == CampaignPlanningLineWaitingForStatus.WaitingForVendor).length;
        }
    },
    setVendorProducts(state, list) {
        state.vendorProducts = list;
    },
    createCampaignPlanningLine(state, campaignLine) {
        let campaignList = state.list.find((list) => list.code == campaignLine.campaignCode);
        let placeIndex = campaignList.campaignPlaningLines.findIndex((x) => x.description.localeCompare(campaignLine.description) > 0);
        campaignList.campaignPlaningLines.splice(placeIndex, 0, campaignLine);
    },
    updateCampaignPlanningLine(state, campaignLine) {
        let campaignList = state.list.find((list) => list.code == campaignLine.campaignCode);
        let lineIndex = campaignList.campaignPlaningLines.findIndex(x => x.vendorItemNo == campaignLine.vendorItemNo);
        const props = Object.keys(campaignList.campaignPlaningLines[lineIndex]);
        props.forEach(prop => {
            campaignList.campaignPlaningLines[lineIndex][prop] = campaignLine[prop];
        });
    },
    deleteCampaignPlanningLine(state, campaignLine) {
        let campaignList = state.list.find((list) => list.code == campaignLine.campaignCode);
        let lineIndex = campaignList.campaignPlaningLines.findIndex(x => x.vendorItemNo == campaignLine.vendorItemNo);
        campaignList.campaignPlaningLines[lineIndex].status = CampaignPlanningLineStatus.Rejected;
    },
    setUnits(state, units) {
        state.units = units;
    },
    setLoading(state, status) {
        state.loading = status;
    }
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
