export class UpdateOrderLineVM {
    lineNo;
    productId;
    quantity;
    selectedUnit; // 
    deliveryDate; // Convert to date?
    description;
    depositLine;
    constructor(lineNo, productId, quantity, selectedUnit, deliveryDate, description, depositLine) {
        this.lineNo = lineNo;
        this.productId = productId;
        this.quantity = quantity;
        this.selectedUnit = selectedUnit;
        this.deliveryDate = deliveryDate;
        this.description = description;
        this.depositLine = depositLine;
    }
}
