var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "custom-tag-wrapper",
      class: {
        "read-only": _vm.readOnly,
        "custom-tag-wrapper--active": _vm.isInputActive,
      },
      on: {
        click: function ($event) {
          return _vm.focusNewTag()
        },
      },
    },
    [
      _vm._l(_vm.innerTags, function (tag, index) {
        return _c("span", { key: index, staticClass: "input-tag" }, [
          _c("span", [_vm._v(_vm._s(tag))]),
          _vm._v(" "),
          !_vm.readOnly
            ? _c(
                "a",
                {
                  staticClass: "remove",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.remove(index)
                    },
                  },
                },
                [_c("i", { staticClass: "mdi mdi-close-circle-outline" })]
              )
            : _vm._e(),
        ])
      }),
      _vm._v(" "),
      !_vm.readOnly && !_vm.isLimit
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.newTag,
                expression: "newTag",
              },
            ],
            ref: "inputtag",
            staticClass: "new-tag",
            attrs: { placeholder: _vm.placeholder, type: "text" },
            domProps: { value: _vm.newTag },
            on: {
              keydown: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                      "Backspace",
                      "Delete",
                      "Del",
                    ])
                  ) {
                    return null
                  }
                  $event.stopPropagation()
                  return _vm.removeLastTag.apply(null, arguments)
                },
                _vm.addNew,
              ],
              blur: _vm.handleInputBlur,
              focus: _vm.handleInputFocus,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.newTag = $event.target.value
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }