var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    !_vm.selectedCategory
      ? _c(
          "ul",
          _vm._l(_vm.filteredCategories, function (cat) {
            return _c(
              "li",
              {
                key: cat.code,
                on: {
                  click: function ($event) {
                    return _vm.selectCategory(cat)
                  },
                },
              },
              [
                _vm._v(
                  "\n            " + _vm._s(cat.description) + "\n            "
                ),
                _c("span", [_vm._v("(" + _vm._s(cat.amount) + ")")]),
              ]
            )
          }),
          0
        )
      : _c(
          "ul",
          [
            _c(
              "li",
              {
                staticClass: "go-back",
                on: {
                  click: function ($event) {
                    return _vm.selectCategory(null)
                  },
                },
              },
              [
                _c("i", { staticClass: "mdi mdi-chevron-left" }),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_search_search_filters_goBack"
                      )
                    )
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm._l(_vm.filteredGroups, function (group) {
              return _c(
                "li",
                {
                  key: group.id,
                  class: { active: group.active },
                  on: {
                    click: function ($event) {
                      return _vm.selectGroup(group)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(group.description) +
                      "\n            "
                  ),
                  _c("span", [_vm._v("(" + _vm._s(group.amount) + ")")]),
                ]
              )
            }),
          ],
          2
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }