import { Service } from './Service';
import Vue from "vue";
export class WindowService extends Service {
    static initializeNewWindow() {
        const newWindow = window.open();
        newWindow.document.write(this.getSpinnerText() + this.getSpinnerElement() + this.getSpinnerCss());
        return newWindow;
    }
    static getSpinnerText() {
        return "<p class='loading-text'>" + Vue.filter("translate")("webshop_myPage_alcohol_generate_report_loading") + "</p>";
    }
    static getSpinnerElement() {
        return "<div class='spinner-wrapper'><div class='lds-dual-ring'></div></div> ";
    }
    static getSpinnerCss() {
        return `
        <style>
        .loading-text{
            margin-top: 5%;
            text-align: center;
            font-family: 'roboto-regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
            font-size: 1.5rem;
        }
        .spinner-wrapper {
            width: 100%;
            text-align: center;
        }
        .lds-dual-ring {
            display: inline-block;
            width: 64px;
            height: 64px;
          }
          .lds-dual-ring:after {
            content: " ";
            display: block;
            width: 46px;
            height: 46px;
            margin: 1px;
            border-radius: 50%;
            border: 5px solid red;
            border-color: rgb(0, 67, 122) transparent rgb(255, 228, 91) transparent;
            animation: lds-dual-ring 1.2s linear infinite;
          }
          @keyframes lds-dual-ring {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }</style>`;
    }
}
