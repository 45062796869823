var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { ToastService } from 'services/ToastService';
import { ProductService } from 'services/ProductService';
import { InventoryLine } from 'models/inventory/InventoryLine';
import { PermissionService } from 'services/PermissionService';
import { PermissionLevel } from 'models/user/PermissionLevel';
import InputNumber from "components/shared/InputNumber.vue";
let InventoryProductOwnFormPartial = class InventoryProductOwnFormPartial extends Vue {
    loading;
    setLoading;
    model = new InventoryLine();
    units = [];
    list;
    line;
    disabled;
    saveBtnText;
    saveBtnClass;
    altSaveBtnText = Vue.filter('translate')('webshop_general_save');
    showPrice = PermissionService.hasPermission(PermissionLevel.ShowPrice);
    get formattedPrice() {
        if (!this.model || !this.model.pricePrUnit)
            return null;
        let price = this.model.pricePrUnit;
        if (this.disabled) //used by confirm delete
            return Vue.filter('currency')(this.model.pricePrUnit, { symbol: '', symbolSpacing: false });
        return this.model.pricePrUnit.toString().replace(".", ",");
    }
    set formattedPrice(newValue) {
        newValue = newValue.replace(",", ".");
        this.model.pricePrUnit = Number.parseFloat(newValue);
    }
    mounted() {
        this.model.amount = 0;
        this.model.pricePrUnit = 0;
        this.model.groupId = null;
        if (this.line && this.line.productId) { //used by confirm delete
            this.model = Object.assign({}, this.line);
            this.units = this.model.units;
        }
        else {
            this.setLoading(true);
            ProductService.getAllUnits().then(data => {
                this.units = data;
                this.setLoading(false);
                if (this.line) {
                    this.model = Object.assign({}, this.line);
                }
            })
                .catch(err => {
                this.setLoading(false);
                ToastService.danger(Vue.filter('translate')('webshop_defaults_toast_errorText'));
            });
        }
    }
    submit() {
        this.$emit('save', this.model);
    }
    cancel() {
        this.$emit('cancel');
    }
};
InventoryProductOwnFormPartial = __decorate([
    Component({
        components: {
            InputNumber
        },
        props: {
            setLoading: Function,
            loading: Boolean,
            list: Object,
            line: Object,
            disabled: Boolean,
            saveBtnText: String,
            saveBtnClass: String
        }
    })
], InventoryProductOwnFormPartial);
export default InventoryProductOwnFormPartial;
