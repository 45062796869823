import { EventTypes } from 'models/EventTypes';
import { BasketProduct } from 'models/order/BasketProduct';
import { ModalService } from 'modules/basic-core/basic-modal/src';
import Vue from 'vue';
import { FavoriteService } from './FavoriteService';
import { OrderService } from './OrderService';
import FavoriteCreateModal from 'components/favorite/FavoriteCreateModal.vue';
import { SearchService } from './SearchService';
import { OrderlineUISourceTypes } from 'models/order/OrderlineUISourceTypes';
import { ProductService } from './ProductService';
import { SearchInput } from 'models/search/SearchInput';
import DepictItem from 'components/shop/partials/DepictItem.vue';
import DepictMultipleProductsFooter from 'components/shop/partials/DepictMultipleProductsFooter.vue';
import { DepictCustomFilter } from 'models/depict/DepictCustomFilters';
import { ItemIcons } from 'models/product/ItemIcons';
import { WindowService } from './WindowService';
import { PdfReportService } from './PdfReportService';
import { ToastService } from './ToastService';
window.depict = window.depict || {};
export class DepictSharedIntegrationService {
    static defineAddToOrder(thisRef) {
        window.depict.addToOrder = (products) => {
            return new Promise((resolve, reject) => {
                let mapProducts = products.map(x => {
                    let p = new BasketProduct();
                    p.productId = x.productId;
                    p.amount = x.amount;
                    p.unit = x.unit;
                    p.orderlineUISource = x.orderLineUISource == null || x.orderLineUISource == undefined || x.orderLineUISource == '' ?
                        OrderlineUISourceTypes.DepictDefault : x.orderLineUISource;
                    p.brand = x.brand;
                    p.image = x.image;
                    p.description = x.title;
                    return p;
                });
                OrderService.addProductsToBasket(mapProducts).then((res) => {
                    if (res != null && res.addedOrderLines > 0) {
                        // ToastService.success(
                        //   Vue.filter("translate")(
                        //     "webshop_cart_add_toast_basketAdded",
                        //     res.addedOrderLines
                        //   )
                        // );
                        thisRef.$root.$emit(EventTypes.MiniCartUpdate, res.previewOrder, mapProducts);
                        thisRef.$root.$emit(EventTypes.BasketUpdate, true);
                        resolve(true);
                    }
                    else {
                        // var errorMessage = Vue.filter("translate")(
                        //   "webshop_cart_add_toast_errorText"
                        // );
                        // if (res.statusCode === 416) {
                        //     ToastService.danger(
                        //     errorMessage,
                        //     Vue.filter("translate")("webshop_cart_add_toast_errorHeader")
                        //     );
                        // }
                        // ToastService.danger(
                        //   errorMessage,
                        //   Vue.filter("translate")("webshop_cart_add_toast_errorHeader")
                        // );
                        reject(false);
                    }
                }).catch(() => {
                    // ToastService.danger(
                    //   Vue.filter("translate")("webshop_defaults_toast_errorText")
                    // );
                    reject(false);
                });
            });
        };
    }
    static defineAddToFavoriteList(thisRef) {
        window.depict.addToFavoriteList = (productId, unit, favoriteListId) => {
            return new Promise((resolve, reject) => {
                FavoriteService.addProduct(Number.parseInt(favoriteListId), productId, unit).then(() => {
                    // ProductService.getProduct(productId).then((product: ProductVM) => {
                    //     ToastService.success(
                    //         Vue.filter('translate')('webshop_search_favorite_toggleFavorite_addProduct_toast_added', product.description)
                    //     );
                    //     resolve(true);
                    // }).catch(() => {
                    //     reject(false);
                    // });
                    resolve(true);
                }).catch(err => {
                    // var errorMessage = Vue.filter('translate')('webshop_search_favorite_toggleFavorite_addProduct_toast_error');
                    // if (err === 406) {
                    //     errorMessage = Vue.filter('translate')('webshop_search_favorite_toggleFavorite_addProduct_toast_listNotFound');
                    // } else if (err === 404) {
                    //     errorMessage = Vue.filter('translate')('webshop_search_favorite_toggleFavorite_addProduct_toast_productNotexists');
                    // } else if (err === 409) {
                    //     errorMessage = Vue.filter('translate')('webshop_search_favorite_toggleFavorite_addProduct_toast_exists');
                    // }
                    // ToastService.danger(errorMessage);
                    reject(false);
                });
            });
        };
    }
    static defineDeleteFromFavoriteList(thisRef) {
        window.depict.deleteFromFavoriteList = (productId, favoriteListId) => {
            return new Promise((resolve, reject) => {
                FavoriteService.deleteProductFromList(Number.parseInt(favoriteListId), productId).then(() => {
                    // ToastService.success(Vue.filter('translate')('webshop_search_favorite_toggleFavorite_remove_toast_removed'));
                    resolve(true);
                }).catch((err) => {
                    // var errorMessage = Vue.filter('translate')('webshop_search_favorite_toggleFavorite_remove_toast_error');
                    // if (err === 406) {
                    //     errorMessage = Vue.filter('translate')('webshop_search_favorite_toggleFavorite_remove_toast_listNotFound');
                    // } else if (err == 404) {
                    //     errorMessage = Vue.filter('translate')('webshop_search_favorite_toggleFavorite_remove_toast_productNotexists');
                    // }
                    // ToastService.danger(errorMessage);
                    reject(false);
                });
            });
        };
    }
    static defineOpenProductModal(thisRef) {
        window.depict.openProductModal = (productId) => {
            return new Promise((resolve, reject) => {
                SearchService.openFoodInfo(thisRef, productId);
                resolve(true);
            });
        };
    }
    static defineOpenCreateNewFavoriteListModal(thisRef) {
        window.depict.openCreateNewFavoriteListModal = (productId, unit) => {
            return new Promise((resolve, reject) => {
                ModalService.create({
                    'cxt': thisRef,
                    'component': FavoriteCreateModal,
                    'maxWidth': '450px',
                });
                thisRef.$root.$once(EventTypes.FavoriteCreatedList, (data) => {
                    if (data == null || data == undefined) {
                        reject(false);
                        return;
                    }
                    if (productId == undefined || productId == '' || productId == null) {
                        resolve(true);
                        return;
                    }
                    window.depict.addToFavoriteList(productId, unit, data.id)
                        .then(() => {
                        resolve(true);
                    })
                        .catch(() => {
                        reject(false);
                    });
                });
            });
        };
    }
    static defineGetProducts() {
        window.depict.getProducts = (productIds) => {
            return ProductService.getProducts(productIds);
        };
    }
    static defineGetSCProducts() {
        window.depict.getSCProducts = (productIds) => {
            const searchInput = new SearchInput();
            if (productIds && productIds.length && productIds.length <= 50)
                searchInput.searchQuery = `[${productIds.join(',')}]`;
            return new Promise((resolve, reject) => {
                SearchService.search(searchInput).then((res) => {
                    resolve(res.products);
                }).catch(err => reject(err));
            });
        };
    }
    static defineGenerateProductCard(thisRef) {
        window.depict.generateProductCard = (item) => {
            item.uiSource = typeof item.uiSource == undefined ? 'depict' : item.uiSource;
            item.loading = typeof item.loading == undefined ? false : item.loading;
            item.viewType = typeof item.viewType == undefined ? 0 : item.viewType;
            const productCardInstance = new DepictItem({ parent: thisRef, propsData: {
                    product: item.product,
                    uiSource: item.uiSource,
                    position: item.position,
                    loading: item.loading,
                    viewType: item.viewType
                } });
            return productCardInstance;
        };
    }
    static defineButtonAddMultipleProducts(thisRef) {
        window.depict.buttonAddMultipleProducts = () => {
            const productCardInstance = new DepictMultipleProductsFooter({ parent: thisRef });
            return productCardInstance;
        };
    }
    static printTopN() {
        const newWindow = WindowService.initializeNewWindow();
        PdfReportService.printTopN(200).then(res => {
            const file = new Blob([res], { type: "application/pdf" });
            const nav = window.navigator;
            if (window.navigator && nav.msSaveOrOpenBlob) {
                nav.msSaveOrOpenBlob(file, 'top200.pdf');
                setTimeout(() => {
                    newWindow.close();
                }, 100);
            }
            else {
                const reportLink = window.URL.createObjectURL(file);
                newWindow.location = reportLink;
            }
        }, err => {
            setTimeout(() => {
                newWindow.close();
                if (err == 404) {
                    ToastService.danger(Vue.filter("translate")("webshop_search_search_result_empty_state_no_products_search"));
                }
                else {
                    ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
                }
            }, 500);
            return;
        });
    }
    static getCustomFilters() {
        const icons = new ItemIcons();
        let filters = [
            new DepictCustomFilter("topp:200", "selector-icon mdi mdi-star", Vue.filter('translate')('webshop_search_search_filters_top_200'), this.printTopN),
            new DepictCustomFilter("hascampaign", "selector-icon " + icons.HasCampaign.icon, icons.HasCampaign.label)
        ];
        if (window.depict && window.depict.searchMenu && window.depict.searchMenu.hasAgreement) {
            filters.push(new DepictCustomFilter("agreement", "selector-icon " + icons.Agreement.icon, icons.Agreement.label));
        }
        return filters;
    }
    static defineGetFilters() {
        window.depict.getFilters = () => {
            return this.getCustomFilters();
        };
    }
    static defineGetProductsFromFilter() {
        window.depict.getProductsFromFilter = (filterId) => {
            const customFilter = this.getCustomFilters().find(x => x.filterId == filterId);
            const searchInput = new SearchInput();
            if (customFilter) {
                if (SearchService.topp200 == customFilter.filterId) {
                    searchInput.searchQuery = customFilter.filterId;
                }
                else {
                    searchInput.markings.push(customFilter.filterId);
                }
            }
            return new Promise((resolve, reject) => {
                SearchService.search(searchInput).then((res) => {
                    resolve(res.products);
                }).catch(err => reject(err));
            });
        };
    }
    static init(thisRef) {
        this.defineAddToOrder(thisRef);
        this.defineAddToFavoriteList(thisRef);
        this.defineDeleteFromFavoriteList(thisRef);
        this.defineOpenProductModal(thisRef);
        this.defineOpenCreateNewFavoriteListModal(thisRef);
        this.defineGetProducts();
        this.defineGetSCProducts();
        this.defineGenerateProductCard(thisRef);
        this.defineButtonAddMultipleProducts(thisRef);
        this.defineGetFilters();
        this.defineGetProductsFromFilter();
    }
}
