var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "input-number-unit": _vm.inputUnit != undefined && _vm.inputUnit != "",
      },
    },
    [
      _c(
        "input",
        _vm._b(
          {
            directives: [
              {
                name: "select-onfocus",
                rawName: "v-select-onfocus",
                value: _vm.selectOnFocus,
                expression: "selectOnFocus",
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.stringValue,
                expression: "stringValue",
              },
            ],
            ref: _vm.inputRef,
            class: _vm.inputClass,
            attrs: {
              disabled: _vm.inputDisabled,
              tabindex: _vm.inputTabindex,
              type: "text",
            },
            domProps: { value: _vm.stringValue },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.stringValue = $event.target.value
                },
                _vm.inputChange,
              ],
              blur: _vm.onBlur,
              focus: function ($event) {
                return _vm.$emit("focus", $event)
              },
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.onKeyEnter.apply(null, arguments)
              },
              keydown: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "up", 38, $event.key, [
                      "Up",
                      "ArrowUp",
                    ])
                  ) {
                    return null
                  }
                  return _vm.changeValueByStep.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "down", 40, $event.key, [
                      "Down",
                      "ArrowDown",
                    ])
                  ) {
                    return null
                  }
                  return _vm.changeValueByStep.apply(null, arguments)
                },
              ],
            },
          },
          "input",
          _vm.$attrs,
          false
        )
      ),
      _vm._v(" "),
      _vm.inputUnit != undefined && _vm.inputUnit != ""
        ? _c("span", { on: { click: _vm.focusInput } }, [
            _vm._v(_vm._s(_vm.inputUnit)),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }