var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { EventTypes } from 'models/EventTypes';
let InventoryConfirmModal = class InventoryConfirmModal extends Vue {
    loading = false;
    settings;
    close() {
        this.$root.$emit(EventTypes.InventoryModalConfirmClose);
    }
    accept() {
        if (typeof this.settings.onAccept === "function") {
            this.loading = true;
            this.settings.onAccept()
                .then(() => {
                this.loading = false;
            })
                .catch(() => {
                this.loading = false;
            });
        }
    }
};
InventoryConfirmModal = __decorate([
    Component({
        props: {
            //loading: Boolean,
            settings: Object
        }
    })
], InventoryConfirmModal);
export default InventoryConfirmModal;
