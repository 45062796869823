var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ui-modal-content" }, [
      _c("h3", { staticClass: "ui-modal-header" }, [
        _vm._v(
          _vm._s(
            _vm._f("translate")(
              "webshop_favorite_alt_product_replace_modal_header"
            )
          )
        ),
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v(
          "\r\n            " +
            _vm._s(
              _vm._f("translate")(
                "webshop_favorite_alt_product_replace_model_content",
                this.data.description
              )
            ) +
            "\r\n        "
        ),
      ]),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "ui-form",
          staticStyle: { "margin-top": "15px" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "ui-form-submitwrapper" },
            [
              _c(
                "a",
                {
                  staticClass: "ui-button",
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_login_changePassword_cancelBtn"
                      )
                    )
                  ),
                ]
              ),
              _vm._v(" "),
              _c("loading-button", {
                attrs: {
                  loading: _vm.loading,
                  "icon-class": "mdi-cached",
                  text: _vm.$options.filters.translate(
                    "webshop_favorite_alt_product_replace"
                  ),
                  "loading-text": _vm.$options.filters.translate(
                    "webshop_favorite_alt_product_replace_modal_loading"
                  ),
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }