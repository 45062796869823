import { Service } from './Service';
import { ApprovalApi } from 'api/ApprovalApi';
import { ApprovalItem } from 'models/login/ApprovalItem';
export class ApprovalService extends Service {
    static getWaitingForLoginApproval() {
        return new Promise((resolve, reject) => {
            ApprovalApi.getWaitingForApproval(true).then((res) => {
                var result = res.data
                    .map(x => ApprovalItem.fromApi(x));
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static acceptApprovals(types) {
        return new Promise((resolve, reject) => {
            ApprovalApi.acceptApprovals(types).then(() => {
                resolve();
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
}
