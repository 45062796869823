import { default as axios } from 'axios';
import { Api } from './Api';
export class AuthApi {
    static client = Api.client('/api/auth');
    static login(username, password, rememberMe) {
        return axios.create({
            baseURL: '/api/auth'
        }).post('/Login', {
            username: username,
            password: password,
            rememberMe: rememberMe
        });
    }
    static getDeliveryDates() {
        return this.client.get('/DeliveryDates');
    }
    static setDeliveryDate(date, pickup) {
        return this.client.post('/SetDeliveryDate', {
            date: date,
            pickup: pickup
        });
    }
    static requestUsername(email) {
        return this.client.post('/RequestUsername?email=' + email);
    }
    static requestPassword(username) {
        return this.client.post('/RequestPassword?username=' + username);
    }
    static resetPassword(guid, token, newPassword) {
        return this.client.post('/ResetPassword', {
            guid: guid,
            token: token,
            newPassword: newPassword
        });
    }
    static changePassword(oldPassword, newPassword) {
        return this.client.post('/ChangePassword', {
            oldPassword: oldPassword,
            newPassword: newPassword
        });
    }
}
