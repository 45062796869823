var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Spinner from "components/shared/Spinner.vue";
let ShopFilterHeaderProps = Vue.extend({
    props: {
        loading: {
            type: Boolean,
            default: true
        },
        active: Boolean,
        mobileFiltersOpen: Boolean,
        name: String,
        isCustomList: Boolean
    }
});
let ShopFilterHeader = class ShopFilterHeader extends ShopFilterHeaderProps {
    isActive = this.active;
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    }
    mounted() {
        this.handleResize();
    }
    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
    }
    handleResize() {
        if (window.innerWidth < 1100)
            this.isActive = false;
        else if (this.active)
            this.isActive = true;
    }
    toggleFilter() {
        this.isActive = !this.isActive;
    }
    onMobileFiltersOpenChange(new_val, old_val) {
        if (window.innerWidth < 1100 && new_val === false)
            this.isActive = new_val;
    }
};
__decorate([
    Watch("mobileFiltersOpen")
], ShopFilterHeader.prototype, "onMobileFiltersOpenChange", null);
ShopFilterHeader = __decorate([
    Component({
        components: {
            Spinner
        }
    })
], ShopFilterHeader);
export default ShopFilterHeader;
