var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nav-mobile__overlay", attrs: { id: "navigation-mobile" } },
    [
      _c("div", { staticClass: "nav-mobile__top" }, [
        _c(
          "button",
          {
            staticClass: "nav-mobile__toggle",
            attrs: { id: "toggle-nav-mobile" },
            on: {
              click: function ($event) {
                return _vm.hideMobile()
              },
            },
          },
          [
            _c("img", {
              attrs: { src: _vm.asssetsBaseLink + "icons/close--white.svg" },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "nav-mobile__menu-links--internal" },
        [
          _vm._l(_vm.menu.Navigation, function (tlPage, i) {
            return [
              tlPage.Children.length > 0
                ? [
                    _c(
                      "div",
                      { key: i, staticClass: "nav-mobile__dropdown" },
                      [
                        _c(
                          "nav-link",
                          {
                            attrs: {
                              className: "regular-link",
                              url: _vm.createLink(tlPage),
                              label: tlPage.Name,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "nav-mobile__dropdown__content",
                                class: { active: _vm.activeIndex === i },
                              },
                              _vm._l(
                                tlPage.Children,
                                function (tlChildPage, iChild) {
                                  return _c(
                                    "ul",
                                    { key: iChild },
                                    [
                                      _c("nav-link", {
                                        attrs: {
                                          url: _vm.createLink(tlChildPage),
                                          label: tlChildPage.Name,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "nav-mobile__dropdown__toggle",
                            class: { active: _vm.activeIndex === i },
                            on: {
                              click: function ($event) {
                                return _vm.showDropDown(i)
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src:
                                  _vm.asssetsBaseLink +
                                  "icons/arrow-down--black.svg",
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                : [
                    _c("nav-link", {
                      key: i,
                      attrs: {
                        url: _vm.createLink(tlPage),
                        label: tlPage.Name,
                      },
                    }),
                  ],
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "nav-mobile__menu-links--external" },
        _vm._l(_vm.menu.TopNavigation, function (externalLink, i) {
          return _c("li", { key: i }, [
            _c("a", { attrs: { href: _vm.createLink(externalLink) } }, [
              _vm._v(_vm._s(externalLink.Name)),
            ]),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }