var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-overlay active inventory-autolager-modal" },
    [
      _c("div", { staticClass: "modal grey" }, [
        _c("div", { staticClass: "modal-header" }, [
          _c("h1", { staticClass: "header-medium" }, [
            _vm._v(
              _vm._s(_vm._f("translate")("webshop_inventory_autolager_title"))
            ),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "modal-close",
              on: {
                click: function ($event) {
                  return _vm.close()
                },
              },
            },
            [_c("i", { staticClass: "mdi mdi-close-circle-outline" })]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-content clearfix" }, [
          !_vm.submitted
            ? _c("div", [
                _c("div", { attrs: { grid: "wrap" } }, [
                  _c("div", { attrs: { column: "m-6" } }, [
                    _c("p", {
                      staticClass: "text-content",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$options.filters.translate(
                            "webshop_inventory_autolager_content"
                          )
                        ),
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { attrs: { column: "m-6" } }, [
                    _c(
                      "div",
                      { staticClass: "upper-submit-wrapper" },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "ui-button ui-button-small ui-button-accept",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.submit()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\r\n                                " +
                                _vm._s(
                                  _vm._f("translate")(
                                    "webshop_inventory_autolager_submitBtn"
                                  )
                                ) +
                                "\r\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("flat-pickr", {
                          staticClass: "date-picker-inline-autolager",
                          attrs: { config: _vm.dpSettings },
                          model: {
                            value: _vm.selectedDate,
                            callback: function ($$v) {
                              _vm.selectedDate = $$v
                            },
                            expression: "selectedDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ])
            : _c("div", [
                _c("div", { attrs: { grid: "wrap" } }, [
                  _c("div", { attrs: { column: "s-12" } }, [
                    _c("p", { staticClass: "text-content" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("translate")(
                            "webshop_inventory_autolager_result_content"
                          )
                        )
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "table--responsive" }, [
                  _c(
                    "table",
                    { staticClass: "table table--full result-table" },
                    [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_inventory_autolager_result_tableName"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_inventory_autolager_result_tableNew"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_inventory_autolager_result_tableUpdated"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_inventory_autolager_result_tableDeleted"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_inventory_autolager_result_tableIgnored"
                                )
                              )
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.result, function (l) {
                          return _c("tr", { key: l.listId }, [
                            _c("td", [_vm._v(_vm._s(l.listName))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(l.added))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(l.updated))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(l.deleted))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(l.ignored))]),
                          ])
                        }),
                        0
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { attrs: { column: "s-12" } }, [
                  _c("div", { staticClass: "submit-wrapper" }, [
                    _c(
                      "button",
                      {
                        staticClass: "ui-button ui-button-small",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.reset()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\r\n                            " +
                            _vm._s(
                              _vm._f("translate")(
                                "webshop_inventory_autolager_result_btns_back"
                              )
                            ) +
                            "\r\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "ui-button ui-button-small ui-button-accept",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.close()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\r\n                            " +
                            _vm._s(
                              _vm._f("translate")(
                                "webshop_inventory_autolager_result_btns_close"
                              )
                            ) +
                            "\r\n                        "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.loading
                  ? _c("div", { staticClass: "inventory-loader" }, [_vm._m(0)])
                  : _vm._e(),
              ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "simple-loader-ui" }, [
      _c("div", { staticClass: "simple-loader dark" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }