var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import LoadingButton from "components/shared/LoadingButton.vue";
import FavoriteDeleteConfirmModal from "components/favorite/FavoriteDeleteConfirmModal.vue";
import FavoriteAddProductModal from "components/favorite/FavoriteAddProductModal.vue";
import ConfirmModal from "components/shared/ConfirmModal.vue";
import { ModalService } from "modules/basic-core/basic-modal/src";
import { FavoriteList } from "models/favorites/FavoriteList";
import { FavoriteService } from "services/FavoriteService";
import { ToastService } from "services/ToastService";
import { SearchService } from "services/SearchService";
import { SearchInput } from "models/search/SearchInput";
import { EventTypes } from "models/EventTypes";
import { PdfReportService } from "services/PdfReportService";
import { WindowService } from 'services/WindowService';
import { PermissionService } from "services/PermissionService";
import { PermissionLevel } from "models/user/PermissionLevel";
import { Settings } from "config/Settings";
import ShopFavoriteListSortModal from "./ShopFavoriteListSortModal.vue";
let ShopFavoriteListHeader = class ShopFavoriteListHeader extends Vue {
    loading;
    lists;
    input;
    list = null;
    showEdit = false;
    editLoading = false;
    enableShareFavoriteList = PermissionService.hasPermission(PermissionLevel.EnableShareFavoriteList);
    enableInventory = PermissionService.hasPermission(PermissionLevel.EnableInventory);
    tempName = null;
    Settings = Settings;
    mounted() {
        if (this.lists && this.lists.length > 0 && this.$route.query && this.$route.query.f && this.$route.query.d) {
            this.resetList();
        }
    }
    onListChange() {
        this.resetList();
    }
    resetList() {
        let listId = SearchService.currentListId(this.$route);
        this.list = listId ? this.lists.find(x => x.id == listId) : null;
        this.showEdit = false;
        this.editLoading = false;
    }
    toggleEdit() {
        this.showEdit = !this.showEdit;
        this.tempName = this.list.listName;
    }
    downloadCsv() {
        const nav = window.navigator;
        const timeout = nav.msSaveBlob ? 200 : 0; //Fix settings close animation for IE
        setTimeout(() => {
            var anchor = document.createElement("a");
            anchor.style.display = "none";
            anchor.download = this.list.listName + ".csv";
            anchor.href = "/api/favoritelists/export/" + this.list.id;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
        }, timeout);
    }
    print() {
        // Needed to trick the browser popup blocker to not block the window
        const newWindow = WindowService.initializeNewWindow();
        PdfReportService.getFavoriteList(this.list.id).then(res => {
            const file = new Blob([res], { type: "application/pdf" });
            const nav = window.navigator;
            if (window.navigator && nav.msSaveOrOpenBlob) {
                nav.msSaveOrOpenBlob(file, 'favoritlista.pdf');
                setTimeout(() => {
                    newWindow.close();
                }, 100);
            }
            else {
                const reportLink = window.URL.createObjectURL(file);
                newWindow.location = reportLink;
            }
            this.loading = false;
        }, err => {
            setTimeout(() => {
                newWindow.close();
                ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
            }, 500);
            this.loading = false;
            return;
        });
    }
    saveList() {
        this.editLoading = true;
        const tempObj = Object.assign(this.list);
        tempObj.listName = this.tempName;
        FavoriteService.updateList(tempObj)
            .then(updatedList => {
            this.list.listName = updatedList.listName;
            this.list.sorting = updatedList.sorting;
            this.$root.$emit(EventTypes.FavoriteListUpdate, updatedList);
            ToastService.success(Vue.filter("translate")("webshop_search_favorite_edit_toasts_updated", "<i>" + this.list.listName + "</i>"));
            this.toggleEdit();
            this.editLoading = false;
        })
            .catch(err => {
            this.editLoading = false;
            let errorTrans = "webshop_search_favorite_edit_toasts_error";
            if (err === 400) {
                errorTrans = "webshop_search_favorite_edit_toasts_missingName";
            }
            else if (err === 409) {
                errorTrans = "webshop_search_favorite_edit_toasts_nameInUse";
            }
            ToastService.danger(Vue.filter("translate")(errorTrans));
        });
    }
    openAddProductModal() {
        ModalService.create({
            cxt: this,
            component: FavoriteAddProductModal,
            maxWidth: "720px",
            data: this.list
        });
    }
    openConfirmDelete() {
        ModalService.create({
            cxt: this,
            component: FavoriteDeleteConfirmModal,
            maxWidth: "450px",
            data: { list: this.list, lists: this.lists }
        });
    }
    closeList() {
        let input = Object.assign(new SearchInput(), this.input);
        input.favoriteListId = null;
        input.department = null;
        this.$root.$emit(EventTypes.ShopSearchStart, input);
    }
    shareFavoriteList() {
        let list = Object.assign(new FavoriteList(), this.list);
        list.isShared = !list.isShared;
        let message = this.list.isShared ? 'webshop_favorite_confirmModal_messageStopSharing' : 'webshop_favorite_confirmModal_messageSharing';
        message = Vue.filter("translate")(message, list.listName);
        this.confirmModal({
            title: 'webshop_favorite_confirmModal_titleSharing',
            message: message,
            confirmFunction: () => {
                return new Promise((resolve, reject) => {
                    FavoriteService.startStopSharing(list).then(res => {
                        this.list.isShared = res.isShared;
                        this.$root.$emit(EventTypes.FavoriteListUpdate, res);
                        let translateMsg = "webshop_search_favorite_edit_toasts_stoppedSharingList";
                        if (this.list.isShared) {
                            translateMsg = "webshop_search_favorite_edit_toasts_sharedList";
                        }
                        ToastService.success(Vue.filter("translate")(translateMsg));
                        resolve(true);
                    }).catch(err => {
                        ToastService.danger(Vue.filter("translate")("webshop_search_favorite_edit_toasts_error"));
                        reject();
                    });
                });
            }
        });
    }
    copyFavoriteList() {
        let list = Object.assign(new FavoriteList(), this.list);
        this.confirmModal({
            title: 'webshop_favorite_confirmModal_titleCopy',
            message: Vue.filter("translate")('webshop_favorite_confirmModal_messageCopy', list.listName),
            confirmFunction: () => {
                return new Promise((resolve, reject) => {
                    FavoriteService.copyList(this.list).then(res => {
                        this.$root.$emit(EventTypes.FavoriteListInsert, res);
                        ToastService.success(Vue.filter("translate")("webshop_search_favorite_edit_toasts_copiedSharedList"));
                        resolve(true);
                    }).catch(err => {
                        ToastService.danger(Vue.filter("translate")("webshop_search_favorite_edit_toasts_error"));
                        reject();
                    });
                });
            }
        });
    }
    createInventoryList() {
        let list = Object.assign(new FavoriteList(), this.list);
        this.confirmModal({
            title: 'webshop_favorite_confirmModal_titleCopy',
            message: Vue.filter("translate")('webshop_favorite_confirmModal_messageCopyInventory', list.listName),
            confirmFunction: () => {
                return new Promise((resolve, reject) => {
                    FavoriteService.createInventoryList(this.list).then(res => {
                        ToastService.success(Vue.filter("translate")("webshop_search_favorite_edit_toasts_inventoryListCreated"));
                        resolve(true);
                    }).catch(err => {
                        ToastService.danger(Vue.filter("translate")("webshop_search_favorite_edit_toasts_error"));
                        reject();
                    });
                });
            }
        });
    }
    confirmModal(data) {
        ModalService.create({
            cxt: this,
            component: ConfirmModal,
            maxWidth: "450px",
            data: data
        });
    }
    sortList() {
        ModalService.create({
            cxt: this,
            component: ShopFavoriteListSortModal,
            maxWidth: "720px",
            data: this.list
        });
    }
};
__decorate([
    Watch("lists"),
    Watch("$route.query.f")
], ShopFavoriteListHeader.prototype, "onListChange", null);
ShopFavoriteListHeader = __decorate([
    Component({
        components: {
            LoadingButton
        },
        props: {
            loading: Boolean,
            lists: Array,
            input: Object
        }
    })
], ShopFavoriteListHeader);
export default ShopFavoriteListHeader;
