var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-overlay active" }, [
    _c("div", { staticClass: "modal grey inventory-modal" }, [
      _c("div", { staticClass: "modal-header" }, [
        _c("h1", { staticClass: "header-medium" }, [
          _vm._v(
            _vm._s(
              _vm._f("translate")("webshop_inventory_addmodals_addProducts")
            )
          ),
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "modal-close",
            on: {
              click: function ($event) {
                return _vm.close()
              },
            },
          },
          [_c("i", { staticClass: "mdi mdi-close-circle-outline" })]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-content" }, [
        _c(
          "div",
          {
            staticClass: "slides",
            class: "slide-active-step-" + _vm.settings.activeSlide,
          },
          [
            _c(
              "div",
              { staticClass: "slide slide-1", class: _vm.getSliceClass(1) },
              [
                _vm.settings.slide === _vm.InventorySlideTypes.dashboard
                  ? _c("inventory-product-search-partial", {
                      attrs: {
                        loading: _vm.loading,
                        list: _vm.list,
                        settings: _vm.settings,
                        "set-loading": _vm.setLoading,
                        "go-back": _vm.resetSettings,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.settings.hasSearchResult
                  ? _c("div", [
                      _c(
                        "h4",
                        { staticClass: "header-small header-other-options" },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_inventory_addmodals_otherOptionsHeader"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "type-icons" }, [
                        _c("div", { staticClass: "type-icon-container" }, [
                          _c("div", { staticClass: "type-icon" }, [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.openSlide(
                                      _vm.InventorySlideTypes.own
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "mdi mdi-pencil" }),
                                _vm._v(" "),
                                _c("small", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("translate")(
                                        "webshop_inventory_addmodals_btns_ownProduct"
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_inventory_addmodals_btns_ownProductDescription"
                                )
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "type-icon-container" }, [
                          _c("div", { staticClass: "type-icon" }, [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.openSlide(
                                      _vm.InventorySlideTypes.favorite
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "mdi mdi-format-list-bulleted",
                                }),
                                _vm._v(" "),
                                _c("small", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("translate")(
                                        "webshop_inventory_addmodals_btns_favoritesList"
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_inventory_addmodals_btns_favoritesListDescription"
                                )
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "type-icon-container" }, [
                          _c("div", { staticClass: "type-icon" }, [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.openLastMonths(6)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "mdi mdi-numeric-6-circle",
                                }),
                                _vm._v(" "),
                                _c("small", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("translate")(
                                        "webshop_inventory_addmodals_btns_Months"
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_inventory_addmodals_btns_MonthsDescription"
                                )
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "type-icon-container" }, [
                          _c("div", { staticClass: "type-icon" }, [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.openSlide(
                                      _vm.InventorySlideTypes.order
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "mdi mdi-calendar" }),
                                _vm._v(" "),
                                _c("small", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("translate")(
                                        "webshop_inventory_addmodals_btns_date"
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_inventory_addmodals_btns_dateDescription"
                                )
                              )
                            ),
                          ]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "slide slide-2", class: _vm.getSliceClass(2) },
              [
                _vm.settings.slide === _vm.InventorySlideTypes.order
                  ? _c("inventory-product-order-partial", {
                      attrs: {
                        loading: _vm.loading,
                        list: _vm.list,
                        "set-loading": _vm.setLoading,
                        "go-back": _vm.resetSettings,
                        "prefixed-period": _vm.settings.prefixedPeriod,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.settings.slide === _vm.InventorySlideTypes.favorite
                  ? _c("inventory-product-favorite-partial", {
                      attrs: {
                        loading: _vm.loading,
                        "set-loading": _vm.setLoading,
                        "go-back": _vm.resetSettings,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.settings.slide === _vm.InventorySlideTypes.own
                  ? _c("inventory-product-own-partial", {
                      attrs: {
                        loading: _vm.loading,
                        "set-loading": _vm.setLoading,
                        "go-back": _vm.resetSettings,
                        list: _vm.list,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "modal-footer-buttons" }, [
          _vm.selectedProducts && _vm.selectedProducts.length > 0
            ? _c(
                "form",
                {
                  staticClass: "add-button-container",
                  attrs: { id: "addForm" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.addProducts()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(
                        _vm._f("translate")(
                          "webshop_inventory_addmodals_addBtn_add",
                          _vm.selectedProducts.length +
                            " " +
                            (_vm.selectedProducts.length > 1
                              ? _vm.$options.filters.translate(
                                  "webshop_inventory_addmodals_addBtn_products"
                                )
                              : _vm.$options.filters.translate(
                                  "webshop_inventory_addmodals_addBtn_product"
                                ))
                        )
                      ) +
                      "\n                    "
                  ),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.groupId,
                          expression: "groupId",
                        },
                      ],
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.groupId = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    [
                      _c("option", { domProps: { value: null } }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("translate")("webshop_general_groupOutside")
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.list.groups, function (group) {
                        return _c(
                          "option",
                          { key: group.id, domProps: { value: group.id } },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(group.name) +
                                "\n                        "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "ui-button ui-button-small ui-button-accept",
                      attrs: { type: "submit" },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm._f("translate")(
                              "webshop_inventory_addmodals_addBtn_addBtn"
                            )
                          ) +
                          "\n                    "
                      ),
                    ]
                  ),
                ]
              )
            : _vm.selectedFavoriteLists && _vm.selectedFavoriteLists.length > 0
            ? _c("div", { staticClass: "add-button-container" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_inventory_addmodals_addBtn_add",
                        _vm.selectedFavoriteLists.length === 1
                          ? _vm.selectedFavoriteLists[0].listName
                          : _vm.selectedFavoriteLists.length +
                              " " +
                              _vm.$options.filters.translate(
                                "webshop_inventory_addmodals_addBtn_favoriteLists"
                              )
                      )
                    ) +
                    "\n                    "
                ),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.groupId,
                        expression: "groupId",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.groupId = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { domProps: { value: null } }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("translate")("webshop_general_groupOutside")
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.list.groups, function (group) {
                      return _c(
                        "option",
                        { key: group.id, domProps: { value: group.id } },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(group.name) +
                              "\n                        "
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "ui-button ui-button-small favorite ui-button-accept",
                    on: {
                      click: function ($event) {
                        return _vm.addFavoritListProducts()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm._f("translate")(
                            "webshop_inventory_addmodals_addBtn_addBtn"
                          )
                        ) +
                        "\n                    "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _vm.loading
          ? _c("div", { staticClass: "inventory-loader" }, [_vm._m(0)])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "simple-loader-ui" }, [
      _c("div", { staticClass: "simple-loader dark" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }