var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.link && _vm.link != undefined
    ? _c("div", { staticClass: "shop-food-info-container" }, [
        _c("iframe", {
          class: { "shop-food-info-iframe": _vm.showProduct },
          attrs: { src: _vm.link },
        }),
        _vm._v(" "),
        _vm.showProduct
          ? _c(
              "div",
              { staticClass: "div-table shop-food-info-product-container" },
              [
                _c(
                  "div",
                  { staticClass: "table-body" },
                  [
                    _c("shop-product-list-item", {
                      staticClass: "shop-food-info-product-component",
                      attrs: {
                        loading: _vm.loading,
                        product: _vm.product,
                        position: 1,
                        "ui-source": _vm.OrderlineUISourceTypes.ShopProductInfo,
                        disableMultipleProductsButton: true,
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }