var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sticky-amount-footer" }, [
    _c("table", { staticClass: "amount-table" }, [
      _c("tr", [
        _c("td", [
          _vm._v(
            "\r\n                " +
              _vm._s(
                _vm._f("translate")(
                  "webshop_search_search_multiple_amounts_text",
                  _vm.itemsWithAmount.length
                )
              ) +
              "\r\n            "
          ),
        ]),
        _vm._v(" "),
        _c("td", { staticClass: "cta-td" }, [
          _c(
            "a",
            {
              staticClass: "shop-product-addToBasket ui-button",
              attrs: { disable: _vm.loading },
              on: {
                click: function ($event) {
                  return _vm.addToBasket()
                },
              },
            },
            [_vm._m(0)]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "shop-favorite-list-icon" }, [
      _c("span", { staticClass: "mdi mdi-cart" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }