var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
let InputSelect = class InputSelect extends Vue {
    value;
    list;
    searchBy;
    displayProperties;
    listKeys;
    placeholder;
    disabled;
    search = "";
    show = false;
    lineIndex = -1;
    mounted() {
        document.body.addEventListener("click", this.outsideClick);
        document.body.addEventListener("keydown", this.handleLineIndexOnArrowKey);
    }
    beforeDestroy() {
        document.body.removeEventListener('click', this.outsideClick);
        document.body.removeEventListener('keydown', this.handleLineIndexOnArrowKey);
    }
    resetLineIndex() {
        this.lineIndex = -1;
    }
    outsideClick(evt) {
        if (!(evt.target === this.$el || this.$el.contains(evt.target))) {
            this.search = "";
            this.show = false;
            this.resetLineIndex();
        }
    }
    showList() {
        this.show = true;
        if (this.value) {
            const selectedLineRef = this.$refs[this.lineKey(this.value)];
            this.lineIndex = this.displayList.findIndex(line => this.lineKey(line) == this.lineKey(this.value));
            this.$nextTick(() => selectedLineRef[0].scrollIntoView());
        }
        this.$nextTick(() => this.$refs.inputSelectSearch.focus());
    }
    lineKey(line) {
        return this.listKeys.reduce((accumulator, key) => accumulator + line[key], "k");
    }
    selectLine(line) {
        this.$emit("input", line);
        this.$emit("select", line);
        this.search = "";
        this.show = false;
    }
    displayLineProperties(line) {
        if (line) {
            return this.displayProperties.map(property => line[property]).join(' - ');
        }
    }
    isSelectedLine(line) {
        return this.value && this.lineKey(line) === this.lineKey(this.value);
    }
    selectOnKeyEnter(evt) {
        if (this.lineIndex > -1) {
            this.$refs.inputSelectSearch.blur();
            this.selectLine(this.displayList[this.lineIndex]);
        }
        evt.preventDefault();
    }
    handleLineIndexOnArrowKey(evt) {
        if (this.show) {
            if (evt.key === 'ArrowDown' && this.lineIndex < this.displayList.length - 1)
                this.lineIndex++;
            if (evt.key === 'ArrowUp' && this.lineIndex > -1)
                this.lineIndex--;
            if (evt.key === 'ArrowUp' || evt.key === 'ArrowDown')
                evt.preventDefault();
            if (this.displayList[this.lineIndex]) {
                const lineRefKey = this.lineKey(this.displayList[this.lineIndex]);
                this.$refs[lineRefKey][0].scrollIntoView();
            }
        }
    }
    setLineIndex(index) {
        this.lineIndex = index;
    }
    get displaySelectedLine() {
        if (this.value) {
            return this.displayLineProperties(this.value);
        }
    }
    get displayList() {
        this.resetLineIndex();
        if (!this.search)
            return this.list;
        const lowerCaseSearch = this.search.toLowerCase();
        return this.list.filter((line) => {
            return this.searchBy.some(property => String(line[property]).toLowerCase().includes(lowerCaseSearch));
        });
    }
};
InputSelect = __decorate([
    Component({
        props: {
            value: Object,
            list: Array,
            searchBy: Array,
            displayProperties: Array,
            listKeys: Array,
            placeholder: String,
            disabled: Boolean,
        },
    })
], InputSelect);
export default InputSelect;
