import { Service } from './Service';
import { CheckOutApi } from 'api/CheckOutApi';
export class CheckOutService extends Service {
    static getCheckoutCampaigns(code) {
        return new Promise((resolve, reject) => {
            CheckOutApi.getCheckoutCampaigns(code).then((res) => {
                var result = res.data;
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static setRating(id, rating) {
        return new Promise((resolve, reject) => {
            CheckOutApi.setRating(id, rating).then((res) => {
                resolve();
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static setComment(id, comment) {
        return new Promise((resolve, reject) => {
            CheckOutApi.setComment(id, comment).then((res) => {
                resolve();
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static declineRating(id) {
        return new Promise((resolve, reject) => {
            CheckOutApi.declineRating(id).then((res) => {
                resolve();
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static declineCheckout(id) {
        return new Promise((resolve, reject) => {
            CheckOutApi.declineCheckout(id).then((res) => {
                resolve();
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
}
