var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ui-modal-content" }, [
      _c("h3", { staticClass: "ui-modal-header" }, [
        _vm._v(
          _vm._s(
            _vm._f("translate")("webshop_calculations_modals_createOrder_title")
          )
        ),
      ]),
      _vm._v(" "),
      _c("div", [
        _c(
          "form",
          {
            staticClass: "ui-form",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submit()
              },
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                $event.preventDefault()
                if ($event.target !== $event.currentTarget) {
                  return null
                }
              },
            },
          },
          [
            _c("div", { attrs: { grid: "wrap" } }, [
              _c("div", { attrs: { column: "s-12 m-3" } }, [
                _c("div", { staticClass: "ui-form-fieldwrapper" }, [
                  _c(
                    "label",
                    { attrs: { for: "multiplier" } },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("translate")(
                              "webshop_calculations_modals_createOrder_labelMultiplier"
                            )
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("input-number", {
                        attrs: {
                          inputClass: "item-amount-input",
                          inputTabindex: "1",
                          autocomplete: "off",
                          inputStep: 0.01,
                          "select-onfocus": "true",
                        },
                        on: { blur: _vm.loadConvertedOrderlines },
                        model: {
                          value: _vm.multiplier,
                          callback: function ($$v) {
                            _vm.multiplier = $$v
                          },
                          expression: "multiplier",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { attrs: { column: "s-12 m-9" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "ui-form-fieldwrapper section-productSearchResults",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "input-checkbox",
                        attrs: { for: "saveInActive" },
                      },
                      [
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm._f("translate")(
                                "webshop_calculations_modals_createOrder_labelSaveInActive"
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "action-group shop-product-viewType" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "action",
                                class: { active: !_vm.saveInActive },
                                on: {
                                  click: function ($event) {
                                    _vm.saveInActive = false
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "text" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("translate")(
                                        "webshop_calculations_modals_createOrder_switchBtnNewBasket"
                                      )
                                    )
                                  ),
                                ]),
                                _c("i", { staticClass: "mdi mdi-plus" }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "action",
                                class: { active: _vm.saveInActive },
                                on: {
                                  click: function ($event) {
                                    _vm.saveInActive = true
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "text" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("translate")(
                                        "webshop_calculations_modals_createOrder_switchBtnActiveBasket"
                                      )
                                    )
                                  ),
                                ]),
                                _c("i", {
                                  staticClass:
                                    "mdi mdi-checkbox-marked-circle-outline",
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "div-table calculation-line-table" }, [
              _c("section", [
                _c("div", { staticClass: "table-header" }, [
                  _c("div", { staticClass: "table-row" }, [
                    _c("div", { staticClass: "table-column" }, [
                      _vm._v(
                        _vm._s(_vm._f("translate")("webshop_general_productNo"))
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "table-column calculation-column-name" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("translate")(
                              "webshop_general_productDescription"
                            )
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "table-column column-justify-end" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("translate")("webshop_general_unitPrice")
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "table-column column-justify-end" },
                      [
                        _vm._v(
                          _vm._s(_vm._f("translate")("webshop_general_amount"))
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "table-column" }, [
                      _vm._v(
                        _vm._s(_vm._f("translate")("webshop_general_unit"))
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "table-body" },
                  [
                    !_vm.loadingConvertedLines
                      ? _vm._l(_vm.convertedOrderlines, function (line) {
                          return _c("converted-orderline", {
                            key: line.productId,
                            attrs: {
                              orderline: line,
                              loading: _vm.loadingConvertedLines || _vm.loading,
                            },
                          })
                        })
                      : _c("loader", { attrs: { size: "large" } }),
                  ],
                  2
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "ui-form-submitwrapper ui-form-submitwrapper-full",
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "ui-button ui-button-secondary ui-button-left",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.closeModal()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm._f("translate")(
                          "webshop_calculations_modals_createOrder_cancel"
                        )
                      )
                    ),
                  ]
                ),
                _vm._v(" "),
                !_vm.loadingConvertedLines &&
                _vm.convertedOrderlines &&
                _vm.convertedOrderlines.length > 0
                  ? _c("loading-button", {
                      attrs: {
                        "icon-class": "mdi-content-save",
                        cancel: false,
                        text: _vm.$options.filters.translate(
                          "webshop_calculations_modals_createOrder_addToBasket"
                        ),
                        "loading-text": _vm.$options.filters.translate(
                          "webshop_calculations_modals_createOrder_addToBasket"
                        ),
                        loading: _vm.loading,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }