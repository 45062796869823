export var WarningType;
(function (WarningType) {
    WarningType[WarningType["highPrice"] = 0] = "highPrice";
    WarningType[WarningType["lowAmount"] = 1] = "lowAmount";
    WarningType[WarningType["unitMisMatch"] = 2] = "unitMisMatch";
    WarningType[WarningType["outOfStock"] = 3] = "outOfStock";
    WarningType[WarningType["unitRemoved"] = 4] = "unitRemoved";
    WarningType[WarningType["highDiscountPrice"] = 5] = "highDiscountPrice";
    WarningType[WarningType["expired"] = 6] = "expired";
})(WarningType || (WarningType = {}));
