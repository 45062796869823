import { Cache } from '../caching/Cache';
import Vue from 'vue';
import { ToastType } from 'models/utility/ToastType';
import { Api } from 'api/Api';
export class Service {
    static cache = new Cache();
    static customErrorHandling(err, reject) {
        if (!err.response || err.response.status == Api.ownResCode) {
            $.dcui.toast(Vue.filter('translate')('webshop_general_connection_lost'), ToastType.Danger, null, { duration: 60000 });
            return null;
        }
        if (err.response.status === 403) {
            $.dcui.toast(Vue.filter('translate')('webshop_general_noAccess_toast'), ToastType.Danger, null, { duration: 60000 });
            return null;
        }
        reject(err.response.status);
    }
    static focusNextOnEnter(vueComponent, querySelectorAll, inputRef) {
        vueComponent.$nextTick(() => {
            const inputs = document.querySelectorAll(querySelectorAll);
            const usedInput = vueComponent.$refs[inputRef];
            let setFocus = false;
            for (let i = 0; i < inputs.length; i++) {
                const input = inputs[i];
                if (setFocus && input && !input.disabled) {
                    input.focus();
                    break;
                }
                if (input === usedInput) {
                    setFocus = true;
                }
            }
        });
    }
}
