var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "searchpage-search" }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
          },
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "shop-productSearch-field",
            staticStyle: { overflow: "visible" },
          },
          [
            _c(
              "label",
              { staticClass: "shop-productSearch-searchTextWrapper" },
              [
                _c(
                  "transition",
                  {
                    attrs: {
                      appear: "",
                      name: "transition-cancel-search",
                      tag: "div",
                    },
                  },
                  [
                    _vm.isSearching
                      ? _c(
                          "span",
                          {
                            staticClass: "search-btn cancel-search",
                            attrs: {
                              title: _vm.$options.filters.translate(
                                "webshop_search_search_options_buttonClear"
                              ),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.clearSearch()
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "mdi mdi-close-circle-outline",
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "search-btn",
                    attrs: {
                      title: _vm.$options.filters.translate(
                        "webshop_search_search_options_buttonSearch"
                      ),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.search()
                      },
                    },
                  },
                  [_c("i", { staticClass: "mdi mdi-magnify" })]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.model,
                      expression: "model",
                    },
                  ],
                  ref: "searchBarInput",
                  staticClass: "shop-productSearch-searchText",
                  attrs: {
                    type: "text",
                    autocomplete: "off",
                    placeholder: _vm.$options.filters.translate(
                      "webshop_search_search_options_searchTextPlaceholder"
                    ),
                  },
                  domProps: { value: _vm.model },
                  on: {
                    keyup: function ($event) {
                      return _vm.handleKeyUp($event)
                    },
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.model = $event.target.value
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.suggestions.length > 0 && _vm.showSuggs && !_vm.toppSearch
              ? _c("div", { staticClass: "shop-productSearch-suggestions" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "shop-productSearch-suggestions-group shop-productSearch-suggestions-list",
                    },
                    _vm._l(_vm.suggestions, function (sug, index) {
                      return _c("div", { key: index, staticClass: "content" }, [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "click-outside",
                                rawName: "v-click-outside",
                                value: {
                                  exclude: ["searchBarInput"],
                                  handler: "closeSuggestions",
                                },
                                expression:
                                  "{ exclude: ['searchBarInput'], handler: 'closeSuggestions' }",
                              },
                            ],
                            staticClass: "suggestion",
                            class: {
                              first: index == 0,
                              "suggestion-active":
                                _vm.selectedSuggestionIndex == index,
                            },
                            style: {
                              "border-bottom":
                                index !== _vm.suggestions.length - 1
                                  ? "1px solid #d3d3d3"
                                  : "",
                            },
                            attrs: {
                              href: "#",
                              "data-searchterm": sug.searchTerm,
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleClickOnSuggestion(
                                  sug.searchTerm
                                )
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticStyle: {
                                width: "auto",
                                height: "40px",
                                "margin-right": "15px",
                              },
                              attrs: { src: sug.imageLink },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "suggestion_item" }, [
                              _vm._v(_vm._s(sug.searchTerm)),
                            ]),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ])
              : _vm._e(),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }