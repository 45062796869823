var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "searchpage-container",
      attrs: { name: "search", container: "" },
    },
    [
      _c("div", { attrs: { grid: "wrap" } }, [
        _c(
          "div",
          {
            staticClass: "filters",
            attrs: { column: "xs-12 xl-3", "sticky-container": "" },
          },
          [
            _c("shop-filters", {
              directives: [
                {
                  name: "sticky",
                  rawName: "v-sticky",
                  value: {
                    zIndex: 99,
                    stickyTop: _vm.searchService.isOpenShop ? 101 : 151,
                    disabled: false,
                  },
                  expression:
                    "{ zIndex: 99, stickyTop: searchService.isOpenShop? 101: 151, disabled: false}",
                },
              ],
              class: { "no-favorite-section": _vm.searchService.isOpenShop },
              attrs: {
                filters: _vm.filters,
                searchMenu: _vm.searchMenu,
                input: _vm.input,
                totalProducts: _vm.totalProducts,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { attrs: { column: "xs-12 xl-9", "sticky-container": "" } },
          [
            _c("shop-breadcrumb", {
              attrs: {
                searchMenu: _vm.searchMenu,
                filters: _vm.filters,
                input: _vm.input,
              },
            }),
            _vm._v(" "),
            _c("shop-favorite-list-header", {
              attrs: {
                loading: _vm.loading,
                lists: _vm.searchMenu.favoriteLists,
                input: _vm.input,
              },
              on: { addedProduct: _vm.addedFavoriteProduct },
            }),
            _vm._v(" "),
            _c("shop-favorite-list-header", {
              attrs: {
                loading: _vm.loading,
                lists: _vm.searchMenu.sharedFavoriteLists,
                input: _vm.input,
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "sticky",
                    rawName: "v-sticky",
                    value: {
                      zIndex: 99,
                      stickyTop: _vm.searchService.isOpenShop ? 101 : 151,
                      disabled: false,
                    },
                    expression:
                      "{ zIndex: 99, stickyTop: searchService.isOpenShop? 101: 151, disabled: false}",
                  },
                ],
              },
              [
                _c(
                  "div",
                  { staticClass: "sticky", attrs: { id: "shopsearch-sticky" } },
                  [
                    _c("shop-search-input", {
                      attrs: {
                        loading: _vm.loading,
                        filters: _vm.filters,
                        input: _vm.input,
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c("shop-results", {
              attrs: {
                loading: _vm.loading,
                products: _vm.products,
                input: _vm.input,
                popularProducts: _vm.popularProducts,
                totalProducts: _vm.totalProducts,
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }