var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "slide" }, [
    _c(
      "div",
      { staticClass: "result-list-container", attrs: { container: "" } },
      [
        _c("div", { attrs: { grid: "wrap" } }, [
          _c("div", { attrs: { column: "xs-6 m-4" } }, [
            _c("input", {
              staticClass: "small-search-input",
              attrs: {
                type: "text",
                placeholder: _vm.$options.filters.translate(
                  "webshop_general_search"
                ),
              },
              on: { input: _vm.searchFilter },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "result-list" },
          [
            _c("li", { staticClass: "result-line" }, [
              _c("div", { attrs: { grid: "justify-start" } }, [
                _c("div", { attrs: { column: "xs-5 s-6 m-6 l-6" } }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.filteredLines.length > 0,
                          expression: "filteredLines.length > 0",
                        },
                      ],
                      staticClass: "clickable",
                      attrs: { grid: "m-no-gutters" },
                      on: {
                        click: function ($event) {
                          return _vm.toggleAllLines()
                        },
                      },
                    },
                    [
                      _c("div", { attrs: { column: "top xs-2 s-1 m-2" } }, [
                        _c("i", {
                          staticClass: "checkbox mdi",
                          class: {
                            "mdi-checkbox-marked": _vm.isAllSelected,
                            "mdi-checkbox-blank-outline": !_vm.isAllSelected,
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "select-all-text",
                          attrs: { column: "top xs-10" },
                        },
                        [
                          _vm._v(
                            "\r\n                                " +
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_calculations_modals_addProducts_search_result_selectAll"
                                )
                              ) +
                              "\r\n                            "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm._l(_vm.filteredLines, function (line) {
              return _c(
                "li",
                {
                  key: line.calculation.calculationId,
                  staticClass: "result-line",
                },
                [
                  _c("div", { attrs: { grid: "wrap" } }, [
                    _c("div", { attrs: { column: "xs-6" } }, [
                      _c(
                        "div",
                        {
                          attrs: { grid: "m-no-gutters clickable" },
                          on: {
                            click: function ($event) {
                              return _vm.toggleSelection(line)
                            },
                          },
                        },
                        [
                          _c("div", { attrs: { column: "xs-2 s-1 m-2" } }, [
                            _c("i", {
                              staticClass: "checkbox mdi",
                              class: [
                                line.selected
                                  ? "mdi-checkbox-marked"
                                  : "mdi-checkbox-blank-outline",
                              ],
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "text-col",
                              attrs: { column: "xs-10" },
                            },
                            [
                              _c("div", { staticClass: "text-wrapper" }, [
                                _vm._v(
                                  "\r\n                                    " +
                                    _vm._s(line.calculation.name) +
                                    "\r\n                                "
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { attrs: { column: "xs-6" } }, [
                      _c("div", { attrs: { grid: "wrap" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "text-right",
                            attrs: { column: "xs-12" },
                          },
                          [
                            _c("div", { staticClass: "text-wrapper" }, [
                              _vm._v(
                                "\r\n                                    " +
                                  _vm._s(
                                    _vm.CalculationType[
                                      line.calculation.calculationType
                                    ]
                                  ) +
                                  "\r\n                                "
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]
              )
            }),
          ],
          2
        ),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "modal-footer-buttons" }, [
      _vm.selectedCount
        ? _c(
            "form",
            {
              staticClass: "add-button-container",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.addCalculations()
                },
              },
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("translate")(
                      _vm.selectedCount > 1
                        ? "webshop_calculations_modals_addCalculations_result_preTextMultiProducts"
                        : "webshop_calculations_modals_addCalculations_result_preTextSingleProduct",
                      _vm.selectedCount
                    )
                  ) + " "
                ),
                _c("b", [_vm._v(_vm._s(_vm.list.name))]),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "ui-button ui-button-small ui-button-accept",
                  attrs: { type: "submit" },
                },
                [
                  _vm._v(
                    "\r\n                " +
                      _vm._s(
                        _vm._f("translate")(
                          "webshop_calculations_modals_addProducts_search_result_addBtn_btnText"
                        )
                      ) +
                      "\r\n            "
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }