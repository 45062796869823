import { GAProduct } from "./GAProduct";
export class GAImpression extends GAProduct {
    list;
    position;
    constructor(id, name, list, price, brand, category, variant, position) {
        super(id, name, price, brand, category, variant);
        this.list = list;
        this.position = position;
    }
    static ConvertFromProducts(products, listName = '') {
        if (products && products.length) {
            return products.map((p, indx) => {
                //let salesPrice = p.units.find(x => x.name == p.salesUnit).price;
                let gaImpression = new GAImpression(p.productId, p.description, listName, null, p.brand, '', '', indx);
                return gaImpression;
            });
        }
    }
}
