export class FavoriteList {
    id;
    listName;
    productExists;
    sorting;
    count;
    department;
    isShared;
    customerNo;
    productStatus;
    saleBlocked;
    blocked;
    constructor() {
        this.id = null;
        this.listName = null;
        this.productExists = null;
        this.sorting = null;
        this.count = 0;
        this.department = null;
        this.isShared = null;
        this.customerNo = null;
        this.productStatus = null;
        this.saleBlocked = null;
        this.blocked = null;
    }
    static fromApi(list) {
        return Object.assign(new FavoriteList(), list);
    }
}
