var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ItemStatus } from 'models/product/ItemStatus';
import Vue from 'vue';
import Component from 'vue-class-component';
let BasketOrderlineObsoleteMessage = class BasketOrderlineObsoleteMessage extends Vue {
    orderline;
    itemStatus = ItemStatus;
    outOfRangeDeliveryDate() {
        let canBeDeliveredBy = this.orderline.markings.canBeDeliveredBy;
        return canBeDeliveredBy && new Date(canBeDeliveredBy).getFullYear() == 9999;
    }
    get message() {
        let _orderline = this.orderline;
        if (_orderline.blocked || (_orderline.saleBlocked && _orderline.saleBlockedDate == null) || this.outOfRangeDeliveryDate()
            || _orderline.itemStatus == ItemStatus.Deleted || _orderline.itemStatus == ItemStatus.Inactive || _orderline.units == null) {
            return Vue.filter("translate")("webshop_search_search_item_blocked_no_end_date");
        }
        else if (_orderline.saleBlocked && _orderline.saleBlockedDate !== null) {
            return Vue.filter("translate")("webshop_search_search_item_blocked_with_end_date") + " " + moment(_orderline.saleBlockedDate).format('YYYY-MM-DD');
        }
        // else if (_orderline.unrecognized) {
        //     return Vue.filter("translate")("webshop_search_search_item_obsolete_text_withoutAltProduct");
        // }
        return "";
    }
};
BasketOrderlineObsoleteMessage = __decorate([
    Component({
        props: {
            orderline: Object
        }
    })
], BasketOrderlineObsoleteMessage);
export default BasketOrderlineObsoleteMessage;
