import { PermissionLevel } from 'models/user/PermissionLevel';
export class Settings {
    static profileInfo = null;
    static paths = [];
    static menuBaseLink = "https://www.svenskcater.se";
    static permissionLevel = PermissionLevel.None;
    static isLoggedIn = () => {
        if (Settings.profileInfo)
            return true;
        else
            return false;
    };
    static isIE;
}
