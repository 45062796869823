var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-overlay active" }, [
    _c(
      "div",
      { staticClass: "modal grey inventory-modal" },
      [
        _c("div", { staticClass: "modal-header" }, [
          _c("h1", { staticClass: "header-medium" }, [
            _vm._v(
              _vm._s(
                _vm._f("translate")("webshop_inventory_productSort_modal_title")
              )
            ),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "modal-close",
              on: {
                click: function ($event) {
                  return _vm.close()
                },
              },
            },
            [_c("i", { staticClass: "mdi mdi-close-circle-outline" })]
          ),
        ]),
        _vm._v(" "),
        _vm.loading
          ? [_c("loader", { attrs: { size: "large" } })]
          : [
              _c("div", { staticClass: "modal-content" }, [
                _c("div", { staticClass: "row inventory-modal-sort-header" }, [
                  _c("div", { staticClass: "input-wrapper" }, [
                    _c(
                      "a",
                      {
                        staticClass: "ui-button ui-button-small",
                        on: {
                          click: function ($event) {
                            return _vm.sortByName()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "mdi mdi-sort" }),
                        _vm._v(
                          _vm._s(
                            _vm._f("translate")(
                              "webshop_inventory_productSort_modal_buttonSortByName"
                            )
                          ) + "\n                        "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "div-table inventory-product-sort-modal-table",
                  },
                  [
                    _c("div", { staticClass: "table-header" }, [
                      _c("div", { staticClass: "table-row" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "table-column inventory-product-sort-column-small",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_inventory_table_headers_productNo"
                                )
                              )
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "table-column" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("translate")(
                                "webshop_inventory_table_headers_product"
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "table-column inventory-product-sort-column-small",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_inventory_table_headers_unit"
                                )
                              )
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "table-column inventory-product-sort-column-small align-right",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_inventory_table_headers_price"
                                )
                              )
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "table-column inventory-product-sort-column-small align-right",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_inventory_table_headers_sorting"
                                )
                              )
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "inventory-modal-table-wrapper" },
                  _vm._l(_vm.list.groups, function (group, index) {
                    return _c(
                      "div",
                      {
                        key: "listgroup" + group.id,
                        staticClass:
                          "div-table inventory-product-sort-modal-table",
                        class: {
                          "table-element-move-over":
                            _vm.transferGroupIndex > index &&
                            _vm.lastGroupIndex === index &&
                            _vm.disableLines &&
                            index !== _vm.list.groups.length - 1,
                          "table-element-move-under":
                            _vm.transferGroupIndex < index &&
                            _vm.lastGroupIndex === index &&
                            _vm.disableLines &&
                            index !== _vm.list.groups.length - 1,
                        },
                        on: {
                          dragover: function ($event) {
                            return _vm.allowDropGroup($event, index)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "table-header group-header",
                            class: { "table-element-move": group.id },
                            attrs: { draggable: group.id ? "true" : "false" },
                            on: {
                              dragstart: function ($event) {
                                return _vm.dragGroup(index)
                              },
                              dragend: function ($event) {
                                return _vm.dropGroup(index)
                              },
                              dragover: function ($event) {
                                return _vm.overGroup(index)
                              },
                              dragleave: function ($event) {
                                return _vm.leaveGroup(index)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "table-row" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "table-column inventory-product-sort-column-small",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("translate")(
                                        group.name
                                          ? group.name
                                          : "webshop_general_groupOutside"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "table-body" },
                          _vm._l(group.lines, function (product, indexLine) {
                            return _c(
                              "div",
                              {
                                key: "line" + product.id,
                                staticClass: "table-outer-row",
                                on: { dragover: _vm.allowDrop },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "table-row table-element-move",
                                    class: {
                                      "shop-product-status-deleted":
                                        product.productStatus ===
                                        _vm.itemStatus.Deleted,
                                      "table-element-move-over":
                                        _vm.transferLineIndex > indexLine &&
                                        indexLine === _vm.lastLineIndex &&
                                        _vm.lastGroupIndex === index &&
                                        _vm.transferGroupIndex === index &&
                                        !_vm.disableLines,
                                      "table-element-move-under":
                                        _vm.transferLineIndex < indexLine &&
                                        indexLine === _vm.lastLineIndex &&
                                        _vm.lastGroupIndex === index &&
                                        _vm.transferGroupIndex === index &&
                                        !_vm.disableLines,
                                    },
                                    attrs: { draggable: "true" },
                                    on: {
                                      dragstart: function ($event) {
                                        return _vm.drag(indexLine, index)
                                      },
                                      dragend: function ($event) {
                                        return _vm.drop(indexLine, index)
                                      },
                                      dragover: function ($event) {
                                        return _vm.over(indexLine, index)
                                      },
                                      dragleave: function ($event) {
                                        return _vm.leave(indexLine, index)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "table-column inventory-product-sort-column-small",
                                      },
                                      [_vm._v(_vm._s(product.productId))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "table-column inventory-product-sort-column",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(product.brand)),
                                        ]),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(product.supplierProductId)
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(_vm._s(product.description)),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "table-column inventory-product-sort-column-small",
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(product.unit)),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "table-column small-third inventory-product-sort-column-small align-right",
                                      },
                                      [
                                        _c(
                                          "span",
                                          [
                                            _vm.showPrice
                                              ? [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("currency")(
                                                        product.pricePrUnit
                                                      )
                                                    )
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        product.productStatus ===
                                        _vm.itemStatus.Outgoing
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "inventory-item-outgoing-text",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("translate")(
                                                      "webshop_inventory_table_product_outgoing_text"
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "table-column inventory-product-sort-column-small align-right",
                                      },
                                      [_vm._v(_vm._s(product.sorting))]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "inventory-product-sort-modal-buttons" },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "ui-button ui-button-secondary ui-button-left",
                        on: {
                          click: function ($event) {
                            return _vm.close()
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("translate")(
                              "webshop_inventory_productSort_modal_cancel"
                            )
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "ui-button ui-button-hasIcon ui-button-accept",
                        on: {
                          click: function ($event) {
                            return _vm.save()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "mdi mdi-content-save" }),
                        _vm._v(
                          _vm._s(
                            _vm._f("translate")(
                              "webshop_inventory_productSort_modal_save"
                            )
                          )
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }