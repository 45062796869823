var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import InventoryProductResultPartial from './InventoryProductResultPartial.vue';
import { ToastService } from 'services/ToastService';
import { InventoryService } from 'services/InventoryService';
let InventoryProductSearchPartial = class InventoryProductSearchPartial extends Vue {
    loading;
    list;
    goBack;
    setLoading;
    settings;
    result = [];
    mounted() {
    }
    search() {
        if (this.settings.searchQuery !== '' && this.settings.searchQuery.trim().length >= 2) {
            this.setLoading(true);
            InventoryService.search(this.settings.searchQuery, this.list.id).then(result => {
                this.settings.hasSearchResult = result && result.length > 0;
                this.result = result;
                if (!this.settings.hasSearchResult)
                    ToastService.danger(Vue.filter('translate')('webshop_inventory_addmodals_searchProduct_toastNoResultText'), Vue.filter('translate')('webshop_inventory_addmodals_searchProduct_toastNoResultTitle'));
                this.setLoading(false);
            })
                .catch(err => {
                this.setLoading(false);
                ToastService.danger(Vue.filter('translate')('webshop_defaults_toast_errorText'));
            });
        }
        else {
            ToastService.danger(Vue.filter('translate')("webshop_inventory_addmodals_searchProduct_toastEmptySearchQuery"));
        }
    }
    reset() {
        this.result = [];
        this.goBack(true);
        //this.$root.$emit(EventTypes.InventoryModalProductSelected, null);
    }
};
InventoryProductSearchPartial = __decorate([
    Component({
        components: {
            InventoryProductResultPartial
        },
        props: {
            goBack: Function,
            setLoading: Function,
            loading: Boolean,
            list: Object,
            settings: Object
        }
    })
], InventoryProductSearchPartial);
export default InventoryProductSearchPartial;
