var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table-outer-row" }, [
    _c("div", { staticClass: "table-row campaign-line-row" }, [
      _c("div", { staticClass: "table-column" }, [
        _c("span", { staticClass: "table-column-name" }, [
          _vm._v(
            _vm._s(
              _vm._f("translate")(
                "webshop_vendor_campaignPlanning_campaignLines_columnItemNo"
              )
            ) + ": "
          ),
        ]),
        _vm._v(_vm._s(_vm.campaignLine.itemNo)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "table-column" }, [
        _c("span", { staticClass: "table-column-name" }, [
          _vm._v(
            _vm._s(
              _vm._f("translate")(
                "webshop_vendor_campaignPlanning_campaignLines_columnVendorItemNo"
              )
            ) + ": "
          ),
        ]),
        _vm._v(_vm._s(_vm.campaignLine.vendorItemNo)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "table-column campaign-column-name" }, [
        _c("span", { staticClass: "table-column-name" }, [
          _vm._v(
            _vm._s(
              _vm._f("translate")(
                "webshop_vendor_campaignPlanning_campaignLines_columnName"
              )
            ) + ": "
          ),
        ]),
        _vm._v(_vm._s(_vm.campaignLine.description)),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "table-column column-status" }, [
        _c("span", { staticClass: "table-column-name" }, [
          _vm._v(
            _vm._s(
              _vm._f("translate")(
                "webshop_vendor_campaignPlanning_campaignLines_columnStatus"
              )
            ) + ": "
          ),
        ]),
        _vm._v(
          _vm._s(
            _vm._f("translate")(
              "webshop_vendor_campaignPlanning_campaignLines_status_" +
                _vm.CampaignPlanningLineStatus[_vm.campaignLine.status]
            )
          ) + "\n            "
        ),
        _vm.campaignLine.waitingFor !=
        _vm.CampaignPlanningLineWaitingForStatus.WaitingForNoOne
          ? _c("span", { staticClass: "column-status-waitingFor" }, [
              _vm._v(
                "(" +
                  _vm._s(
                    _vm._f("translate")(
                      "webshop_vendor_campaignPlanning_campaignLines_status_" +
                        _vm.CampaignPlanningLineWaitingForStatus[
                          _vm.campaignLine.waitingFor
                        ]
                    )
                  ) +
                  ")"
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "i",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.campaignLine.waitingFor ==
                    _vm.CampaignPlanningLineWaitingForStatus.WaitingForVendor &&
                  _vm.isValidPurchaseDeadline,
                expression:
                  "campaignLine.waitingFor == CampaignPlanningLineWaitingForStatus.WaitingForVendor && isValidPurchaseDeadline",
              },
            ],
            staticClass: "campaign-line-waitingFor-icon mdi mdi-bell",
            attrs: {
              title: _vm.$options.filters.translate(
                "webshop_vendor_campaignPlanning_campaignLines_waitingForVendorIconText"
              ),
            },
            on: { click: _vm.changeStatusToWaitingNoOne },
          },
          [_c("span")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "table-column" }, [
        _c("span", { staticClass: "table-column-name" }, [
          _vm._v(
            _vm._s(
              _vm._f("translate")(
                "webshop_vendor_campaignPlanning_campaignLines_columnUnit"
              )
            ) + ": "
          ),
        ]),
        _vm._v(_vm._s(_vm.campaignLine.unit)),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-column column-justify-end" },
        [
          _c("span", { staticClass: "table-column-name" }, [
            _vm._v(
              _vm._s(
                _vm._f("translate")(
                  "webshop_vendor_campaignPlanning_campaignLines_columnOrdinaryPrice"
                )
              ) + ": "
            ),
          ]),
          _vm._v(" "),
          _vm.campaignLine.itemNo != ""
            ? [_vm._v(_vm._s(_vm._f("currency")(_vm.campaignLine.unitCost)))]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-column column-justify-end multiline-column" },
        [
          _c("span", { staticClass: "table-column-name" }, [
            _vm._v(
              _vm._s(
                _vm._f("translate")(
                  "webshop_vendor_campaignPlanning_campaignLines_columnDiscount"
                )
              ) + ": "
            ),
          ]),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              _vm._s(
                _vm._f("currency")(_vm.campaignLine.campaignDiscountPct, {
                  symbol: "%",
                })
              ) + " "
            ),
          ]),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              _vm._s(_vm._f("currency")(_vm.campaignLine.campaignDiscountLcy))
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-column column-justify-end" },
        [
          _c("span", { staticClass: "table-column-name" }, [
            _vm._v(
              _vm._s(
                _vm._f("translate")(
                  "webshop_vendor_campaignPlanning_campaignLines_columnCampaignPrice"
                )
              ) + ": "
            ),
          ]),
          _vm._v(" "),
          _vm.campaignLine.itemNo != ""
            ? [
                _vm._v(
                  _vm._s(_vm._f("currency")(_vm.campaignLine.campaignUnitCost))
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-column column-justify-end multiline-column" },
        [
          _c("span", { staticClass: "table-column-name" }, [
            _vm._v(
              _vm._s(
                _vm._f("translate")(
                  "webshop_vendor_campaignPlanning_campaignLines_columnOutPrice"
                )
              ) + ": "
            ),
          ]),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              _vm._s(_vm._f("currency")(_vm.campaignLine.comparisonPrice)) +
                "/" +
                _vm._s(_vm.campaignLine.comparisonUnit)
            ),
          ]),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              _vm._s(_vm._f("currency")(_vm.campaignLine.campaignUnitPrice))
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-column column-justify-end column-icons" },
        [
          _vm.isValidPurchaseDeadline &&
          _vm.campaignLine.status != _vm.CampaignPlanningLineStatus.Approved &&
          _vm.campaign.active
            ? _c("i", {
                staticClass: "mdi mdi-pencil",
                attrs: {
                  title: _vm.$options.filters.translate(
                    "webshop_vendor_campaignPlanning_campaignLines_buttonEdit",
                    _vm.campaignLine.description
                  ),
                },
                on: {
                  click: function ($event) {
                    return _vm.editLine()
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("i", {
            staticClass: "mdi mdi-email",
            attrs: {
              title: _vm.$options.filters.translate(
                "webshop_vendor_campaignPlanning_campaignLines_buttonMessages",
                _vm.campaignLine.description
              ),
            },
            on: { click: _vm.showComment },
          }),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }