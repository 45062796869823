export class Orderline {
    lineNo;
    productId;
    description;
    amount;
    unit;
    basicUnit;
    price;
    added;
    expiryRef;
    brand;
    unitPrice;
    lineprice;
    procurable;
    weightItem;
    units;
    image;
    warnings;
    checkoutId;
    expiryDate;
    altOrderline;
    isAlternative;
    blocked;
    saleBlocked;
    saleBlockedDate;
    depositProduct;
    discountPrice;
    discountUnitPrice;
    discountAmountExceeded;
    markings;
    stock;
    itemStatus;
    leftAmountWithSurcharge;
    orderlineUISource;
    expiry;
}
