var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.list
    ? _c("div", { staticClass: "shop-favoriteList-header" }, [
        _c("h2", { staticClass: "header-medium" }, [
          _c("span", { staticClass: "label" }, [
            _vm._v(
              _vm._s(_vm._f("translate")("webshop_search_favorite_header"))
            ),
          ]),
          _vm._v(" "),
          !_vm.showEdit
            ? _c("span", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.list.listName)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showEdit
            ? _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.saveList()
                    },
                  },
                },
                [
                  _c("div", { attrs: { grid: "center" } }, [
                    _c("div", { attrs: { column: "s-3" } }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.tempName,
                            expression: "tempName",
                          },
                        ],
                        attrs: { type: "text", required: "" },
                        domProps: { value: _vm.tempName },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.tempName = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { attrs: { column: "s-3" } },
                      [
                        _c("loading-button", {
                          attrs: {
                            "icon-class": "mdi-send",
                            text: _vm.$options.filters.translate(
                              "webshop_search_favorite_edit_saveBtn"
                            ),
                            "loading-text": _vm.$options.filters.translate(
                              "webshop_search_favorite_edit_saveBtn_loading"
                            ),
                            loading: _vm.editLoading,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass:
                "ui-modal-close-link ui-modal-closer mdi mdi-close-circle-outline",
              attrs: {
                title: _vm.$options.filters.translate(
                  "webshop_search_favorite_edit_closeButton"
                ),
              },
              on: {
                click: function ($event) {
                  return _vm.closeList()
                },
              },
            },
            [_c("i")]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "buttons" },
          [
            _vm.list.customerNo == _vm.Settings.profileInfo.customerNo
              ? [
                  _c(
                    "button",
                    {
                      staticClass: "ui-button ui-button-flat ui-button-small",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.toggleEdit()
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "mdi",
                        class: {
                          "mdi-pencil": !_vm.showEdit,
                          "mdi-close-circle-outline": _vm.showEdit,
                        },
                      }),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm._f("translate")(
                              "webshop_search_favorite_listButtons_edit"
                            )
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "ui-button ui-button-flat ui-button-small",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.openAddProductModal()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "mdi mdi-plus" }),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm._f("translate")(
                              "webshop_search_favorite_listButtons_addProducts"
                            )
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "ui-button ui-button-flat ui-button-small",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.downloadCsv()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "mdi mdi-grid" }),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm._f("translate")(
                              "webshop_search_favorite_listButtons_csv"
                            )
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "ui-button ui-button-flat ui-button-small",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.print()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "mdi mdi-printer" }),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm._f("translate")(
                              "webshop_search_favorite_listButtons_print"
                            )
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.enableShareFavoriteList &&
                  _vm.Settings.profileInfo.chainName
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "ui-button ui-button-flat ui-button-small",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.shareFavoriteList()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "mdi mdi-share-variant" }),
                          _vm._v(" "),
                          !_vm.list.isShared
                            ? [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("translate")(
                                      "webshop_search_favorite_listButtons_share"
                                    )
                                  )
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.list.isShared
                            ? [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("translate")(
                                      "webshop_search_favorite_listButtons_stopSharing"
                                    )
                                  )
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "ui-button ui-button-flat ui-button-small",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.copyFavoriteList()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "mdi mdi-content-copy" }),
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("translate")(
                              "webshop_search_favorite_listButtons_copy"
                            )
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.enableInventory
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "ui-button ui-button-flat ui-button-small",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.createInventoryList()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "mdi mdi-content-copy" }),
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_search_favorite_listButtons_createInventoryList"
                                )
                              ) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "ui-button ui-button-flat ui-button-small",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.openConfirmDelete()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "mdi mdi-delete" }),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm._f("translate")(
                              "webshop_search_favorite_listButtons_delete"
                            )
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.list.count > 0
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "ui-button ui-button-flat ui-button-small button-sort-list",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.sortList()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "mdi mdi-sort" }),
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_search_favorite_listButtons_sortList"
                                )
                              ) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              : [
                  _c(
                    "button",
                    {
                      staticClass: "ui-button ui-button-flat ui-button-small",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.copyFavoriteList()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "mdi mdi-content-copy" }),
                      _vm._v(
                        _vm._s(
                          _vm._f("translate")(
                            "webshop_search_favorite_listButtons_copyShared"
                          )
                        ) + "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.enableInventory
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "ui-button ui-button-flat ui-button-small",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.createInventoryList()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "mdi mdi-content-copy" }),
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_search_favorite_listButtons_createInventoryList"
                                )
                              ) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }