export function validateComparePasswords(passDomObj, passRepeatDomObj, errorMessage) {
    let valid = false;
    let validatePassword = () => {
        valid = passDomObj.value == passRepeatDomObj.value;
        if (!valid) {
            passRepeatDomObj.setCustomValidity(errorMessage);
        }
        else {
            passRepeatDomObj.setCustomValidity('');
        }
    };
    //document.addEventListener('DOMContentLoaded', validatePassword);
    passDomObj.onchange = validatePassword;
    passRepeatDomObj.onkeyup = validatePassword;
    return valid;
}
//used default by Angular 4.3
export function isNumeric(value) {
    return !isNaN(value - parseFloat(value));
}
