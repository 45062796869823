var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modalLoginForm-step" }, [
    _vm.submitted
      ? _c("div", { staticClass: "text-center" }, [
          _c("p", [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm._f("translate")(
                    "webshop_login_requestUsername_submittedText"
                  )
                ) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ui-modal-devider" }, [
            _c(
              "button",
              {
                staticClass: "ui-button ui-button-secondary ui-button-hasIcon",
                on: {
                  click: function ($event) {
                    return _vm.goBack()
                  },
                },
              },
              [
                _c("i", { staticClass: "mdi mdi-arrow-left" }),
                _vm._v(
                  _vm._s(_vm._f("translate")("webshop_general_back")) +
                    "\n            "
                ),
              ]
            ),
          ]),
        ])
      : _c(
          "form",
          {
            staticClass: "ui-form",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.request()
              },
            },
          },
          [
            _c("p", [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("translate")("webshop_login_requestUsername_topText")
                  ) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "ui-form-fieldwrapper" }, [
              _c("label", { attrs: { for: "email" } }, [
                _vm._v(
                  _vm._s(
                    _vm._f("translate")(
                      "webshop_login_requestUsername_emailLabel"
                    )
                  )
                ),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email",
                  },
                ],
                attrs: {
                  name: "email",
                  type: "email",
                  autocomplete: "off",
                  required: "",
                  disabled: _vm.loading,
                  placeholder: _vm.$options.filters.translate(
                    "webshop_login_requestUsername_emailPlaceholder"
                  ),
                },
                domProps: { value: _vm.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.email = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "ui-form-fieldwrapper ui-form-submitwrapper ui-form-submitwrapper-full",
              },
              [
                _c(
                  "a",
                  {
                    staticClass:
                      "ui-button ui-button-secondary ui-button-hasIcon modalLoginForm-noLogin left",
                    on: {
                      click: function ($event) {
                        return _vm.goBack()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "mdi mdi-arrow-left" }),
                    _vm._v(
                      _vm._s(_vm._f("translate")("webshop_general_back")) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("loading-button", {
                  attrs: {
                    "icon-class": "mdi-send",
                    text: _vm.$options.filters.translate(
                      "webshop_login_requestUsername_btn_send"
                    ),
                    "loading-text": _vm.$options.filters.translate(
                      "webshop_login_requestUsername_btn_send_loadingText"
                    ),
                    loading: _vm.loading,
                  },
                }),
              ],
              1
            ),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }