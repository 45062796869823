var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "modal-navigation search" }, [
      _c(
        "button",
        {
          staticClass:
            "ui-button ui-button-flat ui-button-small ui-button-transparent",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.goBack()
            },
          },
        },
        [
          _c("i", { staticClass: "mdi mdi-arrow-left" }),
          _vm._v(
            " " +
              _vm._s(_vm._f("translate")("webshop_general_back")) +
              "\n        "
          ),
        ]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "navigation-head" }, [
        _c("i", { staticClass: "mdi mdi-pencil" }),
        _vm._v(
          " " +
            _vm._s(
              _vm._f("translate")("webshop_inventory_addmodals_ownProduct_back")
            )
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "slide-own" },
      [
        _c("p", [
          _vm._v(
            _vm._s(
              _vm._f("translate")(
                "webshop_inventory_addmodals_ownProduct_description"
              )
            )
          ),
        ]),
        _vm._v(" "),
        _c("inventory-product-own-form-partial", {
          attrs: {
            loading: _vm.loading,
            setLoading: _vm.setLoading,
            cancel: _vm.goBack,
            list: _vm.list,
            line: null,
            saveBtnText: _vm.saveBtnText,
          },
          on: { save: _vm.save },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }