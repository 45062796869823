var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.product.blocked && !_vm.product.saleBlocked
    ? _c("div", [
        _c(
          "a",
          {
            staticClass: "shop-product-addToBasket ui-button",
            class: {
              "in-basket": _vm.inBasket,
              "add-to-basket-button": _vm.view == _vm.viewTypes.list,
            },
            attrs: { disabled: _vm.loading || _vm.productLoading },
            on: {
              click: function ($event) {
                return _vm.addToBasket()
              },
            },
          },
          [
            _c(
              "span",
              {
                staticClass: "shop-favorite-list-icon",
                attrs: {
                  title: _vm.product.inBasket
                    ? _vm.$options.filters.translate(
                        "webshop_search_search_item_titles_inBasket"
                      )
                    : "",
                },
              },
              [
                _c("span", {
                  staticClass: "mdi mdi-cart",
                  class: { "right-5": _vm.view == _vm.viewTypes.gallery },
                }),
              ]
            ),
            _vm._v(" "),
            _vm.view == _vm.viewTypes.gallery
              ? _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_search_search_view_gallery_button_addToBacket"
                      )
                    )
                  ),
                ])
              : _vm._e(),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }