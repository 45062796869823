var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "right" }, [
    _c(
      "button",
      {
        staticClass: "ui-button ui-button-hasIcon",
        attrs: { type: "button" },
        on: {
          click: function ($event) {
            return _vm.open()
          },
        },
      },
      [
        _c("i", { staticClass: "mdi mdi-key-variant" }),
        _vm._v(
          " " +
            _vm._s(
              _vm._f("translate")("webshop_myPage_settings_editPassword")
            ) +
            "\n    "
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }