export class CacheItem {
    id;
    params;
    promise;
    created;
    expires;
    constructor(id, params, promise, created, expires) {
        this.id = id;
        this.params = params;
        this.promise = promise;
        this.created = created;
        this.expires = expires;
    }
}
