var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import * as _ from "lodash";
import BasketOrderlineWarnings from "components/basket/BasketOrderlineWarnings.vue";
import BasketOrderlineObsoleteMessage from "components/basket/BasketOrderlineObsoleteMessage.vue";
import SuggestionTooltip from '../shared/SuggestionTooltip.vue';
import ShopItemStockStatus from '../shop/partials/ShopItemStockStatus.vue';
import { Orderline } from "models/order/Orderline";
import { EventTypes } from "models/EventTypes";
import { UpdateOrderLineVM } from "../../models/order/UpdateOrderLineVM";
import { OrderService } from "../../services/OrderService";
import { ToastService } from "../../services/ToastService";
import { Service } from '../../services/Service';
import ShopItemIcons from "../shop/partials/ShopItemIcons.vue";
import IconTrash from '../shared/IconTrash.vue';
import { PriceType } from 'models/product/PriceType';
import InputNumber from "../shared/InputNumber.vue";
import { PermissionService } from "services/PermissionService";
import { PermissionLevel } from "models/user/PermissionLevel";
import BasketAlsoBoughtProducts from "components/basket/BasketAlsoBoughtProducts.vue";
import { ItemStatus } from "models/product/ItemStatus";
import ItemSurchargeWarning from "components/shared/ItemSurchargeWarning.vue";
import HighlightMarkings from "components/shared/HighlightMarkings.vue";
import ShopStockWarning from "components/shop/partials/ShopStockWarning.vue";
//units: Array<WebShopItemUnit>;
let BasketOrderline = class BasketOrderline extends Vue {
    orderline;
    deliveryDate;
    loading = false;
    loaded = false;
    ol = new Orderline();
    _quantity = null;
    isCheckoutOffer = false;
    isExpiryProduct = false;
    selectedUnit = null;
    isInline;
    priceType = PriceType;
    showDiscountExceededText = false;
    showPrice = PermissionService.hasPermission(PermissionLevel.ShowPrice);
    showAvailableQuantity = PermissionService.hasPermission(PermissionLevel.ShowAvailableQuantity);
    showAlsoBoughtProducts = false;
    ItemStatus = ItemStatus;
    mounted() {
        this.ol = _.clone(this.orderline);
        this.isCheckoutOffer = this.orderline.checkoutId > 0;
        this.isExpiryProduct = this.orderline.expiryRef != null;
        this.$root.$on(EventTypes.BasketDepositProductAdded, this.updateDepositOrderline);
        /*
                Set the selectedUnit to match the unit from the orderline.unit
            */
        if (this.orderline.units != null) {
            this.orderline.units.forEach(unit => {
                if (unit.name === this.orderline.unit) {
                    this.selectedUnit = unit;
                }
            });
        }
        /* Set values for rollback */
        this._quantity = _.clone(this.ol.amount);
    }
    beforeDestroy() {
        this.$root.$off(EventTypes.BasketDepositProductAdded);
    }
    updateDepositOrderline(val) {
        if (val) {
            let index = val
                .map(x => {
                return x.productId;
            })
                .indexOf(this.ol.productId);
            if (index > -1) {
                let product = val[index];
                let converter = {
                    a: Number(this.ol.amount),
                    b: Number(product.amount)
                };
                this.ol.amount = converter.a + converter.b;
                this._quantity = this.ol.amount;
            }
            return;
        }
    }
    rollback() {
        this.ol.amount = _.clone(this._quantity);
    }
    replaceProduct() {
        let _altOrderline = this.ol.altOrderline;
        let _orderline = new UpdateOrderLineVM(this.ol.lineNo, this.ol.productId, this.ol.amount, this.ol.unit, this.deliveryDate, this.ol.description);
        let _replacement = new UpdateOrderLineVM(_altOrderline.lineNo, _altOrderline.productId, _altOrderline.amount, _altOrderline.unit, this.deliveryDate, this.ol.description);
        OrderService.replaceProduct(_orderline, _replacement).then(res => {
            this.reload();
            ToastService.success(Vue.filter("translate")("webshop_search_search_item_obsolete_replaced", this.ol.description));
        }, err => {
            ToastService.danger(Vue.filter("translate")("webshop_search_search_item_obsolete_replaced_error", this.ol.description));
            this.reload();
        });
    }
    updateMiniCart(data) {
        this.$root.$emit("mini-cart-update", data);
    }
    update(callback, errorCallback) {
        let unit = this.selectedUnit ? this.selectedUnit.name : this.ol.unit;
        let depositLine = this.ol.lineprice < 0;
        let _orderline = new UpdateOrderLineVM(this.ol.lineNo, this.ol.productId, this.ol.amount, unit, this.deliveryDate, this.ol.description, depositLine);
        this.loading = true;
        _orderline.deliveryDate = this.deliveryDate;
        OrderService.updateOrderLine(_orderline).then(res => {
            this._quantity = _.clone(_orderline.quantity);
            this.ol.amount = _orderline.quantity;
            this.reload();
            this.$root.$emit(EventTypes.MiniCartUpdate, res.previewOrder);
            if (callback) {
                callback(res);
            }
        }, err => {
            // Rollback
            this.rollback();
            // Show errormessages
            if (errorCallback) {
                errorCallback();
            }
            else {
                this.reload();
            }
        });
    }
    updateQuantity() {
        let vm = this;
        this.loading = true;
        let showSuccess = true;
        if (this._quantity == this.ol.amount) {
            showSuccess = false;
            this.loading = false;
            return;
        }
        if (typeof this.ol.amount !== "number") {
            ToastService.danger(Vue.filter("translate")("webshop_order_invalid_amount"));
            showSuccess = false;
            this.ol.amount = this._quantity;
            this.loading = false;
            return;
        }
        if ((!this.ol.weightItem ||
            (this.ol.weightItem && this.selectedUnit.name !== "KG")) &&
            this.ol.amount % 1 != 0) {
            this.ol.amount = Math.ceil(this.ol.amount);
            ToastService.danger(Vue.filter("translate")("webshop_order_invalid_amount"));
            showSuccess = false;
            this.ol.amount = this._quantity;
            this.loading = false;
            return;
        }
        if (this.ol.amount < 0) {
            this.ol.amount = 1;
        }
        if (this.ol.amount == 0) {
            this.removeOrderline(true);
        }
        else {
            let callback = () => {
                if (showSuccess) {
                    ToastService.success(Vue.filter("translate")("webshop_order_changeProductQuantity_lineUpdated", " <i>" + vm.ol.description + "</i>"));
                }
                this.loading = false;
            };
            let errorCallback = () => {
                this.loading = false;
                this.reload();
            };
            this.update(callback, errorCallback);
        }
    }
    updateUnit(e) {
        let vm = this;
        if (this.selectedUnit.name !== "KG" && this.ol.amount % 1 != 0) {
            this.ol.amount = Math.ceil(this.ol.amount);
        }
        let callback = () => {
            ToastService.success(Vue.filter("translate")("webshop_order_changeProductUnit_lineUpdated", "<i>" + vm.ol.description + "</i>"));
            this.loading = false;
        };
        let errorCallback = () => {
            this.loading = false;
            ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
            this.reload();
        };
        this.update(callback, errorCallback);
    }
    removeOrderline(ignoreLoading = false) {
        if (!ignoreLoading && this.loading)
            return;
        let vm = this;
        // Force quantity to 0 to delete
        this.ol.amount = 0;
        let callback = () => {
            ToastService.success(Vue.filter("translate")("webshop_order_removeProduct_lineDeleted", "<i>" + this.ol.description + "</i>"));
        };
        this.update(callback);
    }
    reload() {
        this.$root.$emit(EventTypes.BasketUpdate);
    }
    focusNextOnEnter() {
        /* Service.focusNextOnEnter(this, 'input.shop-basket-input[tabindex="0"]', 'inputAmount'); */
        Service.focusNextOnEnter(this.$refs['inputAmountParent'], 'input.shop-basket-input[tabindex="0"]', 'inputAmount');
    }
    get priceTypeCampaign() {
        return this.orderline.units != null && this.orderline.units.find(x => x.priceType == this.priceType.CampaignPrice) != undefined;
    }
    get outOfRangeDeliveryDate() {
        let canBeDeliveredBy = this.orderline.markings.canBeDeliveredBy;
        return canBeDeliveredBy && new Date(canBeDeliveredBy).getFullYear() == 9999;
    }
    get suggestedAmount() {
        return this.orderline.leftAmountWithSurcharge + this.orderline.amount;
    }
};
BasketOrderline = __decorate([
    Component({
        components: {
            ShopItemIcons,
            BasketOrderlineWarnings,
            BasketOrderlineObsoleteMessage,
            SuggestionTooltip,
            IconTrash,
            InputNumber,
            ShopItemStockStatus,
            BasketAlsoBoughtProducts,
            ItemSurchargeWarning,
            HighlightMarkings,
            ShopStockWarning
        },
        props: {
            orderline: Object,
            deliveryDate: String,
            isInline: Boolean
        }
    })
], BasketOrderline);
export default BasketOrderline;
