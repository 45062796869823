export var CustomerClass;
(function (CustomerClass) {
    CustomerClass["None"] = "";
    CustomerClass["A"] = "A";
    CustomerClass["B"] = "B";
    CustomerClass["K"] = "K";
    CustomerClass["P"] = "P";
    CustomerClass["S"] = "S";
    CustomerClass["T"] = "T";
    CustomerClass["X"] = "X";
    CustomerClass["\u00D6"] = "\u00D6"; // Ej Kampanjpris
})(CustomerClass || (CustomerClass = {}));
