export var CampaignPlanningLineStatus;
(function (CampaignPlanningLineStatus) {
    CampaignPlanningLineStatus[CampaignPlanningLineStatus["NewLine"] = 0] = "NewLine";
    CampaignPlanningLineStatus[CampaignPlanningLineStatus["Waiting"] = 1] = "Waiting";
    CampaignPlanningLineStatus[CampaignPlanningLineStatus["WaitingPrice"] = 2] = "WaitingPrice";
    CampaignPlanningLineStatus[CampaignPlanningLineStatus["Rejected"] = 3] = "Rejected";
    CampaignPlanningLineStatus[CampaignPlanningLineStatus["Approved"] = 4] = "Approved";
    CampaignPlanningLineStatus[CampaignPlanningLineStatus["Booked"] = 5] = "Booked";
})(CampaignPlanningLineStatus || (CampaignPlanningLineStatus = {}));
export var CampaignPlanningLineWaitingForStatus;
(function (CampaignPlanningLineWaitingForStatus) {
    CampaignPlanningLineWaitingForStatus[CampaignPlanningLineWaitingForStatus["WaitingForVendor"] = 0] = "WaitingForVendor";
    CampaignPlanningLineWaitingForStatus[CampaignPlanningLineWaitingForStatus["WaitingForSvenskCater"] = 1] = "WaitingForSvenskCater";
    CampaignPlanningLineWaitingForStatus[CampaignPlanningLineWaitingForStatus["WaitingForNoOne"] = 2] = "WaitingForNoOne";
})(CampaignPlanningLineWaitingForStatus || (CampaignPlanningLineWaitingForStatus = {}));
