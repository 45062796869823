var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { enableBodyScroll, clearAllBodyScrollLocks, } from "body-scroll-lock";
import ShopFilterHeader from "./ShopFilterHeader.vue";
import ShopFilterMultipleSelector from "./ShopFilterMultipleSelector.vue";
import ShopFilterCategories from "./ShopFilterCategories.vue";
import ShopFilterFavoriteLists from "./ShopFilterFavoriteLists.vue";
import ShopFilterCustomLists from "./ShopFilterCustomLists.vue";
import { MarkingTypes } from "models/search/MarkingTypes";
import { StorageTypes } from "models/search/StorageTypes";
import { SearchInput } from "models/search/SearchInput";
import { EventTypes } from "models/EventTypes";
import { KeyValue } from "models/utility/KeyValue";
import { ItemIcons } from "models/product/ItemIcons";
import { SearchService } from "services/SearchService";
import { Settings } from "config/Settings";
let ShopFilters = class ShopFilters extends Vue {
    active = false;
    filters;
    searchMenu;
    input;
    markings = null;
    storages = Object.keys(StorageTypes)
        .filter((key) => isNaN(Number(key)))
        .sort((a, b) => {
        let icons = new ItemIcons();
        let itemIconA = icons[a];
        let itemIconB = icons[b];
        if (!!itemIconA && !!itemIconB) {
            if (itemIconA.label > itemIconB.label)
                return 1;
            if (itemIconB.label > itemIconA.label)
                return -1;
        }
        return 0;
    })
        .map((key) => KeyValue.create(key, StorageTypes[key]));
    brands = null;
    vendors = null;
    mobileFiltersOpen = false;
    isActive = true;
    isNotActive = false;
    isOpenShop = SearchService.isOpenShop;
    categoriesLoading = true;
    storageTypesLoading = true;
    markingsLoading = true;
    brandsLoading = true;
    vendorsLoading = true;
    favoriteListsLoading = true;
    customListsLoading = true;
    eventTypes = EventTypes;
    filterscontainer = null;
    punchOut = Settings.profileInfo && Settings.profileInfo.punchOut;
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
        this.$root.$on(EventTypes.ShopCategoriesLoad, () => {
            this.categoriesLoading = false;
        });
        this.$root.$on(EventTypes.ShopStorageLoad, () => {
            this.storageTypesLoading = false;
        });
        this.$root.$on(EventTypes.ShopMarkingsLoad, () => {
            this.markingsLoading = false;
        });
        this.$root.$on(EventTypes.ShopBrandsLoad, () => {
            this.brandsLoading = false;
        });
        this.$root.$on(EventTypes.ShopVendorsLoad, () => {
            this.vendorsLoading = false;
        });
        if (!this.isOpenShop) {
            this.$root.$on(EventTypes.ShopFavoriteListsLoad, () => {
                this.favoriteListsLoading = false;
            });
            this.$root.$on(EventTypes.ShopCustomListsLoad, () => {
                this.customListsLoading = false;
            });
        }
    }
    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
        this.$root.$off(EventTypes.ShopCategoriesLoad, () => {
            this.categoriesLoading = true;
        });
        this.$root.$off(EventTypes.ShopStorageLoad, () => {
            this.storageTypesLoading = true;
        });
        this.$root.$off(EventTypes.ShopMarkingsLoad, () => {
            this.markingsLoading = true;
        });
        this.$root.$off(EventTypes.ShopBrandsLoad, () => {
            this.brandsLoading = true;
        });
        this.$root.$off(EventTypes.ShopVendorsLoad, () => {
            this.vendorsLoading = true;
        });
        if (!this.isOpenShop) {
            this.$root.$off(EventTypes.ShopFavoriteListsLoad, () => {
                this.favoriteListsLoading = true;
            });
            this.$root.$off(EventTypes.ShopCustomListsLoad, () => {
                this.customListsLoading = true;
            });
        }
        clearAllBodyScrollLocks();
    }
    mounted() {
        this.handleResize();
        this.markings = Object.keys(MarkingTypes)
            .filter((key) => {
            let show = isNaN(Number(key));
            if (!this.searchMenu.hasAgreement &&
                MarkingTypes[key] === MarkingTypes.Agreement)
                return false;
            if (MarkingTypes[key] === MarkingTypes.Systemköp &&
                (!Settings.profileInfo.isMemberOfSystemköp || this.isOpenShop))
                return false;
            return show;
        })
            .sort((a, b) => {
            let icons = new ItemIcons();
            let itemIconA = icons[a];
            let itemIconB = icons[b];
            if (!!itemIconA && !!itemIconB) {
                if (itemIconA.label > itemIconB.label)
                    return 1;
                if (itemIconA.label < itemIconB.label)
                    return -1;
            }
            return 0;
        })
            .map((key, index) => KeyValue.create(key, key));
        this.brands = this.searchMenu.brands.map((key) => KeyValue.create(key, key));
        this.vendors = this.searchMenu.vendors.map((obj) => KeyValue.create(obj.name, obj.code));
    }
    toggleFilters() {
        if (window.innerWidth < 1100) {
            this.mobileFiltersOpen = !this.mobileFiltersOpen;
            // if (this.mobileFiltersOpen)
            //     disableBodyScroll(this.$refs.filterscontainer);
            // else enableBodyScroll(this.$refs.filterscontainer);
        }
    }
    handleResize() {
        if (window.innerWidth < 1100) {
            this.mobileFiltersOpen = false;
            this.isActive = false;
            this.isNotActive = false;
        }
        else {
            enableBodyScroll(this.$refs.filterscontainer);
            this.isActive = true;
            this.isNotActive = false;
        }
    }
    selectStorage(selectedValues) {
        let input = Object.assign(new SearchInput(), this.input);
        input.storageTypes = selectedValues;
        this.$root.$emit(EventTypes.ShopSearchStart, input);
    }
    selectMarking(selectedValues) {
        const hasAgreement = this.input.markings &&
            this.input.markings.includes(MarkingTypes.Agreement);
        let input = Object.assign(new SearchInput(), this.input);
        input.markings = selectedValues;
        if (hasAgreement)
            input.markings.push(MarkingTypes.Agreement);
        this.$root.$emit(EventTypes.ShopSearchStart, input);
    }
    selectBrand(selectedValues) {
        let input = Object.assign(new SearchInput(), this.input);
        input.brandIds = selectedValues;
        this.$root.$emit(EventTypes.ShopSearchStart, input);
    }
    selectVendor(selectedValues) {
        let input = Object.assign(new SearchInput(), this.input);
        input.vendorIds = selectedValues;
        this.$root.$emit(EventTypes.ShopSearchStart, input);
    }
    removeFilter(evt) {
        evt.stopPropagation();
        this.input.favoriteListId = null;
        this.input.department = null;
        this.input.searchQuery =
            this.input.searchQuery != null
                ? this.input.searchQuery.replace("topp:200", "")
                : this.input.searchQuery;
        this.input.markings = [];
        this.input.vendorIds = [];
        this.input.categoryId = null;
        this.input.groupId = null;
        this.input.storageTypes = [];
        this.input.brandIds = [];
        this.$root.$emit(EventTypes.ShopSearchStart, this.input);
    }
    get activeFilters() {
        let count = 0;
        count += this.input.brandIds.length;
        count += this.input.vendorIds.length;
        count += this.input.markings.length;
        count += this.input.storageTypes.length;
        if (this.input.categoryId != null)
            count += 1;
        if (this.input.groupId != null)
            count += 1;
        if (this.input.searchQuery != null &&
            this.input.searchQuery.indexOf("topp:200") > -1)
            count += 1;
        if (this.input.favoriteListId != null)
            count += 1;
        return count;
    }
};
ShopFilters = __decorate([
    Component({
        components: {
            ShopFilterHeader,
            ShopFilterCategories,
            ShopFilterMultipleSelector,
            ShopFilterFavoriteLists,
            ShopFilterCustomLists,
        },
        props: {
            filters: Object,
            input: Object,
            searchMenu: Object,
            totalProducts: Number,
        },
    })
], ShopFilters);
export default ShopFilters;
