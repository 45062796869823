var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "modal-navigation search" }, [
        _c(
          "button",
          {
            staticClass:
              "ui-button ui-button-flat ui-button-small ui-button-transparent",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                !_vm.prefixedPeriod && _vm.result && _vm.result.length > 0
                  ? _vm.resetResult()
                  : _vm.goBack()
              },
            },
          },
          [
            _c("i", { staticClass: "mdi mdi-arrow-left" }),
            _vm._v(
              " " +
                _vm._s(_vm._f("translate")("webshop_general_back")) +
                "\n        "
            ),
          ]
        ),
        _vm._v(" "),
        !_vm.prefixedPeriod
          ? _c("span", { staticClass: "navigation-head" }, [
              _c("i", { staticClass: "mdi mdi-calendar" }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("translate")(
                      "webshop_inventory_addmodals_order_date"
                    )
                  ) +
                  "\n            "
              ),
              _vm.result && _vm.result.length > 0
                ? _c("span", { staticClass: "search-query-container" }, [
                    _vm._v("\n                - "),
                    _c("span", { staticClass: "search-query" }, [
                      _vm._v(
                        '"' +
                          _vm._s(_vm._f("date")(_vm.selectedDate, "L")) +
                          '"'
                      ),
                    ]),
                  ])
                : _vm._e(),
            ])
          : _c("span", { staticClass: "navigation-head" }, [
              _c("i", { staticClass: "mdi mdi-numeric-6-circle" }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("translate")(
                      "webshop_inventory_addmodals_order_fromLastDate",
                      _vm.prefixedPeriod
                    )
                  ) +
                  "\n        "
              ),
            ]),
      ]),
      _vm._v(" "),
      _vm.result && _vm.result.length > 0
        ? [
            _c("inventory-product-result-partial", {
              attrs: { result: _vm.result, "show-search-filter": true },
            }),
          ]
        : _vm.prefixedPeriod
        ? _c("div", { staticClass: "slide-prefixed-period" }, [
            !_vm.loading
              ? _c("div", [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm._f("translate")(
                          "webshop_inventory_addmodals_order_datePeriod",
                          _vm.prefixedPeriod
                        )
                      ) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
          ])
        : _c("div", { staticClass: "slide-date-picker" }, [
            _c("p", [
              _vm._v(
                _vm._s(
                  _vm._f("translate")(
                    "webshop_inventory_addmodals_order_searchProductsOrdersInPeriod"
                  )
                )
              ),
              _c("br"),
              _c("br"),
            ]),
            _vm._v(" "),
            _c(
              "form",
              {
                staticClass: "ui-form",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.search()
                  },
                },
              },
              [
                _c("flat-pickr", {
                  staticClass: "date-picker-inline",
                  attrs: { config: _vm.dpSettings },
                  on: { "on-change": _vm.search },
                  model: {
                    value: _vm.selectedDate,
                    callback: function ($$v) {
                      _vm.selectedDate = $$v
                    },
                    expression: "selectedDate",
                  },
                }),
              ],
              1
            ),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }