var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", { staticClass: "inventory-lists" }, [
    _c("li", { staticClass: "header" }, [
      _c("span", [
        _vm._v(_vm._s(_vm._f("translate")("webshop_inventory_lists_title"))),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "created" }, [
        _vm._v(_vm._s(_vm._f("translate")("webshop_inventory_lists_created"))),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "text-right inventory-picker-total" }, [
        _vm._v(_vm._s(_vm._f("translate")("webshop_inventory_lists_total"))),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "ui-button ui-button-small inventory-lists-new",
          on: {
            click: function ($event) {
              return _vm.toggleNew()
            },
          },
        },
        [
          _c("span", {
            staticClass: "mdi",
            class: {
              "mdi-plus": !_vm.showCreateNew,
              "mdi-close": _vm.showCreateNew,
            },
          }),
          _vm._v(" "),
          !_vm.showCreateNew
            ? _c("span", [
                _vm._v(
                  _vm._s(_vm._f("translate")("webshop_inventory_lists_new"))
                ),
              ])
            : _c("span", [
                _vm._v(
                  _vm._s(_vm._f("translate")("webshop_inventory_lists_cancel"))
                ),
              ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "li",
      { staticClass: "new-list", class: { visible: _vm.showCreateNew } },
      [
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.createNewList()
              },
            },
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.newListName,
                  expression: "newListName",
                },
              ],
              attrs: {
                type: "text",
                name: "listname",
                placeholder: _vm.$options.filters.translate(
                  "webshop_inventory_lists_newNamePlaceholder"
                ),
                required: "",
                maxlength: "20",
              },
              domProps: { value: _vm.newListName },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.newListName = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "ui-button ui-button-small",
                attrs: { type: "submit" },
              },
              [
                _vm._v(
                  _vm._s(_vm._f("translate")("webshop_inventory_lists_create"))
                ),
              ]
            ),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    !_vm.lists || !_vm.lists.length > 0
      ? _c("li", [
          _c(
            "span",
            {
              staticStyle: {
                padding: "1rem .75rem 1rem 14px",
                display: "block",
                width: "100%",
              },
            },
            [
              _c("i", [
                _vm._v(
                  _vm._s(_vm._f("translate")("webshop_inventory_lists_noLists"))
                ),
              ]),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "ul-scroll" },
      _vm._l(_vm.lists, function (list) {
        return _c(
          "li",
          {
            key: list.id,
            class: {
              active: _vm.selectedListId && list.id === _vm.selectedListId,
            },
          },
          [
            _c(
              "router-link",
              {
                attrs: {
                  to: { name: "inventoryDetail", params: { id: list.id } },
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.scrollToList.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(_vm._s(list.name)),
                list.autoList
                  ? _c("i", {
                      staticClass: "mdi mdi-auto-fix left-10",
                      attrs: {
                        title: _vm.$options.filters.translate(
                          "webshop_inventory_autolager_iconTitle"
                        ),
                      },
                    })
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "created" }, [
              _vm._v(_vm._s(_vm._f("date")(list.created, "L"))),
            ]),
            _vm._v(" "),
            _vm.showPrice
              ? _c("span", { staticClass: "text-right" }, [
                  _vm._v(_vm._s(_vm._f("currency")(list.totalValue))),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "inventory-list-options",
                on: {
                  click: function ($event) {
                    list._showOverlay = !list._showOverlay
                  },
                },
              },
              [_c("icon-trash")],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "inventory-list-actions",
                class: { active: list._showOverlay },
              },
              [
                _c("p", { staticClass: "large-delete-text" }, [
                  _vm._v(
                    "\r\n                    " +
                      _vm._s(
                        _vm._f("translate")(
                          "webshop_inventory_lists_deleteText"
                        )
                      ) +
                      " \r\n                    "
                  ),
                  _c("b", [_c("i", [_vm._v(_vm._s(list.name))])]),
                  _vm._v("?\r\n                "),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "small-delete-text" }, [
                  _vm._v(
                    "\r\n                    " +
                      _vm._s(
                        _vm._f("translate")("webshop_inventory_lists_delete")
                      ) +
                      " \r\n                    "
                  ),
                  _c("b", [_c("i", [_vm._v(_vm._s(list.name))])]),
                  _vm._v("?\r\n                "),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "delete-actions" }, [
                  _c(
                    "button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.deleteList(list)
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm._f("translate")(
                            "webshop_inventory_lists_delete_confimation"
                          )
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      on: {
                        click: function ($event) {
                          list._showOverlay = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm._f("translate")(
                            "webshop_inventory_lists_delete_cancel"
                          )
                        )
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ],
          1
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("li", [
      _c("span", { staticClass: "bold" }, [
        _vm._v(_vm._s(_vm._f("translate")("webshop_inventory_lists_sum"))),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "created" }),
      _vm._v(" "),
      _vm.showPrice
        ? _c("span", { staticClass: "text-right inventory-picker-total" }, [
            _vm._v(_vm._s(_vm._f("currency")(_vm.listsTotal))),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }