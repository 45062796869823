var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import VueRouter from "vue-router";
import VueSticky from 'vue-sticky';
import { Settings } from "config/Settings";
import ShopFilters from "./filters/ShopFilters.vue";
import ShopBreadcrumb from "./partials/ShopBreadcrumb.vue";
import ShopSearchInput from "./partials/ShopSearchInput.vue";
import ShopResults from "./result/ShopResults.vue";
import ShopFavoriteListHeader from "./partials/ShopFavoriteListHeader.vue";
import { SearchInput } from 'models/search/SearchInput';
import { SearchService } from 'services/SearchService';
import { ToastService } from 'services/ToastService';
import { EventTypes } from 'models/EventTypes';
import { QueryParameters } from 'models/search/QueryParameters';
import { SearchSortBy } from 'models/search/SearchSortBy';
const routes = [{
        name: "search",
        path: "/webbshop/starta-bestaellningen/:categorySlug?/:groupSlug?"
    }];
const router = new VueRouter({
    mode: "history",
    routes: routes
});
class SearchParams {
    categorySlug;
    groupSlug;
}
let Shop = class Shop extends Vue {
    searchMenu;
    isOpenShop;
    window = window;
    loading = false;
    input = new SearchInput();
    products = [];
    popularProducts = [];
    totalProducts = null;
    filters = null;
    searchService = SearchService;
    created() {
        this.$root.$on(EventTypes.ShopSearchStart, this.search);
        this.$root.$on(EventTypes.ShopRemoveProductFromFavoriteList, this.removeProduct);
        this.$root.$on(EventTypes.ShopSetProductOnFavoriteList, this.setProductOnFavoriteList);
        this.$root.$on(EventTypes.ShopAddedProductToBasket, this.addedProductToBasket);
        this.$root.$on(EventTypes.ShopReplaceProductOnFavoriteList, this.replaceProduct);
        this.$root.$on(EventTypes.ShopSearchRefresh, this.refresh);
        SearchService.isOpenShop = this.isOpenShop;
    }
    beforeDestroy() {
        this.$root.$off(EventTypes.ShopSearchStart, this.search);
        this.$root.$off(EventTypes.ShopRemoveProductFromFavoriteList, this.removeProduct);
        this.$root.$off(EventTypes.ShopSetProductOnFavoriteList, this.setProductOnFavoriteList);
        this.$root.$off(EventTypes.ShopAddedProductToBasket, this.addedProductToBasket);
        this.$root.$off(EventTypes.ShopReplaceProductOnFavoriteList, this.replaceProduct);
        this.$root.$off(EventTypes.ShopSearchRefresh, this.refresh);
    }
    mounted() {
        SearchService.removeCachedSortBy();
        this.loading = true;
        this.loadUrlQuery();
        this.searchPromise(this.input)
            .then(res => {
            this.$root.$emit(EventTypes.ShopSearchDone);
            this.loading = false;
        })
            .catch(err => {
            ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
        });
        this.$store.subscribe(mutation => {
            if (mutation.type === 'Orders/setActiveOrder') {
                this.refresh(true);
            }
        });
    }
    refresh(resetPage = true) {
        this.loading = true;
        this.search(this.input, resetPage);
    }
    searchPromise(input) {
        if (Settings.isIE)
            input.pageSize = 21;
        input.openShop = SearchService.isOpenShop;
        return SearchService.search(input).then(data => {
            this.products = data.products;
            this.filters = data.filters;
            this.popularProducts = data.popularProducts;
            this.totalProducts = data.totalProducts;
        });
    }
    search(input, resetPage = true) {
        if (resetPage)
            input.page = 1;
        input.sortBy = SearchService.getCachedSortBy(input);
        this.searchPromise(input)
            .then(res => {
            this.input.searchQuery = input.searchQuery;
            this.input.categoryId = input.categoryId;
            this.input.groupId = input.groupId;
            this.input.favoriteListId = input.favoriteListId;
            this.input.page = input.page;
            this.input.pageSize = input.pageSize;
            this.input.storageTypes = input.storageTypes;
            this.input.vendorIds = input.vendorIds;
            this.input.brandIds = input.brandIds;
            this.input.markings = input.markings;
            this.input.sortBy = input.sortBy;
            this.input.openShop = input.openShop;
            this.input.department = input.department;
            this.buildUrlQuery();
            this.$root.$emit(EventTypes.ShopSearchDone);
            this.loading = false;
            var isSafari = /constructor/i.test(window["HTMLElement"]) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof window['safari'] !== 'undefined' && window['safari'].pushNotification));
            var isIE = /*@cc_on!@*/ false || !!document["documentMode"];
            var isEdge = !isIE && !!window["StyleMedia"];
            var scrollYPos = window.innerWidth < 667 ? 185 : window.innerWidth < 1100 ? 200 : 345;
            if (!isSafari && !isEdge && !isIE) {
                window.scrollTo({ top: scrollYPos, behavior: 'smooth' });
            }
            else {
                window.scroll(0, scrollYPos);
            }
        })
            .catch(err => {
            ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
        });
    }
    buildUrlQuery() {
        let input = this.input;
        let query = {};
        Object.keys(QueryParameters).forEach(key => {
            let shortKey = QueryParameters[key];
            if (shortKey === QueryParameters.page && input[key] <= 1)
                //skip page if value is less then 1
                return;
            if (shortKey === QueryParameters.sortBy && input[key] == SearchSortBy.Description)
                return;
            if (typeof input[key] !== "undefined" && input[key] !== null && input[key] !== '') {
                query[shortKey] = input[key];
            }
        });
        this.$router.push({ query })
            .catch(err => {
            // Do nothing, else an error is thrown to the console
            // because we're navigating to the same route, nothing serious.
        });
    }
    loadUrlQuery() {
        let input = this.input;
        Object.keys(QueryParameters).forEach(key => {
            let shortKey = QueryParameters[key];
            let value = this.$route.query[shortKey];
            if (shortKey === QueryParameters.page) {
                let page = parseInt(value);
                input.page = page > 1 ? page : 1;
            }
            else if (shortKey === QueryParameters.sortBy) {
                let sortBy = parseInt(value);
                if (!isNaN(sortBy)) {
                    input.sortBy = sortBy;
                }
            }
            else {
                if (typeof value === "undefined") {
                    input[key] = Array.isArray(input[key]) ? [] : null;
                }
                else {
                    let newValue;
                    if (Array.isArray(input[key]) && !Array.isArray(value)) {
                        newValue = [value];
                    }
                    else {
                        newValue = value;
                    }
                    input[key] = newValue;
                }
            }
        });
    }
    removeProduct(product) {
        let index = this.products.findIndex(x => x.productId === product.productId);
        if (index !== -1) {
            //this.$delete(array, index);
            this.products.splice(index, 1);
            this.totalProducts--;
        }
        index = this.popularProducts.findIndex(x => x.productId === product.productId);
        if (index !== -1) {
            this.popularProducts.splice(index, 1);
        }
    }
    replaceProduct(product) {
        let index = this.products.findIndex(x => x.productId === product.productId);
        if (index !== -1) {
            this.products.splice(index, 1);
        }
    }
    setProductOnFavoriteList(addedProduct, isOnFavoriteList) {
        let products = this.findProduct(addedProduct);
        if (products[0]) {
            products[0].isOnFavoriteList = isOnFavoriteList;
        }
        if (products[1]) {
            products[1].isOnFavoriteList = isOnFavoriteList;
        }
    }
    addedProductToBasket(addedProduct) {
        let products = this.findProduct(addedProduct);
        if (products[0]) {
            products[0].inBasket = true;
        }
        if (products[1]) {
            products[1].inBasket = true;
        }
    }
    /**
     * Returns founded product on product list and popular list
     */
    findProduct(product) {
        return [
            this.products.find(x => x.productId === product.productId),
            this.popularProducts.find(x => x.productId === product.productId)
        ];
    }
    addedFavoriteProduct(product) {
        this.products.push(product);
    }
};
Shop = __decorate([
    Component({
        components: {
            ShopFilters,
            ShopBreadcrumb,
            ShopSearchInput,
            ShopResults,
            ShopFavoriteListHeader
        },
        directives: {
            'sticky': VueSticky,
        },
        router: router,
        props: {
            searchMenu: Object,
            isOpenShop: {
                type: Boolean,
                required: false,
                default: false
            }
        }
    })
], Shop);
export default Shop;
