var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center", staticStyle: { "margin-top": "20px" } },
    [
      _c(
        "a",
        {
          staticClass: "ui-button ui-button-accept",
          on: {
            click: function ($event) {
              return _vm.show()
            },
          },
        },
        [
          _vm._v(
            _vm._s(_vm._f("translate")("webshop_campaign_review_addToFavorite"))
          ),
        ]
      ),
      _vm._v(" "),
      _vm.showList
        ? _c(
            "ul",
            { staticClass: "fav-list-select-ul" },
            _vm._l(_vm.lists, function (list, index) {
              return _c("check-out-fav-list-item", {
                key: index,
                attrs: { checkout: _vm.checkout, list: list },
              })
            }),
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }