var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ui-modal-content" },
    [
      _c("h3", { staticClass: "ui-modal-header header-medium" }, [
        _vm._v(
          _vm._s(
            _vm._f("translate")(
              "webshop_calculations_modals_customerItem_title"
            )
          )
        ),
      ]),
      _vm._v(" "),
      _c("calculation-modal-customer-item-form", {
        attrs: {
          list: _vm.data.list,
          line: _vm.data.line,
          loading: _vm.loadingOnSave || _vm.loading,
          setLoading: _vm.setLoading,
        },
        on: { save: _vm.save, close: _vm.closeModal },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }