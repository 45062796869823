import { render, staticRenderFns } from "./BasketOrderline.vue?vue&type=template&id=4e2c0f43&scoped=true&"
import script from "./BasketOrderline.vue?vue&type=script&lang=ts&"
export * from "./BasketOrderline.vue?vue&type=script&lang=ts&"
import style0 from "./BasketOrderline.vue?vue&type=style&index=0&id=4e2c0f43&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e2c0f43",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Projekt\\webbshop\\SvenskCater\\SvenskCater\\app\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4e2c0f43')) {
      api.createRecord('4e2c0f43', component.options)
    } else {
      api.reload('4e2c0f43', component.options)
    }
    module.hot.accept("./BasketOrderline.vue?vue&type=template&id=4e2c0f43&scoped=true&", function () {
      api.rerender('4e2c0f43', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/basket/BasketOrderline.vue"
export default component.exports