var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { EventTypes } from "models/EventTypes";
import { ModalService } from "modules/basic-core/basic-modal/src";
import OrganicPercentageModal from "components/shared/OrganicPercentageModal.vue";
import { Settings } from "config/Settings";
import MiniCartDropdown from "./MiniCartDropdown.vue";
import { PermissionService } from "services/PermissionService";
import { PermissionLevel } from "models/user/PermissionLevel";
let MiniCart = class MiniCart extends Vue {
    currentOrder;
    /* itemCount: number;
      noOfTooLate: number;
      organicPercentage: number;
      totalPrice: number; */
    // agreementCount: number = 0;
    displayItemCount = 0;
    displayNoOfTooLate = 0;
    displayOrganicPercentage = 0;
    displayTotalPrice = 0;
    showPrice = PermissionService.hasPermission(PermissionLevel.ShowPrice);
    punchOut = Settings.profileInfo && Settings.profileInfo.punchOut;
    get cartPath() {
        if (Settings && Settings.paths && Settings.paths["cart"]) {
            return Settings.paths["cart"];
        }
        return "";
    }
    get tooLateCountText() {
        let text = this.displayNoOfTooLate.toString() + " ";
        if (this.displayNoOfTooLate > 1) {
            text += Vue.filter("translate")("webshop_layout_header_exceededText_product_more");
        }
        else {
            text += Vue.filter("translate")("webshop_layout_header_exceededText_product_one");
        }
        return text;
    }
    openOrganicPercentageModal() {
        ModalService.create({
            cxt: this,
            component: OrganicPercentageModal,
            maxWidth: "700px",
        });
    }
    mounted() {
        /* update cart / organic percentage from props */
        /* this.displayItemCount = this.itemCount;
            this.displayNoOfTooLate = this.noOfTooLate;
            this.displayTotalPrice = this.totalPrice;
            this.displayOrganicPercentage = Math.round(this.organicPercentage); */
        this.displayItemCount = this.currentOrder.itemCount;
        this.displayNoOfTooLate = this.currentOrder.noOfTooLate;
        this.displayTotalPrice = this.currentOrder.totalPrice;
        this.displayOrganicPercentage = Math.round(this.currentOrder.ecoPct);
        this.$root.$on(EventTypes.MiniCartUpdate, this.updateMiniCart);
        this.$store.dispatch("Orders/getAllOrders");
        this.$store.subscribe((mutation) => {
            if (mutation.type === "Orders/setActiveOrder") {
                let activeOrd = this.$store.state.Orders.activeOrder;
                this.updateMiniCart(activeOrd);
            }
        });
    }
    beforeDestroy() {
        this.$root.$off(EventTypes.MiniCartUpdate);
    }
    updateMiniCart(data) {
        this.displayItemCount = data.itemCount;
        this.displayNoOfTooLate = data.noOfTooLate;
        this.displayTotalPrice = data.totalPrice;
        this.displayOrganicPercentage = Math.round(data.ecoPct);
    }
    get getAllOrders() {
        return this.$store.state.Orders.list;
    }
};
MiniCart = __decorate([
    Component({
        props: {
            currentOrder: Object,
            /* itemCount: Number,
                noOfTooLate: Number,
                organicPercentage: Number,
                totalPrice: Number */
        },
        components: {
            MiniCartDropdown,
        },
    })
], MiniCart);
export default MiniCart;
