var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition-group",
    { staticClass: "toast-list", attrs: { name: "toast", tag: "ul" } },
    _vm._l(_vm.toasts, function (toast) {
      return _c("li", { key: toast.id, staticClass: "toast-wrapper" }, [
        _c(
          "div",
          {
            staticClass: "toast",
            class: {
              success: toast.type == _vm.toastType.Success,
              warning: toast.type == _vm.toastType.Warning,
              danger: toast.type == _vm.toastType.Danger,
              info: toast.type == _vm.toastType.Info,
            },
          },
          [
            _c("i", {
              staticClass: "icon mdi",
              class: {
                "mdi-check": toast.type == _vm.toastType.Success,
                "mdi-alert-circle-outline": toast.type == _vm.toastType.Warning,
                "mdi-alert-outline": toast.type == _vm.toastType.Danger,
                "mdi-comment-alert-outline": toast.type == _vm.toastType.Info,
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "content",
              domProps: { innerHTML: _vm._s(toast.content) },
            }),
          ]
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }