var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "shop-minicart" },
      [
        _vm.cartPath
          ? [
              _c(
                "div",
                [
                  _c(
                    "a",
                    {
                      staticClass:
                        "shop-header-link shop-header-order shop-header-focus",
                      class: { "shop-header-punchout": _vm.punchOut },
                      attrs: { href: _vm.cartPath },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm._f("translate")("webshop_layout_header_cart")
                            ) +
                            "\n            ("
                        ),
                        _c("span", { staticClass: "shop-minicart-count" }, [
                          _vm._v(_vm._s(_vm.displayItemCount)),
                        ]),
                        _vm._v(")\n          "),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", {
                          staticClass: "mdi mdi-cart shop-header-order-icon",
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(
                                _vm.showPrice ? _vm.displayTotalPrice : 0
                              )
                            )
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.punchOut
                    ? _c("mini-cart-dropdown", {
                        attrs: { orders: _vm.getAllOrders },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.displayNoOfTooLate > 0
          ? _c(
              "div",
              {
                key: _vm.displayNoOfTooLate,
                staticClass: "shop-minicart-delayMessage",
              },
              [
                _c("span", {
                  staticClass:
                    "icon-info-outline shop-minicart-delayMessage-icon",
                }),
                _vm._v(
                  "\n      " +
                    _vm._s(
                      this.$options.filters.translate(
                        "webshop_layout_header_exceededText",
                        _vm.tooLateCountText
                      )
                    ) +
                    "\n    "
                ),
              ]
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }