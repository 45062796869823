var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { ItemIcons } from "models/product/ItemIcons";
import { FilterOption } from "models/search/FilterOption";
import { EventTypes } from "models/EventTypes";
let ShopFilterMultipleSelector = class ShopFilterMultipleSelector extends Vue {
    input;
    loading;
    useIcons;
    options;
    displayOptions = [];
    inputProperty;
    eventType;
    optionsAmounts;
    filterValue = "";
    filteredDisplayOptions = [];
    selectedDisplayOptions = [];
    visibleLines = 50;
    created() {
        this.$root.$on(EventTypes.ShopSearchDone, this.searchDone);
        this.$root.$emit(this.eventType);
    }
    beforeDestroy() {
        this.$root.$off(EventTypes.ShopSearchDone);
        this.$root.$off(this.eventType);
    }
    mounted() {
        var icons = new ItemIcons();
        this.displayOptions = this.getDisplayOptions();
    }
    getDisplayOptions() {
        var icons = new ItemIcons();
        return this.options.map((obj, index) => {
            var icon = "";
            var label = "";
            if (this.useIcons) {
                var itemIcon = icons[obj.key];
                if (itemIcon) {
                    icon = itemIcon.icon;
                    label = itemIcon.label;
                }
            }
            else {
                label = obj.key;
            }
            return Object.assign(new FilterOption(), {
                key: obj.key,
                value: obj.value,
                icon: icon,
                label: label,
                selected: false,
                amount: 0
            });
        });
    }
    select(option) {
        option.selected = !option.selected;
        let selectedValues = this.displayOptions.reduce((newArray, opt) => {
            if (opt.selected)
                newArray.push(opt.value);
            return newArray;
        }, []);
        this.$emit("select", selectedValues);
    }
    searchDone() {
        this.displayOptions.forEach(x => {
            x.selected = this.input[this.inputProperty]
                ? this.input[this.inputProperty].findIndex(y => y.toString().toLowerCase() == x.value.toString().toLowerCase()) !== -1
                : false;
            try {
                let amount = this.optionsAmounts[x.value];
                if (typeof amount === "undefined")
                    amount = 0;
                x.amount = amount;
            }
            catch (error) {
                x.amount = 0;
            }
        });
        if (this.displayOptions.length > 11) {
            this.filteredDisplayOptions = this.displayOptions.filter((x) => {
                if (this.filterValue !== "") {
                    return (x.label.toLowerCase().indexOf(this.filterValue.toLowerCase()) !== -1);
                }
                else {
                    return x.amount > 0 || x.selected;
                }
            });
        }
        else {
            this.filteredDisplayOptions = this.displayOptions;
        }
        this.selectedDisplayOptions = this.displayOptions.filter((x) => {
            return x.selected === true;
        });
    }
    get filteredLines() {
        return this.filteredDisplayOptions.slice(0, this.visibleLines);
    }
    showMoreLines() {
        this.visibleLines += 50 + Math.ceil(this.filteredDisplayOptions.length * 0.1);
    }
    removeFilter() {
        this.input[this.inputProperty] = [];
        this.$root.$emit(EventTypes.ShopSearchStart, this.input);
    }
};
__decorate([
    Watch("filterValue")
], ShopFilterMultipleSelector.prototype, "searchDone", null);
ShopFilterMultipleSelector = __decorate([
    Component({
        props: {
            options: Array,
            inputProperty: String,
            eventType: String,
            optionsAmounts: Object,
            input: Object,
            loading: Boolean,
            useIcons: Boolean
        }
    })
], ShopFilterMultipleSelector);
export default ShopFilterMultipleSelector;
