import { Api } from './Api';
export class StatisticsApi {
    static client = Api.client('/api/Statistics');
    static getByDates(productStatisticsFilter) {
        return this.client.get('/GetByDates', { params: productStatisticsFilter });
    }
    static getByDatesExcel(productStatisticsFilter) {
        return '/api/Statistics/GetByDatesExcel/?fromDate=' +
            productStatisticsFilter.fromDate + '&toDate=' +
            productStatisticsFilter.toDate;
    }
}
