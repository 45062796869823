var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import InlineDeleteButton from 'components/shared/InlineDeleteButton.vue';
import InputNumber from 'components/shared/InputNumber.vue';
import { Service } from "services/Service";
import { CampaignPlanningLineVM } from 'models/campaignPlanning/CampaignPlanningLineVM';
import { CampaignPlanningLineCRUD } from 'models/campaignPlanning/CampaignPlanningLineCRUD';
import { ModalService } from 'modules/basic-core/basic-modal/src';
import CampaignPlanningLineModal from '../modals/CampaignPlanningLineModal.vue';
import IconTrash from 'components/shared/IconTrash.vue';
import { CampaignPlanningLineStatus, CampaignPlanningLineWaitingForStatus } from 'models/campaignPlanning/CampaignPlanningLineStatus';
import { CampaignPlanningVM } from 'models/campaignPlanning/CampaignPlanningVM';
import { EventTypes } from 'models/EventTypes';
let CampaignPlanningLine = class CampaignPlanningLine extends Vue {
    campaign = new CampaignPlanningVM();
    campaignLine;
    CampaignPlanningLineStatus = CampaignPlanningLineStatus;
    CampaignPlanningLineWaitingForStatus = CampaignPlanningLineWaitingForStatus;
    mounted() {
        this.campaign = this.$store.state.VendorCampaignPlanning.selectedCampaign;
    }
    focusNextQuantity() {
        Service.focusNextOnEnter(this.$refs['inputQuantityParent'], 'input.item-amount-input[tabindex="1"]', 'inputQuantity');
    }
    focusNextPercentageWasted() {
        Service.focusNextOnEnter(this.$refs['inputPercentageWastedParent'], 'input.item-amount-input[tabindex="2"]', 'inputPercentageWasted');
    }
    // deleteLine() {
    //     LoaderService.createLoader(null);
    //     this.$store.dispatch('VendorCampaignPlanning/deleteCampaignPlanningLine', this.campaignLine).then((res: CampaignPlanningLineVM) => {
    //         ToastService.success(Vue.filter('translate')('webshop_vendor_campaignPlanning_campaignLines_successfulDeleteMsg', this.campaignLine.description));
    //     }).catch( () => {
    //         ToastService.danger(Vue.filter('translate')('webshop_defaults_toast_errorText'));
    //     }).finally(() => {
    //         LoaderService.stop();
    //     });
    // }
    editLine() {
        let data = new CampaignPlanningLineCRUD();
        data.campaign = this.campaign;
        data.campaignLine = Object.assign(new CampaignPlanningLineVM(), this.campaignLine);
        data.isNew = false;
        ModalService.create({
            cxt: this,
            component: CampaignPlanningLineModal,
            maxWidth: "720px",
            data: data
        });
    }
    get isValidPurchaseDeadline() {
        let currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 3);
        return new Date(this.campaign.purchaseStartingDate) > currentDate;
    }
    get disabledByLineStatus() {
        let lineStatus = this.campaignLine.status;
        return lineStatus != CampaignPlanningLineStatus.NewLine;
    }
    showComment() {
        this.$root.$emit(EventTypes.ShowComment, this.campaignLine);
    }
    changeStatusToWaitingNoOne() {
        if (this.campaignLine.waitingFor == CampaignPlanningLineWaitingForStatus.WaitingForVendor) {
            const campaignLine = Object.assign(new CampaignPlanningVM(), this.campaignLine);
            campaignLine.waitingFor = CampaignPlanningLineWaitingForStatus.WaitingForNoOne;
            this.$store.dispatch('VendorCampaignPlanning/changeCampaignPlanningLineWaitingFor', [campaignLine]);
        }
    }
};
CampaignPlanningLine = __decorate([
    Component({
        components: {
            InlineDeleteButton,
            InputNumber,
            IconTrash
        },
        props: {
            campaignLine: Object
        }
    })
], CampaignPlanningLine);
export default CampaignPlanningLine;
