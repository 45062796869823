var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { EventTypes } from 'models/EventTypes';
import { PermissionService } from 'services/PermissionService';
import { PermissionLevel } from 'models/user/PermissionLevel';
import { PriceType } from 'models/product/PriceType';
import ShopItemIcons from "components/shop/partials/ShopItemIcons.vue";
import InputNumber from "components/shared/InputNumber.vue";
import HighlightMarkings from "components/shared/HighlightMarkings.vue";
let InventoryProductResultPartial = class InventoryProductResultPartial extends Vue {
    result;
    showSearchFilter;
    isAllSelected = false;
    showPrice = PermissionService.hasPermission(PermissionLevel.ShowPrice);
    priceType = PriceType;
    filteredLines = [];
    searchTimeoutId;
    mounted() {
        this.filteredLines = this.result;
    }
    getSelectedUnit(line) {
        var units = line.units;
        if (!units)
            return line.unit;
        return units.find(x => x.name == line.unit);
    }
    changeUnit(line) {
        line.pricePrUnit = line.units.find(x => x.name == line.unit).price;
    }
    toggleSelection(line) {
        let selectedLines;
        let allSelectedLines;
        line._selected = !line._selected;
        selectedLines = this.filteredLines.filter(line => line._selected);
        this.isAllSelected = selectedLines.length === this.filteredLines.length;
        allSelectedLines = this.result.filter(x => x._selected);
        this.$root.$emit(EventTypes.InventoryModalProductSelected, allSelectedLines);
    }
    toggleAllLines() {
        let allSelectedLines;
        this.isAllSelected = !this.isAllSelected;
        this.filteredLines.forEach(line => line._selected = this.isAllSelected);
        allSelectedLines = this.result.filter(x => x._selected);
        this.$root.$emit(EventTypes.InventoryModalProductSelected, allSelectedLines);
    }
    priceTypeCampaign(line) {
        return line.units != null && line.units.find(x => x.priceType == this.priceType.CampaignPrice) != undefined;
    }
    clickItemIcons(evt) {
        evt.stopPropagation();
    }
    searchFilter(evt) {
        if (this.searchTimeoutId)
            clearTimeout(this.searchTimeoutId);
        this.searchTimeoutId = setTimeout(() => {
            if (evt.srcElement.value && evt.srcElement.value.length > 1)
                this.filteredLines = this.result.filter(x => x.description.toLocaleLowerCase().search(evt.srcElement.value.toLowerCase()) >= 0);
            else
                this.filteredLines = this.result;
            this.isAllSelected = this.filteredLines.length == this.filteredLines.filter(x => x._selected).length;
        }, 700);
    }
};
InventoryProductResultPartial = __decorate([
    Component({
        components: {
            ShopItemIcons,
            InputNumber,
            HighlightMarkings
        },
        props: {
            result: Array,
            showSearchFilter: Boolean,
        }
    })
], InventoryProductResultPartial);
export default InventoryProductResultPartial;
