var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.displayOptions.length > 21
      ? _c("div", { staticClass: "internal-filter" }, [
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.input[this.inputProperty] &&
                    _vm.input[this.inputProperty].length > 0,
                  expression:
                    "input[this.inputProperty] && input[this.inputProperty].length > 0",
                },
              ],
              staticClass: "ui-button ui-button-small button-remove-filter",
              attrs: { type: "button" },
              on: { click: _vm.removeFilter },
            },
            [
              _vm._v(
                _vm._s(
                  _vm._f("translate")(
                    "webshop_search_search_filters_removeFilter"
                  )
                )
              ),
            ]
          ),
          _vm._v(" "),
          _c("label", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.filterValue,
                  expression: "filterValue",
                },
              ],
              attrs: {
                type: "text",
                autocomplete: "off",
                placeholder: "Filtrera",
              },
              domProps: { value: _vm.filterValue },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.filterValue = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "spacer" }),
          _vm._v(" "),
          _vm.selectedDisplayOptions.length > 0
            ? _c("div", [
                _c("b", [
                  _vm._v(
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_search_search_filters_chosen"
                      )
                    )
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  _vm._l(_vm.selectedDisplayOptions, function (option) {
                    return _c(
                      "li",
                      {
                        key: option.value,
                        on: {
                          click: function ($event) {
                            return _vm.select(option)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "mdi",
                          class: {
                            "mdi-checkbox-marked-outline": option.selected,
                            "mdi-checkbox-blank-outline": !option.selected,
                          },
                        }),
                        _vm._v(
                          "\n          " + _vm._s(option.label) + "\n          "
                        ),
                        _c("span", [_vm._v("(" + _vm._s(option.amount) + ")")]),
                        _vm._v(" "),
                        option.icon
                          ? _c("i", {
                              staticClass: "selector-icon",
                              class: option.icon,
                            })
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("hr"),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "content" }, [
      _c(
        "button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.displayOptions.length <= 21 &&
                _vm.input[this.inputProperty] &&
                _vm.input[this.inputProperty].length > 0,
              expression:
                "displayOptions.length <= 21 && input[this.inputProperty] && input[this.inputProperty].length > 0",
            },
          ],
          staticClass: "ui-button ui-button-small button-remove-filter",
          attrs: { type: "button" },
          on: { click: _vm.removeFilter },
        },
        [
          _vm._v(
            _vm._s(
              _vm._f("translate")("webshop_search_search_filters_removeFilter")
            )
          ),
        ]
      ),
      _vm._v(" "),
      _vm.filteredDisplayOptions.length > 0
        ? _c("div", [
            _c(
              "ul",
              [
                _vm._l(_vm.filteredLines, function (option) {
                  return _c(
                    "li",
                    {
                      key: option.value,
                      on: {
                        click: function ($event) {
                          return _vm.select(option)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "mdi",
                        class: {
                          "mdi-checkbox-marked-outline": option.selected,
                          "mdi-checkbox-blank-outline": !option.selected,
                        },
                      }),
                      _vm._v(
                        "\n          " + _vm._s(option.label) + "\n          "
                      ),
                      _c("span", [_vm._v("(" + _vm._s(option.amount) + ")")]),
                      _vm._v(" "),
                      option.icon
                        ? _c("i", {
                            staticClass: "selector-icon",
                            class: option.icon,
                          })
                        : _vm._e(),
                    ]
                  )
                }),
                _vm._v(" "),
                _vm.filteredDisplayOptions.length > _vm.filteredLines.length
                  ? _c(
                      "li",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.showMoreLines()
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("translate")(
                              "webshop_search_search_filters_showMore"
                            )
                          )
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ])
        : _c("div", [
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm._f("translate")("webshop_search_search_filters_noresult")
                )
              ),
            ]),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }