var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import InventoryTableGroup from './InventoryTableGroup.vue';
import { EventTypes } from 'models/EventTypes';
import { InventoryGroup } from 'models/inventory/InventoryGroup';
let InventoryTable = class InventoryTable extends Vue {
    list;
    loading;
    setLoading;
    get groupLess() {
        var group = new InventoryGroup();
        group.lines = this.list.linesNotInGroup;
        group.totalPrice = this.list.totalPriceLinesNotInGroup;
        return group;
    }
    openAddProduct() {
        this.$root.$emit(EventTypes.InventoryModalProductOpen, this.list);
    }
};
InventoryTable = __decorate([
    Component({
        components: {
            InventoryTableGroup
        },
        props: {
            list: Object,
            loading: Boolean,
            setLoading: Function
        }
    })
], InventoryTable);
export default InventoryTable;
