var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { AuthService } from 'services/AuthService';
import DeliveryDatePicker from '../shared/DeliveryDatePicker.vue';
let DeliveryDateModal = class DeliveryDateModal extends Vue {
    loadedTimeout = false;
    deliveryDates = null;
    showError = false;
    mounted() {
        AuthService.getDeliveryDates().then(data => {
            this.deliveryDates = data;
        }).catch((err) => {
            this.showError = true;
        });
    }
};
DeliveryDateModal = __decorate([
    Component({
        components: {
            DeliveryDatePicker
        }
    })
], DeliveryDateModal);
export default DeliveryDateModal;
