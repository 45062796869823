var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EventTypes } from 'models/EventTypes';
import { ItemStatus } from 'models/product/ItemStatus';
import { PermissionLevel } from 'models/user/PermissionLevel';
import { FavoriteService } from 'services/FavoriteService';
import { PermissionService } from 'services/PermissionService';
import { ToastService } from 'services/ToastService';
import Vue from 'vue';
import Component from 'vue-class-component';
import Loader from 'components/utility/Loader.vue';
let ShopFavoriteListSortModal = class ShopFavoriteListSortModal extends Vue {
    data;
    listLines = [];
    transferLineIndex = null;
    transferLine = null;
    lastLineIndex = null;
    itemStatus = ItemStatus;
    loading = false;
    disableLines = false;
    showPrice = PermissionService.hasPermission(PermissionLevel.ShowPrice);
    loadListLines() {
        this.loading = true;
        FavoriteService.getFavoriteListLines(this.data.id).then((res) => {
            this.listLines = res;
        }).finally(() => {
            this.loading = false;
        });
    }
    save() {
        this.loading = true;
        this.listLines.forEach((line, indexLine) => {
            line.sorting = indexLine + 1;
        });
        FavoriteService.sortFavoriteListLines(this.data.id, this.listLines).then((response) => {
            ToastService.success(Vue.filter("translate")("webshop_inventory_table_product_toasts_updatedSorting"));
            this.$root.$emit(EventTypes.ShopSearchRefresh);
        }).catch(() => {
            ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
            this.loading = false;
        }).finally(() => {
            this.$emit('close-modal');
        });
    }
    sortByName() {
        var copyList = this.listLines;
        copyList.sort((a, b) => a.description.localeCompare(b.description));
    }
    close() {
        this.$emit('close-modal');
    }
    mounted() {
        this.loadListLines();
    }
    allowDrop(event) {
        if (this.disableLines) {
            event.stopPropagation();
        }
        else {
            event.preventDefault();
        }
    }
    // DragDrop events
    drag(index) {
        this.transferLineIndex = index;
        this.transferLine = this.listLines[index];
    }
    drop(index) {
        this.listLines.splice(index, 1);
        this.listLines.splice(this.lastLineIndex, 0, this.transferLine);
        this.transferLineIndex = null;
        this.lastLineIndex = null;
    }
    over(index) {
        if (this.lastLineIndex !== index) {
            this.lastLineIndex = index;
        }
    }
    leave() {
        this.lastLineIndex = null;
    }
};
ShopFavoriteListSortModal = __decorate([
    Component({
        props: {
            data: Object
        },
        components: {
            Loader
        }
    })
], ShopFavoriteListSortModal);
export default ShopFavoriteListSortModal;
