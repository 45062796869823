var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import ShopProductListItem from "components/shop/partials/ShopProductListItem.vue";
import { ProductService } from "services/ProductService";
import { OrderlineUISourceTypes } from 'models/order/OrderlineUISourceTypes';
import { EventTypes } from "models/EventTypes";
import { SearchService } from "services/SearchService";
import { ItemStatus } from "models/product/ItemStatus";
let ShopFoodInfoModal = class ShopFoodInfoModal extends Vue {
    data;
    link = null;
    product;
    loading = false;
    OrderlineUISourceTypes = OrderlineUISourceTypes;
    SearchService = SearchService;
    ItemStatus = ItemStatus;
    mounted() {
        this.loading = true;
        ProductService.getProduct(this.data)
            .then((res) => {
            this.link = res.markings.productLink;
            this.product = res;
            this.loading = false;
        });
        this.$root.$on(EventTypes.MiniCartUpdate, this.close);
    }
    beforeDestroy() {
        this.$root.$off(EventTypes.MiniCartUpdate, this.close);
    }
    close() {
        this.$emit("close-modal");
    }
    get showProduct() {
        return !SearchService.isOpenShop && this.product &&
            !(this.product.blocked || this.product.saleBlocked || this.product.obsoleteProduct || this.product.status === ItemStatus.Deleted);
    }
};
ShopFoodInfoModal = __decorate([
    Component({
        components: {
            ShopProductListItem
        },
        props: {
            data: String
        }
    })
], ShopFoodInfoModal);
export default ShopFoodInfoModal;
