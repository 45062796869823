var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.itemsWithAmount && _vm.itemsWithAmount.length > 0
    ? _c("shop-multiple-products-footer", {
        attrs: { itemsWithAmount: _vm.itemsWithAmount },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }