var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.order.orderlines && _vm.order.orderlines.length > 0
        ? [
            _c("div", { staticClass: "ui-card shop-orderpart-wrapper" }, [
              _c(
                "div",
                { staticClass: "ui-card-header ui-card-header-inverted" },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm._f("translate")("webshop_cart_deposit_header"))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "ui-card-header-actions" }, [
                    _c(
                      "a",
                      {
                        staticClass: "action shop-order-emptyCart",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handleEmptyBasket()
                          },
                        },
                      },
                      [
                        _c("icon-trash", {
                          attrs: {
                            "class-name": "svg-trash-fixed-with polygon-white",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ui-card-content ui-card-content-nopadding" },
                [
                  _c("div", { staticClass: "div-table orderline-table" }, [
                    _c("div", { staticClass: "table-header" }, [
                      _c("div", { staticClass: "table-row" }, [
                        _c(
                          "div",
                          { staticClass: "table-column product-id-column" },
                          [
                            _vm._v(
                              "\r\n                                " +
                                _vm._s(
                                  _vm._f("translate")(
                                    "webshop_general_productNo"
                                  )
                                ) +
                                "\r\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "table-column description-column" },
                          [
                            _vm._v(
                              "\r\n                                " +
                                _vm._s(
                                  _vm._f("translate")(
                                    "webshop_general_productDescription"
                                  )
                                ) +
                                "\r\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "table-column stock-column" },
                          [
                            _vm._v(
                              "\r\n                                " +
                                _vm._s(
                                  _vm._f("translate")(
                                    "webshop_general_stockBalance"
                                  )
                                ) +
                                "\r\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "table-column column-group price-column-group",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "table-column quantity-column" },
                              [
                                _vm._v(
                                  "\r\n                                    " +
                                    _vm._s(
                                      _vm._f("translate")(
                                        "webshop_general_amount"
                                      )
                                    ) +
                                    "\r\n                                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "table-column unit-column" },
                              [
                                _vm._v(
                                  "\r\n                                    " +
                                    _vm._s(
                                      _vm._f("translate")(
                                        "webshop_general_unit"
                                      )
                                    ) +
                                    "\r\n                                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "table-column price-column" },
                              [
                                _vm._v(
                                  "\r\n                                    " +
                                    _vm._s(
                                      _vm._f("translate")(
                                        "webshop_general_unitPrice"
                                      )
                                    ) +
                                    "\r\n                                "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "table-column actions-column",
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "table-body" },
                      [
                        !!_vm.order.orderlines &&
                        _vm.order.orderlines.length > 0
                          ? _vm._l(_vm.order.orderlines, function (orderline) {
                              return _c("basket-orderline", {
                                key: orderline.lineNo,
                                attrs: {
                                  orderline: orderline,
                                  "delivery-date": _vm.order.deliveryDate,
                                },
                              })
                            })
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "table-footer" }, [
                      _c("div", { staticClass: "table-row" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "table-column order-part-actions-column",
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "right ui-button ui-button-small",
                                on: {
                                  click: _vm.openBasketDepositProductsModal,
                                },
                              },
                              [
                                _c("i", { staticClass: "mdi mdi-plus" }),
                                _vm._v(
                                  "\r\n                                    " +
                                    _vm._s(
                                      _vm._f("translate")(
                                        "webshop_deposit_modal_toggle"
                                      )
                                    ) +
                                    "\r\n                                "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "break-medium" }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "table-column column-group price-column-group",
                          },
                          [
                            _c("div", { staticClass: "table-column" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "table-column quantity-column" },
                              [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("translate")(
                                        "webshop_general_total"
                                      )
                                    ) + ":"
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "table-column price-column" },
                              [
                                _c("strong", [
                                  _vm.showPrice
                                    ? _c("span", {}, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(
                                              _vm.order.totalPrice
                                            )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "table-column actions-column",
                        }),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }