var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "own-product-form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit()
        },
      },
    },
    [
      _c("fieldset", [
        _c("div", { staticClass: "field" }, [
          _c("label", [
            _vm._v(_vm._s(_vm._f("translate")("webshop_general_name"))),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model.description,
                expression: "model.description",
              },
            ],
            staticClass: "slide-input counter",
            attrs: {
              type: "text",
              placeholder: "",
              required: "",
              maxlength: "50",
              disabled: _vm.disabled,
            },
            domProps: { value: _vm.model.description },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.model, "description", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "slide-input-counter" }, [
            _vm._v(
              _vm._s(
                _vm.model.description ? 50 - _vm.model.description.length : "50"
              )
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c("label", [
            _vm._v(_vm._s(_vm._f("translate")("webshop_general_brand"))),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model.brand,
                expression: "model.brand",
              },
            ],
            staticClass: "slide-input counter",
            attrs: {
              type: "text",
              placeholder: "",
              maxlength: "30",
              disabled: _vm.disabled,
            },
            domProps: { value: _vm.model.brand },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.model, "brand", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "slide-input-counter" }, [
            _vm._v(
              _vm._s(_vm.model.brand ? 30 - _vm.model.brand.length : "30")
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c("label", [
            _vm._v(_vm._s(_vm._f("translate")("webshop_general_productGroup"))),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model.postingGroup,
                expression: "model.postingGroup",
              },
            ],
            staticClass: "slide-input counter",
            attrs: {
              type: "text",
              placeholder: "",
              maxlength: "50",
              disabled: _vm.disabled,
            },
            domProps: { value: _vm.model.postingGroup },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.model, "postingGroup", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "slide-input-counter" }, [
            _vm._v(
              _vm._s(
                _vm.model.postingGroup
                  ? 50 - _vm.model.postingGroup.length
                  : "50"
              )
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c("label", [
            _vm._v(_vm._s(_vm._f("translate")("webshop_general_unit"))),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.model.unit,
                  expression: "model.unit",
                },
              ],
              staticClass: "slide-input",
              attrs: { required: "", disabled: _vm.disabled },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.model,
                    "unit",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c("option", { domProps: { value: null } }),
              _vm._v(" "),
              _vm._l(_vm.units, function (unit) {
                return _c(
                  "option",
                  { key: unit.name, domProps: { value: unit.name } },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm._f("unitDescription")(unit)) +
                        "\n                "
                    ),
                  ]
                )
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c(
            "div",
            { staticClass: "field amount-field" },
            [
              _c("label", [
                _vm._v(_vm._s(_vm._f("translate")("webshop_general_quantity"))),
              ]),
              _vm._v(" "),
              _c("input-number", {
                attrs: {
                  inputClass: "slide-input",
                  inputDisabled: _vm.disabled,
                  autocomplete: "off",
                  inputStep: 0.001,
                  "select-onfocus": "true",
                },
                model: {
                  value: _vm.model.amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.model, "amount", $$v)
                  },
                  expression: "model.amount",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "field price-field" }, [
            _c("label", [
              _vm._v(_vm._s(_vm._f("translate")("webshop_general_unitPrice"))),
            ]),
            _vm._v(" "),
            _vm.showPrice
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formattedPrice,
                      expression: "formattedPrice",
                    },
                  ],
                  staticClass: "slide-input",
                  attrs: {
                    type: "text",
                    placeholder: "",
                    required: "",
                    disabled: _vm.disabled,
                  },
                  domProps: { value: _vm.formattedPrice },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.formattedPrice = $event.target.value
                    },
                  },
                })
              : _c("input", {
                  staticClass: "slide-input",
                  attrs: {
                    type: "text",
                    placeholder: "",
                    required: "",
                    disabled: _vm.disabled,
                  },
                }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c("label", [
            _vm._v(_vm._s(_vm._f("translate")("webshop_general_groupOnList"))),
          ]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.model.groupId,
                  expression: "model.groupId",
                },
              ],
              staticClass: "slide-input",
              attrs: { disabled: _vm.disabled },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.model,
                    "groupId",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
              },
            },
            [
              _c("option", { domProps: { value: null } }, [
                _vm._v(
                  _vm._s(_vm._f("translate")("webshop_general_groupOutside"))
                ),
              ]),
              _vm._v(" "),
              _vm._l(_vm.list.groups, function (group) {
                return _c(
                  "option",
                  { key: group.id, domProps: { value: group.id } },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(group.name) +
                        "\n                "
                    ),
                  ]
                )
              }),
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-footer-buttons no-button-margin-top" }, [
        _vm.line
          ? _c(
              "button",
              {
                staticClass: "ui-button ui-button-small",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.cancel()
                  },
                },
              },
              [_vm._v(_vm._s(_vm._f("translate")("webshop_general_cancel")))]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "ui-button ui-button-small ui-button-accept",
            class: _vm.saveBtnClass,
            attrs: { type: "submit" },
          },
          [
            _vm._v(
              _vm._s(_vm.saveBtnText ? _vm.saveBtnText : _vm.altSaveBtnText)
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }