var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import OrderModal from './partials/OrderModal.vue';
import { EventTypes } from 'models/EventTypes';
import { Order } from '../../models/order/Order';
let OrdersModal = class OrdersModal extends Vue {
    order = null;
    deleteOrder = false;
    showOrderModal = false;
    confirmModalSettings;
    created() {
        this.$root.$on(EventTypes.OrdersOrderModal, this.openOrderModal);
        this.$root.$on(EventTypes.OrdersOrderModalClose, this.onCloseOrderModal);
    }
    beforeDestroy() {
        this.$root.$off(EventTypes.OrdersOrderModal, this.openOrderModal);
        this.$root.$off(EventTypes.OrdersOrderModalClose, this.onCloseOrderModal);
    }
    openOrderModal(order, deleteOrder) {
        this.order = order ? Object.assign(new Order(), order) : null;
        this.deleteOrder = deleteOrder;
        this.showOrderModal = true;
    }
    onCloseOrderModal() {
        this.order = null;
        this.deleteOrder = false;
        this.showOrderModal = false;
    }
};
OrdersModal = __decorate([
    Component({
        components: {
            OrderModal
        }
    })
], OrdersModal);
export default OrdersModal;
