import { Service } from './Service';
import { PdfReportApi } from 'api/PdfReportApi';
export class PdfReportService extends Service {
    static getAlcoholReport(fromDate, toDate) {
        return new Promise((resolve, reject) => {
            PdfReportApi.getAlcoholReport(fromDate, toDate).then((res) => {
                resolve(res.data);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static getFavoriteList(listId) {
        return new Promise((resolve, reject) => {
            PdfReportApi.getFavoriteList(listId).then((res) => {
                resolve(res.data);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static printTopN(topN) {
        return new Promise((resolve, reject) => {
            PdfReportApi.printTopN(topN).then((res) => {
                resolve(res.data);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static getInventoryList(listId, countingsheet) {
        return new Promise((resolve, reject) => {
            PdfReportApi.getInventoryList(listId, countingsheet).then((res) => {
                resolve(res.data);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static printAllLists(countingsheet, listIds) {
        return new Promise((resolve, reject) => {
            PdfReportApi.printAllLists(countingsheet, listIds).then((res) => {
                resolve(res.data);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static printInventoryArchive(archiveId) {
        return new Promise((resolve, reject) => {
            PdfReportApi.printInventoryArchive(archiveId).then((res) => {
                resolve(res.data);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static printCalculations(calculationIds) {
        return new Promise((resolve, reject) => {
            PdfReportApi.printCalculations(calculationIds).then((res) => {
                resolve(res.data);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static printCalculationRecipes(calculationIds, numberOfPortions, showSubDescription = true) {
        return new Promise((resolve, reject) => {
            PdfReportApi.printCalculationRecipes(calculationIds, numberOfPortions, showSubDescription).then((res) => {
                resolve(res.data);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
}
