var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ui-modal-content" }, [
      _vm.data.deleteOrder
        ? _c("h3", { staticClass: "ui-modal-header" }, [
            _vm._v(
              _vm._s(
                _vm._f("translate")("webshop_orders_modals_order_titleDelete")
              )
            ),
          ])
        : _vm.data.order
        ? _c("h3", { staticClass: "ui-modal-header" }, [
            _vm._v(
              _vm._s(
                _vm._f("translate")("webshop_orders_modals_order_titleEdit")
              )
            ),
          ])
        : _c("h3", { staticClass: "ui-modal-header" }, [
            _vm._v(
              _vm._s(
                _vm._f("translate")("webshop_orders_modals_order_titleNew")
              )
            ),
          ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.loading
            ? [_c("loader", { attrs: { size: "large" } })]
            : [
                _c(
                  "form",
                  {
                    staticClass: "ui-form",
                    attrs: { name: "orderModal", novalidate: "" },
                    on: {
                      submit: function ($event) {
                        return $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "ui-form-fieldwrapper" }, [
                      _c("label", { attrs: { for: "name" } }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("translate")(
                                "webshop_orders_result_columnNames_name"
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.name,
                              expression: "name",
                            },
                          ],
                          staticClass: "input-orders",
                          attrs: {
                            type: "text",
                            maxlength: "50",
                            autocomplete: "off",
                            name: "name",
                            disabled: _vm.data.deleteOrder,
                          },
                          domProps: { value: _vm.name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.name = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-buttons-wrapper" }, [
                      !_vm.data.order
                        ? _c(
                            "button",
                            {
                              staticClass: "ui-button ui-button-accept",
                              attrs: { disabled: _vm.loading },
                              on: {
                                click: function ($event) {
                                  return _vm.create()
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("translate")(
                                    "webshop_orders_buttons_saveOrder"
                                  )
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.data.deleteOrder
                        ? _c(
                            "button",
                            {
                              staticClass: "ui-button ui-button-cancel",
                              attrs: { disabled: _vm.loading },
                              on: {
                                click: function ($event) {
                                  return _vm.remove()
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("translate")(
                                    "webshop_orders_buttons_deleteOrder"
                                  )
                                )
                              ),
                            ]
                          )
                        : _vm.data.order
                        ? _c(
                            "button",
                            {
                              staticClass: "ui-button ui-button-accept",
                              attrs: { disabled: _vm.loading },
                              on: {
                                click: function ($event) {
                                  return _vm.update()
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("translate")(
                                    "webshop_orders_buttons_saveOrder"
                                  )
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]
                ),
              ],
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }