var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import VueRouter from "vue-router";
import Component from "vue-class-component";
import { EventTypes } from "../../models/EventTypes";
import { ProductStatisticsFilter } from "../../models/statistics/ProductStatisticsFilter";
import { StatisticsService } from '../../services/StatisticService';
import StatisticsFilter from './StatsticsFilter.vue';
import StatisticsResult from './result/StatisticsResult.vue';
import { ToastService } from "services/ToastService";
const routes = [{
        name: "statistics",
        path: "/webbshop/statistics"
    }];
const router = new VueRouter({
    mode: "history",
    routes: routes
});
let Statistics = class Statistics extends Vue {
    loading = false;
    products = [];
    productStatisticsFilter = new ProductStatisticsFilter();
    created() {
        this.$root.$on(EventTypes.ProductStatisticsLoad, this.getArticlesResult);
    }
    beforeDestroy() {
        this.$root.$off(EventTypes.ProductStatisticsLoad);
    }
    getArticlesResult(productStatisticsFilter) {
        this.productStatisticsFilter = new ProductStatisticsFilter(productStatisticsFilter.fromDate, productStatisticsFilter.toDate);
        this.loading = true;
        StatisticsService.getArticlesByDates(productStatisticsFilter)
            .then((response) => {
            this.products = response;
            this.loading = false;
        })
            .catch(err => {
            this.loading = false;
            ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
        });
    }
};
Statistics = __decorate([
    Component({
        components: {
            StatisticsFilter,
            StatisticsResult
        },
        router: router
    })
], Statistics);
export default Statistics;
