var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import InventoryTableProduct from "./InventoryTableProduct.vue";
import { ToastService } from "services/ToastService";
import { InventoryService } from "services/InventoryService";
import { EventTypes } from "models/EventTypes";
import { ModalSettings } from "models/utility/ModalSettings";
import IconTrash from '../shared/IconTrash.vue';
let InventoryTableGroup = class InventoryTableGroup extends Vue {
    group;
    list;
    filter = "";
    loading;
    setLoading;
    editGroup = {
        active: false,
        newGroupName: ""
    };
    get lines() {
        let lines = this.group.lines;
        if (this.filter) {
            let filter = this.filter.toLowerCase();
            const searchIncludes = (prop, query) => {
                if (!prop)
                    return false;
                return prop.toLowerCase().includes(query);
            };
            lines = this.group.lines.filter(line => {
                return (searchIncludes(line.productId, filter) ||
                    searchIncludes(line.description, filter) ||
                    searchIncludes(line.brand, filter) ||
                    searchIncludes(line.unit, filter));
            });
        }
        return lines;
    }
    created() {
        this.$root.$on(EventTypes.InventorySearchChanged, this.searchFilterChanged);
    }
    beforeDestroy() {
        this.$root.$off(EventTypes.InventorySearchChanged, this.searchFilterChanged);
    }
    mounted() { }
    searchFilterChanged(query) {
        this.filter = query;
    }
    toggleEdit() {
        this.editGroup.active = !this.editGroup.active;
        this.editGroup.newGroupName = this.group.name;
    }
    openDeleteConfirm() {
        this.$root.$emit(EventTypes.InventoryModalConfirmOpen, new ModalSettings({
            title: Vue.filter("translate")("webshop_inventory_table_group_deleteModal_title", this.group.name),
            content: "<p>" +
                Vue.filter("translate")("webshop_inventory_table_group_deleteModal_content", this.group.name, Vue.filter("translate")("webshop_general_groupOutside")) +
                "</p>",
            cancelButtonText: Vue.filter("translate")("webshop_general_cancel"),
            acceptButtonText: Vue.filter("translate")("webshop_general_delete"),
            acceptButtonClass: "ui-button-cancel",
            onAccept: () => {
                return InventoryService.deleteGroup(this.group.id)
                    .then(() => {
                    ToastService.success(Vue.filter("translate")("webshop_inventory_table_group_deleteModal_toastDeleted"));
                    this.$root.$emit(EventTypes.InventoryReloadList, this.list.id);
                    this.$root.$emit(EventTypes.InventoryModalConfirmClose);
                })
                    .catch(err => {
                    ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
                });
            }
        }));
    }
    save() {
        if (this.editGroup.newGroupName.length > 1) {
            this.setLoading(true);
            InventoryService.updateGroup(this.group.id, this.editGroup.newGroupName, this.group.sorting)
                .then(group => {
                this.group.name = group.name;
                this.group.sorting = group.sorting;
                this.group.updated = group.updated;
                this.toggleEdit();
                this.setLoading(false);
                ToastService.success(Vue.filter("translate")("webshop_inventory_table_group_toasts_nameUpdated"));
            })
                .catch(err => {
                this.setLoading(false);
                if (err === 409) {
                    ToastService.danger(Vue.filter("translate")("webshop_inventory_table_group_toasts_nameExists", "<b>" + this.editGroup.newGroupName + "</b>"));
                }
                else {
                    ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
                }
            });
        }
        else {
            ToastService.danger(Vue.filter("translate")("webshop_inventory_table_group_toasts_emptyName"));
        }
    }
    moveGroup(up) {
        this.setLoading(true);
        let increase;
        let text;
        if (up) {
            increase = -1;
            text = Vue.filter("translate")("webshop_inventory_table_group_toasts_movedUp");
        }
        else {
            increase = 1;
            text = Vue.filter("translate")("webshop_inventory_table_group_toasts_movedDown");
        }
        var tempObj = Object.assign({}, this.group);
        tempObj.sorting += increase;
        InventoryService.updateGroup(tempObj.id, tempObj.name, tempObj.sorting)
            .then(group => {
            group.lines = tempObj.lines;
            this.setLoading(false);
            this.$root.$emit(EventTypes.InventoryGroupUpdated, group);
            ToastService.success(text);
        })
            .catch(err => {
            this.setLoading(false);
            ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
        });
    }
};
InventoryTableGroup = __decorate([
    Component({
        components: {
            InventoryTableProduct,
            IconTrash
        },
        props: {
            group: Object,
            list: Object,
            loading: Boolean,
            setLoading: Function
        }
    })
], InventoryTableGroup);
export default InventoryTableGroup;
