import Vue from 'vue';
Vue.directive('select-onfocus', {
    bind(el, bind, vnode, oldNode) {
        el.onfocus = () => {
            if (navigator.userAgent.toLowerCase().indexOf('edge') > -1) {
                setTimeout(() => {
                    el.select();
                }, 50);
            }
            else {
                el.select();
            }
        };
    }
});
