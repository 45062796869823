var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { ToastService } from "../../services/ToastService";
import { InventoryService } from "../../services/InventoryService";
import { EventTypes } from "models/EventTypes";
import IconTrash from "../shared/IconTrash.vue";
import { PermissionService } from "services/PermissionService";
import { PermissionLevel } from "models/user/PermissionLevel";
let InventoryPicker = class InventoryPicker extends Vue {
    showCreateNew = false;
    newListName = "";
    lists = [];
    loading;
    setLoading;
    showPrice = PermissionService.hasPermission(PermissionLevel.ShowPrice);
    created() {
        this.$root.$on(EventTypes.InventoryListUpdated, this.listUpdated);
        this.$root.$on(EventTypes.InventoryListDeleted, this.listDeleted);
        this.$root.$on(EventTypes.InventoryReloadAllLists, this.reloadAllLists);
    }
    beforeDestroy() {
        this.$root.$off(EventTypes.InventoryListUpdated, this.listUpdated);
        this.$root.$off(EventTypes.InventoryListDeleted, this.listDeleted);
        this.$root.$off(EventTypes.InventoryReloadAllLists, this.reloadAllLists);
    }
    mounted() {
        this.loadAllLists();
    }
    get selectedListId() {
        let id = parseInt(this.$route.params.id);
        if (isNaN(id))
            return null;
        return id;
    }
    reloadAllLists(currentListId) {
        this.loadAllLists().then(() => {
            if (currentListId)
                this.$root.$emit(EventTypes.InventoryReloadList, currentListId);
        });
    }
    loadAllLists() {
        this.setLoading(true);
        return InventoryService.getAllLists()
            .then(data => {
            this.lists = data;
            this.setLoading(false);
        })
            .catch(err => {
            this.setLoading(false);
            ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
        });
    }
    listUpdated(list) {
        const index = this.lists.findIndex(x => x.id === list.id);
        if (index !== -1) {
            list.totalValue = InventoryService.calTotalPrice(list);
            Vue.set(this.lists, index, list);
        }
    }
    toggleNew() {
        this.showCreateNew = !this.showCreateNew;
        this.newListName = !this.showCreateNew ? "" : this.newListName;
    }
    createNewList() {
        if (this.newListName.length > 1) {
            this.setLoading(true);
            InventoryService.createList(this.newListName)
                .then(newList => {
                this.lists.push(newList);
                this.toggleNew();
                this.$router.push({
                    name: "inventoryDetail",
                    params: { id: newList.id.toString() }
                });
                this.setLoading(false);
                ToastService.success(Vue.filter("translate")("webshop_inventory_picker_toastSuccess"));
            })
                .catch(err => {
                this.setLoading(false);
                if (err === 409) {
                    ToastService.danger(Vue.filter("translate")("webshop_inventory_picker_toastListExist", "<b>" + this.newListName + "</b>"));
                }
                else {
                    ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
                }
            });
        }
        else {
            ToastService.danger(Vue.filter("translate")("webshop_inventory_picker_toastNoName"));
        }
    }
    deleteList(list) {
        this.setLoading(true);
        InventoryService.deleteList(list.id)
            .then(() => {
            this.setLoading(false);
            this.$root.$emit(EventTypes.InventoryListDeleted, list);
        })
            .catch(err => {
            this.setLoading(false);
            ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
        });
    }
    listDeleted(list) {
        this.lists = this.lists.filter(x => x.id !== list.id);
        ToastService.success(Vue.filter("translate")("webshop_inventory_picker_toastListDeleted"));
        if (Number.parseInt(this.$route.params.id) === list.id)
            this.$router.replace({ name: "inventoryOverview" });
    }
    scrollToList() {
        this.$root.$emit(EventTypes.InventoryScrollToList);
    }
    get listsTotal() {
        if (this.lists && this.lists.length > 0) {
            return this.lists
                .map(x => x.totalValue)
                .reduce((total, num) => {
                return total + num;
            });
        }
        else {
            return 0;
        }
    }
};
InventoryPicker = __decorate([
    Component({
        components: {
            IconTrash
        },
        props: {
            descriptionText: String,
            descriptionTitle: String,
            loading: Boolean,
            setLoading: Function
        }
    })
], InventoryPicker);
export default InventoryPicker;
