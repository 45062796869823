var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.orderline.altOrderline
        ? [_vm._v("\n        " + _vm._s(_vm.message) + "\n    ")]
        : [
            _c("b", [_vm._v(_vm._s(_vm.message))]),
            _vm._v(" "),
            _c("p", { staticClass: "small" }, [
              _vm._v(
                _vm._s(
                  _vm._f("translate")(
                    "webshop_search_search_item_alternative_item_beneath"
                  )
                )
              ),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }