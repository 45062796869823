var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "ui-modal-content" },
      [
        _c("strong", [
          _vm._v(
            _vm._s(
              _vm.rated && _vm.commented
                ? _vm.$options.filters.translate(
                    "webshop_campaign_review_comment_submitResponseHeader"
                  )
                : _vm.data.CustomerName
            )
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticStyle: { "margin-top": "10px" } }, [
          _vm.rated && _vm.commented
            ? _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("translate")(
                      "webshop_campaign_review_comment_submitResponseText"
                    )
                  )
                ),
              ])
            : _c("span", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$options.filters.translate(
                      "webshop_campaign_review_text",
                      "<em>" + _vm.dateFormatted + "</em>",
                      "<strong>" +
                        _vm.data.ProductId +
                        " - " +
                        _vm.data.Description +
                        "</strong>"
                    )
                  ),
                },
              }),
        ]),
        _vm._v(" "),
        !_vm.rated && !_vm.commented
          ? _c("check-out-add-to-favorite-list-select", {
              attrs: { checkout: _vm.data },
            })
          : _vm._e(),
        _vm._v(" "),
        !_vm.rated
          ? _c("check-out-review-grade", {
              attrs: { checkout: _vm.data },
              on: {
                rated: function ($event) {
                  _vm.rated = true
                },
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.rated && !_vm.commented
          ? _c("check-out-comment", {
              attrs: { checkout: _vm.data },
              on: {
                commented: function ($event) {
                  _vm.commented = true
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }