var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal-wrapper",
      class: { "modal-wrapper-visible": _vm.showModal },
    },
    [
      _c(
        "transition",
        { attrs: { appear: "", name: "modal-overlay", tag: "div" } },
        [
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showModal && _vm.modals.length > 0,
                expression: "showModal && modals.length > 0",
              },
            ],
            staticClass: "modal-overlay",
            on: {
              click: function ($event) {
                if ($event.target !== $event.currentTarget) {
                  return null
                }
                return _vm.closeOverlay()
              },
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "transition-group",
        { attrs: { name: "modal", tag: "div" } },
        _vm._l(_vm.modals, function (modal) {
          return _c("modal", {
            key: modal.id,
            staticClass: "modal",
            style: { "max-width": modal.maxWidth, height: modal.height },
            attrs: { name: "modal" },
            inlineTemplate: {
              render: function () {
                var _vm = this
                var _h = _vm.$createElement
                var _c = _vm._self._c || _h
                return _c("div", [
                  _c("header", { staticClass: "modal-header" }, [
                    _c(
                      "a",
                      {
                        staticClass: "modal-close",
                        on: {
                          click: function ($event) {
                            return _vm.$parent.$parent.close(modal)
                          },
                        },
                      },
                      [_c("i", { staticClass: "mdi mdi-close-circle-outline" })]
                    ),
                  ]),
                  _vm._v(" "),
                  modal.content != null
                    ? _c("div", {
                        staticClass: "content",
                        staticStyle: { height: "100%" },
                        domProps: { innerHTML: _vm._s(modal.content) },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  modal.component != null
                    ? _c(
                        "div",
                        {
                          staticClass: "content",
                          staticStyle: { height: "100%" },
                        },
                        [
                          _c(
                            "keep-alive",
                            [
                              _c(modal.component, {
                                tag: "component",
                                attrs: { data: modal.data },
                                on: {
                                  "close-modal": function ($event) {
                                    return _vm.$parent.$parent.close(modal)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              },
              staticRenderFns: [],
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }