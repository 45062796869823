import { Api } from './Api';
export class FavoriteApi {
    static client = Api.client('/api/FavoriteLists');
    /*
        LIST
    */
    static getAllLists(productId) {
        let params = productId ? { productId: productId } : {};
        return this.client.get('/GetAll', {
            params: params
        });
    }
    static getFavoriteListLines(listId) {
        return this.client.get('/GetFavoriteListLines/' + listId);
    }
    static sortFavoriteListLines(id, lines) {
        return this.client.put('/SortFavoriteListLines/' + id, lines);
    }
    static addProduct(listId, productId, stdUnit, checkoutId) {
        var ajaxData = {
            listId: listId,
            productId: productId,
            stdUnit: stdUnit,
            checkoutId: checkoutId
        };
        return this.client.post('/AddProduct', ajaxData);
    }
    static addProducts(listId, products) {
        var ajaxData = {
            listId: listId,
            products: products
        };
        return this.client.post('/AddProducts', ajaxData);
    }
    static createList(listName, shared) {
        return this.client.post('/CreateFavoriteList', { listName: listName, shared: shared });
    }
    static createListFromHistory(listName, shared, from, to) {
        return this.client.post('/CreateFavoriteListFromHistory', {
            listName: listName,
            shared: shared,
            from: from,
            to: to
        });
    }
    static deleteProductFromList(listId, productId) {
        return this.client.delete('/DeleteProductFromList', { params: { id: listId, productId: productId } });
    }
    static replaceObsolete(oldId, newId) {
        return this.client.post('/ReplaceObsolete', { oldId: oldId, newId: newId });
    }
    static updateList(list) {
        return this.client.put('/UpdateFavoriteList', list);
    }
    static startStopSharing(list) {
        return this.client.put('/StartStopSharingFavoriteList', list);
    }
    static copyList(list) {
        return this.client.post('/CopyFavoriteList', list);
    }
    static createInventoryList(list) {
        return this.client.post('/CreateInventoryList', list);
    }
    static deleteList(listId) {
        return this.client.delete('/DeleteFavoriteList/' + listId);
    }
    static searchNewProductsBySales(fromDate, listId) {
        return this.client.get('/SearchNewProductsBySales', {
            params: {
                fromDate: fromDate,
                listId: listId
            }
        });
    }
    static searchNewProductsByQuery(query, listId) {
        return this.client.get('/SearchNewProductsByQuery', {
            params: {
                query: query,
                listId: listId
            }
        });
    }
}
