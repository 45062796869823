export class InventoryArchiveVM {
    id;
    name;
    created;
    customerNo;
    total;
    constructor(id = null) {
        this.id = id;
        this.name = null;
        this.created = null;
        this.customerNo = null;
        this.total = null;
    }
    static fromApi(list) {
        return Object.assign(new InventoryArchiveVM(), list);
    }
}
