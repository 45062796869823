var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c(
      "ul",
      { key: "renderCounter" + _vm.renderCounter },
      [
        _vm.selectedList
          ? _c(
              "li",
              {
                on: {
                  click: function ($event) {
                    return _vm.select(null)
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm._f("translate")("webshop_search_search_filters_goBack")
                  )
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.lists, function (list) {
          return _c(
            "li",
            {
              key: "favoriteList" + list.id,
              class: {
                active: _vm.selectedList && _vm.selectedList.id === list.id,
              },
              on: {
                click: function ($event) {
                  return _vm.select(list)
                },
              },
            },
            [
              _c("i", {
                staticClass: "mdi",
                class: {
                  "mdi-checkbox-marked-circle-outline":
                    _vm.selectedList && _vm.selectedList.id === list.id,
                  "mdi-checkbox-blank-circle-outline":
                    !_vm.selectedList || _vm.selectedList.id !== list.id,
                },
              }),
              _vm._v(
                "\r\n            " + _vm._s(list.listName) + "\r\n            "
              ),
              list.isShared
                ? _c("i", {
                    staticClass: "mdi mdi-share-variant",
                    attrs: {
                      title: _vm.$options.filters.translate(
                        "webshop_search_search_filters_iconShareTitle"
                      ),
                    },
                  })
                : _vm._e(),
            ]
          )
        }),
        _vm._v(" "),
        _vm.sharedLists && _vm.sharedLists.length > 0
          ? [
              _c("hr"),
              _vm._v(" "),
              _c("li", { staticClass: "favorite-lists-shared-title" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("translate")(
                      "webshop_search_search_filters_sharedTitle"
                    )
                  )
                ),
              ]),
              _vm._v(" "),
              _vm._l(_vm.sharedLists, function (list) {
                return _c(
                  "li",
                  {
                    key: "sharedFavoriteList" + list.id,
                    class: {
                      active:
                        _vm.selectedList && _vm.selectedList.id === list.id,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.select(list)
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "mdi",
                      class: {
                        "mdi-checkbox-marked-circle-outline":
                          _vm.selectedList && _vm.selectedList.id === list.id,
                        "mdi-checkbox-blank-circle-outline":
                          !_vm.selectedList || _vm.selectedList.id !== list.id,
                      },
                    }),
                    _vm._v("\r\n                " + _vm._s(list.listName)),
                  ]
                )
              }),
            ]
          : _vm._e(),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              staticClass: "create-favorite-list",
              on: {
                click: function ($event) {
                  return _vm.openCreateNew()
                },
              },
            },
            [
              _c("i", { staticClass: "mdi mdi-playlist-plus" }),
              _vm._v(
                "\r\n                " +
                  _vm._s(
                    _vm._f("translate")(
                      "webshop_search_search_options_favOption_createNew"
                    )
                  ) +
                  "\r\n            "
              ),
            ]
          ),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }