var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { CheckOutService } from "services/CheckOutService";
import { ToastService } from "services/ToastService";
import { OrderService } from "services/OrderService";
import { EventTypes } from 'models/EventTypes';
import { OrderlineUISourceTypes } from 'models/order/OrderlineUISourceTypes';
let CheckOutBasketRender = class CheckOutBasketRender extends Vue {
    checkoutvm;
    imageurl;
    declined = false;
    OrderlineUISourceTypes = OrderlineUISourceTypes;
    declineCheckout() {
        CheckOutService.declineCheckout(this.checkoutvm.id).then(res => {
            ToastService.success(Vue.filter("translate")("webshop_campaign_offer_decline_success"));
            this.declined = true;
        }, err => {
            ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
        });
    }
    addProductToBasket() {
        const basketProd = {
            amount: 1,
            productId: this.checkoutvm.productId,
            description: this.checkoutvm.title,
            expiryRef: null,
            unit: this.checkoutvm.unit,
            fromFavoriteList: false,
            list: null,
            checkoutId: this.checkoutvm.id,
            orderlineUISource: OrderlineUISourceTypes.BasketCheckOutList,
            searchPlacement: null,
            isFromSearch: false
        };
        OrderService.addSingleProductToBasket(basketProd)
            .then(res => {
            if (res.addedOrderLines > 0) {
                ToastService.success(Vue.filter("translate")("webshop_cart_add_toast_basketAdded", res.addedOrderLines));
                this.$root.$emit(EventTypes.MiniCartUpdate, res.previewOrder);
                // MiniCartService.updateMiniCart({
                //   count: res.previewOrder.itemCount,
                //   organicCount: res.previewOrder.ecoPct,
                //   agreementCount: res.previewOrder.agreementPct,
                //   noOfTooLate: res.previewOrder.noOfTooLate,
                //   noOfExpired: res.previewOrder.noOfExpired
                // });
                // TODO: Lav mere lækkert med animering i stedet, når hele kurven skal skrives om til vuejs.
                location.reload();
            }
            else {
                ToastService.danger(Vue.filter("translate")("webshop_cart_add_toast_errorText"), Vue.filter("translate")("webshop_cart_add_toast_errorHeader"));
            }
        })
            .catch(err => {
            ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
        });
    }
};
CheckOutBasketRender = __decorate([
    Component({
        props: {
            checkoutvm: Object,
            imageurl: String
        }
    })
], CheckOutBasketRender);
export default CheckOutBasketRender;
