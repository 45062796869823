export class Toast {
    id;
    content;
    type;
    duration;
    active;
    constructor(id, content, type, duration) {
        this.id = id;
        this.content = content;
        this.type = type;
        this.duration = duration;
        this.active = false;
    }
}
