import Vue from 'vue';
// directive allow only number
// example: <input v-number-format="'format'" />
// 'format' can be '[whole-size][.,][decimal-size]'
// if is whole-size equal to zero then whole size is unlimited, same for decimal-size
// more examples
// 'format' is 3.3, max number is 999.999 
// 'format' is 0.3, max number is unlimited.999
// 'format' is 3.0, max number is 999.unlimited
// 'format' is 3, max number is 999
Vue.directive('number-format', {
    bind(el, binding) {
        let numSizes = binding.value.split(".");
        let oldValue = "";
        ["input", "focusout"].forEach((eventType) => {
            el.addEventListener(eventType, () => {
                let pointIndex = el.value.indexOf('.');
                pointIndex = pointIndex >= 0 ? pointIndex : el.value.indexOf(',');
                // allow value [whole][.,][decimal]
                if (!/^-?\d*[.,]?\d*$/.test(el.value)) {
                    el.value = oldValue;
                }
                // limit on whole side
                if (numSizes[0] > 0) {
                    if (pointIndex >= 0 && pointIndex > numSizes[0]) {
                        el.value = oldValue;
                    }
                    if (pointIndex < 0 && el.value.length > numSizes[0]) {
                        el.value = oldValue;
                    }
                }
                // limit on decimal side
                if (pointIndex >= 0) {
                    console.log(!numSizes[1] || el.value.length - pointIndex > numSizes[1]);
                    if (numSizes[1] > 0 && el.value.length - pointIndex - 1 > numSizes[1]) {
                        el.value = oldValue;
                    }
                    if (numSizes[1] === undefined || numSizes[1] === null) {
                        el.value = oldValue;
                    }
                }
                oldValue = el.value;
            });
        });
    }
});
