var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-results-wrapper" },
    [
      _c("div", { attrs: { grid: "column" } }, [
        _c("div", { attrs: { column: "12" } }, [
          _c(
            "div",
            { staticClass: "section-productSearchResults" },
            [
              !!_vm.popularProducts && _vm.popularProducts.length > 0
                ? [
                    _vm.viewType == _vm.viewTypes.gallery
                      ? _c("shop-result-gallery-popular-products", {
                          attrs: {
                            loading: _vm.loading,
                            popularProducts: _vm.popularProducts,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.viewType == _vm.viewTypes.list
                      ? _c("shop-result-list-popular-products", {
                          attrs: {
                            loading: _vm.loading,
                            popularProducts: _vm.popularProducts,
                          },
                        })
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "action-group shop-productSearch-viewType" },
                [
                  _c("h2", [
                    _vm._v(
                      _vm._s(
                        _vm._f("translate")(
                          "webshop_search_search_view_list_headers_searchResult"
                        )
                      )
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.totalProducts
                ? _c("span", { staticClass: "counter" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("translate")(
                          "webshop_search_search_result_counter",
                          _vm.productsCounter.first,
                          _vm.productsCounter.last,
                          _vm.totalProducts
                        )
                      )
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "sort-by-wrapper" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedSortBy,
                        expression: "selectedSortBy",
                      },
                    ],
                    staticClass: "sort-by",
                    attrs: { disabled: _vm.loading },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selectedSortBy = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.changeSortBy,
                      ],
                    },
                  },
                  _vm._l(_vm.sortByFilters, function (sortBy) {
                    return _c(
                      "option",
                      { key: sortBy.value, domProps: { value: sortBy.value } },
                      [
                        _vm._v(
                          "\r\n                            " +
                            _vm._s(sortBy.key) +
                            "\r\n                        "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "action-group shop-product-viewType" }, [
                _c(
                  "a",
                  {
                    staticClass: "action",
                    class: { active: _vm.viewType === _vm.viewTypes.gallery },
                    attrs: {
                      title: _vm.$options.filters.translate(
                        "webshop_search_search_toggleView_galleryTitle"
                      ),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.toggleView(_vm.viewTypes.gallery)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("translate")(
                            "webshop_search_search_toggleView_gallery"
                          )
                        )
                      ),
                    ]),
                    _c("i", { staticClass: "mdi mdi-apps" }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "action",
                    class: { active: _vm.viewType === _vm.viewTypes.list },
                    attrs: {
                      title: _vm.$options.filters.translate(
                        "webshop_search_search_toggleView_listTitle"
                      ),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.toggleView(_vm.viewTypes.list)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("translate")(
                            "webshop_search_search_toggleView_list"
                          )
                        )
                      ),
                    ]),
                    _c("i", { staticClass: "mdi mdi-menu" }),
                  ]
                ),
              ]),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { attrs: { column: "12" } },
          [
            _vm.viewType == _vm.viewTypes.gallery
              ? _c("shop-result-gallery", {
                  attrs: { loading: _vm.loading, products: _vm.products },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.viewType == _vm.viewTypes.list
              ? _c("shop-result-list", {
                  attrs: { loading: _vm.loading, products: _vm.products },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("shop-result-pagination", {
              attrs: {
                loading: _vm.loading,
                totalProducts: _vm.totalProducts,
                input: _vm.input,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.itemsWithAmount && _vm.itemsWithAmount.length > 0
        ? _c("shop-multiple-products-footer", {
            attrs: { itemsWithAmount: _vm.itemsWithAmount },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }