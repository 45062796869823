var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import InventoryProductOwnFormPartial from './partials/InventoryProductOwnFormPartial.vue';
import { ToastService } from 'services/ToastService';
import { InventoryService } from 'services/InventoryService';
import { EventTypes } from 'models/EventTypes';
let InventoryOwnProductEditModal = class InventoryOwnProductEditModal extends Vue {
    loading = false;
    list;
    line;
    setLoading(value) {
        this.loading = value;
    }
    close() {
        this.$root.$emit(EventTypes.InventoryModalOwnProductEditClose);
    }
    save(line) {
        this.setLoading(true);
        InventoryService.updateOwnLine(line).then(resLine => {
            if (resLine.groupId !== this.line.groupId) {
                this.$root.$emit(EventTypes.InventoryLineChangedGroup, resLine, this.line.groupId, resLine.groupId);
            }
            else {
                this.$root.$emit(EventTypes.InventoryLineUpdated, resLine);
            }
            ToastService.success(Vue.filter('translate')('webshop_inventory_addmodals_editOwnProduct_toastSuccess'));
            this.$root.$emit(EventTypes.InventoryModalOwnProductEditClose);
        }).catch(err => {
            this.setLoading(false);
            ToastService.danger(Vue.filter('translate')('webshop_defaults_toast_errorText'));
        });
    }
};
InventoryOwnProductEditModal = __decorate([
    Component({
        components: {
            InventoryProductOwnFormPartial
        },
        props: {
            list: Object,
            line: Object
        }
    })
], InventoryOwnProductEditModal);
export default InventoryOwnProductEditModal;
