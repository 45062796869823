var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        ref: "filterscontainer",
        staticClass: "filters-container",
        class: {
          open: _vm.mobileFiltersOpen,
          "no-favorite-section": _vm.isOpenShop,
        },
        attrs: { id: "filters-container" },
      },
      [
        _c(
          "div",
          {
            staticClass: "filter",
            on: {
              click: function ($event) {
                return _vm.toggleFilters()
              },
            },
          },
          [
            _c("span", [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm._f("translate")("webshop_search_search_filters_filter")
                  ) +
                  "\n        "
              ),
              _c("i", { staticClass: "mdi mdi-filter-variant" }),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeFilters > 0,
                    expression: "activeFilters > 0",
                  },
                ],
                staticClass: "ui-button ui-button-small",
                on: { click: _vm.removeFilter },
              },
              [
                _c("i", { staticClass: "mdi mdi-remove-filter" }),
                _vm._v(
                  _vm._s(
                    _vm._f("translate")(
                      "webshop_search_search_filters_removeAllFilter"
                    )
                  ) + "\n      "
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        !_vm.isOpenShop
          ? _c(
              "shop-filter-header",
              {
                attrs: {
                  loading: _vm.favoriteListsLoading,
                  active: _vm.isActive,
                  mobileFiltersOpen: _vm.mobileFiltersOpen,
                  isCustomList: true,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "search-category-menu" },
                  [
                    _c("shop-filter-custom-lists", {
                      attrs: {
                        loading: _vm.customListsLoading,
                        input: _vm.input,
                        "event-type": _vm.eventTypes.ShopFavoriteListsLoad,
                        hasAgreement: _vm.searchMenu.hasAgreement,
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.isOpenShop
          ? _c(
              "shop-filter-header",
              {
                attrs: {
                  loading: _vm.favoriteListsLoading,
                  active: _vm.isNotActive,
                  mobileFiltersOpen: _vm.mobileFiltersOpen,
                  name: _vm.$options.filters.translate(
                    "webshop_search_search_filters_favoriteLists"
                  ),
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "search-category-menu" },
                  [
                    _c("shop-filter-favorite-lists", {
                      attrs: {
                        lists: _vm.searchMenu.favoriteLists,
                        "shared-lists": _vm.searchMenu.sharedFavoriteLists,
                        loading: _vm.favoriteListsLoading,
                        input: _vm.input,
                        "event-type": _vm.eventTypes.ShopFavoriteListsLoad,
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "shop-filter-header",
          {
            attrs: {
              loading: _vm.categoriesLoading,
              active: !_vm.isOpenShop ? _vm.isNotActive : _vm.isActive,
              mobileFiltersOpen: _vm.mobileFiltersOpen,
              name: _vm.$options.filters.translate(
                "webshop_search_search_filters_categories"
              ),
            },
          },
          [
            _c(
              "div",
              { staticClass: "search-category-menu" },
              [
                _c("shop-filter-categories", {
                  attrs: {
                    filters: _vm.filters,
                    categories: _vm.searchMenu.categories,
                    loading: _vm.categoriesLoading,
                    input: _vm.input,
                    "event-type": _vm.eventTypes.ShopCategoriesLoad,
                    totalProducts: _vm.totalProducts,
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "shop-filter-header",
          {
            attrs: {
              loading: _vm.storageTypesLoading,
              active: _vm.isNotActive,
              mobileFiltersOpen: _vm.mobileFiltersOpen,
              name: _vm.$options.filters.translate(
                "webshop_search_search_filters_storageType"
              ),
            },
          },
          [
            _c(
              "div",
              { staticClass: "search-category-menu" },
              [
                _c("shop-filter-multiple-selector", {
                  attrs: {
                    options: _vm.storages,
                    inputProperty: "storageTypes",
                    optionsAmounts: _vm.filters ? _vm.filters.storage : null,
                    input: _vm.input,
                    loading: _vm.storageTypesLoading,
                    useIcons: true,
                    "event-type": _vm.eventTypes.ShopStorageLoad,
                  },
                  on: { select: _vm.selectStorage },
                }),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "shop-filter-header",
          {
            attrs: {
              loading: _vm.markingsLoading,
              active: _vm.isNotActive,
              mobileFiltersOpen: _vm.mobileFiltersOpen,
              name: _vm.$options.filters.translate(
                "webshop_search_search_filters_markings"
              ),
            },
          },
          [
            _c(
              "div",
              { staticClass: "search-category-menu" },
              [
                _vm.markings
                  ? _c("shop-filter-multiple-selector", {
                      attrs: {
                        options: _vm.markings,
                        inputProperty: "markings",
                        optionsAmounts: _vm.filters ? _vm.filters.marks : null,
                        input: _vm.input,
                        loading: _vm.markingsLoading,
                        useIcons: true,
                        "event-type": _vm.eventTypes.ShopMarkingsLoad,
                      },
                      on: { select: _vm.selectMarking },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "shop-filter-header",
          {
            attrs: {
              loading: _vm.vendorsLoading,
              active: _vm.isNotActive,
              mobileFiltersOpen: _vm.mobileFiltersOpen,
              name: _vm.$options.filters.translate(
                "webshop_search_search_filters_vendors"
              ),
            },
          },
          [
            _c(
              "div",
              { staticClass: "search-category-menu" },
              [
                _vm.vendors
                  ? _c("shop-filter-multiple-selector", {
                      attrs: {
                        options: _vm.vendors,
                        inputProperty: "vendorIds",
                        optionsAmounts: _vm.filters
                          ? _vm.filters.vendors
                          : null,
                        input: _vm.input,
                        loading: _vm.vendorsLoading,
                        useIcons: false,
                        "event-type": _vm.eventTypes.ShopVendorsLoad,
                      },
                      on: { select: _vm.selectVendor },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "shop-filter-header",
          {
            attrs: {
              loading: _vm.brandsLoading,
              active: _vm.isNotActive,
              mobileFiltersOpen: _vm.mobileFiltersOpen,
              name: _vm.$options.filters.translate(
                "webshop_search_search_filters_brands"
              ),
            },
          },
          [
            _c(
              "div",
              { staticClass: "search-category-menu" },
              [
                _vm.brands
                  ? _c("shop-filter-multiple-selector", {
                      attrs: {
                        options: _vm.brands,
                        inputProperty: "brandIds",
                        optionsAmounts: _vm.filters ? _vm.filters.brands : null,
                        input: _vm.input,
                        loading: _vm.brandsLoading,
                        useIcons: false,
                        "event-type": _vm.eventTypes.ShopBrandsLoad,
                      },
                      on: { select: _vm.selectBrand },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }