export class ItemAddVM {
    product;
    amount;
    selectedUnit;
    uiSource;
    position;
    constructor(product, amount, selectedUnit, uiSource = null, position = null) {
        this.product = product;
        this.amount = amount;
        this.selectedUnit = selectedUnit;
        this.uiSource = uiSource;
        this.position = position;
    }
}
