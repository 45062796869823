var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { isNumeric } from "helpers/Validaters";
import { ToastService } from "services/ToastService";
import { InventoryService } from "services/InventoryService";
let InventoryEditPrice = class InventoryEditPrice extends Vue {
    line;
    edit = false;
    valueLocal = null;
    mounted() {
        this.refreshPrice();
    }
    onValueChanged(val, oldVal) {
        this.refreshPrice();
    }
    refreshPrice() {
        this.valueLocal = this.line.pricePrUnit.toString().replace(".", ",");
    }
    setLoading(loading) {
        this.$emit('setLoading', loading);
    }
    updatedPrice(line) {
        this.$emit('updatedPrice', line);
    }
    blur(el) {
        var linePricePrUnit = this.line.pricePrUnit.toString();
        var pricePrUnit = this.valueLocal.replace(",", ".").replace(/[.](?=.*[.])/g, "");
        if (pricePrUnit === linePricePrUnit) {
            this.edit = false;
            return;
        }
        ;
        if (!isNumeric(pricePrUnit)) {
            ToastService.danger(Vue.filter("translate")("webshop_inventory_table_product_toasts_updatedPrice_invalidFormat"));
            if (el && el.target) {
                el.target.focus();
            }
            return;
        }
        var tempObj = Object.assign({}, this.line);
        tempObj.pricePrUnit = Number.parseFloat(pricePrUnit);
        this.setLoading(true);
        InventoryService.updateLine(this.line.id, tempObj, false)
            .then(line => {
            ToastService.success(Vue.filter("translate")("webshop_inventory_table_product_toasts_updatedPrice"));
            this.edit = false;
            this.updatedPrice(line);
        })
            .catch(err => {
            this.setLoading(false);
            if (el && el.target) {
                el.target.focus();
            }
            ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
        });
    }
};
__decorate([
    Watch("line.pricePrUnit")
], InventoryEditPrice.prototype, "onValueChanged", null);
InventoryEditPrice = __decorate([
    Component({
        props: {
            line: Object
        }
    })
], InventoryEditPrice);
export default InventoryEditPrice;
