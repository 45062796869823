var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "i",
    {
      class: _vm.className,
      attrs: {
        title: _vm.title
          ? _vm.title
          : _vm.$options.filters.translate("webshop_general_delete"),
      },
    },
    [
      _c(
        "svg",
        {
          staticClass: "svg-trash",
          attrs: { viewBox: "0 0 40 40" },
          on: {
            click: function ($event) {
              return _vm.$emit("click")
            },
          },
        },
        [
          _c("polygon", {
            staticClass: "polygon p-top",
            attrs: {
              points: "9,8 15,8, 17,5, 23,5, 25,8, 31,8 31,10 9,10",
              "stroke-linejoin": "round",
              "stroke-width": "2",
            },
          }),
          _vm._v(" "),
          _c("polygon", {
            staticClass: "polygon p-bottom",
            attrs: {
              points: "11,14 29,14 28,34 12,34",
              "stroke-linejoin": "round",
              "stroke-width": "2",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }