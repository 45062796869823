var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { ToastService } from "services/ToastService";
import Loader from 'components/utility/Loader.vue';
import { InventoryService } from "services/InventoryService";
import { InventoryArchiveVM } from "../../../models/inventory/InventoryArchiveVM";
let InventoryArchiveModal = class InventoryArchiveModal extends Vue {
    name = '';
    loading = false;
    inventoryArchive = new InventoryArchiveVM();
    mounted() {
    }
    close() {
        this.$emit('close-modal');
    }
    create() {
        this.loading = true;
        InventoryService.CreateInventoryArchive(this.inventoryArchive)
            .then(() => {
            this.loading = false;
            ToastService.success(Vue.filter("translate")("webshop_inventoryArchive_modal_successCreated"));
            this.$emit('close-modal');
        })
            .catch(err => {
            this.loading = false;
            ToastService.danger(Vue.filter("translate")("webshop_inventoryArchive_modal_errorOnCreate"));
            this.$emit('close-modal');
        });
    }
};
InventoryArchiveModal = __decorate([
    Component({
        components: { Loader },
        props: {}
    })
], InventoryArchiveModal);
export default InventoryArchiveModal;
