var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from "vue-property-decorator";
import { MenuTypes } from '../../../models/utility/MenuTypes';
import { Settings } from '../../../config/Settings';
import MobileNavigation from './MobileNavigation.vue';
import NavLink from './NavLink.vue';
let MainNavigation = class MainNavigation extends Vue {
    menu;
    mobileActive = false;
    settings = Settings;
    menuTypes = MenuTypes;
    asssetsBaseLink = "/app/dist2/assets/";
    showMobile() {
        this.mobileActive = !this.mobileActive;
    }
    onMobileActiveChange(newVal) {
        if (newVal) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'auto';
        }
    }
    createLink(link) {
        if (link.Type === this.menuTypes.webShopPage || link.Url.startsWith('http', 0)) {
            return link.Url;
        }
        return this.settings.menuBaseLink + link.Url;
    }
    activePage(link) {
        const pathName = window.location.pathname;
        if (link.Type === this.menuTypes.webShopPage &&
            (pathName === '/' && link.Url === '/webbshop/' || pathName.startsWith(link.Url, 0))) {
            return 'active';
        }
        return '';
    }
};
__decorate([
    Watch('mobileActive')
], MainNavigation.prototype, "onMobileActiveChange", null);
MainNavigation = __decorate([
    Component({
        components: {
            NavLink,
            MobileNavigation
        },
        props: {
            menu: Object
        }
    })
], MainNavigation);
export default MainNavigation;
