import { SearchSortBy } from './SearchSortBy';
export class SearchInput {
    searchQuery = "";
    categoryId = null;
    groupId = null;
    page = 1;
    pageSize = 99;
    storageTypes = [];
    vendorIds = [];
    brandIds = [];
    favoriteListId = null;
    department = null;
    markings = [];
    sortBy = SearchSortBy.Description;
    skipPopular = false;
    skipFilters = false;
    openShop = false;
}
