var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ui-modal-content" }, [
      _c("h3", { staticClass: "ui-modal-header" }, [
        _vm._v(
          _vm._s(
            _vm._f("translate")(
              "webshop_calculations_modals_recipe_print_title"
            )
          )
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.loading
            ? [_c("loader", { attrs: { size: "large" } })]
            : [
                _c(
                  "form",
                  {
                    staticClass: "ui-form",
                    attrs: { name: "calculationModal" },
                  },
                  [
                    _c("div", { staticClass: "ui-form-fieldwrapper" }, [
                      _c(
                        "label",
                        {
                          attrs: { for: "showSubDescription" },
                          on: {
                            click: function ($event) {
                              _vm.showSubDescription = !_vm.showSubDescription
                            },
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.showSubDescription,
                                expression: "showSubDescription",
                              },
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(_vm.showSubDescription)
                                ? _vm._i(_vm.showSubDescription, null) > -1
                                : _vm.showSubDescription,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.showSubDescription,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.showSubDescription = $$a.concat([
                                        $$v,
                                      ]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.showSubDescription = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.showSubDescription = $$c
                                }
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_calculations_modals_recipe_print_showSubDescription"
                                )
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "ui-form-fieldwrapper" }, [
                      _c(
                        "label",
                        { attrs: { for: "numberOfPortion" } },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_calculations_modals_recipe_print_numberOfPortions"
                                )
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          _c("input-number", {
                            attrs: {
                              inputClass: "item-amount-input",
                              autocomplete: "off",
                              inputStep: 0.01,
                              "select-onfocus": "true",
                            },
                            model: {
                              value: _vm.numberOfPortion,
                              callback: function ($$v) {
                                _vm.numberOfPortion = $$v
                              },
                              expression: "numberOfPortion",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-buttons-wrapper" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "ui-button ui-button-secondary ui-button-left",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.closeModal()
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("translate")(
                                "webshop_calculations_calculationHeader_buttons_cancel"
                              )
                            )
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "ui-button",
                          on: {
                            click: function ($event) {
                              return _vm.printRecipe($event)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "mdi mdi-printer" }),
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_calculations_modals_recipe_print_btnPrint"
                                )
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ],
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }