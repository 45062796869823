var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded && _vm.products.length > 0
    ? _c(
        "div",
        { staticClass: "shop-dashboard-localOffers", attrs: { grid: "wrap" } },
        [
          _c("div", { attrs: { column: "s-12" } }, [
            _c("h2", { staticClass: "header-medium section-header" }, [
              _vm._v(
                _vm._s(_vm._f("translate")("webshop_dashboard_todayOffer"))
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { attrs: { column: "s-12" } }, [
            _c(
              "div",
              {
                staticClass:
                  "shop-placeholder-hereAndNowOffers shop-products shop-productlist-thumbs",
              },
              _vm._l(_vm.products, function (product) {
                return _c("shop-gallery-item", {
                  key: product.productId,
                  attrs: { loading: _vm.loading, product: product },
                })
              }),
              1
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }