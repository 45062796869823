var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import LoadingButton from '../../shared/LoadingButton.vue';
import Loader from 'components/utility/Loader.vue';
import InputNumber from 'components/shared/InputNumber.vue';
import ConvertedOrderline from './ConvertedOrderline.vue';
import { CalculationService } from 'services/CalculationService';
import { Settings } from 'config/Settings';
import { OrderlineUISourceTypes } from 'models/order/OrderlineUISourceTypes';
let CreateOrderFromCalculationModal = class CreateOrderFromCalculationModal extends Vue {
    data;
    multiplierOld = 0;
    multiplier = 1;
    saveInActive = true;
    convertedOrderlines = [];
    loading = false;
    loadingConvertedLines = false;
    mounted() {
        this.loadConvertedOrderlines();
    }
    beforeDestroy() {
    }
    closeModal() {
        this.$emit('close-modal');
    }
    submit() {
        if (this.saveInActive) {
            this.addCalculationLinesInActiveOrder();
        }
        else {
            this.createOrderFromCalculation();
        }
    }
    createOrderFromCalculation() {
        this.loading = true;
        CalculationService.createOrderFromCalculation(this.data.calculationId, this.convertedOrderlines).then((res) => {
            this.$store.dispatch('Orders/setActiveOrder', res.orderNo).then(res => {
                this.loading = false;
                window.location.replace(Settings.paths['cart']);
            });
            this.$emit('close-modal');
        });
    }
    addCalculationLinesInActiveOrder() {
        this.loading = true;
        CalculationService.addCalculationInActiveOrder(this.data.calculationId, this.convertedOrderlines).then((res) => {
            this.loading = false;
            window.location.replace(Settings.paths['cart']);
            this.$emit('close-modal');
        });
    }
    loadConvertedOrderlines() {
        if (this.multiplier > 0 && this.multiplier != this.multiplierOld) {
            this.loadingConvertedLines = true;
            CalculationService.getConvertedOrderlines(this.data.calculationId, this.multiplier).then((res) => {
                this.convertedOrderlines = res;
                this.convertedOrderlines.forEach(x => x.orderlineUISource = OrderlineUISourceTypes.CalculationList);
                this.multiplierOld = this.multiplier;
            }).finally(() => this.loadingConvertedLines = false);
        }
    }
};
CreateOrderFromCalculationModal = __decorate([
    Component({
        components: {
            LoadingButton,
            Loader,
            InputNumber,
            ConvertedOrderline
        },
        props: {
            data: Object
        }
    })
], CreateOrderFromCalculationModal);
export default CreateOrderFromCalculationModal;
