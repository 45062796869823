var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { ToastService } from "services/ToastService";
import { OrderService } from "services/OrderService";
import { EventTypes } from "models/EventTypes";
import { OrderlineUISourceTypes } from "models/order/OrderlineUISourceTypes";
let ShopMultipleProductsFooter = class ShopMultipleProductsFooter extends Vue {
    itemsWithAmount;
    loading = false;
    addToBasket() {
        const basketProds = [];
        if (this.loading) {
            return;
        }
        this.loading = true;
        this.itemsWithAmount.forEach(item => {
            basketProds.push({
                amount: +item.amount,
                productId: item.product.productId,
                description: item.product.description,
                expiryRef: item.product.expiry ? item.product.expiry.reference : null,
                unit: item.selectedUnit,
                fromFavoriteList: false,
                list: null,
                checkoutId: 0,
                orderlineUISource: item.uiSource,
                searchPlacement: item.position,
                isFromSearch: item.uiSource == OrderlineUISourceTypes.ShopGallery || item.uiSource == OrderlineUISourceTypes.ShopGalleryPopular
                    || item.uiSource == OrderlineUISourceTypes.ShopList || item.uiSource == OrderlineUISourceTypes.ShopListPopular ? true : false,
                image: item.product.image,
                brand: item.product.brand
            });
        });
        OrderService.addProductsToBasket(basketProds)
            .then(res => {
            if (res != null && res.addedOrderLines > 0) {
                ToastService.success(Vue.filter("translate")("webshop_cart_add_toast_basketAdded", res.addedOrderLines));
                this.$root.$emit(EventTypes.MiniCartUpdate, res.previewOrder, basketProds);
                this.$root.$emit(EventTypes.ShopAddedProductsToBasket);
            }
            else {
                var errorMessage = Vue.filter("translate")("webshop_cart_add_toast_errorText");
                ToastService.danger(errorMessage, Vue.filter("translate")("webshop_cart_add_toast_errorHeader"));
            }
            this.loading = false;
        })
            .catch(err => {
            if (this.itemsWithAmount.length > 1) {
                setTimeout(() => {
                    window.location.href = window.location.href;
                }, 2000);
            }
            this.loading = false;
        });
    }
};
ShopMultipleProductsFooter = __decorate([
    Component({
        components: {},
        props: {
            itemsWithAmount: Array
        }
    })
], ShopMultipleProductsFooter);
export default ShopMultipleProductsFooter;
