var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "calculation-details" },
    [
      _vm.selectedCalculation && _vm.selectedCalculation.calculationId
        ? _c("calculation-header", {
            key: "calcHeader" + _vm.selectedCalculation.calculationId,
            attrs: { calculation: _vm.selectedCalculation },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedCalculation && _vm.selectedCalculation.calculationId
        ? _c("calculation-line-list", {
            key: "calcLines" + _vm.selectedCalculation.calculationId,
            attrs: { calculation: _vm.selectedCalculation },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }