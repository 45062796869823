import { Service } from "../../../basic-service/src";
export class ModalService extends Service {
    static nextId = 0;
    static modals = new Array();
    static spliceModals(modal) {
        let idx = this.modals.findIndex((idxModal) => {
            return idxModal.id === modal.id;
        });
        this.modals.splice(idx, 1);
    }
    static create(args) {
        let settings = {
            'cxt': args.cxt,
            'content': args.content || null,
            'component': args.component || null,
            'maxWidth': args.maxWidth || '100vw',
            'height': args.height || null,
            'duration': args.duration || null,
            'active': args.active || true,
            'allowDuplicates': args.allowDuplicates || false,
            'data': args.data || null
        };
        args.cxt.$root.$emit('onShowModal', settings);
    }
}
