var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.list
    ? _c("div", [
        _c(
          "div",
          { staticClass: "inventory-list-header" },
          [
            _c("span", { staticClass: "label" }, [
              _vm._v(
                _vm._s(
                  _vm._f("translate")("webshop_inventory_header_listHeader")
                )
              ),
            ]),
            _vm._v(" "),
            !_vm.settings.editable
              ? _c("h1", { staticClass: "header-medium" }, [
                  _vm._v(_vm._s(_vm.list.name)),
                  _vm.list.autoList
                    ? _c("i", {
                        staticClass: "mdi mdi-auto-fix left-10",
                        attrs: {
                          title: _vm.$options.filters.translate(
                            "webshop_inventory_autolager_iconTitle"
                          ),
                        },
                      })
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.settings.editable
              ? _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.saveList()
                      },
                    },
                  },
                  [
                    _vm.settings.editable
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newName,
                              expression: "newName",
                            },
                          ],
                          staticClass: "header-medium",
                          attrs: {
                            type: "text",
                            required: "",
                            maxlength: "20",
                          },
                          domProps: { value: _vm.newName },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.newName = $event.target.value
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "ui-button ui-button-flat ui-button-small accept capitalize",
                        attrs: { type: "submit" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("translate")(
                              "webshop_inventory_header_saveListBtn"
                            )
                          )
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "ui-button ui-button-flat ui-button-small cancel capitalize",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.settings.editable = false
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("translate")(
                              "webshop_inventory_header_cancelBtn"
                            )
                          )
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("p", { staticClass: "product-count" }, [
              _vm._v(
                _vm._s(_vm.lineCount) +
                  " " +
                  _vm._s(
                    _vm._f("translate")("webshop_inventory_header_products")
                  )
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "product-total-price" }, [
              _vm._v(
                _vm._s(
                  _vm._f("translate")("webshop_inventory_header_total_sum")
                ) + ": "
              ),
              _vm.showPrice
                ? _c("span", [
                    _vm._v(_vm._s(_vm._f("currency")(_vm.totalPrice))),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            !_vm.settings.editable
              ? [
                  _c(
                    "a",
                    {
                      staticClass: "ui-button ui-button-flat ui-button-small",
                      on: {
                        click: function ($event) {
                          return _vm.openAddProduct()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "mdi mdi-plus" }),
                      _vm._v(
                        "\r\n                " +
                          _vm._s(
                            _vm._f("translate")(
                              "webshop_inventory_header_addProductsBtn"
                            )
                          ) +
                          "\r\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "new-group-button ui-button ui-button-flat ui-button-small",
                      class: { cancel: _vm.settings.showNewGroup },
                      on: {
                        click: function ($event) {
                          return _vm.toggleNewGroup()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.settings.showNewGroup
                            ? _vm.$options.filters.translate(
                                "webshop_inventory_header_group_toggleBtnCancel"
                              )
                            : _vm.$options.filters.translate(
                                "webshop_inventory_header_group_toggleBtnAddGroup"
                              )
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", [
                    _c(
                      "a",
                      {
                        staticClass:
                          "ui-button ui-button-flat ui-button-small _xs-hide _s-hide _m-hide",
                        attrs: { download: "" },
                        on: {
                          click: function ($event) {
                            return _vm.print(false)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "mdi mdi-printer" }),
                        _vm._v(
                          "\r\n                    " +
                            _vm._s(
                              _vm._f("translate")(
                                "webshop_inventory_header_settings_print_list"
                              )
                            ) +
                            "\r\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "ui-button ui-button-flat ui-button-small _xs-hide _s-hide _m-hide",
                        attrs: { download: "" },
                        on: {
                          click: function ($event) {
                            return _vm.print(true)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "mdi mdi-printer" }),
                        _vm._v(
                          "\r\n                    " +
                            _vm._s(
                              _vm._f("translate")(
                                "webshop_inventory_header_settings_print_under"
                              )
                            ) +
                            "\r\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "ui-button ui-button-flat ui-button-small _xs-hide _s-hide",
                        on: {
                          click: function ($event) {
                            return _vm.openResetAmountModal()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "mdi mdi-refresh" }),
                        _vm._v(
                          "\r\n                    " +
                            _vm._s(
                              _vm._f("translate")(
                                "webshop_inventory_header_settings_reset"
                              )
                            ) +
                            "\r\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "ui-button ui-button-flat ui-button-small _xs-hide _s-hide",
                        on: {
                          click: function ($event) {
                            return _vm.updatePricesOnList()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "mdi mdi-auto-fix" }),
                        _vm._v(
                          "\r\n                    " +
                            _vm._s(
                              _vm._f("translate")(
                                "webshop_inventory_header_settings_prices_update"
                              )
                            ) +
                            "\r\n                "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "ui-button ui-button-flat ui-button-small button-sort-list",
                      on: {
                        click: function ($event) {
                          return _vm.lineSortModal()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "mdi mdi-sort" }),
                      _vm._v(
                        "\r\n                " +
                          _vm._s(
                            _vm._f("translate")(
                              "webshop_inventory_header_settings_sortList"
                            )
                          ) +
                          "\r\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "inventory-settings-container",
                      staticClass: "inventory-settings-container",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass:
                            "ui-button ui-button-flat ui-button-small inventory-settings-toggler",
                          class: { cancel: _vm.settings.showSettings },
                          on: {
                            click: function ($event) {
                              return _vm.toggleSettings()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "mdi mdi-dots-vertical" }),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "mdi mdi-close-circle-outline",
                          }),
                          _vm._v(
                            "\r\n                    " +
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_inventory_header_settings_btn"
                                )
                              ) +
                              "\r\n                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.settings.showSettings
                        ? _c("ul", { staticClass: "inventory-settings" }, [
                            _c("li", { staticClass: "_l-hide _xl-hide" }, [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "ui-button ui-button-flat ui-button-small ui-button-transparent _l-hide _xl-hide",
                                  attrs: { download: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.print(false)
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass:
                                      "mdi mdi-printer settings-icon",
                                  }),
                                  _vm._v(
                                    "\r\n                            " +
                                      _vm._s(
                                        _vm._f("translate")(
                                          "webshop_inventory_header_settings_print_list"
                                        )
                                      ) +
                                      "\r\n                        "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("li", { staticClass: "_l-hide _xl-hide" }, [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "ui-button ui-button-flat ui-button-small ui-button-transparent _l-hide _xl-hide",
                                  attrs: { download: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.print(true)
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass:
                                      "mdi mdi-printer settings-icon",
                                  }),
                                  _vm._v(
                                    "\r\n                            " +
                                      _vm._s(
                                        _vm._f("translate")(
                                          "webshop_inventory_header_settings_print_under"
                                        )
                                      ) +
                                      "\r\n                        "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "ui-button ui-button-flat ui-button-small ui-button-transparent",
                                  on: {
                                    click: function ($event) {
                                      return _vm.createFavoriteList()
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass: "mdi mdi-plus settings-icon",
                                  }),
                                  _vm._v(
                                    "\r\n                            " +
                                      _vm._s(
                                        _vm._f("translate")(
                                          "webshop_inventory_header_settings_createFavoriteList"
                                        )
                                      ) +
                                      "\r\n                        "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "ui-button ui-button-flat ui-button-small ui-button-transparent",
                                  on: {
                                    click: function ($event) {
                                      return _vm.excel()
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass: "mdi mdi-grid settings-icon",
                                  }),
                                  _vm._v(
                                    "\r\n                            " +
                                      _vm._s(
                                        _vm._f("translate")(
                                          "webshop_inventory_header_settings_excel"
                                        )
                                      ) +
                                      "\r\n                        "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "ui-button ui-button-flat ui-button-small ui-button-transparent",
                                  on: {
                                    click: function ($event) {
                                      return _vm.openSendEmail()
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass: "mdi mdi-email settings-icon",
                                  }),
                                  _vm._v(
                                    "\r\n                            " +
                                      _vm._s(
                                        _vm._f("translate")(
                                          "webshop_inventory_header_settings_sendList"
                                        )
                                      ) +
                                      "\r\n                        "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            !_vm.list.autoList
                              ? _c("li", [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "ui-button ui-button-flat ui-button-small ui-button-transparent",
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleEditList()
                                        },
                                      },
                                    },
                                    [
                                      _c("span", {
                                        staticClass:
                                          "mdi mdi-pencil settings-icon",
                                      }),
                                      _vm._v(
                                        "\r\n                            " +
                                          _vm._s(
                                            _vm._f("translate")(
                                              "webshop_inventory_header_settings_editListName"
                                            )
                                          ) +
                                          "\r\n                        "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "li",
                              { staticClass: "_m-hide _l-hide _xl-hide" },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "ui-button ui-button-flat ui-button-small ui-button-transparent",
                                    on: {
                                      click: function ($event) {
                                        return _vm.openResetAmountModal()
                                      },
                                    },
                                  },
                                  [
                                    _c("span", {
                                      staticClass:
                                        "mdi mdi-refresh settings-icon",
                                    }),
                                    _vm._v(
                                      "\r\n                            " +
                                        _vm._s(
                                          _vm._f("translate")(
                                            "webshop_inventory_header_settings_reset"
                                          )
                                        ) +
                                        "\r\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              { staticClass: "_m-hide _l-hide _xl-hide" },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "ui-button ui-button-flat ui-button-small ui-button-transparent",
                                    on: {
                                      click: function ($event) {
                                        return _vm.updatePricesOnList()
                                      },
                                    },
                                  },
                                  [
                                    _c("span", {
                                      staticClass:
                                        "mdi mdi-auto-fix settings-icon",
                                    }),
                                    _vm._v(
                                      "\r\n                            " +
                                        _vm._s(
                                          _vm._f("translate")(
                                            "webshop_inventory_header_settings_prices_update"
                                          )
                                        ) +
                                        "\r\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "ui-button ui-button-flat ui-button-small ui-button-transparent",
                                  class: {
                                    accept: _vm.settings.showDeleteConfirm,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickedDeleteList()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "settings-icon trash" },
                                    [
                                      _c("icon-trash", {
                                        staticClass: "polygon-grey",
                                        attrs: { focusable: "false" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "delete-button-text" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("translate")(
                                            "webshop_inventory_header_settings_deleteListConfirm"
                                          )
                                        ) + " "
                                      ),
                                    ]
                                  ),
                                  _vm._v(
                                    "\r\n                            " +
                                      _vm._s(
                                        _vm._f("translate")(
                                          "webshop_inventory_header_settings_deleteListConfirmDelete"
                                        )
                                      ) +
                                      "\r\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "ui-button ui-button-flat ui-button-small ui-button-cancel ui-button-transparent",
                                  class: {
                                    show: _vm.settings.showDeleteConfirm,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.settings.showDeleteConfirm = false
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("translate")(
                                        "webshop_inventory_header_settings_resetAmount"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "inventory-filter-container",
                        class: { "show-reset": _vm.settings.filter },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.settings.filter,
                              expression: "settings.filter",
                            },
                          ],
                          staticClass: "inventory-list-filter",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$options.filters.translate(
                              "webshop_inventory_header_group_searchPlaceholder"
                            ),
                          },
                          domProps: { value: _vm.settings.filter },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.settings,
                                  "filter",
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                return _vm.changeFilter()
                              },
                            ],
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "ui-button-small reset-filter",
                            on: {
                              click: function ($event) {
                                return _vm.resetFilter()
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_inventory_header_searchReset"
                                )
                              )
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "div-table" }, [
          _c(
            "div",
            {
              staticClass: "table-body",
              staticStyle: { position: "relative", "z-index": "1" },
            },
            [
              _c("div", { staticClass: "table-outer-row" }, [
                _c(
                  "div",
                  {
                    staticClass: "table-row new-group",
                    class: { show: _vm.settings.showNewGroup },
                  },
                  [
                    _c(
                      "form",
                      {
                        staticClass: "table-column",
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.createNewGroup()
                          },
                        },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newGroupName,
                              expression: "newGroupName",
                            },
                          ],
                          attrs: {
                            type: "text",
                            placeholder: _vm.$options.filters.translate(
                              "webshop_inventory_header_group_createPlaceholder"
                            ),
                            required: "",
                            maxlength: "50",
                          },
                          domProps: { value: _vm.newGroupName },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.newGroupName = $event.target.value
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "ui-button ui-button-small",
                            attrs: { type: "submit" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_inventory_header_group_createBtn"
                                )
                              )
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }