var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ui-modal-content" }, [
      _c("h3", { staticClass: "ui-modal-header" }, [
        _vm._v(
          _vm._s(
            _vm._f("translate")(
              "webshop_order_complete_acceptOrder_toast_errorHeader"
            )
          )
        ),
      ]),
      _vm._v(" "),
      _c("div", [_c("p", { domProps: { innerHTML: _vm._s(_vm.data) } })]),
      _vm._v(" "),
      _c("div", { staticClass: "ui-modal-footer" }, [
        _c("div", { staticClass: "ui-modal-confirm-wrapper" }, [
          _c(
            "a",
            {
              staticClass: "ui-button ui-button-secondary",
              on: { click: _vm.closeModal },
            },
            [_vm._v(_vm._s(_vm._f("translate")("webshop_general_ok")))]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }