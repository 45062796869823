import { GABase } from "./GABase";
export class GAProduct extends GABase {
    price;
    brand;
    category;
    variant;
    quantity;
    dimension1 = 'SvenskCater';
    constructor(id, name, price = null, brand = '', category = '', variant = '', quantity = null, dimension1 = 'SvenskCater') {
        super(id, name);
        //this.price = price;
        this.brand = brand;
        this.category = category;
        this.variant = variant;
        this.quantity = quantity;
        this.dimension1 = dimension1;
    }
    /**
     * @params products {ProductVM[] | Orderline[]}
    */
    static ConvertFromProducts(products) {
        if (products && products.length) {
            return products.map((p) => {
                //let salesPrice = p.units.find(x => x.name == p.salesUnit || x.name == p.unit).price;
                let gaProduct = new GAProduct(p.productId, p.description, null, p.brand, '', '');
                return gaProduct;
            });
        }
    }
    static ConvertFromBasketProducts(products) {
        if (products && products.length) {
            return products.map((p) => {
                let gaProduct = new GAProduct(p.productId, p.description, null, '', '', '', p.amount);
                return gaProduct;
            });
        }
    }
    static ConvertFromBasketUpdateOrderLines(products) {
        if (products && products.length) {
            return products.map((p) => {
                let gaProduct = new GAProduct(p.productId, p.description, null, '', '', '', p.quantity);
                return gaProduct;
            });
        }
    }
    static ConvertFromOrderlines(products, listName = '') {
        if (products && products.length) {
            return products.map((p) => {
                // let price = p.discountPrice > 0 ? p.discountPrice: p.price;
                let gaProduct = new GAProduct(p.productId, p.description, null, '', '', '', p.amount);
                return gaProduct;
            });
        }
    }
}
