var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import InlineDeleteButton from 'components/shared/InlineDeleteButton.vue';
import CalculationHeader from './result/CalculationHeader.vue';
import CalculationLineList from './result/CalculationLineList.vue';
import { LoaderService } from 'services/LoaderService';
Component.registerHooks([
    'beforeRouteUpdate'
]);
let CalculationDetails = class CalculationDetails extends Vue {
    calculations;
    mounted() {
        let calculationId = Number(this.$route.params.calculationId);
        if (!isNaN(calculationId)) {
            this.selectCalculation(calculationId);
        }
    }
    get selectedCalculation() {
        return this.$store.state.Calculations.selectedCalculation;
    }
    beforeRouteUpdate(to, from, next) {
        var calculationId = Number(to.params.calculationId);
        if (!isNaN(calculationId)) {
            this.selectCalculation(Number(calculationId));
        }
        next();
    }
    selectCalculation(calculationId) {
        LoaderService.createLoader(null);
        this.$store.dispatch('Calculations/selectCalculation', calculationId).catch(err => {
            this.$router.replace({ name: 'calculations' });
        }).finally(() => {
            LoaderService.stop();
        });
    }
};
CalculationDetails = __decorate([
    Component({
        components: {
            InlineDeleteButton,
            CalculationHeader,
            CalculationLineList
        },
        props: {}
    })
], CalculationDetails);
export default CalculationDetails;
