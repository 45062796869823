export var QueryParameters;
(function (QueryParameters) {
    //names have to matchs SearchInput names
    QueryParameters["searchQuery"] = "q";
    QueryParameters["categoryId"] = "c";
    QueryParameters["groupId"] = "g";
    QueryParameters["favoriteListId"] = "f";
    QueryParameters["storageTypes"] = "st";
    QueryParameters["vendorIds"] = "v";
    QueryParameters["brandIds"] = "b";
    QueryParameters["markings"] = "m";
    QueryParameters["page"] = "p";
    QueryParameters["sortBy"] = "sb";
    QueryParameters["department"] = "d";
    //pageSize = "ps" 
})(QueryParameters || (QueryParameters = {}));
