export class CalculationVM {
    calculationId;
    name;
    customerNo;
    priceToCustomer;
    numberOfPortions;
    calculationType;
    vatId;
    totalCost;
    coverage;
    grossProfit;
    calculationLines;
    vatList;
    isShared;
    imageUrl;
    recipeDescription;
    static fromApi(item) {
        return Object.assign(new CalculationVM(), item);
    }
}
