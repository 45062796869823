var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { AuthService } from 'services/AuthService';
import { ToastService } from 'services/ToastService';
import LoadingButton from '../../shared/LoadingButton.vue';
let RequestPasswordPartial = class RequestPasswordPartial extends Vue {
    loading = false;
    submitted = false;
    username = null;
    validUsername = true;
    goBack() {
        this.loading = false;
        this.submitted = false;
        this.username = null;
        this.$emit("goBack");
    }
    request() {
        if (!this.username) {
            ToastService.danger(Vue.filter('translate')('webshop_login_requestPassword_toast_emptyFields'));
            return;
        }
        if (!this.isValidUsername()) {
            return;
        }
        this.loading = true;
        AuthService.requestPassword(this.username)
            .then(() => {
            this.submitted = true;
            this.loading = true;
        })
            .catch(err => {
            if (err === 400) {
                ToastService.danger(Vue.filter('translate')('webshop_login_requestPassword_toast_invalidEmail'));
            }
            else {
                ToastService.danger(Vue.filter('translate')('webshop_defaults_toast_errorText'));
            }
            this.loading = false;
        });
    }
    isValidUsername() {
        if (this.username) {
            let usernameParts = this.username.split('@');
            let customer = usernameParts.length > 1 ? usernameParts[1] : usernameParts[0];
            this.validUsername = customer.toLowerCase().startsWith('k') || customer.toLowerCase().startsWith('l');
        }
        return this.validUsername;
    }
};
RequestPasswordPartial = __decorate([
    Component({
        components: {
            LoadingButton
        }
    })
], RequestPasswordPartial);
export default RequestPasswordPartial;
