var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { FavoriteService } from "services/FavoriteService";
import { ToastService } from "services/ToastService";
import CheckOutFavListItem from "./CheckOutFavListItem.vue";
let CheckOutAddToFavoriteListSelect = class CheckOutAddToFavoriteListSelect extends Vue {
    lists = [];
    loading;
    loaded;
    showList = false;
    checkout;
    mounted() {
        this.getLists();
    }
    getLists() {
        this.loading = true;
        FavoriteService.getAllLists(this.checkout.ProductId)
            .then(data => {
            this.lists = data;
            this.loading = false;
            this.loaded = true;
        })
            .catch(err => {
            this.loading = false;
            ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
        });
    }
    show() {
        this.showList = !this.showList;
    }
};
CheckOutAddToFavoriteListSelect = __decorate([
    Component({
        components: {
            CheckOutFavListItem
        },
        props: {
            checkout: Object
        }
    })
], CheckOutAddToFavoriteListSelect);
export default CheckOutAddToFavoriteListSelect;
