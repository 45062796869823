var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { ToastService } from 'services/ToastService';
import { FavoriteService } from "services/FavoriteService";
import LoadingButton from '../../shared/LoadingButton.vue';
import { EventTypes } from 'models/EventTypes';
let ShopReplaceProdConfirmModal = class ShopReplaceProdConfirmModal extends Vue {
    data;
    loading = false;
    close() {
        this.$emit('close-modal');
    }
    submit() {
        this.loading = true;
        this.$root.$emit(EventTypes.FavoriteProductLoading);
        FavoriteService.replaceObsolete(this.data.productId, this.data.altProductId)
            .then(res => {
            ToastService.success(Vue.filter("translate")("webshop_search_search_item_obsolete_replaced", this.data.description, this.data.AltProduct.description));
            this.$root.$emit(EventTypes.FavoriteReplaceModalSuccess);
            this.close();
            this.loading = false;
        })
            .catch(err => {
            ToastService.danger(Vue.filter("translate")("webshop_favorite_deleteList_toast_fail"));
            this.$root.$emit(EventTypes.FavoriteReplaceModalError);
            this.loading = false;
        });
    }
};
ShopReplaceProdConfirmModal = __decorate([
    Component({
        components: {
            LoadingButton
        },
        props: {
            data: Object
        }
    })
], ShopReplaceProdConfirmModal);
export default ShopReplaceProdConfirmModal;
