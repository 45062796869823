var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "shop-header-link shop-header-focus",
      on: {
        click: function ($event) {
          return _vm.openLogin()
        },
      },
    },
    [
      _vm._v(
        "\n    " +
          _vm._s(_vm._f("translate")("webshop_layout_header_loginText")) +
          "\n"
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }