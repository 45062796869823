var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.settings.hasSearchResult
        ? _c("div", { staticClass: "modal-navigation search" }, [
            _c(
              "button",
              {
                staticClass:
                  "ui-button ui-button-flat ui-button-small ui-button-transparent",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.reset()
                  },
                },
              },
              [
                _c("i", { staticClass: "mdi mdi-arrow-left" }),
                _vm._v(
                  " " +
                    _vm._s(_vm._f("translate")("webshop_general_back")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "navigation-head" }, [
              _c("i", { staticClass: "mdi mdi-magnify" }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("translate")("webshop_inventory_addmodals_search")
                  ) +
                  "\n            "
              ),
              _vm.result && _vm.result.length > 0
                ? _c("span", { staticClass: "search-query-container" }, [
                    _vm._v("\n                - "),
                    _c("span", { staticClass: "search-query" }, [
                      _vm._v('"' + _vm._s(_vm.settings.searchQuery) + '"'),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.settings.hasSearchResult && _vm.result && _vm.result.length > 0
        ? [
            _c("inventory-product-result-partial", {
              attrs: { result: _vm.result },
            }),
          ]
        : _c(
            "form",
            {
              staticClass: "search-form",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.search()
                },
              },
            },
            [
              _c("input", {
                directives: [
                  { name: "autofocus", rawName: "v-autofocus" },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.settings.searchQuery,
                    expression: "settings.searchQuery",
                  },
                ],
                staticClass: "slide-input",
                attrs: {
                  type: "text",
                  placeholder: _vm.$options.filters.translate(
                    "webshop_inventory_addmodals_searchProduct_placeholder"
                  ),
                },
                domProps: { value: _vm.settings.searchQuery },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.settings, "searchQuery", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "ui-button ui-button-accept",
                  attrs: { type: "submit" },
                },
                [
                  _c("i", { staticClass: "mdi mdi-magnify" }),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("translate")(
                          "webshop_inventory_addmodals_search"
                        )
                      )
                  ),
                ]
              ),
            ]
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }