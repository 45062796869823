var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
let ShopStockWarning = class ShopStockWarning extends Vue {
    product;
    amount;
    selectedUnit;
    autoDisplayMessage;
    active = false;
    timeoutId;
    beforeDestroy() {
        clearTimeout(this.timeoutId);
    }
    closeMessage() {
        this.active = false;
        clearTimeout(this.timeoutId);
    }
    toggleMessage() {
        clearTimeout(this.timeoutId);
        this.active = !this.active;
    }
    checkCondition() {
        if (this.selectedUnit && this.amount > 0) {
            const selectedUnitObject = this.product.units.find(unit => unit.name == this.selectedUnit);
            const amountInBaseUnit = selectedUnitObject.quantityPerUnit * this.amount;
            return this.product.stock < 0 || this.product.markings.procure || amountInBaseUnit > this.product.stock;
        }
    }
    displayMessage() {
        this.active = true;
        this.timeoutId = setTimeout(() => this.active = false, 6000);
    }
    get displayContent() {
        let checkCondition = false;
        if (this.product && this.product.stock != null) {
            checkCondition = this.checkCondition();
            if (checkCondition && this.autoDisplayMessage) {
                this.displayMessage();
            }
        }
        return checkCondition;
    }
};
ShopStockWarning = __decorate([
    Component({
        props: {
            product: Object,
            amount: Number,
            selectedUnit: String,
            autoDisplayMessage: Boolean,
        }
    })
], ShopStockWarning);
export default ShopStockWarning;
