import { default as axios } from 'axios';
export class Api {
    static ownResCode = 666;
    static client(baseUrl) {
        let api = axios.create({
            baseURL: baseUrl
        });
        api.interceptors.response.use((res) => {
            return res;
        }, (err) => {
            if (typeof err.response === "undefined") {
                err.response = {
                    data: "",
                    status: Api.ownResCode,
                    statusText: "No Server Response",
                    headers: undefined,
                    config: undefined
                };
            }
            else if (err.response.status === 401) {
                window.location.replace("?logout=true");
            }
            return Promise.reject({ ...err });
        });
        return api;
    }
}
