import { Api } from 'api/Api';
export class InventoryApi {
    static client = Api.client('/api/inventoryList');
    /*
        LIST
    */
    static getAllLists() {
        return this.client.get('/GetInventoryLists');
    }
    static getListById(id) {
        return this.client.get('/GetInventoryListById/' + id);
    }
    static createList(name) {
        return this.client.post('/CreateInventoryList', { name: name });
    }
    static updateList(id, name) {
        return this.client.put('/UpdateInventoryList/' + id, { name: name });
    }
    static deleteList(id) {
        return this.client.delete('/deleteInventoryList/' + id);
    }
    static updatePricesOnList(id) {
        return this.client.put('/updatePricesOnList/' + id);
    }
    static getArchiveList() {
        return this.client.get('/GetInventoryArchiveList');
    }
    static createInventoryArchive(inventoryArchive) {
        return this.client.put('/CreateInventoryArchive', inventoryArchive);
    }
    static deleteInventoryArchive(id) {
        return this.client.delete('/DeleteInventoryArchive/' + id);
    }
    /*
    FAVORITE LIST
    */
    static createFavoriteList(id) {
        return this.client.post('/CreateFavoriteList/' + id);
    }
    /*
        Group
    */
    static createGroup(listId, name) {
        return this.client.post('/CreateInventoryGroup', {
            listId: listId,
            name: name
        });
    }
    static updateGroup(id, name, sorting) {
        return this.client.put('/UpdateInventoryListGroup/' + id, {
            name: name,
            sorting: sorting
        });
    }
    static deleteGroup(id) {
        return this.client.delete('/DeleteInventoryListGroup/' + id);
    }
    /*
        Line
    */
    static createLine(listId, line) {
        return this.client.post('/CreateInventoryListLine/' + listId, line);
    }
    static createLines(listId, lines) {
        return this.client.post('/CreateInventoryListLines/' + listId, lines);
    }
    static updateLine(id, line, forcePriceUpdate) {
        return this.client.put('/UpdateInventoryListLine/' + id + '/?forcePriceUpdate=' + forcePriceUpdate, line);
    }
    static updateLineSort(id, list) {
        return this.client.put('/UpdateInventoryListSort/' + id, list);
    }
    static deleteLine(id) {
        return this.client.delete('/DeleteInventoryListLine/' + id);
    }
    static resetLineToDefault(id) {
        return this.client.post('/ResetLineToDefault/' + id);
    }
    /* Own Lines */
    static createOwnLine(listId, line) {
        return this.client.post('/CreateInventoryListLineForNewProduct/' + listId, line);
    }
    static updateOwnLine(line) {
        return this.client.put('/UpdateNewProductOnInventoryListLine/' + line.id, line);
    }
    /* Reset Lines Amount */
    static resetAmounts(listId) {
        return this.client.post('/ResetAmountOnAllLines/' + listId);
    }
    /* CSV List */
    static getUrlForExcel(listId) {
        return '/api/inventoryList/GetListAsExcel/' + listId;
    }
    /* Send Email */
    static sendListAsEmail(listId, emails) {
        return this.client.post('/SendList/' + listId, { emails: emails });
    }
    /*
        Orders
    */
    static getLinesByOrderDate(fromDate) {
        return this.client.get('/GetOrderLinesByDate?fromDate=' + fromDate);
    }
    static getSaleStatisticsLinesByDate(fromDate, listId) {
        return this.client.get('/GetSaleStatisticsLinesByDate?fromDate=' + fromDate + '&listId=' + listId);
    }
    /*
        FavoriteList
    */
    static addProductsFromFavoritelist(listId, favoriteListIds, groupId) {
        return this.client.post('/AddProductsByFavoritelists/', {
            listId: listId,
            favoriteListIds: favoriteListIds,
            groupId: groupId
        });
    }
    /*
        Search
    */
    static search(query, listId) {
        return this.client.get('/Search', {
            params: {
                query: query,
                listId: listId
            }
        });
    }
    /*
        Autolager
    */
    static autolager(fromDate) {
        return this.client.post('/Autolager', fromDate);
    }
}
