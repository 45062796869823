import { Api } from './Api';
export class SearchApi {
    static client = Api.client('/api/search');
    /*
        Search
     */
    static search(input) {
        return this.client.post('/search', input);
    }
    /*
        Get search suggestions
    */
    static getSuggestions(input) {
        return this.client.post('/search?suggestions=true', input);
    }
}
