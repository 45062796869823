var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { ToastService } from "services/ToastService";
import { InventoryService } from "services/InventoryService";
import Loader from 'components/utility/Loader.vue';
import { EventTypes } from "../../../models/EventTypes";
import { ItemStatus } from "models/product/ItemStatus";
import { InventoryGroup } from "models/inventory/InventoryGroup";
import { PermissionService } from "services/PermissionService";
import { PermissionLevel } from "models/user/PermissionLevel";
let InventoryProductSortModal = class InventoryProductSortModal extends Vue {
    list;
    transferLineIndex = null;
    transferGroupIndex = null;
    transferLine = null;
    transferGroup = null;
    lastLineIndex = null;
    lastGroupIndex = null;
    itemStatus = ItemStatus;
    loading = false;
    disableLines = false;
    showPrice = PermissionService.hasPermission(PermissionLevel.ShowPrice);
    save() {
        this.loading = true;
        this.list.groups.forEach((group, index) => {
            group.lines.forEach((line, indexLine) => {
                line.sorting = indexLine + 1;
            });
            group.sorting = index + 1;
        });
        // put again list in linesNotInGroup
        this.list.linesNotInGroup = this.list.groups.pop().lines;
        InventoryService.updateListSort(this.list.id, this.list).then((response) => {
            ToastService.success(Vue.filter("translate")("webshop_inventory_table_product_toasts_updatedSorting"));
            this.$root.$emit(EventTypes.InventoryModalProductSortClose);
            this.$root.$emit(EventTypes.InventoryReloadList, response.id, true);
        }).catch(() => {
            ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
            let linesNotInGroup = new InventoryGroup();
            linesNotInGroup.lines = this.list.linesNotInGroup;
            this.list.groups.push(linesNotInGroup);
            this.loading = false;
        });
    }
    sortByName() {
        var copyList = this.list;
        copyList.groups.forEach((group) => {
            group.lines = group.lines.sort((a, b) => a.description.localeCompare(b.description));
        });
    }
    close() {
        this.$root.$emit(EventTypes.InventoryModalProductSortClose);
    }
    mounted() {
        // we have linesNotInGroup and user want sort that lines or move some lines in group
        // next three lines put that lines in group on last place and before save we will put again in linesNotInGroup
        // because on this way is easy to handle linesNotInGroup
        // on html we will block some operations with linesNotInGroup
        let linesNotInGroup = new InventoryGroup();
        linesNotInGroup.lines = this.list.linesNotInGroup;
        this.list.groups.push(linesNotInGroup);
    }
    allowDrop(event) {
        if (this.disableLines) {
            event.stopPropagation();
        }
        else {
            event.preventDefault();
        }
    }
    allowDropGroup(event, index) {
        // linesNotInGroup we cant sort with groups
        if (index === this.list.groups.length - 1 && this.disableLines) {
            event.stopPropagation();
        }
        else {
            event.preventDefault();
        }
    }
    // DragDrop events
    drag(index, indexGroup) {
        this.transferLineIndex = index;
        this.transferGroupIndex = indexGroup;
        this.transferLine = this.list.groups[indexGroup].lines[index];
    }
    drop(index, indexGroup) {
        if (this.lastGroupIndex !== null && indexGroup !== this.lastGroupIndex) {
            this.list.groups[indexGroup].lines.splice(index, 1);
            this.list.groups[this.lastGroupIndex].lines.push(this.transferLine);
        }
        else if (this.lastLineIndex !== null) {
            this.list.groups[indexGroup].lines.splice(index, 1);
            this.list.groups[this.lastGroupIndex].lines.splice(this.lastLineIndex, 0, this.transferLine);
        }
        this.transferLineIndex = null;
        this.transferGroupIndex = null;
        this.lastLineIndex = null;
        this.lastGroupIndex = null;
    }
    over(index, indexGroup) {
        if (this.lastLineIndex !== index) {
            this.lastLineIndex = index;
            this.lastGroupIndex = indexGroup;
        }
    }
    leave(index, indexGroup) {
        this.lastLineIndex = null;
        this.lastGroupIndex = null;
    }
    dragGroup(index) {
        this.disableLines = true;
        this.transferGroupIndex = index;
        this.transferGroup = this.list.groups[index];
    }
    dropGroup(index) {
        if (this.lastGroupIndex !== null && this.lastGroupIndex < this.list.groups.length - 1) {
            this.list.groups.splice(index, 1);
            this.list.groups.splice(this.lastGroupIndex, 0, this.transferGroup);
        }
        this.lastGroupIndex = null;
        this.transferGroupIndex = null;
        this.disableLines = false;
    }
    overGroup(index) {
        if (this.lastGroupIndex !== index) {
            this.lastGroupIndex = index;
        }
    }
    leaveGroup(index) {
        this.lastGroupIndex = null;
    }
};
InventoryProductSortModal = __decorate([
    Component({
        components: { Loader },
        props: {
            list: Object
        }
    })
], InventoryProductSortModal);
export default InventoryProductSortModal;
