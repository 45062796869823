var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-overlay active" }, [
    _c("div", { staticClass: "modal grey" }, [
      _c("div", { staticClass: "modal-header" }, [
        _c("h1", { staticClass: "header-medium" }, [
          _vm._v(_vm._s(_vm.settings.title)),
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "modal-close",
            on: {
              click: function ($event) {
                return _vm.close()
              },
            },
          },
          [_c("i", { staticClass: "mdi mdi-close-circle-outline" })]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-content" }, [
        _c("div", { domProps: { innerHTML: _vm._s(_vm.settings.content) } }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "modal-footer-buttons modal-footer-buttons" },
          [
            _c(
              "button",
              {
                staticClass: "ui-button ui-button-small",
                on: {
                  click: function ($event) {
                    return _vm.close()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.settings.cancelButtonText))]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "ui-button ui-button-small",
                class: _vm.settings.acceptButtonClass,
                on: {
                  click: function ($event) {
                    return _vm.accept()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.settings.acceptButtonText))]
            ),
          ]
        ),
        _vm._v(" "),
        _vm.loading
          ? _c("div", { staticClass: "inventory-loader" }, [_vm._m(0)])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "simple-loader-ui" }, [
      _c("div", { staticClass: "simple-loader dark" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }