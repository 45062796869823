import { Api } from './Api';
export class CalculationApi {
    static client = Api.client('/api/Calculation');
    static GetCalculations() {
        return this.client.get('/GetCalculations');
    }
    static GetPossibleCalculations(id) {
        return this.client.get('/GetPossibleCalculations/' + id);
    }
    static GetCalculationById(id) {
        return this.client.get('/GetCalculationById/' + id);
    }
    static CopyCalculation(copyDetails) {
        return this.client.get('/CopyCalculation/' + copyDetails.calculationId, {
            params: {
                deepCopying: copyDetails.deepCopying
            }
        });
    }
    static GetCalculationTreeStructure(id) {
        return this.client.get('/GetCalculationTreeStructure/' + id);
    }
    static ShareCalculation(id, share, deepSharing) {
        return this.client.get('/ShareCalculation/' + id, {
            params: {
                share: share,
                deepSharing: deepSharing
            }
        });
    }
    static UploadImage(calculationId, formData) {
        return this.client.post('/UploadImage/' + calculationId, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
    static CreateCalculation(calculation) {
        return this.client.post('/CreateCalculation/', calculation);
    }
    static UpdateCalculation(calculation) {
        return this.client.put('/UpdateCalculation/', calculation);
    }
    static UpdateCalculationCoverage(calculation, grossProfit = false) {
        return this.client.put('/UpdateCalculationCoverage/', calculation, {
            params: {
                grossProfit: grossProfit
            }
        });
    }
    static DeleteCalculation(id) {
        return this.client.delete('/DeleteCalculation/' + id);
    }
    static AddProductLines(calculationId, productIds) {
        return this.client.post('/AddProductLines/' + calculationId, productIds);
    }
    static AddCustomerItem(calculationId, customerItem) {
        return this.client.post('/AddCustomerItem/' + calculationId, customerItem);
    }
    static AddFromFavoriteLists(calculationId, favoriteLists) {
        return this.client.post('/AddFromFavoriteLists/' + calculationId, favoriteLists);
    }
    static AddCalculationLines(calculationId, calculationIds) {
        return this.client.post('/AddCalculationLines/' + calculationId, calculationIds);
    }
    static UpdateCalculationLine(calculationLine) {
        return this.client.put('/UpdateCalculationLine/', calculationLine);
    }
    static DeleteCalculationLine(calculationLineId) {
        return this.client.delete('/DeleteCalculationLine/' + calculationLineId);
    }
    static GetConvertedOrderlines(id, multiplier) {
        return this.client.get('/GetConvertedOrderlines/' + id, {
            params: {
                multiplier: multiplier
            }
        });
    }
    static CreateOrderFromCalculation(calculationId, orderlines) {
        return this.client.post('/CreateOrderFromCalculation/' + calculationId, orderlines);
    }
    static AddCalculationInActiveOrder(calculationId, orderlines) {
        return this.client.post('/AddCalculationInActiveOrder/' + calculationId, orderlines);
    }
    static searchNewProductsByQuery(query, listId) {
        return this.client.get('/SearchNewProductsByQuery', {
            params: {
                query: query,
                listId: listId
            }
        });
    }
    static searchNewProductsBySales(fromDate, listId) {
        return this.client.get('/SearchNewProductsBySales', {
            params: {
                fromDate: fromDate,
                listId: listId
            }
        });
    }
}
