export class WebShopItemUnit {
    name;
    description;
    quantityPerUnit;
    barCode;
    price;
    stdUnit;
    weighed;
    priceType;
}
