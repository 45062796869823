var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import CustomTag from 'components/shared/CustomTag.vue';
import { ToastService } from 'services/ToastService';
import { InventoryService } from 'services/InventoryService';
import { EventTypes } from 'models/EventTypes';
let InventoryEmailModal = class InventoryEmailModal extends Vue {
    loading = false;
    list;
    emails = [];
    send() {
        if (!this.emails || this.emails.length == 0) {
            ToastService.danger(Vue.filter('translate')('webshop_inventory_editmodals_email_toastAddEmail'));
            return;
        }
        this.loading = true;
        InventoryService.sendListAsEmail(this.list.id, this.emails).then(() => {
            ToastService.success(Vue.filter('translate')('webshop_inventory_editmodals_email_toastListSend', this.emails.length));
            this.$root.$emit(EventTypes.InventoryModalEmailClose);
        }).catch(err => {
            this.loading = false;
            ToastService.danger(Vue.filter('translate')('webshop_defaults_toast_errorText'));
        });
    }
    close() {
        this.$root.$emit(EventTypes.InventoryModalEmailClose);
    }
};
InventoryEmailModal = __decorate([
    Component({
        components: {
            CustomTag
        },
        props: {
            list: Object
        }
    })
], InventoryEmailModal);
export default InventoryEmailModal;
