var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { ToastService } from 'services/ToastService';
import { ProductService } from 'services/ProductService';
import { PermissionService } from 'services/PermissionService';
import { PermissionLevel } from 'models/user/PermissionLevel';
import InputNumber from "components/shared/InputNumber.vue";
import { CalculationLineVM } from 'models/calculation/CalculationLineVM';
import { WebShopItemUnit } from 'models/order/WebShopItemUnit';
import { CalculationLineUnits } from 'models/calculation/CalculationLineUnits';
import LoadingButton from 'components/shared/LoadingButton.vue';
let CalculationModalCustomerItemForm = class CalculationModalCustomerItemForm extends Vue {
    loading;
    setLoading;
    model = new CalculationLineVM();
    units = [];
    list;
    line;
    CalculationLineUnits = CalculationLineUnits;
    disabled;
    showPrice = PermissionService.hasPermission(PermissionLevel.ShowPrice);
    mounted() {
        this.setLoading(true);
        ProductService.getAllUnits().then(data => {
            this.units = data;
            this.setLoading(false);
            if (this.line && this.line.calculationLineId) {
                this.model = Object.assign(new CalculationLineVM(), this.line);
                this.model.salesUnitName = this.model.salesUnit.name;
            }
            else {
                this.model.calculationId = this.list.calculationId;
            }
        })
            .catch(err => {
            this.setLoading(false);
            ToastService.danger(Vue.filter('translate')('webshop_defaults_toast_errorText'));
        });
    }
    submit() {
        this.$emit('save', this.model);
    }
    onSelectUnit() {
        const unitIndex = this.units.findIndex((x) => x.name == this.model.salesUnitName);
        if (unitIndex >= 0) {
            let itemUnit = new WebShopItemUnit();
            itemUnit.description = this.units[unitIndex].description;
            itemUnit.name = this.units[unitIndex].name;
            this.model.salesUnit = itemUnit;
        }
    }
    closeModal() {
        this.$emit('close');
    }
};
CalculationModalCustomerItemForm = __decorate([
    Component({
        components: {
            InputNumber,
            LoadingButton
        },
        props: {
            setLoading: Function,
            loading: Boolean,
            list: Object,
            line: Object,
            disabled: Boolean,
            saveBtnText: String,
            saveBtnClass: String
        }
    })
], CalculationModalCustomerItemForm);
export default CalculationModalCustomerItemForm;
