var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import Loader from 'components/utility/Loader.vue';
import LoadingButton from 'components/shared/LoadingButton.vue';
import InputNumber from 'components/shared/InputNumber.vue';
import InputSelect from 'components/shared/InputSelect.vue';
import { CampaignPlanningLineVM } from "models/campaignPlanning/CampaignPlanningLineVM";
import { ProductVM } from "models/product/ProductVM";
import { CampaignPlanningLineStatus, CampaignPlanningLineWaitingForStatus } from "models/campaignPlanning/CampaignPlanningLineStatus";
import { ToastService } from "services/ToastService";
import { ProductUnitType } from "models/product/ProductUnitType";
import { getWeekNumber } from 'helpers/WeekNumber';
import { CampaignPlanningVM } from "models/campaignPlanning/CampaignPlanningVM";
import { CampaignPlanningCommentVM } from "models/campaignPlanning/CampaignPlanningCommentVM";
import { VendorService } from "services/VendorService";
let CampaignPlanningLineModal = class CampaignPlanningLineModal extends Vue {
    data;
    selectedProduct = new ProductVM();
    selectedUnit = new ProductUnitType();
    units = new Array();
    errorVendorProductId = false;
    errorUnit = false;
    comment = "";
    mounted() {
        this.initDataValues();
        this.data.campaignLine.campaignCode = this.data.campaign.code;
    }
    initDataValues() {
        let vendorProducts = this.$store.state.VendorCampaignPlanning.vendorProducts;
        let product = vendorProducts.find((x) => x.productId == this.data.campaignLine.itemNo);
        this.units = this.$store.state.VendorCampaignPlanning.units;
        this.selectedProduct = null;
        if (product != null) {
            this.selectedProduct = product;
            //this.data.campaignLine.campaignUnitPrice = this.data.campaignLine.campaignPrice / this.selectedProduct.factorPriceKgPiece;
        }
        if (!this.data.isNew)
            this.selectedUnit = this.units.find((x) => x.name.trim() == this.data.campaignLine.unit.trim());
        if (this.data.campaignLine.waitingFor == CampaignPlanningLineWaitingForStatus.WaitingForVendor) {
            const campaignLine = Object.assign(new CampaignPlanningVM(), this.data.campaignLine);
            campaignLine.waitingFor = CampaignPlanningLineWaitingForStatus.WaitingForNoOne;
            this.$store.dispatch('VendorCampaignPlanning/changeCampaignPlanningLineWaitingFor', [campaignLine]);
        }
    }
    close() {
        this.$emit('close-modal');
    }
    submitForm(evt) {
        if (this.selectedUnit == undefined || this.selectedUnit.name == undefined)
            this.errorUnit = true;
        if (this.data.campaignLine.vendorItemNo == '' || this.data.campaignLine.vendorItemNo == null)
            this.errorVendorProductId = true;
        if (this.errorUnit || this.errorVendorProductId)
            return;
        if (this.data.isNew)
            this.createLine();
        else
            this.updateLine();
    }
    createLine() {
        this.$store.dispatch('VendorCampaignPlanning/createCampaignPlanningLine', this.data.campaignLine).then((res) => {
            ToastService.success(Vue.filter('translate')(this.data.campaignLine.description + ' lagt till kampanj!'));
            if (this.comment.length > 0)
                this.sendMessage(res);
            this.$emit('close-modal');
        }).catch((err) => {
            ToastService.danger(Vue.filter('translate')('webshop_defaults_toast_errorText'));
        });
    }
    updateLine() {
        this.$store.dispatch('VendorCampaignPlanning/updateCampaignPlanningLine', this.data.campaignLine).then((res) => {
            ToastService.success(Vue.filter('translate')(this.data.campaignLine.description + ' har ändrat!'));
            if (this.comment.length > 0)
                this.sendMessage(res);
            this.$emit('close-modal');
        }).catch((err) => {
            ToastService.danger(Vue.filter('translate')('webshop_defaults_toast_errorText'));
        });
    }
    sendMessage(campaignLine) {
        let newComment = new CampaignPlanningCommentVM();
        newComment.campaignCode = campaignLine.campaignCode;
        newComment.vendorNo = campaignLine.vendorNo;
        newComment.vendorItemNo = campaignLine.vendorItemNo;
        newComment.comment = this.comment;
        VendorService.writeComment(newComment)
            .then((res) => { });
    }
    selectProduct() {
        this.selectedUnit = new ProductUnitType();
        if (this.selectedProduct == null) {
            this.data.campaignLine = new CampaignPlanningLineVM();
            this.data.campaignLine.campaignCode = this.data.campaign.code;
        }
        else {
            this.data.campaignLine.vendorItemNo = this.selectedProduct.supplierProductId;
            this.data.campaignLine.description = this.selectedProduct.description;
            this.data.campaignLine.itemNo = this.selectedProduct.productId;
            this.data.campaignLine.unitCost = this.selectedProduct.centralPurchasePrice;
            this.data.campaignLine.campaignUnitCost = this.selectedProduct.centralPurchasePrice;
            this.data.campaignLine.vendorNo = this.selectedProduct.supplierCode;
            this.selectedUnit = this.units.find(x => x.name.trim() == this.selectedProduct.basicUnit.trim());
            this.selectUnit();
            this.data.campaignLine.campaignDiscountPct = 0;
            this.data.campaignLine.campaignDiscountLcy = 0;
        }
    }
    selectUnit() {
        if (this.selectedUnit != undefined && this.selectedUnit.name != undefined) {
            this.data.campaignLine.unit = this.selectedUnit.name;
            this.errorUnit = false;
        }
    }
    changeVendorProductId() {
        if (this.data.campaignLine.vendorItemNo)
            this.errorVendorProductId = false;
        else
            this.errorVendorProductId = true;
    }
    updateDiscountLcy() {
        if (this.data.campaignLine.itemNo == '' && this.data.campaignLine.campaignDiscountPct > 0) {
            this.data.campaignLine.campaignDiscountLcy = 0;
            return;
        }
        if (this.data.campaignLine.unitCost > 0)
            this.data.campaignLine.campaignDiscountLcy = this.data.campaignLine.unitCost * this.data.campaignLine.campaignDiscountPct / 100;
        this.data.campaignLine.campaignUnitCost = this.data.campaignLine.unitCost - this.data.campaignLine.campaignDiscountLcy;
    }
    updateDiscountPct() {
        if (this.data.campaignLine.itemNo == '' && this.data.campaignLine.campaignDiscountLcy > 0) {
            this.data.campaignLine.campaignDiscountPct = 0;
            return;
        }
        if (this.data.campaignLine.unitCost > 0)
            this.data.campaignLine.campaignDiscountPct = 100 * this.data.campaignLine.campaignDiscountLcy / this.data.campaignLine.unitCost;
        this.data.campaignLine.campaignUnitCost = this.data.campaignLine.unitCost - this.data.campaignLine.campaignDiscountLcy;
    }
    get vendorProducts() {
        let list = this.$store.state.VendorCampaignPlanning.vendorProducts;
        if (list != null && list.length > 0) {
            if (this.data.isNew) {
                list = list.filter((x) => {
                    let lineIndex = this.data.campaign.campaignPlaningLines.findIndex(line => line.itemNo == x.productId && line.vendorItemNo == x.supplierProductId);
                    if (lineIndex >= 0)
                        return false;
                    return true;
                });
            }
            return list;
        }
        return new Array();
    }
    get loading() {
        return this.$store.state.VendorCampaignPlanning.loading;
    }
    get disabledByLineStatus() {
        let lineStatus = this.data.campaignLine.status;
        return lineStatus == CampaignPlanningLineStatus.Approved || lineStatus == CampaignPlanningLineStatus.Booked || lineStatus == CampaignPlanningLineStatus.Rejected;
    }
    get enablePriceChange() {
        let lineStatus = this.data.campaignLine.status;
        return lineStatus == CampaignPlanningLineStatus.WaitingPrice;
    }
    get purchaseWeek() {
        let startingDate = new Date(this.data.campaign.purchaseStartingDate);
        let endingDate = new Date(this.data.campaign.purchaseEndingDate);
        let weekStart = getWeekNumber(startingDate);
        let weekEnd = getWeekNumber(endingDate);
        return 'v. ' + startingDate.getFullYear() + "/" + weekStart + ' - ' + endingDate.getFullYear() + "/" + weekEnd;
    }
    get salesWeek() {
        let startingDate = new Date(this.data.campaign.salesStartingDate);
        let endingDate = new Date(this.data.campaign.salesEndingDate);
        let weekStart = getWeekNumber(startingDate);
        let weekEnd = getWeekNumber(endingDate);
        return 'v. ' + startingDate.getFullYear() + '/' + weekStart + ' - ' + endingDate.getFullYear() + "/" + weekEnd;
    }
    get priceWithDiscount() {
        if (this.data.campaignLine.campaignUnitCost)
            return this.data.campaignLine.campaignUnitCost - this.data.campaignLine.campaignDiscountLcy;
    }
};
CampaignPlanningLineModal = __decorate([
    Component({
        components: {
            Loader,
            LoadingButton,
            InputNumber,
            InputSelect,
        },
        props: {
            data: Object
        }
    })
], CampaignPlanningLineModal);
export default CampaignPlanningLineModal;
