var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.campaign && _vm.campaign.code
    ? _c("div", [
        _c("div", { staticClass: "inventory-list-header" }, [
          _c("div", { attrs: { grid: "wrap" } }, [
            _c("div", { attrs: { column: "s-12" } }, [
              _c("span", { staticClass: "label" }, [
                _vm._v(_vm._s(_vm.campaign.code)),
              ]),
              _vm._v(" "),
              _c("h1", { staticClass: "header-medium" }, [
                _vm._v(_vm._s(_vm.campaign.description)),
              ]),
              _vm._v(" "),
              _vm.isValidPurchaseDeadline() && _vm.campaign.active
                ? _c(
                    "a",
                    {
                      staticClass: "ui-button ui-button-flat ui-button-small",
                      class: { "ui-button-disabled": _vm.isAddProductDisabled },
                      attrs: { title: _vm.disabledAddProductTitle },
                      on: {
                        click: function ($event) {
                          return _vm.addProduct()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "mdi mdi-plus" }),
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm._f("translate")(
                              "webshop_vendor_campaignPlanning_campaignLines_buttonAddItem"
                            )
                          ) +
                          "\n                "
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }