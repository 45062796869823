var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import InventoryProductOwnFormPartial from './partials/InventoryProductOwnFormPartial.vue';
import { ToastService } from 'services/ToastService';
import { InventoryService } from 'services/InventoryService';
import { EventTypes } from 'models/EventTypes';
let InventoryProductDeleteModal = class InventoryProductDeleteModal extends Vue {
    loading = false;
    list;
    line;
    deleteText = Vue.filter('translate')('webshop_general_delete');
    setLoading(value) {
        this.loading = value;
    }
    close() {
        this.$root.$emit(EventTypes.InventoryModalProductDeleteClose);
    }
    deleteProduct() {
        this.setLoading(true);
        InventoryService.deleteLine(this.line.id).then(() => {
            this.$root.$emit(EventTypes.InventoryLineDeleted, this.line);
            ToastService.success(Vue.filter('translate')('webshop_inventory_addmodals_deleteProduct_toast'));
            this.$root.$emit(EventTypes.InventoryModalProductDeleteClose);
        }).catch(err => {
            this.setLoading(false);
            ToastService.danger(Vue.filter('translate')('webshop_defaults_toast_errorText'));
        });
    }
};
InventoryProductDeleteModal = __decorate([
    Component({
        components: {
            InventoryProductOwnFormPartial
        },
        props: {
            list: Object,
            line: Object
        }
    })
], InventoryProductDeleteModal);
export default InventoryProductDeleteModal;
