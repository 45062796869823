var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "breadcrumb" }, [
    _c("p", [
      _vm._v(
        "\n        " +
          _vm._s(
            _vm._f("translate")("webshop_search_search_breadcrumb_youAreHere")
          ) +
          ":\n        "
      ),
      _c(
        "a",
        {
          class: {
            active:
              !_vm.isTopp200 &&
              !_vm.input.favoriteListId &&
              !_vm.input.categoryId &&
              !_vm.input.groupId,
          },
          on: {
            click: function ($event) {
              return _vm.selectRoot()
            },
          },
        },
        [_vm._v(_vm._s(_vm.rootText))]
      ),
      _vm._v(" "),
      _vm.input.favoriteListId && _vm.favoriteList
        ? _c("span", [
            _c("span", [_vm._v("/")]),
            _vm._v(" "),
            _c(
              "a",
              {
                class: {
                  active:
                    _vm.input.favoriteListId &&
                    !_vm.input.categoryId &&
                    !_vm.input.groupId,
                },
                on: {
                  click: function ($event) {
                    return _vm.selectFavoriteListOrTopp200()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.favoriteList.listName))]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isTopp200
        ? _c("span", [
            _c("span", [_vm._v("/")]),
            _vm._v(" "),
            _c(
              "a",
              {
                class: {
                  active:
                    _vm.isTopp200 &&
                    !_vm.input.categoryId &&
                    !_vm.input.groupId,
                },
                on: {
                  click: function ($event) {
                    return _vm.selectFavoriteListOrTopp200()
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm._f("translate")("webshop_search_search_filters_top_200")
                  )
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.input.categoryId && _vm.category
        ? _c("span", [
            _c("span", [_vm._v("/")]),
            _vm._v(" "),
            _c(
              "a",
              {
                class: { active: _vm.input.categoryId && !_vm.input.groupId },
                on: {
                  click: function ($event) {
                    return _vm.selectCategory()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.category.description))]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.input.groupId && _vm.group
        ? _c("span", [
            _c("span", [_vm._v("/")]),
            _vm._v(" "),
            _c(
              "span",
              { class: { active: _vm.input.categoryId && _vm.input.groupId } },
              [_vm._v(_vm._s(_vm.group.description))]
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }