var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "slide-date-picker" }, [
    _c("p", [
      _vm._v(
        _vm._s(
          _vm._f("translate")(
            "webshop_calculations_modals_addProducts_order_datepickerText"
          )
        )
      ),
      _c("br"),
      _c("br"),
    ]),
    _vm._v(" "),
    _c(
      "form",
      { staticClass: "ui-form" },
      [
        _c("flat-pickr", {
          staticClass: "date-picker-inline",
          attrs: { config: _vm.dpSettings },
          on: { "on-change": _vm.pickedDate },
          model: {
            value: _vm.selectedDate,
            callback: function ($$v) {
              _vm.selectedDate = $$v
            },
            expression: "selectedDate",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }