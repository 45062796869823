var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { WarningType } from 'models/order/WarningType';
let BasketOrderlineWarnings = class BasketOrderlineWarnings extends Vue {
    warning;
    stockWarnings;
    errorMessage = "";
    mounted() {
        this.errorMessage = this.warning.message;
        if (this.warning.type === WarningType.outOfStock) {
            let warningObj = JSON.parse(this.warning.message);
            if (warningObj.stock === 0) {
                this.errorMessage = this.$options.filters.translate('webshop_order_warnings_outOfStock_stockZero');
            }
            else {
                this.errorMessage = this.$options.filters.translate('webshop_order_warnings_outOfStock_amountLimitReached', this.$options.filters.currency(warningObj.stock));
            }
            if (warningObj.unit != undefined && warningObj.sellOutLineUnit != undefined && warningObj.unit != warningObj.sellOutLineUnit) {
                this.errorMessage = this.$options.filters.translate('webshop_order_warnings_unitMisMatch', warningObj.productId);
            }
        }
        else if (this.warning.type == WarningType.highPrice) {
            this.errorMessage = Vue.filter("translate")("webshop_order_warnings_highPrice");
        }
        else if (this.warning.type == WarningType.unitMisMatch) {
            this.errorMessage = Vue.filter("translate")("webshop_order_warnings_unitMisMatch");
        }
        else if (this.warning.type == WarningType.unitRemoved) {
            this.errorMessage = Vue.filter("translate")("webshop_order_warnings_unitRemoved");
        }
        else if (this.warning.type == WarningType.highDiscountPrice) {
            this.errorMessage = Vue.filter("translate")("webshop_order_warnings_highDiscountPrice");
        }
        else if (this.warning.type == WarningType.expired) {
            this.errorMessage = Vue.filter("translate")("webshop_order_warnings_expired");
        }
    }
};
BasketOrderlineWarnings = __decorate([
    Component({
        props: {
            warning: Object
        }
    })
], BasketOrderlineWarnings);
export default BasketOrderlineWarnings;
