var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EventTypes } from 'models/EventTypes';
import Vue from 'vue';
import Component from 'vue-class-component';
let DepictAfterBasketModal = class DepictAfterBasketModal extends Vue {
    products = new Array();
    timeoutId;
    mounted() {
        this.$root.$on(EventTypes.MiniCartUpdate, this.showAfterBasketModal);
    }
    beforeDestroy() {
        this.$root.$off(EventTypes.MiniCartUpdate);
    }
    showAfterBasketModal(previewOrder, products) {
        this.products = products;
        this.timeoutId = setTimeout(() => {
            this.close();
        }, 4000);
    }
    close() {
        this.products = new Array();
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        this.timeoutId = null;
    }
    focus() {
        clearTimeout(this.timeoutId);
    }
};
DepictAfterBasketModal = __decorate([
    Component({
        props: {}
    })
], DepictAfterBasketModal);
export default DepictAfterBasketModal;
