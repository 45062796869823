var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "delivery-modal" }, [
    _c(
      "div",
      { staticClass: "ui-modal-content" },
      [
        _c("h3", { staticClass: "ui-modal-header" }, [
          _vm._v(
            _vm._s(
              !_vm.showError
                ? _vm.$options.filters.translate(
                    "webshop_modals_deliveryDate_modalHeader"
                  )
                : _vm.$options.filters.translate(
                    "webshop_modals_deliveryDate_getDatesErrorHeader"
                  )
            )
          ),
        ]),
        _vm._v(" "),
        !_vm.showError && _vm.deliveryDates
          ? _c("delivery-date-picker", {
              attrs: { "delivery-dates": _vm.deliveryDates },
            })
          : _vm.showError
          ? _c("p", [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm._f("translate")(
                      "webshop_modals_deliveryDate_getDatesErrorText"
                    )
                  ) +
                  "\n        "
              ),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }