export class ReviewOrder {
    orderId;
    selectedDeliveryDay;
    pickupOrder;
    totalOrderPrice;
    totalEcoPct;
    totalAgreementPct;
    splitOrders;
    depositOrder;
    obsoleteProducts;
    blockedProducts;
    expiryProducts;
    unitRemovedProducts;
    discountCodes;
    punchOutForm;
    constructor() {
        this.orderId = 0;
        this.selectedDeliveryDay = null;
        this.pickupOrder = null;
        this.totalOrderPrice = null;
        this.totalEcoPct = null;
        this.totalAgreementPct = null;
        this.splitOrders = [];
        this.depositOrder = null;
        this.obsoleteProducts;
        this.blockedProducts;
        this.expiryProducts;
        this.unitRemovedProducts = false;
        this.discountCodes = [];
        this.punchOutForm = null;
    }
    static fromApi(reviewOrder) {
        return Object.assign(new ReviewOrder(), reviewOrder);
    }
}
