var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-overlay active" }, [
    _c("div", { staticClass: "modal grey" }, [
      _c("div", { staticClass: "modal-header" }, [
        _c("h1", { staticClass: "header-medium" }, [
          _vm._v(
            _vm._s(
              _vm._f("translate")("webshop_inventory_editmodals_email_title")
            )
          ),
        ]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "modal-close",
            on: {
              click: function ($event) {
                return _vm.close()
              },
            },
          },
          [_c("i", { staticClass: "mdi mdi-close-circle-outline" })]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal-content clearfix" }, [
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.send()
              },
            },
          },
          [
            _c(
              "div",
              [
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_inventory_editmodals_email_description"
                      )
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("custom-tag", {
                  attrs: {
                    placeholder: _vm.$options.filters.translate(
                      "webshop_inventory_editmodals_email_placeholder"
                    ),
                    validate: "email",
                    addTagOnBlur: true,
                  },
                  model: {
                    value: _vm.emails,
                    callback: function ($$v) {
                      _vm.emails = $$v
                    },
                    expression: "emails",
                  },
                }),
                _vm._v(" "),
                _c("br"),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer-buttons" }, [
              _c(
                "button",
                {
                  staticClass: "ui-button ui-button-small",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm._f("translate")("webshop_general_cancel")))]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "ui-button ui-button-small ui-button-accept",
                  attrs: { type: "submit" },
                },
                [_vm._v(_vm._s(_vm._f("translate")("webshop_general_send")))]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.loading
          ? _c("div", { staticClass: "inventory-loader" }, [_vm._m(0)])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "simple-loader-ui" }, [
      _c("div", { staticClass: "simple-loader dark" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }