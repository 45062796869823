var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import ShopGalleryItem from '../shop/partials/ShopGalleryItem.vue';
import { SearchInput } from 'models/search/SearchInput';
import { SearchService } from 'services/SearchService';
import { MarkingTypes } from '../../models/search/MarkingTypes';
let TodayOffersGrid = class TodayOffersGrid extends Vue {
    loading = false;
    loaded = false;
    products = [];
    mounted() {
        this.loadProducts();
        this.$store.subscribe((mutation) => {
            if (mutation.type === 'Orders/setActiveOrder') {
                this.loadProducts();
            }
        });
    }
    loadProducts() {
        let input = new SearchInput();
        input.pageSize = 6;
        input.skipPopular = true;
        input.skipFilters = true;
        input.markings = [MarkingTypes.HasCampaign];
        SearchService.search(input)
            .then(data => {
            this.loaded = true;
            this.products = data.products;
        }).catch(err => {
            this.loaded = true;
        });
    }
};
TodayOffersGrid = __decorate([
    Component({
        components: {
            ShopGalleryItem
        }
    })
], TodayOffersGrid);
export default TodayOffersGrid;
