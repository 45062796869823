var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import VueRouter from "vue-router";
import Component from "vue-class-component";
import { EventTypes } from "../../models/EventTypes";
import InventoryArchiveResult from "./result/InventoryArchiveResult.vue";
import { InventoryService } from "../../services/InventoryService";
const routes = [{
        name: "inventory-archive",
        path: "/webbshop/min-sida/inventeringar"
    }];
const router = new VueRouter({
    mode: "history",
    routes: routes
});
let InventoryArchive = class InventoryArchive extends Vue {
    loading = false;
    archiveList = [];
    mounted() {
        this.getInventoryArchiveList();
        this.$root.$on(EventTypes.InventoryArchiveDeleteModalClose, this.getInventoryArchiveList);
    }
    beforeDestroy() {
        this.$root.$off(EventTypes.InventoryArchiveDeleteModalClose);
    }
    getInventoryArchiveList() {
        this.loading = true;
        InventoryService.getArchiveList().then((response) => {
            this.archiveList = response;
            this.loading = false;
        });
    }
};
InventoryArchive = __decorate([
    Component({
        components: {
            InventoryArchiveResult
        },
        router: router
    })
], InventoryArchive);
export default InventoryArchive;
