var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "calculations", attrs: { container: "" } }, [
    _c("div", { attrs: { grid: "wrap" } }, [
      _c("div", { attrs: { column: "s-12 m-5" } }, [
        _c("div", { staticClass: "ui-card" }, [
          _c("div", { staticClass: "ui-card-header ui-card-header-inverted" }, [
            _vm._v(_vm._s(_vm.descriptionTitle)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ui-card-content" }, [
            _c("p", { domProps: { innerHTML: _vm._s(_vm.descriptionText) } }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { column: "s-12 m-7" } }, [
        _c("div", { staticClass: "ui-card" }, [
          _c("div", { staticClass: "ui-card-header ui-card-header-inverted" }, [
            _vm._v(
              _vm._s(
                _vm._f("translate")(
                  "webshop_calculations_calculationList_title"
                )
              )
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ui-card-content" },
            [
              _c("calculation-list", {
                attrs: { calculations: _vm.calculations },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { column: "" } },
        [
          _c("router-view", {
            staticClass: "inventory-scroll-point",
            attrs: { loading: _vm.loading },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }