var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import ShopItemIcons from "./ShopItemIcons.vue";
import ShopItemFavoriteMenu from "./ShopItemFavoriteMenu.vue";
import ShopItemBuyButton from "./ShopItemBuyButton.vue";
import ShopReplaceAltProdButton from "./ShopReplaceAltProdButton.vue";
import SuggestionTooltip from '../../shared/SuggestionTooltip.vue';
import { Service } from "services/Service";
import { SearchService } from "services/SearchService";
import { ProductService } from "services/ProductService";
import { EventTypes } from "models/EventTypes";
import { ItemStatus } from "models/product/ItemStatus";
import { Watch } from "vue-property-decorator";
import { ItemAddVM } from "models/product/ItemAddVM";
import { PermissionService } from "services/PermissionService";
import { PermissionLevel } from "models/user/PermissionLevel";
import { PriceType } from "models/product/PriceType";
import InputNumber from "../../shared/InputNumber.vue";
import ShopItemStockStatus from "./ShopItemStockStatus.vue";
import { GAService } from "services/GAService";
import { Settings } from "config/Settings";
import ItemSurchargeWarning from "components/shared/ItemSurchargeWarning.vue";
import HighlightMarkings from "components/shared/HighlightMarkings.vue";
import ShopStockWarning from "./ShopStockWarning.vue";
let ShopProductListItem = class ShopProductListItem extends Vue {
    loading;
    product;
    amount = null;
    selectedUnit = null;
    productLoading = false;
    isAltProduct;
    showLoadingSuccess = false;
    showDeleteLoading = false;
    itemStatus = ItemStatus;
    isOpenShop = SearchService.isOpenShop;
    showPrice = PermissionService.hasPermission(PermissionLevel.ShowPrice);
    enableOrder = PermissionService.hasPermission(PermissionLevel.EnableOrder);
    showAvailableQuantity = PermissionService.hasPermission(PermissionLevel.ShowAvailableQuantity);
    priceType = PriceType;
    punchOut = Settings.profileInfo && Settings.profileInfo.punchOut;
    isPriceWithSurcharge = false;
    leftAmountWithSurcharge = 0;
    flagsBaseLink = "/app/dist2/assets/flags/";
    uiSource;
    position;
    disableMultipleProductsButton;
    created() {
        this.$root.$on(EventTypes.ShopSearchDone, this.searchDone);
    }
    beforeDestroy() {
        this.$root.$off(EventTypes.ShopSearchDone, this.searchDone);
    }
    mounted() {
        this.searchDone();
        this.onAddedAmountChange(null, null);
    }
    onClearAmountChange(newVal, oldVal) {
        if (newVal) {
            this.amount = null;
            this.product.clearAmount = false;
        }
    }
    onAddedAmountChange(newVal, oldVal) {
        this.amount = this.product.addedAmount;
        this.$set(this.product, 'clearAmount', false);
    }
    searchDone() {
        this.selectedUnit = ProductService.getSelectedUnit(this.product);
    }
    setProductLoading(loading) {
        this.productLoading = loading;
    }
    removeProduct() {
        this.showDeleteLoading = true;
        setTimeout(() => {
            this.$root.$emit(EventTypes.ShopRemoveProductFromFavoriteList, this.product);
        }, 400);
    }
    replaceProduct() {
        this.showDeleteLoading = true;
        setTimeout(() => {
            this.$root.$emit(EventTypes.ShopReplaceProductOnFavoriteList, this.product);
        }, 400);
    }
    addedProduct() {
        let selectedUnit = this.product.units.find(x => x.name.trim() == this.selectedUnit.trim());
        let unitWithoutSurcharge = this.product.units.find(x => x.quantityPerUnit > selectedUnit.quantityPerUnit && !x.orderingUnit && !x.excludedFromSurcharge);
        if (this.leftAmountWithSurcharge > 0 && unitWithoutSurcharge != undefined) {
            this.leftAmountWithSurcharge = this.leftAmountWithSurcharge - this.amount;
            this.product.leftAmountWithSurcharge = this.leftAmountWithSurcharge;
        }
        else {
            this.leftAmountWithSurcharge = 0;
            this.product.leftAmountWithSurcharge = 0;
        }
        this.amount = null;
        this.showLoadingSuccess = true;
        this.$root.$emit(EventTypes.ShopMultipleItemsItemRemoved, this.product.productId);
        setTimeout(() => {
            this.showLoadingSuccess = false;
        }, 2000);
    }
    addProduct(pressedEnter) {
        this.$refs.buyButton.addToBasket(pressedEnter);
        if (pressedEnter) {
            this.focusNextOnEnter();
        }
    }
    get getListId() {
        return SearchService.currentListId(this.$route);
    }
    get price() {
        if (!this.selectedUnit)
            return null;
        if (this.isExpiryProduct)
            return this.product.expiry.price;
        let priceWithSurcharge = this.getPriceWithSurcharge();
        if (priceWithSurcharge > -1)
            return priceWithSurcharge;
        let unit = this.product.units.find(x => x.name == this.selectedUnit);
        return unit.price;
    }
    get priceBeforeCampaignOrExpiration() {
        if (!this.selectedUnit)
            return null;
        let priceWithSurcharge = this.getPriceWithSurcharge(true);
        if (priceWithSurcharge > -1)
            return priceWithSurcharge;
        let unit = this.product.units.find(x => x.name == this.selectedUnit);
        if (this.isExpiryProduct)
            return unit.price;
        return unit.priceBeforeCampaign;
    }
    getPriceWithSurcharge(campaignPrice) {
        if (!this.selectedUnit && this.leftAmountWithSurcharge > 0)
            return -1;
        let selectedUnit = this.product.units.find(x => x.name.trim() == this.selectedUnit.trim());
        let unitWithoutSurcharge = this.product.units.find(x => x.quantityPerUnit > selectedUnit.quantityPerUnit && !x.orderingUnit && !x.excludedFromSurcharge);
        this.leftAmountWithSurcharge = this.product.leftAmountWithSurcharge;
        this.isPriceWithSurcharge = this.leftAmountWithSurcharge > this.amount;
        if (selectedUnit.quantityPerUnit == 1 && unitWithoutSurcharge != undefined && this.amount > 0 && this.isPriceWithSurcharge) {
            if (campaignPrice)
                return selectedUnit.priceBeforeCampaign * (1 + this.product.surchargePct / 100);
            return selectedUnit.price * (1 + this.product.surchargePct / 100);
        }
        this.isPriceWithSurcharge = false;
        return -1;
    }
    get priceTypeCampaign() {
        return this.product.units != null && this.product.units.find(x => x.priceType == this.priceType.CampaignPrice) != undefined;
    }
    get isExpiryProduct() {
        return this.product.expiry && this.product.expiry.price >= 0;
    }
    setAmount(event) {
        this.amount = event;
    }
    openFoodInfo() {
        GAService.productClick([this.product]);
        GAService.productDetail([this.product]);
        SearchService.openFoodInfo(this, this.product.productId);
    }
    addItemAmount() {
        this.$set(this.product, 'clearAmount', false);
        if (!this.disableMultipleProductsButton)
            this.$root.$emit(EventTypes.ShopMultipleItemsItemAdded, new ItemAddVM(this.product, this.amount, this.selectedUnit, this.uiSource, this.position));
    }
    focusNextOnEnter() {
        /* Service.focusNextOnEnter(this, 'input.quantity-input[tabindex="3"]', 'inputAmount'); */
        Service.focusNextOnEnter(this.$refs['inputAmountParent'], 'input.quantity-input[tabindex="3"]', 'inputAmount');
    }
    changeUnit() {
        this.setAmount(null);
        this.addItemAmount();
    }
};
__decorate([
    Watch('product.clearAmount')
], ShopProductListItem.prototype, "onClearAmountChange", null);
__decorate([
    Watch('product.addedAmount')
], ShopProductListItem.prototype, "onAddedAmountChange", null);
ShopProductListItem = __decorate([
    Component({
        components: {
            ShopItemIcons,
            ShopItemFavoriteMenu,
            ShopItemBuyButton,
            ShopReplaceAltProdButton,
            SuggestionTooltip,
            InputNumber,
            ShopItemStockStatus,
            ItemSurchargeWarning,
            HighlightMarkings,
            ShopStockWarning
        },
        props: {
            loading: Boolean,
            product: Object,
            isAltProduct: Boolean,
            uiSource: String,
            position: Number,
            disableMultipleProductsButton: Boolean
        }
    })
], ShopProductListItem);
export default ShopProductListItem;
