import { Service } from '../../../basic-service/src';
import { CookieStorageService } from "./CookieStorageService";
import { LocalStorageService } from "./LocalStorageService";
export class StorageService extends Service {
    static useCookies;
    static initialize(useCookies = false) {
        CookieStorageService.initialize();
        LocalStorageService.initialize();
        if (useCookies) {
            if (CookieStorageService.available) {
                this.useCookies = true;
            }
            else {
                if (LocalStorageService.available) {
                    this.useCookies = false;
                }
            }
        }
        else {
            if (LocalStorageService.available) {
                this.useCookies = false;
            }
            else {
                if (CookieStorageService.available) {
                    this.useCookies = true;
                }
            }
        }
        if (this.useCookies === undefined) {
            throw 'No storage system available.';
        }
    }
    static setItem(key, item) {
        if (this.useCookies) {
            return CookieStorageService.setItem(key, item);
        }
        else {
            return LocalStorageService.setItem(key, item);
        }
    }
    static getItem(key) {
        if (this.useCookies) {
            return CookieStorageService.getItem(key);
        }
        else {
            return LocalStorageService.getItem(key);
        }
    }
    static removeItem(key) {
        if (this.useCookies) {
            return CookieStorageService.removeItem(key);
        }
        else {
            return LocalStorageService.removeItem(key);
        }
    }
}
