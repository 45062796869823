import Vue from 'vue';
import { ToastService } from 'services/ToastService';
import { CalculationService } from 'services/CalculationService';
import { CalculationVM } from 'models/calculation/CalculationVM';
import { CalculationState } from 'models/calculation/CalculationState';
const state = new CalculationState();
const getters = {};
const actions = {
    async getAll({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            CalculationService.getCalculations().then(calculations => {
                commit('setAll', calculations);
                commit('setLoading', false);
                resolve(calculations);
            }).catch(err => {
                commit('setLoading', false);
                reject();
            });
        });
    },
    selectCalculation({ commit }, calculationId) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            CalculationService.getCalculation(calculationId).then(calculation => {
                commit('selectCalculation', calculation);
                commit('setLoading', false);
                resolve(calculation);
            }).catch(err => {
                commit('setLoading', false);
                reject();
                ToastService.danger(Vue.filter("translate")("webshop_calculations_calculationList_toast_errorOnLoadingCalculation"));
            });
        });
    },
    updateSelectedCalculation({ dispatch, commit }, calculation) {
        commit('updateSelectedCalculation', calculation);
        dispatch('getAll');
    },
    createCalculation({ commit }, calculation) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            CalculationService.createCalculation(calculation).then(calc => {
                commit('createCalculation', calc);
                commit('setLoading', false);
                resolve(calc);
                ToastService.success(Vue.filter("translate")("webshop_calculations_modals_createCalculation_toasts_createdSuccessful"));
            }).catch(err => {
                commit('setLoading', false);
                reject();
                ToastService.danger(Vue.filter("translate")("webshop_calculations_modals_createCalculation_toasts_creationError"));
            });
        });
    },
    copyCalculation({ commit, dispatch }, copyDetails) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            commit('selectCalculation', new CalculationVM());
            CalculationService.copyCalculation(copyDetails).then(calc => {
                dispatch('getAll');
                commit('setLoading', false);
                resolve(calc);
                ToastService.success(Vue.filter("translate")("webshop_calculations_calculationList_toast_copySuccessful"));
            }).catch(err => {
                commit('setLoading', false);
                reject();
                ToastService.danger(Vue.filter("translate")("webshop_calculations_calculationList_toast_copyError"));
            });
        });
    },
    deleteCalculation({ dispatch, commit }, calculation) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            CalculationService.deleteCalculation(calculation).then(calc => {
                commit('deleteCalculation', calculation);
                dispatch('getAll');
                commit('setLoading', false);
                resolve(calculation);
                ToastService.success(Vue.filter("translate")("webshop_calculations_modals_deleteCalculation_toasts_deletedSuccessful"));
            }).catch(async (err) => {
                commit('setLoading', false);
                reject();
                ToastService.danger(Vue.filter("translate")("webshop_calculations_modals_deleteCalculation_toasts_deleteError"));
            });
        });
    },
    createCalculationLines({ commit }, calculation) {
        commit('selectCalculation', calculation);
    },
    deleteCalculationLine({ commit }, calculationLine) {
        commit('deleteCalculationLine', calculationLine);
    },
    changeSortBy({ commit }, sortBy) {
        commit('changeSortBy', sortBy);
        commit('selectCalculation', state.selectedCalculation);
    },
    addCustomerItem({ commit }, calculationLine) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            CalculationService.addCustomerItem(calculationLine.calculationId, calculationLine).then(calc => {
                commit('selectCalculation', calc);
                commit('setLoading', false);
                resolve(calc);
                ToastService.success(Vue.filter("translate")("webshop_calculations_modals_addProducts_toasts_addedOne"));
            }).catch(async (err) => {
                commit('setLoading', false);
                reject();
                ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
            });
        });
    },
    updateCustomerItem({ commit }, calculationLine) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            CalculationService.updateCalculationLine(calculationLine).then(calc => {
                commit('selectCalculation', calc);
                commit('setLoading', false);
                resolve(calc);
                ToastService.success(Vue.filter("translate")("webshop_calculations_modals_addProducts_toasts_updatedOne"));
            }).catch(async (err) => {
                commit('setLoading', false);
                reject();
                ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
            });
        });
    }
};
const mutations = {
    changeSortBy(state, sortBy) {
        state.sortBy = sortBy;
    },
    setAll(state, list) {
        list.forEach(x => x.grossProfit = Number.parseFloat(x.grossProfit.toLocaleString()));
        state.list = list;
    },
    selectCalculation(state, calculation) {
        calculation.calculationLines = CalculationService.sortResult(calculation.calculationLines, state.sortBy);
        var oldSelected = state.selectedCalculation;
        state.selectedCalculation = calculation;
        if (oldSelected.calculationId != calculation.calculationId) {
            this._vm.$nextTick(() => {
                try {
                    var isSafari = /constructor/i.test(window["HTMLElement"]) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof window['safari'] !== 'undefined' && window['safari'].pushNotification));
                    var isIE = /*@cc_on!@*/ false || !!document["documentMode"];
                    var isEdge = !isIE && !!window["StyleMedia"];
                    var scrollPoint = document.getElementsByClassName("inventory-scroll-point")[0];
                    var floatingHeader = document.getElementById("shop-floatingHeader");
                    var scrollYPos = scrollPoint.offsetTop - (floatingHeader.offsetHeight + floatingHeader.offsetTop);
                    if (!isSafari && !isEdge && !isIE) {
                        window.scrollTo({ top: scrollYPos, behavior: 'smooth' });
                    }
                    else {
                        window.scroll(0, scrollYPos);
                    }
                }
                catch (e) {
                    console.log("e", e);
                }
            });
        }
    },
    updateSelectedCalculation(state, calculation) {
        if (state.selectedCalculation.calculationId === calculation.calculationId) {
            state.selectedCalculation.vatId = calculation.vatId;
            state.selectedCalculation.coverage = calculation.coverage;
            state.selectedCalculation.grossProfit = calculation.grossProfit;
            state.selectedCalculation.priceToCustomer = calculation.priceToCustomer;
            state.selectedCalculation.totalCost = calculation.totalCost;
            state.selectedCalculation.name = calculation.name;
            state.selectedCalculation.isShared = calculation.isShared;
            state.selectedCalculation.numberOfPortions = calculation.numberOfPortions;
        }
    },
    createCalculation(state, calculation) {
        let index = state.list.findIndex(x => {
            let compare = x.name.localeCompare(calculation.name);
            return compare > 0;
        });
        if (index >= 0) {
            state.list.splice(index, 0, calculation);
        }
        else {
            state.list.push(calculation);
        }
    },
    deleteCalculation(state, calculation) {
        state.list = state.list.filter(calc => calc.calculationId !== calculation.calculationId);
        if (state.selectedCalculation.calculationId == calculation.calculationId) {
            state.selectedCalculation = {};
        }
    },
    deleteCalculationLine(state, calculationLine) {
        state.selectedCalculation.calculationLines = state.selectedCalculation.calculationLines.filter(line => line.calculationLineId != calculationLine.calculationLineId);
    },
    setLoading(state, status) {
        state.loading = status;
    }
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
