import { render, staticRenderFns } from "./ShopItemFavoriteMenu.vue?vue&type=template&id=e4ae22d0&scoped=true&"
import script from "./ShopItemFavoriteMenu.vue?vue&type=script&lang=ts&"
export * from "./ShopItemFavoriteMenu.vue?vue&type=script&lang=ts&"
import style0 from "./ShopItemFavoriteMenu.vue?vue&type=style&index=0&id=e4ae22d0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4ae22d0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Projekt\\webbshop\\SvenskCater\\SvenskCater\\app\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e4ae22d0')) {
      api.createRecord('e4ae22d0', component.options)
    } else {
      api.reload('e4ae22d0', component.options)
    }
    module.hot.accept("./ShopItemFavoriteMenu.vue?vue&type=template&id=e4ae22d0&scoped=true&", function () {
      api.rerender('e4ae22d0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/shop/partials/ShopItemFavoriteMenu.vue"
export default component.exports