var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import ShopGalleryItem from "components/shop/partials/ShopGalleryItem.vue";
import Loader from 'components/utility/Loader.vue';
import { SearchService } from "services/SearchService";
import { OrderlineUISourceTypes } from 'models/order/OrderlineUISourceTypes';
let ShopResultGallery = class ShopResultGallery extends Vue {
    loading;
    products;
    OrderlineUISourceTypes = OrderlineUISourceTypes;
    get currentListId() {
        return SearchService.currentListId(this.$route);
    }
    get searching() {
        return SearchService.isSearching(this.$route);
    }
};
ShopResultGallery = __decorate([
    Component({
        components: {
            ShopGalleryItem,
            Loader
        },
        props: {
            loading: Boolean,
            products: Array
        }
    })
], ShopResultGallery);
export default ShopResultGallery;
