var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "campaign-details" },
    [
      _c("campaign-planning-header", {
        attrs: { campaign: _vm.selectedCampaign },
      }),
      _vm._v(" "),
      _vm.selectedCampaign && _vm.selectedCampaign.code
        ? _c("campaign-planning-line-list", {
            key: "campLines" + _vm.selectedCampaign.code,
            attrs: { campaign: _vm.selectedCampaign },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }