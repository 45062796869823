import { Service } from './Service';
import { CampaignPlanningVM } from 'models/campaignPlanning/CampaignPlanningVM';
import { VendorApi } from 'api/VendorApi';
import { CampaignPlanningLineVM } from 'models/campaignPlanning/CampaignPlanningLineVM';
import { CampaignPlanningCommentVM } from 'models/campaignPlanning/CampaignPlanningCommentVM';
import { VendorHomePageContent } from 'models/vendor/VendorHomePageContent';
import { ToastType } from 'models/utility/ToastType';
import Vue from 'vue';
export class VendorService extends Service {
    static getCampaignPlanningList() {
        return new Promise((resolve, reject) => {
            VendorApi.GetCampaignPlanningList().then((res) => {
                var result = res.data
                    .map(x => CampaignPlanningVM.fromApi(x));
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static getCampaignPlanningById(campaignId) {
        return new Promise((resolve, reject) => {
            VendorApi.GetCampaignPlanningById(campaignId).then((res) => {
                resolve(CampaignPlanningVM.fromApi(res.data));
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static createCampaignPlanningLine(campaignLine) {
        return new Promise((resolve, reject) => {
            VendorApi.CreateCampaignPlanningLine(campaignLine).then((res) => {
                var result = CampaignPlanningLineVM.fromApi(res.data);
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static updateCampaignPlanningLine(campaignLine) {
        return new Promise((resolve, reject) => {
            VendorApi.UpdateCampaignPlanningLine(campaignLine).then((res) => {
                var result = CampaignPlanningLineVM.fromApi(res.data);
                resolve(result);
            }).catch((err) => {
                if (err.response.status == 409) {
                    $.dcui.toast(Vue.filter('translate')('webshop_defaults_toast_errorText'), ToastType.Danger, null, { duration: 6000 });
                }
                else
                    Service.customErrorHandling({ ...err }, reject);
            });
        });
    }
    static deleteCampaignPlanningLine(campaignLine) {
        return new Promise((resolve, reject) => {
            VendorApi.DeleteCampaignPlanningLine(campaignLine).then((res) => {
                resolve(CampaignPlanningLineVM.fromApi(res.data));
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static getCampaignPlanningComments(campaignCode, vendorNo, vendorItemNo) {
        return new Promise((resolve, reject) => {
            VendorApi.GetCampaignPlanningComments(campaignCode, vendorNo, vendorItemNo).then((res) => {
                var result = res.data
                    .map(x => CampaignPlanningCommentVM.fromApi(x));
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static writeComment(comment) {
        return new Promise((resolve, reject) => {
            VendorApi.WriteComment(comment).then((res) => {
                var result = CampaignPlanningCommentVM.fromApi(res.data);
                resolve(result);
            }).catch((err) => {
                if (err.response.status == 409) {
                    $.dcui.toast(Vue.filter('translate')('webshop_defaults_toast_errorText'), ToastType.Danger, null, { duration: 6000 });
                }
                else
                    Service.customErrorHandling({ ...err }, reject);
            });
        });
    }
    static getHomePageContent() {
        return new Promise((resolve, reject) => {
            VendorApi.GetHomePageContent().then((res) => {
                var result = VendorHomePageContent.fromApi(res.data);
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static changeCampaignPlanningLineWaitingFor(campaignLines) {
        return new Promise((resolve, reject) => {
            VendorApi.ChangeCampaignPlanningLineWaitingFor(campaignLines).then((res) => {
                var result = res.data
                    .map(x => CampaignPlanningLineVM.fromApi(x));
                resolve(result);
            }).catch((err) => {
                if (err.response.status == 409) {
                    $.dcui.toast(Vue.filter('translate')('webshop_defaults_toast_errorText'), ToastType.Danger, null, { duration: 6000 });
                }
                else
                    Service.customErrorHandling({ ...err }, reject);
            });
        });
    }
}
