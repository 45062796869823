var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "div-table campaign-line-table" }, [
    _c("section", [
      _c("div", { staticClass: "table-header" }, [
        _c("div", { staticClass: "table-row" }, [
          _c("div", { staticClass: "table-column" }, [
            _vm._v(
              _vm._s(
                _vm._f("translate")(
                  "webshop_vendor_campaignPlanning_campaignLines_columnItemNo"
                )
              )
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "table-column" }, [
            _vm._v(
              _vm._s(
                _vm._f("translate")(
                  "webshop_vendor_campaignPlanning_campaignLines_columnVendorItemNo"
                )
              )
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "table-column campaign-column-name" }, [
            _vm._v(
              _vm._s(
                _vm._f("translate")(
                  "webshop_vendor_campaignPlanning_campaignLines_columnName"
                )
              )
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "table-column column-status" }, [
            _vm._v(
              _vm._s(
                _vm._f("translate")(
                  "webshop_vendor_campaignPlanning_campaignLines_columnStatus"
                )
              )
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "table-column" }, [
            _vm._v(
              _vm._s(
                _vm._f("translate")(
                  "webshop_vendor_campaignPlanning_campaignLines_columnUnit"
                )
              )
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "table-column column-justify-end" }, [
            _vm._v(
              _vm._s(
                _vm._f("translate")(
                  "webshop_vendor_campaignPlanning_campaignLines_columnOrdinaryPrice"
                )
              )
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "table-column column-justify-end" }, [
            _vm._v(
              _vm._s(
                _vm._f("translate")(
                  "webshop_vendor_campaignPlanning_campaignLines_columnDiscount"
                )
              )
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "table-column column-justify-end" }, [
            _vm._v(
              _vm._s(
                _vm._f("translate")(
                  "webshop_vendor_campaignPlanning_campaignLines_columnCampaignPrice"
                )
              )
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "table-column column-justify-end" }, [
            _vm._v(
              _vm._s(
                _vm._f("translate")(
                  "webshop_vendor_campaignPlanning_campaignLines_columnOutPrice"
                )
              )
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "table-column" }, [
            _vm._v(_vm._s(_vm._f("translate")(""))),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-body" },
        _vm._l(_vm.campaignLines, function (line) {
          return _c("campaign-planning-line", {
            key: line.campaignCode + line.vendorNo + line.vendorItemNo,
            attrs: { "campaign-line": line },
          })
        }),
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }