var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import IconTrash from 'components/shared/IconTrash.vue';
import CreateCalculationModal from '../modals/CreateCalculationModal.vue';
import { ModalService } from 'modules/basic-core/basic-modal/src/service/ModalService';
import { CalculationType } from 'models/calculation/CalculationType';
import DeleteCalculationModal from '../modals/DeleteCalculationModal.vue';
import { PermissionService } from 'services/PermissionService';
import { PermissionLevel } from 'models/user/PermissionLevel';
import { Settings } from 'config/Settings';
let CalculationList = class CalculationList extends Vue {
    calculations;
    setLoader;
    CalculationType = CalculationType;
    showPrice = PermissionService.hasPermission(PermissionLevel.ShowPrice);
    Settings = Settings;
    isSharedList = false;
    isSharedListEmpty = false;
    get selectedCalculation() {
        let calculation = this.$store.state.Calculations.selectedCalculation;
        if (calculation && calculation.customerNo) {
            this.isSharedList = calculation.customerNo != Settings.profileInfo.customerNo;
        }
        return calculation;
    }
    get loading() {
        return this.$store.state.Calculations.loading;
    }
    get filteredCalculations() {
        let sharedCalculations = this.calculations.filter(x => x.customerNo != Settings.profileInfo.customerNo);
        this.isSharedListEmpty = sharedCalculations && sharedCalculations.length > 0 ? false : true;
        if (this.isSharedList) {
            return sharedCalculations;
        }
        else {
            return this.calculations.filter(x => x.customerNo == Settings.profileInfo.customerNo);
        }
    }
    deleteCalculation(calculation) {
        ModalService.create({
            cxt: this,
            component: DeleteCalculationModal,
            maxWidth: "450px",
            data: {
                calculation: calculation,
                replaceRoute: () => {
                    this.$router.replace({ name: 'calculations' });
                }
            }
        });
    }
    createCalculation() {
        let thisRef = this;
        ModalService.create({
            cxt: this,
            component: CreateCalculationModal,
            maxWidth: "450px",
            data: (id) => {
                thisRef.$router.push({ name: 'calculationDetails', params: { calculationId: id } });
            }
        });
    }
};
CalculationList = __decorate([
    Component({
        components: {
            IconTrash
        },
        props: {
            calculations: Array
        }
    })
], CalculationList);
export default CalculationList;
