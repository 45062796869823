var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import LoadingButton from "../shared/LoadingButton.vue";
import { ToastService } from "services/ToastService";
import { EventTypes } from "models/EventTypes";
import { OrderService } from "services/OrderService";
let BasketConfirmDeleteModal = class BasketConfirmDeleteModal extends Vue {
    loading = false;
    data;
    closeModal() {
        this.$emit("close-modal");
    }
    get isDeposit() {
        let date = new Date(98, 0);
        date.setFullYear(1);
        return date.toISOString() === this.data;
    }
    submit() {
        var dateString = Vue.filter("date")(this.data);
        OrderService.emptyBasket(dateString).then(res => {
            if (!this.isDeposit) {
                ToastService.success(Vue.filter("translate")("webshop_cart_empty_successText", "<i>" + dateString + "</i>"));
            }
            else {
                ToastService.success(Vue.filter("translate")("webshop_cart_empty_successText_deposit"));
            }
            this.loading = false;
            this.$root.$emit(EventTypes.BasketModalDeleteConfirm);
            this.$root.$emit(EventTypes.MiniCartUpdate, res);
            this.closeModal();
        }, err => {
            this.loading = false;
            ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
            this.$root.$emit(EventTypes.BasketUpdate);
        });
    }
};
BasketConfirmDeleteModal = __decorate([
    Component({
        components: {
            LoadingButton
        },
        props: {
            data: String
        }
    })
], BasketConfirmDeleteModal);
export default BasketConfirmDeleteModal;
