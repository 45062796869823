var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import ShopGalleryItem from "./ShopGalleryItem.vue";
import ShopProductListItem from "./ShopProductListItem.vue";
var ViewTypes;
(function (ViewTypes) {
    ViewTypes[ViewTypes["Card"] = 0] = "Card";
    ViewTypes[ViewTypes["List"] = 1] = "List";
})(ViewTypes || (ViewTypes = {}));
let DepictItem = class DepictItem extends Vue {
    viewType;
    viewTypeValue;
    product;
    loading;
    uiSource;
    position;
    ViewTypes = ViewTypes;
    created() {
        if (this.viewType == undefined || this.viewType == null)
            this.viewTypeValue = ViewTypes.Card;
        else
            this.viewTypeValue = this.viewType;
    }
};
DepictItem = __decorate([
    Component({
        props: {
            viewType: Number,
            product: Object,
            loading: Boolean,
            uiSource: String,
            position: Number
        },
        components: {
            ShopGalleryItem,
            ShopProductListItem
        }
    })
], DepictItem);
export default DepictItem;
