var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "slide slide-1" }, [
    _c("div", [
      _c("div", [
        _c(
          "form",
          {
            staticClass: "search-form",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.search()
              },
            },
          },
          [
            _c("input", {
              directives: [
                { name: "autofocus", rawName: "v-autofocus" },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchQuery,
                  expression: "searchQuery",
                },
              ],
              staticClass: "slide-input",
              attrs: {
                type: "text",
                placeholder: _vm.$options.filters.translate(
                  "webshop_favorite_addProduct_search_inputPlaceholder"
                ),
              },
              domProps: { value: _vm.searchQuery },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.searchQuery = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "ui-button ui-button-accept",
                attrs: { type: "submit" },
              },
              [
                _c("i", { staticClass: "mdi mdi-magnify" }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_favorite_addProduct_search_btn"
                      )
                    ) +
                    "\r\n                "
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("h4", { staticClass: "header-small header-other-options" }, [
        _vm._v(
          "\r\n            " +
            _vm._s(
              _vm._f("translate")(
                "webshop_favorite_addProduct_otherOptions_header"
              )
            ) +
            "\r\n        "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "type-icons" }, [
        _c("div", { staticClass: "type-icon-container" }, [
          _c("div", { staticClass: "type-icon" }, [
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.openOrderSearch(6)
                  },
                },
              },
              [
                _c("i", { staticClass: "mdi mdi-numeric-6-circle" }),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_favorite_addProduct_otherOptions_months"
                      )
                    )
                  ),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              _vm._s(
                _vm._f("translate")(
                  "webshop_favorite_addProduct_otherOptions_monthsDescription"
                )
              )
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "type-icon-container" }, [
          _c("div", { staticClass: "type-icon" }, [
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.openOrderSearch(null)
                  },
                },
              },
              [
                _c("i", { staticClass: "mdi mdi-calendar" }),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_favorite_addProduct_otherOptions_date"
                      )
                    )
                  ),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              _vm._s(
                _vm._f("translate")(
                  "webshop_favorite_addProduct_otherOptions_dateDescription"
                )
              )
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }