var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ShopResults_1;
import Vue from 'vue';
import Component from 'vue-class-component';
import ShopResultGallery from 'components/shop/result/ShopResultGallery.vue';
import ShopResultGalleryPopularProducts from 'components/shop/result/ShopResultGalleryPopularProducts.vue';
import ShopResultList from 'components/shop/result/ShopResultList.vue';
import ShopResultListPopularProducts from 'components/shop/result/ShopResultListPopularProducts.vue';
import ShopResultPagination from 'components/shop/result/ShopResultPagination.vue';
import ShopMultipleProductsFooter from "components/shop/partials/ShopMultipleProductsFooter.vue";
import { ToastService } from 'services/ToastService';
import { EventTypes } from 'models/EventTypes';
import { StorageService } from 'modules/basic-core/basic-storage/src/storage/StorageService';
import { SearchService } from 'services/SearchService';
import { SearchInput } from 'models/search/SearchInput';
import { SearchViewTypes } from 'models/search/SearchViewTypes';
import { SearchSortBy } from 'models/search/SearchSortBy';
import { KeyValue } from 'models/utility/KeyValue';
let ShopResults = ShopResults_1 = class ShopResults extends Vue {
    static viewStorageKey = 'searchResultType';
    loading;
    products;
    popularProducts;
    input;
    totalProducts = null;
    viewTypes = SearchViewTypes;
    viewType = SearchViewTypes.gallery;
    selectedSortBy = null;
    itemsWithAmount = [];
    get sortByFilters() {
        return Object.keys(SearchSortBy)
            .reduce((newArray, key) => {
            if (isNaN(Number(key))) {
                let value = SearchSortBy[key];
                // if (SearchSortBy.Favorite === value) //TODO JCD remove if-statement and this return then favoriteList sorting is ready
                //     return newArray;
                if (value === SearchSortBy.Favorite && this.input.favoriteListId === null)
                    return newArray;
                let label = Vue.filter('translate')('webshop_search_search_sortBy_' + key);
                newArray.push(KeyValue.create(label, value));
            }
            return newArray;
        }, []);
    }
    created() {
        this.$root.$on(EventTypes.ShopSearchDone, this.searchDone);
        this.$root.$on(EventTypes.ShopAddedProductsToBasket, this.emptyArrayWithAmountItems);
        this.$root.$on(EventTypes.ShopMultipleItemsItemAdded, this.addItemAmount);
        this.$root.$on(EventTypes.ShopMultipleItemsItemRemoved, this.removeItemAmount);
    }
    beforeDestroy() {
        this.$root.$off(EventTypes.ShopSearchDone, this.searchDone);
        this.$root.$off(EventTypes.ShopAddedProductsToBasket);
        this.$root.$off(EventTypes.ShopMultipleItemsItemAdded);
        this.$root.$off(EventTypes.ShopMultipleItemsItemRemoved);
    }
    mounted() {
        StorageService.getItem(ShopResults_1.viewStorageKey)
            .then(x => {
            this.viewType = x;
        }).catch(err => { });
        //this.searchDone();
    }
    toggleView(viewType) {
        this.viewType = viewType;
        this.updateItemsWithAmountRef();
        StorageService.setItem(ShopResults_1.viewStorageKey, this.viewType)
            .catch(err => {
            ToastService.danger(Vue.filter('translate')('webshop_defaults_toast_errorText'));
        });
    }
    changeSortBy() {
        let input = Object.assign(new SearchInput(), this.input);
        input.sortBy = this.selectedSortBy;
        SearchService.setCachedSortBy(input.sortBy).then(() => {
            this.$root.$emit(EventTypes.ShopSearchStart, input);
        });
    }
    searchDone() {
        this.selectedSortBy = this.input.sortBy;
        this.updateItemsWithAmountRef();
    }
    updateItemsWithAmountRef() {
        this.itemsWithAmount.forEach(x => {
            let productIndex = this.products.findIndex(y => y.productId == x.product.productId);
            let popularIndex = this.popularProducts.findIndex(y => y.productId == x.product.productId);
            if (productIndex > -1) {
                x.product = this.products[productIndex];
            }
            if (popularIndex > -1) {
                x.product = this.popularProducts[popularIndex];
            }
            x.product.addedAmount = x.amount;
        });
        SearchService.itemsWithAmount = this.itemsWithAmount;
    }
    emptyArrayWithAmountItems() {
        this.itemsWithAmount.forEach(x => {
            let product = this.products.find(y => y.productId == x.product.productId);
            if (product != undefined) {
                product.inBasket = true;
                product.clearAmount = true;
                product.addedAmount = null;
            }
            product = this.popularProducts.find(y => y.productId == x.product.productId);
            if (product != undefined) {
                product.inBasket = true;
                product.clearAmount = true;
                product.addedAmount = null;
            }
        });
        this.itemsWithAmount = [];
        SearchService.itemsWithAmount = this.itemsWithAmount;
    }
    addItemAmount(event) {
        if (!event.amount || event.amount <= 0) {
            this.removeItemAmount(event.product.productId);
        }
        else {
            const index = this.itemsWithAmount.findIndex(x => x.product.productId == event.product.productId);
            if (index > -1) {
                this.itemsWithAmount[index].amount = event.amount;
            }
            else {
                this.itemsWithAmount.push(event);
            }
        }
        SearchService.itemsWithAmount = this.itemsWithAmount;
    }
    removeItemAmount(event) {
        const index = this.itemsWithAmount.findIndex(x => x.product.productId == event);
        if (index > -1) {
            this.itemsWithAmount.splice(index, 1);
        }
    }
    get productsCounter() {
        return {
            first: (this.input.page - 1) * this.input.pageSize + 1,
            last: (this.input.page - 1) * this.input.pageSize + this.products.length
        };
    }
};
ShopResults = ShopResults_1 = __decorate([
    Component({
        components: {
            ShopResultGallery,
            ShopResultGalleryPopularProducts,
            ShopResultList,
            ShopResultListPopularProducts,
            ShopResultPagination,
            ShopMultipleProductsFooter
        },
        props: {
            loading: Boolean,
            products: Array,
            popularProducts: Array,
            input: Object,
            totalProducts: Number
        }
    })
], ShopResults);
export default ShopResults;
