var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import CalculationModalProductResult from './partials/CalculationModalProductResult.vue';
import CalculationModalDatepicker from './partials/CalculationModalDatepicker.vue';
import CalculationModalOverview from './partials/CalculationModalOverview.vue';
import CalculationModalFavoriteProducts from './partials/CalculationModalFavoriteProducts.vue';
import CalculationModalCustomerItemForm from './partials/CalculationModalCustomerItemForm.vue';
import { ToastService } from "services/ToastService";
import { CalculationService } from "services/CalculationService";
let CalculationModalAddProducts = class CalculationModalAddProducts extends Vue {
    loading = false;
    data;
    searchQuery = '';
    prefixedPeriod = null;
    selectedDate = null;
    showNavigation = false;
    showOrderDatepicker = false;
    showFavoriteLists = false;
    showCustomerItem = false;
    result = null;
    mounted() {
    }
    close() {
        this.$emit("close-modal");
    }
    setLoading(value) {
        this.loading = value;
    }
    onResult(result, searchQuery) {
        this.showNavigation = true;
        this.result = result;
        this.searchQuery = searchQuery;
        this.prefixedPeriod = null;
        this.loading = false;
    }
    onOrderSearch(months, showFavoriteLists, showCustomerItem) {
        this.searchQuery = '';
        if (!!months) {
            this.prefixedPeriod = months;
            let date = moment().subtract(months, 'months').toDate();
            this.getResultByOrders(date);
        }
        else {
            this.showOrderDatepicker = true;
            this.showNavigation = true;
        }
        if (showFavoriteLists) {
            this.showNavigation = false;
            this.showOrderDatepicker = false;
            this.showFavoriteLists = true;
            this.showCustomerItem = false;
        }
        if (showCustomerItem) {
            this.showNavigation = true;
            this.showOrderDatepicker = false;
            this.showFavoriteLists = false;
            this.showCustomerItem = true;
        }
    }
    getResultByOrders(date) {
        this.loading = true;
        CalculationService.searchNewProductsBySales(date, this.data.calculationId).then(result => {
            if (result && result.length == 0) {
                if (this.prefixedPeriod) {
                    //Ingen order hittades sedan {0}
                    ToastService.danger(Vue.filter('translate')('webshop_favorite_addProduct_order_toasts_noProduct_datePeriod', this.prefixedPeriod));
                }
                else {
                    //Från de senaste {0} månaderna
                    ToastService.danger(Vue.filter('translate')('webshop_favorite_addProduct_order_toasts_noProduct_fromDate', Vue.filter('date')(date, 'L')));
                }
                this.prefixedPeriod = null;
            }
            else {
                this.showNavigation = true;
                this.selectedDate = date;
                this.result = result;
            }
            this.loading = false;
        })
            .catch(err => {
            this.loading = false;
            ToastService.danger(Vue.filter('translate')('webshop_defaults_toast_errorText'));
        });
    }
    goBack() {
        if (this.showOrderDatepicker) {
            if (!this.selectedDate) {
                this.showNavigation = false;
                this.showOrderDatepicker = false;
            }
        }
        else {
            this.showNavigation = false;
        }
        this.prefixedPeriod = null;
        this.selectedDate = null;
        this.result = null;
        this.showFavoriteLists = false;
        this.showCustomerItem = false;
    }
    saveCustomerItem(model) {
        this.loading = true;
        model.price = model.price == undefined ? 0 : model.price;
        model.itemNetWeight = model.itemNetWeight == undefined ? 0 : model.itemNetWeight;
        this.$store.dispatch('Calculations/addCustomerItem', model).then(res => {
            this.$emit("close-modal");
        });
    }
};
CalculationModalAddProducts = __decorate([
    Component({
        components: {
            CalculationModalProductResult,
            CalculationModalOverview,
            CalculationModalDatepicker,
            CalculationModalFavoriteProducts,
            CalculationModalCustomerItemForm,
        },
        props: {
            data: Object
        }
    })
], CalculationModalAddProducts);
export default CalculationModalAddProducts;
