export class InventoryAutoListResultVM {
    listId = null;
    listName = null;
    created = null;
    added = null;
    updated = null;
    deleted = null;
    ignored = null;
    static fromApi(list) {
        return Object.assign(new InventoryAutoListResultVM(), list);
    }
}
