export class CampaignPlanningVM {
    code;
    description;
    active;
    exported;
    purchaseStartingDate;
    purchaseEndingDate;
    salesStartingDate;
    salesEndingDate;
    campaignPlaningLines;
    waitingForVendor;
    static fromApi(item) {
        return Object.assign(new CampaignPlanningVM(), item);
    }
}
