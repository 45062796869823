var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.customClass,
      on: {
        drop: _vm.dropImage,
        dragenter: function ($event) {
          $event.preventDefault()
        },
        dragover: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c("div", { staticClass: "image-wrapper" }, [
        !_vm.showDeleteButton || !(_vm.isSelected || _vm.imageUrl)
          ? _c("div", [_vm._v(_vm._s(_vm.dragDropText))])
          : _vm._e(),
        _vm._v(" "),
        _c("img", {
          ref: "ipImg",
          class: { "show-image": _vm.isSelected || _vm.imageUrl },
          attrs: { src: _vm.imageUrl },
          on: { error: _vm.onImageError },
        }),
      ]),
      _vm._v(" "),
      !_vm.disabled
        ? _c("div", { staticClass: "picker-wrapper" }, [
            _c(
              "button",
              {
                staticClass: "ui-button ui-button-small",
                on: { click: _vm.selectImage },
              },
              [_vm._v(_vm._s(_vm.buttonText))]
            ),
            _vm._v(" "),
            _vm.showDeleteButton
              ? _c(
                  "button",
                  {
                    staticClass: "ui-button ui-button-small ui-button-cancel",
                    on: { click: _vm.deleteImage },
                  },
                  [_vm._v(_vm._s(_vm.deleteButtonText))]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("input", {
              ref: "ipInput",
              attrs: {
                type: "file",
                name: "image",
                accept: "image/png, image/jpeg",
                autocomplete: "off",
              },
              on: { change: _vm.processImage },
            }),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }