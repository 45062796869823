var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { ToastService } from "services/ToastService";
import { CheckOutService } from "services/CheckOutService";
let CheckOutComment = class CheckOutComment extends Vue {
    checkout;
    comment = "";
    loading = false;
    mounted() { }
    ratingComment() {
        if (this.comment.length > 1) {
            CheckOutService.setComment(this.checkout.CheckoutId, this.comment).then(res => {
                this.$emit("commented");
            }, err => {
                ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
            });
        }
        else {
            ToastService.danger(Vue.filter("translate")("webshop_campaign_review_input_comment"));
        }
    }
};
CheckOutComment = __decorate([
    Component({
        props: {
            checkout: Object
        }
    })
], CheckOutComment);
export default CheckOutComment;
