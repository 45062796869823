var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.declined
    ? _c("div", { staticClass: "checkout-offers" }, [
        _c("div", { staticClass: "row checkout-offer" }, [
          _c("div", { staticClass: "columns large-12" }, [
            _vm.checkoutvm.title
              ? _c("span", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.checkoutvm.title)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "box-card spot-wrapper" }, [
              _c(
                "div",
                {
                  staticClass: "imageWrapper",
                  style: {
                    "background-image":
                      _vm.imageurl !== null ? "url(" + _vm.imageurl + ")" : "",
                  },
                },
                [
                  _c("div", { staticClass: "spot-buttongroup" }, [
                    _vm.checkoutvm.productId !== null
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "ui-button ui-button-accept ui-button-hasIcon spot-button",
                            attrs: {
                              title: _vm.$options.filters.translate(
                                "webshop_campaign_offer_accept_buttonTitle"
                              ),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.addProductToBasket()
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "mdi mdi-cart" }),
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("translate")(
                                    "webshop_campaign_offer_accept_buttonText"
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "ui-button ui-button-secondary spot-button",
                        on: {
                          click: function ($event) {
                            return _vm.declineCheckout()
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("translate")(
                              "webshop_campaign_offer_decline_buttonText"
                            )
                          )
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("small", { staticClass: "small" }, [
              _c("i", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$options.filters.translate(
                      "webshop_campaign_offer_privacypolicy"
                    )
                  ),
                },
              }),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }