var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import ShopItemIcons from "./ShopItemIcons.vue";
import { OrderService } from "services/OrderService";
import { ToastService } from "services/ToastService";
import { EventTypes } from "models/EventTypes";
import { SearchViewTypes } from "models/search/SearchViewTypes";
import { OrderlineUISourceTypes } from "models/order/OrderlineUISourceTypes";
let ShopItemBuyButton = class ShopItemBuyButton extends Vue {
    loading;
    product;
    amount;
    selectedUnit;
    productLoading;
    view;
    viewTypes = SearchViewTypes;
    inBasket;
    uiSource;
    position;
    setProductLoading(loading) {
        this.$emit("loading", loading);
    }
    setProductInBasket() {
        //Setting value both here and with emit - shop need it for handling popular products, but component maybe used outside shop
        this.product.inBasket = true;
        this.$root.$emit(EventTypes.ShopAddedProductToBasket, this.product);
    }
    addToBasket(pressedEnter = false) {
        if (this.amount == undefined || this.amount == null) {
            this.$emit('amount', null);
            return;
        }
        if (typeof this.amount === "string") {
            this.$emit("amount", 1);
        }
        if (this.amount <= 0) {
            ToastService.danger(Vue.filter("translate")("webshop_expiry_no_amount")); //TODO oversættelse skal nok ændres?
            this.$emit("amount", null);
            return;
        }
        if ((!this.product.markings.weight || (this.product.markings.weight && this.selectedUnit !== 'KG')) && (this.amount % 1) != 0) {
            this.$emit("amount", Math.round(this.amount));
            ToastService.danger(Vue.filter("translate")("webshop_order_invalid_amount"));
            return;
        }
        this.setProductLoading(true);
        const basketProd = {
            amount: !+this.amount ? 1 : +this.amount,
            productId: this.product.productId,
            description: this.product.description,
            expiryRef: this.product.expiry ? this.product.expiry.reference : null,
            unit: this.selectedUnit,
            fromFavoriteList: false,
            list: null,
            checkoutId: 0,
            orderlineUISource: this.uiSource,
            searchPlacement: this.position,
            isFromSearch: this.uiSource == OrderlineUISourceTypes.ShopGallery || this.uiSource == OrderlineUISourceTypes.ShopGalleryPopular
                || this.uiSource == OrderlineUISourceTypes.ShopList || this.uiSource == OrderlineUISourceTypes.ShopListPopular ? true : false,
            image: this.product.image,
            brand: this.product.brand
        };
        OrderService.addSingleProductToBasket(basketProd)
            .then(res => {
            if (res != null && res.addedOrderLines > 0) {
                ToastService.success(Vue.filter("translate")("webshop_cart_add_toast_basketAdded", res.addedOrderLines));
                this.$root.$emit(EventTypes.MiniCartUpdate, res.previewOrder, [basketProd]);
                this.$root.$emit(EventTypes.BasketUpdate, true);
                // MiniCartService.updateMiniCart({
                //   count: res.previewOrder.itemCount,
                //   organicCount: res.previewOrder.ecoPct,
                //   agreementCount: res.previewOrder.agreementPct,
                //   noOfTooLate: res.previewOrder.noOfTooLate,
                //   noOfExpired: res.previewOrder.noOfExpired
                // });
                this.setProductLoading(false);
                this.$emit("added");
                this.setProductInBasket();
            }
            else {
                var errorMessage = Vue.filter("translate")("webshop_cart_add_toast_errorText");
                ToastService.danger(errorMessage, Vue.filter("translate")("webshop_cart_add_toast_errorHeader"));
                this.setProductLoading(false);
            }
        })
            .catch(err => {
            this.setProductLoading(false);
        });
    }
};
ShopItemBuyButton = __decorate([
    Component({
        components: {
            ShopItemIcons
        },
        props: {
            loading: Boolean,
            product: Object,
            amount: [Number, String],
            selectedUnit: String,
            productLoading: Boolean,
            view: String,
            inBasket: Boolean,
            uiSource: String,
            position: Number
        }
    })
], ShopItemBuyButton);
export default ShopItemBuyButton;
