var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "age-verification-modal" }, [
    _c("div", { staticClass: "age-verification-content" }, [
      _c("h2", [_vm._v("Godkänn ålder")]),
      _vm._v(" "),
      _c("div", { staticClass: "age-inner" }, [
        _c("p", [
          _vm._v(
            "\n        Denna hemsida innehåller information om alkoholhaltiga drycker. För\n        att besöka webbplatsen behöver du ha fyllt 20 år.\n      "
          ),
        ]),
        _vm._v(" "),
        _c("a", { attrs: { href: "https://www.svenskcater.se/om-oss/" } }, [
          _c(
            "button",
            {
              staticClass: "button",
              staticStyle: { "background-color": "#a2c2d1", color: "#ffffff" },
              on: { click: _vm.closeModal },
            },
            [_vm._v("\n          Jag är under 20.\n        ")]
          ),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "button",
            staticStyle: { "background-color": "#0a447c", color: "#ffffff" },
            on: { click: _vm.verifyAge },
          },
          [_vm._v("\n        Jag är 20 år eller äldre.\n      ")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }