export var MarkingTypes;
(function (MarkingTypes) {
    MarkingTypes[MarkingTypes["HasCampaign"] = 0] = "HasCampaign";
    MarkingTypes[MarkingTypes["Fairtrade"] = 1] = "Fairtrade";
    MarkingTypes[MarkingTypes["SwanLabel"] = 2] = "SwanLabel";
    MarkingTypes[MarkingTypes["KeyholeLabel"] = 3] = "KeyholeLabel";
    MarkingTypes[MarkingTypes["GMOLabel"] = 4] = "GMOLabel";
    MarkingTypes[MarkingTypes["MSCMarkFish"] = 5] = "MSCMarkFish";
    MarkingTypes[MarkingTypes["EUMarkOrganic"] = 6] = "EUMarkOrganic";
    MarkingTypes[MarkingTypes["Sugarfree"] = 7] = "Sugarfree";
    MarkingTypes[MarkingTypes["LactoseFree"] = 8] = "LactoseFree";
    MarkingTypes[MarkingTypes["GlutenFree"] = 9] = "GlutenFree";
    MarkingTypes[MarkingTypes["MilkProteinFree"] = 10] = "MilkProteinFree";
    MarkingTypes[MarkingTypes["SojaFree"] = 11] = "SojaFree";
    MarkingTypes[MarkingTypes["FreeOfEggs"] = 12] = "FreeOfEggs";
    MarkingTypes[MarkingTypes["FreeOfMilk"] = 13] = "FreeOfMilk";
    MarkingTypes[MarkingTypes["PeaProteinFree"] = 14] = "PeaProteinFree";
    MarkingTypes[MarkingTypes["FreeOfProtein"] = 15] = "FreeOfProtein";
    MarkingTypes[MarkingTypes["FreeOfNut"] = 16] = "FreeOfNut";
    MarkingTypes[MarkingTypes["FreeOfAlmonds"] = 17] = "FreeOfAlmonds";
    MarkingTypes[MarkingTypes["FreeOfPeanuts"] = 18] = "FreeOfPeanuts";
    MarkingTypes[MarkingTypes["Agreement"] = 19] = "Agreement";
    MarkingTypes[MarkingTypes["KravLabel"] = 20] = "KravLabel";
    MarkingTypes[MarkingTypes["FromSweden"] = 21] = "FromSweden";
    MarkingTypes[MarkingTypes["SwedishBird"] = 22] = "SwedishBird";
    MarkingTypes[MarkingTypes["SwedishSeal"] = 23] = "SwedishSeal";
    MarkingTypes[MarkingTypes["MeatfromSweden"] = 24] = "MeatfromSweden";
    MarkingTypes[MarkingTypes["FSC"] = 25] = "FSC";
    MarkingTypes[MarkingTypes["Vegan"] = 26] = "Vegan";
    MarkingTypes[MarkingTypes["ASCMarkFish"] = 27] = "ASCMarkFish";
    MarkingTypes[MarkingTypes["Systemk\u00F6p"] = 28] = "Systemk\u00F6p";
    MarkingTypes[MarkingTypes["Halal"] = 29] = "Halal";
    MarkingTypes[MarkingTypes["LocalProduct"] = 30] = "LocalProduct";
    MarkingTypes[MarkingTypes["SellOut"] = 31] = "SellOut";
})(MarkingTypes || (MarkingTypes = {}));
