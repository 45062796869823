var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { AuthService } from "services/AuthService";
import { ApprovalService } from "services/ApprovalService";
import { ToastService } from "services/ToastService";
import LoginFormPartial from "./partials/LoginFormPartial.vue";
import LoginAcceptancePartial from "./partials/LoginAcceptancePartial.vue";
import RequestUsernamePartial from "./partials/RequestUsernamePartial.vue";
import RequestPasswordPartial from "./partials/RequestPasswordPartial.vue";
import DeliveryDatePicker from "../shared/DeliveryDatePicker.vue";
import AgeVerification from "./AgeVerification.vue";
var StepTypes;
(function (StepTypes) {
    StepTypes["ageVerification"] = "ageVerification";
    StepTypes["login"] = "login";
    StepTypes["acceptance"] = "acceptance";
    StepTypes["deliveryDate"] = "deliveryDate";
    StepTypes["requestUsername"] = "requestUsername";
    StepTypes["requestPassword"] = "requestPassword";
})(StepTypes || (StepTypes = {}));
let LoginModal = class LoginModal extends Vue {
    loadedTimeout = false;
    newCustomerLink;
    activeStep = StepTypes.ageVerification;
    deliveryDates = null;
    waitingApprovals = null;
    ageVerified = false;
    setAgeVerified() {
        this.ageVerified = true;
        this.activeStep = StepTypes.login;
    }
    isSameStep(step) {
        return this.activeStep === StepTypes[step];
    }
    close() {
        this.ageVerified = false;
        this.$emit("close-modal");
    }
    loginConfirmed() {
        Promise.all([
            ApprovalService.getWaitingForLoginApproval(),
            AuthService.getDeliveryDates(),
        ])
            .then((res) => {
            this.waitingApprovals = res[0];
            this.deliveryDates = res[1];
            if (this.waitingApprovals.length > 0) {
                this.activeStep = StepTypes.acceptance;
            }
            else {
                this.activeStep = StepTypes.deliveryDate;
            }
        })
            .catch((err) => {
            ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
        });
    }
    getHeaderTranslation() {
        let transKey = "webshop_login_header";
        if (this.activeStep === StepTypes.requestUsername)
            transKey = "webshop_login_requestUsername_header";
        else if (this.activeStep === StepTypes.requestPassword)
            transKey = "webshop_login_requestPassword_header";
        return Vue.filter("translate")(transKey);
    }
    acceptanceAccepted() {
        this.activeStep = StepTypes.deliveryDate;
    }
    goRequestUsername() {
        this.activeStep = StepTypes.requestUsername;
    }
    goRequestPassword() {
        this.activeStep = StepTypes.requestPassword;
    }
    goBack() {
        this.activeStep = StepTypes.login;
    }
};
LoginModal = __decorate([
    Component({
        components: {
            AgeVerification,
            LoginFormPartial,
            LoginAcceptancePartial,
            DeliveryDatePicker,
            RequestUsernamePartial,
            RequestPasswordPartial,
        },
        props: {
            newCustomerLink: String,
        },
    })
], LoginModal);
export default LoginModal;
