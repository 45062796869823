var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table-outer-row" }, [
    _c("div", { staticClass: "table-row" }, [
      _c(
        "div",
        { staticClass: "table-column statistics-result-column-productNo" },
        [
          _c("span", [
            _vm._v(
              _vm._s(_vm._f("translate")("webshop_general_productNo")) + ": "
            ),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.product.productId))]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "table-column" }, [
        _c("span", [
          _vm._v(_vm._s(_vm._f("translate")("webshop_general_name")) + ": "),
        ]),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.product.product.description))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "table-column" }, [
        _c("span", [
          _vm._v(_vm._s(_vm._f("translate")("webshop_general_brand")) + ": "),
        ]),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.product.product.brand))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "table-column align-right mobile-align-left" }, [
        _c("span", [
          _vm._v(_vm._s(_vm._f("translate")("webshop_general_price")) + ": "),
        ]),
        _vm._v(" "),
        _vm.showPrice
          ? _c("span", [
              _vm._v(
                _vm._s(
                  _vm._f("currency")(_vm.product.product.comparisonPrice)
                ) +
                  "/" +
                  _vm._s(_vm.product.product.comparisonUnit)
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "table-column align-right mobile-align-left statistics-result-column-quantity",
        },
        [
          _c("span", [
            _vm._v(
              _vm._s(_vm._f("translate")("webshop_general_quantity")) + ": "
            ),
          ]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.product.quantity))]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "table-column statistics-result-column-unit" }, [
        _c("span", [
          _vm._v(_vm._s(_vm._f("translate")("webshop_general_unit")) + ": "),
        ]),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.product.product.basicUnit))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }