var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "inline-delete-button" }, [
    _c(
      "span",
      {
        staticClass: "list-options",
        on: {
          click: function ($event) {
            _vm.showOverlay = !_vm.showOverlay
          },
        },
      },
      [_c("icon-trash", { attrs: { tabindex: _vm.showOverlay ? -1 : "" } })],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "list-actions", class: { active: _vm.showOverlay } },
      [
        _c("p", { staticClass: "large-delete-text" }, [
          _vm._v("\n            " + _vm._s(_vm.longMessage) + "\n        "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "small-delete-text" }, [
          _vm._v("\n            " + _vm._s(_vm.shortMessage) + "\n        "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "delete-actions" }, [
          _c(
            "button",
            {
              attrs: { tabindex: _vm.showOverlay ? 1 : -1 },
              on: {
                click: function ($event) {
                  return _vm.deleteItem()
                },
              },
            },
            [
              _vm._v(
                _vm._s(
                  _vm._f("translate")(
                    "webshop_inventory_lists_delete_confimation"
                  )
                )
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              attrs: { tabindex: _vm.showOverlay ? 1 : -1 },
              on: {
                click: function ($event) {
                  _vm.showOverlay = false
                },
              },
            },
            [
              _vm._v(
                _vm._s(
                  _vm._f("translate")("webshop_inventory_lists_delete_cancel")
                )
              ),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }