var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import InventoryProductModal from './modals/InventoryProductModal.vue';
import InventoryOwnProductEditModal from './modals/InventoryOwnProductEditModal.vue';
import InventoryConfirmModal from './modals/InventoryConfirmModal.vue';
import InventoryProductDeleteModal from './modals/InventoryProductDeleteModal.vue';
import InventoryEmailModal from './modals/InventoryEmailModal.vue';
import InventoryAutolagerModal from './modals/InventoryAutolagerModal.vue';
import InventoryProductSortModal from './modals/InventoryProductSortModal.vue';
import { EventTypes } from 'models/EventTypes';
import { ModalSettings } from 'models/utility/ModalSettings';
let InventoryModals = class InventoryModals extends Vue {
    showProductModal = false;
    showOwnProductEditModal = false;
    showConfirmModal = false;
    showDeleteProductModal = false;
    showEmailModal = false;
    showAutolagerModal = false;
    showProductSortModal = false;
    listId = null;
    list = null;
    line = null;
    confirmModalSettings;
    created() {
        this.$root.$on(EventTypes.InventoryModalProductOpen, this.onOpenProductModal);
        this.$root.$on(EventTypes.InventoryModalProductClose, this.onCloseProductModal);
        this.$root.$on(EventTypes.InventoryModalOwnProductEditOpen, this.onOpenOwnProductEditModal);
        this.$root.$on(EventTypes.InventoryModalOwnProductEditClose, this.onCloseOwnProductEditModal);
        this.$root.$on(EventTypes.InventoryModalConfirmOpen, this.onOpenConfirmModal);
        this.$root.$on(EventTypes.InventoryModalConfirmClose, this.onCloseConfirmModal);
        this.$root.$on(EventTypes.InventoryModalProductDeleteOpen, this.onOpenDeleteProductModal);
        this.$root.$on(EventTypes.InventoryModalProductDeleteClose, this.onCloseDeleteProductModal);
        this.$root.$on(EventTypes.InventoryModalEmailOpen, this.onOpenEmailModal);
        this.$root.$on(EventTypes.InventoryModalEmailClose, this.onCloseEmailModal);
        this.$root.$on(EventTypes.InventoryModalAutolagerOpen, this.onOpenAutolagerModal);
        this.$root.$on(EventTypes.InventoryModalAutolagerClose, this.onCloseAutolagerModal);
        this.$root.$on(EventTypes.InventoryModalProductSortOpen, this.onOpenProductSortModal);
        this.$root.$on(EventTypes.InventoryModalProductSortClose, this.onCloseProductSortModal);
    }
    beforeDestroy() {
        this.$root.$off(EventTypes.InventoryModalProductOpen, this.onOpenProductModal);
        this.$root.$off(EventTypes.InventoryModalProductClose, this.onCloseProductModal);
        this.$root.$off(EventTypes.InventoryModalOwnProductEditOpen, this.onOpenOwnProductEditModal);
        this.$root.$off(EventTypes.InventoryModalOwnProductEditClose, this.onCloseOwnProductEditModal);
        this.$root.$off(EventTypes.InventoryModalConfirmOpen, this.onOpenConfirmModal);
        this.$root.$off(EventTypes.InventoryModalConfirmClose, this.onCloseConfirmModal);
        this.$root.$off(EventTypes.InventoryModalProductDeleteOpen, this.onOpenDeleteProductModal);
        this.$root.$off(EventTypes.InventoryModalProductDeleteClose, this.onCloseDeleteProductModal);
        this.$root.$off(EventTypes.InventoryModalEmailOpen, this.onOpenEmailModal);
        this.$root.$off(EventTypes.InventoryModalEmailClose, this.onCloseEmailModal);
        this.$root.$off(EventTypes.InventoryModalAutolagerOpen, this.onOpenAutolagerModal);
        this.$root.$off(EventTypes.InventoryModalAutolagerClose, this.onCloseAutolagerModal);
        this.$root.$off(EventTypes.InventoryModalProductSortOpen, this.onOpenProductSortModal);
        this.$root.$off(EventTypes.InventoryModalProductSortClose, this.onCloseProductSortModal);
    }
    onOpenProductModal(list) {
        this.list = list;
        this.showProductModal = true;
    }
    onCloseProductModal() {
        this.showProductModal = false;
        this.list = null;
    }
    onOpenOwnProductEditModal(list, line) {
        this.list = list;
        this.line = line;
        this.showOwnProductEditModal = true;
    }
    onCloseOwnProductEditModal() {
        this.showOwnProductEditModal = false;
        this.list = null;
        this.line = null;
    }
    onOpenConfirmModal(settings = null) {
        if (!settings)
            settings = new ModalSettings({});
        this.confirmModalSettings = settings;
        this.showConfirmModal = true;
    }
    onCloseConfirmModal() {
        this.showConfirmModal = false;
        this.confirmModalSettings = null;
    }
    onOpenDeleteProductModal(list, line) {
        this.showDeleteProductModal = true;
        this.list = list;
        this.line = line;
    }
    onCloseDeleteProductModal() {
        this.showDeleteProductModal = false;
        this.list = null;
        this.line = null;
    }
    onOpenEmailModal(list) {
        this.list = list;
        this.showEmailModal = true;
    }
    onCloseEmailModal() {
        this.list = null;
        this.showEmailModal = false;
    }
    onOpenAutolagerModal(listId) {
        this.listId = listId;
        this.showAutolagerModal = true;
    }
    onCloseAutolagerModal() {
        this.list = null;
        this.showAutolagerModal = false;
    }
    onOpenProductSortModal(list) {
        this.list = list;
        this.showProductSortModal = true;
    }
    onCloseProductSortModal() {
        this.showProductSortModal = false;
    }
};
InventoryModals = __decorate([
    Component({
        components: {
            InventoryProductModal,
            InventoryOwnProductEditModal,
            InventoryConfirmModal,
            InventoryProductDeleteModal,
            InventoryEmailModal,
            InventoryAutolagerModal,
            InventoryProductSortModal
        }
    })
], InventoryModals);
export default InventoryModals;
