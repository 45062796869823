var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import StatisticsResultProductList from './StatisticsResultProductList.vue';
import { ProductStatisticsFilter } from '../../../models/statistics/ProductStatisticsFilter';
import { ToastService } from 'modules/basic-core/basic-toast/src';
import { StatisticsService } from 'services/StatisticService';
let StatisticsResult = class StatisticsResult extends Vue {
    loading;
    products;
    productStatisticsFilter;
    getStatisticsExcel() {
        if (this.productStatisticsFilter.fromDate > this.productStatisticsFilter.toDate) {
            ToastService.danger(Vue.filter("translate")("webshop_statistics_filter_products_submit_wrongDates"), 3000);
        }
        else {
            var excelUrl = StatisticsService.getArticlesByDatesExcel(this.productStatisticsFilter);
            var anchor = document.createElement("a");
            anchor.style.display = "none";
            anchor.href = excelUrl;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
        }
    }
};
StatisticsResult = __decorate([
    Component({
        components: {
            StatisticsResultProductList
        },
        props: {
            loading: Boolean,
            products: Array,
            productStatisticsFilter: ProductStatisticsFilter
        }
    })
], StatisticsResult);
export default StatisticsResult;
