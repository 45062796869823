var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import CalculationModalCustomerItemForm from './CalculationModalCustomerItemForm.vue';
let CalculationModalCustomerItem = class CalculationModalCustomerItem extends Vue {
    data;
    loading = false;
    save(line) {
        this.$store.dispatch('Calculations/updateCustomerItem', line).then(res => {
            this.data.onSaved(line);
            this.$emit("close-modal");
        });
    }
    setLoading(value) {
        this.loading = value;
    }
    get loadingOnSave() {
        return this.$store.state.Calculations.loading;
    }
    closeModal() {
        this.$emit('close-modal');
    }
};
CalculationModalCustomerItem = __decorate([
    Component({
        props: {
            data: Object,
        },
        components: {
            CalculationModalCustomerItemForm
        }
    })
], CalculationModalCustomerItem);
export default CalculationModalCustomerItem;
