import { render, staticRenderFns } from "./ShopResults.vue?vue&type=template&id=bee41132&scoped=true&"
import script from "./ShopResults.vue?vue&type=script&lang=ts&"
export * from "./ShopResults.vue?vue&type=script&lang=ts&"
import style0 from "./ShopResults.vue?vue&type=style&index=0&id=bee41132&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bee41132",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Projekt\\webbshop\\SvenskCater\\SvenskCater\\app\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bee41132')) {
      api.createRecord('bee41132', component.options)
    } else {
      api.reload('bee41132', component.options)
    }
    module.hot.accept("./ShopResults.vue?vue&type=template&id=bee41132&scoped=true&", function () {
      api.rerender('bee41132', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/shop/result/ShopResults.vue"
export default component.exports