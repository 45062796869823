export class InventoryLine {
    id;
    listId;
    groupId;
    productId;
    amount;
    unit;
    units;
    sorting;
    description;
    brand;
    postingGroup;
    pricePrUnit;
    created;
    updated;
    productInfoLink;
    supplierProductId;
    editedUnit;
    editedPrice;
    productStatus;
    markings;
    _selected = false;
    constructor() {
        this.id = null;
        this.listId = null;
        this.groupId = null;
        this.productId = null;
        this.amount = null;
        this.unit = null;
        this.units = null;
        this.sorting = null;
        this.description = null;
        this.brand = null;
        this.postingGroup = null;
        this.pricePrUnit = null;
        this.created = null;
        this.updated = null;
        this.productInfoLink = null;
        this.supplierProductId = null;
        this.editedUnit = null;
        this.editedPrice = null;
        this.productStatus = null;
        this.markings = null;
    }
    static fromApi(line) {
        return Object.assign(new InventoryLine(), line);
    }
}
