import { OrderService } from '../../services/OrderService';
import Vue from 'vue';
import { ToastService } from 'services/ToastService';
const state = {
    list: [],
    activeOrder: null,
    loading: false
};
const getters = {};
const actions = {
    async getAllOrders({ commit }) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            OrderService.getOrders().then(orders => {
                commit('setAllOrders', orders);
                commit('setLoading', false);
                resolve(orders);
            }).catch(err => {
                commit('setLoading', false);
                reject();
            });
        });
    },
    setActiveOrder({ dispatch, commit }, orderNo) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            OrderService.setActiveOrder(orderNo).then(ord => {
                commit('setActiveOrder', ord);
                commit('setLoading', false);
                resolve(ord);
                ToastService.success(Vue.filter("translate")("webshop_orders_result_successActivate"));
            }).catch(async (err) => {
                commit('setLoading', false);
                await dispatch('getAllOrders');
                reject();
                ToastService.danger(Vue.filter("translate")("webshop_orders_result_errorOnActivate"));
            });
        });
    },
    createOrder({ commit }, order) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            OrderService.createOrder(order).then(ord => {
                commit('createOrder', ord);
                commit('setLoading', false);
                resolve(ord);
                ToastService.success(Vue.filter("translate")("webshop_orders_modals_order_successNew"));
            }).catch(err => {
                commit('setLoading', false);
                reject();
                ToastService.danger(Vue.filter("translate")("webshop_orders_modals_order_errorOnNew"));
            });
        });
    },
    updateOrder({ dispatch, commit }, order) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            OrderService.updateOrder(order.orderNo, order).then(ord => {
                commit('updateOrder', ord);
                commit('setLoading', false);
                resolve(ord);
                ToastService.success(Vue.filter("translate")("webshop_orders_modals_order_successEdit"));
            }).catch(async (err) => {
                commit('setLoading', false);
                await dispatch('getAllOrders');
                reject();
                ToastService.danger(Vue.filter("translate")("webshop_orders_modals_order_errorOnEdit"));
            });
        });
    },
    deleteOrder({ dispatch, commit }, order) {
        return new Promise((resolve, reject) => {
            commit('setLoading', true);
            OrderService.deleteOrder(order).then(ord => {
                commit('deleteOrder', ord);
                commit('setLoading', false);
                resolve(ord);
                ToastService.success(Vue.filter("translate")("webshop_orders_modals_order_successDelete"));
            }).catch(async (err) => {
                commit('setLoading', false);
                await dispatch('getAllOrders');
                reject();
                ToastService.danger(Vue.filter("translate")("webshop_orders_modals_order_errorOnDelete"));
            });
        });
    }
};
const mutations = {
    setAllOrders(state, orders) {
        state.list = orders;
    },
    setActiveOrder(state, order) {
        state.list.forEach(ord => {
            if (order.orderId === ord.orderNo) {
                ord.active = true;
            }
            else if (ord.active) {
                ord.active = false;
            }
        });
        state.activeOrder = order;
    },
    createOrder(state, order) {
        state.list.push(order);
    },
    updateOrder(state, order) {
        let indexOrder = state.list.findIndex(ord => ord.orderNo === order.orderNo);
        state.list.splice(indexOrder, 1, order);
    },
    deleteOrder(state, order) {
        state.list = state.list.filter(ord => ord.orderNo !== order.orderNo);
    },
    setLoading(state, status) {
        state.loading = status;
    }
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
