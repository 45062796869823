var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ui-card-content" }, [
    _c(
      "div",
      { staticClass: "div-table" },
      [
        _vm.loading
          ? [_c("loader", { attrs: { size: "large" } })]
          : [
              _vm.products.length > 0
                ? [
                    _c("div", { staticClass: "table-header" }, [
                      _c("div", { staticClass: "table-row" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "table-column statistics-result-column-productNo",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")("webshop_general_productNo")
                              )
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "table-column" }, [
                          _vm._v(
                            _vm._s(_vm._f("translate")("webshop_general_name"))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "table-column" }, [
                          _vm._v(
                            _vm._s(_vm._f("translate")("webshop_general_brand"))
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "table-column align-right mobile-align-left",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")("webshop_general_price")
                              )
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "table-column align-right mobile-align-left statistics-result-column-quantity",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")("webshop_general_quantity")
                              )
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "table-column statistics-result-column-unit",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")("webshop_general_unit")
                              )
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "table-body" },
                      _vm._l(_vm.products, function (product) {
                        return _c("statistics-result-product-item", {
                          key: "statisticsArticle" + product.productId,
                          attrs: { product: product },
                        })
                      }),
                      1
                    ),
                  ]
                : [
                    _c("i", [
                      _vm._v(
                        _vm._s(
                          _vm._f("translate")(
                            "webshop_statistics_result_products_emptyListText"
                          )
                        )
                      ),
                    ]),
                  ],
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }