var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ui-card-content" }, [
    _c(
      "div",
      { staticClass: "div-table" },
      [
        _vm.loading
          ? [_c("loader", { attrs: { size: "large" } })]
          : [
              _vm.orders.length > 0
                ? [
                    _c("div", { staticClass: "table-header" }, [
                      _c("div", { staticClass: "table-row" }, [
                        _c("div", { staticClass: "table-column" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("translate")(
                                "webshop_orders_result_columnNames_name"
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "table-column column-order-active" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_orders_result_columnNames_active"
                                )
                              )
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "table-column" }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "table-body" },
                      _vm._l(_vm.orders, function (order) {
                        return _c("orders-result-list-item", {
                          key: "order" + order.orderNo,
                          attrs: { order: order },
                        })
                      }),
                      1
                    ),
                  ]
                : [
                    _c("i", [
                      _vm._v(
                        _vm._s(
                          _vm._f("translate")(
                            "webshop_orders_result_emptyListText"
                          )
                        )
                      ),
                    ]),
                  ],
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }