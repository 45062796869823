var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import VueRouter from "vue-router";
import Component from "vue-class-component";
import { Settings } from "config/Settings";
import { ModalService } from "modules/basic-core/basic-modal/src";
import IpaperAddToCartModal from "./IpaperAddToCartModal.vue";
import IpaperRedirectToLoginModal from "./IpaperRedirectToLoginModal.vue";
const routes = [{
        name: "ipaper",
        path: "/ipaper"
    }];
const router = new VueRouter({
    mode: "history",
    routes: routes
});
let Ipaper = class Ipaper extends Vue {
    Settings = Settings;
    mounted() {
        window.addEventListener("ipaperAddToBasketEvent", this.addToBasket);
    }
    beforeDestroy() {
        window.removeEventListener("ipaperAddToBasketEvent", this.addToBasket);
    }
    addToBasket(evt) {
        let data = evt.detail;
        if (!Settings.profileInfo || !Settings.profileInfo.customerNo) {
            let pageUrl = new URL(window.location.href);
            pageUrl.searchParams.set("page", (data.pageIndex + 1).toString());
            let redirectUrl = "/?returnUrl=" + pageUrl.pathname + pageUrl.search;
            this.redirectToLoginModal(redirectUrl);
        }
        else {
            this.addToBasketModal(data);
        }
    }
    redirectToLoginModal(returnUrl) {
        ModalService.create({
            cxt: this,
            component: IpaperRedirectToLoginModal,
            maxWidth: "480px",
            data: returnUrl
        });
    }
    addToBasketModal(data) {
        ModalService.create({
            cxt: this,
            component: IpaperAddToCartModal,
            maxWidth: "480px",
            data: data
        });
    }
};
Ipaper = __decorate([
    Component({
        router: router
    })
], Ipaper);
export default Ipaper;
