var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { EventTypes } from 'models/EventTypes';
import { SearchInput } from 'models/search/SearchInput';
let ShopResultPagination = class ShopResultPagination extends Vue {
    loading;
    totalProducts;
    input;
    get totalPages() {
        return Math.ceil(this.totalProducts / this.input.pageSize);
    }
    get pagination() {
        var pagination = [];
        var i = 0;
        if (this.totalPages <= 5) {
            for (i = 0; i < this.totalPages; i++) {
                pagination.push({
                    val: i + 1,
                    isActive: this.input.page === i + 1
                });
            }
        }
        else {
            //if there is more than 5 pages, we need to do some fancy bits
            //get the max index to start
            var maxIndex = this.totalPages - 5;
            //set the start, but it can't be below zero
            var start = Math.max(this.input.page - 5, 0);
            //ensure that it's not too far either
            start = Math.min(maxIndex, start);
            for (i = start; i < 5 + start; i++) {
                pagination.push({
                    val: i + 1,
                    isActive: this.input.page === i + 1
                });
            }
            //now, if the start is greater than 0 then '1' will not be displayed, so do the elipses thing
            if (start > 0) {
                pagination.unshift({
                    name: Vue.filter('translate')('webshop_search_search_result_pagination_first'),
                    icon: "mdi-chevron-double-left",
                    val: 1,
                    isActive: false
                }, {
                    val: '...',
                    isActive: false
                });
            }
            //same for the end
            if (start < maxIndex) {
                pagination.push({
                    val: '...',
                    isActive: false
                }, {
                    name: Vue.filter('translate')('webshop_search_search_result_pagination_last'),
                    icon: "mdi-chevron-double-right",
                    val: this.totalPages,
                    isActive: false
                });
            }
        }
        return pagination;
    }
    prev() {
        let pageNumber = this.input.page - 1;
        if (pageNumber > 0) {
            this.emitPageChange(pageNumber);
        }
    }
    next() {
        let pageNumber = this.input.page + 1;
        if (pageNumber < this.totalPages) {
            this.emitPageChange(pageNumber);
        }
    }
    goToPage(pageNumber) {
        this.emitPageChange(pageNumber);
    }
    emitPageChange(selectedPage) {
        let input = Object.assign(new SearchInput(), this.input);
        input.page = selectedPage;
        this.$root.$emit(EventTypes.ShopSearchStart, input, false);
    }
};
ShopResultPagination = __decorate([
    Component({
        props: {
            loading: Boolean,
            totalProducts: Number,
            input: Object
        }
    })
], ShopResultPagination);
export default ShopResultPagination;
