import { CacheItem } from "./CacheItem";
export class Cache {
    items;
    constructor() {
        this.items = [];
    }
    add(id, params, promise, expiry = null) {
        let item = new CacheItem(id, JSON.stringify(params, Object.keys(params).sort()), promise, new Date(), expiry ? expiry : new Date(8640000000000000));
        this.items.push(item);
        return item;
    }
    get(id, params) {
        return this.items.find((item) => {
            return item.id == id && item.params == JSON.stringify(params, Object.keys(params).sort());
        });
    }
    remove(id, params) {
        var idx = this.items.findIndex((item) => {
            return item.id == id && item.params == JSON.stringify(params, Object.keys(params).sort());
        });
        if (idx > -1) {
            this.items.splice(idx, 1);
        }
    }
}
