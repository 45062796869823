var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import CampaignPlanningLineModal from '../modals/CampaignPlanningLineModal.vue';
import { ModalService } from 'modules/basic-core/basic-modal/src';
import { CampaignPlanningLineCRUD } from 'models/campaignPlanning/CampaignPlanningLineCRUD';
import { CampaignPlanningLineVM } from 'models/campaignPlanning/CampaignPlanningLineVM';
import { Settings } from 'config/Settings';
import { CampaignPlanningLineStatus } from 'models/campaignPlanning/CampaignPlanningLineStatus';
let CampaignPlanningHeader = class CampaignPlanningHeader extends Vue {
    campaign;
    addProduct() {
        if (this.isAddProductDisabled)
            return;
        let data = new CampaignPlanningLineCRUD();
        data.campaign = this.$store.state.VendorCampaignPlanning.selectedCampaign;
        data.campaignLine = new CampaignPlanningLineVM();
        data.campaignLine.vendorNo = Settings.profileInfo.vendorNo;
        data.isNew = true;
        ModalService.create({
            cxt: this,
            component: CampaignPlanningLineModal,
            maxWidth: "720px",
            data: data
        });
    }
    isValidPurchaseDeadline() {
        let currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 3);
        return new Date(this.campaign.purchaseStartingDate) > currentDate;
    }
    get isAddProductDisabled() {
        const activeLines = this.campaign.campaignPlaningLines
            .filter(line => line.status != CampaignPlanningLineStatus.Rejected).length;
        return activeLines >= 15;
    }
    get disabledAddProductTitle() {
        if (this.isAddProductDisabled)
            return Vue.filter("translate")("webshop_vendor_campaignPlanning_campaignLines_disabledAddProductTitle");
    }
};
CampaignPlanningHeader = __decorate([
    Component({
        props: {
            campaign: Object
        },
        components: {}
    })
], CampaignPlanningHeader);
export default CampaignPlanningHeader;
