var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "a",
      {
        staticClass: "shop-product-addToFavorites",
        class: {
          "shop-product-addToFavorites-active": _vm.product.isOnFavoriteList,
        },
        on: {
          click: function ($event) {
            return _vm.toggle()
          },
        },
      },
      [
        _c("span", { staticClass: "shop-favorite-list-icon" }, [
          _c("span", { staticClass: "icon-list" }),
          _vm._v(" "),
          _vm.product.isOnFavoriteList
            ? _c("span", { staticClass: "shop-favorite-checkmarkWrapper" }, [
                _c("span", { staticClass: "icon-checkmark" }),
              ])
            : _vm._e(),
        ]),
      ]
    ),
    _vm._v(" "),
    _vm.showMenu
      ? _c("div", { staticClass: "ui-menu ui-menu-active" }, [
          _vm.product.status !== _vm.itemStatus.Deleted
            ? _c(
                "ul",
                { ref: "listWrapper" },
                _vm._l(_vm.lists, function (list) {
                  return _c(
                    "li",
                    {
                      key: list.id,
                      class: { "ui-menu-item-active": list.productExists },
                      attrs: {
                        title: list.productExists
                          ? _vm.$options.filters.translate(
                              "webshop_search_search_item_favList_remove"
                            )
                          : "",
                      },
                      on: {
                        click: function ($event) {
                          !list.productExists ? _vm.add(list) : _vm.remove(list)
                        },
                      },
                    },
                    [
                      _c("span", {
                        class: {
                          "icon-playlist-add": !list.productExists,
                          "mdi mdi-delete": list.productExists,
                        },
                      }),
                      _vm._v(_vm._s(list.listName) + "\r\n            "),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "ui-menu-footer" }, [
            !_vm.currentListId
              ? _c(
                  "button",
                  {
                    staticClass:
                      "ui-button ui-button-flat shop-favoriteList-toggleCreate",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.createNewList()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm._f("translate")(
                          "webshop_search_search_item_favList_createNewList"
                        )
                      ) + "\r\n            "
                    ),
                  ]
                )
              : _c(
                  "button",
                  {
                    staticClass:
                      "ui-button ui-button-flat shop-favoriteList-removeFromActive",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.removeFromActive()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm._f("translate")(
                          "webshop_search_search_item_favList_removeFromActive"
                        )
                      ) + "\r\n            "
                    ),
                  ]
                ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.showMenu
      ? _c("div", {
          staticClass: "menu-overlay",
          on: {
            click: function ($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              return _vm.close()
            },
          },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }