import Vue from 'vue';
export class ItemIcons {
    HasCampaign = {
        label: Vue.filter('translate')('webshop_icons_hasCampaign'),
        icon: "appicon appicon-campaign"
    };
    Fairtrade = {
        label: Vue.filter('translate')('webshop_icons_fairtrade'),
        icon: "custom-icon__fairtrade"
    };
    SwanLabel = {
        label: Vue.filter('translate')('webshop_icons_swanLabel'),
        icon: "appicon appicon-swan-label"
    };
    KeyholeLabel = {
        label: Vue.filter('translate')('webshop_icons_keyhole'),
        icon: "icon-keyhole"
    };
    GMOLabel = {
        label: Vue.filter('translate')('webshop_icons_gmoLabel'),
        icon: ""
    };
    MSCMarkFish = {
        label: Vue.filter('translate')('webshop_icons_mscMarkFish'),
        icon: "custom-icon__msc"
    };
    EUMarkOrganic = {
        label: Vue.filter('translate')('webshop_icons_organic'),
        icon: "custom-icon__eu-organic"
    };
    Sugarfree = {
        label: Vue.filter('translate')('webshop_icons_sugarfree'),
        icon: "appicon appicon-sugar-free"
    };
    LactoseFree = {
        label: Vue.filter('translate')('webshop_icons_lactoseFree'),
        icon: "custom-icon__lactose-free"
    };
    GlutenFree = {
        label: Vue.filter('translate')('webshop_icons_glutenFree'),
        icon: "custom-icon__gluten-free"
    };
    MilkProteinFree = {
        label: Vue.filter('translate')('webshop_icons_milkProteinFree'),
        icon: "appicon appicon-milk-protein-free"
    };
    SojaFree = {
        label: Vue.filter('translate')('webshop_icons_sojaFree'),
        icon: "appicon appicon-soja-free"
    };
    FreeOfEggs = {
        label: Vue.filter('translate')('webshop_icons_freeOfEggs'),
        icon: "appicon appicon-eggs-free"
    };
    FreeOfMilk = {
        label: Vue.filter('translate')('webshop_icons_freeOfMilk'),
        icon: "appicon appicon-free-of-milk"
    };
    PeaProteinFree = {
        label: Vue.filter('translate')('webshop_icons_peaProteinFree'),
        icon: "appicon appicon-pea-protein-free"
    };
    FreeOfProtein = {
        label: Vue.filter('translate')('webshop_icons_freeOfProtein'),
        icon: "appicon appicon-protein-free"
    };
    FreeOfNut = {
        label: Vue.filter('translate')('webshop_icons_freeOfNut'),
        icon: "appicon appicon-nuts-free"
    };
    FreeOfAlmonds = {
        label: Vue.filter('translate')('webshop_icons_freeOfAlmonds'),
        icon: "appicon appicon-almonds-free"
    };
    FreeOfPeanuts = {
        label: Vue.filter('translate')('webshop_icons_freeOfPeanuts'),
        icon: "appicon appicon-peanuts-free"
    };
    Agreement = {
        label: Vue.filter('translate')('webshop_icons_agreement'),
        icon: "appicon appicon-agreement"
    };
    Cool = {
        label: Vue.filter('translate')('webshop_icons_cool'),
        icon: "icon-cool"
    };
    Frost = {
        label: Vue.filter('translate')('webshop_icons_frost'),
        icon: "icon-frost"
    };
    Colonial = {
        label: Vue.filter('translate')('webshop_icons_colonial'),
        icon: "appicon appicon-colonial"
    };
    KravLabel = {
        label: Vue.filter('translate')('webshop_icons_krav'),
        icon: "appicon appicon-krav"
    };
    FromSweden = {
        label: Vue.filter('translate')('webshop_icons_fromSweden'),
        icon: "custom-icon__se"
    };
    SwedishBird = {
        label: Vue.filter('translate')('webshop_icons_swedishBird'),
        icon: "custom-icon__swedish-bird"
    };
    SwedishSeal = {
        label: Vue.filter('translate')('webshop_icons_swedishSeal'),
        icon: "custom-icon__swedish-seal"
    };
    MeatfromSweden = {
        label: Vue.filter('translate')('webshop_icons_meatFromSweden'),
        icon: "custom-icon__meat-from-sweden"
    };
    FSC = {
        label: Vue.filter('translate')('webshop_icons_fsc'),
        icon: "appicon appicon-fsc"
    };
    Vegan = {
        label: Vue.filter('translate')('webshop_icons_vegan'),
        icon: "custom-icon__vegan"
    };
    ASCMarkFish = {
        label: Vue.filter('translate')('webshop_icons_ascMarkFish'),
        icon: "custom-icon__asc"
    };
    Systemköp = {
        label: Vue.filter('translate')('webshop_icons_systemköp'),
        icon: "appicon appicon-systemköp"
    };
    Halal = {
        label: Vue.filter('translate')('webshop_icons_halal'),
        icon: "appicon appicon-halal"
    };
    LocalProduct = {
        label: Vue.filter('translate')('webshop_icons_localProduct'),
        icon: "appicon appicon-local-product"
    };
    SellOut = {
        label: Vue.filter('translate')('webshop_icons_sellOut'),
        icon: "custom-icon__sell-out"
    };
}
export class ItemIcon {
    label;
    icon;
}
