var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Loader from 'components/utility/Loader.vue';
import { VendorHomePageContent } from 'models/vendor/VendorHomePageContent';
import { VendorService } from 'services/VendorService';
import Vue from 'vue';
import Component from 'vue-class-component';
let VendorHomePage = class VendorHomePage extends Vue {
    loading = false;
    homePageContent = new VendorHomePageContent();
    mounted() {
        this.loadHomePageContent();
    }
    loadHomePageContent() {
        this.loading = true;
        VendorService.getHomePageContent().then((res) => {
            this.homePageContent = res;
        }).catch((err) => { })
            .finally(() => this.loading = false);
    }
    deadlineInDays(purchaseStartingDate) {
        let differenceInTime = new Date(purchaseStartingDate).getTime() - new Date().getTime();
        let differenceInDays = differenceInTime / (1000 * 3600 * 24);
        let days = Math.floor(differenceInDays);
        let differenceInHours = (differenceInDays - days) * 24;
        let hours = Math.floor(differenceInHours);
        let differenceInMinutes = (differenceInHours - hours) * 60;
        let minutes = Math.floor(differenceInMinutes);
        return days + ' d ' + hours + ' h ' + minutes + ' m';
    }
};
VendorHomePage = __decorate([
    Component({
        components: {
            Loader
        },
        props: {
            descriptionText: String,
            descriptionTitle: String
        }
    })
], VendorHomePage);
export default VendorHomePage;
