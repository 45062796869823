import { Service } from './Service';
import { FavoriteApi } from 'api/FavoriteApi';
import { FavoriteList } from 'models/favorites/FavoriteList';
import { ProductVM } from 'models/product/ProductVM';
import { InventoryList } from 'models/inventory/InventoryList';
import { FavoriteListLineVM } from 'models/favorites/FavoriteListLineVM';
export class FavoriteService extends Service {
    /*
        LIST
    */
    static getAllLists(productId) {
        return new Promise((resolve, reject) => {
            FavoriteApi.getAllLists(productId).then((inventoryRes) => {
                var result = inventoryRes.data
                    .map(x => FavoriteList.fromApi(x));
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static getFavoriteListLines(listId) {
        return new Promise((resolve, reject) => {
            FavoriteApi.getFavoriteListLines(listId).then((res) => {
                var result = res.data
                    .map(x => FavoriteListLineVM.fromApi(x));
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static sortFavoriteListLines(id, lines) {
        return new Promise((resolve, reject) => {
            FavoriteApi.sortFavoriteListLines(id, lines).then((res) => {
                var result = res.data
                    .map(x => FavoriteListLineVM.fromApi(x));
                resolve(result);
            })
                .catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static addProduct(listId, productId, stdUnit, checkoutId = -1) {
        if (stdUnit) {
            stdUnit = stdUnit.trim();
        }
        return new Promise((resolve, reject) => {
            FavoriteApi.addProduct(listId, productId, stdUnit, checkoutId).then((res) => {
                resolve(res.data);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static addProducts(listId, products) {
        return new Promise((resolve, reject) => {
            FavoriteApi.addProducts(listId, products).then((res) => {
                resolve(res.data);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static createList(listName, shared) {
        return new Promise((resolve, reject) => {
            FavoriteApi.createList(listName, shared).then((res) => {
                var result = FavoriteList.fromApi(res.data);
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static createListFromHistory(listName, shared, from, to) {
        return new Promise((resolve, reject) => {
            FavoriteApi.createListFromHistory(listName, shared, from, to).then((res) => {
                var result = FavoriteList.fromApi(res.data);
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static deleteProductFromList(listId, productId) {
        return new Promise((resolve, reject) => {
            FavoriteApi.deleteProductFromList(listId, productId).then((res) => {
                resolve(res);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static replaceObsolete(oldId, newId) {
        return new Promise((resolve, reject) => {
            FavoriteApi.replaceObsolete(oldId, newId).then((res) => {
                resolve(res);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static updateList(list) {
        return new Promise((resolve, reject) => {
            FavoriteApi.updateList(list).then((res) => {
                var result = FavoriteList.fromApi(res.data);
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static startStopSharing(list) {
        return new Promise((resolve, reject) => {
            FavoriteApi.startStopSharing(list).then((res) => {
                var result = FavoriteList.fromApi(res.data);
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static copyList(list) {
        return new Promise((resolve, reject) => {
            FavoriteApi.copyList(list).then((res) => {
                var result = FavoriteList.fromApi(res.data);
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static deleteList(listId) {
        return new Promise((resolve, reject) => {
            FavoriteApi.deleteList(listId).then(() => {
                resolve();
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static searchNewProductsBySales(fromDate, listId) {
        return new Promise((resolve, reject) => {
            FavoriteApi.searchNewProductsBySales(fromDate, listId).then((res) => {
                var result = res.data
                    .map(x => ProductVM.fromApi(x));
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static searchNewProductsByQuery(query, listId) {
        return new Promise((resolve, reject) => {
            FavoriteApi.searchNewProductsByQuery(query, listId).then((res) => {
                var result = res.data
                    .map(x => ProductVM.fromApi(x));
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static createInventoryList(list) {
        return new Promise((resolve, reject) => {
            FavoriteApi.createInventoryList(list).then((res) => {
                var result = InventoryList.fromApi(res.data);
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
}
