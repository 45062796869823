var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "shop-gallery-item-wrapper": _vm.viewTypeValue == _vm.ViewTypes.Card,
      },
    },
    [
      _vm.viewTypeValue == _vm.ViewTypes.Card
        ? [
            _c("shop-gallery-item", {
              key: _vm.product.productId,
              attrs: {
                loading: _vm.loading,
                product: _vm.product,
                uiSource: _vm.uiSource,
                position: _vm.position,
              },
            }),
          ]
        : [
            _c("shop-product-list-item", {
              key: _vm.product.productId,
              attrs: {
                loading: _vm.loading,
                product: _vm.product,
                position: _vm.position,
                "ui-source": _vm.uiSource,
              },
            }),
            _vm._v(" "),
            _vm.product.AltProduct
              ? _c("shop-product-list-item", {
                  key:
                    _vm.product.productId +
                    "_" +
                    _vm.product.AltProduct.productId,
                  attrs: {
                    isAltProduct: true,
                    loading: _vm.loading,
                    product: _vm.product.AltProduct,
                    position: _vm.position,
                    "ui-source": _vm.uiSource,
                  },
                })
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }