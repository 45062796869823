var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ui-modal-content" }, [
      _c("h3", { staticClass: "ui-modal-header header-medium" }, [
        _vm._v(
          _vm._s(
            _vm._f("translate")(
              "webshop_calculations_modals_addCalculations_title"
            )
          )
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "slides" },
        [
          _vm.result && _vm.result.length > 0
            ? _c("calculation-modal-calculation-result", {
                attrs: {
                  loading: _vm.loading,
                  setLoading: _vm.setLoading,
                  list: _vm.data,
                  result: _vm.result,
                },
                on: { close: _vm.close },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "inventory-loader" }, [_vm._m(0)])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "simple-loader-ui" }, [
      _c("div", { staticClass: "simple-loader dark" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }