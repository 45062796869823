var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.menu
    ? _c(
        "div",
        [
          _vm.mobileActive
            ? _c("mobile-navigation", {
                attrs: { menu: _vm.menu },
                model: {
                  value: _vm.mobileActive,
                  callback: function ($$v) {
                    _vm.mobileActive = $$v
                  },
                  expression: "mobileActive",
                },
              })
            : _c(
                "div",
                {
                  staticClass: "fixed-top solid",
                  attrs: { id: "navigation", fixed: "top" },
                },
                [
                  _c("div", { staticClass: "nav__outer" }, [
                    _c("div", { staticClass: "nav__inner" }, [
                      _c("div", { staticClass: "nav--left" }, [
                        _c(
                          "a",
                          { attrs: { href: _vm.settings.menuBaseLink } },
                          [
                            _c("div", { staticClass: "nav__logo" }, [
                              _c("img", {
                                attrs: {
                                  id: "logo-color",
                                  src:
                                    _vm.asssetsBaseLink +
                                    "icons/logo-color.png",
                                },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                attrs: {
                                  id: "logo-white",
                                  src:
                                    _vm.asssetsBaseLink +
                                    "icons/logo-white.png",
                                },
                              }),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "nav__menu-links--internal" },
                          [
                            _vm._l(_vm.menu.Navigation, function (tlPage, i) {
                              return [
                                tlPage.Children.length > 0
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          key: i,
                                          staticClass: "nav__dropdown",
                                        },
                                        [
                                          _c(
                                            "nav-link",
                                            {
                                              attrs: {
                                                className: "regular-link",
                                                url: _vm.createLink(tlPage),
                                                label: tlPage.Name,
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "nav__dropdown__content",
                                                },
                                                _vm._l(
                                                  tlPage.Children,
                                                  function (
                                                    tlChildPage,
                                                    iChild
                                                  ) {
                                                    return _c(
                                                      "ul",
                                                      { key: iChild },
                                                      [
                                                        _c("nav-link", {
                                                          attrs: {
                                                            url: _vm.createLink(
                                                              tlChildPage
                                                            ),
                                                            label:
                                                              tlChildPage.Name,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  : [
                                      _c("nav-link", {
                                        key: i,
                                        attrs: {
                                          className:
                                            "regular-link " +
                                            _vm.activePage(tlPage),
                                          url: _vm.createLink(tlPage),
                                          label: tlPage.Name,
                                        },
                                      }),
                                    ],
                              ]
                            }),
                          ],
                          2
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "nav--right" }, [
                        _c(
                          "ul",
                          { staticClass: "nav__menu-links--external" },
                          _vm._l(
                            _vm.menu.TopNavigation,
                            function (externalLink, i) {
                              return _c("li", { key: i }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.createLink(externalLink),
                                    },
                                  },
                                  [_vm._v(_vm._s(externalLink.Name))]
                                ),
                              ])
                            }
                          ),
                          0
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "nav-mobile__toggle",
                            on: {
                              click: function ($event) {
                                return _vm.showMobile()
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: _vm.asssetsBaseLink + "icons/menu.svg",
                              },
                            }),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
          _vm._v(" "),
          _c("div", { attrs: { id: "depict_product_alternatives" } }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }