var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "ui-button ui-button-hasIcon",
      class: {
        "ui-button-loading ui-button-loading-showLoader": _vm.loading,
        "ui-button-accept": !_vm.cancel,
        "ui-button-cancel": _vm.cancel,
      },
      attrs: { type: "submit", disabled: _vm.loading },
    },
    [
      !_vm.loading
        ? [
            _c("i", {
              staticClass: "mdi",
              class: _vm.iconClass ? _vm.iconClass : "mdi-send",
            }),
            _vm._v(" " + _vm._s(_vm.text) + "\n    "),
          ]
        : [
            _vm._v("\n        " + _vm._s(_vm.loadingText) + "\n        "),
            _c(
              "svg",
              {
                staticStyle: {
                  position: "absolute",
                  fill: "#fff",
                  "enable-background": "new 0 0 30 30",
                  right: "10px",
                  top: "4px",
                },
                attrs: {
                  version: "1.1",
                  xmlns: "http://www.w3.org/2000/svg",
                  "xmlns:xlink": "http://www.w3.org/1999/xlink",
                  x: "0px",
                  y: "0px",
                  width: "30px",
                  height: "30px",
                  viewBox: "0 0 50 50",
                  "xml:space": "preserve",
                },
              },
              [
                _c(
                  "path",
                  {
                    staticStyle: { fill: "#fff" },
                    attrs: {
                      fill: "#000",
                      d: "M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z",
                      transform: "rotate(109.043 25 25)",
                    },
                  },
                  [
                    _c("animateTransform", {
                      attrs: {
                        attributeType: "xml",
                        attributeName: "transform",
                        type: "rotate",
                        from: "0 25 25",
                        to: "360 25 25",
                        dur: "1s",
                        repeatCount: "indefinite",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }