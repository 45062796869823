var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { SearchViewTypes } from "models/search/SearchViewTypes";
import { ModalService } from "modules/basic-core/basic-modal/src";
import ShopReplaceProdConfirmModal from './ShopReplaceProdConfirmModal.vue';
import { EventTypes } from "models/EventTypes";
let ShopReplaceAltProdButton = class ShopReplaceAltProdButton extends Vue {
    loading;
    product;
    productLoading;
    view;
    viewTypes = SearchViewTypes;
    listId;
    isModal;
    open() {
        ModalService.create({
            'cxt': this,
            'component': ShopReplaceProdConfirmModal,
            'maxWidth': '450px',
            data: this.product
        });
        this.$root.$once(EventTypes.FavoriteReplaceModalSuccess, this.replaceSuccess);
        this.$root.$once(EventTypes.FavoriteReplaceModalError, this.replaceError);
        this.$root.$on(EventTypes.FavoriteProductLoading, this.setProductLoading);
    }
    replaceSuccess() {
        this.$emit("replaced");
        this.setProductLoading(false);
    }
    replaceError() {
        this.setProductLoading(false);
    }
    setProductLoading(loading = true) {
        this.$emit("loading", loading);
    }
};
ShopReplaceAltProdButton = __decorate([
    Component({
        components: {
            ShopReplaceProdConfirmModal
        },
        props: {
            loading: Boolean,
            product: Object,
            productLoading: Boolean,
            view: String,
            listId: Number,
            isModal: Boolean
        }
    })
], ShopReplaceAltProdButton);
export default ShopReplaceAltProdButton;
