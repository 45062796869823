import Vue from 'vue';
Vue.filter('date', (value, format) => {
    let _format = "YYYY-MM-DD";
    if (format) {
        _format = format;
    }
    return moment(value).locale('sv').format(_format);
});
Vue.filter('timespan', (value, format) => {
    if (!format)
        format = "HH:mm";
    return moment(value, "HH:mm:ss").locale('sv').format(format);
});
