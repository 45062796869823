var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { CalculationService } from "services/CalculationService";
import CalculationModalCalculationResult from "./partials/CalculationModalCalculationResult.vue";
let CalculationModalAddProducts = class CalculationModalAddProducts extends Vue {
    loading = false;
    data;
    result = [];
    mounted() {
        this.loadCalculations();
    }
    close() {
        this.$emit("close-modal");
    }
    setLoading(value) {
        this.loading = value;
    }
    loadCalculations() {
        this.loading = true;
        CalculationService.getPossibleCalculations(this.data.calculationId).then(res => {
            this.result = res;
        }).finally(() => {
            this.loading = false;
        });
    }
};
CalculationModalAddProducts = __decorate([
    Component({
        components: {
            CalculationModalCalculationResult
        },
        props: {
            data: Object
        }
    })
], CalculationModalAddProducts);
export default CalculationModalAddProducts;
