var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { ToastService } from "services/ToastService";
import { PdfReportService } from "services/PdfReportService";
import LoadingButton from "../shared/LoadingButton.vue";
import { setTimeout } from "timers";
import { WindowService } from 'services/WindowService';
let AlcoholReportDatePicker = class AlcoholReportDatePicker extends Vue {
    fromDate = null;
    toDate = null;
    loading = false;
    dpSettings = {
        enable: [{ from: new Date(1957, 0, 1), to: new Date() }]
    };
    generateReport() {
        if (this.fromDate == null ||
            this.toDate == null ||
            this.fromDate > this.toDate) {
            ToastService.danger(Vue.filter("translate")("webshop_myPage_alcohol_no_dates_selected"));
            return;
        }
        this.loading = true;
        // Needed to trick the popup blocker to not block the window 
        const newWindow = WindowService.initializeNewWindow();
        PdfReportService.getAlcoholReport(this.fromDate, this.toDate).then(res => {
            const file = new Blob([res], { type: "application/pdf" });
            const nav = window.navigator;
            if (window.navigator && nav.msSaveOrOpenBlob) {
                nav.msSaveOrOpenBlob(file, 'alkoholrapport.pdf');
                setTimeout(() => {
                    newWindow.close();
                }, 100);
            }
            else {
                const reportLink = window.URL.createObjectURL(file);
                newWindow.location = reportLink;
            }
            this.loading = false;
        }, err => {
            setTimeout(() => {
                newWindow.close();
                ToastService.danger(Vue.filter("translate")("webshop_myPage_alcohol_no_list"));
            }, 500);
            this.loading = false;
            return;
        });
    }
};
AlcoholReportDatePicker = __decorate([
    Component({
        components: {
            LoadingButton
        },
        props: {}
    })
], AlcoholReportDatePicker);
export default AlcoholReportDatePicker;
