var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
let InputNumber = class InputNumber extends Vue {
    value;
    decimalFormat; // examples: [3.3] max number is 999.999, [0.3] infinite.999
    onlyInteger; // if it true only component only accept integer values
    inputRef;
    inputStep;
    decimalPointDot; // default decimal point is comma if you want dot set this property true
    inputMax;
    inputMin;
    inputClass;
    inputDisabled;
    inputTabindex;
    selectOnFocus;
    inputUnit;
    stringValue = '';
    lastValue = '';
    pattern = '';
    mounted() {
        this.setPattern();
        this.initValue();
    }
    onValueChange() {
        this.initValue();
    }
    onOnlyIntegerChange() {
        this.setPattern();
    }
    initValue() {
        let stringVal = '';
        if (this.value != null) {
            let newValue = this.value;
            if (this.decimalFormat) {
                let formatValues = this.decimalFormat.toString().split('.');
                if (formatValues[1]) {
                    let decimalMultiplier = Math.pow(10, Number(formatValues[1]));
                    newValue = Math.round((this.value + Number.EPSILON) * decimalMultiplier) / decimalMultiplier;
                }
            }
            stringVal = !this.decimalPointDot ? newValue.toString().replace('.', ',') : newValue.toString();
        }
        if (new RegExp(this.pattern).test(stringVal)) {
            this.stringValue = stringVal;
            this.lastValue = stringVal;
        }
    }
    setPattern() {
        this.pattern = '^(-?[0-9]*)([.,][0-9]*)?$';
        if (this.decimalFormat) {
            let formatValues = this.decimalFormat.toString().split('.');
            this.pattern = '^(-?[0-9]' + (formatValues[0] && Number(formatValues[0]) > 0 ? '{0,' + formatValues[0] + '}' : '*') +
                ')([.,][0-9]' + (formatValues[1] && Number(formatValues[1]) > 0 ? '{0,' + formatValues[1] + '}' : '*') + ')?$';
        }
        if (this.onlyInteger) {
            this.pattern = this.pattern.slice(0, this.pattern.indexOf('([.,]')) + '$';
        }
    }
    inputChange(evt) {
        evt.target.value = !this.decimalPointDot ? evt.target.value.replace('.', ',') : evt.target.value.replace(',', '.');
        if (new RegExp(this.pattern).test(evt.target.value)) {
            this.stringValue = evt.target.value;
            let outputNumber = parseFloat(evt.target.value.replace(',', '.'));
            if (isNaN(outputNumber)) {
                outputNumber = null;
            }
            if (typeof this.inputMax === 'number' && this.inputMax < outputNumber || typeof this.inputMin === 'number' && this.inputMin > outputNumber) {
                this.stringValue = this.lastValue;
                return;
            }
            if (evt.target.value != '-')
                this.$emit('input', outputNumber);
            this.lastValue = this.stringValue;
        }
        else {
            this.stringValue = this.lastValue;
        }
    }
    changeValueByStep(evt) {
        evt.preventDefault();
        let step = 1;
        let targetValue = evt.target.value;
        step = this.inputStep ? this.inputStep : step;
        if (evt.keyCode === 40) {
            step = (-1) * step;
        }
        targetValue = Number(targetValue.replace(',', '.'));
        targetValue = Math.round((targetValue + step) * 1e12) / 1e12;
        evt.target.value = !this.decimalPointDot ? targetValue.toString().replace('.', ',') : targetValue.toString().replace(',', '.');
        this.inputChange(evt);
    }
    focusInput() {
        this.$refs[this.inputRef].focus();
    }
    onKeyEnter(evt) {
        if (this.stringValue == '-' && this.value)
            this.$emit('input', null);
        setTimeout(() => {
            this.$emit('keyupenter', evt);
        }, 0);
    }
    onBlur(evt) {
        if (this.stringValue == '-' && this.value)
            this.$emit('input', null);
        setTimeout(() => {
            this.$emit('blur', evt);
        }, 0);
    }
};
__decorate([
    Watch('value')
], InputNumber.prototype, "onValueChange", null);
__decorate([
    Watch('onlyInteger')
], InputNumber.prototype, "onOnlyIntegerChange", null);
InputNumber = __decorate([
    Component({
        props: {
            value: Number,
            decimalFormat: Number,
            onlyInteger: Boolean,
            inputRef: String,
            inputStep: Number,
            decimalPointDot: Boolean,
            inputMax: Number,
            inputMin: Number,
            inputClass: String,
            inputDisabled: Boolean,
            inputTabindex: String,
            selectOnFocus: Boolean,
            inputUnit: String,
        }
    })
], InputNumber);
export default InputNumber;
