var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "shop-productlist-thumbs shop-products shop-search-result-gallery",
    },
    [
      _c("div", { staticClass: "ui-modal-content" }, [
        _c("h3", { staticClass: "ui-modal-header" }, [
          _vm._v(
            _vm._s(_vm._f("translate")("webshop_ipaper_modalAddToCart_title"))
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _vm.loading
              ? [_c("loader", { attrs: { size: "large" } })]
              : _vm.product && _vm.product.productId
              ? [
                  _c(
                    "div",
                    {
                      staticClass: "shop-gallery-item-wrapper",
                      attrs: { column: "xs-12" },
                    },
                    [
                      _c("shop-gallery-item", {
                        attrs: {
                          loading: _vm.loading,
                          product: _vm.product,
                          uiSource: _vm.OrderlineUISourceTypes.Ipaper,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              : [
                  _c("div", { attrs: { column: "xs-12" } }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("translate")(
                          "webshop_ipaper_modalAddToCart_noItem"
                        )
                      )
                    ),
                  ]),
                ],
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }