var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "div-table" },
      [
        _c("div", { staticClass: "table-header" }, [
          _c("div", { staticClass: "table-row" }, [
            _c(
              "div",
              {
                staticClass: "table-column",
                staticStyle: { "max-width": "60px" },
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_inventory_table_headers_productNo"
                      )
                    )
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "table-column" }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("translate")(
                      "webshop_inventory_table_headers_product"
                    )
                  )
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "table-column align-right right-neg-15" },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_inventory_table_headers_quantity"
                      )
                    )
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "table-column align-right" }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("translate")("webshop_inventory_table_headers_unit")
                  )
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "table-column align-right" }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("translate")("webshop_inventory_table_headers_price")
                  )
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "table-column i-line-sorting head-col" }, [
              _vm._v(
                _vm._s(
                  _vm._f("translate")("webshop_inventory_table_headers_sorting")
                )
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "table-column i-line-settings" }),
          ]),
        ]),
        _vm._v(" "),
        _vm.list.groups.length || _vm.list.linesNotInGroup.length
          ? [
              _vm._l(_vm.list.groups, function (group) {
                return _c("inventory-table-group", {
                  key: group.id,
                  attrs: {
                    group: group,
                    list: _vm.list,
                    loading: _vm.loading,
                    setLoading: _vm.setLoading,
                  },
                })
              }),
              _vm._v(" "),
              _vm.list.linesNotInGroup.length
                ? _c("inventory-table-group", {
                    attrs: {
                      group: _vm.groupLess,
                      list: _vm.list,
                      loading: _vm.loading,
                      setLoading: _vm.setLoading,
                    },
                  })
                : _vm._e(),
            ]
          : [
              _c("div", { staticClass: "table-header" }, [
                _c("div", { staticClass: "table-row no-products" }, [
                  _c("div", { staticClass: "table-column" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("translate")(
                          "webshop_inventory_table_noProducts"
                        )
                      )
                    ),
                  ]),
                ]),
              ]),
            ],
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "ui-button ui-button-small",
        staticStyle: { "margin-top": "0.5rem" },
        on: {
          click: function ($event) {
            return _vm.openAddProduct()
          },
        },
      },
      [
        _c("i", { staticClass: "mdi mdi-plus" }),
        _vm._v(
          " " +
            _vm._s(
              _vm._f("translate")("webshop_inventory_header_addProductsBtn")
            ) +
            "\n    "
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }