var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
const validators = {
    email: new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
    url: new RegExp(/^(https?|ftp|rmtp|mms):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)(:(\d+))?\/?/i),
    text: new RegExp(/^[a-zA-Z]+$/),
    digits: new RegExp(/^[\d() \.\:\-\+#]+$/),
    isodate: new RegExp(/^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/)
};
class Tag {
    id;
    value;
    constructor(id) {
        this.id = id;
    }
}
let CustomTag = class CustomTag extends Vue {
    value;
    placeholder;
    validate;
    addTagOnBlur;
    readOnly;
    allowDuplicates;
    limit;
    addTagOnKeys = [
        13,
        188,
        9 // Tab
    ];
    innerTags = [];
    newTag = '';
    isInputActive = false;
    get isLimit() {
        return this.limit > 0 && Number(this.limit) === this.innerTags.length;
    }
    onValueChanged(val, oldVal) {
        this.innerTags = val;
    }
    focusNewTag() {
        if (this.readOnly || !this.$el.querySelector(".new-tag")) {
            return;
        }
        this.$el.querySelector(".new-tag").focus();
    }
    handleInputFocus() {
        this.isInputActive = true;
    }
    handleInputBlur(e) {
        this.isInputActive = false;
        this.addNew(e);
    }
    async addNew(e) {
        const keyShouldAddTag = e ? this.addTagOnKeys.indexOf(e.keyCode) !== -1 : true;
        const typeIsNotBlur = e && e.type !== "blur";
        if ((!keyShouldAddTag && (typeIsNotBlur || !this.addTagOnBlur)) || this.isLimit) {
            return;
        }
        const tag = this.newTag;
        const isValid = await this.validateIfNeeded(tag);
        if (tag && isValid && (this.allowDuplicates || this.innerTags.indexOf(tag) === -1)) {
            this.innerTags.push(tag);
            this.newTag = "";
            this.tagChange();
            e && e.preventDefault();
        }
    }
    validateIfNeeded(tagValue) {
        if (this.validate === "" || this.validate === undefined) {
            return true;
        }
        if (typeof this.validate === "function") {
            return this.validate(tagValue);
        }
        if (typeof this.validate === "string" && Object.keys(validators).indexOf(this.validate) > -1) {
            return validators[this.validate].test(tagValue);
        }
        if (typeof this.validate === "object" && this.validate.test !== undefined) {
            return this.validate.test(tagValue);
        }
        return true;
    }
    remove(index) {
        this.innerTags.splice(index, 1);
        this.tagChange();
    }
    removeLastTag() {
        if (this.newTag) {
            return;
        }
        this.innerTags.pop();
        this.tagChange();
    }
    tagChange() {
        this.$emit("update:tags", this.innerTags);
        this.$emit("input", this.innerTags);
    }
};
__decorate([
    Watch('value')
], CustomTag.prototype, "onValueChanged", null);
CustomTag = __decorate([
    Component({
        props: {
            value: {
                type: Array,
                default: []
            },
            placeholder: {
                type: String,
                default: ""
            },
            validate: {
                //type: Any,
                default: ""
            },
            addTagOnBlur: {
                type: Boolean,
                default: false
            },
            readOnly: {
                type: Boolean,
                default: false
            },
            allowDuplicates: {
                type: Boolean,
                default: false
            },
            limit: {
                type: Number,
                default: -1
            }
        }
    })
], CustomTag);
export default CustomTag;
