export class CampaignPlanningLineVM {
    campaignCode;
    vendorNo;
    vendorItemNo;
    newVendorItemNo;
    itemNo;
    description;
    unit;
    campaignUnitCost;
    campaignDiscountPct;
    campaignDiscountLcy;
    campaignUnitPrice;
    campaignPrice;
    status;
    purchaseStartingDate;
    purchaseEndingDate;
    salesStartingDate;
    salesEndingDate;
    waitingFor;
    unitCost;
    constructor() {
        this.campaignCode = '';
        this.vendorNo = '';
        this.vendorItemNo = '';
        this.newVendorItemNo = '';
        this.itemNo = '';
        this.description = '';
        this.unit = '';
        this.campaignUnitCost = null;
        this.campaignDiscountPct = 0;
        this.campaignDiscountLcy = 0;
        this.campaignUnitPrice = null;
        this.campaignPrice = null;
        this.unitCost = null;
    }
    static fromApi(item) {
        return Object.assign(new CampaignPlanningLineVM(), item);
    }
}
