var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shop-expiry-products" },
    [
      _vm.results && _vm.results.length > 0
        ? _c(
            "div",
            {
              staticClass: "shop-productSearch-field",
              staticStyle: { overflow: "visible" },
            },
            [
              _c(
                "label",
                { staticClass: "shop-productSearch-searchTextWrapper" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "search-btn",
                      attrs: {
                        title: _vm.$options.filters.translate(
                          "webshop_search_search_options_buttonSearch"
                        ),
                      },
                    },
                    [_c("i", { staticClass: "mdi mdi-magnify" })]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filter,
                        expression: "filter",
                      },
                    ],
                    staticClass: "shop-productSearch-searchText",
                    attrs: {
                      type: "text",
                      autocomplete: "off",
                      placeholder: _vm.$options.filters.translate(
                        "webshop_expiry_searchPlaceholder"
                      ),
                    },
                    domProps: { value: _vm.filter },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.filter = $event.target.value
                      },
                    },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.results && _vm.results.length > 0
        ? _c("div", { staticClass: "shop-sort-by-select" }, [
            _c("div", { staticClass: "sort-by-wrapper" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedSortBy,
                      expression: "selectedSortBy",
                    },
                  ],
                  staticClass: "sort-by",
                  attrs: { disabled: _vm.loading },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedSortBy = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.sortByFilters, function (sortBy) {
                  return _c(
                    "option",
                    { key: sortBy.value, domProps: { value: sortBy.value } },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(sortBy.key) +
                          "\n                "
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "div",
            {
              staticClass:
                "shop-productlist-thumbs shop-products shop-search-result-gallery",
            },
            [
              _vm.results && _vm.results.length > 0
                ? [
                    _vm.filteredList.length > 0
                      ? [
                          _c(
                            "div",
                            { attrs: { grid: "wrap" } },
                            _vm._l(_vm.filteredList, function (product, index) {
                              return _c(
                                "div",
                                {
                                  key:
                                    "expiry" +
                                    product.productId +
                                    product.expiry.reference,
                                  staticClass: "shop-gallery-item-wrapper",
                                  attrs: { column: "s-12 m-4 l-3" },
                                },
                                [
                                  _c("shop-gallery-item", {
                                    attrs: {
                                      loading: _vm.loading,
                                      product: product,
                                      uiSource:
                                        _vm.OrderlineUISourceTypes.ExpiryList,
                                      position: index + 1,
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]
                      : _c("div", { staticClass: "no-result" }, [
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")("webshop_expiry_noResult")
                              )
                            ),
                          ]),
                        ]),
                  ]
                : _c("div", { staticClass: "no-result text-center" }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm._f("translate")("webshop_expiry_noProducts"))
                      ),
                    ]),
                  ]),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.itemsWithAmount && _vm.itemsWithAmount.length > 0
        ? _c("shop-multiple-products-footer", {
            attrs: { itemsWithAmount: _vm.itemsWithAmount },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }