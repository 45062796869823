var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { ToastService } from "services/ToastService";
import { InventoryService } from "services/InventoryService";
import { EventTypes } from "models/EventTypes";
import { ModalSettings } from "models/utility/ModalSettings";
import { WindowService } from "services/WindowService";
import { PdfReportService } from "services/PdfReportService";
import { PermissionService } from "services/PermissionService";
import { PermissionLevel } from "models/user/PermissionLevel";
import IconTrash from 'components/shared/IconTrash.vue';
let InventoryHeader = class InventoryHeader extends Vue {
    loading;
    setLoading;
    list;
    newName = "";
    newGroupName = "";
    showPrice = PermissionService.hasPermission(PermissionLevel.ShowPrice);
    settings = {
        showSettings: false,
        editable: false,
        showDeleteConfirm: false,
        showNewGroup: false,
        filter: ""
    };
    get totalPrice() {
        return InventoryService.calTotalPrice(this.list);
    }
    get lineCount() {
        var totalLines = 0;
        totalLines += this.list.groups.reduce((sum, group) => sum + group.lines.length, 0);
        totalLines += this.list.linesNotInGroup.length;
        return totalLines;
    }
    created() {
        this.$root.$on(EventTypes.InventoryRouteChanged, this.resetFilter);
    }
    beforeDestroy() {
        this.$root.$off(EventTypes.InventoryRouteChanged, this.resetFilter);
        document.body.removeEventListener("click", this.clickOutsideSettings);
    }
    saveList() {
        if (this.newName.length > 1) {
            this.setLoading(true);
            InventoryService.updateList(this.list.id, this.newName)
                .then(newList => {
                this.list.name = newList.name;
                this.list.updated = newList.updated;
                this.settings.editable = false;
                this.setLoading(false);
                this.$root.$emit(EventTypes.InventoryListUpdated, this.list);
                ToastService.success(Vue.filter("translate")("webshop_inventory_header_toastListNameUpdated"));
            })
                .catch(err => {
                this.setLoading(false);
                if (err === 409) {
                    ToastService.danger(Vue.filter("translate")("webshop_inventory_header_toastListNameUpdated_errorNameExist", "<b>" + this.newName + "</b>"));
                }
                else {
                    ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
                }
            });
        }
        else {
            ToastService.danger(Vue.filter("translate")("webshop_inventory_header_toastNoNameAdded"));
        }
    }
    openAddProduct() {
        this.$root.$emit(EventTypes.InventoryModalProductOpen, this.list);
    }
    print(countingsheet) {
        // Needed to trick the popup blocker to not block the window
        const newWindow = WindowService.initializeNewWindow();
        PdfReportService.getInventoryList(this.list.id, countingsheet).then(res => {
            const file = new Blob([res], { type: "application/pdf" });
            const nav = window.navigator;
            if (window.navigator && nav.msSaveOrOpenBlob) {
                nav.msSaveOrOpenBlob(file, 'inventeringslista.pdf');
                setTimeout(() => {
                    newWindow.close();
                }, 100);
            }
            else {
                const reportLink = window.URL.createObjectURL(file);
                newWindow.location = reportLink;
            }
            this.loading = false;
        }, err => {
            setTimeout(() => {
                newWindow.close();
                if (err === 416) {
                    ToastService.danger(Vue.filter("translate")("webshop_inventory_no_products"));
                }
                else {
                    ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
                }
            }, 500);
            this.loading = false;
            return;
        });
        this.toggleSettings();
    }
    excel() {
        let excelUrl = InventoryService.getUrlForExcel(this.list.id);
        this.toggleSettings();
        const nav = window.navigator;
        const timeout = nav.msSaveBlob ? 200 : 0; //Fix settings close animation for IE
        setTimeout(() => {
            var anchor = document.createElement("a");
            anchor.style.display = "none";
            anchor.download = this.list.name + ".xlsx";
            anchor.href = excelUrl;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
        }, timeout);
    }
    toggleEditList() {
        this.toggleSettings();
        this.settings.editable = !this.settings.editable;
        if (this.settings.editable) {
            this.newName = this.list.name;
            this.settings.showSettings = false;
        }
    }
    clickedDeleteList() {
        if (this.settings.showDeleteConfirm) {
            this.setLoading(true);
            InventoryService.deleteList(this.list.id)
                .then(() => {
                this.setLoading(false);
                this.$root.$emit(EventTypes.InventoryListDeleted, this.list);
            })
                .catch(err => {
                this.setLoading(false);
                ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
            });
        }
        else {
            this.settings.showDeleteConfirm = true;
        }
    }
    updatePricesOnList() {
        this.setLoading(true);
        InventoryService.updatePricesOnList(this.list.id).then((res) => {
            this.setLoading(false);
            ToastService.success(Vue.filter("translate")("webshop_inventory_header_settings_prices_update_updated", res.updatedLines));
            this.$root.$emit(EventTypes.InventoryReloadList, this.list.id);
        }).catch(err => {
            this.setLoading(false);
            ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
        });
    }
    toggleSettings() {
        this.settings.showSettings = !this.settings.showSettings;
        this.settings.showDeleteConfirm = false;
        if (this.settings.showSettings) {
            document.body.addEventListener("click", this.clickOutsideSettings);
        }
        else {
            document.body.removeEventListener("click", this.clickOutsideSettings);
        }
    }
    openSendEmail() {
        this.toggleSettings();
        this.$root.$emit(EventTypes.InventoryModalEmailOpen, this.list);
    }
    openResetAmountModal() {
        this.toggleSettings();
        this.$root.$emit(EventTypes.InventoryModalConfirmOpen, new ModalSettings({
            title: Vue.filter("translate")("webshop_inventory_header_resetModal_title"),
            content: "<p>" +
                Vue.filter("translate")("webshop_inventory_header_resetModal_description") +
                "</p>",
            cancelButtonText: Vue.filter("translate")("webshop_inventory_header_resetModal_cancelBtn"),
            acceptButtonText: Vue.filter("translate")("webshop_inventory_header_resetModal_resetBtn"),
            acceptButtonClass: "ui-button-cancel",
            onAccept: () => {
                return InventoryService.resetAmounts(this.list.id)
                    .then(() => {
                    ToastService.success(Vue.filter("translate")("webshop_inventory_header_resetModal_toastResetSuccess"));
                    this.$root.$emit(EventTypes.InventoryReloadList, this.list.id);
                    this.$root.$emit(EventTypes.InventoryModalConfirmClose);
                })
                    .catch(err => {
                    ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
                });
            }
        }));
    }
    changeFilter() {
        this.$root.$emit(EventTypes.InventorySearchChanged, this.settings.filter);
    }
    resetFilter() {
        this.settings.filter = "";
        this.changeFilter();
    }
    toggleNewGroup() {
        this.newGroupName = "";
        this.settings.showNewGroup = !this.settings.showNewGroup;
    }
    createNewGroup() {
        if (this.newGroupName.length > 1) {
            this.setLoading(true);
            InventoryService.createGroup(this.list.id, this.newGroupName)
                .then(group => {
                this.list.groups.push(group);
                this.toggleNewGroup();
                this.setLoading(false);
                ToastService.success(Vue.filter("translate")("webshop_inventory_header_group_toastSuccess"));
            })
                .catch(err => {
                this.setLoading(false);
                if (err === 409) {
                    ToastService.danger(Vue.filter("translate")("webshop_inventory_header_group_groupExist", "<b>" + this.newGroupName + "</b>"));
                }
                else {
                    ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
                }
            });
        }
        else {
            ToastService.danger(Vue.filter("translate")("webshop_inventory_header_group_noName"));
        }
    }
    clickOutsideSettings(el) {
        const wrapper = this.$refs["inventory-settings-container"];
        if (!wrapper || wrapper.contains(el.target))
            return;
        this.toggleSettings();
    }
    lineSortModal() {
        let listCopy = JSON.parse(JSON.stringify(this.list));
        this.$root.$emit(EventTypes.InventoryModalProductSortOpen, listCopy);
    }
    createFavoriteList() {
        this.setLoading(true);
        InventoryService.createFavoriteList(this.list.id)
            .then(() => {
            this.setLoading(false);
            ToastService.success(Vue.filter("translate")("webshop_inventory_header_toastFavoriteListCreated"));
        })
            .catch(err => {
            this.setLoading(false);
            ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
        });
    }
};
InventoryHeader = __decorate([
    Component({
        components: {
            IconTrash
        },
        props: {
            list: Object,
            loading: Boolean,
            setLoading: Function
        }
    })
], InventoryHeader);
export default InventoryHeader;
