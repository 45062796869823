var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table-outer-row" }, [
    _c("div", { staticClass: "table-row wrap" }, [
      _c("div", { staticClass: "table-column description-column" }, [
        _c("span", { staticClass: "small-caption" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.depositProduct.product.productId) +
              "\n            "
          ),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "productTitle" }, [
          _c("b", [_vm._v(_vm._s(_vm.depositProduct.product.description))]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "break-medium" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-column column-group price-column-group" },
        [
          _vm.showPrice
            ? _c("div", { staticClass: "table-column price-column" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm._f("currency")(_vm.price)) +
                    "\n            "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "table-column quantity-column" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.quantity,
                  expression: "quantity",
                },
              ],
              staticClass: "shop-input",
              attrs: {
                type: "number",
                name: "quantity",
                autocomplete: "off",
                min: "1",
              },
              domProps: { value: _vm.quantity },
              on: {
                change: _vm.changeQuantity,
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.quantity = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "table-column unit-column" }, [
            _vm._v("\n                " + _vm._s(_vm.unit) + "\n            "),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }