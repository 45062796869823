var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { ToastService } from "services/ToastService";
import { EventTypes } from "../../models/EventTypes";
import { ProductStatisticsFilter } from "models/statistics/ProductStatisticsFilter";
import LoadingButton from '../shared/LoadingButton.vue';
let StatisticsFilter = class StatisticsFilter extends Vue {
    loading = false;
    productStatisticsFilter = new ProductStatisticsFilter();
    dateSettings = {
        enable: [{ from: new Date(1957, 0, 1), to: new Date() }]
    };
    getStatistics() {
        if (this.productStatisticsFilter.fromDate > this.productStatisticsFilter.toDate) {
            ToastService.danger(Vue.filter("translate")("webshop_statistics_filter_products_submit_wrongDates"));
        }
        else {
            this.$root.$emit(EventTypes.ProductStatisticsLoad, this.productStatisticsFilter);
        }
    }
    ;
};
StatisticsFilter = __decorate([
    Component({
        components: {
            LoadingButton
        },
        props: {
            loading: Boolean
        }
    })
], StatisticsFilter);
export default StatisticsFilter;
