import { render, staticRenderFns } from "./NavLink.vue?vue&type=template&id=015d1c78&"
import script from "./NavLink.vue?vue&type=script&lang=ts&"
export * from "./NavLink.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Projekt\\webbshop\\SvenskCater\\SvenskCater\\app\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('015d1c78')) {
      api.createRecord('015d1c78', component.options)
    } else {
      api.reload('015d1c78', component.options)
    }
    module.hot.accept("./NavLink.vue?vue&type=template&id=015d1c78&", function () {
      api.rerender('015d1c78', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/header/main-navigation/NavLink.vue"
export default component.exports