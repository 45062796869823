var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { EventTypes } from 'models/EventTypes';
import { ModalService } from 'modules/basic-core/basic-modal/src';
import ConfirmModal from 'components/shared/ConfirmModal.vue';
import { SearchService } from 'services/SearchService';
let HeaderLoggedIn = class HeaderLoggedIn extends Vue {
    deliveryDate;
    confirmedLogout = false;
    get isValidDate() {
        return new Date(this.deliveryDate) > new Date(1, 1, 1);
    }
    changeDeliveryDate() {
        this.$root.$emit(EventTypes.DeliveryDateModalOpen);
    }
    logout(evt) {
        //this.$root.$emit(EventTypes.LoginModalOpen);
        if (!this.confirmedLogout && SearchService.itemsWithAmount.length > 0) {
            evt.stopPropagation();
            ModalService.create({
                cxt: this,
                component: ConfirmModal,
                maxWidth: "500px",
                data: {
                    title: this.$options.filters.translate("webshop_layout_header_logout"),
                    message: this.$options.filters.translate("webshop_layout_header_logout_notSavedProductsMessage"),
                    confirmFunction: this.logoutConfirm
                }
            });
        }
    }
    logoutConfirm() {
        this.confirmedLogout = true;
        const logoutAnchor = this.$refs['shop-logout'];
        logoutAnchor.click();
    }
};
HeaderLoggedIn = __decorate([
    Component({
        props: {
            deliveryDate: String
        }
    })
], HeaderLoggedIn);
export default HeaderLoggedIn;
