var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { EventTypes } from "models/EventTypes";
import { ToastService } from "services/ToastService";
import { PermissionService } from "services/PermissionService";
import { PermissionLevel } from "models/user/PermissionLevel";
import { CalculationService } from "services/CalculationService";
import ShopItemIcons from "components/shop/partials/ShopItemIcons.vue";
import { PriceType } from "models/product/PriceType";
import HighlightMarkings from "components/shared/HighlightMarkings.vue";
class SelectLine {
    product;
    selected;
    standardUnit;
    constructor(product, selected, selectedUnit) {
        this.product = product;
        this.selected = selected;
        this.standardUnit = selectedUnit;
    }
}
let CalculationModalProductResult = class CalculationModalProductResult extends Vue {
    list;
    loading;
    setLoading;
    result;
    showSearchFilter;
    isAllSelected = false;
    lines = [];
    selectedCount = 0;
    showPrice = PermissionService.hasPermission(PermissionLevel.ShowPrice);
    priceType = PriceType;
    filteredLines = [];
    searchTimeoutId;
    mounted() {
        this.lines = this.result.map(x => {
            let unit = x.units.find(y => y.name == x.salesUnit);
            return new SelectLine(x, false, unit);
        });
        this.filteredLines = this.lines;
    }
    close() {
        this.$emit("close");
    }
    toggleSelection(line) {
        line.selected = !line.selected;
        if (!line.selected) {
            this.isAllSelected = false;
            this.selectedCount--;
        }
        else {
            this.selectedCount++;
            this.isAllSelected = this.filteredLines.length === this.filteredLines.filter(x => x.selected).length;
        }
    }
    toggleAllLines() {
        this.isAllSelected = !this.isAllSelected;
        this.filteredLines.forEach(line => {
            if (this.isAllSelected != line.selected) {
                line.selected = this.isAllSelected;
                this.selectedCount += this.isAllSelected ? 1 : -1;
            }
        });
    }
    addProducts() {
        this.setLoading(true);
        let products = [];
        for (let i = 0; i < this.lines.length; i++) {
            const line = this.lines[i];
            if (line.selected) {
                products.push(line.product.productId);
            }
        }
        CalculationService.addProductLines(this.list.calculationId, products).then((calculation) => {
            var newLines = calculation.calculationLines.length - this.list.calculationLines.length;
            this.$store.dispatch('Calculations/createCalculationLines', calculation);
            if (newLines == 1) {
                ToastService.success(Vue.filter('translate')('webshop_calculations_modals_addProducts_toasts_addedOne'));
            }
            else if (newLines > 1) {
                ToastService.success(Vue.filter('translate')('webshop_calculations_modals_addProducts_toasts_addedMore', newLines));
            }
            else {
                ToastService.danger(Vue.filter('translate')('webshop_calculations_modals_addProducts_toasts_noNewItems'));
            }
            this.$root.$emit(EventTypes.ShopSearchRefresh);
            this.close();
        }).catch(err => {
            this.setLoading(false);
            ToastService.danger(Vue.filter('translate')('webshop_defaults_toast_errorText'));
        });
    }
    priceTypeCampaign(line) {
        return line.units != null && line.units.find(x => x.priceType == this.priceType.CampaignPrice) != undefined;
    }
    clickItemIcons(evt) {
        evt.stopPropagation();
    }
    searchFilter(evt) {
        if (this.searchTimeoutId)
            clearTimeout(this.searchTimeoutId);
        this.searchTimeoutId = setTimeout(() => {
            if (evt.srcElement.value && evt.srcElement.value.length > 1)
                this.filteredLines = this.lines.filter(x => x.product.description.toLocaleLowerCase().search(evt.srcElement.value.toLowerCase()) >= 0);
            else
                this.filteredLines = this.lines;
            this.isAllSelected = this.filteredLines.length == this.filteredLines.filter(x => x.selected).length;
        }, 700);
    }
};
CalculationModalProductResult = __decorate([
    Component({
        components: {
            ShopItemIcons,
            HighlightMarkings
        },
        props: {
            list: Object,
            loading: Boolean,
            setLoading: Function,
            result: Array,
            showSearchFilter: Boolean,
        }
    })
], CalculationModalProductResult);
export default CalculationModalProductResult;
