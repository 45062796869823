export class CalculationLineVM {
    calculationLineId;
    name;
    calculationId;
    id;
    isItem;
    quantity;
    percentageWasted;
    price;
    calculationType;
    itemStatus;
    supplierProductId;
    itemBrand;
    salesUnit;
    salesUnitName;
    itemDeleted;
    itemNetWeight;
    markings;
    units;
    isCalculationAccessible;
    created;
    isCustomerItem;
    editedPrice;
    updated;
    static fromApi(item) {
        return Object.assign(new CalculationLineVM(), item);
    }
}
