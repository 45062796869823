export var OrderlineUISourceTypes;
(function (OrderlineUISourceTypes) {
    OrderlineUISourceTypes["ShopGallery"] = "sGallery";
    OrderlineUISourceTypes["ShopGalleryPopular"] = "sGalleryP";
    OrderlineUISourceTypes["ShopList"] = "sList";
    OrderlineUISourceTypes["ShopListPopular"] = "sListP";
    OrderlineUISourceTypes["ShopProductInfo"] = "sProductI";
    OrderlineUISourceTypes["CalculationList"] = "cList";
    OrderlineUISourceTypes["OrderHistoryList"] = "ohList";
    OrderlineUISourceTypes["BasketAlsoBoughtList"] = "babList";
    OrderlineUISourceTypes["BasketCheckOutList"] = "bcoList";
    OrderlineUISourceTypes["ExpiryList"] = "eList";
    OrderlineUISourceTypes["Ipaper"] = "ipaper";
    OrderlineUISourceTypes["Replacement"] = "replace";
    OrderlineUISourceTypes["DepictDefault"] = "depict";
})(OrderlineUISourceTypes || (OrderlineUISourceTypes = {}));
