export class ModalSettings {
    title = '';
    content = '';
    cancelButtonText = 'Annuller';
    acceptButtonText = 'Gem';
    acceptButtonClass = '';
    //onClose: Function;
    onAccept;
    constructor(options) {
        if (options.title)
            this.title = options.title;
        if (options.content)
            this.content = options.content;
        if (options.cancelButtonText)
            this.cancelButtonText = options.cancelButtonText;
        if (options.acceptButtonText)
            this.acceptButtonText = options.acceptButtonText;
        if (options.acceptButtonClass)
            this.acceptButtonClass = options.acceptButtonClass;
        if (options.onAccept)
            this.onAccept = options.onAccept;
    }
}
