var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.changeCalculationName()
        },
      },
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.calculationObject.name,
            expression: "calculationObject.name",
          },
        ],
        staticClass: "header-medium",
        attrs: {
          type: "text",
          required: "",
          maxlength: "35",
          disabled: _vm.loading,
        },
        domProps: { value: _vm.calculationObject.name },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.calculationObject, "name", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _c("loading-button", {
        attrs: {
          "icon-class": "mdi-pencil",
          text: _vm.$options.filters.translate(
            "webshop_calculations_calculationHeader_buttons_changeName"
          ),
          "loading-text": _vm.$options.filters.translate(
            "webshop_calculations_calculationHeader_buttons_changeName"
          ),
          loading: _vm.loading,
        },
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass:
            "ui-button ui-button-flat ui-button-small cancel capitalize",
          attrs: { type: "button", disabled: _vm.loading },
          on: {
            click: function ($event) {
              return _vm.cancel()
            },
          },
        },
        [
          _vm._v(
            _vm._s(
              _vm._f("translate")(
                "webshop_calculations_calculationHeader_buttons_cancel"
              )
            )
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }