var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ui-modal-content" }, [
      _c("h3", { staticClass: "ui-modal-header" }, [
        _vm._v(
          _vm._s(_vm._f("translate")("webshop_inventory_print_modal_header"))
        ),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("ul", { staticClass: "inventory-lists" }, [
          !_vm.lists || !_vm.lists.length > 0
            ? _c("li", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      padding: "1rem .75rem 1rem 14px",
                      display: "block",
                      width: "100%",
                    },
                  },
                  [
                    _c("i", [
                      _vm._v(
                        _vm._s(
                          _vm._f("translate")("webshop_inventory_lists_noLists")
                        )
                      ),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ul-scroll" },
            _vm._l(_vm.lists, function (list) {
              return _c(
                "li",
                {
                  key: list.id,
                  class: { selected: _vm.listSelected(list) },
                  on: {
                    click: function ($event) {
                      return _vm.toggleListSelect(list)
                    },
                  },
                },
                [
                  _c("span", { staticStyle: { width: "unset" } }, [
                    _vm._v(_vm._s(list.name)),
                    list.autoList
                      ? _c("i", {
                          staticClass: "mdi mdi-auto-fix left-10",
                          attrs: {
                            title: _vm.$options.filters.translate(
                              "webshop_inventory_autolager_iconTitle"
                            ),
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm.listSelected(list)
                    ? _c("span", { staticClass: "icon-checkmark" })
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _vm.loading
            ? _c(
                "li",
                { staticClass: "inventory-lists-loader simple-loader-ui" },
                [_c("div", { staticClass: "simple-loader dark" })]
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ui-modal-footer" }, [
        _vm.selectedLists.length > 0
          ? _c("span", [
              _vm._v(
                _vm._s(
                  _vm._f("translate")(
                    "webshop_inventory_print_modal_amount_selected",
                    _vm.selectedLists.length
                  )
                )
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "ui-modal-confirm-wrapper" }, [
          _c(
            "a",
            {
              staticClass: "ui-button ui-button-success",
              attrs: { download: "inventeringslistor.pdf" },
              on: {
                click: function ($event) {
                  return _vm.print()
                },
              },
            },
            [
              _vm._v(
                _vm._s(
                  _vm._f("translate")("webshop_inventory_print_modal_print")
                )
              ),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }