import { Service } from '../../../basic-service/src';
export class LocalStorageService extends Service {
    static available = false;
    static initialize() {
        if (typeof localStorage !== 'undefined') {
            try {
                localStorage.setItem('feature_test', 'yes');
                if (localStorage.getItem('feature_test') === 'yes') {
                    localStorage.removeItem('feature_test');
                    this.available = true;
                }
            }
            catch (e) { }
        }
    }
    static itemExists(key) {
        return localStorage.getItem(key) != null;
    }
    static getItem(key) {
        return new Promise((resolve, reject) => {
            if (this.itemExists(key)) {
                resolve(JSON.parse(localStorage.getItem(key)));
            }
            else {
                reject(null);
            }
        });
    }
    static setItem(key, item) {
        return new Promise((resolve, reject) => {
            resolve(localStorage.setItem(key, JSON.stringify(item)));
        });
    }
    static removeItem(key) {
        return new Promise((resolve, reject) => {
            resolve(localStorage.removeItem(key));
        });
    }
}
