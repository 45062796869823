var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { grid: "wrap" } }, [
    _vm.descriptionTitle && _vm.descriptionText
      ? _c("div", { attrs: { column: "s-12 m-4 xl-7" } }, [
          _c("div", { staticClass: "ui-card ui-card-margin" }, [
            _c(
              "div",
              { staticClass: "ui-card-header ui-card-header-inverted" },
              [_vm._v(_vm._s(_vm.descriptionTitle))]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ui-card-content" }, [
              _c("p", { domProps: { innerHTML: _vm._s(_vm.descriptionText) } }),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { attrs: { column: "s-12 m-8 xl-5" } }, [
      _c("div", { staticClass: "ui-card" }, [
        _c(
          "div",
          {
            staticClass:
              "ui-card-header ui-card-header-inverted ui-card-inventory-list",
          },
          [
            _vm._v(
              "\r\n                " +
                _vm._s(_vm._f("translate")("webshop_inventory_lists_title")) +
                "\r\n                "
            ),
            _c(
              "div",
              { staticClass: "lists-btns-wrapper inventory-list-header" },
              [
                _c(
                  "a",
                  {
                    staticClass: "ui-button ui-button-flat ui-button-small",
                    on: {
                      click: function ($event) {
                        return _vm.openAutolagerModal()
                      },
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "mdi mdi-auto-fix settings-icon",
                    }),
                    _vm._v(
                      "\r\n                        " +
                        _vm._s(
                          _vm._f("translate")(
                            "webshop_inventory_lists_btnAutolager"
                          )
                        ) +
                        "\r\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    ref: "inventory-settings-container",
                    staticClass: "inventory-settings-container",
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "ui-button ui-button-flat ui-button-small inventory-settings-toggler",
                        class: { cancel: _vm.showSettings },
                        on: {
                          click: function ($event) {
                            return _vm.toggleSettings()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "mdi mdi-dots-vertical" }),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "mdi mdi-close-circle-outline",
                        }),
                        _vm._v(
                          "\r\n                            " +
                            _vm._s(
                              _vm._f("translate")(
                                "webshop_inventory_header_settings_btn"
                              )
                            ) +
                            "\r\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.showSettings
                      ? _c("ul", { staticClass: "inventory-settings" }, [
                          _c("li", [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "ui-button ui-button-flat ui-button-small ui-button-transparent",
                                on: {
                                  click: function ($event) {
                                    _vm.print(false)
                                    _vm.toggleSettings()
                                  },
                                },
                              },
                              [
                                _c("span", {
                                  staticClass: "mdi mdi-printer settings-icon",
                                }),
                                _vm._v(
                                  "\r\n                                    " +
                                    _vm._s(
                                      _vm._f("translate")(
                                        "webshop_inventory_lists_print"
                                      )
                                    ) +
                                    "\r\n                                "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "ui-button ui-button-flat ui-button-small ui-button-transparent",
                                on: {
                                  click: function ($event) {
                                    _vm.print(true)
                                    _vm.toggleSettings()
                                  },
                                },
                              },
                              [
                                _c("span", {
                                  staticClass: "mdi mdi-printer settings-icon",
                                }),
                                _vm._v(
                                  "\r\n                                    " +
                                    _vm._s(
                                      _vm._f("translate")(
                                        "webshop_inventory_lists_countingsheet"
                                      )
                                    ) +
                                    "\r\n                                "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "ui-button ui-button-flat ui-button-small ui-button-transparent",
                                on: {
                                  click: function ($event) {
                                    _vm.createArchive()
                                    _vm.toggleSettings()
                                  },
                                },
                              },
                              [
                                _c("span", {
                                  staticClass:
                                    "mdi mdi-content-save settings-icon",
                                }),
                                _vm._v(
                                  "\r\n                                    " +
                                    _vm._s(
                                      _vm._f("translate")(
                                        "webshop_inventory_lists_btnArchive"
                                      )
                                    ) +
                                    "\r\n                                "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "ui-button ui-button-flat ui-button-small ui-button-transparent",
                                attrs: {
                                  href: "../../webbshop/min-sida/inventeringar",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleSettings()
                                  },
                                },
                              },
                              [
                                _c("span", {
                                  staticClass:
                                    "mdi mdi-link-variant settings-icon",
                                }),
                                _vm._v(
                                  "\r\n                                    " +
                                    _vm._s(
                                      _vm._f("translate")(
                                        "webshop_inventory_lists_btnArchiveLink"
                                      )
                                    ) +
                                    "\r\n                                "
                                ),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ui-card-content" },
          [
            _c("inventory-picker", {
              attrs: { loading: _vm.loading, setLoading: _vm.setLoading },
            }),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { column: "" } },
      [
        _c("router-view", {
          staticClass: "inventory-scroll-point",
          attrs: { loading: _vm.loading, setLoading: _vm.setLoading },
        }),
        _vm._v(" "),
        _c("inventory-modals"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }