var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { ToastService } from 'services/ToastService';
import { EventTypes } from 'models/EventTypes';
import { BasketProduct } from 'models/order/BasketProduct';
import { DepositProductVM } from 'models/product/DepositProductVM';
import DepositProductLine from 'components/deposit-products/DepositProductLine.vue';
import { OrderService } from 'services/OrderService';
let DepositProductsModal = class DepositProductsModal extends Vue {
    loading = false;
    data;
    products = [];
    mounted() {
        if (this.data && this.data.length > 0) {
            this.products = this.data.map(x => new DepositProductVM(x, 0));
        }
    }
    onUpdateQuantity(product, quantity) {
        product.quantity = quantity;
    }
    addProducts() {
        let _basketProducts = [];
        for (var i = 0; i < this.products.length; i++) {
            let _depositProduct = this.products[i];
            if (_depositProduct.quantity && _depositProduct.quantity > 0) {
                let _basketProduct = new BasketProduct();
                _basketProduct.amount = _depositProduct.quantity;
                _basketProduct.productId = _depositProduct.product.productId;
                _basketProduct.unit = (_depositProduct.product.units.length ? _depositProduct.product.units[0].name : "");
                _basketProduct.depositLine = true;
                _basketProducts.push(_basketProduct);
            }
        }
        if (_basketProducts.length > 0) {
            OrderService.addProductsToBasket(_basketProducts).then(res => {
                ToastService.success(Vue.filter("translate")("webshop_deposit_modal_toastAddedProducts"));
                this.$root.$emit(EventTypes.BasketDepositProductAdded, _basketProducts);
                this.$root.$emit(EventTypes.BasketUpdate);
                this.$root.$emit(EventTypes.MiniCartUpdate, res.previewOrder);
                this.close();
            }, err => {
                ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
            });
        }
    }
    close() {
        this.$emit('close-modal');
    }
};
DepositProductsModal = __decorate([
    Component({
        components: {
            DepositProductLine
        },
        props: {
            data: Array
        }
    })
], DepositProductsModal);
export default DepositProductsModal;
