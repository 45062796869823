var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c(
        "div",
        {
          staticClass: "delivery-type-picker",
          attrs: { grid: "no-gutters justify-between" },
        },
        [
          _c("div", { attrs: { column: "xs-6" } }, [
            _c(
              "span",
              {
                class: { disabled: !_vm.hasDeliveryDates },
                on: {
                  click: function ($event) {
                    _vm.hasDeliveryDates ? _vm.setPickup(false) : null
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "mdi",
                  class: [
                    !_vm.pickup
                      ? "mdi-checkbox-marked-circle-outline"
                      : "mdi-checkbox-blank-circle-outline",
                  ],
                }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_modals_deliveryDate_toggleDelivery"
                      )
                    ) +
                    "\r\n        "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { attrs: { column: "xs-6" } }, [
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.setPickup(true)
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "mdi",
                  class: [
                    _vm.pickup
                      ? "mdi-checkbox-marked-circle-outline"
                      : "mdi-checkbox-blank-circle-outline",
                  ],
                }),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_modals_deliveryDate_togglePickup"
                      )
                    ) +
                    "\r\n        "
                ),
              ]
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }