var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "loader-wrapper", class: { visible: !_vm.loading } },
    [
      _c("transition", { attrs: { appear: "", name: "overlay", tag: "div" } }, [
        _c("div", { staticClass: "overlay" }),
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "loader", tag: "div" } }, [
        _c("div", { staticClass: "loader", class: { visible: !_vm.loading } }, [
          _c("div", { staticClass: "container position" }, [
            _c("div", { staticClass: "content" }, [
              _c("div", { class: _vm.computedIconClass }),
              _vm._v(" "),
              _c("span", {
                staticClass: "text",
                domProps: { innerHTML: _vm._s(_vm.computedContent) },
              }),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }