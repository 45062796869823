var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "ui-modal-content" }, [
      _c("h3", { staticClass: "ui-modal-header" }, [
        _vm._v(
          _vm._s(_vm._f("translate")("webshop_ipaper_modalAddToCart_title"))
        ),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", [
          _vm._v(
            "\n                " +
              _vm._s(
                _vm._f("translate")("webshop_ipaper_modalRedirectToLogin_text")
              ) +
              "\n            "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ui-form-submitwrapper" }, [
          _c(
            "button",
            {
              staticClass: "ui-button",
              on: {
                click: function ($event) {
                  return _vm.login()
                },
              },
            },
            [
              _vm._v(
                _vm._s(
                  _vm._f("translate")(
                    "webshop_ipaper_modalRedirectToLogin_btnLogin"
                  )
                )
              ),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }