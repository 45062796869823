var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { AuthService } from 'services/AuthService';
import { ToastService } from 'services/ToastService';
import LoadingButton from '../../shared/LoadingButton.vue';
let RequestUsernamePartial = class RequestUsernamePartial extends Vue {
    loading = false;
    submitted = false;
    email = null;
    goBack() {
        this.loading = false;
        this.submitted = false;
        this.email = null;
        this.$emit("goBack");
    }
    request() {
        if (!this.email) {
            ToastService.danger(Vue.filter('translate')('webshop_login_requestUsername_toast_emptyFields'));
            return;
        }
        this.loading = true;
        AuthService.requestUsername(this.email)
            .then(() => {
            this.submitted = true;
            this.loading = true;
        })
            .catch(err => {
            ToastService.danger(Vue.filter('translate')('webshop_defaults_toast_errorText'));
            this.loading = false;
        });
    }
};
RequestUsernamePartial = __decorate([
    Component({
        components: {
            LoadingButton
        }
    })
], RequestUsernamePartial);
export default RequestUsernamePartial;
