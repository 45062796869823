var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { SearchSortBy } from "models/search/SearchSortBy";
import { EventTypes } from "models/EventTypes";
import { SearchInput } from "models/search/SearchInput";
import { SearchService } from 'services/SearchService';
let ShopBreadcrumb = class ShopBreadcrumb extends Vue {
    searchMenu;
    filters;
    input;
    loading;
    isOpenShop = SearchService.isOpenShop;
    mounted() {
    }
    selectRoot() {
        let input = Object.assign(new SearchInput(), this.input);
        input.favoriteListId = null;
        input.department = null;
        input.categoryId = null;
        input.groupId = null;
        if (this.isTopp200) {
            input.searchQuery = '';
            if (input.sortBy === SearchSortBy.Relevance) {
                input.sortBy = SearchSortBy.Description;
            }
        }
        this.$root.$emit(EventTypes.ShopSearchStart, input);
    }
    get favoriteList() {
        if (this.input.favoriteListId && this.input.department) {
            let favList = this.searchMenu.favoriteLists.find((favoriteList) => {
                return favoriteList.id == this.input.favoriteListId && favoriteList.department == this.input.department;
            });
            favList = favList ? favList : this.searchMenu.sharedFavoriteLists.find((favoriteList) => {
                return favoriteList.id == this.input.favoriteListId && favoriteList.department == this.input.department;
            });
            return favList;
        }
        return null;
    }
    selectFavoriteListOrTopp200() {
        let input = Object.assign(new SearchInput(), this.input);
        input.categoryId = null;
        input.groupId = null;
        this.$root.$emit(EventTypes.ShopSearchStart, input);
    }
    get category() {
        if (this.input.categoryId) {
            return this.searchMenu.categories.find((category) => {
                return category.code == this.input.categoryId;
            });
        }
        return null;
    }
    selectCategory() {
        let input = Object.assign(new SearchInput(), this.input);
        input.groupId = null;
        this.$root.$emit(EventTypes.ShopSearchStart, input);
    }
    get group() {
        if (this.category && this.input.groupId) {
            return this.category.groups.find((group) => {
                return group.id === this.input.groupId;
            });
        }
        return null;
    }
    get isTopp200() {
        return SearchService.isTopp200(this.input);
    }
    get rootText() {
        return !this.isOpenShop
            ? Vue.filter('translate')('webshop')
            : Vue.filter('translate')('webshop_search_search_breadcrumb_openShop');
    }
};
ShopBreadcrumb = __decorate([
    Component({
        props: {
            searchMenu: Object,
            filters: Object,
            input: Object,
            loading: Boolean
        }
    })
], ShopBreadcrumb);
export default ShopBreadcrumb;
