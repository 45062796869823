export class ProductCategory {
    code;
    description;
    groups;
    imageNode;
    active;
    //amount is for shop only
    amount;
    static fromApi(item) {
        return Object.assign(new ProductCategory(), item);
    }
}
