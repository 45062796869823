var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ui-card" },
    [
      _c("div", { staticClass: "ui-card-header ui-card-header-inverted" }, [
        _c("span", [
          _vm._v(_vm._s(_vm._f("translate")("webshop_orders_result_title"))),
        ]),
        _vm._v(" "),
        _vm.orders.length
          ? _c("div", { staticClass: "orders-button-new-order-wrapper" }, [
              _c(
                "button",
                {
                  staticClass: "ui-button",
                  attrs: { type: "button", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.newOrder()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "mdi mdi-plus" }),
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm._f("translate")("webshop_orders_buttons_newOrder")
                      ) +
                      "\n            "
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("orders-result-list", {
        attrs: { loading: _vm.loading, orders: _vm.orders },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }