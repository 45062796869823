var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "div-table calculation-line-table" }, [
    _c("section", [
      _c("div", { staticClass: "table-header" }, [
        _c("div", { staticClass: "table-row" }, [
          _c("div", { staticClass: "table-column" }, [
            _vm._v(
              _vm._s(
                _vm._f("translate")(
                  "webshop_calculations_calculationLineList_columns_productNumber"
                )
              )
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "table-column calculation-column-name" }, [
            _vm._v(
              _vm._s(
                _vm._f("translate")(
                  "webshop_calculations_calculationLineList_columns_product"
                )
              )
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "table-column" }, [
            _vm._v(_vm._s(_vm._f("translate")("webshop_general_unit"))),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "table-column column-justify-end" }, [
            _vm._v(
              _vm._s(
                _vm._f("translate")(
                  "webshop_calculations_calculationLineList_columns_price"
                )
              )
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "table-column column-justify-end" }, [
            _vm._v(
              _vm._s(
                _vm._f("translate")(
                  "webshop_calculations_calculationLineList_columns_quantity"
                )
              )
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "table-column column-justify-end" }, [
            _vm._v(
              _vm._s(
                _vm._f("translate")(
                  "webshop_calculations_calculationLineList_columns_percentageWasted"
                )
              )
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "table-column column-button-delete" }),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table-body" },
        _vm._l(_vm.calculationLines, function (line) {
          return _c("calculation-line", {
            key: line.calculationLineId,
            attrs: {
              "calculation-line": line,
              "disable-calculation": _vm.disableCalculation,
            },
          })
        }),
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }