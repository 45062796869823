import { Service } from './Service';
import { ProductApi } from 'api/ProductApi';
import { ProductVM } from 'models/product/ProductVM';
import { ProductUnitType } from 'models/product/ProductUnitType';
import { ProductCategory } from 'models/product/ProductCategory';
import { GAService } from './GAService';
export class ProductService extends Service {
    static _unitCacheName = "p_units";
    static _productCategories = "p_categories";
    static getDepositProducts() {
        return new Promise((resolve, reject) => {
            ProductApi.getDepositProducts().then((res) => {
                var result = res.data
                    .map(x => ProductVM.fromApi(x));
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    /*
        Units
    */
    static getAllUnits() {
        let cacheItem = this.cache.get(this._unitCacheName, {});
        if (!cacheItem) {
            cacheItem = this.cache.add(this._unitCacheName, {}, new Promise((resolve, reject) => {
                ProductApi.getAllUnits().then((res) => {
                    var result = res.data
                        .map(x => ProductUnitType.fromApi(x));
                    resolve(result);
                }).catch((err) => Service.customErrorHandling({ ...err }, reject));
            }));
        }
        return cacheItem.promise;
    }
    /*
        Units
    */
    static getAllCategories() {
        let cacheItem = this.cache.get(this._productCategories, {});
        if (!cacheItem) {
            cacheItem = this.cache.add(this._productCategories, {}, new Promise((resolve, reject) => {
                ProductApi.getAllCategories().then((res) => {
                    var result = res.data
                        .map(x => ProductCategory.fromApi(x));
                    resolve(result);
                }).catch((err) => Service.customErrorHandling({ ...err }, reject));
            }));
        }
        return cacheItem.promise;
    }
    static getSelectedUnit(product) {
        let selectedUnit = null;
        let favoriteUnitObject = product.units.find(x => x.name == product.favoriteUnit);
        if (product.expiry && product.expiry.price > 0) {
            selectedUnit = product.expiry.unit;
            return selectedUnit;
        }
        if (product.favoriteUnit && favoriteUnitObject != undefined) {
            selectedUnit = product.favoriteUnit;
        }
        else {
            for (let i = 0; i < product.units.length; i++) {
                const unit = product.units[i];
                if (unit.lastBought) {
                    selectedUnit = unit.name;
                    break;
                }
                else if (unit.stdUnit) {
                    selectedUnit = unit.name;
                }
            }
        }
        return selectedUnit;
    }
    static getAlsoBoughtItems(productId) {
        return new Promise((resolve, reject) => {
            ProductApi.getAlsoBoughtProducts(productId).then((res) => {
                var result = res.data
                    .map(x => ProductVM.fromApi(x));
                if (result && result.length)
                    GAService.productImpressions(res.data);
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static getVendorProducts(purchaseDate) {
        return new Promise((resolve, reject) => {
            ProductApi.getVendorProducts(purchaseDate).then((res) => {
                var result = res.data
                    .map(x => ProductVM.fromApi(x));
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static getProduct(id) {
        return new Promise((resolve, reject) => {
            ProductApi.getProduct(id).then((res) => {
                var result = ProductVM.fromApi(res.data);
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static getProducts(productIds) {
        return new Promise((resolve, reject) => {
            ProductApi.getProducts(productIds).then((res) => {
                resolve(res.data);
            }).catch((err) => reject(err));
        });
    }
}
