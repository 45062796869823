var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import InventoryProductOwnFormPartial from './InventoryProductOwnFormPartial.vue';
import { ToastService } from 'services/ToastService';
import { InventoryService } from 'services/InventoryService';
import { EventTypes } from 'models/EventTypes';
let InventoryProductOwnPartial = class InventoryProductOwnPartial extends Vue {
    loading;
    goBack;
    setLoading;
    list;
    saveBtnText = Vue.filter('translate')('webshop_inventory_addmodals_ownProduct_saveBtnText');
    save(line) {
        this.setLoading(true);
        InventoryService.createOwnLine(this.list.id, line).then(line => {
            ToastService.success(Vue.filter('translate')('webshop_inventory_addmodals_ownProduct_toast_Success'));
            this.$root.$emit(EventTypes.InventoryLineAdded, line);
            this.$root.$emit(EventTypes.InventoryModalProductClose);
        }).catch(err => {
            this.setLoading(false);
            ToastService.danger(Vue.filter('translate')('webshop_defaults_toast_errorText'));
        });
    }
};
InventoryProductOwnPartial = __decorate([
    Component({
        props: {
            goBack: Function,
            setLoading: Function,
            loading: Boolean,
            list: Object
        },
        components: {
            InventoryProductOwnFormPartial
        }
    })
], InventoryProductOwnPartial);
export default InventoryProductOwnPartial;
