var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { ToastService } from "services/ToastService";
import { FavoriteService } from "services/FavoriteService";
let CheckOutFavListItem = class CheckOutFavListItem extends Vue {
    list;
    checkout;
    loading = false;
    mounted() { }
    addOrDeleteFunction() {
        this.loading = true;
        if (this.list.productExists) {
            FavoriteService.deleteProductFromList(this.list.id, this.checkout.ProductId)
                .then(data => {
                this.loading = false;
                this.list.productExists = !this.list.productExists;
            })
                .catch(err => {
                this.loading = false;
                ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
            });
        }
        else {
            FavoriteService.addProduct(this.list.id, this.checkout.ProductId, null, this.checkout.CheckoutId)
                .then(data => {
                this.loading = false;
                this.list.productExists = !this.list.productExists;
            })
                .catch(err => {
                this.loading = false;
                ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
            });
        }
    }
};
CheckOutFavListItem = __decorate([
    Component({
        props: {
            list: Object,
            checkout: Object
        }
    })
], CheckOutFavListItem);
export default CheckOutFavListItem;
