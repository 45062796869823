var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import OrdersResult from './result/OrdersResult.vue';
import OrdersModal from './OrdersModal.vue';
import VueRouter from 'vue-router';
const routes = [{
        name: "orders",
        path: "/webbshop/orders"
    }];
const router = new VueRouter({
    mode: "history",
    routes: routes
});
let Orders = class Orders extends Vue {
    get orders() {
        return this.$store.state.Orders.list;
    }
    get loading() {
        return this.$store.state.Orders.loading;
    }
};
Orders = __decorate([
    Component({
        components: {
            OrdersResult,
            OrdersModal
        }
    })
], Orders);
export default Orders;
