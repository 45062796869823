var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("strong", [
    _c(
      "a",
      {
        staticClass: "shop-changeDeliveryDate",
        on: {
          click: function ($event) {
            return _vm.changeDeliveryDate()
          },
        },
      },
      [
        _c("i", { staticClass: "mdi mdi-calendar" }),
        _vm._v(
          " " +
            _vm._s(_vm._f("date")(_vm.deliveryDate)) +
            " - " +
            _vm._s(_vm._f("translate")("webshop_order_changeDeliveryDate"))
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }