var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import Loader from 'components/utility/Loader.vue';
import { ProductVM } from 'models/product/ProductVM';
import ShopGalleryItem from 'components/shop/partials/ShopGalleryItem.vue';
import { ProductService } from 'services/ProductService';
import { EventTypes } from 'models/EventTypes';
import { OrderlineUISourceTypes } from 'models/order/OrderlineUISourceTypes';
let IpaperAddToCartModal = class IpaperAddToCartModal extends Vue {
    data;
    product = new ProductVM();
    loading = true;
    OrderlineUISourceTypes = OrderlineUISourceTypes;
    mounted() {
        this.loadProduct();
        this.$root.$on(EventTypes.ShopAddedProductToBasket, this.closeModal);
    }
    beforeDestroy() {
        this.$root.$off(EventTypes.ShopAddedProductToBasket);
    }
    closeModal() {
        this.$emit('close-modal');
    }
    loadProduct() {
        this.loading = true;
        ProductService.getProduct(this.data.productId).then(res => {
            this.product = res;
        }).finally(() => {
            this.loading = false;
        });
    }
};
IpaperAddToCartModal = __decorate([
    Component({
        components: {
            Loader,
            ShopGalleryItem
        },
        props: {
            data: Object
        }
    })
], IpaperAddToCartModal);
export default IpaperAddToCartModal;
