var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import LoadingButton from '../../shared/LoadingButton.vue';
import { CalculationService } from 'services/CalculationService';
import { CalculationTreeVM } from 'models/calculation/CalculationTreeVM';
import { ToastService } from 'services/ToastService';
let ShareCalculationModal = class ShareCalculationModal extends Vue {
    data;
    loading = false;
    calculationTree = new CalculationTreeVM();
    calculationTreeArray = [];
    isSelectedSubCalculations = false;
    share = false;
    mounted() {
        this.share = !this.data.isShared;
        this.loadCalculationTreeStructure();
    }
    closeModal() {
        this.$emit('close-modal');
    }
    getCalculationTreeArray(calculationTree, newArray) {
        let lastIndex = newArray.length - 1;
        if (lastIndex < 0) {
            newArray[0] = [];
            newArray[0].push(CalculationTreeVM.toCalculationVM(calculationTree));
            lastIndex = newArray.length - 1;
        }
        calculationTree.calculationTreeVMs.forEach(x => {
            let newRow = [];
            if (lastIndex >= 0) {
                newRow = [...newArray[lastIndex]];
            }
            newRow.push(CalculationTreeVM.toCalculationVM(x));
            newArray.push(newRow);
            this.getCalculationTreeArray(x, newArray);
        });
    }
    loadCalculationTreeStructure() {
        CalculationService.getCalculationTreeStructure(this.data.calculationId).then((res) => {
            this.calculationTree = res;
            this.calculationTreeArray = [];
            this.getCalculationTreeArray(res, this.calculationTreeArray);
        });
    }
    updateSharing() {
        this.loading = true;
        CalculationService.shareCalculation(this.data.calculationId, this.share, this.isSelectedSubCalculations).then((res) => {
            this.$store.dispatch('Calculations/getAll', res);
            this.$store.dispatch('Calculations/selectCalculation', res.calculationId);
            let translateMsg = "webshop_calculations_calculationList_toast_stoppedSharingList";
            if (res.isShared) {
                translateMsg = "webshop_calculations_calculationList_toast_sharedList";
            }
            ToastService.success(Vue.filter("translate")(translateMsg));
        }).catch(err => {
            ToastService.danger(Vue.filter("translate")("webshop_calculations_calculationList_toast_error"));
        }).finally(() => {
            this.loading = false;
            this.$emit('close-modal');
        });
        ;
    }
    get buttonTextShare() {
        let text = Vue.filter("translate")("webshop_calculations_calculationHeader_buttons_share");
        if (!this.share) {
            text = Vue.filter("translate")("webshop_calculations_calculationHeader_buttons_stopSharing");
        }
        return text;
    }
};
ShareCalculationModal = __decorate([
    Component({
        components: {
            LoadingButton
        },
        props: {
            data: Object
        }
    })
], ShareCalculationModal);
export default ShareCalculationModal;
