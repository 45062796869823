var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ui-card" },
    [
      _c("div", { staticClass: "ui-card-header ui-card-header-inverted" }, [
        _c("span", [
          _vm._v(
            _vm._s(
              _vm._f("translate")(
                "webshop_statistics_result_products_cardTitle"
              )
            )
          ),
        ]),
        _vm._v(" "),
        _vm.products.length &&
        _vm.productStatisticsFilter.fromDate &&
        _vm.productStatisticsFilter.toDate
          ? _c(
              "div",
              { staticClass: "statistics-result-button-excel-wrapper" },
              [
                _c(
                  "button",
                  {
                    staticClass: "ui-button statistics-result-button-excel",
                    attrs: { type: "button", loading: _vm.loading },
                    on: {
                      click: function ($event) {
                        return _vm.getStatisticsExcel()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "mdi mdi-grid" }),
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("translate")(
                            "webshop_statistics_result_products_buttonExportExcel"
                          )
                        ) +
                        "\n            "
                    ),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("statistics-result-product-list", {
        attrs: { loading: _vm.loading, products: _vm.products },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }