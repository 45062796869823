import { render, staticRenderFns } from "./CreateCalculationModal.vue?vue&type=template&id=4429caf4&"
import script from "./CreateCalculationModal.vue?vue&type=script&lang=ts&"
export * from "./CreateCalculationModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Projekt\\webbshop\\SvenskCater\\SvenskCater\\app\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4429caf4')) {
      api.createRecord('4429caf4', component.options)
    } else {
      api.reload('4429caf4', component.options)
    }
    module.hot.accept("./CreateCalculationModal.vue?vue&type=template&id=4429caf4&", function () {
      api.rerender('4429caf4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/calculations/modals/CreateCalculationModal.vue"
export default component.exports