export class ModalVM {
    id;
    content;
    component;
    maxWidth;
    height;
    duration;
    active = true;
    allowDuplicates = false;
    data;
    constructor(id, content, component, maxWidth, height, duration, active = true, allowDuplicates = false, data) {
        this.id = id;
        this.content = content;
        this.component = component;
        this.maxWidth = maxWidth;
        this.height = height;
        this.duration = duration;
        this.active = active;
        this.allowDuplicates = allowDuplicates;
        this.data = data;
    }
}
