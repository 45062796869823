var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-modal" },
    [
      !_vm.ageVerified
        ? _c("age-verification", {
            class: { active: _vm.isSameStep("ageVerification") },
            on: { ageVerified: _vm.setAgeVerified },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.ageVerified
        ? _c("div", { staticClass: "ui-modal-content" }, [
            _c("h3", { staticClass: "ui-modal-header" }, [
              _vm._v(_vm._s(_vm.getHeaderTranslation())),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "div",
                { staticClass: "modalLoginForm-steps" },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.isSameStep("requestUsername") &&
                            !_vm.isSameStep("requestPassword"),
                          expression:
                            "\n            !isSameStep('requestUsername') && !isSameStep('requestPassword')\n          ",
                        },
                      ],
                      staticClass:
                        "modalLoginForm-stepStatus modalLoginForm-stepStatusTwo",
                    },
                    [
                      _c("ul", [
                        _c(
                          "li",
                          {
                            class: {
                              active:
                                _vm.isSameStep("login") ||
                                _vm.isSameStep("acceptance"),
                            },
                          },
                          [
                            _vm._v(
                              "\n              1. " +
                                _vm._s(
                                  _vm._f("translate")(
                                    "webshop_login_subNav_login"
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "li",
                          { class: { active: _vm.isSameStep("deliveryDate") } },
                          [
                            _vm._v(
                              "\n              2. " +
                                _vm._s(
                                  _vm._f("translate")(
                                    "webshop_login_subNav_deliveryDate"
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("login-form-partial", {
                    class: { active: _vm.isSameStep("login") },
                    attrs: { newCustomerLink: _vm.newCustomerLink },
                    on: {
                      onComplete: function ($event) {
                        return _vm.loginConfirmed()
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("login-acceptance-partial", {
                    class: { active: _vm.isSameStep("acceptance") },
                    attrs: { approvals: _vm.waitingApprovals },
                    on: {
                      onComplete: function ($event) {
                        return _vm.acceptanceAccepted()
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.deliveryDates
                    ? _c("delivery-date-picker", {
                        staticClass: "modalLoginForm-step modalLoginForm-step3",
                        class: { active: _vm.isSameStep("deliveryDate") },
                        attrs: {
                          fromLogin: true,
                          "delivery-dates": _vm.deliveryDates,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("request-username-partial", {
                    class: { active: _vm.isSameStep("requestUsername") },
                    on: { close: _vm.close, goBack: _vm.goBack },
                  }),
                  _vm._v(" "),
                  _c("request-password-partial", {
                    class: { active: _vm.isSameStep("requestPassword") },
                    on: { close: _vm.close, goBack: _vm.goBack },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isSameStep("login"),
                      expression: "isSameStep('login')",
                    },
                  ],
                  staticClass: "small text-center",
                  staticStyle: { "line-height": "1.2rem !important" },
                },
                [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.goRequestPassword()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm._f("translate")(
                            "webshop_login_requestPasswordLink"
                          )
                        )
                      ),
                    ]
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.goRequestUsername()
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm._f("translate")(
                            "webshop_login_requestUsernameLink"
                          )
                        )
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }