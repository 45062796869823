import { Service } from './Service';
import { CalculationVM } from 'models/calculation/CalculationVM';
import { CalculationApi } from 'api/CalculationApi';
import { CalculationLineVM } from 'models/calculation/CalculationLineVM';
import { ProductVM } from 'models/product/ProductVM';
import { GAService } from './GAService';
import { CalculationSearchSortBy } from 'models/search/SearchSortBy';
export class CalculationService extends Service {
    static getCalculations() {
        return new Promise((resolve, reject) => {
            CalculationApi.GetCalculations().then((res) => {
                var result = res.data
                    .map(x => CalculationVM.fromApi(x));
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static getPossibleCalculations(calculationId) {
        return new Promise((resolve, reject) => {
            CalculationApi.GetPossibleCalculations(calculationId).then((res) => {
                var result = res.data
                    .map(x => CalculationVM.fromApi(x));
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static getCalculation(calculationId) {
        return new Promise((resolve, reject) => {
            CalculationApi.GetCalculationById(calculationId).then((res) => {
                resolve(CalculationVM.fromApi(res.data));
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static copyCalculation(copyDetails) {
        return new Promise((resolve, reject) => {
            CalculationApi.CopyCalculation(copyDetails).then((res) => {
                resolve(CalculationVM.fromApi(res.data));
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static shareCalculation(calculationId, share, deepSharing) {
        return new Promise((resolve, reject) => {
            CalculationApi.ShareCalculation(calculationId, share, deepSharing).then((res) => {
                var result = res.data;
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static uploadImage(calculationId, formData) {
        return new Promise((resolve, reject) => {
            CalculationApi.UploadImage(calculationId, formData).then((res) => {
                var result = res.data;
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static getCalculationTreeStructure(calculationId) {
        return new Promise((resolve, reject) => {
            CalculationApi.GetCalculationTreeStructure(calculationId).then((res) => {
                var result = res.data;
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static getConvertedOrderlines(calculationId, multiplier) {
        return new Promise((resolve, reject) => {
            CalculationApi.GetConvertedOrderlines(calculationId, multiplier).then((res) => {
                var result = res.data;
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static createCalculation(calculation) {
        return new Promise((resolve, reject) => {
            CalculationApi.CreateCalculation(calculation).then((res) => {
                var result = CalculationVM.fromApi(res.data);
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static updateCalculation(calculation) {
        return new Promise((resolve, reject) => {
            CalculationApi.UpdateCalculation(calculation).then((res) => {
                var result = CalculationVM.fromApi(res.data);
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static updateCalculationCoverage(calculation, grossProfit = false) {
        return new Promise((resolve, reject) => {
            CalculationApi.UpdateCalculationCoverage(calculation, grossProfit).then((res) => {
                var result = CalculationVM.fromApi(res.data);
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static deleteCalculation(calculation) {
        return new Promise((resolve, reject) => {
            CalculationApi.DeleteCalculation(calculation.calculationId).then((res) => {
                resolve(CalculationVM.fromApi(res.data));
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static addProductLines(calculationId, productIds) {
        return new Promise((resolve, reject) => {
            CalculationApi.AddProductLines(calculationId, productIds).then((res) => {
                var result = CalculationVM.fromApi(res.data);
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static addCustomerItem(calculationId, customerItem) {
        return new Promise((resolve, reject) => {
            CalculationApi.AddCustomerItem(calculationId, customerItem).then((res) => {
                var result = CalculationVM.fromApi(res.data);
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static addFromFavoriteLists(calculationId, favoriteLists) {
        return new Promise((resolve, reject) => {
            CalculationApi.AddFromFavoriteLists(calculationId, favoriteLists).then((res) => {
                var result = res.data;
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static addCalculationLines(calculationId, calculationIds) {
        return new Promise((resolve, reject) => {
            CalculationApi.AddCalculationLines(calculationId, calculationIds).then((res) => {
                var result = CalculationVM.fromApi(res.data);
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static updateCalculationLine(calculationLine) {
        return new Promise((resolve, reject) => {
            CalculationApi.UpdateCalculationLine(calculationLine).then((res) => {
                var result = CalculationLineVM.fromApi(res.data);
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static deleteCalculationLine(calculationLineId) {
        return new Promise((resolve, reject) => {
            CalculationApi.DeleteCalculationLine(calculationLineId).then((res) => {
                resolve(CalculationLineVM.fromApi(res.data));
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static createOrderFromCalculation(calculationId, orderlines) {
        return new Promise((resolve, reject) => {
            CalculationApi.CreateOrderFromCalculation(calculationId, orderlines).then((res) => {
                GAService.addProductToBasket(orderlines);
                resolve(res.data);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static addCalculationInActiveOrder(calculationId, orderlines) {
        return new Promise((resolve, reject) => {
            CalculationApi.AddCalculationInActiveOrder(calculationId, orderlines).then((res) => {
                GAService.addProductToBasket(orderlines);
                resolve(res.data);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static searchNewProductsByQuery(query, listId) {
        return new Promise((resolve, reject) => {
            CalculationApi.searchNewProductsByQuery(query, listId).then((res) => {
                var result = res.data
                    .map(x => ProductVM.fromApi(x));
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static searchNewProductsBySales(fromDate, listId) {
        return new Promise((resolve, reject) => {
            CalculationApi.searchNewProductsBySales(fromDate, listId).then((res) => {
                var result = res.data
                    .map(x => ProductVM.fromApi(x));
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static sortResult(list, sortBy) {
        let res = Object.assign([], list);
        let semiNumberComparer = (s1, s2) => {
            let n1, n2, b1, b2;
            n1 = Number(s1);
            n2 = Number(s2);
            if (!Number.isNaN(n1) && !Number.isNaN(n2)) {
                if (n1 > n2)
                    return 1;
                if (n1 < n2)
                    return -1;
                if (n1 == n2)
                    return 0;
            }
            if (b1 && Number.isNaN(n2))
                return -1;
            if (Number.isNaN(n1) && b2)
                return 1;
            return s1 < s2 ? -1 : 1;
        };
        return res.sort((a, b) => {
            switch (sortBy) {
                case CalculationSearchSortBy.Description:
                    return a.name < b.name ? -1 : 1;
                case CalculationSearchSortBy.DescriptionDesc:
                    return b.name < a.name ? -1 : 1;
                case CalculationSearchSortBy.ProductId:
                    return semiNumberComparer(a.id, b.id);
                case CalculationSearchSortBy.ProductIdDesc:
                    return semiNumberComparer(b.id, a.id);
                case CalculationSearchSortBy.Date:
                    return a.created < b.created ? -1 : 1;
                case CalculationSearchSortBy.DateDesc:
                    return b.created < a.created ? -1 : 1;
                default:
                    return 0;
            }
        });
    }
}
