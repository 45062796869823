import { InventoryLine } from 'models/inventory/InventoryLine';
import { InventoryGroup } from 'models/inventory/InventoryGroup';
import { Service } from './Service';
import { InventoryApi } from 'api/InventoryApi';
import { InventoryList } from 'models/inventory/InventoryList';
import { CreatedLinesResponseVM } from 'models/inventory/CreatedLinesResponseVM';
import { InventoryAutoListResultVM } from 'models/inventory/InventoryAutoListResultVM';
import { InventoryArchiveVM } from 'models/inventory/InventoryArchiveVM';
import { FavoriteList } from 'models/favorites/FavoriteList';
export class InventoryService extends Service {
    /*
        LIST
    */
    static getAllLists() {
        return new Promise((resolve, reject) => {
            InventoryApi.getAllLists().then((inventoryRes) => {
                var result = inventoryRes.data
                    .map(x => InventoryList.fromApi(x));
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static getListById(id) {
        return new Promise((resolve, reject) => {
            InventoryApi.getListById(id).then((inventoryRes) => {
                var result = InventoryList.fromApi(inventoryRes.data);
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static createList(name) {
        return new Promise((resolve, reject) => {
            InventoryApi.createList(name).then((inventoryRes) => {
                var result = InventoryList.fromApi(inventoryRes.data);
                resolve(result);
            })
                .catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static updateList(id, name) {
        return new Promise((resolve, reject) => {
            InventoryApi.updateList(id, name).then((inventoryRes) => {
                var result = InventoryList.fromApi(inventoryRes.data);
                resolve(result);
            })
                .catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static deleteList(id) {
        return new Promise((resolve, reject) => {
            InventoryApi.deleteList(id).then((res) => {
                resolve();
            })
                .catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static updatePricesOnList(id) {
        return new Promise((resolve, reject) => {
            InventoryApi.updatePricesOnList(id).then((res) => {
                resolve(res.data);
            })
                .catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    ;
    static getArchiveList() {
        return new Promise((resolve, reject) => {
            InventoryApi.getArchiveList().then((inventoryRes) => {
                var result = inventoryRes.data
                    .map(x => InventoryArchiveVM.fromApi(x));
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static CreateInventoryArchive(inventoryArhive) {
        return new Promise((resolve, reject) => {
            InventoryApi.createInventoryArchive(inventoryArhive).then((res) => {
                var result = InventoryGroup.fromApi(res.data);
                resolve(result);
            })
                .catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static deleteInventoryArchive(id) {
        return new Promise((resolve, reject) => {
            InventoryApi.deleteInventoryArchive(id).then((res) => {
                resolve();
            })
                .catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    /*
        FAVORITE LIST
    */
    static createFavoriteList(id) {
        return new Promise((resolve, reject) => {
            InventoryApi.createFavoriteList(id).then((inventoryRes) => {
                var result = FavoriteList.fromApi(inventoryRes.data);
                resolve(result);
            })
                .catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    /*
        Group
    */
    static createGroup(listId, name) {
        return new Promise((resolve, reject) => {
            InventoryApi.createGroup(listId, name).then((inventoryRes) => {
                var result = InventoryGroup.fromApi(inventoryRes.data);
                resolve(result);
            })
                .catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static updateGroup(id, name, sorting) {
        return new Promise((resolve, reject) => {
            InventoryApi.updateGroup(id, name, sorting).then((inventoryRes) => {
                var result = InventoryGroup.fromApi(inventoryRes.data);
                resolve(result);
            })
                .catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static deleteGroup(id) {
        return new Promise((resolve, reject) => {
            InventoryApi.deleteGroup(id).then((res) => {
                resolve();
            })
                .catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    /*
        Line
    */
    // static createLine(listId: number, line: InventoryLine) {
    //     return new Promise<InventoryLine>((resolve, reject) => {
    //         InventoryApi.createLine(listId, line).then((inventoryRes) => {
    //             var result = InventoryLine.fromApi(inventoryRes.data);
    //             resolve(result);
    //         })
    //         .catch(Service.customErrorHandling);
    //     });
    // }
    static createLines(listId, lines) {
        return new Promise((resolve, reject) => {
            InventoryApi.createLines(listId, lines).then((res) => {
                var result = CreatedLinesResponseVM.fromApi(res.data);
                resolve(result);
            })
                .catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static updateLine(id, line, forcePriceUpdate) {
        return new Promise((resolve, reject) => {
            InventoryApi.updateLine(id, line, forcePriceUpdate).then((inventoryRes) => {
                var result = InventoryLine.fromApi(inventoryRes.data);
                resolve(result);
            })
                .catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static updateListSort(id, list) {
        return new Promise((resolve, reject) => {
            InventoryApi.updateLineSort(id, list).then((inventoryRes) => {
                var result = InventoryList.fromApi(inventoryRes.data);
                resolve(result);
            })
                .catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static deleteLine(id) {
        return new Promise((resolve, reject) => {
            InventoryApi.deleteLine(id).then((res) => {
                resolve();
            })
                .catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static resetLineToDefault(id) {
        return new Promise((resolve, reject) => {
            InventoryApi.resetLineToDefault(id).then((res) => {
                var result = InventoryLine.fromApi(res.data);
                resolve(result);
            }).catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    /* Own Lines */
    static createOwnLine(listId, line) {
        return new Promise((resolve, reject) => {
            InventoryApi.createOwnLine(listId, line).then((inventoryRes) => {
                var result = InventoryLine.fromApi(inventoryRes.data);
                resolve(result);
            })
                .catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static updateOwnLine(line) {
        return new Promise((resolve, reject) => {
            InventoryApi.updateOwnLine(line).then((inventoryRes) => {
                var result = InventoryLine.fromApi(inventoryRes.data);
                resolve(result);
            })
                .catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    /* Reset Lines Amount */
    static resetAmounts(listId) {
        return new Promise((resolve, reject) => {
            InventoryApi.resetAmounts(listId).then(() => {
                resolve();
            })
                .catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    /* CSV List */
    static getUrlForExcel(listId) {
        return InventoryApi.getUrlForExcel(listId);
    }
    /* CSV List */
    // static getListAsCsv(listId: number) {
    //     return new Promise<any>((resolve, reject) => {
    //         InventoryApi.getListAsCsv(listId).then((res) => {
    //             resolve(res.data);
    //         })
    //         .catch((err) => Service.customErrorHandling({...err}, reject));
    //     });
    // }
    /* Send Email */
    static sendListAsEmail(listId, emails) {
        return new Promise((resolve, reject) => {
            InventoryApi.sendListAsEmail(listId, emails).then(() => {
                resolve();
            })
                .catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    /*
        Orders
    */
    static getLinesByOrderDate(fromDate) {
        let date = moment(fromDate).toJSON();
        return new Promise((resolve, reject) => {
            InventoryApi.getLinesByOrderDate(date).then((inventoryRes) => {
                var result = inventoryRes.data
                    .map(x => InventoryLine.fromApi(x));
                resolve(result);
            })
                .catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    static getSaleStatisticsLinesByDate(fromDate, listId) {
        let date = moment(fromDate).toJSON();
        return new Promise((resolve, reject) => {
            InventoryApi.getSaleStatisticsLinesByDate(date, listId).then((inventoryRes) => {
                var result = inventoryRes.data
                    .map(x => InventoryLine.fromApi(x));
                resolve(result);
            })
                .catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    /*
        FavoriteList
    */
    static addProductsFromFavoritelist(listId, favoriteListIds, groupId) {
        return new Promise((resolve, reject) => {
            InventoryApi.addProductsFromFavoritelist(listId, favoriteListIds, groupId).then((res) => {
                var result = CreatedLinesResponseVM.fromApi(res.data);
                resolve(result);
            })
                .catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    /*
        Search
    */
    static search(query, listId) {
        return new Promise((resolve, reject) => {
            InventoryApi.search(query, listId).then((res) => {
                var result = res.data
                    .map(x => InventoryLine.fromApi(x));
                resolve(result);
            })
                .catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    /*
        Autolager
    */
    static autolager(fromDate) {
        return new Promise((resolve, reject) => {
            InventoryApi.autolager(fromDate).then((res) => {
                var result = res.data
                    .map(x => InventoryAutoListResultVM.fromApi(x));
                resolve(result);
            })
                .catch((err) => Service.customErrorHandling({ ...err }, reject));
        });
    }
    /*
        Helpers
    */
    static calTotalPrice(list) {
        var totalPrice = 0;
        totalPrice += list.groups.reduce((sum, group) => sum + group.lines.reduce((sumLine, line) => sumLine + line.pricePrUnit * line.amount, 0), 0);
        totalPrice += list.linesNotInGroup.reduce((sum, line) => sum + line.pricePrUnit * line.amount, 0);
        return totalPrice;
    }
}
