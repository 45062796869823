var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading || _vm.products.length > 0
        ? [
            _c(
              "div",
              { staticClass: "ui-card-header also-bought-products-header" },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_order_alsoBoughtProducts_title"
                      )
                    )
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ui-card-content" }, [
              _c(
                "div",
                {
                  staticClass:
                    "shop-productlist-thumbs shop-products shop-search-result-gallery also-bought-products",
                },
                [
                  _vm.loading
                    ? _c("loader", { attrs: { size: "large" } })
                    : _c(
                        "div",
                        { attrs: { column: "12" } },
                        [
                          _c(
                            "transition",
                            {
                              attrs: {
                                appear: "",
                                name: "transition-products",
                                tag: "div",
                              },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { grid: "wrap" } },
                                [
                                  _c(
                                    "carousel",
                                    {
                                      staticStyle: { display: "grid" },
                                      attrs: {
                                        perPageCustom: _vm.perPageCustom,
                                        paginationPadding: 5,
                                        paginationColor: "#aaa",
                                        centerMode: true,
                                      },
                                    },
                                    _vm._l(
                                      _vm.products,
                                      function (product, index) {
                                        return _c(
                                          "slide",
                                          { key: product.productId },
                                          [
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "shop-gallery-item-wrapper",
                                                  attrs: { column: "xs-12" },
                                                },
                                                [
                                                  _c("shop-gallery-item", {
                                                    attrs: {
                                                      loading: _vm.loading,
                                                      product: product,
                                                      position: index + 1,
                                                      uiSource:
                                                        _vm
                                                          .OrderlineUISourceTypes
                                                          .BasketAlsoBoughtList,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                          ],
                                          2
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }