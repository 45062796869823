var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "div-table" },
    [
      _vm.hasPopularProducts
        ? [
            _c("div", { staticClass: "table-header group-header" }, [
              _c(
                "div",
                { staticClass: "table-row shop-product-list-popular" },
                [
                  _c("i", { staticClass: "icon icon-flame" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "text" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("translate")(
                          "webshop_search_search_view_list_headers_popular"
                        )
                      )
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "table-body" },
              [
                _vm._l(_vm.displayedProducts, function (product, index) {
                  return [
                    _c("shop-product-list-item", {
                      key: "p" + product.productId,
                      attrs: {
                        loading: _vm.loading,
                        product: product,
                        position: index + 1,
                        "ui-source": _vm.OrderlineUISourceTypes.ShopListPopular,
                      },
                    }),
                    _vm._v(" "),
                    product.AltProduct
                      ? _c("shop-product-list-item", {
                          key:
                            "p" +
                            product.productId +
                            "_" +
                            product.AltProduct.productId,
                          attrs: {
                            isAltProduct: true,
                            loading: _vm.loading,
                            product: product.AltProduct,
                            position: index + 1,
                            "ui-source":
                              _vm.OrderlineUISourceTypes.ShopListPopular,
                          },
                        })
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
            _vm._v(" "),
            _vm.popularProducts && _vm.popularProducts.length > 3
              ? _c("div", { staticClass: "btn-more-wrapper" }, [
                  !_vm.showAllPopularProducts
                    ? _c(
                        "button",
                        {
                          staticClass: "ui-button",
                          on: {
                            click: function ($event) {
                              return _vm.showMorePopularProducts()
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("translate")(
                                "webshop_search_search_view_general_popular_more"
                              )
                            )
                          ),
                        ]
                      )
                    : _c(
                        "button",
                        {
                          staticClass: "ui-button",
                          on: {
                            click: function ($event) {
                              return _vm.showMorePopularProducts()
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("translate")(
                                "webshop_search_search_view_general_popular_less"
                              )
                            )
                          ),
                        ]
                      ),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }