var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { EventTypes } from "models/EventTypes";
import { SearchInput } from "models/search/SearchInput";
let ShopFilterCategories = class ShopFilterCategories extends Vue {
    loading;
    //selectedCategory: ProductCategory = null;
    //selectedGroup: ProductGroup = null;
    filters;
    categories;
    input;
    eventType;
    totalProducts;
    beforeDestroy() {
        this.$root.$off(this.eventType);
    }
    created() {
        this.$root.$emit(this.eventType);
    }
    mounted() {
    }
    get filteredCategories() {
        if (!this.categories || !this.filters)
            return [];
        return this.categories.map((cat) => {
            let amount = this.filters.categories[cat.code];
            cat.amount = typeof amount !== "undefined" ? amount : 0;
            return cat;
        });
    }
    get filteredGroups() {
        if (!this.selectedCategory || !this.filters)
            return [];
        return this.selectedCategory.groups.map((group) => {
            let amount = this.filters.groups[group.id];
            group.amount = typeof amount !== "undefined" ? amount : 0;
            group.active = group.id == this.input.groupId;
            return group;
        });
    }
    get selectedCategory() {
        if (this.input && this.input.categoryId) {
            return this.categories.find(x => x.code == this.input.categoryId);
        }
        return null;
    }
    selectCategory(cat) {
        let input = Object.assign(new SearchInput(), this.input);
        input.categoryId = cat ? cat.code : null;
        input.groupId = null;
        this.$root.$emit(EventTypes.ShopSearchStart, input);
    }
    selectGroup(group) {
        let input = Object.assign(new SearchInput(), this.input);
        input.groupId = group && !group.active ? group.id : null;
        this.$root.$emit(EventTypes.ShopSearchStart, input);
    }
    getCategoryId() {
        if (!this.input)
            return null;
        return this.input.categoryId;
    }
};
ShopFilterCategories = __decorate([
    Component({
        props: {
            filters: Object,
            input: Object,
            categories: Array,
            loading: Boolean,
            eventType: String,
            totalProducts: Number
        }
    })
], ShopFilterCategories);
export default ShopFilterCategories;
