var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { ToastService } from "services/ToastService";
import { SearchService } from 'services/SearchService';
import { EventTypes } from "models/EventTypes";
import { SearchInput } from "models/search/SearchInput";
import { MarkingTypes } from "models/search/MarkingTypes";
import { ItemIcons } from "models/product/ItemIcons";
import { SearchSortBy } from "models/search/SearchSortBy";
import { Settings } from "config/Settings";
import { CustomerClass } from "models/user/CustomerClass";
import { WindowService } from "services/WindowService";
import { PdfReportService } from "services/PdfReportService";
let ShopFilterCustomLists = class ShopFilterCustomLists extends Vue {
    loading;
    input;
    eventType;
    agreementKey;
    agreement = new ItemIcons().Agreement;
    hasAgreement;
    campaignKey;
    campaign = new ItemIcons().HasCampaign;
    renderCounter = 0;
    settings = Settings;
    customerClass = CustomerClass;
    mounted() {
        let markingKey = Object.keys(MarkingTypes)
            .find(key => isNaN(Number(key)) && MarkingTypes[key] === MarkingTypes.Agreement);
        this.agreementKey = markingKey;
        markingKey = Object.keys(MarkingTypes)
            .find(key => isNaN(Number(key)) && MarkingTypes[key] === MarkingTypes.HasCampaign);
        this.campaignKey = markingKey;
    }
    beforeDestroy() {
        this.$root.$off(this.eventType);
    }
    created() {
        this.$root.$emit(this.eventType);
    }
    select() {
        let input = Object.assign(new SearchInput(), this.input);
        input.favoriteListId = null;
        input.department = null;
        if (this.isTopp200) {
            input.searchQuery = "";
            input.sortBy = SearchSortBy.Description;
        }
        else {
            input.searchQuery = SearchService.topp200;
            input.sortBy = SearchSortBy.Relevance;
        }
        this.$root.$emit(EventTypes.ShopSearchStart, input);
    }
    get isTopp200() {
        return SearchService.isTopp200(this.input);
    }
    get agreementActive() {
        if (!this.input)
            return false;
        return this.input.markings.findIndex(y => y.toString().toLowerCase() == this.agreementKey.toLowerCase()) !== -1;
    }
    get campaignActive() {
        if (!this.input)
            return false;
        return this.input.markings.findIndex(y => y.toString().toLowerCase() == this.campaignKey.toLowerCase()) !== -1;
    }
    agreementProducts() {
        let input = Object.assign(new SearchInput(), this.input);
        if (this.agreementActive) {
            input.markings = input.markings.filter(x => x.toString().toLowerCase() != this.agreementKey.toLowerCase());
        }
        else {
            if (!input.markings)
                input.markings = [];
            input.markings.push(this.agreementKey.toLowerCase());
        }
        this.$root.$emit(EventTypes.ShopSearchStart, input);
    }
    campaignProducts() {
        let input = Object.assign(new SearchInput(), this.input);
        if (this.campaignActive) {
            input.markings = input.markings.filter(x => x.toString().toLowerCase() != this.campaignKey.toLowerCase());
        }
        else {
            if (!input.markings)
                input.markings = [];
            input.markings.push(this.campaignKey.toLowerCase());
        }
        this.$root.$emit(EventTypes.ShopSearchStart, input);
    }
    printTopN() {
        const newWindow = WindowService.initializeNewWindow();
        PdfReportService.printTopN(200).then(res => {
            const file = new Blob([res], { type: "application/pdf" });
            const nav = window.navigator;
            if (window.navigator && nav.msSaveOrOpenBlob) {
                nav.msSaveOrOpenBlob(file, 'top200.pdf');
                setTimeout(() => {
                    newWindow.close();
                }, 100);
            }
            else {
                const reportLink = window.URL.createObjectURL(file);
                newWindow.location = reportLink;
            }
            this.loading = false;
        }, err => {
            setTimeout(() => {
                newWindow.close();
                if (err == 404) {
                    ToastService.danger(Vue.filter("translate")("webshop_search_search_result_empty_state_no_products_search"));
                }
                else {
                    ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
                }
            }, 500);
            this.loading = false;
            return;
        });
    }
};
ShopFilterCustomLists = __decorate([
    Component({
        props: {
            input: Object,
            loading: Boolean,
            eventType: String,
            hasAgreement: Boolean
        }
    })
], ShopFilterCustomLists);
export default ShopFilterCustomLists;
