var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modalLoginForm-step modalLoginForm-step1" },
    [
      _c(
        "form",
        {
          staticClass: "ui-form modalLoginForm-step1",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.login()
            },
          },
        },
        [
          _c("div", { staticClass: "ui-form-fieldwrapper" }, [
            _c("label", { staticClass: "username" }, [
              _vm._v(
                _vm._s(_vm._f("translate")("webshop_login_field_usernameLabel"))
              ),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.username,
                  expression: "username",
                },
              ],
              attrs: {
                type: "text",
                name: "user",
                autocomplete: "off",
                required: "",
                disabled: _vm.loading,
                placeholder: _vm.$options.filters.translate(
                  "webshop_login_field_usernamePlaceholder"
                ),
              },
              domProps: { value: _vm.username },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.username = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ui-form-fieldwrapper" }, [
            _c("label", { staticClass: "password" }, [
              _vm._v(
                _vm._s(_vm._f("translate")("webshop_login_field_passwordLabel"))
              ),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password",
                },
              ],
              attrs: {
                type: "password",
                name: "password",
                autocomplete: "off",
                required: "",
                disabled: _vm.loading,
                placeholder: _vm.$options.filters.translate(
                  "webshop_login_field_passwordPlaceholder"
                ),
              },
              domProps: { value: _vm.password },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.password = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ui-form-fieldwrapper ui-form-checkboxwrapper" },
            [
              _c("label", { staticClass: "remember" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.rememberMe,
                      expression: "rememberMe",
                    },
                  ],
                  staticClass: "loginRememberMe",
                  attrs: {
                    type: "checkbox",
                    name: "rememberMe",
                    disabled: _vm.loading,
                  },
                  domProps: {
                    checked: Array.isArray(_vm.rememberMe)
                      ? _vm._i(_vm.rememberMe, null) > -1
                      : _vm.rememberMe,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.rememberMe,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.rememberMe = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.rememberMe = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.rememberMe = $$c
                      }
                    },
                  },
                }),
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm._f("translate")("webshop_login_field_RememberMeLabel")
                    ) +
                    "\n            "
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "ui-message ui-message-error ui-message-small modalLoginForm-loginError",
              class: { hidden: !_vm.showError },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm._f("translate")("webshop_login_submit_error")) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "ui-form-fieldwrapper ui-form-submitwrapper ui-form-submitwrapper-full",
            },
            [
              _vm.newCustomerLink
                ? _c(
                    "a",
                    {
                      staticClass:
                        "ui-button ui-button-secondary modalLoginForm-noLogin left",
                      attrs: { href: _vm.newCustomerLink },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm._f("translate")(
                              "webshop_login_button_becomeCustomer"
                            )
                          ) +
                          "\n            "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("loading-button", {
                attrs: {
                  "icon-class": "mdi-key-variant",
                  text: _vm.$options.filters.translate(
                    "webshop_login_button_login"
                  ),
                  "loading-text": _vm.$options.filters.translate(
                    "webshop_login_button_login_loadingText"
                  ),
                  loading: _vm.loading,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }