var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { ToastService } from "services/ToastService";
import { AuthService } from "services/AuthService";
import { LoaderService } from "services/LoaderService";
import { getParameterByName } from "helpers/QueryParameter";
let DeliveryDatePicker = class DeliveryDatePicker extends Vue {
    deliveryDates;
    fromLogin;
    pickup = false;
    selectedDate = null;
    dpSettings = {
        inline: true,
        enable: new Array()
    };
    mounted() {
        let enableDates = [];
        this.pickup = this.deliveryDates.currentOrderPickup;
        if (!this.hasDeliveryDates) {
            this.pickup = true;
        }
        if (this.pickup) {
            enableDates = this.deliveryDates.pickupDates;
        }
        else {
            enableDates = this.deliveryDates.deliveryDates;
        }
        this.dpSettings.enable = enableDates.map(x => new Date(x));
    }
    get showDatePicker() {
        if (!this.deliveryDates)
            return false;
        return (this.hasDeliveryDates)
            || (this.deliveryDates.pickupDates && this.deliveryDates.pickupDates.length > 0);
    }
    get hasDeliveryDates() {
        if (!this.deliveryDates)
            return false;
        return this.deliveryDates.deliveryDates && this.deliveryDates.deliveryDates.length > 0;
    }
    selectDate(date) {
        let dateIndex = 0;
        dateIndex = this.dpSettings.enable.findIndex(x => x.toDateString() == date[0].toDateString());
        let deliveryDate = new Date().toString();
        if (this.pickup) {
            deliveryDate = this.deliveryDates.pickupDates[dateIndex];
        }
        else {
            deliveryDate = this.deliveryDates.deliveryDates[dateIndex];
        }
        this.setDeliveryDate(deliveryDate, this.pickup);
    }
    setDeliveryDate(date, pickup) {
        LoaderService.createLoader({
            content: Vue.filter("translate")("webshop_modals_deliveryDate_selectLoadingText")
        });
        AuthService.setDeliveryDate(date, pickup)
            .then(() => {
            if (this.fromLogin) {
                let returnUrl = getParameterByName('ReturnUrl');
                if (!returnUrl || !returnUrl.startsWith('/webbshop') && !returnUrl.startsWith('/ipaper'))
                    returnUrl = '/webbshop/';
                window.location.href = returnUrl;
            }
            else {
                if (window.location.href.indexOf("forceDeliveryDate=true") >= 0) {
                    window.location.href = window.location.href.split('#')[0];
                }
                else {
                    window.location.reload();
                }
            }
        })
            .catch(err => {
            LoaderService.stop();
            ToastService.danger("<p>" + Vue.filter("translate")("webshop_modals_deliveryDate_submitErrorDefaultText") + "</p>");
        });
    }
    setPickup(pickup) {
        this.pickup = pickup;
        let enableDates = [];
        if (this.pickup) {
            enableDates = this.deliveryDates.pickupDates;
        }
        else {
            enableDates = this.deliveryDates.deliveryDates;
        }
        this.dpSettings.enable = enableDates.map(x => new Date(x));
    }
};
DeliveryDatePicker = __decorate([
    Component({
        components: {},
        props: {
            deliveryDates: Object,
            fromLogin: Boolean
        }
    })
], DeliveryDatePicker);
export default DeliveryDatePicker;
