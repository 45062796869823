var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loaded
        ? _c("inventory-header", {
            attrs: {
              list: _vm.list,
              loading: _vm.loading,
              setLoading: _vm.setLoading,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c("inventory-table", {
            attrs: {
              list: _vm.list,
              loading: _vm.loading,
              setLoading: _vm.setLoading,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }