var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
// require('inobounce');
import Vue from "vue";
import Component from "vue-class-component";
import { EventTypes } from "models/EventTypes";
import ToastList from "modules/basic-core/basic-toast/src";
import ModalList from "modules/basic-core/basic-modal/src";
import Spinner from "./shared/Spinner.vue";
import Loader from "./shared/Loader.vue";
import Login from "./login/Login.vue";
import LoginModal from "components/login/LoginModal.vue";
import ResetPassword from "./reset-password/ResetPassword.vue";
import ChangePassword from "./change-password/ChangePassword.vue";
import Inventory from "./inventory/Inventory.vue";
import Expiry from "./expiry/Expiry.vue";
import MainNavigation from "./header/main-navigation/MainNavigation.vue";
import HeaderLoginButton from "./header/HeaderLoginButton.vue";
import HeaderLoggedIn from "./header/HeaderLoggedIn.vue";
import Shop from "./shop/shop.vue";
import TodayOffersGrid from "./grid-components/TodayOffersGrid.vue";
import FavoriteCreateListButton from "./favorite/FavoriteCreateListButton.vue";
import CheckOutModalLoader from "./check-out/CheckOutModalLoader.vue";
import CheckOutBasketRender from "./check-out/CheckOutBasketRender.vue";
import AlcoholReportModal from "./min-sida/AlcoholReportModal.vue";
import Basket from "./basket/basket.vue";
import MiniCart from "./header/MiniCart.vue";
import ValidooModal from "./min-sida/ValidooModal.vue";
import Statistics from "./statistics/Statistics.vue";
import Orders from "./orders/Orders.vue";
import IconTrash from "./shared/IconTrash.vue";
import InventoryArchive from "./inventory/InventoryArchive.vue";
import Calculations from "./calculations/Calculations.vue";
import Ipaper from "./ipaper/Ipaper.vue";
import VendorCampaignPlanning from "components/vendor/campaign-planning/VendorCampaignPlanning.vue";
import VendorHomePage from "components/vendor/vendor-home-page/VendorHomePage.vue";
import { Settings } from "../config/Settings";
import { PermissionLevel } from "models/user/PermissionLevel";
// import "directives/ClickOutside";
import "directives/Autofocus";
import "directives/NumberFormat";
import "directives/SelectOnFocus";
import ScrollToTopButton from "./utility/ScrollToTopButton.vue";
import { DepictSharedIntegrationService } from "services/DepictSharedIntegrationService";
import DepictAfterBasketModal from "./header/DepictAfterBasketModal.vue";
let ScApp = class ScApp extends Vue {
    profileInfo;
    paths;
    mounted() {
        // if (window.location.href.indexOf('forceLogin=true') > -1) {
        //     this.$root.$emit(EventTypes.LoginModalOpen);
        // }
        if (window.location.href.indexOf("forceDeliveryDate=true") > -1) {
            this.$root.$emit(EventTypes.DeliveryDateModalOpen);
        }
        if (Settings.profileInfo.depictModuleEnabled) {
            DepictSharedIntegrationService.init(this);
        }
    }
    created() {
        Settings.profileInfo = this.profileInfo;
        if (this.profileInfo && this.profileInfo.permissionLevel) {
            Settings.permissionLevel = this.profileInfo.permissionLevel;
        }
        else {
            Settings.permissionLevel = PermissionLevel.None;
        }
        Settings.paths = this.paths;
        Settings.isIE =
            !!window.MSInputMethodContext &&
                !!document.documentMode;
    }
};
ScApp = __decorate([
    Component({
        components: {
            ToastList,
            ModalList,
            Spinner,
            Loader,
            Login,
            LoginModal,
            ResetPassword,
            ChangePassword,
            Inventory,
            Expiry,
            MainNavigation,
            HeaderLoginButton,
            HeaderLoggedIn,
            //CustomTag,
            Shop,
            TodayOffersGrid,
            FavoriteCreateListButton,
            CheckOutModalLoader,
            CheckOutBasketRender,
            AlcoholReportModal,
            Basket,
            MiniCart,
            ValidooModal,
            Statistics,
            Orders,
            IconTrash,
            InventoryArchive,
            Calculations,
            Ipaper,
            VendorCampaignPlanning,
            VendorHomePage,
            ScrollToTopButton,
            DepictAfterBasketModal,
        },
        props: {
            profileInfo: Object,
            paths: Object,
        },
    })
], ScApp);
export default ScApp;
Vue.component("v-style", {
    render: function (createElement) {
        return createElement("style", this.$slots.default);
    },
});
Vue.component("v-script", {
    render: function (createElement) {
        return createElement("script", this.$slots.default);
    },
});
