var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "div-table campaign-list" }, [
    _c("div", { staticClass: "table-header" }, [
      _c("div", { staticClass: "table-row" }, [
        _c("div", { staticClass: "table-column campaign-column-name" }, [
          _vm._v(
            _vm._s(
              _vm._f("translate")(
                "webshop_vendor_campaignPlanning_campaignList_columnName"
              )
            )
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "table-column" }, [
          _vm._v(
            _vm._s(
              _vm._f("translate")(
                "webshop_vendor_campaignPlanning_campaignList_columnSalesFrom"
              )
            )
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "table-column" }, [
          _vm._v(
            _vm._s(
              _vm._f("translate")(
                "webshop_vendor_campaignPlanning_campaignList_columnSalesTo"
              )
            )
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "table-column column-other" }, [
          _vm._v(
            _vm._s(
              _vm._f("translate")(
                "webshop_vendor_campaignPlanning_campaignList_columnActive"
              )
            )
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "table-body campaign-list-table-body" },
      [
        _vm._l(_vm.campaigns, function (campaign) {
          return _c(
            "div",
            {
              key: campaign.code,
              staticClass: "table-outer-row",
              class: {
                active: _vm.activeCampaign(campaign.code),
                disabled: !_vm.isValidPurchaseDeadline(campaign),
              },
            },
            [
              _c("div", { staticClass: "table-row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "table-column campaign-column-name small-third",
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "campaign-list-link",
                        attrs: {
                          to: {
                            name: "vendorCampaignDetails",
                            params: { campaignCode: campaign.code },
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(campaign.description) +
                            " (" +
                            _vm._s(campaign.code) +
                            ")"
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "table-column" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm._f("date")(campaign.purchaseStartingDate)) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "table-column" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm._f("date")(campaign.purchaseEndingDate)) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "table-column column-other" }, [
                  campaign.active
                    ? _c("i", { staticClass: "mdi mdi-checkbox-marked" })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "i",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            campaign.waitingForVendor > 0 &&
                            _vm.isValidPurchaseDeadline(campaign),
                          expression:
                            "campaign.waitingForVendor > 0 && isValidPurchaseDeadline(campaign)",
                        },
                      ],
                      staticClass: "campaign-line-waitingFor-icon mdi mdi-bell",
                      attrs: {
                        title: _vm.$options.filters.translate(
                          "webshop_vendor_campaignPlanning_campaignList_waitingForVendorIconText"
                        ),
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(campaign.waitingForVendor))])]
                  ),
                ]),
              ]),
            ]
          )
        }),
        _vm._v(" "),
        !_vm.loading && (!_vm.campaigns || _vm.campaigns.length == 0)
          ? _c("i", [
              _vm._v(
                _vm._s(
                  _vm._f("translate")(
                    "webshop_vendor_campaignPlanning_campaignList_emptyList"
                  )
                )
              ),
            ])
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }