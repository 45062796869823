var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modalLoginForm-step modalLoginForm-step2" },
    [
      _c(
        "form",
        {
          staticClass: "sla-container",
          attrs: { novalidate: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.accept.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "p",
            { staticClass: "text-center", staticStyle: { margin: "1rem" } },
            [_vm._v(_vm._s(_vm._f("translate")("webshop_login_accept_header")))]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "sla-content" },
            _vm._l(_vm.approvals, function (acceptance) {
              return _c(
                "div",
                { key: acceptance.id, staticClass: "sla-content-section" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "sla-acceptance",
                      class: { expanded: acceptance.expanded },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "toggler",
                          on: {
                            click: function ($event) {
                              acceptance.expanded = !acceptance.expanded
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              acceptance.expanded
                                ? _vm.$options.filters.translate(
                                    "webshop_login_accept_showLess"
                                  )
                                : _vm.$options.filters.translate(
                                    "webshop_login_accept_showMore"
                                  )
                            )
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("h2", { staticClass: "sla-title" }, [
                        _vm._v(_vm._s(acceptance.name)),
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        domProps: { innerHTML: _vm._s(acceptance.description) },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("label", { staticClass: "sla-checkbox" }, [
                    _vm._v(
                      "\r\n                    " +
                        _vm._s(
                          _vm._f("translate")(
                            "webshop_login_accept_checkboxLabel"
                          )
                        ) +
                        "\r\n                    "
                    ),
                    _c("input", {
                      attrs: {
                        name: "accept_" + acceptance.id,
                        type: "checkbox",
                        required: "",
                      },
                      domProps: {
                        checked:
                          _vm.selectedApprovalIds.indexOf(acceptance.id) !== -1,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.toggle(acceptance)
                        },
                      },
                    }),
                  ]),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "sla-footer" },
            [
              _c("loading-button", {
                attrs: {
                  "icon-class": "mdi-check",
                  text: _vm.$options.filters.translate(
                    "webshop_login_accept_acceptBtn"
                  ),
                  "loading-text": _vm.$options.filters.translate(
                    "webshop_login_button_login_loadingText"
                  ),
                  loading: _vm.loading,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "xsmall text-center",
              staticStyle: {
                margin: "1rem 0 -2rem 0",
                display: "inline-block",
              },
            },
            [
              _vm._v(
                _vm._s(_vm._f("translate")("webshop_login_accept_disclaimer"))
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }