var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "shop-item-icons" }, [
    _vm.product.markings.storage == "0"
      ? _c("span", {
          staticClass: "icon-cool",
          attrs: {
            title: _vm.$options.filters.translate("webshop_icons_cool"),
          },
        })
      : _vm.product.markings.storage == "1"
      ? _c("span", {
          staticClass: "icon-frost",
          attrs: {
            title: _vm.$options.filters.translate("webshop_icons_frost"),
          },
        })
      : _vm.product.markings.storage == "2"
      ? _c("span", {
          staticClass: "appicon appicon-colonial",
          attrs: {
            title: _vm.$options.filters.translate("webshop_icons_colonial"),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.hasCampaign && _vm.priceTypeCampaign
      ? _c("span", {
          staticClass: "appicon appicon-campaign",
          attrs: {
            title: _vm.$options.filters.translate("webshop_icons_hasCampaign"),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.swanLabel
      ? _c("span", {
          staticClass: "appicon appicon-swan-label",
          attrs: {
            title: _vm.$options.filters.translate("webshop_icons_swanLabel"),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.sojaFree
      ? _c("span", {
          staticClass: "appicon appicon-soja-free",
          attrs: {
            title: _vm.$options.filters.translate("webshop_icons_sojaFree"),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.freeOfEggs
      ? _c("span", {
          staticClass: "appicon appicon-eggs-free",
          attrs: {
            title: _vm.$options.filters.translate("webshop_icons_freeOfEggs"),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.peaProteinFree
      ? _c("span", {
          staticClass: "appicon appicon-pea-protein-free",
          attrs: {
            title: _vm.$options.filters.translate(
              "webshop_icons_peaProteinFree"
            ),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.freeOfProtein
      ? _c("span", {
          staticClass: "appicon appicon-protein-free",
          attrs: {
            title: _vm.$options.filters.translate(
              "webshop_icons_freeOfProtein"
            ),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.freeOfNut
      ? _c("span", {
          staticClass: "appicon appicon-nuts-free",
          attrs: {
            title: _vm.$options.filters.translate("webshop_icons_freeOfNut"),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.freeOfAlmonds
      ? _c("span", {
          staticClass: "appicon appicon-almonds-free",
          attrs: {
            title: _vm.$options.filters.translate(
              "webshop_icons_freeOfAlmonds"
            ),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.freeOfPeanuts
      ? _c("span", {
          staticClass: "appicon appicon-peanuts-free",
          attrs: {
            title: _vm.$options.filters.translate(
              "webshop_icons_freeOfPeanuts"
            ),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.kravLabel
      ? _c("span", {
          staticClass: "appicon appicon-krav",
          attrs: {
            title: _vm.$options.filters.translate("webshop_icons_krav"),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.fromSweden
      ? _c("span", {
          staticClass: "custom-icon__se",
          attrs: {
            title: _vm.$options.filters.translate("webshop_icons_fromSweden"),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.swedishBird
      ? _c("span", {
          staticClass: "custom-icon__swedish-bird",
          attrs: {
            title: _vm.$options.filters.translate("webshop_icons_swedishBird"),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.swedishSeal
      ? _c("span", {
          staticClass: "custom-icon__swedish-seal",
          attrs: {
            title: _vm.$options.filters.translate("webshop_icons_swedishSeal"),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.meatfromSweden
      ? _c("span", {
          staticClass: "custom-icon__meat-from-sweden",
          attrs: {
            title: _vm.$options.filters.translate(
              "webshop_icons_meatFromSweden"
            ),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.lactoseFree
      ? _c("span", {
          staticClass: "custom-icon__lactose-free",
          attrs: {
            title: _vm.$options.filters.translate("webshop_icons_lactoseFree"),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.freeOfMilk
      ? _c("span", {
          staticClass: "appicon appicon-free-of-milk",
          attrs: {
            title: _vm.$options.filters.translate("webshop_icons_freeOfMilk"),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.milkProteinFree
      ? _c("span", {
          staticClass: "appicon appicon-milk-protein-free",
          attrs: {
            title: _vm.$options.filters.translate(
              "webshop_icons_milkProteinFree"
            ),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.sugarfree
      ? _c("span", {
          staticClass: "appicon appicon-sugar-free",
          attrs: {
            title: _vm.$options.filters.translate("webshop_icons_sugarfree"),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.fairtrade
      ? _c("span", {
          staticClass: "custom-icon__fairtrade",
          attrs: {
            title: _vm.$options.filters.translate("webshop_icons_fairtrade"),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.glutenFree
      ? _c("span", {
          staticClass: "custom-icon__gluten-free",
          attrs: {
            title: _vm.$options.filters.translate("webshop_icons_glutenFree"),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.vegan
      ? _c("span", {
          staticClass: "custom-icon__vegan",
          attrs: {
            title: _vm.$options.filters.translate("webshop_icons_vegan"),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.ascMarkFish
      ? _c("span", {
          staticClass: "custom-icon__asc",
          attrs: {
            title: _vm.$options.filters.translate("webshop_icons_ascMarkFish"),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.fsc
      ? _c("span", {
          staticClass: "appicon appicon-fsc",
          attrs: { title: _vm.$options.filters.translate("webshop_icons_fsc") },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.mscMarkFish
      ? _c("span", {
          staticClass: "custom-icon__msc",
          attrs: {
            title: _vm.$options.filters.translate("webshop_icons_mscMarkFish"),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.keyholeLabel
      ? _c("span", {
          staticClass: "icon-keyhole",
          attrs: {
            title: _vm.$options.filters.translate("webshop_icons_keyhole"),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.wholeGrain
      ? _c("span", {
          staticClass: "icon-wholegrain",
          attrs: {
            title: _vm.$options.filters.translate("webshop_icons_wholegrain"),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.organic
      ? _c("span", {
          staticClass: "custom-icon__eu-organic",
          attrs: {
            title: _vm.$options.filters.translate("webshop_icons_organic"),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.halal
      ? _c("span", {
          staticClass: "appicon appicon-halal",
          attrs: {
            title: _vm.$options.filters.translate("webshop_icons_halal"),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.localProduct
      ? _c("span", {
          staticClass: "appicon appicon-local-product",
          attrs: {
            title: _vm.$options.filters.translate("webshop_icons_localProduct"),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.sellOut
      ? _c("span", {
          staticClass: "custom-icon__sell-out",
          attrs: {
            title: _vm.$options.filters.translate("webshop_icons_sellOut"),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.organicOther && !_vm.product.markings.organic
      ? _c("span", {
          staticClass: "icon-organic2",
          attrs: {
            title: _vm.$options.filters.translate("webshop_icons_organic"),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.weight
      ? _c("span", {
          staticClass: "icon-weight",
          attrs: {
            title: _vm.$options.filters.translate("webshop_icons_weight"),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.procure
      ? _c("span", {
          staticClass: "icon-procuregoods",
          attrs: {
            title: _vm.$options.filters.translate(
              "webshop_icons_procuregoods",
              this.product.markings.leadTime
            ),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.productLink
      ? _c(
          "a",
          {
            staticClass: "icon-info show-foodinfo tooltip",
            on: {
              click: function ($event) {
                return _vm.open()
              },
            },
          },
          [
            _vm.product.markings.image
              ? _c("span", [
                  _c("img", { attrs: { src: _vm.product.markings.image } }),
                ])
              : _vm._e(),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.agreement
      ? _c("span", {
          staticClass: "appicon appicon-agreement",
          attrs: {
            title: _vm.$options.filters.translate("webshop_icons_agreement"),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.supplierApproved
      ? _c("span", {
          staticClass: "icon-approved-supplier",
          attrs: {
            title: _vm.$options.filters.translate(
              "webshop_icons_approvedSupplier"
            ),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.product.markings.systemköp &&
    _vm.Settings.profileInfo.isMemberOfSystemköp
      ? _c("span", {
          staticClass: "appicon appicon-systemköp",
          attrs: {
            title: _vm.$options.filters.translate("webshop_icons_systemköp"),
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.checkIfDeadlineProduct
      ? _c(
          "span",
          {
            staticClass: "deadline_product_icon",
            attrs: {
              title: _vm.$options.filters.translate(
                "webshop_search_search_result_stoptime",
                _vm.$options.filters.date(
                  _vm.product.markings.canBeDeliveredBy,
                  "dddd L"
                ),
                _vm.$options.filters.timespan(_vm.product.markings.stopTime)
              ),
            },
          },
          [
            _c("span", { staticClass: "icon-deadline" }),
            _vm._v(" "),
            _c("span", { staticClass: "time" }, [
              _vm._v(
                _vm._s(
                  _vm._f("timespan")(_vm.product.markings.stopTime, "HH:mm")
                )
              ),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "days" }, [
              _vm._v(
                _vm._s(
                  _vm._f("date")(_vm.product.markings.canBeDeliveredBy, "ddd")
                )
              ),
            ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.isExpiryProduct && _vm.hasBestBefore
      ? _c(
          "span",
          {
            staticClass: "deadline_product_icon sell-out-line-deadline",
            attrs: {
              title: _vm.$options.filters.translate(
                "webshop_search_search_result_bestBefore",
                _vm.$options.filters.date(_vm.product.expiry.date, "dddd L")
              ),
            },
          },
          [
            _c("span", { staticClass: "icon-deadline" }),
            _vm._v(" "),
            _c("span", { staticClass: "time" }, [
              _vm._v(
                _vm._s(
                  _vm.daysBetweenDates(
                    new Date(_vm.product.expiry.date),
                    new Date()
                  )
                ) + " d"
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }