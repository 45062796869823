var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from 'vue-property-decorator';
let SuggestionTooltip = class SuggestionTooltip extends Vue {
    product;
    selectedUnit;
    amount;
    disabled;
    sawSuggestion = false;
    closedSuggestion = true;
    onSelectedUnitChanged(val) {
        if (val) {
            this.showSuggestion();
        }
    }
    onAmountChanged(val) {
        if (val) {
            this.showSuggestion();
        }
    }
    getNextUnit(product, selectedUnit, amount) {
        let next = null;
        let selectedUnitObj = null;
        product.units.forEach((unit) => {
            if (unit.name === selectedUnit)
                selectedUnitObj = unit;
        });
        product.units.forEach((unit) => {
            let remainder = 0;
            if (amount - unit.quantityPerUnit >= 0 && unit.name !== selectedUnit && selectedUnitObj.quantityPerUnit < unit.quantityPerUnit) {
                if (!next && remainder <= amount - unit.quantityPerUnit) {
                    next = { ...unit };
                }
                remainder = amount - unit.quantityPerUnit;
            }
        });
        return next;
    }
    showSuggestion() {
        if (!this.amount || this.product.units == null || this.disabled)
            return;
        let nextUnit = this.getNextUnit(this.product, this.selectedUnit, this.amount);
        if (this.product.units.length > 1 && !this.sawSuggestion && nextUnit) {
            this.sawSuggestion = true;
            this.closedSuggestion = false;
            setTimeout(() => { this.closedSuggestion = true; }, 3000);
        }
    }
};
__decorate([
    Watch('selectedUnit')
], SuggestionTooltip.prototype, "onSelectedUnitChanged", null);
__decorate([
    Watch('amount')
], SuggestionTooltip.prototype, "onAmountChanged", null);
SuggestionTooltip = __decorate([
    Component({
        components: {},
        props: {
            product: Object,
            selectedUnit: String,
            amount: [String, Number],
            disabled: Boolean
        }
    })
], SuggestionTooltip);
export default SuggestionTooltip;
