var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { SearchService } from "services/SearchService";
import { ToastService } from "services/ToastService";
import { InventoryService } from "services/InventoryService";
import { EventTypes } from "models/EventTypes";
import { ModalSettings } from 'models/utility/ModalSettings';
import InventoryEditPrice from "./partials/InventoryEditPrice.vue";
import InputNumber from "../shared/InputNumber.vue";
import { ItemStatus } from "models/product/ItemStatus";
import { Service } from "services/Service";
import IconTrash from '../shared/IconTrash.vue';
import { PermissionService } from "services/PermissionService";
import { PermissionLevel } from "models/user/PermissionLevel";
import { PriceType } from "models/product/PriceType";
import ShopItemIcons from "components/shop/partials/ShopItemIcons.vue";
import HighlightMarkings from "components/shared/HighlightMarkings.vue";
let InventoryTableProduct = class InventoryTableProduct extends Vue {
    line;
    list;
    group;
    showGroupList = false;
    amount = 0;
    invalidAmount = false;
    sort = 0;
    loading;
    setLoading;
    selectedUnit = null;
    enterUpdateAmountEvent = false;
    itemStatus = ItemStatus;
    successfullyUpdated = false;
    disableAmount = false;
    showPrice = PermissionService.hasPermission(PermissionLevel.ShowPrice);
    priceType = PriceType;
    mounted() {
        this.refreshAmount();
        this.refreshUnit();
        this.sort = this.line.sorting;
    }
    beforeDestroy() {
        document.body.removeEventListener("click", this.clickOutsideGroupSelectEvent);
    }
    get totalPrice() {
        return this.line.amount * this.line.pricePrUnit;
    }
    get selectedUnitObj() {
        if (!this.line)
            return null;
        var units = this.line.units;
        if (!units)
            return null;
        return units.find(x => x.name == this.selectedUnit);
    }
    onAmountChanged(val, oldVal) {
        this.refreshAmount();
    }
    refreshAmount() {
        this.amount = this.line.amount;
    }
    refreshUnit() {
        this.selectedUnit = this.line.unit;
    }
    openEditProduct(list, line) {
        this.$root.$emit(EventTypes.InventoryModalOwnProductEditOpen, list, line);
    }
    updateAmount(el, pressedEnter = false) {
        if (this.disableAmount)
            return;
        this.disableAmount = true;
        if (pressedEnter) {
            this.focusNextAmount();
        }
        if (typeof this.amount === "string") {
            if (this.amount.trim().length === 0) {
                this.amount = this.line.amount;
            }
            else {
                ToastService.danger(Vue.filter("translate")("webshop_inventory_table_product_toasts_updatedAmount_invalidFormat"));
                this.invalidAmount = true;
                this.disableAmount = false;
                return;
            }
        }
        if (this.amount === null) {
            this.amount = this.line.amount;
        }
        if (this.amount != this.line.amount) {
            var tempObj = Object.assign({}, this.line);
            tempObj.amount = this.amount;
            InventoryService.updateLine(this.line.id, tempObj, false)
                .then(line => {
                this.successfullyUpdated = true;
                this.updateLineShowed(line);
                setTimeout(() => {
                    this.successfullyUpdated = false;
                }, 2000);
                this.disableAmount = false;
            })
                .catch(err => {
                this.invalidAmount = true;
                this.disableAmount = false;
            });
        }
        else {
            this.disableAmount = false;
        }
    }
    updateLineShowed(line) {
        this.line.unit = line.unit;
        this.line.pricePrUnit = line.pricePrUnit;
        this.line.amount = line.amount;
        this.line.editedPrice = line.editedPrice;
        this.line.editedUnit = line.editedUnit;
        this.refreshUnit();
        this.refreshAmount();
        this.setLoading(false);
        this.$root.$emit(EventTypes.InventoryLineUpdated, this.line);
    }
    changeUnit() {
        if (this.line.editedPrice) {
            this.openEditedPriceAskModal();
        }
        this.setLoading(true);
        var tempObj = Object.assign({}, this.line);
        tempObj.unit = this.selectedUnit;
        InventoryService.updateLine(this.line.id, tempObj, false)
            .then(line => {
            this.updateLineShowed(line);
            this.line.editedUnit = true;
            ToastService.success(Vue.filter("translate")("webshop_inventory_table_product_toasts_changeUnit"));
        })
            .catch(err => {
            this.setLoading(false);
            ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
        });
    }
    openEditedPriceAskModal() {
        this.$root.$emit(EventTypes.InventoryModalConfirmOpen, new ModalSettings({
            title: Vue.filter('translate')('webshop_inventory_lists_line_change_price_modal'),
            content: '<p>' + Vue.filter('translate')('webshop_inventory_lists_line_change_price_modal_content') + '</p>',
            cancelButtonText: Vue.filter('translate')('webshop_general_no'),
            acceptButtonText: Vue.filter('translate')('webshop_general_yes'),
            acceptButtonClass: 'ui-button-cancel',
            onAccept: () => {
                return InventoryService.updateLine(this.line.id, this.line, true)
                    .then(line => {
                    this.updateLineShowed(line);
                    ToastService.success(Vue.filter("translate")("webshop_inventory_lists_line_price_updated"));
                    this.$root.$emit(EventTypes.InventoryModalConfirmClose);
                })
                    .catch(err => {
                    this.setLoading(false);
                    ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
                });
            }
        }));
    }
    toggleGroupList() {
        this.showGroupList = !this.showGroupList;
        if (this.showGroupList) {
            document.body.addEventListener("click", this.clickOutsideGroupSelectEvent);
        }
        else {
            document.body.removeEventListener("click", this.clickOutsideGroupSelectEvent);
        }
    }
    selectGroup(groupId) {
        this.setLoading(true);
        var tempObj = Object.assign({}, this.line);
        var oldGroupId = this.line.groupId;
        tempObj.groupId = groupId;
        InventoryService.updateLine(this.line.id, tempObj, false)
            .then(line => {
            this.$root.$emit(EventTypes.InventoryLineChangedGroup, line, oldGroupId, groupId);
            ToastService.success(Vue.filter("translate")("webshop_inventory_table_product_toasts_movedGroup"));
        })
            .catch(err => {
            this.setLoading(false);
            ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
        });
    }
    openDeleteComfirm() {
        this.$root.$emit(EventTypes.InventoryModalProductDeleteOpen, this.list, this.line);
    }
    clickOutsideGroupSelectEvent(el) {
        const wrapper = this.$refs["group-select-wrapper"];
        if (!wrapper || wrapper.contains(el.target))
            return;
        this.toggleGroupList();
    }
    openFoodInfoModal() {
        SearchService.openFoodInfo(this, this.line.productId);
    }
    resetLine() {
        this.setLoading(true);
        InventoryService.resetLineToDefault(this.line.id).then(line => {
            this.updateLineShowed(line);
            ToastService.success(Vue.filter("translate")("webshop_inventory_table_product_toasts_resetLine"));
        }, err => {
            this.setLoading(false);
            ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
        });
    }
    updateSorting(el, pressedEnter) {
        if (pressedEnter) {
            this.focusNextSort();
        }
        if (this.sort == this.line.sorting)
            return;
        this.line.sorting = this.sort;
        this.setLoading(true);
        InventoryService.updateLine(this.line.id, this.line, false)
            .then(line => {
            this.updateLineShowed(line);
            ToastService.success(Vue.filter("translate")("webshop_inventory_table_product_toasts_updatedSorting"));
        })
            .catch(err => {
            this.setLoading(false);
            ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
        });
    }
    focusNextAmount() {
        Service.focusNextOnEnter(this.$refs['inputAmountParent'], 'input.item-amount-input[tabindex="0"]', 'inputAmount');
    }
    focusNextSort() {
        Service.focusNextOnEnter(this.$refs['inputSortParent'], 'input.item-amount-input[tabindex="1"]', 'inputSort');
    }
    onFocusAmount(el) {
        this.resetInvalidAmount();
        /* if (this.amount == 0 && el && el.target) {
            el.target.value = '';
        } */
    }
    resetInvalidAmount() {
        this.invalidAmount = false;
    }
    get priceTypeCampaign() {
        return this.line.units != null && this.line.units.find(x => x.priceType == this.priceType.CampaignPrice) != undefined;
    }
};
__decorate([
    Watch("line.amount")
], InventoryTableProduct.prototype, "onAmountChanged", null);
InventoryTableProduct = __decorate([
    Component({
        components: {
            ShopItemIcons,
            InventoryEditPrice,
            InputNumber,
            IconTrash,
            HighlightMarkings
        },
        props: {
            line: Object,
            list: Object,
            group: Object,
            loading: Boolean,
            setLoading: Function
        }
    })
], InventoryTableProduct);
export default InventoryTableProduct;
