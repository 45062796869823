var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import NavLink from './NavLink.vue';
import { Settings } from '../../../config/Settings';
import { MenuTypes } from 'models/utility/MenuTypes';
let MobileNavigation = class MobileNavigation extends Vue {
    menu;
    activeIndex = null;
    settings = Settings;
    menuTypes = MenuTypes;
    asssetsBaseLink = "/app/dist2/assets/";
    hideMobile() {
        this.$emit('input', false);
    }
    showDropDown(index) {
        if (index === this.activeIndex || (this.activeIndex !== null && this.activeIndex !== index)) {
            this.activeIndex = null;
        }
        else {
            this.activeIndex = index;
        }
    }
    createLink(link) {
        if (link.Type === this.menuTypes.webShopPage || link.Url.startsWith('http', 0)) {
            return link.Url;
        }
        return this.settings.menuBaseLink + link.Url;
    }
};
MobileNavigation = __decorate([
    Component({
        components: {
            NavLink
        },
        props: {
            menu: Object
        }
    })
], MobileNavigation);
export default MobileNavigation;
