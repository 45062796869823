var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "fav-list-select-li",
      on: {
        click: function ($event) {
          return _vm.addOrDeleteFunction()
        },
      },
    },
    [
      _c("a", { staticClass: "fav-list-toggle" }, [
        !_vm.list.productExists
          ? _c("i", { staticClass: "icon-playlist-add add-ico" })
          : _vm._e(),
        _vm._v(" "),
        _vm.list.productExists
          ? _c("i", { staticClass: "mdi mdi-trash-can remove-ico" })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "span",
          { style: { color: _vm.list.productExists ? "#79c249" : "" } },
          [_vm._v(_vm._s(_vm.list.listName))]
        ),
        _vm._v(" "),
        _vm.loading ? _c("i", { staticClass: "inline-spinner" }) : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }