var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import VueRouter from "vue-router";
import { ExpiryService } from "services/ExpiryService";
import { ToastService } from "services/ToastService";
import ShopGalleryItem from "components/shop/partials/ShopGalleryItem.vue";
import ShopMultipleProductsFooter from "components/shop/partials/ShopMultipleProductsFooter.vue";
import { EventTypes } from "models/EventTypes";
import { SearchService } from "services/SearchService";
import { GAService } from "services/GAService";
import { SearchSortBy } from 'models/search/SearchSortBy';
import { KeyValue } from 'models/utility/KeyValue';
import { OrderlineUISourceTypes } from 'models/order/OrderlineUISourceTypes';
const router = new VueRouter({
    mode: "history"
});
let Expiry = class Expiry extends Vue {
    loading = false;
    loaded = false;
    filter = "";
    results = [];
    itemsWithAmount = [];
    selectedSortBy = null;
    OrderlineUISourceTypes = OrderlineUISourceTypes;
    mounted() {
        this.loadList();
        this.$root.$on(EventTypes.ShopAddedProductsToBasket, this.emptyArrayWithAmountItems);
        this.$root.$on(EventTypes.ShopMultipleItemsItemAdded, this.addItemAmount);
        this.$root.$on(EventTypes.ShopMultipleItemsItemRemoved, this.removeItemAmount);
        this.$store.subscribe(mutation => {
            if (mutation.type === 'Orders/setActiveOrder') {
                this.loadList();
            }
        });
    }
    beforeDestroy() {
        this.$root.$off(EventTypes.ShopAddedProductsToBasket);
        this.$root.$off(EventTypes.ShopMultipleItemsItemAdded);
        this.$root.$off(EventTypes.ShopMultipleItemsItemRemoved);
    }
    get filteredList() {
        let list = this.results;
        if (this.filter) {
            const searchIncludes = (prop, query) => {
                if (!prop)
                    return false;
                return prop.toLowerCase().includes(query.toLowerCase());
            };
            list = this.results.filter(x => {
                return (searchIncludes(x.productId, this.filter) ||
                    searchIncludes(x.description, this.filter) ||
                    searchIncludes(x.brand, this.filter));
            });
            this.updateItemsWithAmountRef(list);
        }
        if (this.selectedSortBy) {
            list = this.sortResult(list);
        }
        return list;
    }
    updateItemsWithAmountRef(products) {
        this.itemsWithAmount.forEach(x => {
            let productIndex = products.findIndex(y => y.productId == x.product.productId);
            if (productIndex > -1) {
                x.product = products[productIndex];
            }
            x.product.addedAmount = x.amount;
        });
        SearchService.itemsWithAmount = this.itemsWithAmount;
    }
    loadList() {
        this.loading = true;
        ExpiryService.getExpiryProducts()
            .then(data => {
            this.results = data;
            this.loading = false;
            this.loaded = true;
            this.selectedSortBy = SearchSortBy.Relevance;
            if (this.results && this.results.length > 0) {
                GAService.productImpressions(this.results);
            }
        })
            .catch(err => {
            this.loading = false;
            ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
        });
    }
    emptyArrayWithAmountItems() {
        this.itemsWithAmount.forEach(x => {
            let product = this.results.find(y => y.productId == x.product.productId);
            if (product != undefined) {
                product.inBasket = true;
                product.clearAmount = true;
                product.addedAmount = null;
            }
        });
        this.itemsWithAmount = [];
        SearchService.itemsWithAmount = this.itemsWithAmount;
    }
    addItemAmount(event) {
        if (!event.amount || event.amount <= 0) {
            this.removeItemAmount(event.product.productId);
        }
        else {
            const index = this.itemsWithAmount.findIndex(x => x.product.productId == event.product.productId);
            if (index > -1) {
                this.itemsWithAmount[index].amount = event.amount;
            }
            else {
                this.itemsWithAmount.push(event);
            }
        }
        SearchService.itemsWithAmount = this.itemsWithAmount;
    }
    removeItemAmount(event) {
        const index = this.itemsWithAmount.findIndex(x => x.product.productId == event);
        if (index > -1) {
            this.itemsWithAmount.splice(index, 1);
        }
    }
    get sortByFilters() {
        return Object.keys(SearchSortBy)
            .reduce((newArray, key) => {
            if (isNaN(Number(key)) && SearchSortBy[key] != SearchSortBy.Favorite) {
                let value = SearchSortBy[key];
                let label = Vue.filter('translate')('webshop_search_search_sortBy_' + key);
                newArray.push(KeyValue.create(label, value));
            }
            return newArray;
        }, []);
    }
    sortResult(list) {
        let res = Object.assign([], list);
        let semiNumberComparer = (s1, s2) => {
            let n1, n2, b1, b2;
            n1 = Number(s1);
            n2 = Number(s2);
            if (!Number.isNaN(n1) && !Number.isNaN(n2)) {
                if (n1 > n2)
                    return 1;
                if (n1 < n2)
                    return -1;
                if (n1 == n2)
                    return 0;
            }
            if (b1 && Number.isNaN(n2))
                return -1;
            if (Number.isNaN(n1) && b2)
                return 1;
            return s1 < s2 ? -1 : 1;
        };
        return res.sort((a, b) => {
            switch (this.selectedSortBy) {
                case SearchSortBy.Description:
                    return a.description < b.description ? -1 : 1;
                case SearchSortBy.DescriptionDesc:
                    return b.description < a.description ? -1 : 1;
                case SearchSortBy.ProductId:
                    return semiNumberComparer(a.productId, b.productId);
                case SearchSortBy.ProductIdDesc:
                    return semiNumberComparer(b.productId, a.productId);
                case SearchSortBy.ComparisonPrice:
                    return Number(a.comparisonPrice) - Number(b.comparisonPrice);
                case SearchSortBy.ComparisonPriceDesc:
                    return Number(b.comparisonPrice) - Number(a.comparisonPrice);
                case SearchSortBy.ProductGroup:
                    return a.postingGroupName < b.postingGroupName ? -1 : 1;
                case SearchSortBy.ProductGroupDesc:
                    return b.postingGroupName < a.postingGroupName ? -1 : 1;
                default:
                    return 0;
            }
        });
    }
};
Expiry = __decorate([
    Component({
        components: {
            ShopGalleryItem,
            ShopMultipleProductsFooter
        }
    })
], Expiry);
export default Expiry;
