var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "div-table" },
      [
        _vm.loading
          ? [_c("loader", { attrs: { size: "large" } })]
          : [
              _vm.products.length > 0
                ? [
                    _c(
                      "div",
                      { staticClass: "table-body" },
                      [
                        _c("div", { staticClass: "table-outer-row" }, [
                          _c(
                            "div",
                            { staticClass: "table-header column-headers" },
                            [
                              _c("div", { staticClass: "table-row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "table-column product-id-column",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("translate")(
                                          "webshop_general_productNo"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "table-column description-column",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("translate")(
                                          "webshop_general_productDescription"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "table-column brand-column" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("translate")(
                                          "webshop_general_productBrand"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "table-column unit-column",
                                    class: { "open-shop": _vm.isOpenShop },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("translate")(
                                          "webshop_general_unit"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                !_vm.isOpenShop
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "table-column price-column",
                                      },
                                      [
                                        _vm.showPrice
                                          ? [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("translate")(
                                                    "webshop_search_search_view_list_headers_price"
                                                  )
                                                )
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.isOpenShop
                                  ? _c("div", {
                                      staticClass: "table-column amount-column",
                                    })
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.products, function (product, index) {
                          return [
                            _c("shop-product-list-item", {
                              key: product.productId,
                              attrs: {
                                loading: _vm.loading,
                                product: product,
                                position: index + 1,
                                "ui-source":
                                  _vm.OrderlineUISourceTypes.ShopList,
                              },
                            }),
                            _vm._v(" "),
                            product.AltProduct
                              ? _c("shop-product-list-item", {
                                  key:
                                    product.productId +
                                    "_" +
                                    product.AltProduct.productId,
                                  attrs: {
                                    isAltProduct: true,
                                    loading: _vm.loading,
                                    product: product.AltProduct,
                                    position: index + 1,
                                    "ui-source":
                                      _vm.OrderlineUISourceTypes.ShopList,
                                  },
                                })
                              : _vm._e(),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]
                : [
                    _c(
                      "div",
                      { staticClass: "table-row search-wrap" },
                      [
                        _vm.searching
                          ? [
                              _vm.currentListId == null
                                ? _c("p", { staticClass: "no-result-text" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("translate")(
                                          "webshop_search_search_result_empty_state_search"
                                        )
                                      )
                                    ),
                                  ])
                                : _c("p", { staticClass: "no-result-text" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("translate")(
                                          "webshop_search_search_result_empty_state_favorite_list"
                                        )
                                      )
                                    ),
                                  ]),
                            ]
                          : [
                              _vm.currentListId == null
                                ? _c("p", { staticClass: "no-result-text" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("translate")(
                                          "webshop_search_search_result_empty_state_no_products_search"
                                        )
                                      )
                                    ),
                                  ])
                                : _c("p", { staticClass: "no-result-text" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("translate")(
                                          "webshop_search_search_result_empty_state_no_products_favorite_list"
                                        )
                                      )
                                    ),
                                  ]),
                            ],
                      ],
                      2
                    ),
                  ],
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }