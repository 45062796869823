var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ui-modal-content" }, [
      _c("h3", { staticClass: "ui-modal-header" }, [
        _vm._v(
          _vm._s(
            _vm._f("translate")(
              "webshop_calculations_modals_createCalculation_title"
            )
          )
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.loading
            ? [_c("loader", { attrs: { size: "large" } })]
            : [
                _c(
                  "form",
                  {
                    staticClass: "ui-form",
                    attrs: { name: "calculationModal" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.create()
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "ui-form-fieldwrapper" }, [
                      _c("label", { attrs: { for: "name" } }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("translate")(
                                "webshop_calculations_modals_createCalculation_labelName"
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.name,
                              expression: "name",
                            },
                          ],
                          staticClass: "input-calculation",
                          attrs: {
                            type: "text",
                            maxlength: "35",
                            autocomplete: "off",
                            name: "name",
                            required: "",
                            tabindex: "1",
                          },
                          domProps: { value: _vm.name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.name = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "ui-form-fieldwrapper" }, [
                      _c("label", { attrs: { for: "name" } }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("translate")(
                                "webshop_calculations_modals_createCalculation_labelType"
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectedType,
                                expression: "selectedType",
                              },
                            ],
                            staticClass: "input-calculation",
                            attrs: { tabindex: "-1" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.selectedType = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          _vm._l(_vm.calculationTypes, function (calcType) {
                            return _c(
                              "option",
                              {
                                key: "calculationType" + calcType,
                                domProps: {
                                  value: _vm.CalculationType[calcType],
                                },
                              },
                              [_vm._v(_vm._s(calcType))]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "modal-buttons-wrapper" },
                      [
                        _c("loading-button", {
                          attrs: {
                            "icon-class": "mdi-content-save",
                            text: _vm.$options.filters.translate(
                              "webshop_calculations_modals_createCalculation_buttonSave"
                            ),
                            "loading-text": _vm.$options.filters.translate(
                              "webshop_calculations_modals_createCalculation_buttonSave"
                            ),
                            loading: _vm.loading,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }