import { Api } from './Api';
export class CheckOutApi {
    static client = Api.client('/api/Checkout/');
    static getCheckoutCampaigns(code) {
        return this.client.get('GetCheckoutCampaigns', {
            params: { code: code }
        });
    }
    static setRating(id, rating) {
        return this.client.post('SetRating', { id: id, rating: rating });
    }
    static setComment(id, comment) {
        return this.client.post('SetComment', { id: id, comment: comment });
    }
    static declineRating(id) {
        return this.client.delete('DeclineRating', { params: { id: id } });
    }
    static declineCheckout(id) {
        return this.client.post('DeclineCheckout/' + id);
    }
}
