export class KeyValue {
    key;
    value;
    static create(key, value) {
        var keyValue = new KeyValue();
        keyValue.key = key;
        keyValue.value = value;
        return keyValue;
    }
}
