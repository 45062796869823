export class ItemMarkings {
    fairtrade;
    swanLabel;
    keyholeLabel;
    gmoLabel;
    mscMarkFish;
    euMarkOrganic;
    sugarfree;
    lactoseFree;
    glutenFree;
    milkProteinFree;
    sojaFree;
    freeOfEggs;
    freeOfMilk;
    peaProteinFree;
    freeOfProtein;
    freeOfNut;
    freeOfAlmonds;
    freeOfPeanuts;
    kravLabel;
    storage;
    wholeGrain;
    organic;
    organicOther;
    weight;
    procure;
    productLink;
    agreement;
    supplierApproved;
    hasCampaign;
    stopTime;
    leadTime;
    canBeDeliveredBy;
    image;
    fromSweden;
    swedishBird;
    swedishSeal;
    meatfromSweden;
    fsc;
    vegan;
    ascMarkFisk;
    systemköp;
    halal;
    localProduct;
    sellOut;
}
