var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ui-modal-content" }, [
      _c("h3", { staticClass: "ui-modal-header" }, [
        _vm._v(
          _vm._s(_vm._f("translate")("webshop_inventoryArchive_modal_title"))
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.loading
            ? [_c("loader", { attrs: { size: "large" } })]
            : [
                _c(
                  "form",
                  {
                    staticClass: "ui-form",
                    attrs: { name: "archiveModal", novalidate: "" },
                    on: {
                      submit: function ($event) {
                        return $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "ui-form-fieldwrapper" }, [
                      _c("label", { attrs: { for: "name" } }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("translate")("webshop_general_name"))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.inventoryArchive.name,
                              expression: "inventoryArchive.name",
                            },
                          ],
                          attrs: {
                            type: "text",
                            maxlength: "20",
                            autocomplete: "off",
                            name: "name",
                          },
                          domProps: { value: _vm.inventoryArchive.name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.inventoryArchive,
                                "name",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-buttons-wrapper" }, [
                      _c(
                        "button",
                        {
                          staticClass: "ui-button ui-button-accept",
                          attrs: { disabled: _vm.loading },
                          on: {
                            click: function ($event) {
                              return _vm.create()
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("translate")(
                                "webshop_inventoryArchive_modal_btnSave"
                              )
                            )
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ],
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }