var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-center" }, [
    _c("h1", { staticClass: "header-medium" }, [
      _vm._v(_vm._s(_vm._f("translate")("webshop_login_resetPassword_header"))),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "columns large-centered large-4" }, [
        _c(
          "form",
          {
            staticClass: "ui-form",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submit.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "ui-form-fieldwrapper" }, [
              _c("label", [
                _vm._v(
                  _vm._s(
                    _vm._f("translate")(
                      "webshop_login_resetPassword_label_pass"
                    )
                  )
                ),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.tempForm.newPassword,
                    expression: "tempForm.newPassword",
                  },
                  { name: "autofocus", rawName: "v-autofocus" },
                ],
                ref: "newPass",
                attrs: {
                  type: "password",
                  required: "",
                  minlength: "6",
                  autocomplete: "off",
                },
                domProps: { value: _vm.tempForm.newPassword },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.tempForm, "newPassword", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "ui-form-fieldwrapper" }, [
              _c("label", [
                _vm._v(
                  _vm._s(
                    _vm._f("translate")(
                      "webshop_login_resetPassword_label_passRepeat"
                    )
                  )
                ),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.tempForm.repeatNewPassword,
                    expression: "tempForm.repeatNewPassword",
                  },
                ],
                ref: "newPassRep",
                attrs: {
                  type: "password",
                  required: "",
                  minlength: "6",
                  autocomplete: "off",
                },
                domProps: { value: _vm.tempForm.repeatNewPassword },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.tempForm,
                      "repeatNewPassword",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ui-form-fieldwrapper" },
              [
                _c("loading-button", {
                  attrs: {
                    "icon-class": "mdi-send",
                    text: _vm.$options.filters.translate(
                      "webshop_login_resetPassword_submitBtn"
                    ),
                    "loading-text": _vm.$options.filters.translate(
                      "webshop_login_resetPassword_submitBtn_loading"
                    ),
                    loading: _vm.loading,
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }