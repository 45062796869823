var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "item-surcharge-wrapper",
      on: {
        mouseleave: function ($event) {
          _vm.showWarningMsg = false
        },
      },
    },
    [
      _c("div", {
        staticClass: "icon-report-problem",
        attrs: {
          title: _vm.$options.filters.translate(
            "webshop_search_search_item_surchargeWarning",
            _vm.suggestedAmount
          ),
        },
        on: {
          click: function ($event) {
            _vm.showWarningMsg = !_vm.showWarningMsg
          },
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showWarningMsg,
              expression: "showWarningMsg",
            },
          ],
          staticClass: "item-surcharge-warning",
          class: {
            "item-surcharge-warning-viewlist":
              _vm.viewType == _vm.viewTypes.list,
          },
        },
        [
          _vm._v(
            _vm._s(
              _vm._f("translate")(
                "webshop_search_search_item_surchargeWarning",
                _vm.suggestedAmount
              )
            )
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }