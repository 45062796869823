var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table-outer-row" }, [
    _c("div", { staticClass: "table-row" }, [
      _c("div", { staticClass: "table-column" }, [
        _c("span", [
          _vm._v(_vm._s(_vm._f("translate")("webshop_general_name")) + ": "),
        ]),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.archiveItem.name))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "table-column" }, [
        _c("span", [
          _vm._v(_vm._s(_vm._f("translate")("webshop_general_date")) + ": "),
        ]),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm._f("date")(_vm.archiveItem.created)))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "table-column align-right mobile-align-left" }, [
        _c("span", [
          _vm._v(_vm._s(_vm._f("translate")("webshop_general_total")) + ": "),
        ]),
        _vm._v(" "),
        _c(
          "span",
          [
            _vm.showPrice
              ? [_vm._v(_vm._s(_vm._f("currency")(_vm.archiveItem.total)))]
              : _vm._e(),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "table-column column-report" }, [
        _c("span"),
        _vm._v(" "),
        _c("span", [
          _c(
            "button",
            {
              staticClass: "ui-button ui-button-small inventory-archive-print",
              attrs: { disabled: _vm.loading },
              on: {
                click: function ($event) {
                  return _vm.showReport()
                },
              },
            },
            [
              _c("span", { staticClass: "mdi mdi-printer" }),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("translate")("webshop_inventoryArchive_modal_print")
                  )
                ),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "icon-trash-wrapper" },
          [
            _c("icon-trash", {
              attrs: { focusable: "false" },
              on: {
                click: function ($event) {
                  return _vm.deleteArchive()
                },
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }