import { Service } from '../../../basic-service/src';
export class CookieStorageService extends Service {
    static available = false;
    static initialize() {
        this.available = navigator.cookieEnabled;
    }
    static setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
    static getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    static itemExists(key) {
        return this.getCookie(key) != "";
    }
    static getItem(key) {
        return new Promise((resolve, reject) => {
            if (this.itemExists(key)) {
                resolve(JSON.parse(this.getCookie(key)));
            }
            else {
                reject(null);
            }
        });
    }
    static setItem(key, item) {
        return new Promise((resolve, reject) => {
            resolve(this.setCookie(key, JSON.stringify(item), 365));
        });
    }
    static removeItem(key) {
        return new Promise((resolve, reject) => {
            resolve(this.setCookie(key, "", 0));
        });
    }
}
