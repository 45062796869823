var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { EventTypes } from "models/EventTypes";
import { SearchInput } from "models/search/SearchInput";
import { SearchSortBy } from "models/search/SearchSortBy";
import { SearchService } from "services/SearchService";
import { debounce } from "lodash";
import "directives/ClickOutside";
let ShopSearchInput = class ShopSearchInput extends Vue {
    loading;
    filters;
    input;
    model = "";
    suggestions = [];
    showSugs = false;
    selectedSuggestionIndex = -1;
    searchSuggestionsFunc = debounce(function () {
        this.getSearchSuggestions();
        this.showSugs = true;
    }, 500);
    created() {
        this.$root.$on(EventTypes.ShopSearchDone, this.searchDone);
    }
    beforeDestroy() {
        this.$root.$off(EventTypes.ShopSearchDone, this.searchDone);
    }
    mounted() { }
    search() {
        let input = Object.assign(new SearchInput(), this.input);
        if (this.model !== '' && input.searchQuery !== this.model) {
            input.sortBy = SearchSortBy.Relevance;
        }
        else if (this.model === '' && input.sortBy === SearchSortBy.Relevance) {
            input.sortBy = SearchSortBy.Description;
        }
        input.searchQuery = this.model;
        if (SearchService.isTopp200(input)) {
            input.favoriteListId = null;
            //input.sortBy = SearchSortBy.Relevance;
        }
        this.$root.$emit(EventTypes.ShopSearchStart, input);
    }
    searchDone() {
        this.model = this.input.searchQuery;
    }
    getSearchSuggestions() {
        if (this.model && this.model.length > 1) {
            let input = Object.assign(new SearchInput(), this.input);
            input.searchQuery = this.model;
            SearchService.getSearchSuggestions(input).then(res => {
                this.suggestions = res;
            });
        }
    }
    handleKeyUp(event) {
        const key = event.keyCode;
        if (key == 40 || key == 38) {
            event.preventDefault();
            if (key == 40) {
                this.selectedSuggestionIndex++;
            }
            else {
                this.selectedSuggestionIndex--;
            }
            if (this.selectedSuggestionIndex == this.suggestions.length) {
                this.selectedSuggestionIndex = 0;
            }
            else if (this.selectedSuggestionIndex == -1) {
                this.selectedSuggestionIndex = this.suggestions.length - 1;
            }
            this.model = this.suggestions[this.selectedSuggestionIndex] ? this.suggestions[this.selectedSuggestionIndex].searchTerm : undefined;
            this.showSugs = true;
            return;
        }
        else if (key == 27) {
            event.preventDefault();
            this.closeSuggestions();
        }
        else if (key == 13) {
            event.preventDefault();
            this.search();
            this.closeSuggestions();
        }
        else if (key != 32) {
            this.searchSuggestionsFunc();
        }
    }
    get showSuggs() {
        return this.showSugs;
    }
    get toppSearch() {
        return this.model && this.model.includes("topp:");
    }
    get isSearching() {
        return (this.model && this.model.length > 0 ||
            SearchService.isSearching(this.$route));
    }
    clearSearch() {
        const input = new SearchInput();
        SearchService.removeCachedSortBy();
        this.input.searchQuery = '';
        this.input.sortBy = SearchSortBy.Description;
        this.$root.$emit(EventTypes.ShopSearchStart, this.input);
    }
    closeSuggestions() {
        this.searchSuggestionsFunc.cancel();
        this.showSugs = false;
    }
    handleClickOnSuggestion(suggestion) {
        this.model = suggestion;
        this.search();
    }
};
ShopSearchInput = __decorate([
    Component({
        props: {
            filters: Object,
            input: Object,
            loading: Boolean
        }
    })
], ShopSearchInput);
export default ShopSearchInput;
