var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import Cookies from 'js-cookie';
let AgeVerification = class AgeVerification extends Vue {
    verifyAge() {
        // Set the ageVerified cookie and emit the event
        Cookies.set('ageVerified', 'true', {
            expires: 30,
            path: '/',
            domain: '.svenskcater.se', // Set the domain to your website domain
        });
        this.$emit('ageVerified');
    }
    closeModal() {
        // Close the modal without setting the cookie
        this.$emit('close-modal');
    }
    // Add a method to check if age verification is already done
    isAgeVerified() {
        return Cookies.get('ageVerified') === 'true';
    }
    // Add the following lifecycle hook to check age verification status on component creation
    created() {
        if (this.isAgeVerified()) {
            this.$emit('ageVerified');
        }
    }
};
AgeVerification = __decorate([
    Component
], AgeVerification);
export default AgeVerification;
