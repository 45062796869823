export class BasketProduct {
    amount;
    productId;
    description;
    unit;
    expiryRef;
    fromFavoriteList;
    checkoutId;
    list;
    depositLine;
    orderlineUISource;
    searchPlacement;
    isFromSearch;
    image;
    brand;
    constructor(amount, productId, unit) {
        this.amount = null;
        this.productId = null;
        this.description = null;
        this.unit = null;
        this.expiryRef = null;
        this.fromFavoriteList = false;
        this.checkoutId = 0;
        this.list = null;
        this.image = null;
        this.brand = null;
    }
    static fromApi(product) {
        return Object.assign(new BasketProduct(), product);
    }
}
