var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "suggestion-tooltip-wrapper" }, [
    !_vm.closedSuggestion
      ? _c("span", { staticClass: "suggestion-tooltip" }, [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm._f("translate")(
                  "webshop_search_search_item_suggestion_text"
                )
              ) +
              "\n    "
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }