var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.calculation && _vm.calculation.calculationId
    ? _c("div", [
        _c("div", { staticClass: "inventory-list-header" }, [
          _c("div", { attrs: { grid: "wrap" } }, [
            _c(
              "div",
              { attrs: { column: "s-12" } },
              [
                _c("span", { staticClass: "label" }, [
                  _vm._v(
                    _vm._s(_vm.CalculationType[_vm.calculation.calculationType])
                  ),
                ]),
                _vm._v(" "),
                !_vm.showChangeNameForm
                  ? [
                      _c("h1", { staticClass: "header-medium" }, [
                        _vm._v(_vm._s(_vm.calculation.name)),
                      ]),
                      _vm._v(" "),
                      !_vm.disableCalculation
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "ui-button ui-button-flat ui-button-small",
                              on: {
                                click: function ($event) {
                                  return _vm.addProductLines()
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "mdi mdi-plus" }),
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm._f("translate")(
                                      "webshop_calculations_calculationHeader_buttons_addProducts"
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.disableCalculation
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "ui-button ui-button-flat ui-button-small",
                              on: {
                                click: function ($event) {
                                  return _vm.addCalculationLines()
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "mdi mdi-plus" }),
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm._f("translate")(
                                      "webshop_calculations_calculationHeader_buttons_addCalculations"
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.disableCalculation
                        ? _c(
                            "a",
                            {
                              staticClass:
                                "ui-button ui-button-flat ui-button-small",
                              on: { click: _vm.changeCalculationName },
                            },
                            [
                              _c("i", {
                                staticClass: "mdi mdi-pencil settings-icon",
                              }),
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm._f("translate")(
                                      "webshop_calculations_calculationHeader_buttons_changeName"
                                    )
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.calculation.calculationLines.length > 0
                        ? [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "ui-button ui-button-flat ui-button-small _xs-hide _s-hide",
                                on: {
                                  click: function ($event) {
                                    return _vm.createOrderFromCalculation()
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "mdi mdi-content-save settings-icon",
                                }),
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm._f("translate")(
                                        "webshop_calculations_calculationHeader_buttons_createOrder"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass:
                                  "ui-button ui-button-flat ui-button-small _xs-hide _s-hide _m-hide",
                                on: { click: _vm.printCalculation },
                              },
                              [
                                _c("i", {
                                  staticClass: "mdi mdi-printer settings-icon",
                                }),
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm._f("translate")(
                                        "webshop_calculations_calculationHeader_buttons_print"
                                      )
                                    ) +
                                    "\n                        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.showShareButton && !_vm.disableCalculation
                              ? _c(
                                  "a",
                                  {
                                    staticClass:
                                      "ui-button ui-button-flat ui-button-small _xs-hide _s-hide _m-hide",
                                    on: { click: _vm.shareCalculation },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "mdi mdi-share-variant settings-icon",
                                    }),
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm._f("translate")(
                                            "webshop_calculations_calculationHeader_buttons_share"
                                          )
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                ref: "inventory-settings-container",
                                staticClass: "inventory-settings-container",
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "ui-button ui-button-flat ui-button-small inventory-settings-toggler",
                                    class: { cancel: _vm.showSettings },
                                    on: { click: _vm.toggleSettings },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "mdi mdi-dots-vertical",
                                    }),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass:
                                        "mdi mdi-close-circle-outline",
                                    }),
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm._f("translate")(
                                            "webshop_inventory_header_settings_btn"
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.showSettings
                                  ? _c(
                                      "ul",
                                      { staticClass: "inventory-settings" },
                                      [
                                        _c(
                                          "li",
                                          {
                                            staticClass:
                                              "_m-hide _l-hide _xl-hide",
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "ui-button ui-button-flat ui-button-small",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.createOrderFromCalculation()
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "mdi mdi-content-save settings-icon",
                                                }),
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      _vm._f("translate")(
                                                        "webshop_calculations_calculationHeader_buttons_createOrder"
                                                      )
                                                    ) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "li",
                                          { staticClass: "_l-hide _xl-hide" },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "ui-button ui-button-flat ui-button-small ui-button-transparent",
                                                on: {
                                                  click: _vm.printCalculation,
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "mdi mdi-printer settings-icon",
                                                }),
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      _vm._f("translate")(
                                                        "webshop_calculations_calculationHeader_buttons_print"
                                                      )
                                                    ) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "li",
                                          { staticClass: "_l-hide _xl-hide" },
                                          [
                                            _vm.showShareButton &&
                                            !_vm.disableCalculation
                                              ? _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "ui-button ui-button-flat ui-button-small",
                                                    on: {
                                                      click:
                                                        _vm.shareCalculation,
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "mdi mdi-share-variant settings-icon",
                                                    }),
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm._f("translate")(
                                                            "webshop_calculations_calculationHeader_buttons_share"
                                                          )
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("li", {}, [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "ui-button ui-button-flat ui-button-small",
                                              on: {
                                                click: _vm.copyCalculation,
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "mdi mdi-content-copy settings-icon",
                                              }),
                                              _vm._v(" "),
                                              _vm.disableCalculation
                                                ? [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("translate")(
                                                          "webshop_calculations_calculationHeader_buttons_copySharedCalculation"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                : [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("translate")(
                                                          "webshop_calculations_calculationHeader_buttons_copyCalculation"
                                                        )
                                                      )
                                                    ),
                                                  ],
                                            ],
                                            2
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _vm.calculation.calculationType !=
                                        _vm.CalculationType.Meny
                                          ? [
                                              _c("li", {}, [
                                                !_vm.disableCalculation
                                                  ? _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "ui-button ui-button-flat ui-button-small",
                                                        on: {
                                                          click:
                                                            _vm.openRecipeModal,
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "mdi mdi-file-document settings-icon",
                                                        }),
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "translate"
                                                              )(
                                                                "webshop_calculations_calculationHeader_buttons_recipeDetails"
                                                              )
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]),
                                              _vm._v(" "),
                                              _c("li", {}, [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "ui-button ui-button-flat ui-button-small",
                                                    on: {
                                                      click: _vm.printRecipe,
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "mdi mdi-printer settings-icon",
                                                    }),
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(
                                                          _vm._f("translate")(
                                                            "webshop_calculations_modals_recipe_btnPrint"
                                                          )
                                                        ) +
                                                        "\n                                        "
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.showChangeNameForm
                  ? _c("change-calculation-name", {
                      attrs: { calculation: _vm.calculation },
                      on: {
                        updated: function ($event) {
                          _vm.showChangeNameForm = false
                        },
                        cancel: function ($event) {
                          _vm.showChangeNameForm = false
                        },
                      },
                    })
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            !_vm.showChangeNameForm
              ? _c("div", { attrs: { column: "s-12" } }, [
                  _c("p", { staticClass: "product-count" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("translate")(
                          "webshop_calculations_calculationHeader_calculationPerPortionTitle"
                        )
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "calculation-header-inputs item-amount-col",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "input-wrapper" },
                        [
                          _c("span", { staticClass: "product-total-price" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_calculations_calculationHeader_productsCostText"
                                )
                              ) + " [KR]"
                            ),
                            _vm.showPrice ? _c("span") : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("input-number", {
                            attrs: {
                              inputClass: "item-amount-input",
                              inputDisabled: true,
                              decimalFormat: 0.2,
                            },
                            model: {
                              value: _vm.calculation.totalCost,
                              callback: function ($$v) {
                                _vm.$set(_vm.calculation, "totalCost", $$v)
                              },
                              expression: "calculation.totalCost",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "input-wrapper" },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_calculations_calculationHeader_label_coverage"
                                )
                              ) + " [%]"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.invalidCoverage,
                                expression: "invalidCoverage",
                              },
                            ],
                            staticClass: "input-icon mdi mdi-alert",
                          }),
                          _vm._v(" "),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.coverageUpdated,
                                expression: "coverageUpdated",
                              },
                            ],
                            staticClass: "input-icon-updated mdi mdi-check",
                          }),
                          _vm._v(" "),
                          _c("input-number", {
                            attrs: {
                              inputClass: "item-amount-input",
                              inputTabindex: "1",
                              autocomplete: "off",
                              inputDisabled:
                                _vm.disableInputs || _vm.disableCalculation,
                              inputMax: 99.999,
                              decimalFormat: 0.2,
                              inputStep: 0.01,
                              "select-onfocus": "true",
                            },
                            on: {
                              keyupenter: _vm.updateCoverage,
                              blur: _vm.updateCoverage,
                              focus: _vm.updateValues,
                            },
                            model: {
                              value: _vm.calculationObject.coverage,
                              callback: function ($$v) {
                                _vm.$set(_vm.calculationObject, "coverage", $$v)
                              },
                              expression: "calculationObject.coverage",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "input-wrapper" },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_calculations_calculationHeader_label_grossProfit"
                                )
                              ) + " [KR]"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.invalidGrossProfit,
                                expression: "invalidGrossProfit",
                              },
                            ],
                            staticClass: "input-icon mdi mdi-alert",
                          }),
                          _vm._v(" "),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.grossProfitUpdated,
                                expression: "grossProfitUpdated",
                              },
                            ],
                            staticClass: "input-icon-updated mdi mdi-check",
                          }),
                          _vm._v(" "),
                          _c("input-number", {
                            attrs: {
                              inputClass: "item-amount-input",
                              inputTabindex: "1",
                              autocomplete: "off",
                              inputDisabled:
                                _vm.disableInputs ||
                                !_vm.showPrice ||
                                _vm.disableCalculation,
                              decimalFormat: 0.2,
                              inputStep: 0.01,
                              "select-onfocus": "true",
                            },
                            on: {
                              keyupenter: _vm.updateGrossProfit,
                              blur: _vm.updateGrossProfit,
                              focus: _vm.updateValues,
                            },
                            model: {
                              value: _vm.calculationObject.grossProfit,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.calculationObject,
                                  "grossProfit",
                                  $$v
                                )
                              },
                              expression: "calculationObject.grossProfit",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "input-wrapper" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("translate")(
                                "webshop_calculations_calculationHeader_label_vat"
                              )
                            ) + " [%]"
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.calculationObject.vatId,
                                expression: "calculationObject.vatId",
                              },
                            ],
                            staticClass: "input-calculation",
                            attrs: {
                              tabindex: "1",
                              disabled:
                                _vm.disableInputs || _vm.disableCalculation,
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.calculationObject,
                                    "vatId",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function ($event) {
                                  return _vm.updateCalculation(
                                    _vm.calculationObject
                                  )
                                },
                              ],
                            },
                          },
                          _vm._l(_vm.calculation.vatList, function (vat) {
                            return _c(
                              "option",
                              {
                                key: "vat" + vat.calculationVatId,
                                domProps: { value: vat.calculationVatId },
                              },
                              [_vm._v(_vm._s(vat.vat))]
                            )
                          }),
                          0
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "input-wrapper" },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("translate")(
                                  "webshop_calculations_calculationHeader_label_salesPrice"
                                )
                              ) + " [kr]"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.invalidSalesPrice,
                                expression: "invalidSalesPrice",
                              },
                            ],
                            staticClass: "input-icon mdi mdi-alert",
                          }),
                          _vm._v(" "),
                          _c("i", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.salesPriceUpdated,
                                expression: "salesPriceUpdated",
                              },
                            ],
                            staticClass: "input-icon-updated mdi mdi-check",
                          }),
                          _vm._v(" "),
                          _c("input-number", {
                            attrs: {
                              inputClass: "item-amount-input",
                              autocomplete: "off",
                              inputDisabled:
                                _vm.disableInputs ||
                                !_vm.showPrice ||
                                _vm.disableCalculation,
                              inputTabindex: "1",
                              decimalFormat: 0.2,
                              inputStep: 0.01,
                              "select-onfocus": "true",
                            },
                            on: {
                              keyupenter: _vm.updateSalesPrice,
                              blur: _vm.updateSalesPrice,
                              focus: _vm.updateValues,
                            },
                            model: {
                              value: _vm.calculationObject.priceToCustomer,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.calculationObject,
                                  "priceToCustomer",
                                  $$v
                                )
                              },
                              expression: "calculationObject.priceToCustomer",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.calculationObject.calculationType ===
                      _vm.CalculationType.Meny
                        ? _c(
                            "div",
                            { staticClass: "input-wrapper" },
                            [
                              _c(
                                "span",
                                { staticClass: "product-total-price" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("translate")(
                                        "webshop_calculations_calculationHeader_label_averagePrice"
                                      )
                                    ) + " [KR]"
                                  ),
                                  _vm.showPrice ? _c("span") : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c("input-number", {
                                attrs: {
                                  inputClass: "item-amount-input",
                                  inputDisabled: true,
                                  decimalFormat: 0.2,
                                },
                                model: {
                                  value: _vm.averagePricePerMeal,
                                  callback: function ($$v) {
                                    _vm.averagePricePerMeal = $$v
                                  },
                                  expression: "averagePricePerMeal",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        !_vm.showChangeNameForm
          ? _c(
              "div",
              { staticClass: "div-table calculation-header-other-inputs" },
              [
                _c("div", { staticClass: "table-header" }, [
                  _c("div", { staticClass: "table-row" }, [
                    _c("div", { staticClass: "table-column" }, [
                      _c("div", { staticClass: "input-container" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("translate")(
                                "webshop_calculations_calculationHeader_label_numberOfPortions"
                              )
                            ) + ":"
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "input-wrapper" },
                          [
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.invalidNumberOfPortion,
                                  expression: "invalidNumberOfPortion",
                                },
                              ],
                              staticClass: "input-icon mdi mdi-alert",
                            }),
                            _vm._v(" "),
                            _c("i", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.numberOfPortionUpdated,
                                  expression: "numberOfPortionUpdated",
                                },
                              ],
                              staticClass: "input-icon-updated mdi mdi-check",
                            }),
                            _vm._v(" "),
                            _c("input-number", {
                              attrs: {
                                inputClass: "item-amount-input",
                                autocomplete: "off",
                                inputDisabled:
                                  _vm.disableInputs || _vm.disableCalculation,
                                tabindex: "1",
                                decimalFormat: 0.2,
                                inputStep: 0.01,
                                "select-onfocus": "true",
                              },
                              on: {
                                keyupenter: _vm.updateNumberOfPortion,
                                blur: _vm.updateNumberOfPortion,
                                focus: _vm.updateValues,
                              },
                              model: {
                                value: _vm.calculationObject.numberOfPortions,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.calculationObject,
                                    "numberOfPortions",
                                    $$v
                                  )
                                },
                                expression:
                                  "calculationObject.numberOfPortions",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "input-container" }, [
                        _c("span", { staticClass: "product-count" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("translate")(
                                "webshop_calculations_calculationHeader_productCountText"
                              )
                            ) + ":"
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "input-wrapper" },
                          [
                            _c("input-number", {
                              attrs: {
                                inputClass: "item-amount-input",
                                inputDisabled: true,
                                decimalFormat: 0.2,
                              },
                              model: {
                                value: _vm.calculation.calculationLines.length,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.calculation.calculationLines,
                                    "length",
                                    $$v
                                  )
                                },
                                expression:
                                  "calculation.calculationLines.length",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "sort-by-container" }, [
                        _c("div", { staticClass: "sort-by-wrapper" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedSortBy,
                                  expression: "selectedSortBy",
                                },
                              ],
                              staticClass: "sort-by",
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.selectedSortBy = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  _vm.changeSortBy,
                                ],
                              },
                            },
                            _vm._l(_vm.sortByFilters, function (sortBy) {
                              return _c(
                                "option",
                                {
                                  key: sortBy.value,
                                  domProps: { value: sortBy.value },
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(sortBy.key) +
                                      "\n                                "
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }