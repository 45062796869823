var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c(
      "div",
      { staticClass: "table-header group-header inventory-group-header" },
      [
        _c("div", { staticClass: "table-row" }, [
          _c("div", { staticClass: "table-column" }, [
            !_vm.editGroup.active
              ? _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        _vm.group.name && _vm.toggleEdit()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.group.name
                          ? _vm.group.name
                          : _vm.$options.filters.translate(
                              "webshop_general_groupOutside"
                            )
                      )
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.editGroup.active
              ? _c(
                  "form",
                  {
                    staticClass: "group-new-name",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.save()
                      },
                    },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.editGroup.newGroupName,
                          expression: "editGroup.newGroupName",
                        },
                      ],
                      attrs: { type: "text", required: "", maxlength: "50" },
                      domProps: { value: _vm.editGroup.newGroupName },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.editGroup,
                            "newGroupName",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "ui-button ui-button-small accept",
                        attrs: { type: "submit" },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("translate")("webshop_general_save"))
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "ui-button ui-button-small cancel",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.editGroup.active = false
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("translate")("webshop_general_cancel"))
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.group.id !== null && _vm.list.groups.length > 1
            ? _c("div", { staticClass: "table-column sort-group-list" }, [
                _vm.group.sorting > 0
                  ? _c(
                      "a",
                      {
                        attrs: {
                          title: _vm.$options.filters.translate(
                            "webshop_inventory_table_group_moveUp"
                          ),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.moveGroup(true)
                          },
                        },
                      },
                      [_c("i", { staticClass: "mdi mdi-arrow-up" })]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.list.groups.length - 1 > _vm.group.sorting
                  ? _c(
                      "a",
                      {
                        attrs: {
                          title: _vm.$options.filters.translate(
                            "webshop_inventory_table_group_moveDown"
                          ),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.moveGroup(false)
                          },
                        },
                      },
                      [_c("i", { staticClass: "mdi mdi-arrow-down" })]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.group.id !== null
            ? _c(
                "div",
                { staticClass: "table-column group-delete" },
                [
                  _c("icon-trash", {
                    on: {
                      click: function ($event) {
                        return _vm.openDeleteConfirm()
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "table-body" },
      [
        _vm.group.lines && _vm.group.lines.length > 0
          ? [
              _vm.lines.length > 0
                ? [
                    _vm._l(_vm.lines, function (line) {
                      return _c("inventory-table-product", {
                        key: line.id,
                        attrs: {
                          line: line,
                          list: _vm.list,
                          group: _vm.group,
                          loading: _vm.loading,
                          setLoading: _vm.setLoading,
                        },
                      })
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "table-outer-row" }, [
                      _c("div", { staticClass: "table-row" }, [
                        _c("div", { staticClass: "table-column" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "table-column" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "table-column" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "table-column align-right" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("translate")(
                                "webshop_inventory_stock_summary"
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "table-column align-right" }, [
                          _vm._v(
                            _vm._s(_vm._f("currency")(_vm.group.totalPrice))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "table-column i-line-sorting",
                        }),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "table-column i-line-settings",
                        }),
                      ]),
                    ]),
                  ]
                : _c("div", { staticClass: "table-outer-row" }, [
                    _c("div", { staticClass: "table-row" }, [
                      _c("div", { staticClass: "table-column" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("translate")(
                              "webshop_inventory_table_group_noProducts_search"
                            )
                          )
                        ),
                      ]),
                    ]),
                  ]),
            ]
          : _c("div", { staticClass: "table-outer-row" }, [
              _c("div", { staticClass: "table-row" }, [
                _c("div", { staticClass: "table-column" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("translate")(
                        "webshop_inventory_table_group_noProducts"
                      )
                    )
                  ),
                ]),
              ]),
            ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }