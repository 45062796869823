var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { ToastService } from "services/ToastService";
import { AuthService } from "services/AuthService";
import { LoaderService } from "services/LoaderService";
let BasketChangeDeliveryForm = class BasketChangeDeliveryForm extends Vue {
    currentOrder;
    deliveryDates = null;
    loaded = false;
    hasDeliveryDates = false;
    mounted() {
        AuthService.getDeliveryDates().then(data => {
            this.loaded = true;
            this.deliveryDates = data;
            this.hasDeliveryDates = this.deliveryDates.deliveryDates && this.deliveryDates.deliveryDates.length > 0;
        }).catch((err) => {
            ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
        });
    }
    get pickup() {
        return this.currentOrder && this.currentOrder.pickupOrder;
    }
    setPickup(pickup) {
        //let date = moment(this.currentOrder.selectedDeliveryDay).toDate();
        LoaderService.createLoader({
            content: Vue.filter("translate")("webshop_modals_deliveryDate_selectLoadingText")
        });
        AuthService.setDeliveryDate(this.currentOrder.selectedDeliveryDay, pickup)
            .then(() => {
            LoaderService.stop();
            this.currentOrder.pickupOrder = pickup;
        })
            .catch(err => {
            LoaderService.stop();
            if (err === 400) {
                ToastService.danger("<p>" + Vue.filter("translate")("webshop_order_deliveryForm_noDeliveryThatDay") + "</p>");
            }
            else {
                ToastService.danger(Vue.filter("translate")("webshop_defaults_toast_errorText"));
            }
        });
    }
};
BasketChangeDeliveryForm = __decorate([
    Component({
        props: {
            currentOrder: Object
        }
    })
], BasketChangeDeliveryForm);
export default BasketChangeDeliveryForm;
