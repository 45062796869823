var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "shop-productlist-thumbs shop-products shop-search-result-gallery",
    },
    [
      _vm.hasPopularProducts
        ? _c("div", { staticClass: "shop-product-thumb-group-bar-popular" }, [
            _c("i", { staticClass: "icon icon-flame" }),
            _vm._v(" "),
            _c("span", { staticClass: "left-10" }, [
              _vm._v(
                _vm._s(
                  _vm._f("translate")(
                    "webshop_search_search_view_list_headers_popular"
                  )
                )
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "popular-products" },
        [
          _vm.hasPopularProducts
            ? [
                _c(
                  "div",
                  { attrs: { grid: "wrap" } },
                  [
                    _vm._l(_vm.displayedProducts, function (product, index) {
                      return _c(
                        "div",
                        {
                          key: product.productId,
                          staticClass: "shop-gallery-item-wrapper",
                          attrs: { column: "xs-12 l-6 xl-4" },
                        },
                        [
                          _c("shop-gallery-item", {
                            attrs: {
                              loading: _vm.loading,
                              product: product,
                              uiSource:
                                _vm.OrderlineUISourceTypes.ShopGalleryPopular,
                              position: index + 1,
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _vm.popularProducts && _vm.popularProducts.length > 3
                      ? _c("div", { staticClass: "btn-more-wrapper" }, [
                          !_vm.showAllPopularProducts
                            ? _c(
                                "button",
                                {
                                  staticClass: "ui-button",
                                  on: {
                                    click: function ($event) {
                                      return _vm.showMorePopularProducts()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("translate")(
                                        "webshop_search_search_view_general_popular_more"
                                      )
                                    )
                                  ),
                                ]
                              )
                            : _c(
                                "button",
                                {
                                  staticClass: "ui-button",
                                  on: {
                                    click: function ($event) {
                                      return _vm.showMorePopularProducts()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("translate")(
                                        "webshop_search_search_view_general_popular_less"
                                      )
                                    )
                                  ),
                                ]
                              ),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }