var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ui-modal-content" }, [
      _c("h3", { staticClass: "ui-modal-header" }, [
        _vm._v(
          _vm._s(_vm._f("translate")("webshop_cart_future_order_modal_header"))
        ),
      ]),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "ui-form",
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("div", [
            _c("p", [
              _vm._v(
                _vm._s(
                  _vm._f("translate")("webshop_cart_future_order_modal_text")
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ui-modal-footer" }, [
            _c("div", { staticClass: "ui-modal-confirm-wrapper" }, [
              _c(
                "button",
                {
                  staticClass: "ui-button ui-button-secondary",
                  on: { click: _vm.closeModal },
                },
                [_vm._v("OK")]
              ),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }