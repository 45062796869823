var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import * as _ from "lodash";
import { CalculationLineVM } from 'models/calculation/CalculationLineVM';
import InlineDeleteButton from 'components/shared/InlineDeleteButton.vue';
import InputNumber from 'components/shared/InputNumber.vue';
import { CalculationType } from 'models/calculation/CalculationType';
import { CalculationService } from 'services/CalculationService';
import { Service } from "services/Service";
import { ItemStatus } from 'models/product/ItemStatus';
import { ToastService } from 'services/ToastService';
import { PermissionService } from 'services/PermissionService';
import { PermissionLevel } from 'models/user/PermissionLevel';
import { CalculationLineUnits } from 'models/calculation/CalculationLineUnits';
import ShopItemIcons from "components/shop/partials/ShopItemIcons.vue";
import { PriceType } from 'models/product/PriceType';
import { ModalService } from 'modules/basic-core/basic-modal/src';
import CalculationModalCustomerItem from '../modals/partials/CalculationModalCustomerItem.vue';
import HighlightMarkings from "components/shared/HighlightMarkings.vue";
let CalculationLine = class CalculationLine extends Vue {
    calculationLine;
    disableCalculation;
    quantity = 0;
    percentageWasted = 0;
    CalculationType = CalculationType;
    disableQuantity = false;
    disablePercentageWasted = false;
    ItemStatus = ItemStatus;
    quantityUpdated = false;
    percentageWastedUpdated = false;
    invalidQuantity = false;
    invalidPercentageWasted = false;
    showPrice = PermissionService.hasPermission(PermissionLevel.ShowPrice);
    CalculationLineUnits = CalculationLineUnits;
    calcLine = new CalculationLineVM();
    priceType = PriceType;
    mounted() {
        this.calcLine = _.clone(this.calculationLine);
        this.quantity = this.calculationLine.quantity;
        this.percentageWasted = this.calculationLine.percentageWasted;
    }
    deleteCalculationLine() {
        CalculationService.deleteCalculationLine(this.calculationLine.calculationLineId).then(res => {
            this.$store.dispatch('Calculations/deleteCalculationLine', this.calculationLine);
            this.$store.dispatch('Calculations/updateSelectedCalculation', res);
            ToastService.success(Vue.filter('translate')('webshop_calculations_calculationLineList_toasts_deletedSuccessful'));
        }).catch(err => {
            ToastService.danger(Vue.filter('translate')('webshop_calculations_calculationLineList_toasts_deleteError'));
        });
    }
    updateQuantity(keyEnter) {
        if (keyEnter) {
            this.focusNextQuantity();
        }
        if (this.disableQuantity || this.quantity == this.calcLine.quantity) {
            return;
        }
        this.disableQuantity = true;
        CalculationService.updateCalculationLine(this.calcLine).then(res => {
            this.$store.dispatch('Calculations/updateSelectedCalculation', res);
            this.disableQuantity = false;
            this.quantityUpdated = true;
            this.quantity = this.calcLine.quantity;
            setTimeout(() => { this.quantityUpdated = false; }, 2000);
        }).catch(err => {
            this.disableQuantity = false;
            this.calcLine.quantity = this.quantity;
            this.invalidQuantity = true;
            setTimeout(() => { this.invalidQuantity = false; }, 2000);
        });
    }
    updatePercentageWasted(keyEnter) {
        if (keyEnter) {
            this.focusNextPercentageWasted();
        }
        if (this.disablePercentageWasted || this.percentageWasted == this.calcLine.percentageWasted) {
            return;
        }
        this.disablePercentageWasted = true;
        CalculationService.updateCalculationLine(this.calcLine).then(res => {
            this.$store.dispatch('Calculations/updateSelectedCalculation', res);
            this.disablePercentageWasted = false;
            this.percentageWastedUpdated = true;
            this.percentageWasted = this.calcLine.percentageWasted;
            setTimeout(() => { this.percentageWastedUpdated = false; }, 2000);
        }).catch(err => {
            this.disablePercentageWasted = false;
            this.calcLine.percentageWasted = this.percentageWasted;
            this.invalidPercentageWasted = true;
            setTimeout(() => { this.invalidPercentageWasted = false; }, 2000);
        });
    }
    editCustomerItem() {
        ModalService.create({
            cxt: this,
            component: CalculationModalCustomerItem,
            maxWidth: "720px",
            data: {
                list: this.$store.state.Calculations.selectedCalculation,
                line: this.calcLine,
                onSaved: (line) => {
                    this.calcLine = line;
                    this.$store.dispatch('Calculations/updateSelectedCalculation', this.$store.state.Calculations.selectedCalculation);
                }
            }
        });
    }
    focusNextQuantity() {
        Service.focusNextOnEnter(this.$refs['inputQuantityParent'], 'input.item-amount-input[tabindex="1"]', 'inputQuantity');
    }
    focusNextPercentageWasted() {
        Service.focusNextOnEnter(this.$refs['inputPercentageWastedParent'], 'input.item-amount-input[tabindex="1"]', 'inputQuantity');
    }
    get lineDisabled() {
        return this.calculationLine.itemDeleted || this.calculationLine.isItem && !this.calculationLine.isCustomerItem && (this.calculationLine.itemStatus === ItemStatus.Inactive ||
            this.calculationLine.itemStatus === ItemStatus.Deleted ||
            this.calculationLine.itemNetWeight == 0) || this.disableCalculation;
    }
    get priceTypeCampaign() {
        return this.calculationLine.units != null && this.calculationLine.units.find(x => x.priceType == this.priceType.CampaignPrice) != undefined;
    }
    get nettoWeight() {
        return this.calculationLine.itemNetWeight.toLocaleString().replace(".", ",");
    }
};
CalculationLine = __decorate([
    Component({
        components: {
            InlineDeleteButton,
            InputNumber,
            ShopItemIcons,
            HighlightMarkings
        },
        props: {
            calculationLine: Object,
            disableCalculation: Boolean
        }
    })
], CalculationLine);
export default CalculationLine;
