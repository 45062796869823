import { Api } from './Api';
export class ApprovalApi {
    static client = Api.client('/api/approval');
    static getWaitingForApproval(forLogin) {
        return this.client.get('/WaitingForApproval?forLogin=' + forLogin);
    }
    static acceptApprovals(types) {
        return this.client.post('/AcceptApprovals', { types: types });
    }
}
