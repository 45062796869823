var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { CalculationVM } from 'models/calculation/CalculationVM';
import { CalculationService } from 'services/CalculationService';
import { ToastService } from 'services/ToastService';
import Vue from 'vue';
import Component from 'vue-class-component';
import LoadingButton from '../../shared/LoadingButton.vue';
let ChangeCalculationName = class ChangeCalculationName extends Vue {
    calculation;
    oldCalculationId = 0;
    loading = false;
    get calculationObject() {
        let calculation = Object.assign(new CalculationVM(), this.calculation);
        if (this.oldCalculationId > 0 && this.oldCalculationId != calculation.calculationId) {
            this.$emit("cancel");
        }
        this.oldCalculationId = calculation.calculationId;
        return calculation;
    }
    changeCalculationName() {
        if (this.loading || this.calculationObject.name === this.calculation.name)
            return;
        this.loading = true;
        CalculationService.updateCalculation(this.calculationObject).then(res => {
            this.$store.dispatch('Calculations/updateSelectedCalculation', res);
            ToastService.success(Vue.filter("translate")("webshop_calculations_calculationHeader_toast_nameChanged"));
            this.$emit("updated");
        })
            .catch(err => {
            ToastService.danger(Vue.filter("translate")("webshop_calculations_calculationHeader_toast_errorChangeName"));
        })
            .finally(() => {
            this.loading = false;
        });
    }
    cancel() {
        this.$emit("cancel");
    }
};
ChangeCalculationName = __decorate([
    Component({
        props: {
            calculation: Object
        },
        components: {
            LoadingButton
        }
    })
], ChangeCalculationName);
export default ChangeCalculationName;
