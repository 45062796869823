var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ui-card ui-card-statistics" }, [
    _c("div", { staticClass: "ui-card-content" }, [
      _c(
        "form",
        {
          staticClass: "ui-form",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.getStatistics()
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "statistics-filter-element" },
            [
              _c("label", [
                _vm._v(
                  _vm._s(
                    _vm._f("translate")(
                      "webshop_statistics_filter_products_fromDate"
                    )
                  )
                ),
              ]),
              _vm._v(" "),
              _c("flat-pickr", {
                staticClass: "statistics-filter-input-date",
                attrs: {
                  config: _vm.dateSettings,
                  disabled: _vm.loading,
                  required: "true",
                },
                model: {
                  value: _vm.productStatisticsFilter.fromDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.productStatisticsFilter, "fromDate", $$v)
                  },
                  expression: "productStatisticsFilter.fromDate",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "statistics-filter-element" },
            [
              _c("label", [
                _vm._v(
                  _vm._s(
                    _vm._f("translate")(
                      "webshop_statistics_filter_products_toDate"
                    )
                  )
                ),
              ]),
              _vm._v(" "),
              _c("flat-pickr", {
                staticClass: "statistics-filter-input-date",
                attrs: {
                  config: _vm.dateSettings,
                  disabled: _vm.loading,
                  required: "true",
                },
                model: {
                  value: _vm.productStatisticsFilter.toDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.productStatisticsFilter, "toDate", $$v)
                  },
                  expression: "productStatisticsFilter.toDate",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "statistics-filter-element" },
            [
              _c("loading-button", {
                staticClass: "statistics-filter-button",
                attrs: {
                  "loading-text": _vm.$options.filters.translate(
                    "webshop_general_show"
                  ),
                  text: _vm.$options.filters.translate("webshop_general_show"),
                  loading: _vm.loading,
                  "icon-class": " mdi-chart-bar",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }