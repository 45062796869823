var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { PermissionService } from 'services/PermissionService';
import { PermissionLevel } from 'models/user/PermissionLevel';
let DepositProductLine = class DepositProductLine extends Vue {
    depositProduct;
    quantity = null;
    showPrice = PermissionService.hasPermission(PermissionLevel.ShowPrice);
    get unit() {
        if (this.depositProduct.product && this.depositProduct.product.units) {
            return this.depositProduct.product.units[0].name;
        }
        return "";
    }
    get price() {
        if (this.depositProduct.product && this.depositProduct.product.units) {
            return this.depositProduct.product.units[0].price;
        }
        return 0;
    }
    changeQuantity() {
        this.$emit('update-quantity', this.depositProduct, this.quantity);
    }
};
DepositProductLine = __decorate([
    Component({
        components: {},
        props: {
            depositProduct: Object
        }
    })
], DepositProductLine);
export default DepositProductLine;
