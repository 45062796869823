var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import LoadingButton from '../shared/LoadingButton.vue';
import { ToastService } from 'services/ToastService';
import { FavoriteService } from 'services/FavoriteService';
import { EventTypes } from 'models/EventTypes';
let FavoriteCreateModal = class FavoriteCreateModal extends Vue {
    loading = false;
    dpSettings = {
        enable: [{ from: new Date(1957, 0, 1), to: new Date() }]
    };
    tempForm = {
        listName: '',
        shared: false,
        from: null,
        to: null
    };
    createdList = null;
    showByDate = false;
    beforeDestroy() {
        this.$root.$emit(EventTypes.FavoriteCreatedList, this.createdList);
    }
    toogleByDate() {
        this.tempForm.from = null,
            this.tempForm.to = null;
        this.showByDate = !this.showByDate;
    }
    closeModal() {
        this.$emit('close-modal');
    }
    submit() {
        if (this.showByDate) {
            if (!this.tempForm.from || !this.tempForm.to || this.tempForm.from > this.tempForm.to) {
                ToastService.danger(Vue.filter("translate")('webshop_favorite_createList_toast_invalidDates'));
                return;
            }
        }
        this.loading = true;
        let promise = !this.showByDate
            ? FavoriteService.createList(this.tempForm.listName, this.tempForm.shared)
            : FavoriteService.createListFromHistory(this.tempForm.listName, this.tempForm.shared, this.tempForm.from, this.tempForm.to);
        promise.then(createdList => {
            ToastService.success(Vue.filter('translate')('webshop_favorite_createList_toast_created', '<i>' + createdList.listName + '</i>'));
            this.createdList = createdList;
            this.closeModal();
        }).catch(err => {
            this.loading = false;
            let errorTrans = 'webshop_favorite_createList_toast_created_error';
            if (err === 400) {
                errorTrans = 'webshop_favorite_createList_toast_missingName';
            }
            else if (err === 406) {
                errorTrans = 'webshop_favorite_createList_toast_invalidDates';
            }
            else if (err === 409) {
                errorTrans = 'webshop_favorite_createList_toast_nameInUse';
            }
            else if (err === 416) {
                errorTrans = 'webshop_favorite_createList_toast_noProductsInPeriod';
            }
            ToastService.danger(Vue.filter("translate")(errorTrans));
        });
    }
};
FavoriteCreateModal = __decorate([
    Component({
        components: {
            LoadingButton
        }
    })
], FavoriteCreateModal);
export default FavoriteCreateModal;
