import { Service } from "../../../basic-service/src";
import { Toast } from "../models/Toast";
import { ToastType } from "../models/ToastType";
export class ToastService extends Service {
    static nextId = 0;
    static toasts = new Array();
    static create(content, type, duration) {
        return new Promise((resolve, reject) => {
            let toast = new Toast(this.nextId++, content, type, duration);
            ToastService.toasts.push(toast);
            setTimeout(() => {
                let idx = ToastService.toasts.findIndex((idxToast) => {
                    return idxToast.id == toast.id;
                });
                ToastService.toasts.splice(idx, 1);
                resolve();
            }, toast.duration);
        });
    }
    static success(content, duration) {
        return ToastService.create(content, ToastType.Success, duration);
    }
    static info(content, duration) {
        return ToastService.create(content, ToastType.Info, duration);
    }
    static warning(content, duration) {
        return ToastService.create(content, ToastType.Warning, duration);
    }
    static danger(content, duration) {
        return ToastService.create(content, ToastType.Danger, duration);
    }
}
