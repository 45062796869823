var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { ModalService } from "modules/basic-core/basic-modal/src";
import OrderModal from "../partials/OrderModal.vue";
import IconTrash from '../../shared/IconTrash.vue';
let OrdersResultListItem = class OrdersResultListItem extends Vue {
    order;
    editOrder() {
        ModalService.create({
            cxt: this,
            component: OrderModal,
            maxWidth: "350px",
            data: {
                order: this.order,
                delete: false
            }
        });
    }
    deleteOrder() {
        ModalService.create({
            cxt: this,
            component: OrderModal,
            maxWidth: "350px",
            data: {
                order: this.order,
                deleteOrder: true
            }
        });
    }
    setActiveOrder() {
        this.$store.dispatch('Orders/setActiveOrder', this.order.orderNo).then(res => {
        });
    }
};
OrdersResultListItem = __decorate([
    Component({
        components: {
            IconTrash
        },
        props: {
            order: Object
        }
    })
], OrdersResultListItem);
export default OrdersResultListItem;
