var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? [_c("loader", { attrs: { size: "large" } })]
        : [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "columns large-12" }, [
                _c("div", { staticClass: "ui-card shop-orderpart-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "ui-card-header ui-card-header-inverted" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm._f("translate")(
                            "webshop_vendor_homePage_tableDeadlines"
                          )
                        )
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "ui-card-content" }, [
                    _c("div", { staticClass: "table--responsive" }, [
                      _c(
                        "table",
                        {
                          staticClass: "shop-table",
                          attrs: { cellpadding: "0", cellspacing: "0" },
                        },
                        [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("translate")(
                                      "webshop_vendor_homePage_tableDeadlines_columnCode"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("translate")(
                                      "webshop_vendor_homePage_tableDeadlines_columnName"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("translate")(
                                      "webshop_vendor_homePage_tableDeadlines_columnDeadlineDate"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("th"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(
                              _vm.homePageContent.campaignDeadlines,
                              function (campaign) {
                                return _c(
                                  "tr",
                                  {
                                    key: campaign.code,
                                    staticClass: "shop-table-row",
                                  },
                                  [
                                    _c("td", { staticClass: "col1" }, [
                                      _vm._v(_vm._s(campaign.code)),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "col2" }, [
                                      _vm._v(_vm._s(campaign.description)),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "col3" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.deadlineInDays(
                                            campaign.purchaseStartingDate
                                          )
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      { staticClass: "col4 text-right" },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "ui-button ui-button-small",
                                            attrs: {
                                              href:
                                                "/leverantoer/kampanjplanering/" +
                                                campaign.code,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("translate")(
                                                  "webshop_vendor_homePage_tableDeadlines_buttonMore"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }